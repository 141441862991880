<template>
<div>
    <!-- modal -->
    <b-modal id="nationalTrademarkSearchFormModal" centered size="xl" no-close-on-backdrop ref="nationalTrademarkSearchFormModal" :title=" $t('nationalTrademarkSearch.national_trademark_search_info')" hide-footer @close="clear()">
        <agent-note-form ref="agentNoteFormModal"></agent-note-form>
        <client-form ref="clientFormModal"></client-form>
        <reminder-form ref="reminderFormModal"></reminder-form>
        <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
        <div class="custom-search d-flex justify-content mb-1">
            <b-row style="margin-right: auto;">
                <b-media no-body>
                    <b-media-aside>
                        <b-img :src="image" style="height:auto;" class="uploading-image" />
                    </b-media-aside>
                    <b-media-body>
                        <b-dropdown style="width: 4em; height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                            <template #button-content>
                                <feather-icon icon="DownloadIcon" size="10" class="align-middle" />
                            </template>
                            <b-form-file ref="refInputEl" accept=".jpg, .png, .gif, .jpeg" :hidden="true" plain @change="uploadImage" />
                            <b-dropdown-item @click="$refs.refInputEl.$el.click()" size="sm">
                                <span> {{$t('others.select_image')}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="downloadLogo" size="sm">
                                <span> {{$t('others.download')}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-media-body>
                </b-media>
            </b-row>
            <b-row style="margin-left: auto;">
                <b-col>
                    <b-button variant="outline-primary" size="sm" class="ml-05" @click.prevent="addOrUpdate">
                        {{$t('others.ok_title')}}
                    </b-button>
                    <b-dropdown v-if="id > 0" class="ml-05" style="height: 2.1em;" size="sm" :text="$t('others.invoices')" variant="outline-primary">
                        <b-dropdown-item @click="getClientInvoiceReport(2)">{{$t('others.InvoicesToBePaid')}}: {{ invoicesToBePaid }}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(3)">{{$t('others.AllInvoices')}}: {{ allInvoices }}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(4)">{{$t('others.InvoicesToBePaidForThisJob')}}: {{invoicesToBePaidForThisJob}}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(1)">{{$t('others.AllInvoicesRelatedToThisJob')}}: {{ allInvoicesRelatedToThisJob }}</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                        <template #button-content>
                            <feather-icon icon="PrinterIcon" size="10" class="align-middle" />
                        </template>
                        <div class="dropdown-scroll">
                            <b-dropdown-item @click="downloadNationalTrademarkSearchWordFile(wordTemplate.id, wordTemplate.name)" v-for="wordTemplate in wordTemplateList" :key="wordTemplate.id">
                                <span>{{ wordTemplate.name }}</span>
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                        <template #button-content>
                            <feather-icon icon="SendIcon" size="10" class="align-middle" />
                        </template>
                        <div class="dropdown-scroll">
                            <b-dropdown-item @click="prepareNationalTrademarkSearchMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                <span>{{mailTemplate.explanation}}</span>
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <b-button variant="outline-primary" class="ml-05" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                        <feather-icon icon="BellIcon" size="10" class="align-middle" />
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <b-row>
            <b-col class="col-md-3">
                <b-form>
                    <label for="national-trademark-search-record-no-input">{{ $t('nationalTrademarkSearch.record_no') }}</label>
                    <b-form-group>
                        <b-form-input id="national-trademark-search-record-no-input" size="sm" v-model="recordNo" disabled />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="national-trademark-search-type-input">{{ $t('nationalTrademarkSearch.national_trademark_search_type') }}</label>
                    <b-form-select v-model="typeSelect" value-field="id" size="sm" text-field="name" :options="typeOption" />
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <validation-observer ref="simpleRules">
                    <b-form>
                        <label for="national-trademark-search-name-input">{{ $t('nationalTrademarkSearch.name') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('nationalTrademarkSearch.name')" rules="required">
                                <b-form-input id="national-trademark-search-trademark-name-input" size="sm" v-model="nationalTrademarkName" maxlength="250" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </b-col>
            <b-col class="col-md-3">
                <label for="national-trademark-search-application-type-input">{{ $t('nationalTrademarkSearch.national_trademark_search_application_type') }}</label>
                <b-form-select v-model="applicationTypeSelect" value-field="id" size="sm" text-field="name" :options="applicationTypeOption" />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-3">
                <b-form>
                    <label for="national-trademark-search-registration-chance-input">{{ $t('nationalTrademarkSearch.registration_chance') }}</label>
                    <b-input-group>
                        <b-form-select v-model="registrationChanceSelect" value-field="id" size="sm" text-field="name" :options="registrationChanceOption" />
                        <b-input-group-append style="width: 15%;">
                            <b-form-input id="national-trademark-search-registration-chance-rate-input" size="sm" v-model="registrationChanceRate" type="number" />
                        </b-input-group-append>
                    </b-input-group>
                    <b-input-group>
                    </b-input-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="national-trademark-search-use-chance-input">{{ $t('nationalTrademarkSearch.use_chance') }}</label>
                    <b-input-group>
                        <b-form-select v-model="useChanceSelect" value-field="id" size="sm" text-field="name" :options="useChanceOption" />
                        <b-input-group-append style="width: 15%;">
                            <b-form-input size="sm" v-model="useChanceRate" type="number" />
                        </b-input-group-append>
                    </b-input-group>
                    <b-input-group>
                    </b-input-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="national-trademark-search-agent-ref-no-input">{{ $t('nationalTrademarkSearch.agent_ref_no') }}</label>
                    <b-form-group>
                        <b-form-input id="national-trademark-search-agent-ref-no-input" size="sm" v-model="agentReferenceNo" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <label for="national-trademark-search-instruction-date-input">{{ $t('nationalTrademarkSearch.instruction_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="instructionDate"></CustomDatePicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-3">
                <label for="national-trademark-search-critical-date-input">{{ $t('nationalTrademarkSearch.critical_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="criticalDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-3">
                <label for="national-trademark-search-client-critical-date-input">{{ $t('nationalTrademarkSearch.client_critical_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="clientCriticalDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-2">
                <label for="national-trademark-search-is51CRisk-input">{{ $t('nationalTrademarkSearch.is_51CRisk') }}</label>
                <br>
                <b-form-checkbox class="custom-control-success" name="national-trademark-search-is51CRisk-input" id="national-trademark-search-is51CRisk-input" v-model="is51CRisk" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
            <b-col class="col-md-2">
                <label for="national-trademark-search-isUrgent-input">{{ $t('nationalTrademarkSearch.is_urgent') }}</label>
                <br>
                <b-form-checkbox class="custom-control-success" name="national-trademark-search-isUrgent-input" id="national-trademark-search-isUrgent-input" v-model="isUrgent" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
            <b-col class="col-md-2">
                <label for="national-trademark-search-isConflict-input">{{ $t('nationalTrademarkSearch.is_conflict') }}</label>
                <br>
                <b-form-checkbox class="custom-control-success" name="national-trademark-search-isConflict-input" id="national-trademark-search-isConflict-input" v-model="isConflict" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <label for="national-trademark-search-countries-input">{{ $t('nationalTrademarkSearch.countries') }}</label>
                <b-form-select v-model="countriesSelect" value-field="id" size="sm" text-field="name" :options="countriesOption" />
            </b-col>
        </b-row>
        <b-row v-if="hiddenControl == false">
            <b-col class="col-lg-12 mt-1">
                <b-form-group>
                    <label for="national-trademark-search-agent-input"> {{ $t('nationalTrademarkSearch.agent') }} </label>
                    <b-input-group class="d-flex align-items-center">
                        <b-form-group label-for="national-trademark-search-agent-input" class="flex-grow-1 mb-0">
                            <v-select id="national-trademark-search-agent-input" v-model="agentSelect" :options="agentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                        </b-form-group>
                        <b-input-group-append>
                            <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                <template #button-content>
                                    <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                </template>
                                <b-dropdown-item @click="getClientInfo()">
                                    <span>{{this.$t('others.add_client')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getAgentNoteInfo(agentSelect.clientAccountingId)">
                                    <span>{{this.$t('others.show_note')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getClientShowInfo(agentSelect.clientId)">
                                    <span>{{this.$t('others.show_agent')}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-else-if="hiddenControl == true">
            <b-col class="col-lg-12 mt-1">
                <b-form-group>
                    <label for="national-trademark-search-agent-input"> {{ $t('nationalTrademarkSearch.agent') }} </label>
                    <b-input-group class="d-flex align-items-center">
                        <b-form-group label-for="national-trademark-search-agent-input" class="flex-grow-1 mb-0">
                            <v-select id="national-trademark-search-agent-input" v-model="agentSelect" :options="agentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                        </b-form-group>
                        <b-input-group-append>
                            <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                <template #button-content>
                                    <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                </template>
                                <b-dropdown-item @click="getClientInfo()">
                                    <span>{{this.$t('others.add_client')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getClientShowInfo(agentSelect.clientId)">
                                    <span>{{this.$t('others.show_agent')}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <b-form-group :label="$t('nationalTrademarkSearch.invoice_client_accounting')" label-for="nationalTrademarkSearchInvoiceClientAccounting">
                        <b-input-group class="d-flex align-items-center">
                            <b-form-group label-for="national-trademark-search-agent-input" class="flex-grow-1 mb-0">
                                <v-select id="nationalTrademarkSearchInvoiceClientAccounting" v-model="invoiceClientSelect" :options="invoiceClientOption" @input="setClientIdWithInvoiceClientCheck" label="clientName" @search="onInvoiceClientAccountingSearch">
                                </v-select>
                            </b-form-group>
                            <b-input-group-append>
                                <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                    <template #button-content>
                                        <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                    </template>
                                    <b-dropdown-item @click="getClientInfo()">
                                        <span>{{this.$t('others.add_client')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getAgentNoteInfo(invoiceClientSelect.clientAccountingId)">
                                        <span>{{this.$t('others.show_note')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getClientShowInfo(invoiceClientSelect.clientId)">
                                        <span>{{this.$t('others.show_agent')}}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>

                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-12 mt-1">
                <h4 style="text-align: center; place-content: center;">{{ $t('nationalTrademarkSearch.nice_class') }}</h4>
                <b-form-group>
                    <b-form-input size="sm" disabled :value="niceClassSelectString" maxlength="250" />
                    <b-form-checkbox-group id="national-trademark-nice-class-type-input" v-model="niceClassSelect" :options="sortedArray" value-field="name" text-field="name" class="demo-inline-spacing" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-6 mt-1">
                <label for="goods-textarea-default">{{ $t('nationalTrademarkSearch.goods') }}</label>
                <b-form-textarea id="goods-textarea-default" v-model="goods" rows="5" maxlength="400" />
            </b-col>
            <b-col class="col-md-6 mt-1">
                <label for="note-textarea-default">{{ $t('nationalTrademarkSearch.note') }}</label>
                <b-form-textarea id="note-textarea-default" v-model="note" rows="5" maxlength="400" />
            </b-col>
        </b-row>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title=" $t('nationalTrademarkSearch.responsible')" style="width:100%;">
                            <b-card-text>
                                <national-trademark-search-responsible-list :nationalTrademarkSearchId="this.id" :clientId="this.clientId"></national-trademark-search-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab active :title=" $t('nationalTrademarkSearch.owner')" style="width:100%;">
                            <b-card-text>
                                <national-trademark-search-owner-list :getNationalTrademarkSearchList="getNationalTrademarkSearchList" :getClientInfo="getClientInfo" :nationalTrademarkSearchId="this.id"></national-trademark-search-owner-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('nationalTrademarkSearch.history')" style="width:100%;">
                            <b-card-text>
                                <national-trademark-search-history-list :getNationalTrademarkSearchList="getNationalTrademarkSearchList" :nationalTrademarkSearchId="this.id" :clientId="this.clientId"></national-trademark-search-history-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('nationalTrademarkSearch.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="23"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="23"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getNationalTrademarkSearchList: {
            type: Function
        },
    },
    computed: {
        niceClassSelectString() {
            return this.niceClassSelect.sort().join(' ');
        },
        sortedArray() {
            return this.niceClassOption.sort((a, b) => a.id - b.id);
        },
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            recordNo: '',
            nationalTrademarkName: '',
            typeSelect: 0,
            typeOption: [],
            registrationChanceSelect: 0,
            registrationChanceOption: [],
            useChanceSelect: 0,
            useChanceOption: [],
            applicationTypeSelect: 0,
            applicationTypeOption: [],
            agentSelect: 0,
            agentOption: [],
            invoiceClientOption: [],
            invoiceClientSelect: 0,
            agentReferenceNo: '',
            agentagentReferenceNoRefNo: '',
            instructionDate: null,
            criticalDate: null,
            clientCriticalDate: null,
            niceClass: '',
            goods: '',
            note: '',
            hiddenControl: true,
            niceClassOption: [],
            niceClassSelect: [],
            image: null,
            isUrgent: false,
            isConflict: false,
            is51CRisk: false,
            registrationChanceRate: 0,
            useChanceRate: 0,
            wordTemplateList: [],
            mailTemplateList: [],
            invoicesToBePaidForThisJob: 0,
            invoicesToBePaid: 0,
            allInvoices: 0,
            allInvoicesRelatedToThisJob: 0,
            countriesSelect: 0,
            countriesOption: [],
            clientAccountingId: 0,
        }
    },
    methods: {
        getClientInvoiceReport(type) {
            this.$refs.clientInvoiceReportListCallForm.showInfo(type, this.clientAccountingId, 23, this.id)
        },
        onInvoiceClientAccountingSearch(invoiceClientAccountingSearch, loading) {
            if (invoiceClientAccountingSearch.length) {
                loading(true);
                this.invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, this);
            }
        },
        invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, vm) {
            if (invoiceClientAccountingSearch.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: invoiceClientAccountingSearch
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.invoiceClientOption = response.data.clientList
                    loading(false)
                })
            }
        },
        uploadImage(event) {
            var input = event.target;
            if (this.id != 0) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.image = e.target.result;
                        var base64 = this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                        const data = {
                            fileName: this.nationalTrademarkSearchId,
                            base64: base64
                        }
                        const controller = {
                            name: 'NationalTrademarkSearch',
                            actionName: 'SaveNationalTrademarkSearchLogo'
                        };
                        const payload = {
                            data: data,
                            controller: controller
                        };
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.getNationalTrademarkSearchList()
                                this.$PleaseClickSave()
                            }
                        })
                    }
                    reader.readAsDataURL(input.files[0]);
                }
            } else if (this.id == 0) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.image = e.target.result;
                    };
                    reader.readAsDataURL(input.files[0]);
                }
            }
        },
        downloadLogo() {
            const data = {
                id: this.id
            }
            const controller = {
                name: 'NationalTrademarkSearch',
                actionName: 'DownloadNationalTrademarkSearchLogo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    const link = document.createElement('a')
                    link.href = 'data:image/jpeg;base64,' + response.data.base64
                    link.download = this.recordNo + '.jpg'
                    link.click()
                    this.getNationalTrademarkSearchList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getClientShowInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        async getReminderInfo() {
            this.$refs.reminderFormModal.showInfo(0, this.id, 2)
        },
        setClientId(event) {
            this.clientId = event.clientId
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.agentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        clear() {
            this.image = null
        },
        showInfo(id) {
            this.$refs['nationalTrademarkSearchFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'NationalTrademarkSearch'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.nationalTrademarkName = response.data.nationalTrademarkSearch.name
                    this.recordNo = response.data.nationalTrademarkSearch.recordNo
                    this.typeSelect = response.data.nationalTrademarkSearch.nationalTrademarkSearchTypeId
                    this.typeOption = response.data.nationalTrademarkSearchTypeList
                    this.registrationChanceSelect = response.data.nationalTrademarkSearch.registrationChanceId
                    this.registrationChanceOption = response.data.nationalTrademarkSearchRegistrationChanceRateList
                    this.useChanceSelect = response.data.nationalTrademarkSearch.useChanceId
                    this.useChanceOption = response.data.nationalTrademarkSearchUseChanceRateList
                    this.applicationTypeSelect = response.data.nationalTrademarkSearch.trademarkApplicationTypeId
                    this.applicationTypeOption = response.data.trademarkApplicationTypeList
                    this.agentReferenceNo = response.data.nationalTrademarkSearch.agentReferenceNo
                    this.instructionDate = response.data.nationalTrademarkSearch.instructionDate
                    this.criticalDate = response.data.nationalTrademarkSearch.criticalDate
                    this.clientCriticalDate = response.data.nationalTrademarkSearch.clientCriticalDate
                    this.registrationChanceRate = response.data.nationalTrademarkSearch.registrationChanceRate
                    this.useChanceRate = response.data.nationalTrademarkSearch.useChanceRate
                    this.is51CRisk = response.data.nationalTrademarkSearch.is51CRisk
                    this.isUrgent = response.data.nationalTrademarkSearch.isUrgent
                    this.isConflict = response.data.nationalTrademarkSearch.isConflict
                    this.countriesSelect = response.data.nationalTrademarkSearch.countryId
                    this.countriesOption = response.data.countryList
                    this.countriesOption.splice(0, 0, this.$nullSelected())
                    this.typeOption.splice(0, 0, this.$nullSelected())
                    this.useChanceOption.splice(0, 0, this.$nullSelected())
                    this.applicationTypeOption.splice(0, 0, this.$nullSelected())
                    this.registrationChanceOption.splice(0, 0, this.$nullSelected())
                    this.mailTemplateList = response.data.mailTemplateList
                    this.wordTemplateList = response.data.wordTemplateList
                    this.$emit('passToisUrgent', this.isUrgent);
                    if (response.data.nationalTrademarkSearch.niceClass !== null) {
                        this.niceClass = response.data.nationalTrademarkSearch.niceClass
                        this.niceClassSelect = response.data.nationalTrademarkSearch.niceClass.split(' ').sort()
                    } else {
                        this.niceClassSelect = []
                        this.niceClass = null
                    }
                    this.goods = response.data.nationalTrademarkSearch.goods
                    this.note = response.data.nationalTrademarkSearch.note
                    this.invoiceClientSelect = response.data.nationalTrademarkSearch.invoiceClientAccountingId
                    this.niceClassOption = response.data.niceClassList
                    if (this.id == 0) {
                        this.hiddenControl = true
                    } else {
                        this.hiddenControl = false
                    }
                    var nonBase64Image = 'data:image/jpeg;base64,'.concat(response.data.nationalTrademarkSearch.logo)
                    this.image = nonBase64Image

                    // Client Accounting Codes 
                    if (response.data.nationalTrademarkSearch.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.nationalTrademarkSearch.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.agentOption = clientAccountingResponse.data.clientList
                            this.agentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.agentOption = []
                        this.agentSelect = 0;
                    }
                    if (response.data.nationalTrademarkSearch.invoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.nationalTrademarkSearch.invoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.invoiceClientOption = clientAccountingResponse.data.clientList
                            this.invoiceClientSelect = clientAccountingResponse.data.clientList[0]
                        })
                    } else {
                        this.invoiceClientOption = []
                        this.invoiceClientSelect = 0;
                    }
                    if (this.id > 0) {
                        let accountingId = 0
                        if (response.data.nationalTrademarkSearch.clientAccountingId > 0)
                            accountingId = response.data.nationalTrademarkSearch.clientAccountingId
                        if (response.data.nationalTrademarkSearch.invoiceClientAccountingId > 0)
                            accountingId = response.data.nationalTrademarkSearch.invoiceClientAccountingId
                        this.clientAccountingId = accountingId

                        const invoiceData = {
                            clientAccountingId: accountingId,
                            moduleId: 23,
                            recordId: this.id
                        }
                        const invoiceController = {
                            name: 'ClientAccounting',
                            actionName: 'GetInvoiceCountByClientAccountId'
                        }
                        const invoicePayload = {
                            data: invoiceData,
                            controller: invoiceController
                        }
                        this.$store.dispatch('moduleAuth/customService', invoicePayload).then((invoiceResponse) => {
                            this.invoicesToBePaid = invoiceResponse.data.invoicesToBePaidCount
                            this.allInvoices = invoiceResponse.data.allInvoiceCount
                            this.allInvoicesRelatedToThisJob = invoiceResponse.data.allInvoicesRelatedToThisJobCount
                            this.invoicesToBePaidForThisJob = invoiceResponse.data.invoicesToBePaidForThisJobCount
                        })

                    } else {
                        this.invoicesToBePaid = 0
                        this.allInvoices = 0;
                        this.allInvoicesRelatedToThisJob = 0;
                        this.invoicesToBePaidForThisJob = 0;
                    }
                } else {
                    this.$bvModal.hide('nationalTrademarkSearchFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadNationalTrademarkSearchWordFile(documentId, name) {
            const data = {
                moduleId: 23,
                documentId: documentId,
                nationalTrademarkSearchId: this.id
            }
            const controller = {
                name: 'NationalTrademarkSearch',
                actionName: 'DownloadNationalTrademarkSearchWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.nationalTrademarkName,
                recordNo: this.recordNo,
                nationalTrademarkSearchTypeId: this.typeSelect,
                registrationChanceId: this.registrationChanceSelect,
                useChanceId: this.useChanceSelect,
                is51CRisk: this.is51CRisk,
                isConflict: this.isConflict,
                isUrgent: this.isUrgent,
                registrationChanceRate: this.registrationChanceRate,
                useChanceRate: this.useChanceRate,
                trademarkApplicationTypeId: this.applicationTypeSelect,
                agentReferenceNo: this.agentReferenceNo,
                instructionDate: this.instructionDate,
                criticalDate: this.criticalDate,
                clientCriticalDate: this.clientCriticalDate,
                niceClass: this.niceClass,
                goods: this.goods,
                note: this.note,
                countryId: this.countriesSelect,
                niceClass: this.niceClassSelectString,
                clientAccountingId: this.agentSelect.clientAccountingId,
                invoiceClientAccountingId: this.invoiceClientSelect.clientAccountingId,
                logo: this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
            }
            const controller = {
                name: 'NationalTrademarkSearch'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (this.id == 0) {
                        var base64 = this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                        const data = {
                            fileName: this.nationalTrademarkSearchId,
                            base64: base64
                        }
                        const controller = {
                            name: 'NationalTrademarkSearch',
                            actionName: 'SaveNationalTrademarkSearchLogo'
                        }
                        const payload = {
                            data: data,
                            controller: controller
                        }
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.getNationalTrademarkSearchList()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getNationalTrademarkSearchList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.nationalTrademarkSearchId
                                this.nationalTrademarkSearchRefCode = response.data.recordNo
                                this.hiddenControl = false
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        prepareNationalTrademarkSearchMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                nationalTrademarkSearchId: this.id
            }
            const controller = {
                name: 'NationalTrademarkSearch',
                actionName: 'PrepareNationalTrademarkSearchMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        setClientIdWithInvoiceClientCheck(event) {
            if (event != null) {
                if (this.invoiceClientSelect.forInvoice == false) {
                    this.clientId = null;
                    this.invoiceClientSelect = null;
                    this.$ForInvoiceAlert()
                } else {
                    this.clientId = event.clientId
                }
            } else {
                this.clientId = null;
            }
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] body.dark-layout .b-calendar-grid.form-control .small {
    background: #161d31;
    display: none !important;
}

.b-calendar .b-calendar-grid-help {
    color: #6e6b7b !important;
    display: none;
}

[dir=ltr] [class*=collapse-] .card .card-header {
    padding: 1rem 2.8rem 1rem 1rem;
    place-content: center;
}

.uploading-image {
    display: flex;
    width: 35em;
    height: 30em;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
