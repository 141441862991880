<template>
<div>
    <!-- modal -->
    <b-modal id="trademarkFormModal" centered size="xl" no-close-on-backdrop ref="trademarkFormModal" :title=" $t('trademark.trademark_info')" hide-footer @close="clear()">
        <agent-note-form ref="agentNoteForm"></agent-note-form>
        <client-form ref="clientForm"></client-form>
        <reminder-form ref="reminderForm"></reminder-form>
        <trademark-detail-form ref="trademarkDetailFormModal"></trademark-detail-form>
        <p-d-f-viewer ref="bulletinInfo"></p-d-f-viewer>
        <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
        <div class="custom-search d-flex justify-content mb-1">
            <b-row style="margin-right: auto;">
                <b-media no-body>
                    <b-media-aside>
                        <b-img :src="image" style="height:auto;" class="uploading-image" />
                    </b-media-aside>
                    <b-media-body>
                        <b-dropdown style="width: 4em; height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                            <template #button-content>
                                <feather-icon icon="DownloadIcon" size="10" class="align-middle" />
                            </template>
                            <b-form-file ref="refInputEl" accept=".jpg, .png, .gif, .jpeg" :hidden="true" plain @change="uploadImage" />
                            <b-dropdown-item @click="$refs.refInputEl.$el.click()" size="sm">
                                <span> {{$t('others.select_image')}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="createLogo" size="sm">
                                <span> {{$t('others.create')}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="downloadLogo" size="sm">
                                <span> {{$t('others.download')}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-media-body>
                </b-media>
            </b-row>
            <b-row style="margin-left: auto;">
                <b-col>
                    <b-button variant="outline-primary" size="sm" class="ml-05" @click.prevent="addOrUpdate">
                        {{$t('others.ok_title')}}
                    </b-button>
                    <b-dropdown v-if="id > 0" class="ml-05" style="height: 2.1em;" size="sm" :text="$t('others.invoices')" variant="outline-primary">
                        <b-dropdown-item @click="getClientInvoiceReport(2)">{{$t('others.InvoicesToBePaid')}}: {{ invoicesToBePaid }}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(3)">{{$t('others.AllInvoices')}}: {{ allInvoices }}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(4)">{{$t('others.InvoicesToBePaidForThisJob')}}: {{invoicesToBePaidForThisJob}}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(1)">{{$t('others.AllInvoicesRelatedToThisJob')}}: {{ allInvoicesRelatedToThisJob }}</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                        <template #button-content>
                            <feather-icon icon="PrinterIcon" size="10" class="align-middle" />
                        </template>
                        <div class="dropdown-scroll">
                            <b-dropdown-item @click="downloadTrademarkWordFile(wordTemplate.id, wordTemplate.name)" v-for="wordTemplate in wordTemplateList" :key="wordTemplate.id">
                                <span>{{wordTemplate.name}}</span>
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                        <template #button-content>
                            <feather-icon icon="SendIcon" size="10" class="align-middle selectInputScroll" />
                        </template>
                        <div class="dropdown-scroll">
                            <b-dropdown-item @click="prepareTrademarkMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                <span>{{mailTemplate.explanation}}</span>
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <b-button variant="outline-primary" class="ml-05" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                        <feather-icon icon="BellIcon" size="10" class="align-middle" />
                    </b-button>
                    <b-button v-if="this.bulletinNo > 0" class="ml-05" v-b-tooltip.html :title="$t('others.bulletin')" variant="warning" size="sm" @click="openBulletin()">
                        <feather-icon llx icon="BoldIcon" />
                    </b-button>
                    <b-dropdown class="ml-05" style="width: 4em; height: 2.1em; margin-right: 4em" size="sm" :text="$t('others.process')" variant="outline-primary">
                        <b-dropdown-item @click="getTrademarkDetail()">{{$t('trademark.trademark_detail')}}</b-dropdown-item>
                        <b-dropdown-item>{{$t('trademark.copy_folder')}}</b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>
        </div>
        <b-row>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-record-no-input">{{ $t('trademark.trademark_folder_no') }}</label>
                    <b-form-input id="trademark-record-no-input" size="sm" v-model="trademarkRefCode" disabled />
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <validation-observer ref="simpleRules">
                    <b-form>
                        <label for="trademark-name-input">{{ $t('trademark.name') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('trademark.name')" rules="required">
                                <b-form-input id="trademark-name-input" size="sm" v-model="name" maxlength="50" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-name-en-input">{{ $t('trademark.name_by_client') }}</label>
                    <b-form-group>
                        <b-form-input id="trademark-name-en-input" size="sm" v-model="nameEn" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-application-type-input">{{ $t('trademark.trademark_application_type') }}</label>
                    <b-form-select v-model="trademarkApplicationTypeSelect" value-field="id" size="sm" text-field="name" :options="trademarkApplicationOption" />
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="trademark-application-no-input">{{ $t('trademark.application_no') }}</label>
                    <b-form-group>
                        <b-form-input id="trademark-application-no-input" size="sm" v-model="applicationNo" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-type-input">{{ $t('trademark.trademark_type') }}</label>
                    <b-form-select v-model="trademarkTypeSelect" value-field="id" size="sm" text-field="name" :options="trademarkTypeOption" />
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <label for="trademark-instruction-date-input">{{ $t('trademark.instruction_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="instructionDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-3">
                <label for="trademark-yidk-date-input">{{ $t('trademark.yidk_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="yidkDate"></CustomDatePicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-first-register-no-input">{{ $t('trademark.first_register_no') }}</label>
                    <b-form-group>
                        <b-form-input id="trademark-first-register-no-input" size="sm" v-model="firstRegisterNo" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <label for="trademark-application-date-input">{{ $t('trademark.application_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="applicationDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-lg-3">
                <label for="trademark-bulletin-date-input">{{ $t('trademark.bulletin_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="bulletinDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-lg-3">
                <label for="trademark-emendation-date-input">{{ $t('trademark.emendation_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="emendationDate"></CustomDatePicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-register-no-input">{{ $t('trademark.register_no') }}</label>
                    <b-form-group>
                        <b-form-input id="trademark-register-no-input" size="sm" v-model="registerNo" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <label for="trademark-priority-document-deadline-date-input">{{ $t('trademark.priority_document_deadline_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="priorityDocumentDeadlineDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-3">
                <label for="trademark-register-date-input">{{ $t('trademark.register_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="registerDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-3">
                <label for="trademark-priority-document-date-input">{{ $t('trademark.priority_document_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="priorityDocumentDate"></CustomDatePicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-3">
                <label for="trademark-next-renewals-date-input">{{ $t('trademark.next_renewals_date') }}</label>
                <b-form-group>
                    <CustomDatePicker disabled :datePickerValidateOption="0" v-model="nextRenewalDate"></CustomDatePicker>
                </b-form-group>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-status-input">{{ $t('trademark.status') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="trademark-status-input" disabled v-model="trademarkStatus" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-6">
                <b-form>
                    <label for="trademark-nice-class-input">{{ $t('trademark.nice_class') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="trademark-nice-class-input" disabled v-model="niceClass" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-3">
                <label for="is-replacement-input"> {{ $t('trademark.is_replacement') }} </label>
                <b-form-checkbox :checked="isReplacement" class="custom-control-success" name="check-button" v-model="isReplacement" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
            <b-col class="col-md-3">
                <label for="is-famous-brand-input"> {{ $t('trademark.is_famous_brand') }} </label>
                <b-form-checkbox :checked="isFamousBrand" class="custom-control-success" name="check-button" v-model="isFamousBrand" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
            <b-col class="col-md-3">
                <label for="is-take-over-input"> {{ $t('trademark.is_take_over') }} </label>
                <b-form-checkbox :checked="isTakeOver" class="custom-control-success" name="check-button" v-model="isTakeOver" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
            <b-col class="col-md-3">
                <label for="is-transformation-input"> {{ $t('trademark.is_transformation') }} </label>
                <b-form-checkbox :checked="isTransformation" class="custom-control-success" name="check-button" v-model="isTransformation" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row v-if="hiddenControl == false">
            <b-col class="col-lg-12 mt-1">
                <b-form-group>
                    <label for="trademark-agent-input"> {{ $t('trademark.agent') }} </label>
                    <b-input-group class="d-flex align-items-center">
                        <b-form-group label-for="trademark-agent-input" class="flex-grow-1 mb-0">
                            <v-select id="trademark-agent-input" v-model="trademarkAgentSelect" :options="trademarkAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                        </b-form-group>
                        <b-input-group-append>
                            <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                <template #button-content>
                                    <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                </template>
                                <b-dropdown-item @click="getClientInfo(0)">
                                    <span>{{this.$t('others.add_client')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getAgentNoteInfo(trademarkAgentSelect.clientAccountingId)">
                                    <span>{{this.$t('others.show_note')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getClientInfo(trademarkAgentSelect.clientId)">
                                    <span>{{this.$t('others.show_agent')}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-else-if="hiddenControl == true">
            <b-col class="col-lg-12 mt-1">
                <b-form-group>
                    <label for="trademark-agent-input"> {{ $t('trademark.agent') }} </label>
                    <b-input-group class="d-flex align-items-center">
                        <b-form-group label-for="trademark-agent-input" class="flex-grow-1 mb-0">
                            <v-select id="trademark-agent-input" v-model="trademarkAgentSelect" :options="trademarkAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                        </b-form-group>
                        <b-input-group-append>
                            <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                <template #button-content>
                                    <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                </template>
                                <b-dropdown-item @click="getClientInfo(0)">
                                    <span>{{this.$t('others.add_client')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getClientInfo(trademarkAgentSelect.clientId)">
                                    <span>{{this.$t('others.show_agent')}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="trademark-agent-ref-code-input">{{ $t('trademark.trademark_agent_ref_code') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="trademark-agent-ref-code-input" v-model="agentReferenceNo" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <b-form-group :label="$t('trademark.invoice_client_accounting')" label-for="trademarkInvoiceClientAccounting">
                        <b-input-group class="d-flex align-items-center">
                            <b-form-group label-for="trademark-agent-input" class="flex-grow-1 mb-0">
                                <v-select id="trademarkInvoiceClientAccounting" v-model="trademarkInvoiceClientSelect" :options="trademarkInvoiceClientOption" @input="setClientIdWithInvoiceClientCheck" label="clientName" @search="onInvoiceClientAccountingSearch">
                                </v-select>
                            </b-form-group>
                            <b-input-group-append>
                                <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                    <template #button-content>
                                        <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                    </template>
                                    <b-dropdown-item @click="getClientInfo(0)">
                                        <span>{{this.$t('others.add_client')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getAgentNoteInfo(trademarkInvoiceClientSelect.clientAccountingId)">
                                        <span>{{this.$t('others.show_note')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getClientInfo(trademarkInvoiceClientSelect.clientId)">
                                        <span>{{this.$t('others.show_agent')}}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab active :title=" $t('trademark.owner')" style="width:100%;">
                            <b-card-text>
                                <trademark-owner-list :getClientInfo="getClientInfo" :getTrademarkList="getTrademarkList" :trademarkId="this.id"></trademark-owner-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('trademark.responsible')" style="width:100%;">
                            <b-card-text>
                                <trademark-responsible-list :trademarkId="this.id" :clientId="this.clientId"></trademark-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('trademark.priority')" style="width:100%;">
                            <b-card-text>
                                <trademark-priority-list :getTrademarkList="getTrademarkList" :trademarkId="this.id"></trademark-priority-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('trademark.vienna_classes')" style="width:100%;">
                            <b-card-text>
                                <trademark-vienna-classes-list :getTrademarkList="getTrademarkList" :trademarkId="this.id"></trademark-vienna-classes-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('trademark.nice_class')" style="width:100%;">
                            <b-card-text>
                                <trademark-nice-class-list :getTrademarkList="getTrademarkList" :trademarkId="this.id"></trademark-nice-class-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('trademark.note')" style="width:100%;">
                            <b-card-text>
                                <trademark-note-list :getTrademarkList="getTrademarkList" :trademarkId="this.id"></trademark-note-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('trademark.renewals')" style="width:100%;">
                            <b-card-text>
                                <trademark-renewals-list :getTrademarkList="getTrademarkList" :trademarkId="this.id"></trademark-renewals-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('trademark.history')" style="width:100%;">
                            <b-card-text>
                                <trademark-history-list :getTrademarkList="getTrademarkList" :trademarkId="this.id"></trademark-history-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('trademark.objection_to_us')" style="width:100%;">
                            <b-card-text>
                                <trademark-objection-to-us-list :getTrademarkList="getTrademarkList" :trademarkId="this.id"></trademark-objection-to-us-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('trademark.provisional')" style="width:100%;">
                            <b-card-text>
                                <trademark-provisional-list :getTrademarkList="getTrademarkList" :trademarkId="this.id"></trademark-provisional-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('trademark.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="2"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.job')" style="width:100%;">
                            <b-card-text>
                                <job-list :recordId="this.id" :moduleId="2" :recordNo="this.trademarkRefCode"></job-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="2"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getTrademarkList: {
            type: Function
        },
        generalData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            trademarkRefCode: '',
            name: '',
            nameEn: '',
            instructionDate: null,
            yidkDate: null,
            applicationDate: null,
            bulletinDate: null,
            emendationDate: null,
            registerDate: null,
            priorityDocumentDate: null,
            nextRenewalDate: null,
            priorityDocumentDeadlineDate: null,
            trademarkApplicationTypeSelect: 0,
            trademarkApplicationOption: [],
            trademarkTypeSelect: 1,
            trademarkTypeOption: [],
            isTakeOver: false,
            isTransformation: false,
            isReplacement: false,
            isFamousBrand: false,
            bulletinNo: 0,
            applicationNo: '',
            bulletinPage: 0,
            registerNo: '',
            firstRegisterNo: '',
            trademarkAgentSelect: 0,
            trademarkAgentOption: [],
            agentReferenceNo: '',
            hiddenControl: true,
            wordTemplateList: [],
            trademarkInvoiceClientSelect: 0,
            trademarkInvoiceClientOption: [],
            mailTemplateList: [],
            image: null,
            invoicesToBePaidForThisJob: 0,
            invoicesToBePaid: 0,
            allInvoices: 0,
            allInvoicesRelatedToThisJob: 0,
            recordNo: null,
            niceClass: '',
            trademarkStatus: '',
            clientAccountingId: 0,
            trademarkDetailObject: []
        }
    },
    methods: {
        getTrademarkDetail() {
            this.$refs.trademarkDetailFormModal.showInfo(this.trademarkDetailObject)
        },
        getClientInvoiceReport(type) {
            this.$refs.clientInvoiceReportListCallForm.showInfo(type, this.clientAccountingId, 2, this.id)
        },
        uploadImage(event) {
            var input = event.target;
            if (this.id != 0) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.image = e.target.result;
                        var base64 = this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                        const data = {
                            fileName: this.trademarkId,
                            base64: base64
                        }
                        const controller = {
                            name: 'Trademark',
                            actionName: 'SaveTrademarkLogo'
                        };
                        const payload = {
                            data: data,
                            controller: controller
                        };
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.getTrademarkList()
                                this.$PleaseClickSave()
                            }
                        })
                    }
                    reader.readAsDataURL(input.files[0]);
                }
            } else if (this.id == 0) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.image = e.target.result;
                    };
                    reader.readAsDataURL(input.files[0]);
                }
            }
        },
        createLogo() {
            const data = {
                id: this.id,
                name: this.name
            }
            const controller = {
                name: 'Trademark',
                actionName: 'CreateTrademarkLogo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.getTrademarkList()
                    this.$SaveAlert()
                    this.image = 'data:image/jpeg;base64,' + response.data.base64
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        async openBulletin() {
            this.$refs.bulletinInfo.showInfo(this.bulletinNo.toString(), this.bulletinPage, 3)
        },
        onInvoiceClientAccountingSearch(invoiceClientAccountingSearch, loading) {
            if (invoiceClientAccountingSearch.length) {
                loading(true);
                this.invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, this);
            }
        },
        invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, vm) {

            if (invoiceClientAccountingSearch.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: invoiceClientAccountingSearch
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkInvoiceClientOption = response.data.clientList
                    loading(false)
                })
            }
        },
        downloadLogo() {
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Trademark',
                actionName: 'DownloadTrademarkLogo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    const link = document.createElement('a')
                    link.href = 'data:image/jpeg;base64,' + response.data.base64
                    link.download = this.trademarkRefCode + '.jpg'
                    link.click()
                    this.getTrademarkList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteForm.getNoteListByAccountingId(clientAccountingId)
        },
        async getClientInfo(clientId) {
            this.$refs.clientForm.showInfo(clientId)
        },
        async getReminderInfo() {
            this.$refs.reminderForm.showInfo(0, this.id, 2)
        },
        setClientId(event) {
            this.clientId = event.clientId
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        clear() {
            this.image = null
        },
        showInfo(id) {
            this.trademarkDetailObject = []
            this.$refs['trademarkFormModal'].show()
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Trademark'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.trademarkDetailObject.push(response.data.trademark)
                    this.name = response.data.trademark.name
                    this.nameEn = response.data.trademark.nameEn
                    this.trademarkApplicationTypeSelect = response.data.trademark.trademarkApplicationTypeId
                    this.trademarkApplicationOption = response.data.trademarkApplicationTypeList
                    if (response.data.trademark.trademarkTypeId == 0) {
                        this.trademarkTypeSelect = 1
                    } else {
                        this.trademarkTypeSelect = response.data.trademark.trademarkTypeId
                    }
                    this.trademarkTypeOption = response.data.trademarkTypeList
                    this.isTakeOver = response.data.trademark.isTakeOver
                    this.isTransformation = response.data.trademark.isTransformation
                    this.isReplacement = response.data.trademark.isReplacement
                    this.isFamousBrand = response.data.trademark.isFamousBrand
                    this.instructionDate = response.data.trademark.instructionDate
                    this.yidkDate = response.data.trademark.yidkDate
                    this.applicationNo = response.data.trademark.applicationNo
                    this.applicationDate = response.data.trademark.applicationDate
                    this.bulletinDate = response.data.trademark.bulletinDate
                    this.bulletinNo = response.data.trademark.bulletinNo
                    this.bulletinPage = response.data.trademark.bulletinPage
                    this.emendationDate = response.data.trademark.emendationDate
                    this.registerNo = response.data.trademark.registerNo
                    this.firstRegisterNo = response.data.trademark.firstRegisterNo
                    this.registerDate = response.data.trademark.registerDate
                    this.priorityDocumentDate = response.data.trademark.priorityDocumentDate
                    this.nextRenewalDate = response.data.trademark.nextRenewalDate
                    this.priorityDocumentDeadlineDate = response.data.trademark.priorityDocumentDeadline
                    this.trademarkRefCode = response.data.trademark.recordNo
                    this.agentReferenceNo = response.data.trademark.agentReferenceNo
                    this.wordTemplateList = response.data.wordTemplateList
                    var nonBase64Image = 'data:image/jpeg;base64,'.concat(response.data.trademark.logo)
                    this.image = nonBase64Image
                    this.trademarkApplicationOption.splice(0, 0, this.$nullSelected())
                    this.trademarkTypeOption.splice(0, 0, this.$nullSelected())
                    this.mailTemplateList = response.data.mailTemplateList
                    this.niceClass = response.data.trademark.niceClass
                    this.trademarkStatus = response.data.trademark.trademarkStatus
                    if (response.data.trademark.clientAccountingId > 0) {
                        this.clientAccountingId = response.data.trademark.clientAccountingId
                        const clientAccountingData = {
                            clientAccountingId: response.data.trademark.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.trademarkAgentOption = clientAccountingResponse.data.clientList
                            this.trademarkAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.trademarkAgentOption = []
                        this.trademarkAgentSelect = 0;
                    }

                    if (response.data.trademark.invoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.trademark.invoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.trademarkInvoiceClientOption = clientAccountingResponse.data.clientList
                            this.trademarkInvoiceClientSelect = clientAccountingResponse.data.clientList[0]
                        })
                    } else {
                        this.trademarkInvoiceClientOption = []
                        this.trademarkInvoiceClientSelect = 0;
                    }
                    if (this.id > 0) {
                        let accountingId = 0
                        if (response.data.trademark.clientAccountingId > 0)
                            accountingId = response.data.trademark.clientAccountingId
                        if (response.data.trademark.invoiceClientAccountingId > 0)
                            accountingId = response.data.trademark.invoiceClientAccountingId

                        const invoiceData = {
                            clientAccountingId: accountingId,
                            moduleId: 2,
                            recordId: this.id
                        }
                        const invoiceController = {
                            name: 'ClientAccounting',
                            actionName: 'GetInvoiceCountByClientAccountId'
                        }
                        const invoicePayload = {
                            data: invoiceData,
                            controller: invoiceController
                        }
                        this.$store.dispatch('moduleAuth/customService', invoicePayload).then((invoiceResponse) => {
                            this.invoicesToBePaid = invoiceResponse.data.invoicesToBePaidCount
                            this.allInvoices = invoiceResponse.data.allInvoiceCount
                            this.allInvoicesRelatedToThisJob = invoiceResponse.data.allInvoicesRelatedToThisJobCount
                            this.invoicesToBePaidForThisJob = invoiceResponse.data.invoicesToBePaidForThisJobCount
                        })

                    } else {
                        this.invoicesToBePaid = 0
                        this.allInvoices = 0;
                        this.allInvoicesRelatedToThisJob = 0;
                        this.invoicesToBePaidForThisJob = 0;
                    }
                } else {
                    this.$bvModal.hide('trademarkFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadTrademarkWordFile(documentId, name) {
            const data = {
                moduleId: 2,
                documentId: documentId,
                trademarkId: this.id
            }
            const controller = {
                name: 'Trademark',
                actionName: 'DownloadTrademarkWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            if (this.trademarkInvoiceClientSelect === null) {
                this.invoiceClientId = 0
            } else {
                this.invoiceClientId = this.trademarkInvoiceClientSelect.clientAccountingId
            }
            const data = {
                id: this.id,
                name: this.name,
                nameEn: this.nameEn,
                trademarkRefCode: this.trademarkRefCode,
                trademarkApplicationTypeId: this.trademarkApplicationTypeSelect,
                trademarkTypeId: this.trademarkTypeSelect,
                isTakeOver: this.isTakeOver,
                isTransformation: this.isTransformation,
                isReplacement: this.isReplacement,
                isFamousBrand: this.isFamousBrand,
                instructionDate: this.instructionDate,
                yidkDate: this.yidkDate,
                applicationNo: this.applicationNo,
                applicationDate: this.applicationDate,
                bulletinDate: this.bulletinDate,
                emendationDate: this.emendationDate,
                registerNo: this.registerNo,
                firstRegisterNo: this.firstRegisterNo,
                registerDate: this.registerDate,
                priorityDocumentDate: this.priorityDocumentDate,
                priorityDocumentDeadline: this.priorityDocumentDeadlineDate,
                agentReferenceNo: this.agentReferenceNo,
                clientAccountingId: this.trademarkAgentSelect.clientAccountingId,
                invoiceClientAccountingId: this.invoiceClientId,
                logo: this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
            }
            const controller = {
                name: 'Trademark'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getTrademarkList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.trademarkId
                                this.trademarkRefCode = response.data.recordNo
                                this.hiddenControl = false
                            }
                            //I changed the save  order of Trademark - Trademark Logo .
                            //16.09.2024 Nevzat.
                            var base64 = this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                            const data = {
                                fileName: this.trademarkId,
                                base64: base64
                            }
                            const controller = {
                                name: 'Trademark',
                                actionName: 'SaveTrademarkLogo'
                            }
                            const payload = {
                                data: data,
                                controller: controller
                            }
                            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                                if (response.data.resultStatus == true) {
                                    this.getTrademarkList()
                                } else {
                                    this.$CustomWarningAlert(response.data.resultMessage)
                                }
                            })
                        } else {
                            this.$CustomWarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        prepareTrademarkMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                trademarkId: this.id
            }
            const controller = {
                name: 'Trademark',
                actionName: 'PrepareTrademarkMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        setClientIdWithInvoiceClientCheck(event) {
            if (event != null) {
                if (this.trademarkInvoiceClientSelect.forInvoice == false) {
                    this.clientId = null;
                    this.trademarkInvoiceClientSelect = null;
                    this.$ForInvoiceAlert()
                } else {
                    this.clientId = event.clientId
                }
            } else {
                this.clientId = null;
            }
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] body.dark-layout .b-calendar-grid.form-control .small {
    background: #161d31;
    display: none !important;
}

.b-calendar .b-calendar-grid-help {
    color: #6e6b7b !important;
    display: none;
}

.uploading-image {
    display: flex;
    width: 15em;
    height: 10em;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
