<template>
<div>
    <mail-history-form :getMailHistoryList="getMailHistoryList" ref="mailHistoryFormModal"></mail-history-form>
    <b-row>
        <b-col>
            <b-card>
                <b-row>
                    <b-col>
                        <b-card-title style="text-align: right;">{{$t('others.search_criteria')}}</b-card-title>
                    </b-col>
                    <b-col>
                        <b-button style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                        <b-button style="float:right;" class="ml-1" size="sm" variant="outline-primary" v-on:keyup.enter="getMailHistoryList()" @click="getMailHistoryList()">{{ $t('others.list') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-3">
                        <label for="mail-history-mail-to-input">{{ $t('mailHistory.mail_to') }}</label>
                        <div class="d-flex align-items-center">
                            <b-form-input size="sm" v-model="mailTo" :placeholder="$t('mailHistory.mail_to')" type="text" class="d-inline-block" />
                        </div>
                    </b-col>
                    <b-col class="col-lg-3">
                        <label for="mail-history-subject-input">{{ $t('mailHistory.subject') }}</label>
                        <div class="d-flex align-items-center">
                            <b-form-input size="sm" v-model="subject" :placeholder="$t('mailHistory.subject')" type="text" class="d-inline-block" />
                        </div>
                    </b-col>
                    <b-col class="col-lg-3">
                        <label for="mail-history-module-input">{{ $t('mailHistory.module') }}</label>
                        <b-form-group>
                            <b-form-select v-model="moduleSelect" size="sm" value-field="id" text-field="name" :options="moduleOption" />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-3">
                        <label for="mail-history-account-input">{{ $t('mailHistory.account') }}</label>
                        <b-form-group>
                            <b-form-select v-model="mailAccountSelect" size="sm" value-field="id" text-field="displayName" :options="mailAccountOption" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>                     
                    <b-col class="col-lg-3 mt-1">
                        <label for="mail-history-explanation-input">{{ $t('mailHistory.explanation') }}</label>
                        <b-form-group>
                            <b-form-select v-model="mailTemplateSelect" size="sm" value-field="id" text-field="explanation" :options="mailTemplateOption" />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-3 mt-1">
                        <label for="mail-history-date-start-input">{{ $t('mailHistory.date_start') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('mailHistory.date_start')" size="sm" v-model="dateStart" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-3 mt-1">
                        <label for="mail-history-date-end-input">{{ $t('mailHistory.date_end') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('mailHistory.date_end')" size="sm" v-model="dateEnd" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="mailHistoryTable" ref="mailHistoryTable" :columns="columns" :rows="rows" :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }" styleClass="vgt-table condensed tableSize" :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }" :pagination-options="{
                enabled: true,
                perPage: pageLength
              }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'module'" class="text-nowrap">
                {{ $t('mailHistory.module') }}
            </span>
            <span v-else-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('mailHistory.record_no') }}
            </span>
            <span v-else-if="props.column.field === 'subject'" class="text-nowrap">
                {{ $t('mailHistory.subject') }}
            </span>
            <span v-else-if="props.column.field === 'mailAccount'" class="text-nowrap">
                {{ $t('mailHistory.mail_account') }}
            </span>
            <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                {{ $t('mailHistory.date') }}
            </span>
            <span v-else-if="props.column.field === 'mailTemplate'" class="text-nowrap">
                {{ $t('mailHistory.mail_template') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- Pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                     
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getMailHistoryList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    watch: {
        dateStart(newValue) {
            if(newValue == ''){
                this.dateStart = null
            }
        },
        dateEnd(newValue) {
            if(newValue == ''){
                this.dateEnd = null
            }
        },
    },
    data() {
        return {
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('mailHistory.module'),
                    field: `module`,
                },
                {
                    label: this.$t('mailHistory.record_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('mailHistory.subject'),
                    field: `subject`,
                },
                {
                    label: this.$t('mailHistory.mail_account'),
                    field: `mailAccount`,
                },
                {
                    label: this.$t('mailHistory.date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('mailHistory.mail_template'),
                    field: `mailTemplate`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            mailTo: '',
            subject: '',
            moduleSelect: 0,
            moduleOption: [],
            mailTemplateSelect: 0,
            mailTemplateOption: [],
            mailAccountSelect: 0,
            mailAccountOption: [],
            searchTerm: '',
            dateStart: null,
            dateEnd: null,
            total: 0,
            pageLength: 10,
        }
    },
    mounted() {
        this.getMailHistoryList()
    },
    methods: {
        async getInfo(id) {
            this.$refs.mailHistoryFormModal.showInfo(id)
        },
        getMailHistoryList() {
            this.rows = []
            const controller = {
                name: 'MailHistory'
            }
            const data = {
                dateStart: this.dateStart,
                dateEnd: this.dateEnd,
                moduleId: this.moduleSelect,
                mailTemplateId: this.mailTemplateSelect,
                mailAccountId: this.mailAccountSelect,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    this.mailTemplateOption = response.data.mailTemplateList
                    this.moduleOption = response.data.moduleList
                    this.mailAccountOption = response.data.mailAccountList
                    this.mailTemplateOption.splice(0, 0, this.$nullSelected('explanation'))
                    this.moduleOption.splice(0, 0, this.$nullSelected())
                    this.mailAccountOption.splice(0, 0, this.$nullSelected('displayName'))
                    response.data.mailHistoryList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'MailHistory',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Marka", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
}

.tableSizeTrademark {
    font-size: 14px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.featherIconReSize {
    width: 12px;
    height: 12px;
}
</style>
