<template>
<div>
    <patent-bulletin-analyse-report-form :getPatentBulletinAnalyseReportList="getPatentBulletinAnalyseReportList" ref="patentBulletinAnalyseFormModal"></patent-bulletin-analyse-report-form>
    <b-row>
        <b-col>
            <b-card>
                <b-row>
                    <b-col>
                        <b-card-title style="text-align: right;">{{$t('others.search_criteria')}}</b-card-title>
                    </b-col>
                    <b-col>
                        <b-button style="float:right;" size="sm" variant="outline-primary" v-on:keyup.enter="getPatentBulletinAnalyseReportList()" @click="getPatentBulletinAnalyseReportList()">{{ $t('translate.list') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <label for="patentBulletinAnalyseReport-no-select">{{ $t('patentBulletinAnalyseReport.bulletin_select') }}</label>
                        <b-form-select v-model="bulletinSelect" size="sm" value-field="id" text-field="no" :options="bulletinOption" />
                    </b-col>
                    <b-col class="col-md-3">
                        <label for="patentBulletinAnalyseReport-patentType-select">{{ $t('patentBulletinAnalyseReport.patent_type') }}</label>
                        <b-form-select v-model="patentTypeSelect" size="sm" value-field="id" text-field="name" :options="patentTypeOption" />
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="patentBulletinAnalyseReport-patentName-input">{{ $t('patentBulletinAnalyseReport.patent_name') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="patentBulletinAnalyseReport-patentName-input" v-model="patentName" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="patentBulletinAnalyseReport-abstract-input">{{ $t('patentBulletinAnalyseReport.abstract') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="patentBulletinAnalyseReport-abstract-input" v-model="abstract" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="patentBulletinAnalyseReport-iPCCode-input">{{ $t('patentBulletinAnalyseReport.iPC_Code') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="patentBulletinAnalyseReport-iPCCode-input" v-model="iPCCode" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="patentBulletinAnalyseReport-holder-input">{{ $t('patentBulletinAnalyseReport.holder') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="patentBulletinAnalyseReport-holder-input" v-model="holder" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="patentBulletinAnalyseReport-inventor-input">{{ $t('patentBulletinAnalyseReport.inventor') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="patentBulletinAnalyseReport-inventor-input" v-model="inventor" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="patentBulletinAnalyseReport-attorney-input">{{ $t('patentBulletinAnalyseReport.attorney') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="patentBulletinAnalyseReport-attorney-input" v-model="attorney" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <label for="patentBulletinAnalyseReport-origin-select">{{ $t('patentBulletinAnalyseReport.origin') }}</label>
                        <b-form-select size="sm" v-model="originSelect" value-field="id" text-field="name" :options="originOption" />
                    </b-col>
                    <b-col class="col-md-3">
                        <label for="patentBulletinAnalyseReport-my-patent-list-input">{{ $t('patentBulletinAnalyseReport.my_patent_list') }}</label>
                        <br>
                        <b-form-checkbox class="custom-control-success" name="patentBulletinAnalyseReport-my-patent-list-input" id="patentBulletinAnalyseReport-my-patent-list-input" v-model="onPatentList" switch>
                            <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                            </span>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="patentBulletinAnalyseReportTable" ref="patentBulletinAnalyseReportTable" styleClass="vgt-table condensed tableSize" :columns="columns" :rows="rows" :search-options="{
        enabled: true,
        externalQuery: searchTerm
    }" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }" :pagination-options="{
        enabled: true,
        perPage: pageLength,
    }" theme="my-theme">
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field ==='process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'patentName'">
                <span style="font-size: small" class="mb-05">
                    {{ props.row.patentName }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'holderList'">
                <b-row v-for="patentHolder in props.row.holderList" v-bind:key="patentHolder.id" style="font-size: x-small;">
                    <tr v-if="props.row.holderList.length != 1">
                        <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                        <span style=" font-size: small" class="mb-05">
                            {{patentHolder.name }}<br>
                    <tr v-if="patentHolder.address !== ''">
                        {{patentHolder.address}}
                    </tr>
            </span>
            </tr>
            <tr v-else-if="props.row.holderList.length == 1">
                <span style=" font-size: small" class="mb-05">
                    {{patentHolder.name }}<br>
            <tr v-if="patentHolder.address !== ''">
                {{patentHolder.address}}
            </tr>
            </span>
            </tr>
            </b-row>
            </span>
            <span v-else-if="props.column.field === 'inventorList'">
                <b-row v-for="inventor in props.row.inventorList" v-bind:key="inventor.id" style="font-size: x-small;">
                    <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                    <span>{{ inventor.name }} </span> <br />
                </b-row>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title=" $t('others.show')" v-b-modal.modal-top variant="info" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                    </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getPatentBulletinAnalyseReportList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [{
                    label: this.$t("patentBulletinAnalyseReport.application_no"),
                    field: "applicationNo",
                },
                {
                    label: this.$t("patentBulletinAnalyseReport.application_date"),
                    field: `applicationDate`,
                    formatFn: this.$formatFn,
                },
                {
                    label: this.$t("patentBulletinAnalyseReport.bulletin_no"),
                    field: "bulletinNo",
                },
                {
                    label: this.$t("patentBulletinAnalyseReport.patent_name"),
                    field: "patentName",
                },
                {
                    label: this.$t("patentBulletinAnalyseReport.holder_name"),
                    field: "holderList",
                    width: '20em'
                },
                {
                    label: this.$t("patentBulletinAnalyseReport.attorney"),
                    field: "attorney",
                },
                {
                    label: "Process",
                    field: "process",
                    width: "10em",
                },
            ],
            rows: [],
            searchTerm: "",
            bulletinOption: [],
            bulletinSelect: 0,
            patentName: null,
            iPCCode: null,
            abstract: null,
            attorney: null,
            holder: null,
            inventor: null,
            originSelect: 0,
            originOption: [{
                    id: 0,
                    name: this.$t('translate.select')
                },
                {
                    id: 1,
                    name: this.$t('patentBulletinAnalyseReport.domestic')
                },
                {
                    id: 2,
                    name: this.$t('patentBulletinAnalyseReport.abroad')
                },
            ],
            pageNo: 1,
            pageLength: 10,
            total: 0,
            domestic: false,
            abroad: false,
            patentTypeOption: [],
            patentTypeSelect: 0,
            onPatentList: false,
            lastPatentBulletinId: null,
            
        };
    },
    mounted() {
        this.lastPatentBulletinId = this.$route.query.lastPatentBulletinId;
        if (this.lastPatentBulletinId != null) {
            this.onPatentList = true
        } else this.onPatentList = false
        this.getPatentBulletinAnalyseReportList();
    },
    methods: {
        async getInfo(id) {
            this.$refs.patentBulletinAnalyseFormModal.showInfo(id);
        },
        getPatentBulletinAnalyseReportList() {
            if (this.originSelect == 1) {
                this.domestic = true;
                this.abroad = false
            } else if (this.originSelect == 2) {
                this.abroad = true;
                this.domestic = false;

            }
            const data = {
                bulletinId: (this.lastPatentBulletinId > 0) ? this.lastPatentBulletinId : this.bulletinSelect,
                title: this.patentName,
                iPCCode: this.iPCCode,
                abstract: this.abstract,
                attorney: this.attorney,
                patentInventor: this.inventor,
                abroad: this.abroad,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                domestic: this.domestic,
                applicationNo: this.applicationNo,
                patentHolder: this.holder,
                patentTypeId: this.patentTypeSelect,
                onPatentList: this.onPatentList
            }
            this.rows = [];
            const controller = {
                name: "Bulletin",
                actionName: 'GetPatentBulletinAnalyseReport'
            }
            const payload = {
                data: data,
                controller: controller,
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.patentBulletinAnalyseList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.total = response.data.total
                    this.bulletinOption = response.data.patentBulletinList
                    this.patentTypeOption = response.data.patentTypeList
                    this.patentTypeOption.splice(0, 0, this.$nullSelected())
                    this.bulletinOption.splice(0, 0, this.$nullSelected('no'))

            
                    if (this.lastPatentBulletinId > 0) {
                        this.bulletinSelect = this.lastPatentBulletinId
                    } else {
                        this.bulletinSelect = 0
                    }
                }
            })

        },
    },
};
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
