<template>
    <div>
      <b-modal
        id="employeeNoteFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="employeeNoteFormModal"
        :title=" $t('employee.employee_note_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
        <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col>
                <validation-provider
                  :name=" $t('employeeNotePermission.employee_name')"
                  :rules="{ excluded:0}" 
                  v-slot="{ errors }"
                >
                <label for="employeenotepermission-employeename-input">{{ $t('employeeNotePermission.employee_name') }}</label>
                <b-input-group>
                  <b-form-select
                    :state="errors.length > 0 ? false:null"
                    v-model="employeeNoteSelect"
                    value-field="id"
                    size="sm"
                    text-field="fullName"
                    :options="employeeNoteOption"/>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small> 
                </validation-provider>
                </b-col>
              </b-row>
            </b-form>
        </validation-observer>  
      </b-modal> 
    </div>
</template>
<script>
export default {
  props: {
    getEmployeeNoteList: {type: Function}
  },
  data() {
    return {
        id: 0,
        employeeNoteSelect: 0,
        employeeNoteOption: [],
        employeeNoteList:'',
        employeeId:0
        }
    },
  methods: {
    showInfo(id, employeeId){
      this.$refs['employeeNoteFormModal'].show()
      this.id = id
      this.employeeId = employeeId
      const data = {id: this.id}
      const controller = {name : 'EmployeeNotePermission'}
      const payload = {data: data, controller: controller}
      this.$store.dispatch('moduleAuth/getInfo',payload).then((response) => {
           if(response.data.resultStatus == true){
               this.employeeNoteOption = response.data.employeeList
               this.employeeNoteSelect = response.data.employeeNotePermission.employeeId
               this.employeeNoteOption.splice(0, 0, this.$nullSelected("fullName"))
               this.getEmployeeNoteList();
           } else {
               this.$bvModal.hide('employeeNoteFormModal')
               this.$WarningAlert(response.data.resultMessage)
           }  
       }) 
    },
    addOrUpdate() {
      const data = {id: this.id, managerId: this.employeeId, employeeId: this.employeeNoteSelect}
      const controller = {name : 'EmployeeNotePermission'}
      const payload = {data: data, controller: controller}
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
              this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                if(response.data.resultStatus == true){
                  this.$bvModal.hide('employeeNoteFormModal')
                  this.getEmployeeNoteList();
                  this.$SaveAlert()
                } else {
                  this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
      })
    },
  }
    }
</script>
</script>
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}
[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}
.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}
[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem!important;
    margin-top: -1.5rem!important;
    background-color: #ea5455!important;
    border-radius: 0.358rem!important;
    left: 20px!important
}
.tableSize {
  font-size: 13px!important;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}
</style>