<template>
    <div>
      <!-- modal -->
      <b-modal
        id="trademarkSearchDatabaseFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="trademarkSearchDatabaseFormModal"
        :title=" $t('trademarkSearchDatabase.trademark_search_database_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
      <validation-observer ref="simpleRules">
      <b-row>
        <b-col class="col-lg-6">
          <b-form>
            <label for="trademark-search-database-name-input">{{ $t('trademarkSearchDatabase.name') }}</label>
              <b-form-group>
                <validation-provider #default="{ errors }" :name=" $t('trademarkSearchDatabase.name')" rules="required">
                  <b-form-input id="trademark-search-database-name-input" v-model="name" size="sm" maxlength="100" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
          </b-form>
        </b-col>
        <b-col class="col-lg-6">
          <b-form>
            <label for="trademark-search-database-name-en-input">{{ $t('trademarkSearchDatabase.name_en') }}</label>
              <b-form-group>
                <validation-provider #default="{ errors }" :name=" $t('trademarkSearchDatabase.name_en')" rules="required" >
                  <b-form-input id="trademark-search-database-name-en-input"  v-model="nameEn" size="sm" maxlength="100" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getTrademarkSearchDatabaseList: {type: Function},
    },
    data() {
      return {
        id: 0,
        name: '',
        nameEn: ''
      }
    },
    methods: {
      showInfo(id) { 
        this.$refs['trademarkSearchDatabaseFormModal'].show() 
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'TrademarkSearchDatabase'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.name = response.data.trademarkSearchDatabase.name
                this.nameEn = response.data.trademarkSearchDatabase.nameEn
            } else {
                this.$bvModal.hide('trademarkSearchDatabaseFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, name: this.name, nameEn: this.nameEn}
        const controller = {name : 'TrademarkSearchDatabase'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('trademarkSearchDatabaseFormModal')
                    this.getTrademarkSearchDatabaseList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  