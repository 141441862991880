<template>
<div>
    <!-- modal -->
    <b-modal id="translatorContactInformationFormModal" centered size="lg" no-close-on-backdrop ref="translatorContactInformationFormModal" :title=" $t('translatorContactInformation.add_contact_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-12">
                    <b-form>
                        <validation-provider :name=" $t('translatorContactInformation.contact_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                            <label for="translator-contact-type-input">{{ $t('translatorContactInformation.contact_type') }}</label>
                            <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="contactTypeSelect" value-field="id" text-field="name" :options="contactTypeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12 mt-1">
                    <b-form>
                        <label for="contact-value-input">{{ $t('translatorContactInformation.value') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('translatorContactInformation.value')" rules="required" maxlength="50">
                                <b-form-input size="sm" id="contact-value-input" v-model="value" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

  
<script>
export default {
    props: {
        getTranslatorContactInformationList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            translatorId : 0,
            contactTypeSelect: 0,
            contactTypeOption: [],
            value: '',
        }
    },
    methods: {
        showInfo(id, translatorId) {
            this.$refs['translatorContactInformationFormModal'].show() 
            this.id = id
            this.translatorId = translatorId;
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TranslatorContactInformation'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.contactTypeOption = response.data.contactTypeList
                    this.contactTypeSelect = response.data.translatorContactInformation.contactTypeId
                    this.contactTypeOption.splice(0, 0, this.$nullSelected())
                    this.value = response.data.translatorContactInformation.value
                } else {
                    this.$bvModal.hide('translatorContactInformationFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                translatorId: this.translatorId,
                value: this.value,
                contactTypeId: this.contactTypeSelect
            }
            const controller = {
                name: 'TranslatorContactInformation'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('translatorContactInformationFormModal')
                            this.getTranslatorContactInformationList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
