var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"mailToReadFormModal",attrs:{"id":"mailToReadFormModal","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('mailToRead.mail_to_read_info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"close":function($event){return _vm.clear()},"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":_vm.$t('mailToRead.module'),"rules":{ excluded: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"mailToRead-module-input"}},[_vm._v(_vm._s(_vm.$t('mailToRead.module')))]),_c('b-form-select',{attrs:{"size":"sm","state":errors.length > 0 ? false : null,"value-field":"id","text-field":"name","options":_vm.moduleOption},on:{"change":function($event){return _vm.moduleChange()}},model:{value:(_vm.moduleSelect),callback:function ($$v) {_vm.moduleSelect=$$v},expression:"moduleSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":_vm.$t('mailToRead.document_type'),"rules":{ excluded: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"mailToRead-documentType-input"}},[_vm._v(_vm._s(_vm.$t('mailToRead.document_type')))]),_c('b-form-select',{attrs:{"size":"sm","state":errors.length > 0 ? false : null,"value-field":"id","text-field":"name","options":_vm.documentTypeOption},on:{"change":function($event){return _vm.moduleChange()}},model:{value:(_vm.documentTypeSelect),callback:function ($$v) {_vm.documentTypeSelect=$$v},expression:"documentTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":_vm.$t('mailToRead.document_type_detail'),"rules":{ excluded: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"mailToRead-documentTypeDetail-input"}},[_vm._v(_vm._s(_vm.$t('mailToRead.document_type_detail')))]),_c('b-form-select',{attrs:{"size":"sm","state":errors.length > 0 ? false : null,"value-field":"id","text-field":"name","options":_vm.documentTypeDetailOption},on:{"change":function($event){return _vm.moduleChange()}},model:{value:(_vm.documentTypeDetailSelect),callback:function ($$v) {_vm.documentTypeDetailSelect=$$v},expression:"documentTypeDetailSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1"},[_c('label',{attrs:{"for":"mailToRead-sender-input"}},[_vm._v(_vm._s(_vm.$t('mailToRead.sender_mail')))]),_c('validation-provider',{attrs:{"name":_vm.$t('mailToRead.sender_mail'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"mailToRead-sender-input","maxlength":"100","state":errors.length > 0 ? false:null},model:{value:(_vm.senderMail),callback:function ($$v) {_vm.senderMail=$$v},expression:"senderMail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":_vm.$t('mailToRead.mail_read_type'),"rules":{ excluded: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"mailToRead-mail-read-type-input"}},[_vm._v(_vm._s(_vm.$t('mailToRead.mail_read_type')))]),_c('b-form-select',{attrs:{"size":"sm","value-field":"id","text-field":"type","options":_vm.mailReadTypeOption},model:{value:(_vm.mailReadTypeSelect),callback:function ($$v) {_vm.mailReadTypeSelect=$$v},expression:"mailReadTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":_vm.$t('mailToRead.mail_content'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"mailToRead-mail-content-input"}},[_vm._v(_vm._s(_vm.$t('mailToRead.mail_content')))]),_c('b-form-group',[_c('b-form-textarea',{attrs:{"id":"mailToRead-mail-content-input","size":"sm","maxlength":"250","rows":"3","state":errors.length > 0 ? false:null},model:{value:(_vm.mailContent),callback:function ($$v) {_vm.mailContent=$$v},expression:"mailContent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }