<template>
<div>
    <e-invoice-form :statusSelect="this.statusSelect" :getEInvoiceList="getEInvoiceList" ref="einvoiceFormModal"></e-invoice-form>
    <!-- <e-invoice-transfer-records-form :id="this.id" :getEInvoiceList="getEInvoiceList" ref="einvoiceTransferRecordsForm"></e-invoice-transfer-records-form> -->
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2 mb-1">
                <label for="e-invoice-vkn-input">{{ $t('einvoice.vkn') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="vkn" @change="getEInvoiceList()" :placeholder="$t('einvoice.vkn')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2 mb-1">
                <label for="e-invoice-title-input">{{ $t('einvoice.title') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="title" @change="getEInvoiceList()" :placeholder="$t('einvoice.title')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2 mb-1">
                <label for="e-invoice-status-input">{{ $t('einvoice.status') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-select id="e-invoice-status-input" v-model="statusSelect" @change="getEInvoiceList()" v-on:keyup.enter="getEInvoiceList()" size="sm" value-field="id" text-field="name" :options="statusOption" />
                </div>
            </b-col>
            <b-col class="col-md-6 mt-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button class="d-inline-block mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getEInvoiceList()" @click="getEInvoiceList()">{{ $t('others.list') }}</b-button>
                    <b-button size="sm" variant="outline-primary" @click="getTransferRecords(id)">{{ $t('einvoice.transfer_records') }}</b-button>
                </div>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table ref="eInvoiceTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
              enabled: true,
              externalQuery: searchTerm }" :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }" :pagination-options="{
              enabled: true,
              perPage:pageLength
            }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field ==='vkn'" class="text-nowrap">
                {{ $t('einvoice.vkn') }}
            </span>
            <span v-else-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('einvoice.title') }}
            </span>
            <span v-else-if="props.column.field === 'address'" class="text-nowrap">
                {{ $t('einvoice.address') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'address'">
                <span>
                    {{ props.row.citySubDivision + ' / ' + props.row.city }}
                </span>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title=" $t('others.pending_invoices')" v-b-modal.modal-top variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="getPendingInvoicesInfo(props.row.id)">
                        <feather-icon icon="MoveIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <!-- ,'100', '1000' 
                         Bu alanları ben kaldırdım - Nevzat Peker 11/07/2023 -->
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getEInvoiceList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

    
<script>
export default {
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            isActive: 0,
            vkn: '',
            title: '',
            id: 0,
            statusSelect: 0,
            statusOption: [{
                    id: 0,
                    name: this.$t('einvoice.waiting')
                },
                {
                    id: 1,
                    name: this.$t('einvoice.transfer')
                },
                {
                    id: 2,
                    name: this.$t('einvoice.not_transfer')
                }
            ],
            columns: [{
                    label: this.$t('einvoice.vkn'),
                    field: `vkn`,
                },
                {
                    label: this.$t('einvoice.title'),
                    field: `name`,
                },
                {
                    label: this.$t('einvoice.address'),
                    field: `address`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getEInvoiceList()
    },
    methods: {
        async getInfo(id) {
            this.$refs.einvoiceFormModal.showInfo(id)
        },
        async getPendingInvoicesInfo(id) {
            //ToDo Burası Doldurulacak
        },
        async getAccountingInfo(id) {
            //ToDo Burası Doldurulacak
        },
        getEInvoiceList() {
            this.rows = []
            const controller = {
                name: 'IncomeInvoiceClient',
                actionName: 'GetIncomeInvoiceClientListByFilter'
            }
            const data = {
                statusId: this.statusSelect,
                pageLength: this.pageLength,
                pageNo: this.pageNo
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.incomeInvoiceClientList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.total = response.data.total
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        },
    },
}
</script>

    
<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
