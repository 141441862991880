<template>
    <div>
        <!-- modal -->
        <b-modal id="employeeHourlyAdminAnnualLeaveRequestFormModal" centered size="lg" no-close-on-backdrop ref="employeeHourlyAdminAnnualLeaveRequestFormModal" :title=" this.adminAnnualLeaveStatusSelectName"
        :cancel-title=" $t('hourlyAdminAnnualLeave.decline')" :ok-title=" $t('hourlyAdminAnnualLeave.accept')" cancel-variant="outline-warning" @ok.prevent="approveHourlyAdminAnnualLeave(id,true)" @cancel.prevent="approveHourlyAdminAnnualLeave(id,false)">
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <b-col class="col-md-4 mt-1">
                            <b-form>
                                <label for="hourly-admin-annual-leave-employee-input">{{ $t('hourlyAdminAnnualLeave.employee') }}</label>
                                <b-form-group>
                                    <b-form-input size="sm" id="hourly-admin-annual-leave-employee-input" disabled v-model="employee" />
                                </b-form-group>
                            </b-form>
                        </b-col>
                        <b-col class="col-md-4 mt-1">
                            <b-form>
                                <label for="hourly-admin-annual-leave-department-input">{{ $t('hourlyAdminAnnualLeave.department') }}</label>
                                <b-form-group>
                                    <b-form-input size="sm" id="hourly-admin-annual-leave-department-input" v-model="department" disabled />
                                </b-form-group>
                            </b-form>
                        </b-col>
                        <b-col class="col-md-4 mt-1">
                            <b-form-group>
                                <label for="hourly-admin-annual-leave-leave-date">{{ $t('hourlyAdminAnnualLeave.leave_date') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="leaveDate"></CustomDatePicker>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-md-6 mt-1">
                            <label for="hourly-admin-annual-leave-request-starting-time">{{ $t('hourlyAdminAnnualLeave.starting_time') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="startingTime"></CustomDatePicker>
                        </b-col>
                        <b-col class="col-md-6 mt-1">
                            <label for="hourly-admin-annual-leave-request-ending-time">{{ $t('hourlyAdminAnnualLeave.ending_time') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="endingTime"></CustomDatePicker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-md-12 mt-1">
                            <label for="hourly-admin-annual-leave-request-description-input">{{ $t('hourlyAdminAnnualLeave.request_description') }}</label>
                            <validation-provider #default="{ errors }" :name=" $t('hourlyAdminAnnualLeave.request_description')">
                                <b-form-textarea size="sm" id="hourly-admin-annual-leave-request-description-input" v-model="requestDescription" disabled rows="3" max-rows="8" maxlength="250" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <!--ToDo Burası kaldırıldı, başlık olarak konuldu. Eğer başlık müşteri için efektif olmazsa, tekrar açılacak.-->
                    <!-- <b-row>
                        <b-col class="col-md-12 mt-1">
                            <b-form>
                                <label for="hourlyAnnualLeave-status-input">{{ $t('hourlyAnnualLeave.status') }}</label>
                                <b-form-select size="sm" v-model="annualLeaveStatusSelect" value-field="id" text-field="name" disabled :options="annualLeaveStatusOption" />
                            </b-form>
                        </b-col>
                    </b-row> -->
                    <b-row>
                        <b-col class="col-md-12 mt-1">
                            <label for="hourly-admin-annual-leave-approve-description-input">{{ $t('hourlyAdminAnnualLeave.approve_description') }}</label>
                            <validation-provider #default="{ errors }" :name=" $t('hourlyAdminAnnualLeave.approve_description')">
                                <b-form-textarea size="sm" id="hourly-admin-annual-leave-request-approve-description-input" v-model="approveDescription" rows="3" max-rows="8" maxlength="250" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <!-- <b-row>
                        <b-col class="col-md-6 mt-1">
                            <b-button v-b-tooltip.html :title="$t('hourlyAnnualLeave.decline')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" disable @click="approveHourlyAnnualLeave(id,false)">
                                {{ $t('hourlyAnnualLeave.decline') }}
                            </b-button>
                        </b-col>
                        <b-col class="col-md-6 mt-1">
                            <b-button v-b-tooltip.html :title="$t('hourlyAnnualLeave.accept')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" disable @click="approveHourlyAnnualLeave(id,true)">
                                {{ $t('hourlyAnnualLeave.accept') }}
                            </b-button>
                        </b-col>
                    </b-row> -->
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            getHourlyAdminAnnualLeaveList: {
                type: Function
            },
        },
        data() {
            return {
                id: 0,
                startingTime: '',
                endingTime: '',
                locale: this.$i18n.locale,
                leaveDate: null,
                requestDescription: '',
                approveDescription: '',
                labels: {
                    tr: {
                        labelHours: 'Saat',
                        labelMinutes: 'Dakika',
                        labelSeconds: 'Saniye',
                        labelIncrement: 'Arttır',
                        labelDecrement: 'Azalt',
                        labelSelected: 'Seçiniz..',
                        labelNoTimeSelected: 'Henüz tarih seçilmedi',
                        labelCloseButton: 'Kapat',
                        labelResetButton: 'Temizle',
                        labelNowButton: 'Şu an'
                    },
                },
                options: {
                    time: {
                        time: true,
                        timePattern: ['h', 'm'],
                    },
                },
                adminAnnualLeaveStatusOption: [],
                adminAnnualLeaveStatusSelect: 0,
                adminAnnualLeaveStatusSelectName: '',
                approval: false,
                employee: '',
                department: '',
                dateOption: {
                    date: true,
                    delimiter: '.',
                    datePattern: ['d', 'm', 'Y'],
                },
            }
        },
        methods: {
            showInfo(id) {
                this.$refs['employeeHourlyAdminAnnualLeaveRequestFormModal'].show()
                this.id = id
                const data = {
                    id: this.id
                }
                const controller = {
                    name: 'HourlyAnnualLeave'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.startingTime = response.data.hourlyAnnualLeave.startingTime
                        this.endingTime = response.data.hourlyAnnualLeave.endingTime
                        this.requestDescription = response.data.hourlyAnnualLeave.requestDescription
                        this.leaveDate = response.data.hourlyAnnualLeave.leaveDate
                        this.approveDescription = response.data.hourlyAnnualLeave.approveDescription
                        this.employee = response.data.hourlyAnnualLeave.employee
                        this.department = response.data.hourlyAnnualLeave.department
                        this.adminAnnualLeaveStatusOption = response.data.annualLeaveStatusList
                        this.adminAnnualLeaveStatusOption.splice(0, 0, this.$nullSelected())
                        if (response.data.hourlyAnnualLeave.annualLeaveStatusId > 0) {
                            this.adminAnnualLeaveStatusSelect = response.data.hourlyAnnualLeave.annualLeaveStatusId
                            this.adminAnnualLeaveStatusSelectName = response.data.hourlyAnnualLeave.annualLeaveStatus
                            // if(this.annualLeaveStatusSelect == 1){
                            //     this.annualLeaveStatusSelectName = 'Yönetici Onayı Bekliyor'
                            // }
                        }
    
                        // this.status = response.data.hourlyAnnualLeave
                    } else {
                        this.$bvModal.hide('employeeHourlyAdminAnnualLeaveRequestFormModal')
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            approveHourlyAdminAnnualLeave(id, approval) {
                this.id = id,
                    this.approval = approval
                const controller = {
                    name: 'HourlyAnnualLeave',
                    actionName: 'ApproveHourlyAnnualLeave'
                }
                const data = {
                    managerId: localStorage.getItem('EmployeeId'),
                    id: this.id,
                    approval: this.approval,
                    approveDescription: this.approveDescription
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$bvModal.hide('employeeHourlyAdminAnnualLeaveRequestFormModal')
                                this.getHourlyAdminAnnualLeaveList()
                                this.$SaveAlert()
                            } else {
                                this.$bvModal.hide('employeeHourlyAdminAnnualLeaveRequestFormModal')
                                this.$WarningAlert(response.data.resultMessage)
                                this.approveDescription = ''
                                this.adminAnnualLeaveStatusSelect = 0
                                this.approval = false
                            }
                        })
                    }
                })
            }
        }
    }
    </script>
    
    <style lang="scss">
    [dir=ltr] .modal-header .close {
        margin: -0.4rem -0.4rem -0.8rem auto;
    }
    
    [dir] label {
        margin-bottom: 0.2857rem;
        font-weight: bold;
    }
    
    .modal .modal-content {
        overflow: visible;
        margin-bottom: auto;
    }
    
    [dir] .invalid-tooltip {
        padding: 0.4rem 0.775rem !important;
        margin-top: -1.5rem !important;
        background-color: #ea5455 !important;
        border-radius: 0.358rem !important;
        left: 20px !important
    }
    .datepickerStyle{
        max-height: 2.2em;
        font-family: fangsong;
    }
    </style>
    