<template>
<div>
    <mail-send-form ref="mailSendFormModal"></mail-send-form>
    <patent-form ref="patentFormModal"></patent-form>
    
    <!-- table -->
    <vue-good-table id="patentCertificateTable" ref="patentCertificateTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('patentCertificate.our_ref') }}
            </span>
            <span v-else-if="props.column.field === 'applicationNo'" class="text-nowrap">
                {{ $t('patentCertificate.application_no') }}
            </span>
            <span v-else-if="props.column.field === 'applicationDate'" class="text-nowrap">
                {{ $t('patentCertificate.application_date') }}
            </span>
            <span v-else-if="props.column.field === 'workingDeclarationDeadline'" class="text-nowrap">
                {{ $t('patentCertificate.working_declaration_deadline') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('patentCertificate.owner') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.patentId)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('patentCertificate.send_certificate')" variant="success" class="btn-icon mb-1 ml-1" size="sm" @click="showMailForm(props.row.patentId)">
                        <feather-icon icon="MailIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.approve')" variant="success" class="btn-icon mb-1 ml-1"
                        size="sm" @click="addOrUpdate(props.row.patentId)">
                        <feather-icon icon="CheckSquareIcon" />
                    </b-button>
                </span>
            </span>
        </template>
    </vue-good-table>
</div>
</template>

  
<script>
export default {
    props: {
        patentId: Number,
        getPatentList: {
            type: Function
        },
    },
    data() {
        return {
            dir: false,
            columns: [{
                    label: this.$t('patentCertificate.our_ref'),
                    field: 'recordNo',
                },
                {
                    label: this.$t('patentCertificate.application_no'),
                    field: 'applicationNo',
                },
                {
                    label: this.$t('patentCertificate.application_date'),
                    field: 'applicationDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('patentCertificate.working_declaration_deadline'),
                    field: 'workingDeclarationDeadline',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('patentCertificate.owner'),
                    field: 'agent',
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '15em'
                },
            ],
            rows: [],
            searchTerm: '',
            item: 0,
            toMailAllElements: []
        }
    },
    mounted() {
        this.getPatentCertificateList()
    },
    methods: {
        async getInfo(patentId) {
            this.$refs.patentFormModal.showInfo(patentId)
        },
        getPatentCertificateList() {
            this.item = 0
            this.rows = []
            const controller = {
                name: 'Patent',
                actionName: 'GetPatentCertificateList'
            }
            const payload = {
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.patentCertificateList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        showMailForm(patentId) {
            this.$refs.mailSendFormModal.showInfo()
            const data = {
                Id: patentId
            }
            const controller = {
                name: 'MailTemplate',
                actionName: 'PreparePatentCertificateMail'
            }
            const payload = {
                controller: controller,
                data: data
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    
                    this.$refs.mailSendForm.showInfo(response.data)  
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate(id) {
            const data = {id: id}
            const controller = { name: 'Patent', actionName: 'ConfirmPatentCertificate' }
            const payload = { data: data, controller: controller }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if(response.data.resultStatus == true){
                    this.getPatentCertificateList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
      },
    },
}
</script>
  
<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
