<template>
    <div>
      <!-- modal -->
      <b-modal
        id="sectorFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="sectorFormModal"
        :title=" $t('sector.sector_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
  
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col class="col-lg-12">
                <validation-provider
                #default="{ errors }"
                :name=" $t('sector.name_TR')"
                rules="required"
              >
              <label for="sector-nameTR-input">{{ $t('sector.name_TR') }}</label>
              <b-form-group>
                <b-form-input
                  id="sector-nameTR-input"
                  v-model="sectorNameTR"
                  maxlength="50"
                  size="sm"
                  :state="errors.length > 0 ? false:null"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-lg-12">
                <validation-provider
                #default="{ errors }"
                :name=" $t('sector.name_EN')"
                rules="required"
              >
              <label for="sector-nameEN-input">{{ $t('sector.name_EN') }}</label>
              <b-form-group>
                <b-form-input
                  id="sector-nameEN-input"
                  v-model="sectorNameEN"
                  maxlength="50"
                  size="sm"
                  :state="errors.length > 0 ? false:null"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
      </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getSectorList: {type: Function}
    },
    data() {
      return {
        id: 0,
        sectorNameTR: '',
        sectorNameEN: '',
      }
    },
    methods: {
      showInfo(id) {
        this.$refs['sectorFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'Sector'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.sectorNameTR = response.data.sector.nameTR
                this.sectorNameEN = response.data.sector.nameEN

            } else {
                this.$bvModal.hide('sectorFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, nameEN: this.sectorNameEN,nameTR:this.sectorNameTR}
        const controller = {name : 'Sector'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('sectorFormModal')
                    this.getSectorList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  