<template>
<div>
    <trademark-transaction-report-detail ref="trademarkTransactionReportDetail"></trademark-transaction-report-detail>
    <b-card>
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row1SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType1" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-md-3">
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="row1First" :placeholder="$t('trademarkTransactionReport.referance_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row1Last" :placeholder="$t('trademarkTransactionReport.referance_no')" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getTrademarkTransactionList()" @click="getTrademarkTransactionList()">{{ $t('domain.list') }}</b-button>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row2SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType2" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row2FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType2" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row2Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row3SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row3FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row3Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row4SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row4FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row4Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row5SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row5FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row5Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row6SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row6FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row6Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row7SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row7FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row7Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row8SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row8FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row8Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row9SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row9FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row9Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row10SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row10FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row10Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row11SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row11FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType4" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-datepicker v-model="row11StartDateValue" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkTransactionReport.application_date_start')" size="sm" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-datepicker v-model="row11EndDateValue" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkTransactionReport.application_date_start')" size="sm" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row12SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType3" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row12FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType4" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-datepicker v-model="row12StartDateValue" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkTransactionReport.application_date_start')" size="sm" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <b-form-datepicker v-model="row12EndDateValue" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkTransactionReport.application_date_start')" size="sm" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row13SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType4" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="trademarkStatusSelect" size="sm" value-field="id" text-field="name" :options="trademarkStatusOption" />
                    </div>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row14SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType4" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="trademarkApplicationTypeSelect" size="sm" value-field="id" text-field="name" :options="trademarkApplicationTypeOption" />
                    </div>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row15SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType4" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="trademarkTypeSelect" size="sm" value-field="id" text-field="name" :options="trademarkTypeOption" />
                    </div>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label for="trademark-application-no-input">{{ $t('trademarkTransactionReportDetail.isTakeOver') }}</label>
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="TakeOverSelected" size="sm" value-field="id" text-field="name" :options="fieldType5" />
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card>
</div>
</template>

<script>
export default {
    watch: {
        row11StartDateValue(newValue) {
            if (newValue == '') {
                this.row11StartDateValue = null
            }
        },
        row11EndDateValue(newValue) {
            if (newValue == '') {
                this.row11EndDateValue = null
            }
        },
        row12StartDateValue(newValue) {
            if (newValue == '') {
                this.row12StartDateValue = null
            }
        },
        row12EndDateValue(newValue) {
            if (newValue == '') {
                this.row12EndDateValue = null
            }
        }
    },
    data() {
        return {
            rows: [],
            searchTerm: '',
            total: 0,
            pageLength: 50,
            dir: false,
            pageNo: 1,

            searchType1: [{
                    id: '1',
                    name: 'Eşit'
                },
                {
                    id: '2',
                    name: 'Arasında'
                }
            ],
            searchType2: [{
                    id: '1',
                    name: 'İçinde Geçen'
                },
                {
                    id: '2',
                    name: 'Eşit'
                },
                {
                    id: '3',
                    name: 'İle Başlayan'
                },
                {
                    id: '4',
                    name: 'İle Biten'
                },
                {
                    id: '5',
                    name: 'Smilar'
                },
                {
                    id: '6',
                    name: 'Soundex'
                }
            ],
            searchType3: [{
                    id: '1',
                    name: 'And'
                },
                {
                    id: '2',
                    name: 'Or'
                }
            ],
            searchType4: [{
                    id: '1',
                    name: 'And'
                },
                {
                    id: '2',
                    name: 'Or'
                },
                {
                    id: '3',
                    name: 'Not'
                }
            ],
            fieldType2: [{
                    id: '1',
                    name: 'Marka Adı'
                },
                {
                    id: '2',
                    name: 'Marka Adı(Müv.)'
                },
            ],
            fieldType3: [{
                    id: '1',
                    name: 'Tescil No'
                },
                {
                    id: '2',
                    name: 'Başvuru No'
                },
                {
                    id: '3',
                    name: 'Sınıflar'
                },
                {
                    id: '4',
                    name: 'Mal ve Hizmetler'
                },
                {
                    id: '5',
                    name: 'Mal ve Hizmetler (Eng)'
                },
                {
                    id: '6',
                    name: 'Ajan Adı'
                },
                {
                    id: '7',
                    name: 'Sahip Adı'
                },
                {
                    id: '8',
                    name: 'Not'
                },
            ],
            fieldType4: [{
                    id: '1',
                    name: 'Başvuru Tarihi'
                },
                {
                    id: '2',
                    name: 'Sonraki Yenileme Tarihi'
                },
                {
                    id: '3',
                    name: 'Yenileme Başvuru Tarihi'
                },
                {
                    id: '4',
                    name: 'Yen. Hatır. Gönderi Tarihi'
                },
                {
                    id: '5',
                    name: 'İşlem Tarihi'
                },
                {
                    id: '6',
                    name: 'Rüçhan Tarihi'
                },
                {
                    id: '7',
                    name: 'Tescil Tarihi'
                },
                {
                    id: '8',
                    name: 'İlan Tarihi'
                },
            ],
            fieldType5: [{
                    id: '1',
                    name: 'Hepsi'
                },
                {
                    id: '2',
                    name: 'Vekil Atama Var'
                },
                {
                    id: '3',
                    name: 'Vekil Atama Yok'
                },
            ],

            row1SearchTypeSelected: 1,
            row1First: '',
            row1Last: '',
            row2SearchTypeSelected: 1,
            row2FieldTypeSelected: 1,
            row2Value: '',
            row3SearchTypeSelected: 1,
            row3FieldTypeSelected: 1,
            row3Value: '',
            row4SearchTypeSelected: 1,
            row4FieldTypeSelected: 2,
            row4Value: '',
            row5SearchTypeSelected: 1,
            row5FieldTypeSelected: 3,
            row5Value: '',
            row6SearchTypeSelected: 1,
            row6FieldTypeSelected: 4,
            row6Value: '',
            row7SearchTypeSelected: 1,
            row7FieldTypeSelected: 5,
            row7Value: '',
            row8SearchTypeSelected: 1,
            row8FieldTypeSelected: 6,
            row8Value: '',
            row9SearchTypeSelected: 1,
            row9FieldTypeSelected: 7,
            row9Value: '',
            row10SearchTypeSelected: 1,
            row10FieldTypeSelected: 8,
            row10Value: '',
            row11SearchTypeSelected: 1,
            row11FieldTypeSelected: 2,
            row11StartDateValue: null,
            row11EndDateValue: null,
            row12SearchTypeSelected: 1,
            row12FieldTypeSelected: 7,
            row12StartDateValue: null,
            row12EndDateValue: null,
            row13SearchTypeSelected: 1,
            trademarkStatusOption: [],
            trademarkStatusSelect: 0,
            row14SearchTypeSelected: 1,
            trademarkApplicationTypeOption: [],
            trademarkApplicationTypeSelect: 0,
            row15SearchTypeSelected: 1,
            trademarkTypeOption: [],
            trademarkTypeSelect: 0,
            TakeOverSelected: 1,
        }
    },
    mounted() {
        const controller = {
            name: 'Trademark',
            actionName: 'GetTrademarkTransactionReportInfo'
        }

        const payload = {
            controller: controller
        }

        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
                this.trademarkStatusOption = response.data.trademarkStatusList
                this.trademarkApplicationTypeOption = response.data.trademarkApplicationTypeList
                this.trademarkTypeOption = response.data.trademarkTypeList
                this.trademarkTypeOption.splice(0, 0, this.$nullSelected())
                this.trademarkApplicationTypeOption.splice(0, 0, this.$nullSelected())
                this.trademarkStatusOption.splice(0, 0, this.$nullSelected())
            } else {
                this.$WarningAlert(response.data.resultMessage)
            }
        })
    },
    methods: {
        getTrademarkTransactionList() {
            this.rows = []
            const controller = {
                name: 'Trademark',
                actionName: 'TrademarkTransactionReport'
            }
            const data = {
                row1SearchType: this.row1SearchTypeSelected,
                row1First: this.row1First,
                row1Last: this.row1Last,
                row2SearchType: this.row2SearchTypeSelected,
                row2FieldType: this.row2FieldTypeSelected,
                row2Value: this.row2Value,
                row3SearchType: this.row3SearchTypeSelected,
                row3FieldType: this.row3FieldTypeSelected,
                row3Value: this.row3Value,
                row4SearchType: this.row4SearchTypeSelected,
                row4FieldType: this.row4FieldTypeSelected,
                row4Value: this.row4Value,
                row5SearchType: this.row5SearchTypeSelected,
                row5FieldType: this.row5FieldTypeSelected,
                row5Value: this.row5Value,
                row6SearchType: this.row6SearchTypeSelected,
                row6FieldType: this.row6FieldTypeSelected,
                row6Value: this.row6Value,
                row7SearchType: this.row7SearchTypeSelected,
                row7FieldType: this.row7FieldTypeSelected,
                row7Value: this.row7Value,
                row8SearchType: this.row8SearchTypeSelected,
                row8FieldType: this.row8FieldTypeSelected,
                row8Value: this.row8Value,
                row9SearchType: this.row9SearchTypeSelected,
                row9FieldType: this.row9FieldTypeSelected,
                row9Value: this.row9Value,
                row10SearchType: this.row10SearchTypeSelected,
                row10FieldType: this.row10FieldTypeSelected,
                row10Value: this.row10Value,
                row11SearchType: this.row11SearchTypeSelected,
                row11FieldType: this.row11FieldTypeSelected,
                row11StartDateValue: this.row11StartDateValue,
                row11EndDateValue: this.row11EndDateValue,
                row12SearchType: this.row12SearchTypeSelected,
                row12FieldType: this.row12FieldTypeSelected,
                row12StartDateValue: this.row12StartDateValue,
                row12EndDateValue: this.row12EndDateValue,
                row13SearchType: this.row13SearchTypeSelected,
                trademarkStatusId: this.trademarkStatusSelect,
                row14SearchType: this.row14SearchTypeSelected,
                trademarkApplicationTypeId: this.trademarkApplicationTypeSelect,
                row15SearchType: this.row15SearchTypeSelected,
                trademarkTypeId: this.trademarkTypeSelect,
                takeOverId: this.TakeOverSelected
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$refs.trademarkTransactionReportDetail.showData(response.data.trademarkList)
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.featherIconReSize {
    width: 12px;
    height: 12px;
}

[dir=ltr] .mr-05,
[dir=ltr] .mx-1 {
    margin-right: 0.5rem !important;
}
</style>
