<template>
<div>
    <patent-xml-upload-form ref="patentXmlUploadFormModal"></patent-xml-upload-form>
    <patent-wipo-xml-upload-form ref="patentWipoXmlUploadFormModal"></patent-wipo-xml-upload-form>
    <design-form ref="designFormModal"></design-form>
    <b-modal id="findRecordFormModal" centered size="lg" no-close-on-backdrop ref="findRecordFormModal" :title="$t('findRecord.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" @ok.prevent="emitRecordId" cancel-variant="outline-secondary" @close="clear">
        <b-row class="mt-1">
            <b-col class="col-lg-12 mt-1">
                <b-form-group>
                    <label for="find-record-folder-no-input"> {{ $t('findRecord.folder_no') }} </label>
                    <b-input-group>
                        <validation-provider :name=" $t('findRecord.folder_no')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <b-form-group label-for="find-record-folder-no-input" :state="errors.length > 0 ? false:null">
                                <v-select class="agentInput" id="find-record-folder-no-input" v-model="recordNoSelect" :options="recordNoOption" label="record" @search="onSearch">
                                </v-select>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                      <b-input-group-append>
                        <b-dropdown style="width: 4em; height: 3em; margin-bottom: 1em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                            <template #button-content>
                                <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                            </template>
                            <b-dropdown-item @click="getWipoXml(0)" v-if="this.patentCheck">
                                <span>{{ $t('patent.add_from_wipo_xml') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="getEpoXml(0)" v-if="this.patentCheck">
                                <span>{{ $t('patent.add_from_epo_xml') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="addPatent(0)" v-if="this.patentCheck">
                                <span>{{ $t('patent.patent_add') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="addDesign(0)" v-if="this.designCheck">
                                <span>{{ $t('design.design_add') }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
export default {
    data() {
        return {
            id: 0,
            recordId: 0,
            recordNoSelect: 0,
            recordNoOption: [],
            recordNoId: 0,
            importedRecordId: 0,
            patentCheck: false,
            designCheck: false
        }
    },
    props: {
        moduleId: Number
    },
    mounted() {
        this.checkModule();
    },
    methods: {
        showInfo() {
            this.$refs['findRecordFormModal'].show()
        },
        async getEpoXml() {
            this.$refs.patentXmlUploadFormModal.showInfo(0)
        },
        async getWipoXml() {
            this.$refs.patentWipoXmlUploadFormModal.showInfo(0)
        },
        async addPatent(id) {
            this.$refs.patentFormModal.showInfo(id)
        },
        async addDesign(id) {
            this.$refs.designFormModal.showInfo(id)
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    moduleId: this.moduleId,
                    recordNo: search
                }
                const controller = {
                    name: 'Module',
                    actionName: 'GetRecordListbyModuleIdAndRecordNo'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.recordNoOption = response.data.recordList
                    loading(false)
                    this.$emit('exportValue', this.recordNoSelect);
                })
            }
        },
        checkModule() {
            if (this.moduleId == 4) {
                this.patentCheck = true
            } else if (this.moduleId == 5) {
                this.designCheck = true
            }
        },
        emitRecordId() {
            this.$bvModal.hide('findRecordFormModal')
            this.$emit('importedRecordId', this.recordNoSelect.recordId);
        },
        clear() {
            this.recordNoSelect = 0,
                this.recordNoOption = [],
                this.patentCheck = true,
                this.designCheck = true
        }
    }
}
</script>
<style>
[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
.agentInput{
    max-width: 87em!important;
    width: 86em!important;
}
</style>