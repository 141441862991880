<template>
    <div>
      <!-- modal -->
      <b-modal
        id="designBulletinAnalyseFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="designBulletinAnalyseFormModal"
        :title=" $t('designBulletinAnalyse.tpmk_design_analyse_bulletin_info')"
        hide-footer
      >
      <b-row>
        <!--TPMK Design--> 
          <b-col>
            <b-card :title=" $t('designBulletinAnalyse.tpmk_analyse_bulletin_design_title') ">
              <b-list-group>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletinAnalyse.tpmk_analyse_application_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAnalyseApplicationNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletinAnalyse.tpmk_analyse_application_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{this.$formatFn(tpmkAnalyseApplicationDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletinAnalyse.tpmk_analyse_register_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAnalyseRegisterNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletinAnalyse.tpmk_analyse_register_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{this.$formatFn(tpmkAnalyseRegisterDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletinAnalyse.tpmk_analyse_bulletin_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAnalyseBulletinNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletinAnalyse.tpmk_analyse_bulletin_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{this.$formatFn(tpmkAnalyseBulletinDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-12">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletinAnalyse.tpmk_analyse_attorney') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAnalyseAttorney}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
              </b-list-group>
            </b-card>
        <!--TPMK Designer--> 
            <b-card :title=" $t('designBulletinAnalyse.tpmk_analyse_bulletin_designer_title') ">
              <b-list-group v-for="(nameAndAddress, index) in tpmkDesignDesignerNameAndAddress" :key="nameAndAddress.id">
                <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <span>
                            {{ nameAndAddress.name }}
                            <br>
                            {{ nameAndAddress.address }}
                          </span>
                        </b-col>
                      </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
      </b-row>

      <b-row>
        <!--TPMK Design Owner--> 
          <b-col>
            <b-card :title=" $t('designBulletinAnalyse.tpmk_analyse_bulletin_design_owner_title') ">
              <b-list-group v-for="(nameAndAddress, index) in tpmkDesignDesignerOwnerNameAndAddress" :key="nameAndAddress.id">
                <b-list-group-item class="flex-column align-items-start">
                  <b-row>
                    <b-col>
                      <b-card-text>
                        {{ nameAndAddress.name }}
                        <br>
                        {{ nameAndAddress.address }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
        <!--TPMK Design Detail--> 
            <b-card :title=" $t('designBulletinAnalyse.tpmk_analyse_bulletin_design_detail_title') ">
              <b-list-group v-for="(nameAndLocarno, index) in tpmkDesignDetailList" :key="nameAndLocarno.id">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-col>
                        <b-card-text>
                          {{nameAndLocarno.no + ' - ' + nameAndLocarno.productName + ' - ' + nameAndLocarno.locarnoCodes}}
                        </b-card-text>
                      </b-col>
                    </b-list-group-item>
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                            <b-row>
                              <span v-for="(image, index) in nameAndLocarno.images" :key="image.id">
                                  <b-img :src="'data:image/png;base64,' + image.base64"
                                    fluid
                                    alt="Img"
                                    style="width: 5em; height: 5em; border-style: double;"/>
                              </span>
                            </b-row>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
              </b-list-group>
            </b-card>
        </b-col>
      </b-row>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getDesignBulletinList: {type: Function},
    },
    data() {
      return {
        id: 0,
        tpmkAnalyseApplicationNo: '',
        tpmkAnalyseApplicationDate: null,
        tpmkAnalyseRegisterNo: '',
        tpmkAnalyseRegisterDate: null,
        tpmkAnalyseBulletinNo: 0,
        tpmkAnalyseBulletinDate: 0,
        tpmkAnalyseAttorney: '',
        tpmkDesignDesignerNameAndAddress: [],
        tpmkDesignDesignerOwnerNameAndAddress: [],
        tpmkDesignDetailList: [],
        tpmkAnalyseNo: 0,
        tpmkAnalyseProductName: '',
        tpmkAnalyseProductNameEn: '',
        tpmkAnalyseLocarnoCodes: '',
        tpmkAnalyseImages: [],
        searchTerm: '',
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['designBulletinAnalyseFormModal'].show()
        this.id = id
        const data = { id: this.id }
        const controller = { name: 'Bulletin', actionName: 'GetDesignBulletinAnalyseInfo' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
                  this.tpmkAnalyseImages = []
                //Design
                  this.tpmkAnalyseApplicationNo = response.data.tpmkDesign.applicationNo
                  this.tpmkAnalyseApplicationDate = response.data.tpmkDesign.applciationDate
                  this.tpmkAnalyseRegisterNo = response.data.tpmkDesign.registerNo
                  this.tpmkAnalyseRegisterDate = response.data.tpmkDesign.registerDate
                  this.tpmkAnalyseBulletinNo = response.data.tpmkDesign.bulletinNo
                  this.tpmkAnalyseBulletinDate = response.data.tpmkDesign.bulletinDate
                  this.tpmkAnalyseAttorney = response.data.tpmkDesign.attorney

                  //Designer
                  this.tpmkDesignDesignerNameAndAddress = response.data.tpmkDesignDesignerList

                  //Owner
                  this.tpmkDesignDesignerOwnerNameAndAddress = response.data.tpmkDesignHolderList

                  //Detail
                  this.tpmkDesignDetailList = response.data.tpmkDesignDetailList
                  // response.data.tpmkDesignDetailList.forEach(element => {
                  //   this.tpmkAnalyseNo = element.no
                  //   this.tpmkAnalyseProductName = element.productName
                  //   this.tpmkAnalyseProductNameEn = element.productNameEn
                  //   this.tpmkAnalyseLocarnoCodes = element.locarnoCodes
                  //   if(element.images != null){
                  //     element.images.forEach(el => {
                  //       this.tpmkAnalyseImages.push(el)
                  //     })
                  //   } else {
                  //     this.tpmkAnalyseImages = []
                  //   }
                  // })
        }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
.tableSize {
  font-size: 13px!important;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}
  </style>
  