<template>
    <div>
      <!-- modal -->
      <b-modal
        id="trademarkBulletinAnalyseFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="trademarkBulletinAnalyseFormModal"
        :title=" $t('trademarkBulletinAnalyse.tpmk_trademark_analyse_bulletin_info')"
        hide-footer
      >
    <!--TPMK Logo--> 
      <div class="custom-search d-flex justify-content-start">
        <b-row>
            <b-col>
                <span v-if="tpmkTrademarkTrademarkImage != null">
                    <b-img :src="'data:image/png;base64,' + tpmkTrademarkTrademarkImage"
                      fluid
                      alt="Img"
                      style="width: 10em; height: 7em; border-style: double;"/>
                </span>
            </b-col>
        </b-row>
    </div>
    <!--TPMK Trademark--> 
      <b-row>
          <b-col>
            <b-card :title=" $t('trademarkBulletinAnalyse.tpmk_analyse_bulletin_trademark_title') ">
              <b-list-group>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletinAnalyse.tpmk_analyse_application_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAnalyseApplicationNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletinAnalyse.tpmk_analyse_application_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{this.$formatFn(tpmkAnalyseApplicationDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletinAnalyse.tpmk_analyse_register_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAnalyseRegisterNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletinAnalyse.tpmk_analyse_register_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{this.$formatFn(tpmkAnalyseRegisterDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletinAnalyse.tpmk_analyse_bulletin_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAnalyseBulletinNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletinAnalyse.tpmk_analyse_vienna_classes') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{ tpmkAnalyseViennaClasses }}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row v-if="tpmkAnalyseNiceClasses != null">
                    <b-col class="col-md-12 mb-1">
                      <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                          <b-col>
                            <div class="d-flex w-100 justify-content-between">
                              <h6>
                                {{ $t('trademarkBulletinAnalyse.tpmk_analyse_nice_classes') }}
                              </h6>
                            </div>
                          </b-col>
                          <b-col>
                            <b-card-text>
                              {{tpmkAnalyseNiceClasses}}
                            </b-card-text>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-col>
                </b-row>
                <b-row v-if="tpmkAnalyseName != null">
                    <b-col class="col-md-12 mb-1">
                      <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                          <b-col>
                            <div class="d-flex w-100 justify-content-between">
                              <h6>
                                {{ $t('trademarkBulletinAnalyse.tpmk_analyse_name') }}
                              </h6>
                            </div>
                          </b-col>
                          <b-col>
                            <b-card-text>
                              {{tpmkAnalyseName}}
                            </b-card-text>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-col>
                  </b-row>
                <b-row v-if="tpmkAnalyseAttorney != null">
                  <b-col class="col-md-12 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletinAnalyse.tpmk_analyse_attorney') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAnalyseAttorney}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row v-if="tpmkAnalyseApplicationExtracredGoods != null">
                  <b-col class="col-md-12">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletinAnalyse.tpmk_analyse_application_extracred_goods') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{ tpmkAnalyseApplicationExtracredGoods }}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
              </b-list-group>
            </b-card>
          </b-col>
      </b-row>
    <!--TPMK Trademark Owner--> 
    <b-row>
        <b-col>
          <b-card :title=" $t('trademarkBulletinAnalyse.tpmk_analyse_bulletin_trademark_owner_info') ">
            <b-list-group v-for="(nameAndAddress, index) in tpmkTrademarkTrademarkerOwnerNameAndAddress" :key="nameAndAddress.id">
              <b-row>
                <b-col class="col-md-12">
                  <b-list-group-item class="flex-column align-items-start">
                    <b-row>
                      <b-col>
                        <h6>
                          {{ $t('trademarkBulletinAnalyse.tpmk_analyse_owner_address') }}
                        </h6>
                        <b-card-text>
                            {{ nameAndAddress.tpeOwnerCode + ' ' + nameAndAddress.name   }}
                        </b-card-text>
                        <b-card-text>
                            {{nameAndAddress.address + ' , ' + nameAndAddress.state + ' / ' + nameAndAddress.cityName + ' / ' + nameAndAddress.countryName}}
                        </b-card-text>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-col>
              </b-row>
            </b-list-group>
          </b-card>
        </b-col>
    </b-row>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getTrademarkBulletinList: {type: Function},
    },
    data() {
      return {
        id: 0,
        tpmkAnalyseApplicationNo: '',
        tpmkAnalyseApplicationDate: null,
        tpmkAnalyseRegisterNo: '',
        tpmkAnalyseRegisterDate: null,
        tpmkAnalyseBulletinNo: 0,
        tpmkAnalyseBulletinDate: 0,
        tpmkAnalyseAttorney: '',
        tpmkAnalyseApplicationExtracredGoods: null,
        tpmkAnalyseName: '',
        tpmkAnalyseNiceClasses: '',
        tpmkAnalyseViennaClasses: '',
        tpmkTrademarkTrademarkerNameAndAddress: [],
        tpmkTrademarkTrademarkerOwnerNameAndAddress: [],
        tpmkTrademarkTrademarkImage: '',
        searchTerm: '',
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['trademarkBulletinAnalyseFormModal'].show()
        this.id = id
        const data = { id: this.id }
        const controller = { name: 'Bulletin', actionName: 'GetTrademarkBulletinAnalyseInfo' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
                //Trademark
                  this.tpmkAnalyseApplicationNo = response.data.tpmkTrademark.applicationNo
                  this.tpmkAnalyseApplicationDate = response.data.tpmkTrademark.applciationDate
                  this.tpmkAnalyseRegisterNo = response.data.tpmkTrademark.registerNo
                  this.tpmkAnalyseRegisterDate = response.data.tpmkTrademark.registerDate
                  this.tpmkAnalyseBulletinNo = response.data.tpmkTrademark.bulletinNo
                  this.tpmkAnalyseBulletinDate = response.data.tpmkTrademark.bulletinDate
                  this.tpmkAnalyseAttorney = response.data.tpmkTrademark.attorney
                  this.tpmkAnalyseApplicationExtracredGoods = response.data.tpmkTrademark.applicationExtracredGoods
                  this.tpmkAnalyseName = response.data.tpmkTrademark.name
                  this.tpmkAnalyseNiceClasses = response.data.tpmkTrademark.niceClasses
                  this.tpmkAnalyseViennaClasses = response.data.tpmkTrademark.viennaClasses

                  //Owner
                  this.tpmkTrademarkTrademarkerOwnerNameAndAddress = response.data.tpmkTrademarkHolderList

                  //Logo
                  this.tpmkTrademarkTrademarkImage = response.data.base64
        }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
.tableSize {
  font-size: 13px!important;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}
  </style>
  