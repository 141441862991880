
     <template>
      <div>
        <document-type-detail-form class="mb-2" :getDocumentTypeDetailList="getDocumentTypeDetailList" ref="documentTypeDetailFormModal"></document-type-detail-form>
        <b-row>
          <b-col class="col-12">
            <div style="float:right;" class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-button style="float:right;" size="sm" variant="outline-primary"
                    @click="getInfo(0)">{{ $t('documentType.add_document_type_detail') }}</b-button>
                </div>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <vue-good-table
          ref="documentTypeDetailTable"
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table condensed tableSize"     
          :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        
          theme="my-theme"
      >
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field ==='name'" class="text-nowrap">
            {{ $t('documentType.name') }}
          </span>
          <span v-else-if="props.column.field ==='sequenceNo'" class="text-nowrap">
            {{ $t('documentType.sequence_no') }}
          </span>
          <span v-else-if="props.column.field ==='isTr'" class="text-nowrap">
            {{ $t('documentType.is_TR') }}
          </span>
          <span v-else-if="props.column.field ==='isKKTC'" class="text-nowrap">
            {{ $t('documentType.is_KKTC') }}
          </span>
          <span v-else-if="props.column.field ==='process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <template
            slot="table-row"
            slot-scope="props"
        >
          <span v-if="props.column.field === 'isTr'">
            <b-form-checkbox disabled v-model="props.row.isTrContent" />
          </span>
          <span v-else-if="props.column.field === 'isKKTC'">
            <b-form-checkbox disabled v-model="props.row.isKKTCContent"/>
          </span>
          <!-- Column: Process -->
          <span v-else-if="props.column.field === 'process'">
            <span>
              <b-button
                  v-b-tooltip.html
                  :title=" $t('others.edit')"
                  v-b-modal.modal-top
                  variant="primary"
                  class="btn-icon mb-1 ml-1"
                  size="sm"
                  @click="getInfo(props.row.id)"
              >

                <feather-icon icon="EditIcon" />
              </b-button>    
              <b-button
                  v-b-tooltip.html
                  :title=" $t('others.delete')"
                  variant="warning"
                  class="btn-icon mb-1 ml-1"
                  size="sm"
                  @click="deleted(props.row)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>      
      </vue-good-table>  
      </div>
     </template>
     <script>
export default {
  props: {
    documentTypeId:Number
  },
  data() {
    return {
      id: 0,
      dir: false,
      isKKTC : false,
      isTr : false,
      sequenceNo : 0,
      columns: [
        {
          label: this.$t('documentType.name'),
          field: `name`,
        },
        {
          label: this.$t('documentType.sequence_no'),
          field: `sequenceNo`,
        },
        {
          label: this.$t('documentType.is_TR'),
          field: `isTr`,
        },
        {
          label: this.$t('documentType.is_KKTC'),
          field: `isKKTC`,
        },
        {
          label: 'process',
          field: 'process',
          width: '10em'
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  mounted() {
      this.getDocumentTypeDetailList()
    },
  methods: {
    async getInfo(id) {
        this.$refs.documentTypeDetailFormModal.showInfo(id, this.documentTypeId)
      },
    getDocumentTypeDetailList () {
          this.rows = []
          const data = {id: this.documentTypeId}
          const controller = {name : 'DocumentTypeDetail', actionName:'GetDocumentTypeDetailListByDocumentTypeId'}
          const payload = {data: data, controller: controller}
          this.$store.dispatch('moduleAuth/customService',payload).then((response) => {
            if(response.data.resultStatus == true){
              response.data.documentTypeDetailList.forEach(element => {
              this.rows.push(element)
              })
            }
            else{
             this.$WarningAlert(response.data.resultMessage)
            }
          }
        )
    },
    async deleted(data) {
      data = {id: data.id}
      const controller = {name : 'DocumentTypeDetail'}
      const payload = {data: data, controller: controller}
     this.$DeleteConfirm().then(result => {
       if(result.value){
          this.$store.dispatch('moduleAuth/delete',payload).then((response) => {
            if(response.data.resultStatus == true){
              this.$DeleteAlert()
              this.getDocumentTypeDetailList()
            } else {
              this.$WarningAlert(response.data.resultMessage)
            }     
          })
       }
     })  
    },
  }
}
</script>
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}
[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}
.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}
[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem!important;
    margin-top: -1.5rem!important;
    background-color: #ea5455!important;
    border-radius: 0.358rem!important;
    left: 20px!important
}
.tableSize {
  font-size: 13px!important;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}
</style>