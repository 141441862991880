<template>
<div>
    <trademark-provisional-form :getTrademarkList="getTrademarkList" :getTrademarkProvisionalList="getTrademarkProvisionalList" ref="trademarkProvisionalFormModal"></trademark-provisional-form>
    <!-- table -->
    <vue-good-table id="trademarkProvisionalTable" ref="trademarkProvisionalTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'date'" class="text-nowrap">
                {{ $t('trademarkProvisional.date') }}
            </span>
            <span v-else-if="props.column.field === 'owner'" class="text-nowrap">
                {{ $t('trademarkProvisional.owner') }}
            </span>
            <span v-else-if="props.column.field === 'groundOfObjection'" class="text-nowrap">
                {{ $t('trademarkProvisional.ground_of_objection') }}
            </span>
            <span v-else-if="props.column.field === 'decision'" class="text-nowrap">
                {{ $t('trademarkProvisional.decision') }}
            </span>
            <span v-else-if="props.column.field === 'status'" class="text-nowrap">
                {{ $t('trademarkProvisional.provisional_status')}}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.detail')" variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </span>
            </span>
        </template>
    </vue-good-table>
</div>
</template>

    
<script>
export default {
    props: {
        trademarkId: Number,
        getTrademarkList: {
            type: Function
        },
    },
    data() {
        return {
            dir: false,
            columns: [{
                    label: this.$t('trademarkProvisional.date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('trademarkProvisional.clause'),
                    field: `clause`,
                },
                {
                    label: this.$t('trademarkProvisional.provisional_status'),
                    field: `trademarkProvisionalStatus`,
                },
                {
                    label: this.$t('trademarkProvisional.employee'),
                    field: `employee`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getTrademarkProvisionalList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'TrademarkProvisional'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getTrademarkProvisionalList()
                            this.getTrademarkList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getTrademarkProvisionalList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.trademarkProvisionalFormModal.showInfo(id)
        },
        getTrademarkProvisionalList() {
            this.rows = []
            const data = {
                id: this.trademarkId
            }
            const controller = {
                name: 'TrademarkProvisional',
                actionName: 'GetTrademarkProvisionalListByTrademarkId'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    if(response.data.trademarkProvisionalList != null){
                        response.data.trademarkProvisionalList.forEach(element => {
                            this.rows.push(element)
                        })
                    } 
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

    
<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
