<template>
    <div>
        <b-modal
            id="designResponsibleFormModal"
            centered
            size="lg"
            no-close-on-backdrop
            ref="designResponsibleFormModal"
            :title=" $t('designResponsible.responsible_info')"
            :cancel-title=" $t('others.cancel_title')"
            :ok-title=" $t('others.ok_title')"
            cancel-variant="outline-secondary"
            @ok.prevent="addOrUpdate"
            hide-footer
        >
        <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="LockIcon" size="18" />
                <span class="align-middle ml-50">{{  $t('designResponsible.responsible')  }}</span>
              </b-card-title>
            </b-card-header>
            <!--eslint-disable-->
            <table class="table table-striped table-bordered">
              <tbody>
                <tr v-for="designResponsibleItem in designResponsibleList" :key="designResponsibleItem.id">
                  <td >
                      <b-form-checkbox @change="changeResponsible($event, designResponsibleItem.id)" />                                 
                  </td>
                  <td>{{  designResponsibleItem.name  }}</td>

                </tr>
              </tbody>
            </table>
          </b-card>
        </b-modal>
    </div>
</template>
<script>
export default{
  props: {
      getDesignResponsibleList: {type: Function},
    },
  data(){
    return {
      designId: 0,
      designResponsibleList:[]
    }
  },
  methods:{
    changeResponsible(selected, clientResponsibleId) {
      const controller = { name: 'DesignResponsible' }
      if (selected == true) {
        const data = { designId: this.designId, clientResponsibleId: clientResponsibleId }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.getDesignResponsibleList()
            this.$SaveAlert()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
    },
    showInfo(designId, clientId) {
      this.$refs['designResponsibleFormModal'].show()
      this.designId= designId
      const data = {clientId: clientId,designId : designId}
      const controller = {name: 'ClientResponsible', actionName:'GetClientResponsibleListForModules'}
      const payload = {data: data, controller: controller}
      this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
        if(response.data.resultStatus == true){    
          this.designResponsibleList = response.data.clientResponsibleList
        } else {
          this.$bvModal.hide('designResponsibleFormModal')
          this.$WarningAlert(response.data.resultMessage)
        }  
      }) 
    },
  }
}
</script>