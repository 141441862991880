<template>
  <div>
      <b-modal id="invoiceUpdateItemFormModal" centered size="lg" no-close-on-backdrop ref="invoiceUpdateItemFormModal" :title=" $t('invoiceUpdateItem.info')" :cancel-title=" $t('others.cancel_title')"
      :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
      <validation-observer ref="simpleRules">
        <b-col class="col-lg-12">
          <label for="invoice-add-item-name-input">{{ $t('invoiceUpdateItem.name') }}</label>
          <validation-provider
            #default="{ errors }"
            :name=" $t('invoiceUpdateItem.name')"
            rules="required"
          >
          <b-form-textarea id="invoice-add-item-name-input" size="sm" rows="3" maxlength="600"
          :state="errors.length > 0 ? false:null"
          v-model="name" />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-col>          
    </validation-observer>
  </b-modal>


  </div>
</template>
<script>
export default{
props: {
  showInvoiceInfo: {
    type: Function
  }
},
data(){    
  return {
    id: 0,
    invoiceId: 0,
    name : '',
  }
},
methods:{
  showInfo(id, invoiceId) {
    this.id = id
    this.invoiceId = invoiceId
    this.$refs['invoiceUpdateItemFormModal'].show()

    const data = {id: this.id}
        const controller = {name : 'InvoiceDetail'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.name = response.data.invoiceDetail.name               
            } else {
                this.$bvModal.hide('invoiceUpdateItemFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  

  },
  addOrUpdate() {      
    this.$refs.simpleRules.validate().then(success => {
      if (success) {
        const data = {
            id: this.id,
            name: this.name,
            invoiceId:this.invoiceId,
        }

        const controller = {
          name: 'InvoiceDetail',
          actionName:'updateInvoiceDetail'
        }
        const payload = {
          data: data,
          controller: controller
        }

         this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.$bvModal.hide('invoiceUpdateItemFormModal')
            this.showInvoiceInfo(this.invoiceId)

            this.$SaveAlert()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
          })
      }
      })
    },  
  }
}
</script>
<style>
.tab-content > .active {
display: block;
width: 75em;
}
</style>