<template>
    <div>
      <!-- modal -->
      <b-modal id="etebsFormModal" centered size="lg" no-close-on-backdrop ref="etebsFormModal" :title=" $t('etebs.info')" :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-6">
                <label for="etebs-module-input">{{ $t('etebs.module') }}</label>
                <b-form-group>
                    <b-form-input disabled id="etebs-module-input" size="sm" v-model="moduleName" />
                </b-form-group>
            </b-col>
            <b-col class="col-lg-6">
                <b-form-group disabled>
                <label for="etebs-notification-date-input">{{ $t('etebs.notification_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" disabled v-model="notificationDate"></CustomDatePicker>
            </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-6">
                <label for="etebs-application-no-input">{{ $t('etebs.application_no') }}</label>
                <b-form-group>
                    <b-form-input disabled id="etebs-application-no-input" size="sm" v-model="applicationNo" />
                </b-form-group>
            </b-col>
       
            <b-col class="col-lg-6">
                <label for="etebs-document-type-input">{{ $t('etebs.document_type') }}</label>
                <b-form-group>
                    <b-form-input disabled id="etebs-document-type-input" size="sm" v-model="documentType" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <label for="etebs-responsible-input">{{ $t('etebs.responsible') }}</label>
                <b-form-group>
                    <b-form-input disabled id="etebs-responsible-input" size="sm" v-model="responsible" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <label for="etebs-note-input">{{ $t('etebs.note') }}</label>
                <b-form-textarea id="etebs-note-input" :placeholder="$t('etebs.note')" v-model="note" rows="3" maxlength="200" />
            </b-col>
        </b-row>
      </b-modal>
    </div>
  </template>
  <script>    
  export default {
    props: {
      getEtebsList: {type: Function},
    },
    data() {
      return {
        id: 0,
        moduleName: '',
        notificationDate: null,
        applicationNo: '',
        responsible: '',
        documentType: '',
        note: '',
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['etebsFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'Etebs'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.moduleName = response.data.etebs.module
                this.notificationDate = response.data.etebs.notificationDate
                this.applicationNo = response.data.etebs.applicationNo
                this.responsible = response.data.etebs.responsible
                this.documentType = response.data.etebs.documentType
                this.note = response.data.etebs.note
            } else {
                this.$bvModal.hide('etebsFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, moduleName: this.moduleName, notificationDate: this.notificationDate, applicationNo: this.applicationNo, responsible: this.responsible,
        documentType: this.documentType, note: this.note }
        const controller = {name : 'Etebs'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/save',payload).then((response) => {
            if(response.data.resultStatus == true){
                this.$bvModal.hide('etebsFormModal')
                this.getEtebsList()
                this.$SaveAlert()
            } else {
                this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  .datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
  </style>
  