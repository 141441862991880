<template>
    <div>
      <!-- modal -->
      <b-modal
        id="employeeContactFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="employeeContactFormModal"
        :title=" $t('employeeContact.contact_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
      <validation-observer ref="simpleRules">
      <b-row>
        <b-col>
          <b-form>
            <validation-provider :name=" $t('employeeContact.contact_type')" :rules="{ excluded:0}" v-slot="{ errors }" >
              <label for="employee-contact-type-input">{{ $t('employeeContact.contact_type') }}</label>
              <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="contactTypeSelect" value-field="id" text-field="name" :options="contactTypeOption" />
              <small class="text-danger">{{ errors[0] }}</small>       
            </validation-provider>
          </b-form>
        </b-col>
        <b-col>
          <b-form>
            <label for="contact-num-input">{{ $t('employeeContact.num') }}</label>
            <b-form-group>
              <validation-provider #default="{ errors }" :name=" $t('employeeContact.num')" rules="required" maxlength="15" >
                <b-form-input size="sm" id="contact-num-input" v-model="num" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getEmployeeContactList: {type: Function},
    },
    data() {
      return {
        id: 0,
        contactTypeSelect: 0,
        contactTypeOption: [],
        employeeId:0
      }
    },
    methods: {
      check(e) {  
        this.isReceiveMail = e.target.checked;  
        }, 
        showInfo(id, employeeId) {
          this.$refs['employeeContactFormModal'].show()
        this.id = id
        this.employeeId = employeeId;
        const data = {id: this.id}
        const controller = {name : 'EmployeeContact'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.num = response.data.employeeContact.number
                this.contactTypeOption = response.data.contactTypeList
                this.contactTypeSelect = response.data.employeeContact.contactTypeId
                this.contactTypeOption.splice(0, 0, this.$nullSelected())
            } else {
                this.$bvModal.hide('employeeContactFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, employeeId: this.employeeId, number: this.num, contactTypeId: this.contactTypeSelect}
        const controller = {name : 'EmployeeContact'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('employeeContactFormModal')
                    this.getEmployeeContactList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  