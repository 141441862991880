<template>
    <div>
        <div class="table-container">
        <!-- table -->
          <vue-good-table :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" 
            :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }" 
            
            :pagination-options="{
              enabled: false,
              perPage: pageLength
            }" theme="my-theme">
              <div slot="emptystate">
                  {{ $t('dataTable.data_not_found') }}
              </div>              
              <!-- Row: Table Row -->
              <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'paymentId'" class="text-nowrap">
                  {{ $t('paymentDetail.payment_id') }}
              </span>
                <span v-else-if="props.column.field === 'invoiceId'" class="text-nowrap">
                    {{ $t('paymentDetail.invoice_id') }}
                </span>
            </template>
                  <template slot="table-row" slot-scope="props">
              <!-- Column: TranslatorPayment Transactions Name -->
    
              <span v-if="props.column.field === 'paymentId'">
                <b-form-checkbox :checked="checkStatus(props.row.paymentId)"  @change.native="save($event.target.checked, props.row)"/>
              </span>
            </template>
          </vue-good-table>
        </div>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            getPaymentList: Function
        },
        data() {
            return {
                id: 0,
                pageLength: 10,
                dir: false,
                selected: null,
                columns: [
                    {
                      label: this.$t('paymentDetail.payment_id'),
                      field: `paymentId`,
                    },
                    {
                        label: this.$t('paymentDetail.debit_no'),
                        field: `debitNo`,
                    },
                    {
                        label: this.$t('paymentDetail.total'),
                        field: `total`,
                    },
                    {
                        label: this.$t('paymentDetail.proforma_date'),
                        field: `proformaDate`,
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('paymentDetail.invoice_no'),
                        field: `invoiceNo`,
                    },
                    {
                        label: this.$t('paymentDetail.invoice_date'),
                        field: `invoiceDate`,
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('paymentDetail.employee'),
                        field: `employee`,
                    },
                    {
                        label: this.$t('paymentDetail.note'),
                        field: `note`,
                    }
                ],
                rows: [],
                searchTerm: '',
                paymentId: 0,
                selectControl: false,
                invoiceTotal:0,
            }
        },
        methods: {
            checkStatus(paymentId) {
                if(paymentId == 0)
                    return false
                else
                    return true;
            },
            getPaymentDetailList(paymentId, clientAccountingId) {
              if(paymentId == 0 && clientAccountingId == 0)
                return;
              this.paymentId = paymentId
              this.rows= []
                    const data = {
                        paymentId: this.paymentId,
                        clientAccountingId: clientAccountingId
                    }
                    const controller = {
                        name: 'Invoice',
                        actionName: 'GetInvoiceListForPayment'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                          this.rows = response.data.invoiceList

                          this.invoiceTotal = parseFloat(response.data.invoiceList.filter(x => x.paymentId > 0).reduce((n, {total}) => n + total, 0)).toFixed(2)
                          this.$emit('input', this.invoiceTotal);
                        }
                    })
            },
            save(selected, params) {
              const controller = { name: 'Invoice', actionName: 'SaveInvoiceForPayment' }
              if (selected) {
                const data = { id: params.invoiceId, paymentId: this.paymentId,}
                const payload = { data: data, controller: controller }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                  if (response.data.resultStatus == true) {
                    this.$SaveAlert()
                    this.invoiceTotal = parseFloat(this.invoiceTotal) + parseFloat(params.total)
                    this.$emit('input', this.invoiceTotal);
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
                })                
              }
              else {
                const data = { id: params.invoiceId, paymentId: 0}
                const payload = { data: data, controller: controller }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                  if (response.data.resultStatus == true) {
                    this.$SaveAlert()
                    this.invoiceTotal = parseFloat(this.invoiceTotal) - parseFloat(params.total)
                    this.$emit('input', this.invoiceTotal);
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
                })
              }       
            },
        },
    }
    </script>
    
    <style lang="scss">
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    .table-container {
      max-height: 400px; /* Tablonun maksimum yüksekliği, gereksinimlerinize göre ayarlayın */
      overflow-y: auto; /* Dikey kaydırma çubuğunu etkinleştir */
    }
    .vgt-table thead th.vgt-checkbox-col {
        vertical-align: middle;
        visibility: hidden;
    }
    </style>