<template>
    <div>
        <b-modal id="employeePowerOfAttorneyFormModal" centered size="lg" no-close-on-backdrop ref="employeePowerOfAttorneyFormModal" :title=" $t('employeePowerOfAttorney.power_of_attorney_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col-md-12 mt-2">
                        <b-form>
                            <validation-provider :name=" $t('employeePowerOfAttorney.power_attorney_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <label for="employee-powerOfAttorney-type-input">{{ $t('employeePowerOfAttorney.power_of_attorney_type') }}</label>
                                <b-form-select :state="errors.length > 0 ? false:null" size="sm" v-model="powerOfAttorneyTypeSelect" value-field="id" text-field="name" :options="powerOfAttorneyTypeOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-2">
                        <b-form>
                            <validation-provider #default="{ errors }" :name="$t('employeePowerOfAttorney.power_of_attorney_no')" rules="required">
                                    <label>{{ $t('employeePowerOfAttorney.power_of_attorney_no')  }}</label>
                                    <b-form-input size="sm" v-model="powerOfAttorneyNo"  :placeholder="$t('employeePowerOfAttorney.power_of_attorney_no')" />
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-2">
                        <b-form>
                            <validation-provider #default="{ errors }" :name="$t('employeePowerOfAttorney.power_of_attorney_year')" rules="required">
                                    <label>{{ $t('employeePowerOfAttorney.power_of_attorney_year')  }}</label>
                                    <b-form-input size="sm" v-model="powerOfAttorneyYear"  :placeholder="$t('employeePowerOfAttorney.power_of_attorney_year')" />
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            getEmployeePowerOfAttorneyList: {
                type: Function
            }
        },
        data() {
            return {
                powerOfAttorneyTypeSelect: 0,
                powerOfAttorneyTypeOption: [],
                powerOfAttorneyNo: '',
                powerOfAttorneyYear : 0,
                id: 0,
                employeeId: 0,
            }
        },
        methods: {
            showInfo(id, employeeId) {
                this.$refs['employeePowerOfAttorneyFormModal'].show()
                this.id = id
                this.employeeId = employeeId;
                if(this.employeeId == null){
                    this.employeeId = localStorage.getItem("EmployeeId")
                }
                const data = {
                    id: this.id
                }
                const controller = {
                    name: 'EmployeePowerOfAttorney'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.powerOfAttorneyYear = response.data.employeePowerOfAttorney.powerOfAttorneyYear
                        this.powerOfAttorneyNo = response.data.employeePowerOfAttorney.powerOfAttorneyNo
                        this.powerOfAttorneyTypeOption = response.data.powerOfAttorneyTypeList
                        this.powerOfAttorneyTypeSelect = response.data.employeePowerOfAttorney.powerOfAttorneyTypeId
                        this.powerOfAttorneyTypeOption.splice(0, 0, this.$nullSelected())
                    } else {
                        this.$bvModal.hide('employeePowerOfAttorneyFormModal')
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            addOrUpdate() {
                if(this.employeeId == null){
                    this.employeeId = localStorage.getItem("EmployeeId")
                }
                const data = {
                    id: this.id,
                    employeeId: this.employeeId,
                    powerOfAttorneyTypeId : this.powerOfAttorneyTypeSelect,
                    powerOfAttorneyNo : this.powerOfAttorneyNo,
                    powerOfAttorneyYear : this.powerOfAttorneyYear
                }
                const controller = {
                    name: 'EmployeePowerOfAttorney'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$bvModal.hide('employeePowerOfAttorneyFormModal')
                                this.getEmployeePowerOfAttorneyList()
                                this.$SaveAlert()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                })
            },
        }
    }
    </script>
    
    <style lang="scss">
    [dir=ltr] .modal-header .close {
        margin: -0.4rem -0.4rem -0.8rem auto;
    }
    
    [dir] label {
        margin-bottom: 0.2857rem;
        font-weight: bold;
    }
    
    .modal .modal-content {
        overflow: visible;
        margin-bottom: auto;
    }
    
    [dir] .invalid-tooltip {
        padding: 0.4rem 0.775rem !important;
        margin-top: -1.5rem !important;
        background-color: #ea5455 !important;
        border-radius: 0.358rem !important;
        left: 20px !important
    }
    </style>
    