<template>
    <div>
      <patent-bulletin-check-form :getPatentBulletinCheckList="getPatentBulletinCheckList" ref="patentBulletinCheckFormModal"></patent-bulletin-check-form>
      <patent-form ref="patentFormModal"></patent-form>
      <!-- <p-d-f-viewer ref="bulletinInfo"></p-d-f-viewer> -->
      <b-row class="align-items-center mb-1">
        <b-col class="d-flex align-items-center col-lg-8">
            <b-col>
              <label for="patentBulletinAnalyseReport-no-select">{{ $t('patentBulletinAnalyseReport.bulletin_select') }}</label>
              <b-form-select v-model="bulletinSelect" size="sm" value-field="id" text-field="no" @change="getPatentBulletinCheckList()" :options="bulletinOption" />
            </b-col>
        </b-col>
        <b-col class="d-flex justify-content-end col-lg-4 mt-2">
          <vue-excel-xlsx :data="rows" :columns="columns" :file-name="'Stock'" :file-type="'xlsx'" :sheet-name="'sheetname'" class="excel-css" :excelAuthorizationConfirm="excelAuthorizationConfirm" :excelTrueOrFalse="excelTrueOrFalse">
            {{ $t('excel.excel') }}
          </vue-excel-xlsx>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table
        id="patentBulletinCheckTable"
        ref="patentBulletinCheckTable" 
        :columns="columns"
        :rows="rows"
        styleClass="vgt-table condensed tableSize"
        :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" 
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'Record No'" class="text-nowrap">
            {{ $t('patentBulletinCheck.record_no') }}
          </span>
            <span v-else-if="props.column.label === 'Application No'" class="text-nowrap">
            {{ $t('patentBulletinCheck.application_no') }}
            </span>
            <span v-else-if="props.column.label === 'Application Date'" class="text-nowrap">
            {{ $t('patentBulletinCheck.application_date') }}
            </span>
            <span v-else-if="props.column.label === 'Stock Agent'">
              {{ $t('patentBulletinCheck.stock_agent') }}
            </span>
            <span v-else-if="props.column.label === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Client Accounting Information Name -->
            <span v-if="props.column.field === 'application_no'" class="text-nowrap">
              <span>{{ props.row.applicationNo }}</span>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
              <span>
                <b-button v-b-tooltip.html :title="$t('others.compare')" v-b-modal.modal-top variant="info"
                  class="btn-icon mb-1 ml-1" size="sm" @click="getShowInfo(props.row.patentId)">
    
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                    class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.patentId)">
      
                    <feather-icon icon="EditIcon" />
                </b-button>
                <b-button v-b-tooltip.html :title="$t('others.approve')" variant="success" class="btn-icon mb-1 ml-1"
                    size="sm" @click="addOrUpdate(props.row.patentId)">
                    <feather-icon icon="CheckSquareIcon" />
                </b-button>
              </span>
            </span>
          </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                  </span>
                  <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                  <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
              </div>
              <div>
                  <b-pagination size="sm" :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                      <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                  </b-pagination>
              </div>
          </div>
      </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        pageLength: 10,
        dir: false,
        excelTrueOrFalse: null,
        bulletinOption: [],
        bulletinSelect: 0,
        columns: [
          {
            label: 'Record No',
            field: 'recordNo',
          },
          {
            label: 'Application No',
            field: 'applicationNo',
          },
          {
            label: 'Application Date',
            field: 'applicationDate',
            formatFn: this.$formatFn,
          },
          {
            label: 'Stock Agent',
            field: 'agent',
          },
          {
            label: 'process',
            field: 'process',
            width: '19em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      const data = {}
      const controller = { name: 'Bulletin', actionName: 'GetPatentBulletinListInfo'}
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
              this.bulletinOption = response.data.tpmkPatentBulletinList
              this.bulletinSelect = response.data.lastBulletinId
              this.getPatentBulletinCheckList()

          } else {
              this.$WarningAlert(response.data.resultMessage)
          }
        })

    },
    methods: {
      async getInfo(patentId) {
        this.$refs.patentFormModal.showInfo(patentId)
      },
      async getShowInfo(patentId){
        this.$refs.patentBulletinCheckFormModal.showInfo(patentId)
      },
      getPatentBulletinCheckList() {
        this.rows = []
        const data = { bulletinId: this.bulletinSelect}
        const controller = { name: 'Bulletin', actionName: 'GetPatentBulletinList'}
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
              response.data.patentBulletinList.forEach(element => {
                this.rows.push(element)
              })
          } else {
              this.$WarningAlert(response.data.resultMessage)
          }
        })
      },
      addOrUpdate(id) {
        const data = {id: id}
        const controller = { name: 'Patent', actionName: 'ConfirmPatent' }
        const payload = { data: data, controller: controller }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.getPatentBulletinCheckList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
      },
      excelAuthorizationConfirm() {
          const controller = { name: 'Patent' }
          const payload = { controller: controller }
          this.$store.dispatch('moduleAuth/canExport', payload).then((response) => {
            if (response.data.resultStatus == true) {
              this.excelTrueOrFalse = 1
            }
            else {
              this.excelTrueOrFalse = 2
              this.$WarningAlert(response.data.resultMessage)
            }
          }
          )
      }
    },
  }
  </script>
<style>
   .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
  