<template>
<div>
    <b-modal id="employeeFormModal" centered size="xl" no-close-on-backdrop ref="employeeFormModal" :title="$t('employeeForm.form_title')" hide-footer>
        <b-card>
            <div class="custom-search d-flex justify-content-end">
                <b-row>
                    <b-col>
                        <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="addOrUpdate">
                            {{$t('others.ok_title')}}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <employee-photo-cropper-form :refreshEmployeePhoto="refreshEmployeePhoto" :image="image" :employeeId="this.id" :uploadImage="uploadImage" :employeePhotoCropperForm="employeePhotoCropperForm"></employee-photo-cropper-form>
            <b-form :hidden="hiddenEmployeePhoto">
                <b-row>
                    <b-col>
                        <b-img v-b-tooltip.html :title="$t('employeeForm.change')" style="cursor: pointer;" :src="imageSrc" v-bind="mainProps" onclick="document.getElementById('photoSelect').click()" rounded="circle" alt="Circle image" class="d-inline-block mb-1" />
                    </b-col>
                    <b-col>
                        <input hidden id="photoSelect" type="file" ref="file" @change="uploadImage($event)" accept="image/*" />
                    </b-col>
                </b-row>
            </b-form>
            <b-form>
                <validation-observer ref="simpleRules">
                    <b-row>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('employeeForm.name')" rules="required">
                                    <label>{{ $t('employeeForm.name')  }}</label>
                                    <b-form-input size="sm" v-model="name" :placeholder="$t('employeeForm.name')" />
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('employeeForm.surname')" rules="required">
                                    <label>{{ $t('employeeForm.surname')  }}</label>
                                    <b-form-input size="sm" v-model="surname" :placeholder="$t('employeeForm.surname')" />
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('employeeForm.email')" rules="required|email">
                                    <label>{{ $t('employeeForm.email')  }}</label>
                                    <b-form-input size="sm" v-model="email" :placeholder="$t('employeeForm.email')" />
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group>
                                <validation-provider :name="$t('employeeForm.title')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                                    <label>{{ $t('employeeForm.title')  }}</label>
                                    <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="titleSelect" value-field="id" text-field="name" :options="titleOption" />
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group>
                                <label for="starting-date-input">{{ $t('employeeForm.starting_date') }}</label>
                                <CustomDatePicker :datePickerValidateOption="1" v-model="startingDate"></CustomDatePicker>
                            </b-form-group>
                        </b-col>
                        <b-col class="col-lg-2 mt-1">
                            <b-form-group>
                                <label for="ending-date-input">{{ $t('employeeForm.ending_date') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="endingDate"></CustomDatePicker>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col">
                            <b-form-group>
                                <validation-provider :name="$t('employeeForm.department')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                                    <label>{{ $t('employeeForm.department')  }}</label>
                                    <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="departmentSelect" value-field="id" text-field="name" :options="departmentOption" />
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col class="col">
                            <b-form-group>
                                <validation-provider :name="$t('employeeForm.role')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                                    <label>{{ $t('employeeForm.role')  }}</label>
                                    <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="roleSelect" value-field="id" text-field="name" :options="roleOption" />
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col class="col">
                            <b-form-group>
                                <label>{{ $t('employeeForm.classification')  }}</label>
                                <b-form-select size="sm" v-model="classificationSelect" value-field="id" text-field="name" :options="classificationOption" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col">
                            <b-form-group>
                                <validation-provider :name="$t('employeeForm.position')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                                    <label>{{ $t('employeeForm.position')  }}</label>
                                    <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="positionSelect" value-field="id" text-field="name" :options="positionOption" />
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col :hidden="hiddenPermissionSelect" class="col">
                            <b-form-group>
                                <label>{{ $t('employeeForm.permission_template')  }}</label>
                                <b-form-select size="sm" v-model="permissionTemplateSelect" value-field="id" text-field="name" :options="permissionTemplateOption" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col">
                            <b-form>
                                <label for="employees-national_id_number-input">{{ $t('employees.national_id_number') }}</label>
                                <b-form-group>
                                    <b-form-input id="employees-national_id_number-input" v-model="nationalIDNumber" maxlength="11" type="number" size="sm" />
                                </b-form-group>
                            </b-form>
                        </b-col>
                        <b-col>
                            <label for="employees-birth-date-input">{{ $t('employees.birth_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" id="employees-birth-date-input" v-model="dateOfBirth"></CustomDatePicker>
                        </b-col>
                    </b-row>
                </validation-observer>
            </b-form>
            <!--EK BİLGİLER -->
            <b-row>
                <app-collapse class="ml-1 mb-1 mt-2" type="margin" style="width: 100%">
                    <app-collapse-item :title="$t('others.additional_information')">
                        <b-row>
                            <b-col class="col-md-4">
                                <b-form>
                                    <label for="employees-social-working-number-input">{{ $t('employees.sgk_no') }}</label>
                                    <b-form-group>
                                        <b-form-input id="employees-social-working-number-input" v-model="socialWorkingNumber" maxlength="13" type="number" size="sm" />
                                    </b-form-group>
                                </b-form>
                            </b-col>
                            <b-col class="col-md-4">
                                <b-form>
                                    <label>{{ $t('employees.annual_leave_right')  }}</label>
                                    <b-form-select size="sm" v-model="annualLeaveRightSelect" value-field="id" text-field="explanation" :options="annualLeaveRightOption" />
                                </b-form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-md-2 mt-1">
                                <label for="employees-is-lawyer-input">{{ $t('employees.is_lawyer') }}</label>
                                <br>
                                <b-form-checkbox class="custom-control-success" name="employees-is-lawyer-input" id="employees-is-lawyer-input" v-model="isLawyer" switch>
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </b-col>
                            <b-col v-if="isLawyer == true" class="col-md-3 mt-1">
                                <b-form>
                                    <label for="employees-lawyer-no-input">{{ $t('employees.lawyer_no') }}</label>
                                    <b-form-group>
                                        <b-form-input id="employees-lawyer-no-input" v-model="lawyerNo" maxlength="250" size="sm" />
                                    </b-form-group>
                                </b-form>
                            </b-col>
                            <b-col v-if="isLawyer == true" class="col-md-3 mt-1">
                                <label for="employees-lawyer-year-date-input">{{ $t('employees.lawyer_year') }}</label>
                                <b-form-group>
                                    <b-form-input id="employees-lawyer-year-date-input" v-model="lawyerYear" type="number" maxlength="4" size="sm" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-md-2 mt-1">
                                <label for="employees-is-retired-input">{{ $t('employees.is_retired') }}</label>
                                <br>
                                <b-form-checkbox class="custom-control-success" name="employees-is-retired-input" id="employees-is-retired-input" v-model="isRetired" switch>
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </b-col>
                            <b-col v-if="isRetired == true" class="col-md-3 mt-1">
                                <label for="employees-retired-year-date-input">{{ $t('employees.retired_year') }}</label>
                                <b-form-group>
                                    <b-form-input id="employees-retired-year-date-input" v-model="retiredYear" type="number" maxlength="4" size="sm" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-md-2 mt-1">
                                <label for="employees-is-law-input">{{ $t('employees.is_law') }}</label>
                                <br>
                                <b-form-checkbox class="custom-control-success" name="employees-is-law-input" id="employees-is-law-input" v-model="isLaw" switch>
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-row>
        </b-card>
        <b-card :hidden="hiddenEmployeeControl">
            <b-form>
                <b-row>
                    <b-col class="mt-2">
                        <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                            <b-tab :title=" $t('employee.permission')" active style="width:100%;">
                                <b-card-text>
                                    <employee-permission-table-form ref="employeePermissionTableForm" :employeeId="this.id"></employee-permission-table-form>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('employee.price_list')" style="width:100%;">
                                <b-card-text>
                                    <employee-price-list :employeeId="this.id"></employee-price-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('employee.note_permission')" style="width:100%;">
                                <b-card-text>
                                    <employee-note-list :employeeId="this.id"></employee-note-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('employee.employment_contract')" style="width:100%;">
                                <b-card-text>
                                    <employee-contract-list :employeeId="this.id"></employee-contract-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('employee.hourly_annual_leave')" style="width:100%;">
                                <b-card-text>
                                    <hourly-annual-leave-employee-list :employeeId="this.id"></hourly-annual-leave-employee-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('employee.overtime_work')" style="width:100%;">
                                <b-card-text>
                                    <overtime-work-list-for-employee :employeeId="this.id"></overtime-work-list-for-employee>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('employee.annual_leave')" style="width:100%;">
                                <b-card-text>
                                    <employee-annual-leave-list :employeeId="this.id"></employee-annual-leave-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('employee.address')" style="width:100%;">
                                <b-card-text>
                                    <employee-address-list ref="employeeAddressForm" :employeeId="this.id"></employee-address-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('employee.education_information')" style="width:100%;">
                                <b-card-text>
                                    <employee-educationInformation-list ref="employeeEducationInformation" :employeeId="this.id"></employee-educationInformation-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('employee.power_of_attorney')" style="width:100%;">
                                <b-card-text>
                                    <employee-powerOfAttorney-list ref="employeepowerOfAttorney" :employeeId="this.id"></employee-powerOfAttorney-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('employee.contact')" style="width:100%;">
                                <b-card-text>
                                    <employee-contact-list ref="employeeContact" :employeeId="this.id"></employee-contact-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('employee.language')" style="width:100%;">
                                <b-card-text>
                                    <employee-language-list ref="employeeLanguage" :employeeId="this.id"></employee-language-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title="$t('others.digital_archive')" style="width:100%;">
                                <b-card-text>
                                    <documents-list :recordId="this.id" :moduleId="17"></documents-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('others.job')" style="width:100%;">
                                <b-card-text>
                                    <job-list :recordId="this.id" :moduleId="17" :recordNo="this.name +' '+this.surname"></job-list>
                                </b-card-text>
                            </b-tab>
                            <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                                <b-card-text>
                                    <dailywork-list :recordId="this.id" :moduleId="17"></dailywork-list>
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getEmployeeList: {
            type: Function
        }
    },
    data() {
        return {
            id: null,
            employeePhotoCropperForm: {},
            hiddenEmployeePhoto: true,
            hiddenPermissionSelect: true,
            hiddenEmployeeControl: true,
            name: '',
            surname: '',
            email: '',
            titleSelect: 0,
            titleOption: [],
            departmentSelect: 0,
            departmentOption: [],
            roleSelect: 0,
            roleOption: [],
            classificationSelect: 0,
            classificationOption: [],
            positionSelect: 0,
            positionOption: [],
            permissionTemplateSelect: 0,
            permissionTemplateOption: [],
            endingDate: null,
            startingDate: null,
            dateOfBirth: null,
            imageSrc: '',
            image: null,
            mainProps: {
                blank: false,
                blankColor: '#777',
                width: 75,
                height: 75,
                class: 'm1',
            },
            phoneNumber: 0,
            socialWorkingNumber: 0,
            nationalIDNumber: 0,
            foreignLanguage: '',
            isTranslatorSelect: 0,
            isTranslatorOption: [],
            languageItTranslates: '',
            isLawyer: false,
            isLaw: false,
            lawyerNo: 0,
            lawyerYear: 0,
            isRetired: false,
            retiredYear: 0,
            personToContactinCaseOfEmergency: '',
            graduatedSchool: '',
            graduatedDepartment: '',
            graduatedYear: '',
            master: false,
            masterDepartment: '',
            annualLeaveRightOption: [],
            annualLeaveRightSelect: 0,
        }
    },
    mounted() {
        this.refreshEmployeePhoto()
    },
    methods: {
        clear() {
            this.departmentSelect = 0
            this.roleSelect = 0
            this.permissionTemplateSelect = 0
            this.classificationSelect = 0
        },
        refreshEmployeePhoto(base64Image) {
            this.imageSrc = 'data:image/png;base64,' + base64Image
        },
        uploadImage(event) {
            this.$refs.employeePhotoCropperForm.showInfo()
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.image = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        showInfo(id) {
            this.$refs['employeeFormModal'].show()
            this.id = id
            if (this.id == 0) {
                this.hiddenPermissionSelect = false
                this.hiddenEmployeePhoto = true
                this.hiddenEmployeeControl = true
            } else {
                this.hiddenPermissionSelect = true
                this.hiddenEmployeePhoto = false
                this.hiddenEmployeeControl = false
                this.imageSrc = require('@/assets/images/logoUser.jpeg')
            }
            var data = {
                id: this.id
            }
            var controller = {
                name: 'Employee'
            }
            var payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.name = response.data.employee.name
                    this.surname = response.data.employee.surname
                    this.email = response.data.employee.email
                    this.titleOption = response.data.titleList
                    this.titleSelect = response.data.employee.titleId
                    this.titleOption.splice(0, 0, this.$nullSelected("name"))
                    this.departmentOption = response.data.departmentList
                    this.departmentSelect = response.data.employee.departmentId
                    this.departmentOption.splice(0, 0, this.$nullSelected("name"))
                    this.roleOption = response.data.employeeRoleList
                    this.roleSelect = response.data.employee.roleId
                    this.roleOption.splice(0, 0, this.$nullSelected("name"))
                    this.classificationOption = response.data.employeeClassificationList
                    this.classificationSelect = response.data.employee.classificationId
                    this.classificationOption.splice(0, 0, this.$nullSelected("name"))
                    this.positionOption = response.data.positionList
                    this.positionSelect = response.data.employee.positionId
                    this.positionOption.splice(0, 0, this.$nullSelected("name"))
                    this.permissionTemplateOption = response.data.permissionTemplateList
                    this.permissionTemplateSelect = response.data.employee.permissionTemplateId
                    this.permissionTemplateOption.splice(0, 0, this.$nullSelected("name"))
                    this.startingDate = response.data.employee.startingDate
                    this.endingDate = response.data.employee.endingDate
                    this.positionOption.splice(0, 0, this.$nullSelected("name"))
                    this.nationalIDNumber = response.data.employee.nationalIDNumber
                    this.socialWorkingNumber = response.data.employee.socialWorkingNumber
                    this.isRetired = response.data.employee.isRetired
                    this.retiredYear = response.data.employee.retirementYear
                    this.lawyerYear = response.data.employee.becomeLawyerYear
                    this.lawyerNo = response.data.employee.lawyerRegistryNumber
                    this.isLawyer = response.data.employee.isLawyer
                    this.isLaw = response.data.employee.isLaw
                    this.dateOfBirth = response.data.employee.dateOfBirth
                    this.annualLeaveRightOption = response.data.annualLeaveRightList
                    this.annualLeaveRightOption.splice(0, 0, this.$nullSelected("explanation"))
                    if (response.data.employee.annualLeaveRightId > 0) {
                        this.annualLeaveRightSelect = response.data.employee.annualLeaveRightId
                    } else {
                        this.annualLeaveRightSelect = 0
                    }
                    if (this.id > 0) {
                        this.$refs.employeePermissionTableForm.showInfoPermission(this.id)
                        const data = {
                            id: this.id
                        }
                        controller = {
                            name: 'Employee',
                            actionName: 'DownloadEmployeePhoto'
                        }
                        payload = {
                            data: data,
                            controller: controller
                        }
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                if (response.data.base64 == null) {
                                    this.imageSrc = require('@/assets/images/logoUser.jpeg')
                                } else {
                                    this.imageSrc = 'data:image/png;base64,' + response.data.base64
                                }
                            }
                        })
                    }
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const data = {
                        id: this.id,
                        name: this.name,
                        surname: this.surname,
                        email: this.email,
                        titleId: this.titleSelect,
                        departmentId: this.departmentSelect,
                        roleId: this.roleSelect,
                        positionId: this.positionSelect,
                        classificationId: this.classificationSelect,
                        permissionTemplateId: this.permissionTemplateSelect,
                        startingDate: this.startingDate,
                        endingDate: this.endingDate,
                        socialWorkingNumber: this.socialWorkingNumber,
                        nationalIDNumber: this.nationalIDNumber,
                        dateOfBirth: this.dateOfBirth,
                        isRetired: this.isRetired,
                        retirementYear: this.retiredYear,
                        becomeLawyerYear: this.lawyerYear,
                        lawyerRegistryNumber: this.lawyerNo,
                        isLawyer: this.isLawyer,
                        isLaw: this.isLaw,
                        annualLeaveRightId: this.annualLeaveRightSelect
                    }
                    const controller = {
                        name: 'Employee'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getEmployeeList()
                            if (this.id == 0) {
                                this.id = response.data.employeeId
                            }

                            if (response.data.employeeId > 0) {
                                this.$refs.employeePermissionTableForm.showInfoPermission(this.id)
                            }
                            this.hiddenEmployeePhoto = false
                            this.hiddenEmployeeControl = false
                            this.hiddenPermissionSelect = true
                            this.$SaveAlert()
                            if (response.data.base64 == null) {
                                this.imageSrc = require('@/assets/images/logoUser.jpeg')
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
