<template>
    <div>
      <!-- modal -->
      <b-modal id="accountingBooksReceiptFormModal" centered size="xl" no-close-on-backdrop ref="accountingBooksReceiptFormModal" :title=" $t('accountingBooksReceipt.info')"
        :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary"  hide-footer>
        <div class="custom-search d-flex justify-content-end">
                <b-row>
                    <b-col>
                        <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="addOrUpdate">
                            {{$t('others.ok_title')}}
                        </b-button>                        
                    </b-col>
                </b-row>
            </div>
        <validation-observer ref="simpleRules">
             <b-row>
            <b-col class="col-md-4">
                <b-form>
                    <label for="accountingTransactionType-input">{{ $t('accountingBooksReceipt.accountingTransactionType') }}</label>
                    <b-form-group>
                    <validation-provider :name="$t('accountingBooksReceipt.accountingTransactionType')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                        <b-form-select id="accountingTransactionType-input" size="sm" v-model="accountingTransactionTypeSelect" value-field="id" text-field="name" :options="accountingTransactionTypeOption" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-4">
                <b-form>
                    <label for="number-input">{{ $t('accountingBooksReceipt.number') }}</label>
                    <b-form-group>
                        <b-form-input disabled size="sm" id="number-input" v-model="number" />
                    </b-form-group>
                </b-form>
            </b-col>

            <b-col class="col-md-4">
                <b-form>
                    <label for="accounting-books-receipt-payment-type-input">{{ $t('accountingBooksReceipt.payment_type') }}</label>
                    <b-form-group>
                        <b-form-select size="sm" v-model="paymentMethodSelect" value-field="id" text-field="name" :options="paymentMethodOption" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-4">
                <b-form>
                    <label for="accounting-books-receipt-document-date-input">{{ $t('accountingBooksReceipt.documentDate') }}</label>
                    <CustomDatePicker :datePickerValidateOption="1" v-model="documentDate"></CustomDatePicker>
                </b-form>
            </b-col>

            <b-col class="col-md-4">
                <b-form>
                    <label for="accounting-books-receipt-date-input">{{ $t('accountingBooksReceipt.date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="1" v-model="date"></CustomDatePicker>
                </b-form>
            </b-col>

            <b-col class="col-md-4">
                <b-form>
                    <label for="accounting-books-receipt-document-type-input">{{ $t('accountingBooksReceipt.document_type') }}</label>
                    <b-form-group>
                    <validation-provider :name="$t('accountingBooksReceipt.document_type')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                        <b-form-select size="sm" v-model="accountingTransactionDocumentTypeSelect" value-field="id" text-field="name" :options="accountingTransactionDocumentTypeOption" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-4">
                <b-form>
                    <label for="amount-input">{{ $t('accountingBooksReceipt.amount') }}</label>
                    <b-form-input disabled size="sm" id="amount-input" v-model="amount" />
                </b-form>
            </b-col>

            <b-col class="col-md-4">
                <b-form>
                    <label for="accounting-books-receipt-document-no-input">{{ $t('accountingBooksReceipt.reference') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('accountingBooksAccounts.account_no')" rules="required">
                        <b-form-input size="sm" id="accounting-books-receipt-document-no-input" v-model="reference" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-form>
            </b-col>

            <b-col class="col-md-4">
                <b-form>
                    <label for="employee-input">{{ $t('accountingBooksReceipt.employee') }}</label>
                    <b-form-group>
                        <b-form-select size="sm" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-12">
                <b-form>
                    <label for="accounting-books-receipt-description-input">{{ $t('accountingBooksReceipt.description') }}</label>
                    <b-form-group>
                        <b-form-textarea id="accounting-books-receipt-description-input" :placeholder=" $t('accountingBooksReceipt.description')" rows="3" v-model="description" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </validation-observer>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title="$t('accountingBooksReceipt.standart')" active style="width:100%;">
                            <b-card-text>
                                <accounting-books-receipt-standart-list :subsidiaryLedgerId="this.subsidiaryLedgerId" :accountingTransactionId="this.id" :getAccountingBooksList="getAccountingBooksList" :getAccountingBooksReceiptList="getAccountingBooksReceiptList"></accounting-books-receipt-standart-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getAccountingBooksList: {type: Function},
      getAccountingBooksReceiptList: {type: Function},
      subsidiaryLedgerId: {Number}
    },
    data() {
      return {
        id: 0,
        employeeSelect: 0,
        employeeOption: [],
        paymentMethodSelect: 0,
        paymentMethodOption: [],
        accountingTransactionDocumentTypeSelect: 0,
        accountingTransactionDocumentTypeOption: [],
        accountingTransactionTypeSelect: 0,
        accountingTransactionTypeOption: [],
        amount:0.0,
        number:0,
        documentDate: null,
        date: null,
        reference: '',
        description: '',
        hiddenControl: true,
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['accountingBooksReceiptFormModal'].show()
        this.id = id
        if (this.id == 0){
            this.hiddenControl = true
        }
        else {
            this.hiddenControl = false
        }
        const data = {id: this.id}
        const controller = {name : 'AccountingTransaction'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.accountingTransactionDocumentTypeOption = response.data.accountingTransactionDocumentTypeList
                this.accountingTransactionTypeOption = response.data.accountingTransactionTypeList
                this.paymentMethodOption = response.data.paymentMethodTypeList
                this.employeeOption = response.data.employeeList
                this.documentDate = response.data.accountingTransaction.documentDate
                this.date = response.data.accountingTransaction.date
                this.reference = response.data.accountingTransaction.reference
                this.description = response.data.accountingTransaction.description
                this.amount = response.data.accountingTransaction.amount
                this.number = response.data.accountingTransaction.number
                this.accountingTransactionDocumentTypeSelect = response.data.accountingTransaction.accountingTransactionDocumentTypeId
                this.accountingTransactionTypeSelect = response.data.accountingTransaction.accountingTransactionTypeId

                if (response.data.accountingTransaction.employeeId != null && response.data.accountingTransaction.employeeId != 0) {
                    this.employeeSelect = response.data.accountingTransaction.employeeId
                } else {
                        this.employeeSelect = localStorage.getItem('EmployeeId')
                }

                
                this.paymentMethodSelect = response.data.accountingTransaction.paymentMethodId

                this.accountingTransactionDocumentTypeOption.splice(0, 0, this.$nullSelected())
                this.employeeOption.splice(0, 0, this.$nullSelected())
                this.paymentMethodOption.splice(0, 0, this.$nullSelected())
                this.accountingTransactionTypeOption.splice(0, 0, this.$nullSelected())
            } else {
                this.$bvModal.hide('accountingBooksReceiptFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const data = {id: this.id, subsidiaryLedgerId: this.subsidiaryLedgerId, accountingTransactionTypeId: this.accountingTransactionTypeSelect, accountingTransactionDocumentTypeId: this.accountingTransactionDocumentTypeSelect, paymentMethodId: this.paymentMethodSelect, documentDate: this.documentDate, date: this.date, reference: this.reference,
            description: this.description, amount: this.amount, employeeId: this.employeeSelect}
        const controller = {name : 'AccountingTransaction'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/save',payload).then((response) => {
            if(response.data.resultStatus == true){
                this.getAccountingBooksList()
                this.getAccountingBooksReceiptList()
                this.$SaveAlert()
                this.id = response.data.accoutingTransactionId
                this.hiddenControl = false
            } else {
                this.$WarningAlert(response.data.resultMessage)
            }
        })
                }
            })

      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  .datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
  </style>
  