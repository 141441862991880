<template>
<div>

    <!-- modal -->
    <b-modal id="designFormModal" centered size="xl" no-close-on-backdrop ref="designFormModal" :title="$t('design.design_info')" hide-footer>
        <reminder-form ref="reminderFormModal"></reminder-form>
        <agent-note-form ref="agentNoteFormModal"></agent-note-form>
        <client-form ref="clientFormModal"></client-form>
        <p-d-f-viewer ref="bulletinInfoModal"></p-d-f-viewer>
        <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
        <b-card>
            <div class="custom-search d-flex justify-content-start">
                <b-row>
                    <b-col v-if="designMultipleCount != designMultipleApplicationInput && isMultipleApplication != false" style="color: red;">
                        {{ $t('others.multiple_application_not_match') }}
                    </b-col>
                </b-row>
            </div>
            <div class="custom-search d-flex justify-content-end">
                <b-row>
                    <b-col>
                        <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="addOrUpdate">
                            {{ $t('others.ok_title') }}
                        </b-button>
                        <b-dropdown v-if="id > 0" style="width: 4em; height: 2.1em; margin-right: 4em" size="sm" :text="$t('others.invoices')" variant="outline-primary">
                            <b-dropdown-item @click="getClientInvoiceReport(2)">{{$t('others.InvoicesToBePaid')}}: {{ invoicesToBePaid }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(3)">{{$t('others.AllInvoices')}}: {{ allInvoices }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(4)">{{$t('others.InvoicesToBePaidForThisJob')}}: {{invoicesToBePaidForThisJob}}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(1)">{{$t('others.AllInvoicesRelatedToThisJob')}}: {{ allInvoicesRelatedToThisJob }}</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown style="width: 4em; height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                            <template #button-content>
                                <feather-icon icon="PrinterIcon" size="10" class="align-middle" />
                            </template>
                            <div class="dropdown-scroll">
                                <b-dropdown-item @click="downloadDesignWordFile(wordTemplate.id, wordTemplate.name)" v-for="wordTemplate in wordTemplateList" :key="wordTemplate.id">
                                    <span>{{wordTemplate.name}}</span>
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                        <b-dropdown style="width: 4em; height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-1" size="sm">
                            <template #button-content>
                                <feather-icon icon="SendIcon" size="10" class="align-middle" />
                            </template>
                            <div class="dropdown-scroll">
                                <b-dropdown-item @click="prepareDesignMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                    <span>{{mailTemplate.explanation}}</span>
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                        <b-button variant="outline-primary" class="ml-1" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                            <feather-icon icon="BellIcon" size="10" class="align-middle" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col-md-2 mt-1">
                        <b-form>
                            <label for="design-recordNo-input">{{ $t('design.record_no') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="design-recordNo-input" v-model="recordNo" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form>
                            <label for="design-applicationNo-input">{{ $t('design.application_no') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="design-applicationNo-input" v-model="applicationNo" maxlength="12" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form-group>
                            <label for="design-applicationDate-input">{{ $t('design.application_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="applicationDate" @input="delayedPublishFromApplicationDate($event)"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form-group>
                            <label for="design-last-application-date-input">{{ $t('design.last_application_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="lastApplicationDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form-group>
                            <label for="design-instructionDate-input">{{ $t('design.instruction_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="instructionDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form>
                            <label for="design-status-input">{{ $t('design.status') }}</label>
                            <b-form-select size="sm" v-model="designStatusSelect" value-field="id" text-field="name" :options="designStatusOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <!-- 2. Satır Durum ve Bülten bilgileri -->
                    <b-col class="col-md-2 mt-1">
                        <b-form>
                            <b-form-group>
                                <label for="design-bulletinNo-input">{{ $t('design.bulletin_no') }}</label>
                                <validation-provider #default="{ errors }" rules="required" :name="$t('design.bulletin_no')">
                                    <b-input-group>
                                        <b-form-input size="sm" id="design-bulletinNo-input" v-model="bulletinNo" maxlength="50" :state="errors.length > 0 ? false : null" />
                                        <b-input-group-append>
                                            <b-button v-if="bulletinPage > 0" v-b-tooltip.html :title="$t('others.bulletin')" variant="warning" size="sm" @click="openBulletin()">
                                                <feather-icon icon="BoldIcon" />
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form-group>
                            <label for="design-bulletinDate-input">{{ $t('design.bulletin_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="bulletinDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form>
                            <label for="design-registerNo-input">{{ $t('design.register_no') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="design-registerNo-input" v-model="registerNo" maxlength="12" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form-group>
                            <label for="design-registerDate-input">{{ $t('design.register_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="registerDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form>
                            <label for="design-documentNo-input">{{ $t('design.document_no') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="design-documentNo-input" v-model="documentNo" maxlength="20" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form-group>
                            <label for="design-dateOfPublicPresentation-input">{{ $t('design.date_of_public_presentation') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="dateOfPublicPresentation"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                <!-- Row 3 Protection Deadline Date  -->
                <b-row>
                    <b-col class="col-md-2 mt-1">
                        <b-form-group>
                            <label for="design-protection-deadline-date-input">{{ $t('design.protection_deadline_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="protectionDeadlineDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <!-- Row 3  -->
                    <b-col class="col-md-2 mt-1">
                        <b-form>
                            <label for="isRequestForAdjournment-input">{{ $t('design.is_request_for_adjournment') }}</label>
                            <b-form-checkbox class="custom-control-success" name="is-request-for-adjournment-input" id="is-request-for-adjournment-input" v-model="isRequestForAdjournment" switch>
                                <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                </span>
                                <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                </span>
                            </b-form-checkbox>
                        </b-form>
                    </b-col>
                    <b-col v-if="isRequestForAdjournment == true" class="col-md-2 mt-1">
                        <b-form-group>
                            <label for="design-requested-publish-date-input">{{ $t('design.requested_publish_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="requestedPublishDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="isRequestForAdjournment == true" class="col-md-2 mt-1">
                        <b-form-group>
                            <label for="design-delayedPublishDate-input">{{ $t('design.delayed_publish_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="delayedPublishDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="isRequestForAdjournment == true" class="col-md-2 mt-1">
                        <b-form>
                            <label for="design-delayedPublishNo-input">{{ $t('design.delayed_publish_no') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="design-delayedPublishNo-input" v-model="delayedPublishNo" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col v-if="isRequestForAdjournment == true" class="col-md-2 mt-1">
                        <b-form-group>
                            <label for="design-deadline-input">{{ $t('design.deadline') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="deadline"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="isRequestForAdjournment == true" class="col-md-2 mt-1">
                        <b-form-group>
                            <label for="design-maximum-postponement-date-input">{{ $t('design.maximum_postponement_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="maximumPostponementDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-2 mb-1 mt-1">
                        <b-form>
                            <label for="isMultipleApplication-input">{{ $t('design.is_multiple_application') }}</label>
                            <b-form-checkbox class="custom-control-success" name="is-multiple-application-input" id="is-multiple-application-input" v-model="isMultipleApplication" switch>
                                <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                </span>
                                <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                </span>
                            </b-form-checkbox>
                        </b-form>
                    </b-col>
                    <b-col v-if="isMultipleApplication == true" class="col-md-2 mb-1">
                        <b-form>
                            <b-form-group>
                                <label for="design-multiple-application-input">{{ $t('design.multiple_application_input') }}</label>
                                <validation-provider #default="{ errors }" rules="required" :name="$t('design.multiple_application_input')">
                                    <b-form-input size="sm" id="design-multiple-application-input" v-model="designMultipleApplicationInput" maxlength="50" :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <!-- Row 4 Note -->
                <b-row>
                    <b-col class="col-md-12">
                        <label for="textarea-default">{{ $t('design.note') }}</label>
                        <b-form-textarea size="sm" id="textarea-default" :placeholder=" $t('design.note')" v-model="note" maxlength="500" rows="2" />
                    </b-col>
                </b-row>
                <!-- Row 5 -->
                <b-row>
                    <b-col v-if="hiddenControl == false" class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="design-agent-input"> {{ $t('design.agent') }} </label>
                            <b-input-group>
                                <validation-provider :name=" $t('design.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                    <b-form-group label-for="design-agent-input" :state="errors.length > 0 ? false:null">
                                        <v-select class="agentInput" id="design-agent-input" v-model="designAgentSelect" :options="designAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                                        </v-select>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-input-group-append>
                                    <b-dropdown style="width: 4em; height: 2.5em; margin-bottom: 1em!important" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                        <template #button-content>
                                            <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                        </template>
                                        <b-dropdown-item @click="getClientInfo(0)">
                                            <span>{{this.$t('others.add_client')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getAgentNoteInfo(designAgentSelect.clientAccountingId)">
                                            <span>{{this.$t('others.show_note')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getClientInfo(designAgentSelect.clientId)">
                                            <span>{{this.$t('others.show_agent')}}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="hiddenControl == false" class="col-lg-12">
                        <b-form>
                            <b-form-group>
                                <label for="design-agent-ref-code-input">{{ $t('design.design_agent_ref_code') }}</label>
                                <validation-provider #default="{ errors }" :name=" $t('design.design_agent_ref_code')" rules="required">
                                    <b-form-input id="design-agent-ref-code-input" size="sm" maxlength="50" v-model="agentReferenceNo" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="hiddenControl == true" class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="design-agent-input"> {{ $t('design.agent') }} </label>
                            <b-input-group>
                                <validation-provider :name=" $t('design.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                    <b-form-group label-for="design-agent-input" :state="errors.length > 0 ? false:null">
                                        <v-select class="agentInput" id="design-agent-input" v-model="designAgentSelect" :options="designAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                                        </v-select>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-input-group-append>
                                    <b-button variant="success" style="width: 4em; height: 3em; margin-bottom: 1em!important" size="sm" v-b-tooltip.html :title="$t('others.add_client')" @click="getClientInfo()">
                                        <feather-icon icon="PlusCircleIcon" />
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="hiddenControl == true" class="col-lg-12">
                        <b-form-group>
                            <label for="design-agent-ref-code-input">{{ $t('design.design_agent_ref_code') }}</label>
                            <b-input-group>
                                <validation-provider #default="{ errors }" :name=" $t('design.design_agent_ref_code')" rules="required">
                                    <b-form-input id="design-agent-ref-code-input" size="sm" maxlength="50" v-model="agentReferenceNo" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                <b-input-group-append>
                                    <b-button variant="success" style="width: 4em; height: 2.5em;" size="sm" v-b-tooltip.html :title="$t('others.show_agent')" @click="getClientInfo(domainAgentSelect.clientId)">
                                        <feather-icon icon="TerminalIcon" />
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-card>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title="$t('design.design_product')" active style="width:100%;">
                            <b-card-text>
                                <design-product-list :getDesignList="getDesignList" :getDesignDetailCount="getDesignDetailCount" :designId="this.id"></design-product-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('design.owner')" style="width:100%;">
                            <b-card-text>
                                <design-owner-list :getClientInfo="getClientInfo" :getDesignList="getDesignList" :designId="this.id"></design-owner-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('design.responsible')" style="width:100%;">
                            <b-card-text>
                                <design-responsible-list :designId="this.id" :clientId="this.clientId"></design-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('design.designer')" style="width:100%;">
                            <b-card-text>
                                <design-designer-list :designId="this.id"></design-designer-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('design.priority')" style="width:100%;">
                            <b-card-text>
                                <design-priority-list @passToLastApplicationDateFromPriorityForm="passedToLastApplicationDateFromPriorityForm" :designId="this.id"></design-priority-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('design.renewal')" style="width:100%;">
                            <b-card-text>
                                <design-renewal-list :designFormSaved="designFormSaved" :designId="this.id"></design-renewal-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('design.objection_to_us')" style="width:100%;">
                            <b-card-text>
                                <design-objectionToUs-list :designId="this.id"></design-objectionToUs-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('design.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="5"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.job')" style="width:100%;">
                            <b-card-text>
                                <job-list :recordId="this.id" :moduleId="5" :recordNo="this.recordNo"></job-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="5"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDesignList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            recordNo: '',
            isRequestForAdjournment: false,
            isMultipleApplication: false,
            delayedPublishNo: 0,
            documentNo: 0,
            bulletinNo: 0,
            lastApplicationDateFromPriority: null,
            bulletinPage: 0,
            designStatusSelect: 0,
            designStatusOption: [],
            applicationNo: 0,
            designAgentSelect: 0,
            designAgentOption: [],
            wordTemplateList: [],
            mailTemplateList: [],
            designMultipleApplicationInput: 0,
            designMultipleCount: 0,
            agentReferenceNo: '',
            hiddenControl: true,
            note: '',
            registerNo: '',
            designFormSaved: false,
            protectionDeadlineDate: null,
            dateOfPublicPresentation: null,
            instructionDate: null,
            lastApplicationDate: null,
            applicationDate: null,
            registerDate: null,
            bulletinDate: null,
            delayedPublishDate: null,
            requestedPublishDate: null,
            deadline: null,
            maximumPostponementDate: null,
            invoicesToBePaidForThisJob: 0,
            invoicesToBePaid: 0,
            allInvoices: 0,
            allInvoicesRelatedToThisJob: 0,
        }
    },
    methods: {
        getClientInvoiceReport(type) {
            this.$refs.clientInvoiceReportListCallForm.showInfo(type, this.clientAccountingId, 5, this.id)
        },
        passedToLastApplicationDateFromPriorityForm(event) {
            this.lastApplicationDateFromPriority = event
        },
        delayedPublishFromApplicationDate(date) {
            const dateObj = new Date(date)
            dateObj.setMonth(dateObj.getMonth() + 30)
            const newDate = dateObj.toISOString().slice(0, 10)
            this.delayedPublishDate = newDate
        },
        async getClientInfo() {
            this.$refs.clientFormModal.showInfo(0)
        },
        async getReminderInfo() {
            this.$refs.reminderFormModal.showInfo(0, this.id, 5)
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        async openBulletin() {
            this.$refs.bulletinInfoModal.showInfo(this.bulletinNo.toString(), this.bulletinPage, 1)
        },
        setClientId(event) {
            this.clientId = event.clientId
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.designAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        showInfo(id) {
            this.$refs['designFormModal'].show()
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Design'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.applicationDate = response.data.design.applicationDate
                    this.applicationNo = response.data.design.applicationNo
                    if (this.applicationDate != null) {
                        const dateObj = new Date(this.applicationDate)
                        dateObj.setMonth(dateObj.getMonth() + 30)
                        const newDate = dateObj.toISOString().slice(0, 10)
                        this.maximumPostponementDate = newDate
                    } else {
                        this.maximumPostponementDate = response.data.design.maximumPostponementDate
                    }
                    this.wordTemplateList = response.data.wordTemplateList
                    this.recordNo = response.data.design.recordNo
                    this.agentReferenceNo = response.data.design.agentReferenceNo
                    this.isRequestForAdjournment = response.data.design.isRequestForAdjournment
                    this.isMultipleApplication = response.data.design.isMultipleApplication
                    this.deadline = response.data.design.deadline
                    this.delayedPublishNo = response.data.design.delayedPublishNo
                    this.requestedPublishDate = response.data.design.requestedPublishDate
                    this.isRequestForAdjournment = response.data.design.isRequestForAdjournment
                    this.documentNo = response.data.design.documentNo
                    this.registerDate = response.data.design.registerDate
                    this.registerNo = response.data.design.registerNo
                    this.bulletinDate = response.data.design.bulletinDate
                    this.bulletinNo = response.data.design.bulletinNo
                    this.bulletinPage = response.data.design.bulletinPage
                    this.instructionDate = response.data.design.instructionDate
                    this.lastApplicationDate = response.data.design.lastApplicationDate
                    this.designMultipleApplicationInput = response.data.design.detailProductCount
                    this.designMultipleCount = response.data.design.desingDetailCount
                    this.dateOfPublicPresentation = response.data.design.dateOfPublicPresentation
                    this.note = response.data.design.note
                    this.designStatusSelect = response.data.design.designStatusId
                    this.designStatusOption = response.data.designStatusList
                    this.designStatusOption.splice(0, 0, this.$nullSelected())
                    this.protectionDeadlineDate = response.data.design.protectionDeadlineDate
                    if (response.data.design.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.design.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.designAgentOption = clientAccountingResponse.data.clientList
                            this.designAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.designAgentOption = []
                        this.designAgentSelect = 0;
                    }
                    if (this.id > 0) {
                        let accountingId = 0
                        if (response.data.design.clientAccountingId > 0)
                            accountingId = response.data.design.clientAccountingId
                        if (response.data.design.invoiceClientAccountingId > 0)
                            accountingId = response.data.design.invoiceClientAccountingId
                        this.clientAccountingId = accountingId

                        const invoiceData = {
                            clientAccountingId: accountingId,
                            moduleId: 5,
                            recordId: this.id
                        }
                        const invoiceController = {
                            name: 'ClientAccounting',
                            actionName: 'GetInvoiceCountByClientAccountId'
                        }
                        const invoicePayload = {
                            data: invoiceData,
                            controller: invoiceController
                        }
                        this.$store.dispatch('moduleAuth/customService', invoicePayload).then((invoiceResponse) => {
                            this.invoicesToBePaid = invoiceResponse.data.invoicesToBePaidCount
                            this.allInvoices = invoiceResponse.data.allInvoiceCount
                            this.allInvoicesRelatedToThisJob = invoiceResponse.data.allInvoicesRelatedToThisJobCount
                            this.invoicesToBePaidForThisJob = invoiceResponse.data.invoicesToBePaidForThisJobCount
                        })

                    } else {
                        this.invoicesToBePaid = 0
                        this.allInvoices = 0;
                        this.allInvoicesRelatedToThisJob = 0;
                        this.invoicesToBePaidForThisJob = 0;
                    }
                } else {
                    this.$bvModal.hide('designFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadDesignWordFile(documentId, name) {
            const data = {
                moduleId: 5,
                documentId: documentId,
                designId: this.id
            }
            const controller = {
                name: 'Design',
                actionName: 'DownloadDesignWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        getDesignDetailCount() {
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Design',
                actionName: 'GetDesignDetailCount'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.designMultipleCount = response.data.count
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.designName,
                agentReferenceNo: this.agentReferenceNo,
                clientAccountingId: this.designAgentSelect.clientAccountingId,
                isRequestForAdjournment: this.isRequestForAdjournment,
                isMultipleApplication: this.isMultipleApplication,
                deadline: this.deadline,
                delayedPublishNo: this.delayedPublishNo,
                delayedPublishDate: this.delayedPublishDate,
                requestedPublishDate: this.requestedPublishDate,
                isRequestForAdjournment: this.isRequestForAdjournment,
                documentNo: this.documentNo,
                registerDate: this.registerDate,
                registerNo: this.registerNo,
                bulletinDate: this.bulletinDate,
                bulletinNo: this.bulletinNo,
                instructionDate: this.instructionDate,
                lastApplicationDate: this.lastApplicationDate,
                applicationDate: this.applicationDate,
                applicationNo: this.applicationNo,
                designStatusId: this.designStatusSelect,
                dateOfPublicPresentation: this.dateOfPublicPresentation,
                note: this.note,
                detailProductCount: this.designMultipleApplicationInput,
                maximumPostponementDate: this.maximumPostponementDate
            }
            const controller = {
                name: 'Design'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getDesignList()
                            this.designFormSaved = true
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.designId
                                this.recordNo = response.data.recordNo
                                this.priorityFillingDate = response.data.priorityFillingDate

                                this.hiddenControl = false
                            }
                        } else {
                            this.designFormSaved = false
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
            this.designFormSaved = false
        },
        prepareDesignMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                designId: this.id
            }
            const controller = {
                name: 'Design',
                actionName: 'PrepareDesignMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] body.dark-layout .b-calendar-grid.form-control .small {
    background: #161d31;
    display: none !important;
}

.b-calendar .b-calendar-grid-help {
    color: #6e6b7b !important;
    display: none;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 50em !important;
    width: 49em !important;
}
</style>
