<template>
    <div>
        <admin-annual-leave-approve-form :getAdminAnnualLeaveList="getAdminAnnualLeaveList" ref="adminAnnualLeaveApproveFormModal"></admin-annual-leave-approve-form>
        <div class="custom-search">
            <b-row>
                <b-col class="col-md-2">
                    <label for="admin-employee-annual-leave-employee-input">{{ $t('adminAnnualLeave.employee') }}</label>
                    <div class="d-flex align-items-center">
                        <b-form-input size="sm" v-model="employee" @input="getAdminAnnualLeaveList()" :placeholder="$t('adminAnnualLeave.employee')" type="text" class="d-inline-block" />
                    </div>
                </b-col>
                <b-col class="col-md-2">
                    <label for="admin-employee-annual-leave-employee-input">{{ $t('adminAnnualLeave.status') }}</label>
                    <b-form-select v-model="adminAnnualLeaveStatusSelect" @change="getAdminAnnualLeaveList()" size="sm" value-field="id" text-field="name" :options="adminAnnualLeaveStatusOption" />
                </b-col>
                <b-col class="col-md-2">
                    <label for="admin-employee-annual-leave-employee-input">{{ $t('adminAnnualLeave.type') }}</label>
                    <b-form-select v-model="adminAnnualLeaveTypeSelect" @change="getAdminAnnualLeaveList()" size="sm" value-field="id" text-field="name" :options="adminAnnualLeaveTypeOption" />
                </b-col>
                <b-col class="col-md-2">
                    <label for="admin-employee-annual-leave-employee-input">{{ $t('adminAnnualLeave.request_date_start') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" @input="getAdminAnnualLeaveList()" v-model="requestDateStart"></CustomDatePicker>
                </b-col>
                <b-col class="col-md-2">
                    <label for="admin-employee-annual-leave-employee-input">{{ $t('adminAnnualLeave.request_date_end') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" @input="getAdminAnnualLeaveList()" v-model="requestDateEnd"></CustomDatePicker>
                </b-col>
                <b-col class="col-md-2 custom-search d-flex justify-content-end">
                    <b-row>
                        <div class="d-flex align-items-center">
                            <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                        </div>
                    </b-row>
                </b-col>
            </b-row>
        </div>
        <!-- table -->
        <vue-good-table id="adminAnnualLeaveEmployeeTable" ref="adminAnnualLeaveEmployeeTable" :columns="columns" styleClass="vgt-table condensed tableSize" :rows="rows" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                  }" :select-options="{
                    enabled: false,
                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                  }" :pagination-options="{
                    enabled: true,
                    perPage: pageLength
                  }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'name'" class="text-nowrap">
                    {{ $t('adminAnnualLeave.status') }}
                </span>
                <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>
            <!-- Column: Table Column -->
            <template slot="table-row" slot-scope="props">
                <!-- Column: Process -->
                <span v-if="props.column.field === 'process'">
                    <span>
                        <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id,employeeId)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                            <feather-icon icon="XIcon" />
                        </b-button>
                    </span>
                </span>
            </template>
    
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                        <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <!-- ,'100', '1000'
                            Bu alanları kaldıran : Nevzat PEKER 11/07/2023 -->
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getAdminAnnualLeaveList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
    </template>
    
    <script>    
    export default {
        data() {
            return {
                total: 0,
                pageLength: 10,
                dir: false,
                pageNo: 1,
                columns: [{
                        label: this.$t('adminAnnualLeave.employee'),
                        field: 'employee',
                    },
                    {
                        label: this.$t('adminAnnualLeave.admin_annual_leave_type'),
                        field: 'annualLeaveType',
                    },
                    {
                        label: this.$t('adminAnnualLeave.starting_date'),
                        field: 'startingDate',
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('adminAnnualLeave.ending_date'),
                        field: 'endingDate',
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('adminAnnualLeave.approve_description'),
                        field: 'approveDescription',
                    },
                    {
                        label: this.$t('adminAnnualLeave.request_date'),
                        field: 'requestDate',
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('adminAnnualLeave.request_description'),
                        field: 'requestDescription',
                    },
                    {
                        label: this.$t('adminAnnualLeave.admin_annual_leave_status'),
                        field: 'annualLeaveStatus',
                    },
                    {
                        label: 'Process',
                        field: 'process',
                        width: '10em'
                    },
                ],
                rows: [],
                searchTerm: '',
                tokenEmployeeId: 0,
                id: 0,
                adminAnnualLeaveStatusSelect: 0,
                adminAnnualLeaveStatusOption: [],
                adminAnnualLeaveTypeSelect: 0,
                adminAnnualLeaveTypeOption: [],
                requestDateStart: null,
                requestDateEnd: null,
                employee : '',
                employeeId : 0
            }
        },
        mounted() {
            this.getAdminAnnualLeaveList()
            if (this.employeeId == 0) {
                this.employeeId = localStorage.getItem('EmployeeId')
            }
        },
        methods: {
            async deleted(data) {
                data = {
                    id: data.id,
                    pageLength: this.pageLength,
                    pageNo: this.pageNo
                }
                const controller = {
                    name: 'AnnualLeaveRequest'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$DeleteConfirm().then(result => {
                    if (result.value) {
                        this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$DeleteAlert()
                                this.getAdminAnnualLeaveListByEmployee()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                                this.getAdminAnnualLeaveListByEmployee()
                            }
                        })
                    }
                })
            },
            async getInfo(id) {
                this.$refs.adminAnnualLeaveApproveFormModal.showInfo(id)
            },
            getAdminAnnualLeaveList() {
                this.rows = []
                const controller = {
                    name: 'AnnualLeaveRequest',
                    actionName: 'GetAnnualLeaveRequestList'
                }
                const data = {
                    pageLength: this.pageLength,
                    pageNo: this.pageNo,
                    employee: this.employee,
                    annualLeaveStatusId: this.adminAnnualLeaveStatusSelect,
                    annualLeaveTypeId: this.adminAnnualLeaveTypeSelect,
                    requestDateStart: this.requestDateStart,
                    requestDateEnd: this.requestDateEnd
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.adminAnnualLeaveStatusSelect = this.adminAnnualLeaveStatusSelect
                        this.adminAnnualLeaveStatusOption = response.data.annualLeaveStatusList
                        this.adminAnnualLeaveTypeSelect = this.adminAnnualLeaveTypeSelect
                        this.adminAnnualLeaveTypeOption = response.data.annualLeaveTypeList
                        this.adminAnnualLeaveStatusOption.splice(0, 0, this.$nullSelected())
                        this.adminAnnualLeaveTypeOption.splice(0, 0, this.$nullSelected())
                        this.total = response.data.total
                        response.data.annualLeaveRequestList.forEach(element => {
                            this.rows.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            exportExcel() {
                const controller = {
                    name: 'AnnualLeaveRequest',
                    actionName: 'CanExport'
                }
                const data = {
                    columnList: this.columns,
                    DataList: this.rows
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("AnnualLeaveDurumlari", response.data.base64, 'xlsx')
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
        },
    }
    </script>
    
    <style lang="scss">
    .excel-css {
        float: right;
        margin-right: 1em;
        width: 9.5em;
        color: #7367f0;
        border: 1px solid #7367f0;
        background-color: transparent;
        margin-left: 1em;
        height: 2em;
        border-radius: 0.358rem;
    }
    
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    .datepickerStyle{
        max-height: 2.2em;
        font-family: fangsong;
    }
    </style>
    