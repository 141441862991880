<template>
<div>
    <!-- modal -->
    <b-modal id="ledesFormModal" centered size="xl" no-close-on-backdrop ref="ledesFormModal" :title=" $t('ledes.info')" @close="clear()" hide-footer>
        <agent-note-form ref="agentNoteForm"></agent-note-form>
        <client-form ref="clientForm"></client-form>
        <div class="custom-search d-flex justify-content-end">
            <b-row>
                <b-col>
                    <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="addOrUpdate">
                        {{$t('others.ok_title')}}
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="col-md-4">
                        <validation-provider :name="$t('ledes.country')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <label for="ledes-country-input">{{ $t('ledes.country') }}</label>
                            <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="countrySelect" value-field="id" text-field="name" :options="countryOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col class="col-md-4">
                        <label for="ledes-zip-code-input">{{ $t('ledes.zip_code') }}</label>
                        <b-form-group>
                            <b-form-input id="ledes-zip-code-input" v-model="zipCode" size="sm" maxlength="50" />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-4">
                        <label for="ledes-tax-input">{{ $t('ledes.tax') }}</label>
                        <b-form-group>
                            <b-form-input id="ledes-tax-input" v-model="tax" size="sm" maxlength="50" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-4">
                        <validation-provider :name="$t('ledes.ledes_type')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <label for="ledes-type-input">{{ $t('ledes.ledes_type') }}</label>
                            <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="ledesTypeSelect" value-field="id" text-field="name" :options="ledesTypeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col class="col-md-4">
                        <validation-provider :name="$t('ledes.employee')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <label for="ledes-type-input">{{ $t('ledes.employee') }}</label>
                            <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col class="col-md-4">
                        <label for="ledes-query-input">{{ $t('ledes.query') }}</label>
                        <b-form-group>
                            <b-form-input id="ledes-query-input" v-model="query" size="sm" maxlength="50" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="ledes-agent-input"> {{ $t('ledes.agent') }} </label>
                            <b-input-group>
                                <validation-provider :name=" $t('ledes.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                    <b-form-group label-for="ledes-agent-input" :state="errors.length > 0 ? false:null">
                                        <v-select class="agentInput" id="ledes-agent-input" v-model="ledesAgentSelect" :options="ledesAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                                        </v-select>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                              <b-input-group-append>
                                <b-dropdown style="width: 4em; height: 3em; margin-bottom: 1em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                    <template #button-content>
                                        <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                    </template>
                                    <b-dropdown-item @click="getClientInfo(0)">
                                        <span>{{this.$t('others.add_client')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getClientInfo(ledesAgentSelect.clientId)">
                                        <span>{{this.$t('others.show_agent')}}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col>
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab active :title=" $t('ledes.ledes')" style="width:100%;">
                            <ledes-detail-list :getLedesList="getLedesList" :ledesId="this.id"></ledes-detail-list>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getLedesList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            ledesTypeSelect: 0,
            ledesTypeOption: [],
            countryOption: [],
            ledesAgentOption: [],
            ledesAgentSelect: 0,
            countrySelect: 0,
            hiddenControl: true,
            tax: '',
            query: '',
            zipCode: '',
            clientId: 0,
            employeeSelect:0,
            employeeOption : [],
        }
    },
    methods: {
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteForm.getNoteListByAccountingId(clientAccountingId)
        },
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
            } else {
                this.clientId = null;
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.ledesAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        clear() {
            this.query = '';
            this.ledesTypeSelect = 0;
            this.countrySelect = 0;
            this.ledesAgentSelect = 0;
            this.tax = '';
            this.hiddenControl = true;
            this.employeeSelect = 0;
        },
        showInfo(id) {
            this.$refs['ledesFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Ledes'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.employeeOption = response.data.employeeList
                    this.countryOption = response.data.countryList
                    this.ledesTypeOption = response.data.ledesTypeList
                    this.countryOption.splice(0, 0, this.$nullSelected('name'))
                    this.ledesTypeOption.splice(0, 0, this.$nullSelected('name'))
                    this.employeeOption.splice(0, 0, this.$nullSelected('name'))
                    this.query = response.data.ledes.query
                    this.tax = response.data.ledes.tax
                    this.name = response.data.ledes.name
                    this.zipCode = response.data.ledes.zipCode
                    if (response.data.ledes.id > 0) {
                        this.hiddenControl = false
                    } else {
                        this.hiddenControl = true
                    }
                    if (response.data.ledes.ledesTypeId > 0) {
                        this.ledesTypeSelect = response.data.ledes.ledesTypeId;
                    }
                    if (response.data.ledes.countryId > 0) {
                        this.countrySelect = response.data.ledes.countryId;
                    }
                    if(response.data.ledes.employeeId > 0){
                        this.employeeSelect = response.data.ledes.employeeId;
                    }
                    else {
                        this.employeeSelect = localStorage.getItem('EmployeeId');
                    }
                    if (response.data.ledes.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.ledes.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.ledesAgentOption = clientAccountingResponse.data.clientList
                            this.ledesAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.ledesAgentOption = []
                        this.ledesAgentSelect = 0;
                    }
                } else {
                    this.$bvModal.hide('ledesFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.name,
                countryId: this.countrySelect,
                ledesTypeId: this.ledesTypeSelect,
                clientAccountingId: this.ledesAgentSelect.clientAccountingId,
                zipCode: this.zipCode,
                employeeId: localStorage.getItem('EmployeeId'),
                tax : this.tax,
                query : this.query,
                employeeId : this.employeeSelect
            }
            const controller = {
                name: 'Ledes'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.hiddenControl = false
                            this.id = response.data.ledesId
                            this.getLedesList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.countrySelect = 0,
                                this.ledesTypeSelect = 0,
                                this.ledesAgentSelect = 0,
                                this.employeeSelect = 0
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
.agentInput{
    max-width: 87em!important;
    width: 86em!important;
}
</style>
