<template>
<div>
    <wipo-xml-form :getPatentList="getPatentList" ref="wipoXmlForm"></wipo-xml-form>
    <!-- modal -->
    <b-modal id="patentWipoXmlUploadFormModal" centered size="lg" no-close-on-backdrop ref="patentWipoXmlUploadFormModal" :title=" $t('patent.wipo_xml')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.upload')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" @close="clear">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col>
                    <!-- <validation-provider
                        #default="{ errors }"
                        :name=" $t('patent.select_xml')"
                        rules="required"
                    > -->
                    <label for="patentXml-input">{{ $t('patent.xml_name') }}</label>
                    <b-input-group>
                        <b-form-input id="patentXml-input" v-model="xmlName" size="sm" maxlength="50" />
                        <!-- :state="errors.length > 0 ? false:null" -->

                        <b-input-group-append>
                            <b-button variant="outline-primary" size="sm" onclick="document.getElementById('patentWipoXmlUploadFileSelect').click()">Seçiniz</b-button>
                        </b-input-group-append>

                    </b-input-group>

                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
                </b-col>
                <b-form-file style="display:none" id="patentWipoXmlUploadFileSelect" size="sm" accept=".xml" @change="uploadFile" :placeholder=" $t('patent.xml_select')" :drop-placeholder=" $t('patent.xml_select_info')" />
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getPatentList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            xmlName: '',
            wordFile: '',
            fileInfo: [],
            xmlPatent: {},
            patentNo: '',
            patentKindSelect: 'B1',
            patentKindOption: [{
                    value: 'A1',
                    text: 'A1'
                },
                {
                    value: 'A2',
                    text: 'A2'
                },
                {
                    value: 'B1',
                    text: 'B1'
                },
                {
                    value: 'B2',
                    text: 'B2'
                },
                {
                    value: 'B3',
                    text: 'B3'
                },
                {
                    value: 'B8',
                    text: 'B8'
                },
                {
                    value: 'B9',
                    text: 'B9'
                },
            ],
        }
    },
    methods: {
        showInfo() {
          this.$refs['patentWipoXmlUploadFormModal'].show()
      },
        async uploadFile(e) {
            var fileInfo = await this.$getFileInfo(e.target.files[0])
            this.xmlName = fileInfo.name
            this.wordFile = fileInfo.base64
        },
        addOrUpdate() {
            const data = {
                name: this.xmlName,
                base64: this.wordFile
            }
            const controller = {
                name: 'Patent',
                actionName: 'ReadFromWipoXML'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.xmlPatent = response.data
                    this.$refs.wipoXmlForm.showInfo(this.xmlPatent)
                    this.$bvModal.hide('patentWipoXmlUploadFormModal')
                } else {
                    this.$WarningAlert(response.data.resultMessage)

                }
            })
        },
        clear() {
            this.fileInfo = []
        }
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: end !important;
    width: 100%;
}
</style>
