<template>
<div>
    <!-- modal -->
    <b-modal id="nationalTrademarkFormModal" centered size="xl" no-close-on-backdrop ref="nationalTrademarkFormModal" :title=" $t('nationalTrademark.national_trademark_info')" hide-footer @close="clear()">
        <agent-note-form ref="agentNoteFormModal"></agent-note-form>
        <client-form ref="clientFormModal"></client-form>
        <reminder-form ref="reminderFormModal"></reminder-form>
        <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
        <div class="custom-search d-flex justify-content mb-1">
            <b-row style="margin-right: auto;">
                <b-media no-body>
                    <b-media-aside>
                        <b-img :src="image" style="height:auto;" class="uploading-image" />
                    </b-media-aside>
                    <b-media-body>
                        <b-dropdown style="width: 4em; height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                            <template #button-content>
                                <feather-icon icon="DownloadIcon" size="10" class="align-middle" />
                            </template>
                            <b-form-file ref="refInputEl" accept=".jpg, .png, .gif, .jpeg" :hidden="true" plain @change="uploadImage" />
                            <b-dropdown-item @click="$refs.refInputEl.$el.click()" size="sm">
                                <span> {{$t('others.select_image')}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="createLogo" size="sm">
                                <span> {{$t('others.create')}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="downloadLogo" size="sm">
                                <span> {{$t('others.download')}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-media-body>
                </b-media>
            </b-row>
            <b-row style="margin-left: auto;">
                <b-col>
                    <b-button variant="outline-primary" size="sm" class="ml-05" @click.prevent="addOrUpdate">
                        {{$t('others.ok_title')}}
                    </b-button>
                    <b-dropdown v-if="id > 0" style="height: 2.1em;" class="ml-05" size="sm" :text="$t('others.invoices')" variant="outline-primary">
                        <b-dropdown-item @click="getClientInvoiceReport(2)">{{$t('others.InvoicesToBePaid')}}: {{ invoicesToBePaid }}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(3)">{{$t('others.AllInvoices')}}: {{ allInvoices }}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(4)">{{$t('others.InvoicesToBePaidForThisJob')}}: {{invoicesToBePaidForThisJob}}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(1)">{{$t('others.AllInvoicesRelatedToThisJob')}}: {{ allInvoicesRelatedToThisJob }}</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                        <template #button-content>
                            <feather-icon icon="PrinterIcon" size="10" class="align-middle" />
                        </template>
                        <div class="dropdown-scroll">
                            <b-dropdown-item @click="downloadNationalTrademarkWordFile(wordTemplate.id, wordTemplate.name)" v-for="wordTemplate in wordTemplateList" :key="wordTemplate.id">
                                <span>{{wordTemplate.name}}</span>
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                        <template #button-content>
                            <feather-icon icon="SendIcon" size="10" class="align-middle" />
                        </template>
                        <div class="dropdown-scroll">
                            <b-dropdown-item @click="prepareNationalTrademarkMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                <span>{{mailTemplate.explanation}}</span>
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <b-button variant="outline-primary" class="ml-05" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                        <feather-icon icon="BellIcon" size="10" class="align-middle" />
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <b-row>
            <b-col class="col-md-4">
                <b-form>
                    <label for="national-trademark-record-no-input">{{ $t('nationalTrademark.national_trademark_folder_no') }}</label>
                    <b-form-group>
                        <b-form-input id="national-trademark-record-no-input" size="sm" v-model="nationalTrademarkRefCode" disabled />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-4">
                <validation-observer ref="simpleRules">
                    <b-form>
                        <label for="national-trademark-name-input">{{ $t('nationalTrademark.name') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('nationalTrademark.name')" rules="required">
                                <b-form-input id="national-trademark-name-input" size="sm" v-model="name" maxlength="50" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </b-col>
            <b-col class="col-md-4">
                <b-form>
                    <label for="national-trademark-name-en-input">{{ $t('nationalTrademark.name_by_client') }}</label>
                    <b-form-group>
                        <b-form-input id="national-trademark-name-en-input" size="sm" v-model="nameByClient" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-4">
                <b-form>
                    <label for="national-trademark-application-type-input">{{ $t('nationalTrademark.trademark_application_type') }}</label>
                    <b-form-select v-model="trademarkApplicationTypeSelect" value-field="id" size="sm" text-field="name" :options="trademarkApplicationTypeOption" />
                </b-form>
            </b-col>
            <b-col class="col-md-4">
                <b-form>
                    <label for="national-trademark-application-type-input">{{ $t('nationalTrademark.national_trademark_application') }}</label>
                    <b-form-select :disabled="id > 0" @change="countryTypeSelect($event)" v-model="nationalTrademarkApplicationTypeSelect" value-field="id" size="sm" text-field="name" :options="nationalTrademarkApplicationTypeOption" />
                </b-form>
            </b-col>
            <b-col class="col-md-4">
                <label for="national-trademark-instruction-date-input">{{ $t('nationalTrademark.instruction_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="instructionDate"></CustomDatePicker>
            </b-col>
        </b-row>
        <b-row>
            <!--ToDo buraya bak, hata var-->
            <div class="mb-1">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <b-card class="checkbox-scroll">
                                <b-form-checkbox-group :disabled="this.id > 0 && this.nationalTrademarkApplicationTypeSelect == 1" v-model="countrySelect">
                                    <b-form-checkbox class="checkbox-container" v-for="(country, index) in countryOption" :key="index" :value="country.id" :disabled="isChecked(country.id)">
                                        {{ country.name }}
                                    </b-form-checkbox>
                                </b-form-checkbox-group>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>
        </b-row>
        <b-row v-if="hiddenControl == false">
            <b-col class="col-lg-12 mt-1">
                <b-form-group>
                    <label for="national-trademark-agent-input"> {{ $t('nationalTrademark.agent') }} </label>
                    <b-input-group class="d-flex align-items-center">
                        <b-form-group label-for="national-trademark-agent-input" class="flex-grow-1 mb-0">
                            <v-select id="national-trademark-agent-input" v-model="nationalTrademarkAgentSelect" :options="nationalTrademarkAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                        </b-form-group>
                        <b-input-group-append>
                            <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                <template #button-content>
                                    <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                </template>
                                <b-dropdown-item @click="getClientInfo(0)">
                                    <span>{{this.$t('others.add_client')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getAgentNoteInfo(nationalTrademarkAgentSelect.clientAccountingId)">
                                    <span>{{this.$t('others.show_note')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getClientInfo(nationalTrademarkAgentSelect.clientId)">
                                    <span>{{this.$t('others.show_agent')}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-else-if="hiddenControl == true">
            <b-col class="col-lg-12 mt-1">
                <b-form-group>
                    <label for="national-trademark-agent-input"> {{ $t('nationalTrademark.agent') }} </label>
                    <b-input-group class="d-flex align-items-center">
                        <b-form-group label-for="national-trademark-agent-input" class="flex-grow-1 mb-0">
                            <v-select id="national-trademark-agent-input" v-model="nationalTrademarkAgentSelect" :options="nationalTrademarkAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                        </b-form-group>
                        <b-input-group-append>
                            <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                <template #button-content>
                                    <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                </template>
                                <b-dropdown-item @click="getClientInfo(0)">
                                    <span>{{this.$t('others.add_client')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getClientInfo(nationalTrademarkAgentSelect.clientId)">
                                    <span>{{this.$t('others.show_agent')}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12 mt-1">
                <b-form>
                    <b-form-group :label="$t('nationalTrademark.invoice_client_accounting')" label-for="nationalTrademarkInvoiceClientAccounting">
                        <b-input-group class="d-flex align-items-center">
                            <b-form-group label-for="national-trademark-agent-input" class="flex-grow-1 mb-0">
                                <v-select id="nationalTrademarkInvoiceClientAccounting" v-model="nationalTrademarkInvoiceClientSelect" :options="nationalTrademarkInvoiceClientOption" @input="setClientIdWithInvoiceClientCheck" label="clientName" @search="onInvoiceClientAccountingSearch">
                                </v-select>
                            </b-form-group>
                            <b-input-group-append>
                                <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                    <template #button-content>
                                        <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                    </template>
                                    <b-dropdown-item @click="getClientInfo(0)">
                                        <span>{{this.$t('others.add_client')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getAgentNoteInfo(nationalTrademarkInvoiceClientSelect.clientAccountingId)">
                                        <span>{{this.$t('others.show_note')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getClientInfo(nationalTrademarkInvoiceClientSelect.clientId)">
                                        <span>{{this.$t('others.show_agent')}}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12 mt-1">
                <b-form>
                    <label for="national-trademark-agent-ref-code-input">{{ $t('nationalTrademark.national_trademark_agent_ref_code') }}</label>
                    <b-form-group>
                        <b-form-input id="national-trademark-agent-ref-code-input" v-model="agentReferenceNo" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab active :title=" $t('nationalTrademark.country_and_history')" style="width:100%;">
                            <b-card-text>
                                <national-trademark-country-list ref="childComponentReference" :getNationalTrademarkList="getNationalTrademarkList" :nationalTrademarkApplicationTypeSelect="nationalTrademarkApplicationTypeSelect" :nationalTrademarkId="this.id"></national-trademark-country-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('nationalTrademark.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="15"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('nationalTrademark.owner')" style="width:100%;">
                            <b-card-text>
                                <national-trademark-owner-list :getClientInfo="getClientInfo" :getNationalTrademarkList="getNationalTrademarkList" :nationalTrademarkId="this.id"></national-trademark-owner-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('nationalTrademark.responsible')" style="width:100%;">
                            <b-card-text>
                                <national-trademark-responsible-list :nationalTrademarkId="this.id" :clientId="this.clientId"></national-trademark-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('nationalTrademark.nice_class')" style="width:100%;">
                            <b-card-text>
                                <national-trademark-nice-class-list :getNationalTrademarkList="getNationalTrademarkList" :nationalTrademarkId="this.id"></national-trademark-nice-class-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('nationalTrademark.renewals')" style="width:100%;">
                            <b-card-text>
                                <national-trademark-renewals-list :getNationalTrademarkList="getNationalTrademarkList" :nationalTrademarkId="this.id"></national-trademark-renewals-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.job')" style="width:100%;">
                            <b-card-text>
                                <job-list :recordId="this.id" :moduleId="15" :recordNo="this.nationalTrademarkRefCode"></job-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="15"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        nationalTrademarkId: Number,
        getNationalTrademarkList: {
            type: Function
        },
        generalData: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            id: 0,
            clientId: 0,
            nationalTrademarkRefCode: '',
            name: '',
            nameByClient: '',
            trademarkApplicationTypeSelect: 0,
            trademarkApplicationTypeOption: [],
            nationalTrademarkApplicationTypeSelect: 0,
            nationalTrademarkApplicationTypeOption: [],
            instructionDate: null,
            nationalTrademarkAgentSelect: 0,
            nationalTrademarkAgentOption: [],
            agentReferenceNo: '',
            hiddenControl: true,
            wordTemplateList: [],
            mailTemplateList: [],
            nationalTrademarkInvoiceClientSelect: 0,
            nationalTrademarkInvoiceClientOption: [],
            countryOption: [],
            countrySelect: [],
            countrySelectControl: [],
            countriesId: [],
            image: null,
            invoicesToBePaidForThisJob: 0,
            invoicesToBePaid: 0,
            allInvoices: 0,
            allInvoicesRelatedToThisJob: 0,
            clientAccountingId: 0
        }
    },
    methods: {
        getClientInvoiceReport(type) {
            this.$refs.clientInvoiceReportListCallForm.showInfo(type, this.clientAccountingId, 15, this.id)
        },
        isChecked(countryId) {
            return this.countrySelectControl.includes(countryId);
        },
        uploadImage(event) {
            var input = event.target;
            if (this.id != 0) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.image = e.target.result;
                        var base64 = this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                        const data = {
                            fileName: this.nationalTrademarkId,
                            base64: base64
                        }
                        const controller = {
                            name: 'NationalTrademark',
                            actionName: 'SaveNationalTrademarkLogo'
                        };
                        const payload = {
                            data: data,
                            controller: controller
                        };
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.getNationalTrademarkList()
                                this.$PleaseClickSave()
                            }
                        })
                    }
                    reader.readAsDataURL(input.files[0]);
                }
            } else if (this.id == 0) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.image = e.target.result;
                    };
                    reader.readAsDataURL(input.files[0]);
                }
            }
        },
        createLogo() {
            const data = {
                id: this.id,
                name: this.name
            }
            const controller = {
                name: 'NationalTrademark',
                actionName: 'CreateNationalTrademarkLogo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.getNationalTrademarkList()
                    this.$SaveAlert()
                    this.image = 'data:image/jpeg;base64,' + response.data.base64
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadLogo() {
            const data = {
                id: this.id
            }
            const controller = {
                name: 'NationalTrademark',
                actionName: 'DownloadNationalTrademarkLogo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    const link = document.createElement('a')
                    link.href = 'data:image/jpeg;base64,' + response.data.base64
                    link.download = this.Id + '.jpg'
                    link.click()
                    this.getNationalTrademarkList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        onInvoiceClientAccountingSearch(invoiceClientAccountingSearch, loading) {
            if (invoiceClientAccountingSearch.length) {
                loading(true);
                this.invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, this);
            }
        },
        invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, vm) {
            if (invoiceClientAccountingSearch.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: invoiceClientAccountingSearch,
                    countryIdList: this.countrySelect
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientNameAndCountryId'
                }

                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.nationalTrademarkInvoiceClientOption = response.data.clientList
                    loading(false)
                })
            }
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        async getReminderInfo() {
            this.$refs.reminderFormModal.showInfo(0, this.id, 2)
        },
        setClientId(event) {
            this.clientId = event.clientId
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search,
                    countryIdList: this.countrySelect
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientNameAndCountryId'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.nationalTrademarkAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        countryTypeSelect(selected) {
            this.countryOption = []

            const countryData = {
                applicationTypeId: selected,
                nationalTrademarkId: this.id
            }
            const countryController = {
                name: 'NationalTrademarkCountry',
                actionName: 'GetNationalTrademarkCountryListByNationalTrademarkId'
            }
            const countryPayload = {
                data: countryData,
                controller: countryController
            }
            this.$store.dispatch('moduleAuth/customService', countryPayload).then((countryResponse) => {
                this.countryOption = countryResponse.data.countryList
                if (countryResponse.data.countryList != null) {
                    this.countryOption = []
                    countryResponse.data.countryList.forEach(element => {
                        this.countryOption.push(element)
                    })
                }
            })
        },
        clear() {
            this.image = null
        },
        showInfo(id) {
            this.$refs['nationalTrademarkFormModal'].show()
            this.countryOption = []
            this.countrySelectControl = []
            this.countrySelect = []
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'NationalTrademark'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.name = response.data.nationalTrademark.name
                    this.nameByClient = response.data.nationalTrademark.nameByClient
                    this.nationalTrademarkApplicationTypeSelect = response.data.nationalTrademark.nationalTrademarkApplicationTypeId
                    this.nationalTrademarkApplicationTypeOption = response.data.nationalTrademarkApplicationTypeList
                    this.trademarkApplicationTypeSelect = response.data.nationalTrademark.trademarkApplicationTypeId
                    this.trademarkApplicationTypeOption = response.data.trademarkApplicationTypeList
                    this.instructionDate = response.data.nationalTrademark.instructionDate
                    this.nationalTrademarkRefCode = response.data.nationalTrademark.recordNo
                    this.agentReferenceNo = response.data.nationalTrademark.agentReferenceNo
                    this.wordTemplateList = response.data.wordTemplateList
                    this.mailTemplateList = response.data.mailTemplateList
                    var nonBase64Image = 'data:image/jpeg;base64,'.concat(response.data.nationalTrademark.logo)
                    this.image = nonBase64Image
                    if (this.nationalTrademarkApplicationTypeOption != []) {
                        this.nationalTrademarkApplicationTypeOption.splice(0, 0, this.$nullSelected())
                    }
                    // Country 
                    if (response.data.nationalTrademark.nationalTrademarkApplicationTypeId != null) {
                        const countryData = {
                            applicationTypeId: response.data.nationalTrademark.nationalTrademarkApplicationTypeId,
                            nationalTrademarkId: this.id
                        }
                        const countryController = {
                            name: 'NationalTrademarkCountry',
                            actionName: 'GetNationalTrademarkCountryListByNationalTrademarkId'
                        }
                        const countryPayload = {
                            data: countryData,
                            controller: countryController
                        }
                        this.$store.dispatch('moduleAuth/customService', countryPayload).then((countryResponse) => {
                            this.countryOption = countryResponse.data.countryList
                            if (countryResponse.data.countryList != null) {
                                countryResponse.data.nationalTrademarkCountryList.forEach(element => {
                                    this.countrySelect.push(element.countryId)
                                })

                                this.countrySelectControl = this.countrySelect;
                            }
                        })
                    }
                    if (response.data.nationalTrademark.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.nationalTrademark.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.nationalTrademarkAgentOption = clientAccountingResponse.data.clientList
                            this.nationalTrademarkAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.nationalTrademarkAgentOption = []
                        this.nationalTrademarkAgentSelect = 0;
                    }

                    if (response.data.nationalTrademark.invoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.nationalTrademark.invoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.nationalTrademarkInvoiceClientOption = clientAccountingResponse.data.clientList
                            this.nationalTrademarkInvoiceClientSelect = clientAccountingResponse.data.clientList[0]
                        })
                    } else {
                        this.nationalTrademarkInvoiceClientOption = []
                        this.nationalTrademarkInvoiceClientSelect = 0;
                    }
                    if (this.id > 0) {
                        let accountingId = 0
                        if (response.data.nationalTrademark.clientAccountingId > 0)
                            accountingId = response.data.nationalTrademark.clientAccountingId
                        if (response.data.nationalTrademark.invoiceClientAccountingId > 0)
                            accountingId = response.data.nationalTrademark.invoiceClientAccountingId
                        this.clientAccountingId = accountingId

                        const invoiceData = {
                            clientAccountingId: accountingId,
                            moduleId: 15,
                            recordId: this.id
                        }
                        const invoiceController = {
                            name: 'ClientAccounting',
                            actionName: 'GetInvoiceCountByClientAccountId'
                        }
                        const invoicePayload = {
                            data: invoiceData,
                            controller: invoiceController
                        }
                        this.$store.dispatch('moduleAuth/customService', invoicePayload).then((invoiceResponse) => {
                            this.invoicesToBePaid = invoiceResponse.data.invoicesToBePaidCount
                            this.allInvoices = invoiceResponse.data.allInvoiceCount
                            this.allInvoicesRelatedToThisJob = invoiceResponse.data.allInvoicesRelatedToThisJobCount
                            this.invoicesToBePaidForThisJob = invoiceResponse.data.invoicesToBePaidForThisJobCount
                        })

                    } else {
                        this.invoicesToBePaid = 0
                        this.allInvoices = 0;
                        this.allInvoicesRelatedToThisJob = 0;
                        this.invoicesToBePaidForThisJob = 0;
                    }
                } else {
                    this.$bvModal.hide('nationalTrademarkFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadNationalTrademarkWordFile(documentId, name) {
            const data = {
                moduleId: 2,
                documentId: documentId,
                nationalTrademarkId: this.id
            }
            const controller = {
                name: 'NationalTrademark',
                actionName: 'DownloadNationalTrademarkWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addDetail(selected, countryId) {
            const controller = {
                name: 'NationalTrademarkCountry'
            }
            if (selected == true) {
                const data = {
                    nationalTrademarkId: this.nationalTrademarkId,
                    countryId: countryId
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$SaveAlert()
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
        },
        addOrUpdate() {
            if (this.nationalTrademarkInvoiceClientSelect === null) {
                this.invoiceClientId = 0
            } else {
                this.invoiceClientId = this.nationalTrademarkInvoiceClientSelect.clientAccountingId
            }

            const data = {
                id: this.id,
                name: this.name,
                nameByClient: this.nameByClient,
                nationalTrademarkRefCode: this.nationalTrademarkRefCode,
                trademarkApplicationTypeId: this.trademarkApplicationTypeSelect,
                nationalTrademarkApplicationTypeId: this.nationalTrademarkApplicationTypeSelect,
                instructionDate: this.instructionDate,
                agentReferenceNo: this.agentReferenceNo,
                countryIdList: this.countrySelect,
                clientAccountingId: this.nationalTrademarkAgentSelect.clientAccountingId,
                invoiceClientAccountingId: this.invoiceClientId,
                logo: this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
            }
            const controller = {
                name: 'NationalTrademark'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (this.id == 0) {
                        var base64 = this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                        const data = {
                            fileName: this.nationalTrademarkId,
                            base64: base64
                        }
                        const controller = {
                            name: 'NationalTrademark',
                            actionName: 'SaveNationalTrademarkLogo'
                        }
                        const payload = {
                            data: data,
                            controller: controller
                        }
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.getNationalTrademarkList()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getNationalTrademarkList()
                            this.$refs.childComponentReference.getNationalTrademarkCountryList();
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.nationalTrademarkId
                                this.nationalTrademarkRefCode = response.data.recordNo
                                this.hiddenControl = false
                            }
                            const saveCountryData = {
                                nationalTrademarkId: this.id,
                                countryIdList: this.countrySelect
                            }
                            const saveCountryController = {
                                name: 'NationalTrademarkCountry'
                            }
                            const saveCountryPayload = {
                                data: saveCountryData,
                                controller: saveCountryController
                            }
                            this.$store.dispatch('moduleAuth/save', saveCountryPayload).then((saveCountryResponse) => {
                                if (saveCountryResponse.data.resultStatus == true) {
                                    this.getNationalTrademarkList()
                                    this.$refs.childComponentReference.getNationalTrademarkCountryList();
                                } else {
                                    this.$WarningAlert(response.data.resultMessage)
                                }
                            })
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        prepareNationalTrademarkMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                nationalTrademarkId: this.id
            }
            const controller = {
                name: 'NationalTrademark',
                actionName: 'PrepareNationalTrademarkMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        setClientIdWithInvoiceClientCheck(event) {
            if (event != null) {
                if (this.nationalTrademarkInvoiceClientSelect.forInvoice == false) {
                    this.clientId = null;
                    this.nationalTrademarkInvoiceClientSelect = null;
                    this.$ForInvoiceAlert()
                } else {
                    this.clientId = event.clientId
                }
            } else {
                this.clientId = null;
            }
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] body.dark-layout .b-calendar-grid.form-control .small {
    background: #161d31;
    display: none !important;
}

.b-calendar .b-calendar-grid-help {
    color: #6e6b7b !important;
    display: none;
}

.uploading-image {
    display: flex;
    width: 15em;
    height: 10em;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    /* Satırların sonunda bir sonraki satıra geçmesini sağlar */
}

.checkbox-container .custom-control {
    flex-basis: calc(100% / 7);
    /* Her satırda 7 eleman olacak şekilde hesaplanır */
    padding: 1px;
}

/* Scroll içine almak için bir scroll kutusu oluşturuyoruz */
.checkbox-scroll {
    max-height: 200px;
    /* İstenilen yüksekliği belirleyin */
    overflow-y: auto;
    /* Dikey yönde scrollbar görünür */
}

[dir=ltr] .custom-control-inline {
    margin-right: 1rem !important;
    margin-top: 1em !important;
    display: inline-table !important;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
