<template>
    <div>
        <patent-Division-form :getPatentList="getPatentList" :getPatentDivisionList="getPatentDivisionList"
            ref="patentDivisionFormModal">
        </patent-Division-form>
        <b-row>
            <b-col class="col-12">
                <div style="float:right;" class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <b-button style="float:right;" size="sm" variant="outline-primary" @click="getInfo(0)">{{
                                $t('patentDivision.division_add') }}</b-button>
                        </div>
                    </b-form-group>
                </div>
            </b-col>
        </b-row>
        <!-- table -->
        <vue-good-table id="patentDivisionTable" ref="patentDivisionTable" :columns="columns" :rows="rows"
            styleClass="vgt-table condensed tableSize" :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'docNumber'" class="text-nowrap">
                    {{ $t('patentDivision.doc_number') }}
                </span>
                <span v-else-if="props.column.field === 'country'" class="text-nowrap">
                    {{ $t('patentDivision.country') }}
                </span>
                <span v-else-if="props.column.field === 'kind'" class="text-nowrap">
                    {{ $t('patentDivision.kind') }}
                </span>
                <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>
            <!-- Column: Table Column -->
            <template slot="table-row" slot-scope="props">
                <!-- Column: Process -->
                <span v-if="props.column.field === 'process'">
                    <span>
                        <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                            class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                            size="sm" @click="deleted(props.row)">
                            <feather-icon icon="XIcon" />
                        </b-button>
                    </span>
                </span>
            </template>
        </vue-good-table>
    </div>
</template>
  
<script>
export default {
    props: {
        patentId: Number,
        getPatentList: { type: Function },
    },
    data() {
        return {
            dir: false,
            columns: [
                {
                    label: this.$t('patentDivision.doc_number'),
                    field: 'docNumber',
                },
                {
                    label: this.$t('patentDivision.country'),
                    field: `country`,
                },
                {
                    label: this.$t('patentDivision.kind'),
                    field: `kind`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getPatentDivisionList()
    },
    methods: {
        async deleted(data) {
            data = { id: data.id }
            const controller = { name: 'PatentDivision' }
            const payload = { data: data, controller: controller }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getPatentDivisionList()
                            this.getPatentList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getPatentDivisionList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.patentDivisionFormModal.showInfo(id, this.patentId)
        },
        getPatentDivisionList() {
            this.rows = []
            const data = { id: this.patentId }
            const controller = { name: 'PatentDivision', actionName: 'GetPatentDivisionListByPatentId' }
            const payload = { data: data, controller: controller }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.patentDivisionList.forEach(element => {
                        this.rows.push(element)
                    })
                }
                else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            }
            )
        },
    },
}
</script>
<style lang="scss" >
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
  