var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"ledesDetailFormModal",attrs:{"id":"ledesDetailFormModal","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('ledesDetail.info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)},"close":function($event){return _vm.clear()}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":_vm.$t('ledesDetail.ledes_code_type'),"rules":{ excluded: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"ledes-detail-ledes-code-type-input"}},[_vm._v(_vm._s(_vm.$t('ledesDetail.ledes_code_type')))]),_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null,"value-field":"id","text-field":"name","options":_vm.ledesCodeTypeOption},model:{value:(_vm.ledesCodeTypeSelect),callback:function ($$v) {_vm.ledesCodeTypeSelect=$$v},expression:"ledesCodeTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('ledesDetail.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"ledes-detail-name-input"}},[_vm._v(_vm._s(_vm.$t('ledesDetail.name')))]),_c('b-form-group',[_c('b-form-textarea',{attrs:{"id":"ledes-detail-name-input","size":"sm","maxlength":"250","rows":"2","state":errors.length > 0 ? false : null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('ledesDetail.code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"ledes-detail-code-input"}},[_vm._v(_vm._s(_vm.$t('ledesDetail.code')))]),_c('b-form-group',[_c('b-form-input',{attrs:{"id":"ledes-detail-code-input","size":"sm","maxlength":"20","state":errors.length > 0 ? false : null},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('ledesDetail.tmpt'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"ledes-detail-tmpt-input"}},[_vm._v(_vm._s(_vm.$t('ledesDetail.tmpt')))]),_c('b-form-group',[_c('b-form-input',{attrs:{"id":"ledes-detail-tmpt-input","size":"sm","maxlength":"50","state":errors.length > 0 ? false : null},model:{value:(_vm.tmpt),callback:function ($$v) {_vm.tmpt=$$v},expression:"tmpt"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":"ledes-detail-description-input"}},[_vm._v(_vm._s(_vm.$t('ledesDetail.description')))]),_c('b-form-group',[_c('b-form-textarea',{attrs:{"id":"ledes-detail-description-input","size":"sm","maxlength":"1500","rows":"3"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }