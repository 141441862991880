<template>
    <div>
        <!-- modal -->
        <b-modal id="adminAnnualLeaveApproveFormModal" centered size="lg" no-close-on-backdrop ref="adminAnnualLeaveApproveFormModal" :title=" $t('adminAnnualLeave.info')" 
        :cancel-title=" $t('hourlyAdminAnnualLeave.decline')" :ok-title=" $t('hourlyAdminAnnualLeave.accept')" cancel-variant="outline-warning"
        @ok.prevent="approveAdminAnnualLeave(id,true)" @cancel.prevent="approveAdminAnnualLeave(id,false)">
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <b-col class="col-md-3 mt-1">
                            <b-form>
                                <label for="admin-annual-leave-employee-input">{{ $t('adminAnnualLeave.employee') }}</label>
                                <b-form-group>
                                    <b-form-input size="sm" id="admin-annual-leave-employee-input" v-model="employee" disabled />
                                </b-form-group>
                            </b-form>
                        </b-col>
                        <b-col class="col-md-3 mt-1">
                            <b-form-group>
                                <label for="admin-annual-leave-request-date-input">{{ $t('adminAnnualLeave.request_date') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="requestDate"></CustomDatePicker>
                            </b-form-group>
                        </b-col>
                        <b-col class="col-md-3 mt-1">
                            <b-form>
                                <label for="admin-annual-leave-status-input">{{ $t('adminAnnualLeave.admin_annual_leave_status') }}</label>
                                <b-form-select disabled size="sm" v-model="adminAnnualLeaveStatusSelect" value-field="id" text-field="name" :options="adminAnnualLeaveStatusOption" />
                            </b-form>
                        </b-col>
                        <b-col class="col-md-3 mt-1">
                            <b-form>
                                <label for="admin-annual-leave-employee-vesting-input">{{ $t('adminAnnualLeave.employee_vesting') }}</label>
                                <b-form-group>
                                    <b-form-input size="sm" id="admin-annual-leave-employee-vesting-input" v-model="employeeVesting" type="number" disabled />
                                </b-form-group>
                            </b-form>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-md-4 mt-1">
                            <label for="admin-annual-leave-starting-date-input">{{ $t('adminAnnualLeave.starting_date') }}</label>
                            <b-form-group>
                                <flat-pickr disabled v-model="startingDate" class="form-control" size="sm" :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col-md-4 mt-1">
                            <label for="admin-annual-leave-ending-date-input">{{ $t('adminAnnualLeave.ending_date') }}</label>
                            <b-form-group>
                                <flat-pickr disabled v-model="endingDate" class="form-control" size="sm" time_24hr="true" :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col-md-4 mt-1">
                            <b-form>
                                <label for="admin-annual-leave-type-input">{{ $t('adminAnnualLeave.annual_leave_type') }}</label>
                                <b-form-select size="sm" v-model="adminAnnualLeaveTypeSelect" value-field="id" text-field="name" :options="adminAnnualLeaveTypeOption" />
                            </b-form>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-md-6 mt-1">
                            <label for="admin-annual-leave-request-description-input">{{ $t('adminAnnualLeave.request_description') }}</label>
                            <b-form-textarea disabled size="sm" id="admin-annual-leave-request-description-input" v-model="requestDescription" rows="3" max-rows="8" maxlength="250" />
                        </b-col>
                        <b-col class="col-md-6 mt-1">
                            <label for="admin-annual-leave-approve-description-input">{{ $t('adminAnnualLeave.approve_description') }}</label>
                            <b-form-textarea size="sm" id="admin-annual-leave-approve-description-input" v-model="approveDescription" rows="3" max-rows="8" maxlength="250" />
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            getAdminAnnualLeaveList: {
                type: Function
            },
        },
        data() {
            return {
                id: 0,
                employee: '',
                startingDate: null,
                endingDate: null,
                requestDate: null,
                adminAnnualLeaveStatusSelect: 0,
                adminAnnualLeaveStatusOption: [],
                adminAnnualLeaveTypeSelect: 0,
                adminAnnualLeaveTypeOption: [],
                requestDescription: '',
                approveDescription: '',
                total: 0,
                tokenEmployeeId: 0,
                approval: false,
                employeeVesting: 0,
            }
        },
        methods: {
            showInfo(id, employeeId) {
                this.$refs['adminAnnualLeaveApproveFormModal'].show()
                this.tokenEmployeeId = localStorage.getItem('EmployeeId')
                if (this.employeeId > 0 && this.employeeId != this.tokenEmployeeId) {
                    this.employeeId = this.employeeId
                } else {
                    this.employeeId = this.tokenEmployeeId
                }
                this.id = id
                const data = {
                    id: this.id,
                    employeeId: this.employeeId
                }
                const controller = {
                    name: 'AnnualLeaveRequest'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.startingDate = response.data.annualLeaveRequest.startingDate
                        this.endingDate = response.data.annualLeaveRequest.endingDate
                        this.requestDate = response.data.annualLeaveRequest.requestDate
                        this.adminAnnualLeaveStatusSelect = response.data.annualLeaveRequest.annualLeaveStatusId
                        this.adminAnnualLeaveStatusOption = response.data.annualLeaveStatusList
                        this.adminAnnualLeaveTypeSelect = response.data.annualLeaveRequest.annualLeaveTypeId
                        this.adminAnnualLeaveTypeOption = response.data.annualLeaveTypeList
                        this.requestDescription = response.data.annualLeaveRequest.requestDescription
                        this.approveDescription = response.data.annualLeaveRequest.approveDescription
                        this.adminAnnualLeaveStatusOption.splice(0, 0, this.$nullSelected())
                        this.adminAnnualLeaveTypeOption.splice(0, 0, this.$nullSelected())
                        this.employee = response.data.employee
                        this.employeeVesting = response.data.employeeAnnualLeaveVesting
                    } else {
                        this.$bvModal.hide('AnnualLeaveApproveFormModal')
                        this.$WarningAlert(response.data.resultMessage)
                        this.startingTime = ''
                        this.endingTime = ''
                        this.requestDescription = ''
                        this.adminAnnualLeaveStatusSelect = 0
                    }
                })
            },
            approveAdminAnnualLeave(id, approval) {
                this.id = id,
                    this.approval = approval
                const controller = {
                    name: 'AnnualLeaveRequest',
                    actionName: 'ApproveAnnualLeaveRequest'
                }
                const data = {
                    managerId: localStorage.getItem('EmployeeId'),
                    id: this.id,
                    approval: this.approval,
                    approveDescription: this.approveDescription
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$bvModal.hide('adminAnnualLeaveApproveFormModal')
                                this.getAdminAnnualLeaveList()
                                this.$SaveAlert()
                            } else {
                                this.$bvModal.hide('adminAnnualLeaveApproveFormModal')
                                this.$WarningAlert(response.data.resultMessage)
                                this.approveDescription = '',
                                    this.approval = false
                            }
                        })
                    }
                })
            }
        }
    }
    </script>
    
    <style lang="scss">
    [dir=ltr] .modal-header .close {
        margin: -0.4rem -0.4rem -0.8rem auto;
    }
    
    [dir] label {
        margin-bottom: 0.2857rem;
        font-weight: bold;
    }
    
    .modal .modal-content {
        overflow: visible;
        margin-bottom: auto;
    }
    
    [dir] .invalid-tooltip {
        padding: 0.4rem 0.775rem !important;
        margin-top: -1.5rem !important;
        background-color: #ea5455 !important;
        border-radius: 0.358rem !important;
        left: 20px !important
    }
    .datepickerStyle{
        max-height: 2.2em;
        font-family: fangsong;
    }
    </style>
    