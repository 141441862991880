<template>
    <div>
      <!-- modal -->
      <b-modal
        id="agentNoteFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="agentNoteFormModal"
        :title=" $t('note.note_info')"
        hide-footer
        @close="clear()"

      >
      <app-collapse accordion type="margin">

        <app-collapse-item :title="$t('note.general_note')">
          <tr v-for="invoice in itemsInvoice" :key="invoice.id">
            <td v-if="invoice.noteType == 'Genel'">
              <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
              {{ invoice.note }}
            </td>
          </tr>
        </app-collapse-item>
    
        <app-collapse-item :title="$t('note.invoice_note')" >
          <tr v-for="invoice in itemsInvoice" :key="invoice.id" >
            <td v-if="invoice.noteType == 'Fatura'">
              <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
              {{ invoice.note }}
            </td>
          </tr>
        </app-collapse-item>
    
        <app-collapse-item :title="$t('note.accounting_note')">
          <td>
            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
            {{ itemsAccounting }}
          </td>
        </app-collapse-item>
  
      </app-collapse>
      </b-modal>
    </div>
  </template>
  <script>

  export default {
    props: {
      noteForm: Object,
    },
    data() {
      return {
        id: 0,
        itemsInvoice: [],
        itemsAccounting: []
      }
    },
    methods: {
      getNoteListByAccountingId(id) {
        this.$refs['agentNoteFormModal'].show()
        this.rows = [] 
        const data = { clientAccountingId: id , noteTypeId : 0}
        const controller = { name: 'ClientNote', actionName:'GetClientAccountingNoteWithClientNoteListByClientAccountingId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.itemsInvoice = response.data.clientNoteList
            this.itemsAccounting = response.data.accountingNote
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
      clear(){
        this.itemsAccounting = []
        this.itemsInvoice = []
      },
    },
  }
  </script>
  <style lang="scss" >
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
  