<template>
<div>
    <design-product-form :getDesignProductList="getDesignProductList" ref="designProductFormModal"></design-product-form>
    <b-row>
        <b-col class="col-12">
            <div style="float:right;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-button style="float:right;" size="sm" v-b-modal.designProductForm variant="outline-primary" @click="getInfo(0)">{{ $t('designProduct.design_product_add') }}</b-button>
                    </div>
                </b-form-group>
            </div>
        </b-col>
    </b-row>
    <vue-good-table id="designProductTable" ref="designProductTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
          enabled: true,
          perPage: pageLength
        }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.label === 'No'" class="text-nowrap">
                {{ $t('designProduct.serial_number') }}
            </span>
            <span v-else-if="props.column.label === 'Design Product Name'" class="text-nowrap">
                {{ $t('designProduct.design_name') }}
            </span>
            <span v-else-if="props.column.label === 'Locarno'" class="text-nowrap">
                {{ $t('designProduct.locarno') }}
            </span>
            <span v-else-if="props.column.label === 'Images'" class="text-nowrap">
                {{ $t('designProduct.image') }}
            </span>
            <span v-else-if="props.column.label === 'Process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
            <span v-else>
                {{ props.column.label }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Design Product Name -->
            <span v-if="props.column.field === 'no'" class="text-nowrap">
                <span>{{ props.row.no }}</span>
            </span>
            <!-- Column: Image -->
            <span v-else-if="props.column.field === 'imageList'" class="text-nowrap">
                <b-row>
                    <span v-for="(image, index) in imageList.filter(p => p.fileName.includes(props.row.no + '_')).slice(0, 4)" :key="index">
                        <b-img :src="'data:image/png;base64,' + image.base64" fluid alt="Img" style="width: 8em; height: 8em;" />
                    </span>
                </b-row>
                <!-- <b-row>
                    <span v-for="(image, index) in imageList.filter(p => p.fileName.includes(props.row.no + '_'))" :key="image.id">
                        <span v-if="index <= 3">
                            <b-img :src="'data:image/png;base64,' + image.base64" fluid alt="Img" style="width: 8em; height: 8em;" />
                        </span>
                    </span>
                </b-row> -->
                <!-- <b-row>
                    <span v-for="(image, index) in imageList.filter(p => p.fileName.includes(props.row.no + '_'))" :key="image.id">
                        <span v-if="index > 4">
                            <b-img :src="'data:image/png;base64,' + image.base64" fluid alt="Img" style="width: 8em; height: 8em;" />
                        </span>
                    </span>
                </b-row> -->
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                    </span>
                    <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    props: {
        designId: Number,
        getDesignList: {
            type: Function
        },
        getDesignDetailCount: {
            type: Function
        },
    },
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            itemList: [],
            imageList: [],
            columns: [{
                    label: this.$t('designProduct.serial_number'),
                    field: `no`,
                    width: '6em',
                },
                {
                    label: this.$t('designProduct.design_name'),
                    field: `productName`,
                    width: '9em',
                },
                {
                    label: this.$t('designProduct.design_name_en'),
                    field: `productNameEn`,
                    width: '9em',
                },
                {
                    label: this.$t('designProduct.locarno'),
                    field: `locarnoCodes`,
                    width: '11em',
                },
                {
                    label: this.$t('designProduct.image'),
                    field: `imageList`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getDesignProductList(),
        this.getDesignDetailCount()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'DesignDetail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getDesignProductList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getDesignProductList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.designProductFormModal.showInfo(id, this.designId)
        },
        getDesignProductList() {
            this.itemList = []
            this.imageList = []
            this.rows = []
            const data = {
                id: this.designId
            }
            const controller = {
                name: 'DesignDetail',
                actionName: 'GetDesignDetailListByDesignId'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                  this.getDesignDetailCount()
                    response.data.designDetailList.forEach(element => {
                        this.rows.push(element)
                    })
                    if (response.data.imageList != null) {
                        this.imageList = response.data.imageList
                    }
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    },
}
</script>

<style>
.vl {
    border-left: 2px solid rgb(93, 63, 211);
    height: 100%;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
