<template>
    <div>
        <div class="table-container">
          <vue-good-table :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{ enabled: true, externalQuery: searchTerm}" 
            :pagination-options="{ enabled: true, perPage: pageLength }" theme="my-theme">
              <div slot="emptystate">
                  {{ $t('dataTable.data_not_found') }}
              </div>              
              <!-- Row: Table Row -->
              <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'invoiceTypeCode'" class="text-nowrap">
                    {{ $t('einvoiceAwaitingApproval.invoice_type') }}
                </span>
                <span v-else-if="props.column.field === 'profile'" class="text-nowrap">
                    {{ $t('einvoiceAwaitingApproval.scenario') }}
                </span>
                <span v-else-if="props.column.field === 'invoiceNo'" class="text-nowrap">
                    {{ $t('einvoiceAwaitingApproval.invoice_no') }}
                </span>
                <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                    {{ $t('einvoiceAwaitingApproval.invoice_date') }}
                </span>
                <span v-else-if="props.column.field === 'amount'" class="text-nowrap">
                    {{ $t('einvoiceAwaitingApproval.amount') }}
                </span>
                <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>
            <!-- Column: Table Column -->
            <template slot="table-row" slot-scope="props">
                <!-- Column: Process -->
                <span v-if="props.column.field === 'process'">
                    <span>
                        <b-button v-b-tooltip.html :title="$t('others.show_invoice')" v-b-modal.modal-top variant="info" class="btn-icon mb-1 ml-1" size="sm" @click="getEInvoicePdf(props.row.invoiceUUID)">
                            <feather-icon icon="EyeIcon" />
                        </b-button>
                        <b-button v-b-tooltip.html :title=" $t('others.approve')" v-b-modal.modal-top variant="success" class="btn-icon mb-1 ml-1" size="sm" @click="getApproveInfo(props.row.id)">
                            <feather-icon icon="CheckIcon" />
                        </b-button>
                        <b-button v-b-tooltip.html :title=" $t('others.no_transfer')" variant="danger" class="btn-icon mb-1 ml-1" size="sm" @click="getNoTransferInfo(props.row.id)">
                            <feather-icon icon="XIcon" />
                        </b-button>
                    </span>
                </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                        <b-form-select size="sm" v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getEInvoiceAwaitingApprovalList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
          </vue-good-table>
        </div>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            getPaymentList: Function,
            incomeInvoiceClientId: Number,
            statusSelect: Number
        },
        data() {
            return {
                id: 0,
                total: 0,
                pageLength: 10,
                dir: false,
                pageNo: 1,
                columns: [
                    {
                        label: this.$t('einvoiceAwaitingApproval.invoice_type'),
                        field: `invoiceTypeCode`,
                    },
                    {
                        label: this.$t('einvoiceAwaitingApproval.scenario'),
                        field: `profile`,
                    },
                    {
                        label: this.$t('einvoiceAwaitingApproval.invoice_No'),
                        field: `invoiceNo`,
                    },
                    {
                        label: this.$t('einvoiceAwaitingApproval.invoice_date'),
                        field: `date`,
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('einvoiceAwaitingApproval.amount'),
                        field: `amount`,
                    },
                    {
                        label: 'Process',
                        field: 'process',
                        width: '15em'
                    },
                ],
                rows: [],
                searchTerm: ''
            }
        },
        mounted() {
            this.getEInvoiceAwaitingApprovalList()
        },
        methods: {
            getEInvoicePdf(invoiceUUID){
                const data = {
                    string: invoiceUUID,
                }
                const controller = {
                    name: 'EInvoice',
                    actionName: 'GetIncomeInvoicePdfByInvoiceUUID'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                    this.$downloadFile(response.data.file.fileName, response.data.file.base64, 'pdf')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                })
            },
            getApproveInfo(id){
                this.$PrivateConfirm(this.$t("payment.confirmTitle"), this.$t("payment.confirmMessage"), "warning",this.$t("payment.confirmButtonText"),this.$t("payment.cancelButtonText")).then(result => {
                    if (result.value) {
                        
                      const data = {
                        id : id,
                        employeeId : localStorage.getItem('EmployeeId')
                      }
                      const controller = { name: 'AccountingTransaction', actionName: 'IncomeInvoiceTransfer' }
                      const payload = { data: data, controller: controller }

                      this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                          this.getEInvoiceAwaitingApprovalList()
                          this.$SaveAlert()
                        } else {
                          this.$WarningAlert(response.data.resultMessage)
                        }
                      })
                    }
                })
            },
            getNoTransferInfo(id){
                //ToDo Buraya yazılacak
            },
            getEInvoiceAwaitingApprovalList() {
                this.rows = []
                const data = { statusId: this.statusSelect, pageLength: this.pageLength, pageNo: this.pageNo, incomeInvoiceClientId: this.incomeInvoiceClientId}
                const controller = { name: 'IncomeInvoice' }
                const payload = { data: data, controller: controller }
                this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        response.data.incomeInvoiceList.forEach((element) => { 
                            this.rows.push(element);
                        });
                    }
                })
            },
        },
    }
    </script>
    
    <style lang="scss">
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    .table-container {
      max-height: 400px; /* Tablonun maksimum yüksekliği, gereksinimlerinize göre ayarlayın */
      overflow-y: auto; /* Dikey kaydırma çubuğunu etkinleştir */
    }
    .vgt-table thead th.vgt-checkbox-col {
        vertical-align: middle;
        visibility: hidden;
    }
    </style>