<template>
    <div>
        <!-- modal -->
        <b-modal 
            id="trademarkPriorityFormModal" 
            centered size="lg" 
            no-close-on-backdrop ref="trademarkPriorityFormModal"
            :title="$t('trademarkPriority.priority_info')" 
            :cancel-title="$t('others.cancel_title')"
            :ok-title="$t('others.ok_title')" 
            cancel-variant="outline-secondary" 
            @close="clear()"
            @ok.prevent="addOrUpdate"
        >
            <validation-observer ref="infoRules" tag="form">
                <b-row>
                    <b-col class="col-md-12">
                        <b-form>
                            <label for="trademarkPriority-no-input">{{ $t('trademarkPriority.no') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('trademarkPriority.no')"
                                    rules="required">
                                    <b-form-input id="trademarkPriority-no-input" size="sm" v-model="no" maxlength="20"
                                        :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12">
                        <validation-provider :name="$t('trademarkPriority.country')" #default="{ errors }"
                            :rules="{ excluded: 0 }">
                            <b-form>
                                <label for="country-input">{{ $t('trademarkPriority.country') }}</label>
                                <b-form-select v-model="countrySelect" value-field="id" size="sm" text-field="name"
                                    :options="countryOption" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <label for="trademarkPriority-date-input">{{ $t('trademarkPriority.date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="1" v-model="date"></CustomDatePicker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12">
                        <b-form>
                            <label for="trademark-priority-nice-class-input">{{ $t('trademarkPriority.nice_class') }}</label>
                            <b-form-group>
                                <b-form-input id="trademark-priority-nica-class-input" size="sm" v-model="niceClass" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>    
export default {
    props: {
        getTrademarkPriorityList: {
            type: Function
        }, 
    },
    data() {
        return {
            id: 0,
            trademarkId:0,
            date: null,
            no: '',
            countryOption: [],
            countrySelect: 0,
            niceClass: '',
        }
    },
    methods: {
        showInfo(id, trademarkId) {
            this.$refs['trademarkPriorityFormModal'].show()
            this.id= id
            this.trademarkId= trademarkId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TrademarkPriority'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.no = response.data.trademarkPriority.no
                    this.date = response.data.trademarkPriority.date
                    this.niceClass = response.data.trademarkPriority.niceClass
                    // this.niceClassSelect = response.data.trademarkPriority.niceClass
                    // this.niceClassOption = response.data.niceClassList
                    this.countrySelect = response.data.trademarkPriority.countryId
                    this.countryOption = response.data.countryList
                    this.countryOption.splice(0, 0, this.$nullSelected())
                    // this.niceClassOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('trademarkPriorityFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
            this.countrySelect = null,
                this.date = null,
                this.no = null
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                trademarkId: this.trademarkId,
                countryId: this.countrySelect,
                date: this.date,
                no: this.no,
                niceClass: this.niceClass
                // niceClass: this.niceClassSelect
            }
            const controller = {
                name: 'TrademarkPriority'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('trademarkPriorityFormModal')
                            this.getTrademarkPriorityList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        }
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
