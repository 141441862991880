<template>
    <div>
      <!-- modal -->
      <b-modal
        id="designBulletinFormModal"
        centered
        size="xl"
        no-close-on-backdrop
        ref="designBulletinFormModal"
        :title=" $t('designBulletinCheckReport.desing_bulletin_info')"
        @ok.prevent="addOrUpdate"
        hide-footer
      >
      <b-row>
        <!--Design--> 
          <b-col>
            <b-card :title=" $t('designBulletin.bulletin_design_title') ">
              <b-list-group >
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.application_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.applicationNo != this.tpmkApplicationNo" style="color:red">
                            {{applicationNo}}
                          </b-card-text>
                          <b-card-text v-else-if="this.applicationNo == this.tpmkApplicationNo">
                            {{applicationNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.application_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.applicationDate != this.tpmkApplicationDate" style="color:red">
                            {{this.$formatFn(applicationDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.applicationDate == this.tpmkApplicationDate">
                            {{this.$formatFn(applicationDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.register_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.registerNo != this.tpmkRegisterNo" style="color:red">
                            {{registerNo}}
                          </b-card-text>
                          <b-card-text v-else-if="this.registerNo == this.tpmkRegisterNo">
                            {{registerNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.register_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.registerDate != this.tpmkRegisterDate" style="color:red">
                            {{this.$formatFn(registerDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.registerDate == this.tpmkRegisterDate">
                            {{this.$formatFn(registerDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.bulletin_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.bulletinNo != this.tpmkBulletinNo" style="color:red">
                            {{bulletinNo}}
                          </b-card-text>
                          <b-card-text v-else-if="this.bulletinNo == this.tpmkBulletinNo">
                            {{bulletinNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.bulletin_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.bulletinDate != this.tpmkBulletinDate" style="color:red">
                            {{this.$formatFn(bulletinDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.bulletinDate == this.tpmkBulletinDate">
                            {{this.$formatFn(bulletinDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
              </b-list-group>
            </b-card>
          </b-col>
          <!--TPMK Design--> 
          <b-col>
            <b-card :title=" $t('designBulletin.tpmk_bulletin_design_title') ">
              <b-list-group>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.tpmk_application_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkApplicationNo != this.applicationNo" style="color:red">
                            {{tpmkApplicationNo}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkApplicationNo == this.applicationNo">
                            {{tpmkApplicationNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.tpmk_application_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkApplicationDate != this.applicationDate" style="color:red">
                            {{this.$formatFn(tpmkApplicationDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkApplicationDate == this.applicationDate">
                            {{this.$formatFn(tpmkApplicationDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.tpmk_register_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkRegisterNo != this.registerNo" style="color:red">
                            {{tpmkRegisterNo}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkRegisterNo == this.registerNo">
                            {{tpmkRegisterNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.tpmk_register_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkRegisterDate != this.registerDate" style="color:red">
                            {{this.$formatFn(tpmkRegisterDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkRegisterDate == this.registerDate">
                            {{this.$formatFn(tpmkRegisterDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.tpmk_bulletin_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkBulletinNo != this.bulletinNo" style="color:red">
                            {{tpmkBulletinNo}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkBulletinNo == this.bulletinNo">
                            {{tpmkBulletinNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.tpmk_bulletin_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkBulletinDate != this.bulletinDate" style="color:red">
                            {{this.$formatFn(tpmkBulletinDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkBulletinDate == this.bulletinDate">
                            {{this.$formatFn(tpmkBulletinDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-12">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('designBulletin.tpmk_attorney') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAttorney}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
              </b-list-group>
            </b-card>
          </b-col>
      </b-row>
      <b-row>
      <!--Designer--> 
        <b-col>
          <b-card :title=" $t('designBulletin.bulletin_designer_title') ">
            <b-list-group v-for="(nameAndAddress, index) in designBulletinNameAndAddress" :key="nameAndAddress.id">
              <b-list-group-item class="flex-column align-items-start">
                <b-row>
                    <b-col>
                      <b-card-text>
                        {{ nameAndAddress.name }}
                        <br>
                        {{ nameAndAddress.address }}
                      </b-card-text>
                    </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
        <!--TPMK Designer--> 
        <b-col>
          <b-card :title=" $t('designBulletin.tpmk_bulletin_designer_title') ">
            <b-list-group v-for="(nameAndAddress, index) in tpmkDesignBulletinNameAndAddress" :key="nameAndAddress.id">
              <b-list-group-item class="flex-column align-items-start">
                    <b-row>
                      <b-col>
                        <b-card-text>
                          {{ nameAndAddress.name }}
                          <br>
                          {{ nameAndAddress.address }}
                        </b-card-text>
                      </b-col>
                    </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <!--Design Owner--> 
          <b-col>
            <b-card :title=" $t('designBulletin.bulletin_design_owner_title') ">
              <b-list-group  v-for="(nameAddress, index) in designDesignerNameAndAddress" :key="nameAddress.id">
                <b-list-group-item class="flex-column align-items-start">
                  <b-row>
                    <b-col>
                      <b-card-text>
                        {{ nameAddress.clientName }}
                        <br>
                        {{ nameAddress.address }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
          <!--TPMK Design Owner--> 
          <b-col>
            <b-card :title=" $t('designBulletin.tpmk_bulletin_design_owner_title') ">
              <b-list-group v-for="(nameAddress, index) in tpmkDesignDesignerNameAndAddress" :key="nameAddress.id">
                <b-list-group-item class="flex-column align-items-start">
                  <b-row>
                    <b-col>
                      <b-card-text>
                        {{ nameAddress.name }}
                        <br>
                        {{ nameAddress.address }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
      </b-row>
      <b-row>
        <!--Design Detail--> 
        <b-col>
          <b-card :title=" $t('designBulletin.bulletin_design_detail_title') ">
            <b-list-group v-for="(nameAndLocarno, index) in designDetailList" :key="nameAndLocarno.id">
              <b-list-group-item class="flex-column align-items-start">
                <b-col>
                  <b-card-text>
                    {{nameAndLocarno.no + ' - ' + nameAndLocarno.productName + ' - ' + nameAndLocarno.locarnoCodes}}
                  </b-card-text>
                </b-col>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <b-row>
                  <b-col>
                    <b-row>
                      <span v-for="(image, index) in nameAndLocarno.images" :key="image.id">
                          <b-img :src="'data:image/png;base64,' + image.base64"
                            fluid
                            alt="Img"
                            style="width: 5em; height: 5em; border-style: double;"/>
                      </span>
                    </b-row>
                </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
        <!--TPMK Design Detail--> 
        <b-col>
          <b-card :title=" $t('designBulletin.tpmk_bulletin_design_detail_title') ">
            <b-list-group v-for="(nameAndLocarno, index) in tpmkDesignDetailList" :key="nameAndLocarno.id">
                  <b-list-group-item class="flex-column align-items-start">
                    <b-col>
                      <b-card-text>
                        {{nameAndLocarno.no + ' - ' + nameAndLocarno.productName + ' - ' + nameAndLocarno.locarnoCodes}}
                      </b-card-text>
                    </b-col>
                  </b-list-group-item>
                  <b-list-group-item class="flex-column align-items-start">
                    <b-row>
                      <b-col>
                          <b-row>
                            <span v-for="(image, index) in nameAndLocarno.images" :key="image.id">
                                <b-img :src="'data:image/png;base64,' + image.base64"
                                  fluid
                                  alt="Img"
                                  style="width: 5em; height: 5em; border-style: double;"/>
                            </span>
                          </b-row>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getDesignBulletinList: {type: Function},
    },
    data() {
      return {
        id: 0,
        applicationNo: '',
        applicationDate: null,
        registerNo: '',
        registerDate: null,
        bulletinNo: 0,
        bulletinDate: 0,
        tpmkApplicationNo: '',
        tpmkApplicationDate: null,
        tpmkRegisterNo: '',
        tpmkRegisterDate: null,
        tpmkBulletinNo: 0,
        tpmkBulletinDate: 0,
        tpmkAttorney: '',
        designBulletinNameAndAddress: [],
        tpmkDesignBulletinNameAndAddress: [],
        designDesignerNameAndAddress: [],
        tpmkDesignDesignerNameAndAddress: [],
        designDetailList: [],
        tpmkDesignDetailList: [],
        no: 0,
        productName: '',
        productNameEn: '',
        locarnoCodes: '',
        designImages: [],
        tpmkNo: 0,
        tpmkProductName: '',
        tpmkProductNameEn: '',
        tpmkLocarnoCodes: '',
        tpmkImages: [],
        searchTerm: '',
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['designBulletinFormModal'].show()
        this.rows = []
        this.tpmkRows = []
        this.id = id
        const data = { id: this.id }
        const controller = { name: 'Bulletin', actionName: 'GetDesignBulletinInfoByDesignId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
                  this.tpmkImages = []
                  this.designImages = []
              //Design
                  this.applicationNo = response.data.design.applicationNo
                  this.applicationDate = response.data.design.applicationDate
                  this.registerNo = response.data.design.registerNo
                  this.registerDate = response.data.design.registerDate
                  this.bulletinNo = response.data.design.bulletinNo
                  this.bulletinDate = response.data.design.bulletinDate
                  this.tpmkApplicationNo = response.data.tpmkDesign.applicationNo
                  this.tpmkApplicationDate = response.data.tpmkDesign.applicationDate
                  this.tpmkRegisterNo = response.data.tpmkDesign.registerNo
                  this.tpmkRegisterDate = response.data.tpmkDesign.registerDate
                  this.tpmkBulletinNo = response.data.tpmkDesign.bulletinNo
                  this.tpmkBulletinDate = response.data.tpmkDesign.bulletinDate
                  this.tpmkAttorney = response.data.tpmkDesign.attorney
                  //Designer
                  this.designBulletinNameAndAddress = response.data.designDesignerList
                  this.tpmkDesignBulletinNameAndAddress = response.data.tpmkDesignDesignerList

                  //Owner
                  this.designDesignerNameAndAddress = response.data.designOwnerList
                  this.tpmkDesignDesignerNameAndAddress = response.data.tpmkDesignHolder

                  //Detail
                  this.designDetailList = response.data.designDetailList
                  this.tpmkDesignDetailList = response.data.tpmkDesignDetailList
        }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  [dir] .dark-layout .card {
    background-color: #1c2333!important;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.24)!important;
}
.tableSize {
  font-size: 13px!important;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}
  </style>
  