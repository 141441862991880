<template>
    <div >
        <agent-annuity-price-detail-form :getAgentAnnuityPriceDetailList="getAgentAnnuityPriceDetailList" :getAgentAnnuityPriceList="getAgentAnnuityPriceList" ref="agentAnnuityPriceDetailFormModal"></agent-annuity-price-detail-form>
      <!-- table -->
      <vue-good-table
      ref="agentAnnuityPriceDetailTable"
      :columns="columns"
      :rows="rows"
      styleClass="vgt-table condensed tableSize vgt-responsive-custom" 
      :search-options="{
        enabled: false,
        externalQuery: searchTerm }"
      :select-options="{ 
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
      :pagination-options="{
      enabled: false,
      perPage:pageLength
    }"
      theme="my-theme">
      <div slot="emptystate">
        {{ $t('dataTable.data_not_found') }}
      </div>
      <!-- Row: Table Row -->
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field ==='tpmkCode'" class="text-nowrap">
          {{ $t('agentAnnuityPriceDetail.tpmk_code') }}
        </span>
        <span v-else-if="props.column.field ==='service'" class="text-nowrap">
          {{ $t('agentAnnuityPriceDetail.service') }}
        </span>
        <span v-else-if="props.column.field ==='tpmkName'" class="text-nowrap">
          {{ $t('agentAnnuityPriceDetail.tpmk_name') }}
        </span>
        <span v-else-if="props.column.field ==='stockFee'" class="text-nowrap">
          {{ $t('agentAnnuityPriceDetail.stock_fee') }}
        </span>
        <span v-else-if="props.column.field ==='tpmkFee'" class="text-nowrap">
          {{ $t('agentAnnuityPriceDetail.tpmk_fee') }}
        </span>
        <span v-else-if="props.column.field ==='officialFee'" class="text-nowrap">
          {{ $t('agentAnnuityPriceDetail.official_fee') }}
        </span>
        <span v-else-if="props.column.field ==='officialFineFee'" class="text-nowrap">
          {{ $t('agentAnnuityPriceDetail.official_fine_fee') }}
        </span>  
        <span v-else-if="props.column.field ==='process'" class="text-nowrap">
          {{ $t('others.process') }}
        </span>
      </template>
      <!-- Column: Table Column -->
      <template slot="table-row" slot-scope="props">
        <!-- Column: Process -->
        <span v-if="props.column.field === 'process'">
          <span>
            <b-button v-b-tooltip.html :title=" $t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
              <feather-icon icon="EditIcon" />
            </b-button>                
          </span>
        </span>
      </template>
    </vue-good-table>
    </div>
  </template>
  <script>
  export default {
    props: {
        getAgentAnnuityPriceList: {type: Function},
        id: {type: Number}
    },
    data() {
      return {
        pageLength: 10,
        dir: false,
        columns: [
          {
            label : this.$t('agentAnnuityPriceDetail.service'),
            field : 'service',
          },
          {
            label : this.$t('agentAnnuityPriceDetail.year'),
            field : 'year',
          },
          {
            label : this.$t('agentAnnuityPriceDetail.stock_fee'),
            field : 'stockFee',
          },
          {
            label : this.$t('agentAnnuityPriceDetail.tpmk_fee'),
            field : 'tpmkFee',
          },
          {
            label : this.$t('agentAnnuityPriceDetail.official_fee'),
            field : 'officialFee',
          },
          {
            label : this.$t('agentAnnuityPriceDetail.official_fine_fee'),
            field : 'officialFineFee',
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted(){
      this.getAgentAnnuityPriceDetailList()
    },
    methods: {
        getAgentAnnuityPriceDetailList() {  
            this.rows = []
            if(this.id != 0){
              const data = {id: this.id}
              const controller = {name: 'AgentAnnuityPrice', actionName: 'GetAgentAnnuityPriceListByAgentAnnuityId'}
              const payload = {data: data, controller: controller}
              this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                  if(response.data.resultStatus == true){
                      this.rows.push(response.data.agentAnnuityPriceList)
                      response.data.agentAnnuityPriceList.forEach(element => {
                          this.rows.push(element)
                      })

                  } else {
                      this.$bvModal.hide('agentAnnuityPriceDetailFormModal')
                      this.$WarningAlert(response.data.resultMessage)
                  }  
              }) 
            }

        },
      async getInfo(id){
          this.$refs.agentAnnuityPriceDetailFormModal.showInfo(id)
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  .vgt-responsive-custom {
    width: 100%;
    overflow-x: auto;
    height: 20em;
    position: relative;
}
  </style>
  