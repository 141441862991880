<template>
<div>
    <!-- table -->
    <b-modal id="annuityDetailListForInvoiceModal" centered size="xl" no-close-on-backdrop ref="annuityDetailListForInvoiceModal" :title="$t('annuityDetailListForInvoice.title')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                    <b-col class="col-lg-12 mt-12">
                        <draggable v-model="annuityDetailForInvoiceList" tag="tbody" class="cursor-move">
                        <table class="table" style="width: 100%;">
                          <thead>
                            <tr>
                              <th>{{ $t('annuityDetailListForInvoice.service') }}</th>
                              <th>{{ $t('annuityDetailListForInvoice.total') }} : {{ this.annuityDetailForInvoiceList.reduce((n, {price}) => n + price, 0) }}</th>
                            </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(item, index) in annuityDetailForInvoiceList" :key="index">
                                <td>{{ item.service }}</td>
                                <td>{{ item.price }}</td>
                              </tr>
                          </tbody>
                        </table>
                    </draggable>
                      </b-col>
                </b-row>
</validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getAnnuityList: {
            type: Function
        },
    },
    data() {
        return {
            annuityId :0,
            annuityDetailForInvoiceList: [],
        }
    },
    mounted() {
        this.getAnnuityList()
    },
    methods: {
        showInfo(annuityId) {
            this.$refs['annuityDetailListForInvoiceModal'].show()
            this.annuityId = annuityId
            this.annuityDetailForInvoiceList= []
            const controller = {
                name: 'AnnuityDetail',
                actionName: 'GetAnnuityDetailForInvoiceList'
            }
            const data = {
                id: this.annuityId
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.annuityDetailForInvoiceList = response.data.annuityDetailForInvoiceList
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                annuityId: this.annuityId,
                employeeId :localStorage.getItem('EmployeeId'),
                annuityDetailForInvoiceList : this.annuityDetailForInvoiceList
            }
            const controller = {
                name: 'AnnuityDetail',
                actionName: 'SaveInvoice'
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$bvModal.hide('annuityDetailListForInvoiceModal')
                    this.getAnnuityList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })            
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
