
<template>
    <div>
      <accounting-books-accounts-form :subsidiaryLedgerId="subsidiaryLedgerId" :getAccountingBooksAccountsList="getAccountingBooksAccountsList" :getAccountingBooksList="getAccountingBooksList" ref="accountingBooksAccountsFormModal"></accounting-books-accounts-form>
      <div class="custom-search">
        <b-row>
          <b-col class="col-md-2 mb-1">
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="accountNo" :placeholder="$t('accountingBooksAccounts.account_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2 mb-1">
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="accountName" :placeholder="$t('accountingBooksAccounts.account_name')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-8 mb-1 custom-search d-flex justify-content-end">
                    <div class="d-flex align-items-center">
                      <b-button class="d-inline-block ml-1" size="sm" variant="outline-primary" v-on:keyup.enter="getAccountingBooksAccountsList()" @click="getAccountingBooksAccountsList()">{{ $t('others.list') }}</b-button>
                      <b-button style="float:right;" class="ml-1" size="sm"  variant="outline-primary" @click="getInfo(0)">{{ $t('accountingBooksAccounts.add') }}</b-button>
                    </div>
            </b-col>
        </b-row>
    </div>
      
      <!-- table -->
      <vue-good-table 
      id="accountingBooksAccountsTable"
      ref="accountingBooksAccountsTable" 
      :columns="columns" 
      :rows="rows" 
      styleClass="vgt-table condensed tableSize" 
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" 
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'number'" class="text-nowrap">
            {{ $t('accountingBooksAccounts.account_no') }}
          </span>
          <span v-else-if="props.column.field === 'name'" class="text-nowrap">
            {{ $t('accountingBooksAccounts.account_name') }}
          </span>
          <span v-else-if="props.column.field === 'currency'" class="text-nowrap">
            {{ $t('accountingBooksAccounts.currency') }}
          </span>
          <span v-else-if="props.column.field === 'receivableAmount'" class="text-nowrap">
            {{ $t('accountingBooksAccounts.total') }}
          </span>
          <span v-else-if="props.column.field === 'receivableAmountInTL'" class="text-nowrap">
            {{ $t('accountingBooksAccounts.total_tl') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </span>
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                  <b-form-select size="sm" v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                  <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
              </div>
              <div>
                  <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getAccountingBooksAccountsList()">
                      <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                  </b-pagination>
              </div>
          </div>
      </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      getAccountingBooksList: {type: Function},
      subsidiaryLedgerId: {type: Number}
    },
    data() {
      return {
        total: 0,
        pageLength: 10,
        dir: false,
        pageNo: 1,
        accountNo: '',
        accountName: '',
        columns: [
          {
            label: this.$t('accountingBooksAccounts.account_no'),
            field: `number`,
          },
          {
            label: this.$t('accountingBooksAccounts.account_name'),
            field: `name`,
          },
          {
            label: this.$t('accountingBooksAccounts.currency'),
            field: `currency`,
          },
          {
            label: this.$t('accountingBooksAccounts.total'),
            field: `receivableAmount`,
          },
          {
            label: this.$t('accountingBooksAccounts.total_tl'),
            field: `receivableAmountInTL`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      this.getAccountingBooksAccountsList()
    },
    methods: {
      async getInfo(id) {
        this.$refs.accountingBooksAccountsFormModal.showInfo(id)
      },
      getAccountingBooksAccountsList() {
        this.rows = []
        const controller = { name: 'Accounting' }
        const data = { subsidiaryLedgerId: this.subsidiaryLedgerId, pageLength: this.pageLength, pageNo: this.pageNo, no: this.accountNo, name: this.accountName}
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
          this.total = response.data.total
          if (response.data.resultStatus == true) {
            response.data.accountingList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
      exportExcel() {
        const controller = { name: 'AccountingBooksAccounts', actionName: 'CanExport'}            
        const data = { columnList : this.columns, DataList : this.rows}
        const payload = { data : data, controller: controller}
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.$downloadFile("AccountingBooksAccounts", response.data.base64, 'xlsx')
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
    },
  }
  </script>
  <style lang="scss" >
  .excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  </style>
  