<template>
    <div>
      <translator-payments-form :getTranslatorPaymentList="getTranslatorPaymentList" ref="translatorPaymentFormModal"></translator-payments-form>
        <b-row>
            <b-col class="col-md-2">
              <label for="translator-payment-translator-input">{{ $t('translatorPayment.translator') }}</label>
                <div class="d-flex align-items-center">
                  <b-form-select @input="getTranslatorPaymentList()" v-model="translatorSelect" size="sm" value-field="id" text-field="name" :options="translatorOption"/>
              </div>
            </b-col>
            <b-col class="col-md-2">
              <label for="translator-payment-payment-start-input">{{ $t('translatorPayment.payment_start') }}</label>
              <CustomDatePicker :datePickerValidateOption="0" @input="getTranslatorPaymentList()" v-model="paymentStart"></CustomDatePicker>         
            </b-col>
            <b-col class="col-md-2">
              <label for="translator-payment-payment-end-input">{{ $t('translatorPayment.payment_end') }}</label>
              <CustomDatePicker :datePickerValidateOption="0" @input="getTranslatorPaymentList()" v-model="paymentEnd"></CustomDatePicker>               
            </b-col>
            <b-col class="col-md-2 mt-1">
                <b-form-group class="d-flex align-items-center">
                    <b-form-checkbox @input="getTranslatorPaymentList()" v-model="status" name="check-button" switch inline>
                        {{ $t('translatorPayment.status') }}
                    </b-form-checkbox>
                 </b-form-group> 
            </b-col>
            <b-col class="col-md-4 mt-2">
                <div style="float:right;" class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <b-button style="float:right;" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('translatorPayment.translator_payment_add') }}</b-button>
                    </b-form-group>
                    <b-form-group>
                        <b-button style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    </b-form-group>
                </div>
            </b-col>
        </b-row> 
      <!-- table -->
      <vue-good-table id="translatorPaymentTable" ref="translatorPaymentTable" :columns="columns" :rows="rows" :sort-options="{
        enabled: false,
      }" 
      styleClass="vgt-table condensed tableSize"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }" :pagination-options="{
          enabled: true,
          perPage: pageLength
        }" theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'reportDate'" class="text-nowrap">
            {{ $t('translatorPayment.report_date') }}
          </span>
          <span v-else-if="props.column.field === 'paymentDate'" class="text-nowrap">
            {{ $t('translatorPayment.payment_date') }}
          </span>
          <span v-else-if="props.column.field === 'translator'" class="text-nowrap">
            {{ $t('translatorPayment.translator') }}
          </span>
          <span v-else-if="props.column.field === 'totalWordCount'" class="text-nowrap">
            {{ $t('translatorPayment.total_word') }}
          </span>
          <span v-else-if="props.column.field === 'files'" class="text-nowrap">
            {{ $t('translatorPayment.folder_no') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: TranslatorPayment Transactions Name -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
  
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
              </span>
              <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </template>
    
  <script>     
  export default {
    data() {
      return {
        pageLength: 10,
        dir: false,
        translatorSelect: 0,
        translatorOption: [],
        paymentStart: null,
        paymentEnd: new Date(),
        status : true,
        dir: false,
        columns: [
          {
            label: this.$t('translatorPayment.report_date'),
            field: `reportDate`,
            formatFn: this.$formatFn
          },
          {
            label: this.$t('translatorPayment.payment_date'),
            field: `paymentDate`,
            formatFn: this.$formatFn
          },
          {
            label: this.$t('translatorPayment.translator'),
            field: `translator`,
          },
          {
            label: this.$t('translatorPayment.total_word'),
            field: `totalWordCount`,
          },
          {
            label: this.$t('translatorPayment.folder_no'),
            field: `files`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      this.getTranslatorPaymentList()
    },
    methods: {
      async deleted(data) {
        data = { id: data.id }
        const controller = { name: 'TranslatorPayment' }
        const payload = { data: data, controller: controller }
        this.$DeleteConfirm().then(result => {
          if (result.value) {
            this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$DeleteAlert()
                this.getTranslatorPaymentList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getTranslatorPaymentList()
              }
            })
          }
        })
      },
      async getInfo(id) {
        this.$refs.translatorPaymentFormModal.showInfo(id)
      },
      getTranslatorPaymentList(id) {
        this.rows = []
        this.id = id
        const controller = { name: 'TranslatorPayment' }
        if(this.paymentStart == null){
          var val = new Date()
          var threeYearAgo = new Date(val)
          threeYearAgo.setFullYear(val.getFullYear() - 3);
          this.paymentStart = threeYearAgo
        }
        const data = { translatorId: this.translatorSelect, paymentDateStart: this.paymentStart, paymentDateEnd: this.paymentEnd, paymentStatus: this.status}
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.translatorOption = response.data.translatorList
            this.translatorSelect = this.translatorSelect
            this.translatorOption.splice(0, 0, this.$nullSelected("name"))
            response.data.translatorPayment.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
      exportExcel() {
              const controller = { name: 'TranslatorPayment', actionName: 'CanExport'}            
              const data = { columnList : this.columns, DataList : this.rows}
              const payload = { data : data, controller: controller}
              this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                  if (response.data.resultStatus == true) {
                      this.$downloadFile("Müvekkil", response.data.base64, 'xlsx')
                  }
                   else {
                      this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
    },
  }
  </script>
  <style lang="scss" >
  .excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
  }
  [dir] .dark-layout .card {
    background-color: #252d3f!important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%)!important;
  }
  .tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
.datepickerStyle{
  max-height: 2.2em;
  font-family: fangsong;
}
  </style>
    