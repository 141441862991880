<template>
  <div>
    <!-- modal -->
    <b-modal id="permissionTemplateFormModal" centered size="xl" no-close-on-backdrop ref="permissionTemplateFormModal"
      :title="$t('permissionTemplate.permission_template_info')" :cancel-title="$t('others.cancel_title')"
      :ok-title="$t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
      <validation-observer ref="simpleRules">
        <b-form>
          <label for="permission-template-name-input">{{  $t('permissionTemplate.permission_template_name')  }}</label>
          <b-form-group>
            <validation-provider #default="{ errors }" :name="$t('permissionTemplate.permission_template_name')"
              rules="required">
              <b-form-input size="sm" id="permission-template-name-input" v-model="permissionTemplateName" maxlength="50"
                :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{  errors[0]  }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!-- PERMISSION TABLE -->
      <b-card :hidden="hiddenPermissionTable" no-body class="border mt-1">
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon icon="LockIcon" size="18" />
            <span class="align-middle ml-50">{{  $t('permissionTemplate.permission')  }}</span>
          </b-card-title>
        </b-card-header>
        <!--eslint-disable-->
        <table class="table table-striped table-bordered">
          <tbody>
            <tr v-for="group in items" :key="group.id">
              <td>{{  group.name  }}</td>
              <td v-for="permission in group.permissionList">
                <b-row>
                  <b-form-checkbox @change="addDetail($event, permission.id, permission.tableId)"
                    :checked="permission.selected" />
                  {{  permission.name  }}
                </b-row>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  props: {
    getPermissionTemplateList: { type: Function },
  },
  data() {
    return {
      id: 0,
      permissionTemplateName: '',
      items: [],
      hiddenPermissionTable: true
    }
  },
  methods: {
    showInfo(id) {
      this.$refs['permissionTemplateFormModal'].show()
      this.id = id
        if (this.id == 0)
          this.hiddenPermissionTable = true
        else
          this.hiddenPermissionTable = false
        const data = {id: this.id}
        const controller = { name: 'PermissionTemplate' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.permissionTemplateName = response.data.permissionTemplate.name
            this.items = response.data.permissionGroupList
          } else {
            this.$bvModal.hide('permissionTemplateFormModal')
            this.$WarningAlert(response.data.resultMessage)
          }
        })
    },
    addDetail(selected, permissionId, tableId) {
      const controller = { name: 'PermissionTemplateDetail' }

      if (selected == true) {
        const data = { permissionTemplateId: this.id, permissionId: permissionId }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.$SaveAlert()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
      else {
        const data = { id: tableId }
        const payload = { data: data, controller: controller }

        this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.$SaveAlert()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
    },
    addOrUpdate() {
      const data = { id: this.id, name: this.permissionTemplateName }
      const controller = { name: 'PermissionTemplate' }
      const payload = { data: data, controller: controller }
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('moduleAuth/save', payload).then((response) => {
            if (response.data.resultStatus == true) {
              this.getPermissionTemplateList()
              this.$SaveAlert()
              if (this.id == 0) {
                this.id = response.data.permissionTemplateId
                this.hiddenPermissionTable = false
              }
            }
            else {
              this.$WarningAlert(response.data.resultMessage)
            }
          })
        }
      })
    }
  },
}
</script>
<style lang="scss">
[dir=ltr] .modal-header .close {
  margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
  margin-bottom: 0.2857rem;
  font-weight: bold;
}

.modal .modal-content {
  overflow: visible;
  margin-bottom: auto;
}

[dir] .invalid-tooltip {
  padding: 0.4rem 0.775rem !important;
  margin-top: -1.5rem !important;
  background-color: #ea5455 !important;
  border-radius: 0.358rem !important;
  left: 20px !important
}
</style>
