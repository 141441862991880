var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"translatorPriceFormModal",attrs:{"id":"translatorPriceFormModal","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('translatorPrice.price_info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)},"close":function($event){return _vm.clear()}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{staticClass:"col-lg-4 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"price-wordPrice-input"}},[_vm._v(_vm._s(_vm.$t('translatorPrice.word_price')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('translatorPrice.word_price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"price-wordPrice-input","maxlength":"7"},model:{value:(_vm.wordPrice),callback:function ($$v) {_vm.wordPrice=$$v},expression:"wordPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-lg-4 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"price-imagePrice-input"}},[_vm._v(_vm._s(_vm.$t('translatorPrice.image_price')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('translatorPrice.image_price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"price-wordPrice-input","maxlength":"7"},model:{value:(_vm.imagePrice),callback:function ($$v) {_vm.imagePrice=$$v},expression:"imagePrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-lg-4 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"price-formulaPrice-input"}},[_vm._v(_vm._s(_vm.$t('translatorPrice.formula_price')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('translatorPrice.formula_price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"price-formulaPrice-input","maxlength":"7"},model:{value:(_vm.formulaPrice),callback:function ($$v) {_vm.formulaPrice=$$v},expression:"formulaPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-lg-4 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"price-tablePrice-input"}},[_vm._v(_vm._s(_vm.$t('translatorPrice.table_price')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('translatorPrice.table_price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"price-tablePrice-input","maxlength":"7"},model:{value:(_vm.tablePrice),callback:function ($$v) {_vm.tablePrice=$$v},expression:"tablePrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-lg-4 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"price-pagePrice-input"}},[_vm._v(_vm._s(_vm.$t('translatorPrice.page_price')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('translatorPrice.page_price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"price-pagePrice-input","maxlength":"7"},model:{value:(_vm.pagePrice),callback:function ($$v) {_vm.pagePrice=$$v},expression:"pagePrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-md-4 mt-1"},[_c('b-form-group',[_c('label',{attrs:{"for":"design-date-input"}},[_vm._v(_vm._s(_vm.$t('translatorPrice.date')))]),_c('CustomDatePicker',{attrs:{"datePickerValidateOption":0},model:{value:(_vm.leaveDate),callback:function ($$v) {_vm.leaveDate=$$v},expression:"leaveDate"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }