<template>
    <div>
      <!-- modal -->
      <b-modal
        id="bulletinInfoModal"
        centered
        size="xl"
        no-close-on-backdrop
        ref="bulletinInfoModal"
        :title=" $t('bulletinInfo.info')"
        hide-footer
      >
      <div class="custom-search d-flex justify-content-center">
        <b-row>
          <b-col>
            <b-button
              variant="flat-primary"
              size="lg"
              class="btn-icon mr-1 mb-1"
              @click.prevent="backToPdf"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>
            <b-button
              variant="flat-primary"
              size="lg"
              class="btn-icon mr-1 mb-1"
              @click.prevent="nextToPdf"
            >
              <feather-icon icon="ArrowRightIcon" />
            </b-button>
          </b-col>
        </b-row>
       </div> 
        <embed :src="this.pdf" type="application/pdf" width="100%" height="550em" />
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getDepartmentList: {type: Function},
    },
    data() {
      return {
        id: 0,
        bulletinNo: 0,
        bulletinPage: 0,
        bulletinType: 0,
        image: '',
        pdf: ''
      }
    },
    methods: {
      showInfo(bulletinNo, bulletinPage, bulletinType) { 
        this.$refs['bulletinInfoModal'].show()
        this.pdf = ''
        this.bulletinNo = bulletinNo
        this.bulletinPage = bulletinPage
        this.bulletinType = bulletinType
        const data = {pageNo: this.bulletinPage, bulletinNo: this.bulletinNo, bulletinType: this.bulletinType}
        const controller = { name: 'Bulletin', actionName: 'DownloadBulletinPage' }
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if(response.data.resultStatus == true){
            if(response.data.base64 != null){
              this.image = response.data.base64
              this.pdf = `data:application/pdf;base64,${this.image}`
            } else{
              this.$bvModal.hide('bulletinInfoModal')
              this.$pdfNotExist(response.data.resultMessage)
            }
          } else {
            this.$bvModal.hide('bulletinInfoModal')
            this.$WarningAlert(response.data.resultMessage)
          }
        })  
      },
      nextToPdf(){
        this.bulletinPage = this.bulletinPage + 1
        const data = {pageNo: this.bulletinPage, bulletinNo: this.bulletinNo, bulletinType: this.bulletinType}
        const controller = { name: 'Bulletin', actionName: 'DownloadBulletinPage' }
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if(response.data.resultStatus == true){
            if(response.data.base64 != null){
              this.image = response.data.base64
              this.pdf = `data:application/pdf;base64,${this.image}`
            } else{
              this.$bvModal.hide('bulletinInfoModal')
              this.$pdfNotExist(response.data.resultMessage)
            }
          } else {
            this.$bvModal.hide('bulletinInfoModal')
            this.$WarningAlert(response.data.resultMessage)
          }
        }) 
      },
      backToPdf(){
        this.bulletinPage = this.bulletinPage -1
        const data = {pageNo: this.bulletinPage, bulletinNo: this.bulletinNo, bulletinType: this.bulletinType}
        const controller = { name: 'Bulletin', actionName: 'DownloadBulletinPage' }
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if(response.data.resultStatus == true){
            if(response.data.base64 != null){
              this.image = response.data.base64
              this.pdf = `data:application/pdf;base64,${this.image}`
            } else{
              this.$bvModal.hide('bulletinInfoModal')
              this.$pdfNotExist(response.data.resultMessage)
            }
          } else {
            this.$bvModal.hide('bulletinInfoModal')
            this.$WarningAlert(response.data.resultMessage)
          }
        }) 
      }
    }
  }
  </script>
  