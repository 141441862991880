<template>
    <div>
      <!-- modal -->
      <b-modal
        id="nationalPatentCountryShowHistoryListFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="nationalPatentCountryShowHistoryListFormModal"
        :title="this.transportToCountryName"
        hide-footer
      >
        <national-patent-history-list :transportToCountryName="transportToCountryName" :nationalPatentCountryId="nationalPatentCountryId" :getNationalPatentList="getNationalPatentList" :getNationalPatentCountryList="getNationalPatentCountryList"></national-patent-history-list>
      </b-modal>
    </div>
  </template>
  <script>

  export default {
    props: { 
        getNationalPatentCountryList: {type: Function},
        getNationalPatentList: {type: Function},
        transportToCountryName: String
    },
    data() {
      return {
        id: 0,
        nationalPatentCountryId: 0,
        nationalPatentCountryAgentOption: [],
        agentReferenceNo: '',
      }
    },
    methods: {
      showInfo(id){
        this.$refs['nationalPatentCountryShowHistoryListFormModal'].show()
        this.nationalPatentCountryId = id
      }
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  