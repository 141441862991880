<template>
<div>
    <annual-leave-approve-form :getAnnualLeaveList="getAnnualLeaveList" ref="annualLeaveApproveFormModal"></annual-leave-approve-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="annual-leave-employee-input">{{ $t('annualLeave.employee') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="employee" @input="getAnnualLeaveList()" :placeholder="$t('annualLeave.employee')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="annual-leave-status-input">{{ $t('annualLeave.status') }}</label>
                <b-form-select v-model="annualLeaveStatusSelect" @change="getAnnualLeaveList()" size="sm" value-field="id" text-field="name" :options="annualLeaveStatusOption" />
            </b-col>
            <b-col class="col-md-2">
                <label for="annual-leave-type-input">{{ $t('annualLeave.type') }}</label>
                <b-form-select v-model="annualLeaveTypeSelect" @change="getAnnualLeaveList()" size="sm" value-field="id" text-field="name" :options="annualLeaveTypeOption" />
            </b-col>
            <b-col class="col-md-2">
                <label for="annual-leave-request-date-start-input">{{ $t('annualLeave.request_date_start') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" @input="getAnnualLeaveList()" v-model="requestDateStart"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-2">
                <label for="annual-leave-request-date-end-input">{{ $t('annualLeave.request_date_end') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" @input="getAnnualLeaveList()" v-model="requestDateEnd"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                </div>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="annualLeaveEmployeeTable" ref="annualLeaveEmployeeTable" :columns="columns" styleClass="vgt-table condensed tableSize" :rows="rows" :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }" :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }" :pagination-options="{
                enabled: true,
                perPage: pageLength
              }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('annualLeave.status') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id,employeeId)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <!-- ,'100', '1000'
                        Bu alanları kaldıran : Nevzat PEKER 11/07/2023 -->
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getAnnualLeaveList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>     
export default {
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('annualLeave.employee'),
                    field: 'employee',
                },
                {
                    label: this.$t('annualLeave.annual_leave_type'),
                    field: 'annualLeaveType',
                },
                {
                    label: this.$t('annualLeave.starting_date'),
                    field: 'startingDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('annualLeave.ending_date'),
                    field: 'endingDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('annualLeave.approve_description'),
                    field: 'approveDescription',
                },
                {
                    label: this.$t('annualLeave.request_date'),
                    field: 'requestDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('annualLeave.request_description'),
                    field: 'requestDescription',
                },
                {
                    label: this.$t('annualLeave.annual_leave_status'),
                    field: 'annualLeaveStatus',
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            tokenEmployeeId: 0,
            id: 0,
            annualLeaveStatusSelect: 0,
            annualLeaveStatusOption: [],
            annualLeaveTypeSelect: 0,
            annualLeaveTypeOption: [],
            requestDateStart: null,
            requestDateEnd: null,
            employee : '',
            employeeId : 0,
            annualLeaveToApprove: null,
        }
    },
    mounted() {
        this.annualLeaveToApprove = this.$route.query.annualLeaveToApprove;
        if (this.annualLeaveToApprove != null) {
            this.annualLeaveStatusSelect = 2
        } else this.annualLeaveStatusSelect = 0
        this.getAnnualLeaveList()
        if (this.employeeId == 0) {
            this.employeeId = localStorage.getItem('EmployeeId')
        }
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id,
                pageLength: this.pageLength,
                pageNo: this.pageNo
            }
            const controller = {
                name: 'AnnualLeaveRequest'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getAnnualLeaveList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getAnnualLeaveList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.annualLeaveApproveFormModal.showInfo(id)
        },
        getAnnualLeaveList() {
            this.rows = []
            const controller = {
                name: 'AnnualLeaveRequest',
                actionName: 'GetAnnualLeaveRequestList'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                employee: this.employee,
                annualLeaveStatusId: this.annualLeaveStatusSelect,
                annualLeaveTypeId: this.annualLeaveTypeSelect,
                requestDateStart: this.requestDateStart,
                requestDateEnd: this.requestDateEnd
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.annualLeaveStatusSelect = this.annualLeaveStatusSelect
                    this.annualLeaveStatusOption = response.data.annualLeaveStatusList
                    this.annualLeaveTypeSelect = this.annualLeaveTypeSelect
                    this.annualLeaveTypeOption = response.data.annualLeaveTypeList
                    this.annualLeaveStatusOption.splice(0, 0, this.$nullSelected())
                    this.annualLeaveTypeOption.splice(0, 0, this.$nullSelected())
                    this.total = response.data.total
                    response.data.annualLeaveRequestList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'AnnualLeaveRequest',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("AnnualLeaveDurumlari", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
