<template>
<div>
    <!-- modal -->
    <b-modal id="annualLeaveRightFormModal" centered size="lg" no-close-on-backdrop ref="annualLeaveRightFormModal" :title=" $t('annualLeaveRight.annual_leave_right_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-form>
                <label for="annual-leave-right-min-year-input">{{ $t('annualLeaveRight.min_working_year') }}</label>
                <b-form-group>
                    <validation-provider #default="{ errors }" :name=" $t('annualLeaveRight.min_working_year')" rules="required">
                        <b-form-input id="annual-leave-right-min-year-input" v-model="minWorkingYear" type="number" size="sm" :state="errors.length > 0 ? false:null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <label for="annual-leave-right-max-year-input">{{ $t('annualLeaveRight.max_working_year') }}</label>
                <b-form-group>
                    <validation-provider #default="{ errors }" :name=" $t('annualLeaveRight.max_year')" rules="required">
                        <b-form-input id="annual-leave-right-max-year-input" v-model="maxWorkingYear" type="number" size="sm" :state="errors.length > 0 ? false:null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <label for="deserved-annual-vacation-days-input">{{ $t('annualLeaveRight.deserved_annual_vacation_days') }}</label>
                <b-form-group>
                    <validation-provider #default="{ errors }" :name=" $t('annualLeaveRight.deserved_annual_vacation_days')" rules="required">
                        <b-form-input id="deserved-annual-vacation-days-input" v-model="deservedAnnualVacationDays" type="number" size="sm" :state="errors.length > 0 ? false:null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getAnnualLeaveRightList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            minWorkingYear: 0,
            maxWorkingYear: 0,
            deservedAnnualVacationDays: 0,
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['annualLeaveRightFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'AnnualLeaveRight'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.minWorkingYear = response.data.annualLeaveRight.minWorkingYear
                    this.maxWorkingYear = response.data.annualLeaveRight.maxWorkingYear
                    this.deservedAnnualVacationDays = response.data.annualLeaveRight.deservedAnnualVacationDays

                } else {
                    this.$bvModal.hide('annualLeaveRightFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                deservedAnnualVacationDays: this.deservedAnnualVacationDays,
                maxWorkingYear: this.maxWorkingYear,
                minWorkingYear: this.minWorkingYear
            }
            const controller = {
                name: 'AnnualLeaveRight'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('annualLeaveRightFormModal')
                            this.getAnnualLeaveRightList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.$bvModal.hide('annualLeaveRightFormModal')
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
