<template>
    <div>
      <owner-note-form ref="ownerNoteFormModal"></owner-note-form>
      <national-patent-owner-form ref="nationalPatentOwnerForm" :getNationalPatentList="getNationalPatentList" :getNationalPatentOwnerList="getNationalPatentOwnerList"  :nationalPatentId="this.nationalPatentId"></national-patent-owner-form>
      <b-row>
        <b-col class="col-12">
          <div style="float:right;" class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-button style="float:right;" size="sm" @click="getInfo()" variant="outline-primary"
                  >{{ $t('nationalPatentOwner.owner_add') }}</b-button>
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table 
      id="nationalPatentOwnerTable"
      ref="nationalPatentOwnerTable" 
      :columns="columns" 
      :rows="rows"
      styleClass="vgt-table condensed tableSize"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
        theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'clientName'" class="text-nowrap">
            {{ $t('nationalPatentOwner.owner_name') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('note.note')" variant="light" class="btn-icon mb-1 ml-1"
              size="sm" @click="getOwnerNoteInfo(props.row.clientId)">
                <feather-icon icon="AlignRightIcon" />
              </b-button>
              <b-button v-b-tooltip.html :title="$t('others.show_owner')" variant="info" class="btn-icon mb-1 ml-1"
                size="sm" @click="getShowOwner(props.row.clientId)">
                <feather-icon icon="UsersIcon" />
              </b-button>
              <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  
  export default {
    props: {
      nationalPatentId: Number,
      getNationalPatentList: {type: Function},
      getClientInfo: {type: Function},
    },
    data() {
      return {
        dir: false,
        columns: [
          {
            label: this.$t('nationalPatentOwner.owner_name'),
            field: `clientName`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '15em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      this.getNationalPatentOwnerList()
    },
    methods: {
      async getInfo() {
        this.$refs.nationalPatentOwnerForm.showInfo()
      },
      async getOwnerNoteInfo(clientId) {
        this.$refs.ownerNoteFormModal.getNoteListByClientId(clientId)        
      }, 
      async getShowOwner(clientId) {
        this.$refs.clientFormModal.showInfo(clientId)        
      }, 
      async getShowOwner(clientId) {
      this.getClientInfo(clientId)      
    }, 
      async deleted(data) {
        data = { id: data.id }
        const controller = { name: 'NationalPatentOwner' }
        const payload = { data: data, controller: controller }
        this.$DeleteConfirm().then(result => {
          if (result.value) {
            this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$DeleteAlert()
                this.getNationalPatentOwnerList()
                this.getNationalPatentList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getNationalPatentOwnerList()
              }
            })
          }
        })
      },
      getNationalPatentOwnerList() {
        this.rows = [] 
        const data = { id: this.nationalPatentId }
        const controller = { name: 'NationalPatentOwner', actionName:'GetNationalPatentOwnerListByNationalPatentId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            response.data.nationalPatentOwnerList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
    },
  }
  </script>
  <style lang="scss" >
    .tableSize {
      font-size: 13px!important;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      table-layout: auto;
  }
  </style>
  