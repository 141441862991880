<template>
<div>

    <!-- modal -->
    <b-modal id="domainOppositionFormModal" centered size="xl" no-close-on-backdrop ref="domainOppositionFormModal" :title=" $t('domainOpposition.domainOpposition_info')" hide-footer>
        <reminder-form ref="reminderFormModal"></reminder-form>
        <agent-note-form ref="agentNoteFormModal"></agent-note-form>
        <client-form ref="clientFormModal"></client-form>
        <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
        <b-card>
            <div class="custom-search d-flex justify-content-end">
                <b-row>
                    <b-col>
                        <b-button variant="outline-primary" size="sm" class="ml-05" @click.prevent="addOrUpdate">
                            {{$t('others.ok_title')}}
                        </b-button>
                        <b-dropdown v-if="id > 0" style="height: 2.1em;" class="ml-05" size="sm" :text="$t('others.invoices')" variant="outline-primary">
                            <b-dropdown-item @click="getClientInvoiceReport(2)">{{$t('others.InvoicesToBePaid')}}: {{ invoicesToBePaid }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(3)">{{$t('others.AllInvoices')}}: {{ allInvoices }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(4)">{{$t('others.InvoicesToBePaidForThisJob')}}: {{invoicesToBePaidForThisJob}}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(1)">{{$t('others.AllInvoicesRelatedToThisJob')}}: {{ allInvoicesRelatedToThisJob }}</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                            <template #button-content>
                                <feather-icon icon="PrinterIcon" size="10" class="align-middle" />
                            </template>
                            <div class="dropdown-scroll">
                                <b-dropdown-item @click="downloadDomainOppositionWordFile(wordTemplate.id, wordTemplate.name)" v-for="wordTemplate in wordTemplateList" :key="wordTemplate.id">
                                    <span>{{wordTemplate.name}}</span>
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                        <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                            <template #button-content>
                                <feather-icon icon="SendIcon" size="10" class="align-middle" />
                            </template>
                            <div class="dropdown-scroll">
                                <b-dropdown-item @click="prepareDomainOppositionMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                    <span>{{mailTemplate.explanation}}</span>
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                        <b-button variant="outline-primary" class="ml-05" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                            <feather-icon icon="BellIcon" size="10" class="align-middle" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col-md-6">
                        <b-form>
                            <label for="domainOpposition-record-no-input">{{ $t('domainOpposition.record_no') }}</label>
                            <b-form-group>
                                <b-form-input id="domainOpposition-record-no-input" size="sm" v-model="domainOppositionRecordNo" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-6">
                        <b-form>
                            <label for="domainOpposition-domainname-input">{{ $t('domainOpposition.domain_name') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name=" $t('domainOpposition.domain_name')" rules="required">
                                    <b-form-input id="domainOpposition-domainname-input" v-model="domainOppositionDomainName" maxlength="50" size="sm" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6">
                        <b-form>
                            <label for="domainOpposition-holderName-input">{{ $t('domainOpposition.holder_name') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name=" $t('domainOpposition.holder_name')" rules="required">
                                    <b-form-input id="domainOpposition-holderName-input" v-model="holderName" maxlength="250" size="sm" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-6">
                        <b-form-group>
                            <label for="domainOpposition-appealDeadline-input">{{ $t('domainOpposition.appeal_deadline') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="appealDeadline"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12">
                        <label for="textarea-default">{{ $t('domainOpposition.note') }}</label>
                        <b-form-textarea id="textarea-default" :placeholder=" $t('domainOpposition.note')" v-model="notes" size="sm" rows="1" maxlength="250" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="hiddenControl == false" class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="domain-opposition-agent-input"> {{ $t('domainOpposition.agent') }} </label>
                            <b-input-group>
                                <validation-provider :name=" $t('domainOpposition.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                    <b-form-group label-for="domain-opposition-agent-input" :state="errors.length > 0 ? false:null">
                                        <v-select class="agentInput" id="domain-opposition-agent-input" v-model="domainOppositionAgentSelect" :options="domainOppositionAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                                        </v-select>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-input-group-append>
                                    <b-dropdown style="width: 4em; height: 2.5em; margin-bottom: 1em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                        <template #button-content>
                                            <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                        </template>
                                        <b-dropdown-item @click="getClientInfo(0)">
                                            <span>{{this.$t('others.add_client')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getAgentNoteInfo(domainOppositionAgentSelect.clientAccountingId)">
                                            <span>{{this.$t('others.show_note')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getClientInfo(domainOppositionAgentSelect.clientId)">
                                            <span>{{this.$t('others.show_agent')}}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="hiddenControl == false" class="col-lg-12">
                        <b-form>
                            <label for="domainOpposition-agentReferenceNo-input">{{ $t('domainOpposition.agent_ref_code') }}</label>
                            <b-form-group>
                                <b-form-input id="domainOpposition-agentReferenceNo-input" v-model="agentReferenceNo" maxlength="50" size="sm" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="hiddenControl == true" class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="domain-opposition-agent-input"> {{ $t('domainOpposition.agent') }} </label>
                            <b-input-group>
                                <validation-provider :name=" $t('domainOpposition.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                    <b-form-group label-for="domain-opposition-agent-input" :state="errors.length > 0 ? false:null">
                                        <v-select class="agentInput" id="domain-opposition-agent-input" v-model="domainOppositionAgentSelect" :options="domainOppositionAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                                        </v-select>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-input-group-append>
                                    <b-button variant="success" style="width: 4em; height: 3em; margin-bottom: 1em" size="sm" v-b-tooltip.html :title="$t('others.add_client')" @click="getClientInfo(0)">
                                        <feather-icon icon="PlusCircleIcon" />
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="hiddenControl == true" class="col-lg-12">
                        <b-form-group>
                            <label for="domainOpposition-agentReferenceNo-input">{{ $t('domainOpposition.agent_ref_code') }}</label>
                            <b-input-group>
                                <b-form-group>
                                    <b-form-input size="sm" id="domainOpposition-agentReferenceNo-input" v-model="agentReferenceNo" maxlength="50" />
                                </b-form-group>
                                <b-input-group-append>
                                    <b-button variant="success" style="width: 4em; height: 2.5em; margin-bottom: 1em" size="sm" v-b-tooltip.html :title="$t('others.show_agent')" @click="getClientInfo(domainAgentSelect.clientId)">
                                        <feather-icon icon="TerminalIcon" />
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-card>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title=" $t('domainOpposition.owner')" active style="width:100%;">
                            <b-card-text>
                                <domainOpposition-owner-list :getClientInfo="getClientInfo" :getDomainOppositionList="getDomainOppositionList" :domainOppositionId="this.id"></domainOpposition-owner-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('domainOpposition.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="8"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('domainOpposition.responsible')" style="width:100%;">
                            <b-card-text>
                                <domainOpposition-responsible-list :domainOppositionId="this.id" :clientId="this.clientId"></domainOpposition-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('domainOpposition.basis_for_opposition')" style="width:100%;">
                            <b-card-text>
                                <domainOpposition-basis-for-opposition-list :getDomainOppositionList="getDomainOppositionList" :domainOppositionId="this.id"></domainOpposition-basis-for-opposition-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.job')" style="width:100%;">
                            <b-card-text>
                                <job-list :recordId="this.id" :moduleId="8" :recordNo="this.domainOppositionRecordNo"></job-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="8"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDomainOppositionList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            domainOppositionDomainName: '',
            holderName: '',
            domainOppositionRecordNo: '',
            appealDeadline: null,
            domainOppositionAgentSelect: 0,
            domainOppositionAgentOption: [],
            agentReferenceNo: '',
            notes: '',
            hiddenControl: true,
            wordTemplateList: [],
            mailTemplateList: [],
            invoicesToBePaidForThisJob: 0,
            invoicesToBePaid: 0,
            allInvoices: 0,
            allInvoicesRelatedToThisJob: 0,
            dateOption: {
                date: true,
                delimiter: '.',
                datePattern: ['d', 'm', 'Y'],
            },
        }
    },
    methods: {
        getClientInvoiceReport(type) {
            this.$refs.clientInvoiceReportListCallForm.showInfo(type, this.clientAccountingId, 8, this.id)
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getReminderInfo() {
            this.$refs.reminderFormModal.showInfo(0, this.id, 8)
        },
        setClientId(event) {
            this.clientId = event.clientId
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.domainOppositionAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        showInfo(id) {
            this.$refs['domainOppositionFormModal'].show()
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'DomainOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.domainOppositionRecordNo = response.data.domainOpposition.recordNo
                    this.wordTemplateList = response.data.wordTemplateList
                    this.mailTemplateList = response.data.mailTemplateList
                    this.domainOppositionDomainName = response.data.domainOpposition.domainName
                    this.holderName = response.data.domainOpposition.holderName
                    this.registerDate = response.data.domainOpposition.recordDate
                    this.appealDeadline = response.data.domainOpposition.appealDeadline
                    this.agentReferenceNo = response.data.domainOpposition.agentReferenceNo
                    this.notes = response.data.domainOpposition.note
                    if (response.data.domainOpposition.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.domainOpposition.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.domainOppositionAgentOption = clientAccountingResponse.data.clientList
                            this.domainOppositionAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.domainOppositionAgentOption = []
                        this.domainOppositionAgentSelect = 0;
                    }
                    if (this.id > 0) {
                        let accountingId = 0
                        if (response.data.domainOpposition.clientAccountingId > 0)
                            accountingId = response.data.domainOpposition.clientAccountingId
                        if (response.data.domainOpposition.invoiceClientAccountingId > 0)
                            accountingId = response.data.domainOpposition.invoiceClientAccountingId
                        this.clientAccountingId = accountingId

                        const invoiceData = {
                            clientAccountingId: accountingId,
                            moduleId: 8,
                            recordId: this.id
                        }
                        const invoiceController = {
                            name: 'ClientAccounting',
                            actionName: 'GetInvoiceCountByClientAccountId'
                        }
                        const invoicePayload = {
                            data: invoiceData,
                            controller: invoiceController
                        }
                        this.$store.dispatch('moduleAuth/customService', invoicePayload).then((invoiceResponse) => {
                            this.invoicesToBePaid = invoiceResponse.data.invoicesToBePaidCount
                            this.allInvoices = invoiceResponse.data.allInvoiceCount
                            this.allInvoicesRelatedToThisJob = invoiceResponse.data.allInvoicesRelatedToThisJobCount
                            this.invoicesToBePaidForThisJob = invoiceResponse.data.invoicesToBePaidForThisJobCount
                        })

                    } else {
                        this.invoicesToBePaid = 0
                        this.allInvoices = 0;
                        this.allInvoicesRelatedToThisJob = 0;
                        this.invoicesToBePaidForThisJob = 0;
                    }
                } else {
                    this.$bvModal.hide('domainOppositionFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },

        downloadDomainOppositionWordFile(documentId, name) {
            const data = {
                moduleId: 8,
                documentId: documentId,
                domainOppositionId: this.id
            }
            const controller = {
                name: 'DomainOpposition',
                actionName: 'DownloadDomainOppositionWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },

        addOrUpdate() {
            if (this.appealDeadline == "") {
                this.appealDeadline = null
            }
            const data = {
                id: this.id,
                domainName: this.domainOppositionDomainName,
                holderName: this.holderName,
                clientAccountingId: this.domainOppositionAgentSelect.clientAccountingId,
                appealDeadline: this.appealDeadline,
                note: this.notes,
                trademarkId: this.trademarkId,
                employeeId: localStorage.getItem('EmployeeId'),
                agentReferenceNo: this.agentReferenceNo
            }
            const controller = {
                name: 'DomainOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getDomainOppositionList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.domainOppositionId
                                this.domainOppositionRecordNo = response.data.recordNo
                                this.hiddenControl = false
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        prepareDomainOppositionMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                domainOppositionId: this.id
            }
            const controller = {
                name: 'DomainOpposition',
                actionName: 'PrepareDomainOppositionMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
