<template>
    <div>
        <patent-form :getPatentList="getPatentList" ref="patentFormModal">
        </patent-form>
        <!-- modal -->
        <b-modal
          id="patentTradosUploadFormModal"
          centered
          size="lg"
          no-close-on-backdrop
          ref="patentTradosUploadFormModal"
          :title=" $t('patentTranslateTrados.add_trados')"
          :cancel-title=" $t('others.cancel_title')"
          :ok-title=" $t('others.add')"
          cancel-variant="outline-secondary"
          @ok.prevent="addOrUpdate"
        >
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col>
            <validation-provider
                  #default="{ errors }"
                  :name=" $t('patentTranslateTrados.select_trados')"
                  rules="required"
              >
              <label for="patent-trados-input">{{ $t('patentTranslateTrados.trados_folder') }}</label>
            <b-input-group>
              <b-form-input
                  id="patent-trados-input"
                  v-model="tradosName"
                  maxlength="50"
                  :state="errors.length > 0 ? false:null"
              />
  
              <b-input-group-append>
                <b-button variant="outline-primary" onclick="document.getElementById('patentTradosUploadFileSelect').click()">Seçiniz</b-button>
              </b-input-group-append>
  
            </b-input-group>
  
            <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-col>
          <b-form-file
          style="display:none"
          id="patentTradosUploadFileSelect"
          accept=".xlsx"
          @change="uploadFile"
          :placeholder=" $t('patentTranslateTrados.select_trados')"
          :drop-placeholder=" $t('patentTranslateTrados.trados_select_info')"
          />
        </b-row>
      </validation-observer>
        </b-modal>
      </div>
    </template>
    <script>
  
    export default {
      props: {
        getPatentList: {type: Function},
        getPatentInfo: {type: Function},
      },
      data() {
        return {
          id: 0,
          tradosName: '',
          wordFile: '',
          fileInfo : [],
          tradosValues: [],
        }
      },
      methods: {
        showInfo() {
          this.$refs['patentTradosUploadFormModal'].show()
      },
      async uploadFile(e){
        var fileInfo = await this.$getFileInfo( e.target.files[0])
        this.tradosName = fileInfo.name
        this.wordFile = fileInfo.base64
      },
      addOrUpdate() {
        const data = {name: this.tradosName, base64: this.wordFile}
        const controller = {name : 'PatentTranslate',actionName: 'ReadFromTradosReport'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/customService',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.tradosValues.push(response.data)
                    this.$emit('tradosValues', this.tradosValues)
                    this.$bvModal.hide('patentTradosUploadFormModal')
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
      }
    }
    </script>
    <style lang="scss">
    [dir=ltr] .modal-header .close {
        margin: -0.4rem -0.4rem -0.8rem auto;
    }
    [dir] label {
        margin-bottom: 0.2857rem;
        font-weight: bold;
    }
    .modal .modal-content {
        overflow: visible;
        margin-bottom: auto;
    }
    [dir] .invalid-tooltip {
        padding: 0.4rem 0.775rem!important;
        margin-top: -1.5rem!important;
        background-color: #ea5455!important;
        border-radius: 0.358rem!important;
        left: 20px!important
    }
    .input-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: end!important;
      width: 100%;
  }
    </style>
    