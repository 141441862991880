<template>
<div>
    <!-- modal -->
    <b-modal id="holidayFormModal" centered size="lg" no-close-on-backdrop ref="holidayFormModal" :title=" $t('holiday.holiday_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-md-12">
                    <b-form>
                        <label for="holiday-description-input">{{ $t('holiday.description') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('holiday.description')" rules="required">
                                <b-form-input size="sm"  id="holiday-description-input" v-model="description" maxlength="100" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12">
                    <b-form>
                        <label for="holiday-summary-input">{{ $t('holiday.summary') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('holiday.summary')" rules="required">
                                <b-form-input size="sm" id="holiday-summary-input" v-model="summary" maxlength="100" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                    <b-col class="col-md-6">
                      <b-form-group>
                            <label for="holiday-startdate-input">{{ $t('holiday.start_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="startDate"></CustomDatePicker>
                        </b-form-group>
                </b-col>
                    <b-col class="col-md-6">
                        <b-form-group>
                            <label for="holiday-enddate-input">{{ $t('holiday.end_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="endDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getHolidayList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            description: '',
            summary: '',
            startDate : null,
            endDate : null,
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['holidayFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Holiday'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.description = response.data.holiday.description
                    this.summary = response.data.holiday.summary
                    this.startDate = response.data.holiday.start
                    this.endDate = response.data.holiday.end
                } else {
                    this.$bvModal.hide('holidayFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                description: this.description,
                summary : this.summary,
                end : this.endDate,
                start: this.startDate
            }
            const controller = {
                name: 'Holiday'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('holidayFormModal')
                            this.getHolidayList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
