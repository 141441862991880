<template>
<div>
    <design-bulletin-analyse-report-form :getDesignBulletinAnalyseReportList="getDesignBulletinAnalyseReportList" ref="designBulletinAnalyseFormModal"></design-bulletin-analyse-report-form>
    <p-d-f-viewer ref="designBulletinInfoModal"></p-d-f-viewer>
    <b-row>
        <b-col>
            <b-card>
                <b-row>
                    <b-col>
                        <b-card-title style="text-align: right;">{{$t('others.search_criteria')}}</b-card-title>
                    </b-col>
                    <b-col>
                        <b-button style="float:right;" size="sm" variant="outline-primary" @click="getDesignBulletinAnalyseReportList()" v-on:keyup.enter="getDesignBulletinAnalyseReportList()">{{ $t('translate.list') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <label for="designBulletinAnalyseReport-no-select">{{ $t('designBulletinAnalyseReport.bulletin_select') }}</label>
                        <b-form-select v-model="bulletinSelect" size="sm" value-field="id" text-field="no" :options="bulletinOption" />
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="designBulletinAnalyseReport-designName-input">{{ $t('designBulletinAnalyseReport.design_name') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="designBulletinAnalyseReport-designName-input" v-model="designName" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="designBulletinAnalyseReport-locarnoCodes-input">{{ $t('designBulletinAnalyseReport.locarno_codes') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="designBulletinAnalyseReport-locarnoCodes-input" v-model="locarnoCodes" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="designBulletinAnalyseReport-designerName-input">{{ $t('designBulletinAnalyseReport.designer_name') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="designBulletinAnalyseReport-designerName-input" v-model="designerName" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="designBulletinAnalyseReport-attorney-input">{{ $t('designBulletinAnalyseReport.attorney') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="designBulletinAnalyseReport-attorney-input" v-model="attorney" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <label for="designBulletinAnalyseReport-origin-select">{{ $t('designBulletinAnalyseReport.origin') }}</label>
                        <b-form-select size="sm" v-model="originSelect" value-field="id" text-field="name" :options="originOption" />
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="designBulletinAnalyseReportTable" ref="designBulletinAnalyseReportTable" :columns="columns" :rows="rows" :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" 
          styleClass="vgt-table condensed tableSize"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }" theme="my-theme">
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field ==='applicationNo'" class="text-nowrap">
                {{ $t("designBulletinAnalyseReport.application_no") }}
            </span>
            <span v-else-if="props.column.field ==='applicationDate'" class="text-nowrap">
                {{ $t("designBulletinAnalyseReport.application_date") }}
            </span>
            <span v-else-if="props.column.field ==='bulletinNo'" class="text-nowrap">
                {{ $t("designBulletinAnalyseReport.bulletin_no") }}
            </span>
            <span v-else-if="props.column.field ==='tpmkDesignDetailList'" class="text-nowrap">
                {{ $t("designBulletinAnalyseReport.product_name")}}
            </span>
            <span v-else-if="props.column.field ==='tpmkDesignHolderList'">
                {{ $t("designBulletinAnalyseReport.holder_name") }}
            </span>
            <span v-else-if="props.column.field ==='tpmkDesignDesignerList'" class="text-nowrap">
                {{ $t("designBulletinAnalyseReport.designer_name") }}
            </span>
            <span v-else-if="props.column.field ==='attorney'">
                {{ $t("designBulletinAnalyseReport.attorney") }}
            </span>
            <span v-else-if="props.column.field ==='process'" class="text-nowrap">
                {{ $t("others.process") }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- 
            <span v-else-if="props.column.field === 'attorney'">
                <b-col style="width: 100%;">
                    <feather-icon class="featherIconReSize" icon="UserIcon" />
                    <span style=" font-size: x-small" class="mb-05 text-nowrap">
                        {{props.row.attorney}}
                    </span>
                </b-col>
            </span> -->
            <span v-if="props.column.field === 'applicationNo'" class="text-nowrap">
                {{ props.row.applicationNo }}
            </span>
            <span v-else-if="props.column.field === 'applicationDate'" class="text-nowrap">
                {{ $formatFn(props.row.applicationDate) }}
            </span>
            <span v-else-if="props.column.field === 'bulletinNo'" class="text-nowrap">
                {{ props.row.bulletinNo }}
            </span>
            <span v-else-if="props.column.field === 'tpmkDesignDetailList'" class="text-nowrap">
                <b-col v-for="designDetail in props.row.tpmkDesignDetailList" v-bind:key="designDetail.id">
                    <b-row>
                        <span style=" font-size: x-small" class="text-nowrap">
                            {{ designDetail.productName }} 
                        </span> 
                    </b-row>
                    <b-row>
                        <span style="font-size: x-small" class="mb-05 text-nowrap">
                            <tr v-if="designDetail.locarnoCodes !== ''">
                              {{designDetail.locarnoCodes}} 
                            </tr>
                        </span>
                    </b-row>
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'tpmkDesignHolderList'">
                <b-col v-for="designHolder in props.row.tpmkDesignHolderList" v-bind:key="designHolder.id">
                    <b-row>
                        <b-col>
                            <span style=" font-size: x-small">
                                {{designHolder.name }}
                            </span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <span style=" font-size: x-small">
                                <tr v-if="designHolder.address !== ''">
                                    {{designHolder.address}}
                                </tr>
                            </span>
                        </b-col>
                    </b-row>
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'tpmkDesignDesignerList'" class="text-nowrap">
                <b-col>
                    <b-row v-for="designDesigner in props.row.tpmkDesignDesignerList" v-bind:key="designDesigner.id" style="font-size: x-small;">
                        <tr v-if="props.row.tpmkDesignDesignerList.length != 1">
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ designDesigner.name }} </span> <br />
                        </tr>
                        <tr v-else-if="props.row.tpmkDesignDesignerList.length == 1">
                            <span>{{ designDesigner.name }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'attorney'">
                <span style=" font-size: x-small" class="mb-05">
                    {{props.row.attorney}}
                </span>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title=" $t('others.show')" v-b-modal.modal-top variant="info" 
                    class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.bulletin')" variant="warning" class="btn-icon mb-1 ml-1"
                    size="sm" @click="openBulletin(props.row.bulletinNo, props.row.bulletinPage, 1)">
                        <feather-icon icon="BoldIcon" />
                  </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                    </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getDesignBulletinAnalyseReportList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [{
                    label: this.$t("designBulletinAnalyseReport.application_no"),
                    field: "applicationNo",
                },
                {
                    label: this.$t("designBulletinAnalyseReport.application_date"),
                    field: `applicationDate`,
                    formatFn: this.$formatFn,
                },
                {
                    label: this.$t("designBulletinAnalyseReport.bulletin_no"),
                    field: "bulletinNo",
                },
                {
                    label: this.$t("designBulletinAnalyseReport.product_name"),
                    field: "tpmkDesignDetailList",
                },
                {
                    label: this.$t("designBulletinAnalyseReport.holder_name"),
                    field: "tpmkDesignHolderList",
                },
                {
                    label: this.$t("designBulletinAnalyseReport.designer_name"),
                    field: "tpmkDesignDesignerList",
                    width: "15em"
                },
                {
                    label: this.$t("designBulletinAnalyseReport.attorney"),
                    field: "attorney",
                },
                {
                    label: "Process",
                    field: "process",
                    width: "10em",
                },
            ],
            rows: [],
            searchTerm: "",
            bulletinOption: [],
            bulletinSelect: 0,
            designName: null,
            locarnoCodes: null,
            designerName: null,
            attorney: null,
            originSelect: 0,
            originOption: [{
                    id: 0,
                    name: this.$t('translate.select')
                },
                {
                    id: 1,
                    name: this.$t('designBulletinAnalyseReport.domestic')
                },
                {
                    id: 2,
                    name: this.$t('designBulletinAnalyseReport.abroad')
                },
            ],
            pageNo: 1,
            pageLength: 10,
            total: 0,
            domestic: false,
            abroad: false
        };
    },
    mounted() {
        this.getDesignBulletinAnalyseReportList();


    },
    methods: {
        async openBulletin(bulletinNo, bulletinPage, bulletinType){
            var bulletinString = bulletinNo.toString()
            this.$refs.designBulletinInfoModal.showInfo(bulletinString, bulletinPage, bulletinType)
        },
        async getInfo(id) {
            this.$refs.designBulletinAnalyseFormModal.showInfo(id);
        },
        getDesignBulletinAnalyseReportList() {
            if (this.originSelect == 1) {
                this.domestic = true;
                this.abroad = false;
            } else if (this.originSelect == 2) {
                this.abroad = true;
                this.domestic = false;
            }
            const data = {
                bulletinId: this.bulletinSelect,
                productName: this.designName,
                locarnoCodes: this.locarnoCodes,
                designerName: this.designerName,
                attorney: this.attorney,
                abroad: this.abroad,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                domestic: this.domestic
            }
            this.rows = [];
            const controller = {
                name: "Bulletin",
                actionName: 'GetDesignBulletinAnalyseReport'
            }
            const payload = {
                data: data,
                controller: controller,
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.designBulletinAnalyseList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.total = response.data.total

                    this.bulletinOption = response.data.bulletinList
                    this.bulletinOption.splice(0, 0, this.$nullSelected('no'))
                }
                
            })

        },
        exportExcel() {
            const controller = {
                name: "Bulletin",
                actionName: "CanExport",
            };
            const data = {
                columnList: this.columns,
                DataList: this.rows,
            };
            const payload = {
                data: data,
                controller: controller,
            };
            this.$store
                .dispatch("moduleAuth/customService", payload)
                .then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("Export", response.data.base64, "xlsx");
                    } else {
                        this.$WarningAlert(response.data.resultMessage);
                    }
                });
        },
    },
};
</script>

<style lang="scss">
.tableSize {
    font-size: 12px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
