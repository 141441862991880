<template>
<div>
    <trademark-search-form :getTrademarkSearchList="getTrademarkSearchList" ref="trademarkSearchFormModal"></trademark-search-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="trademark-search-record-no-input">{{ $t('trademarkSearch.record_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="recordNo" v-on:keyup.enter="getTrademarkSearchList()" :placeholder="$t('trademarkSearch.record_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="trademark-search-agent-input">{{ $t('trademarkSearch.agent') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="agent" v-on:keyup.enter="getTrademarkSearchList()" :placeholder="$t('trademarkSearch.agent')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="trademark-search-agent-ref-no-input">{{ $t('trademarkSearch.agent_ref_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="agentReferenceNo" v-on:keyup.enter="getTrademarkSearchList()" :placeholder="$t('trademarkSearch.agent_ref_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="trademark-search-name-input">{{ $t('trademarkSearch.name') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="name" v-on:keyup.enter="getTrademarkSearchList()" :placeholder="$t('trademarkSearch.name')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-4 mt-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getTrademarkSearchList()" @click="getTrademarkSearchList()">{{ $t('domain.list') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('trademarkSearch.trademark_search_add') }}</b-button>
                    <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1 mt-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-3">
                                <label for="trademark-search-client-critical-date-start-input">{{ $t('trademarkSearch.client_critical_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkSearch.client_critical_date_start')" size="sm" v-model="clientCriticalDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-search-client-critical-date-end-input">{{ $t('trademarkSearch.client_critical_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkSearch.client_critical_date_end')" size="sm" v-model="clientCriticalDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-search-critical-date-start-input">{{ $t('trademarkSearch.critical_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkSearch.critical_date_start')" size="sm" v-model="criticalDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-search-critical-date-end-input">{{ $t('trademarkSearch.critical_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkSearch.critical_date_end')" size="sm" v-model="criticalDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="trademark-search-instruction-date-start-input">{{ $t('trademarkSearch.instruction_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkSearch.instruction_date_start')" size="sm" v-model="instructionDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-search-instruction-date-end-input">{{ $t('trademarkSearch.instruction_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkSearch.instruction_date_end')" size="sm" v-model="instructionDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-search-application-type-input">{{ $t('trademarkSearch.application_type') }}</label>
                                <b-form-select v-model="trademarkApplicationTypeSelect" v-on:keyup.enter="getTrademarkSearchList()" size="sm" value-field="id" text-field="name" :options="trademarkApplicationTypeOption" />
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-search-type-input">{{ $t('trademarkSearch.type') }}</label>
                                <b-form-select v-model="trademarkSearchTypeSelect" v-on:keyup.enter="getTrademarkSearchList()" size="sm" value-field="id" text-field="name" :options="trademarkSearchTypeOption" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="trademark-search-registration-chance-input">{{ $t('trademarkSearch.registration_chance') }}</label>
                                <b-form-select v-model="trademarkSearchRegistrationChanceSelect" v-on:keyup.enter="getTrademarkSearchList()" size="sm" value-field="id" text-field="name" :options="trademarkSearchRegistrationChanceOption" />
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-search-use-chance-input">{{ $t('trademarkSearch.use_chance') }}</label>
                                <b-form-select v-model="trademarkSearchUseChanceSelect" v-on:keyup.enter="getTrademarkSearchList()" size="sm" value-field="id" text-field="name" :options="trademarkSearchUseChanceOption" />
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-search-goods-input">{{ $t('trademarkSearch.goods') }}</label>
                                <b-form-input size="sm" v-model="goods" v-on:keyup.enter="getTrademarkSearchList()" :placeholder="$t('trademarkSearch.goods')" type="text" class="d-inline-block" />
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="trademarkSearchTable" ref="trademarkSearchTable" :columns="columns" :rows="rows" :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }" styleClass="vgt-table condensed tableSize" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'logo'" class="text-nowrap">
                {{ $t('trademarkSearch.logo') }}
            </span>
            <span v-else-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('trademarkSearch.record_no') }}
            </span>
            <span v-else-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('trademarkSearch.name') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('trademarkSearch.agent') }}
            </span>
            <span v-else-if="props.column.field === 'trademarkSearchOwnerList'" class="text-nowrap">
                {{ $t('trademarkSearch.owner') }}
            </span>
            <span v-else-if="props.column.field === 'trademarkSearchType'" class="text-nowrap">
                {{ $t('trademarkSearch.trademark_search_type') }}
            </span>
            <span v-else-if="props.column.field === 'history'" class="text-nowrap">
                {{ $t('trademarkSearch.history') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'logo'" class="text-nowrap">
                <b-row>
                    <b-col v-if="props.row.logo != null" class="font-size: x-small">
                        <b-img>{{ 'data:image/jpeg;base64,'.concat(props.row.logo) }}</b-img>
                        <b-img :src="'data:image/jpeg;base64,'.concat(props.row.logo)" fluid alt="Responsive image" />
                    </b-col>
                </b-row>
            </span>
            <!-- Column: Agent Name -->
            <span v-else-if="props.column.field === 'recordNo'" class="text-nowrap">
                <b-row>
                    <b-col class="font-size: x-small" :class="rowClass(props.row)">
                        <span>{{ props.row.recordNo }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.agent }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ props.row.clientAccounting }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'trademarkSearchOwnerList'">
                <b-col>
                    <b-row v-for="owner in props.row.trademarkSearchOwnerList" v-bind:data="owner" v-bind:key="owner.clientName" style="font-size: small;">
                        <tr v-if="props.row.trademarkSearchOwnerList.length != 1">
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                        <tr v-else-if="props.row.trademarkSearchOwnerList.length == 1">
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'trademarkSearchHistoryStatus'">
                <b-col>
                    {{ (props.row.trademarkSearchHistoryStatus != null)? props.row.trademarkSearchHistoryStatus.trademarkSearchStatus:"" }}
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <!-- ,'100', '1000'
                    Bu alanları kaldıran : Nevzat PEKER 11/07/2023 -->
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getTrademarkSearchList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    watch: {
        clientCriticalDateStart(newValue) {
            if (newValue == '') {
                this.clientCriticalDateStart = null
            }
        },
        clientCriticalDateEnd(newValue) {
            if (newValue == '') {
                this.clientCriticalDateEnd = null
            }
        },
        criticalDateStart(newValue) {
            if (newValue == '') {
                this.criticalDateStart = null
            }
        },
        criticalDateEnd(newValue) {
            if (newValue == '') {
                this.criticalDateEnd = null
            }
        },
        instructionDateStart(newValue) {
            if (newValue == '') {
                this.instructionDateStart = null
            }
        },
        instructionDateEnd(newValue) {
            if (newValue == '') {
                this.instructionDateEnd = null
            }
        },
    },
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            trademarkApplicationTypeSelect: 0,
            trademarkApplicationTypeOption: [],
            trademarkSearchUseChanceSelect: 0,
            trademarkSearchUseChanceOption: [],
            trademarkSearchRegistrationChanceSelect: 0,
            trademarkSearchRegistrationChanceOption: [],
            trademarkSearchTypeSelect: 0,
            trademarkSearchTypeOption: [],
            clientCriticalDateStart: null,
            clientCriticalDateEnd: null,
            criticalDateStart: null,
            criticalDateEnd: null,
            instructionDateStart: null,
            instructionDateEnd: null,
            columns: [{
                    label: this.$t('trademarkSearch.logo'),
                    field: `logo`,
                },
                {
                    label: this.$t('trademarkSearch.name'),
                    field: `name`,
                },
                {
                    label: this.$t('trademarkSearch.record_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('trademarkSearch.agent'),
                    field: `agent`,
                },
                {
                    label: this.$t('trademarkSearch.nice_class'),
                    field: `niceClass`,
                },
                {
                    label: this.$t('trademarkSearch.owner'),
                    field: `trademarkSearchOwnerList`,
                },
                {
                    label: this.$t('trademarkSearch.trademark_search_type'),
                    field: `trademarkSearchType`,
                },
                {
                    label: this.$t('trademarkSearch.instruction_date'),
                    field: `instructionDate`,
                    formatFn: this.$formatFn

                },
                {
                    label: this.$t('trademarkSearch.client_critical_date'),
                    field: `clientCriticalDate`,
                    formatFn: this.$formatFn

                },
                {
                    label: this.$t('trademarkSearch.critical_date'),
                    field: `criticalDate`,
                    formatFn: this.$formatFn

                },
                
                {
                    label: this.$t('trademarkSearch.history'),
                    field: `trademarkSearchHistoryStatus`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            agent: '',
            recordNo: '',
            name: '',
            agentReferenceNo: '',
            goods: '',
        }
    },
    computed: {
        rowClass() {
            return (row) => ({
                'urgent-row': row.isUrgent,
            });
        },
    },
    mounted() {
        this.getTrademarkSearchList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'TrademarkSearch'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getTrademarkSearchList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getTrademarkSearchList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.trademarkSearchFormModal.showInfo(id)
        },
        getTrademarkSearchList() {
            this.rows = []
            const controller = {
                name: 'TrademarkSearch'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                trademarkSearchTypeId: this.trademarkSearchTypeSelect,
                registrationChanceId: this.trademarkSearchRegistrationChanceSelect,
                useChanceId: this.trademarkSearchUseChanceSelect,
                trademarkApplicationTypeId: this.trademarkApplicationTypeSelect,
                clientCriticalDateStart: this.clientCriticalDateStart,
                clientCriticalDateEnd: this.clientCriticalDateEnd,
                instructionDateStart: this.instructionDateStart,
                instructionDateEnd: this.instructionDateEnd,
                goods: this.goods,
                criticalDateStart: this.criticalDateStart,
                criticalDateEnd: this.criticalDateEnd,
                name: this.name,
                agentReferenceNo: this.agentReferenceNo,
                agent: this.agent,
                recordNo: this.recordNo
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.trademarkSearchTypeSelect = this.trademarkSearchTypeSelect
                    this.trademarkSearchTypeOption = response.data.trademarkSearchTypeList
                    this.trademarkSearchRegistrationChanceSelect = this.trademarkSearchRegistrationChanceSelect
                    this.trademarkSearchRegistrationChanceOption = response.data.trademarkSearchRegistrationChanceRateList
                    this.trademarkSearchUseChanceOption = response.data.trademarkSearchUseChanceRateList
                    this.trademarkApplicationTypeSelect = this.trademarkApplicationTypeSelect
                    this.trademarkApplicationTypeOption = response.data.trademarkApplicationTypeList
                    this.trademarkSearchTypeOption.splice(0, 0, this.$nullSelected())
                    this.trademarkSearchRegistrationChanceOption.splice(0, 0, this.$nullSelected())
                    this.trademarkSearchUseChanceOption.splice(0, 0, this.$nullSelected())
                    this.trademarkApplicationTypeOption.splice(0, 0, this.$nullSelected())
                    this.total = response.data.total
                    response.data.trademarkSearchList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {

            var controller = {
                name: 'TrademarkSearch'
            }
            var data = {
                pageLength: 5000,
                pageNo: 1,
                trademarkSearchTypeId: this.trademarkSearchTypeSelect,
                registrationChanceId: this.trademarkSearchRegistrationChanceSelect,
                useChanceId: this.trademarkSearchUseChanceSelect,
                trademarkApplicationTypeId: this.trademarkApplicationTypeSelect,
                clientCriticalDateStart: this.clientCriticalDateStart,
                clientCriticalDateEnd: this.clientCriticalDateEnd,
                instructionDateStart: this.instructionDateStart,
                instructionDateEnd: this.instructionDateEnd,
                goods: this.goods,
                criticalDateStart: this.criticalDateStart,
                criticalDateEnd: this.criticalDateEnd,
                name: this.name,
                agentReferenceNo: this.agentReferenceNo,
                agent: this.agent,
                recordNo: this.recordNo,
                isShowLogo: false
            }
            var payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    controller = {
                        name: 'TrademarkSearch',
                        actionName: 'CanExport'
                    }
                    data = {
                        columnList: this.columns,
                        DataList: response.data.trademarkSearchList
                    }
                    payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$downloadFile("Marka Araştırma", response.data.base64, 'xlsx')
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.featherIconReSize {
    width: 12px;
    height: 12px;
}

.urgent-row {
    background-color: red;
    /* İstediğiniz rengi seçebilirsiniz. */
    color: white;
    /* Metin rengini istediğiniz gibi ayarlayabilirsiniz. */
}

[dir] .urgent-row {
    background-color: #9c5e5e;
    max-width: 10em;
}
</style>
