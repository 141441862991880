var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"mailAccountFormModal",attrs:{"id":"mailAccountFormModal","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('mailAccount.mail_account_info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"mail-address-input"}},[_vm._v(_vm._s(_vm.$t('mailAccount.mail_address_name')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('mailAccount.mail_address_name'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"mail-address-input","maxlength":"50","state":errors.length > 0 ? false:null},model:{value:(_vm.mailAddress),callback:function ($$v) {_vm.mailAddress=$$v},expression:"mailAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"display-name-input"}},[_vm._v(_vm._s(_vm.$t('mailAccount.display_name')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('mailAccount.display_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"display-name-input","maxlength":"50","state":errors.length > 0 ? false:null},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":"mail-smtp-input"}},[_vm._v(_vm._s(_vm.$t('mailAccount.mail_smtp_name')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('mailAccount.mail_smtp_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"mail-smtp-input","maxlength":"50","state":errors.length > 0 ? false:null},model:{value:(_vm.mailSMTP),callback:function ($$v) {_vm.mailSMTP=$$v},expression:"mailSMTP"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('label',{attrs:{"for":"mail-port-input"}},[_vm._v(_vm._s(_vm.$t('mailAccount.port_name')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('mailAccount.port_name'),"rules":"required|between:1,1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"mail-port-input","type":"number","maxlength":"1000","state":errors.length > 0 ? false:null},model:{value:(_vm.mailPort),callback:function ($$v) {_vm.mailPort=$$v},expression:"mailPort"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('label',{attrs:{"for":"mail-pass-input"}},[_vm._v(_vm._s(_vm.$t('mailAccount.password')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('mailAccount.password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"size":"sm","id":"mail-pass-input","maxlength":"50","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null},model:{value:(_vm.mailPass),callback:function ($$v) {_vm.mailPass=$$v},expression:"mailPass"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",staticStyle:{"height":"0.5em"},attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }