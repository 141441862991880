<template>
<div>
    <!-- modal -->
    <b-modal id="paymentFormModal" centered size="xl" no-close-on-backdrop ref="paymentFormModal" :title=" $t('payment.info')" hide-footer>
        <b-form-group :disabled="isTransfer == true">
            <validation-observer ref="simpleRules">
                <div class="custom-search d-flex justify-content-end mb-1">
                    <b-row>
                        <b-col>
                            <b-button variant="outline-primary" size="sm" @click.prevent="addOrUpdate">
                                {{ $t('others.ok_title') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
                <b-row>
                    <b-col class="col-lg-6">
                        <b-form>
                            <validation-provider :name="$t('payment.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                <b-form-group :label="$t('payment.agent')" label-for="payment-agent-input" :state="errors.length > 0 ? false : null">
                                    <v-select size="sm" id="payment-agent-input" v-model="agentSelect" :options="agentOption" @input="setClientId" label="clientName" @search="onSearch">
                                    </v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-3">
                        <label for="payment-currency-input">{{ $t('payment.currency') }}</label>
                        <validation-provider :name="$t('payment.currency')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="currencySelect" value-field="id" text-field="name" :options="currencyOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col class="col-lg-3">
                        <label for="payment-jurisdiction-input">{{ $t('payment.jurisdiction') }}</label>
                        <validation-provider :name="$t('payment.jurisdiction')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="jurisdictionSelect" value-field="id" text-field="name" :options="jurisdictionOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-3">
                        <b-form-group>
                            <label for="payment-date-input">{{ $t('payment.date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-3">
                        <label for="payment-document-type-input">{{ $t('payment.document_type') }}</label>
                        <validation-provider :name="$t('payment.document_type')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select size="sm" v-model="documentTypeSelect" value-field="id" text-field="name" :options="documentTypeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col class="col-lg-3">
                        <b-form-group>
                            <label for="payment-document-date-input">{{ $t('payment.document_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="documentDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-3">
                        <label for="payment-document-no-input">{{ $t('payment.document_no') }}</label>
                        <validation-provider #default="{ errors }" :name=" $t('payment.document_no')" rules="required">
                            <b-form-input id="payment-document-no-input" v-model="documentNo" size="sm" maxlength="100" />                            
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col :hidden="documentTypeSelect != 4" class="col-lg-3">
                        <b-form-group>
                            <label for="payment-instruction-date-input">{{ $t('payment.instruction_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="instructionDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col :hidden="documentTypeSelect != 4" class="col-lg-3">
                        <b-form-group>
                            <label for="payment-payment-date-input">{{ $t('payment.payment_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="paymentDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col :hidden="documentTypeSelect != 4" class="col-lg-3">
                        <label for="payment-correspondent-bank-input">{{ $t('payment.correspondent_bank') }}</label>
                        <b-form-input id="payment-correspondent-bank-input" v-model="correspondentBank" size="sm" maxlength="100" />
                    </b-col>
                    <b-col :hidden="documentTypeSelect != 4" class="col-lg-3">
                        <label for="payment-payment-status-input">{{ $t('payment.payment_status') }}</label>
                        <b-form-checkbox class="custom-control-success" name="payment-payment-status-input" id="payment-payment-status-input" v-model="paymentStatus" switch>
                            <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                            </span>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col :hidden="documentTypeSelect == 4" class="col-lg-4">
                        <label for="payment-sender-name-input">{{ $t('payment.sender_name') }}</label>
                        <b-form-input id="payment-sender-name-input" v-model="senderName" size="sm" maxlength="100" />
                    </b-col>
                    <b-col :hidden="documentTypeSelect == 4" class="col-lg-4">
                        <validation-provider :name="$t('payment.bank')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <label for="payment-bank-input">{{ $t('payment.bank') }}</label>
                            <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="bankSelect" value-field="id" text-field="name" :options="bankOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col :hidden="documentTypeSelect == 4" class="col-lg-4">
                        <label for="payment-receipt-no-input">{{ $t('payment.receipt_no') }}</label>
                        <b-form-input id="payment-receipt-no-input" v-model="receiptNo" size="sm" maxlength="100" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <label for="payment-note-input">{{ $t('payment.note') }}</label>
                        <b-form-textarea id="payment-note-input" :placeholder=" $t('payment.note')" rows="3" v-model="note" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-3 mt-1 mb-1">
                        <label for="payment-invoce-total-input">{{ $t('payment.invoce_total') }}</label>
                        <b-form-input disabled id="payment-invoce-total-input" v-model="invoiceTotal" size="sm" maxlength="100" />
                    </b-col>
                    <b-col class="col-lg-3 mt-1 mb-1">
                        <label for="payment-amount-input">{{ $t('payment.amount') }}</label>
                        <!-- <validation-provider :name="$t('payment.amount')" :rules="{ excluded: 0 }" v-slot="{ errors }">
              <cleave id="payment-amount-input" v-model="amount" class="cleaveStyle form-control" :options="cleaveOptions" :state="errors.length > 0 ? false : null"></cleave>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->

                        <validation-provider :name="$t('payment.amount')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <cleave id="payment-amount-input" v-model="amount" class="cleaveStyle form-control" :options="cleaveOptions" :state="errors.length > 0 ? false : null"></cleave>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col class="col-lg-3 mt-1 mb-1">
                        <label for="payment-cost-input">{{ $t('payment.cost') }}</label>
                        <cleave id="payment-cost-input" v-model="cost" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-col>
                    <b-col :hidden="documentTypeSelect == 4" class="col-lg-3 mt-1 mb-1">
                        <label for="payment-commission-input">{{ $t('payment.commission') }}</label>
                        <cleave id="payment-commission-input" v-model="commission" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-col>
                </b-row>
                <b-card :hidden="hiddenControl">
                    <b-row>
                        <b-col class="mt-2">
                            <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                                <b-tab active :title=" $t('payment.invoices')" style="width:100%;">
                                    <b-card-text>
                                        <payment-detail-list v-model="invoiceTotal" ref="paymentDetailList" :getPaymentList="getPaymentList"></payment-detail-list>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-col>
                    </b-row>
                </b-card>
            </validation-observer>
        </b-form-group>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getPaymentList: {
            type: Function
        },
    },
    watch: {
        agentSelect(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.clientName = newValue.clientName
                this.clientAccountingCode = newValue.clientAccountingCode
                var clientId = newValue.clientId
                if (this.id == null && this.id == undefined) {
                    this.id = 0
                }
            } 
            // Eğer yeni ekleme işlemi yapılıyorsa (id 0 veya null ise), senderName'i güncelle
            if (!this.id || this.id === 0) {
                this.senderName = newValue.clientName; //agentSelect'in clientName'ini senderName'e ata
                var senderNameSplit = this.senderName.split("|", 1);
                this.senderName = senderNameSplit[0]
            }
        },
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            clientAccountingId: 0,
            clientAccountingCode: 0,
            clientName: '',
            agentSelect: 0,
            agentOption: [],
            documentNo: '',
            documentTypeSelect: 0,
            documentTypeOption: [],
            date: null,
            documentDate: null,
            instructionDate: null,
            paymentDate: null,
            receiptNo: '',
            paymentStatus: false,
            senderName: '',
            bankSelect: 0,
            bankOption: [],
            correspondentBank: '',
            note: '',
            amount: '',
            cost: '',
            commission: '',
            currencySelect: 0,
            currencyOption: [],
            hiddenControl: true,
            jurisdictionSelect: 0,
            jurisdictionOption: [],
            invoiceTotal: 0,
            isTransfer: false,
            cleaveOptions: {
                prefix: '',
                numeral: true,
                numeralPositiveOnly: true,
                noImmediatePrefix: true,
                rawValueTrimPrefix: true,
                numeralIntegerScale: 9,
                numeralDecimalScale: 2
            },
        }
    },
    methods: {
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
                this.currencySelect = event.currencyId
                this.clientAccountingId = event.clientAccountingId
            } else {
                this.clientId = null;
                this.currencySelect = 0;
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.agentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        showInfo(id) {
            this.$refs['paymentFormModal'].show()
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Payment'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    if (response.data.payment.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.payment.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.agentOption = clientAccountingResponse.data.clientList
                            this.agentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.agentOption = []
                        this.agentSelect = 0;
                    }
                    //Closed For STOCK-1224
                    // if (this.id == 0) {
                    //     this.documentTypeSelect = 0
                    // } else {
                    //     this.documentTypeSelect = response.data.payment.paymentMethodTypeId
                    // }
                    this.documentTypeSelect = response.data.payment.paymentMethodTypeId
                    this.date = response.data.payment.date
                    this.documentTypeOption = response.data.paymentMethodTypeList
                    this.documentDate = response.data.payment.documentDate
                    this.documentNo = response.data.payment.documentNo
                    this.instructionDate = response.data.payment.instructionDate
                    this.paymentDate = response.data.payment.paymentDate
                    this.receiptNo = response.data.payment.receiptNo
                    this.note = response.data.payment.note
                    this.senderName = response.data.payment.senderName
                    this.bankSelect = response.data.payment.bankId
                    this.bankOption = response.data.bankList
                    this.correspondentBank = response.data.payment.correspondentBank
                    this.paymentStatus = response.data.payment.isPaid
                    this.amount = response.data.payment.total
                    this.cost = response.data.payment.cost
                    this.commission = response.data.payment.commission
                    this.correspondentBank = response.data.payment.clientBank
                    this.currencySelect = response.data.payment.currencyId
                    this.currencyOption = response.data.currencyList
                    this.isTransfer = response.data.payment.isTransfer
                    // if (this.jurisdictionSelect == 0) {
                    //     this.jurisdictionSelect = 1
                    // } else {
                    //     this.jurisdictionSelect = response.data.payment.jurisdictionId
                    // }
                    this.jurisdictionSelect = response.data.payment.jurisdictionId
                    this.jurisdictionOption = response.data.jurisdictionList
                    this.documentTypeOption.splice(0, 0, this.$nullSelected())
                    this.bankOption.splice(0, 0, this.$nullSelected())
                    this.currencyOption.splice(0, 0, this.$nullSelected())
                    this.jurisdictionOption.splice(0, 0, this.$nullSelected())
                    this.clientAccountingId = response.data.payment.clientAccountingId
                    this.$refs.paymentDetailList.getPaymentDetailList(this.id, this.clientAccountingId)
                } else {
                    this.$bvModal.hide('paymentFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                clientAccountingId: this.agentSelect.clientAccountingId,
                date: this.date,
                paymentMethodTypeId: this.documentTypeSelect,
                documentDate: this.documentDate,
                documentNo: this.documentNo,
                instructionDate: this.instructionDate,
                paymentDate: this.paymentDate,
                receiptNo: this.receiptNo,
                note: this.note,
                senderName: this.agentSelect.clientName,
                bankId: this.bankSelect,
                isPaid: this.paymentStatus,
                total: this.amount,
                cost: this.cost,
                commission: this.commission,
                currencyId: this.currencySelect,
                jurisdictionId: this.jurisdictionSelect,
                clientBank: this.correspondentBank
            }
            const controller = {
                name: 'Payment'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getPaymentList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.id
                                this.hiddenControl = false
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                        this.$refs.paymentDetailList.getPaymentDetailList(this.id, this.clientAccountingId)
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.cleaveStyle {
    max-height: 3em !important;
    height: 2.2em !important;
}
</style>
