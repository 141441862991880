<template>
    <div>
      <!-- table -->
      <vue-good-table 
      id="clientHistoryTable"
      ref="clientHistoryTable" 
      :columns="columns" 
      :rows="rows" 
      styleClass="vgt-table condensed tableSize"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
        theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'date'" class="text-nowrap">
            {{ $t('clientHistory.history_date') }}
          </span>
          <span v-else-if="props.column.field === 'historyType'" class="text-nowrap">
            {{ $t('clientHistory.history_type') }}
          </span>
          <span v-else-if="props.column.field === 'explanation'" class="text-nowrap">
            {{ $t('clientHistory.company_old_name') }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
    //eslint-disable
  export default {
    props: {
      clientId: Number
    },
    data() {
      return {
        pageLength: 10,
        dir: false,
        columns: [
          {
            label: this.$t('clientHistory.history_date'),
            field: `date`,
            formatFn: this.formatFn
          },
          {
            label: this.$t('clientHistory.history_type'),
            field: `historyType`,
          },
          {
            label: this.$t('clientHistory.company_old_name'),
            field: `explanation`,
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      this.getClientHistoryList()
    },
    methods: {
      formatFn(event){
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' }
        const dateFormatted = new Date(event)
        return dateFormatted.toLocaleDateString("tr-TR", options)
      },
      getClientHistoryList() {
        this.rows = []
        const data = { id: this.clientId }
        const controller = { name: 'ClientHistory', actionName:'GetClientHistoryListByClientId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            response.data.clientHistoryList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
    },
  }
  </script>
  <style lang="scss" >
    .tableSize {
      font-size: 13px!important;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      table-layout: auto;
  }
  </style>
  