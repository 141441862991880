<template>
    <div>
      <!-- modal -->
      <b-modal
        id="openJobListFormModal" centered size="xl" no-close-on-backdrop ref="openJobListFormModal" :title=" $t('etebs.upload_from_etebs')" @close="getEtebsList()" hide-footer >
        <job-list :getEtebsList="getEtebsList" :moduleId="moduleId" :recordId="recordId" :documentId="documentId"></job-list>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
        getEtebsList: {type: Function},
    },
    data() {
      return {
        id: 0,
        moduleId: 0,
        recordId: 0,
        documentId: 0,
      }
    },
    methods: {
      showInfo(moduleId, recordId, documentId) { 
        this.$refs['openJobListFormModal'].show() 
        this.moduleId = moduleId
        this.recordId = recordId
        this.documentId = documentId
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  