<template>
<div>
    <!-- modal -->
    <b-modal id="designRenewalFormModal" centered size="lg" no-close-on-backdrop ref="designRenewalFormModal" :title=" $t('designRenewal.renewal_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @close="clear()" @ok.prevent="addOrUpdate">
        <validation-observer ref="infoRules" tag="form">
            <b-row>
                <b-col class="col-md-12 mt-1">
                    <b-form>
                        <label for="designPriority-No-input">{{ $t('designPriority.no') }}</label>
                        <b-form-group>
                            <b-form-input id="designPriority-no-input" v-model="no" disabled maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-6 mt-1">
                        <label for="design-date-input">{{ $t('designRenewal.date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="1" v-model="date"></CustomDatePicker>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-6 mt-1">
                    <label for="design-renewal-reminder-date-input">{{ $t('designRenewal.reminder_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="reminderDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-md-6 mt-1">
                    <label for="design-renewal-payment-date-input">{{ $t('designRenewal.payment_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="paymentDate"></CustomDatePicker>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDesignRenewalList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            desginId:0,
            reminderDate: null,
            paymentDate: null,
            date: null,
            no: '',
        }
    },
    methods: {
        clear() {
            this.reminderDate = null,
                this.paymentDate = null,
                this.date = null
        },
        showInfo(id, desginId) {
            this.$refs['designRenewalFormModal'].show()
            this.id= id
            this.desginId= desginId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'DesignRenewal'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                this.reminderDate = response.data.designRenewal.reminderDate,
                    this.paymentDate = response.data.designRenewal.paymentDate,
                    this.date = response.data.designRenewal.date,
                    this.no = response.data.designRenewal.no
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                designId: this.desginId,
                reminderDate: this.reminderDate,
                paymentDate: this.paymentDate,
                date: this.date
            }
            const controller = {
                name: 'DesignRenewal'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('designRenewalFormModal')
                            this.reminderDate = null
                            this.paymentDate = null
                            this.date = null
                            this.getDesignRenewalList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        }

    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
