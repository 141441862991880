<template>
    <div>
      <!-- modal -->
      <b-modal id="trademarkDetailFormModal" centered size="lg" no-close-on-backdrop ref="trademarkDetailFormModal" :title=" $t('trademarkDetail.info')" hide-footer>
        <table class="styled-table">
          <tbody v-for="(selectedElementsValue, selectedElementsKey) in selectedElements" :key="selectedElementsKey">
            <tr v-for="(value, key) in selectedElementsValue" :key="key" v-if="key !== 'clientAccountingId'">
              <td>{{ $t(`trademarkDetail.${key}`) }}</td>
              <td>
                <!-- Resim gösterme kısmı -->
                <img v-if="key === 'image'" :src="value" alt="Trademark Logo" style="max-width: 100px; max-height: 100px;" />
                <!-- Değilse diğer verileri normal olarak göster -->
                <span v-else>{{ value }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        id: 0,
        clientAccountingId: 0,
        trademark: '',
        image: null,
        trademarkOwner: '',
        applicationNumber: '',
        applicationDate: null,
        registrationNo: '',
        registrationDate: null,
        classes: '',
        nextRenewalDate: null,
        status: '',
        stockReference: '',
        selectedElements : []
      }
    },
    methods: {
      async showInfo(trademarkDetailObject) {  
        this.selectedElements = []
        this.rows = [] 
        const data = { id: trademarkDetailObject[0].id }
        const controller = { name: 'TrademarkOwner', actionName:'GetTrademarkOwnerListByTrademarkId' }
        const payload = { data: data, controller: controller }
        await this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            response.data.trademarkOwnerList.forEach(element => {
              this.rows.push(element)
            })
          }
        })

        this.$refs['trademarkDetailFormModal'].show()
        trademarkDetailObject.forEach(element => {
            if (element != '') { 
                this.selectedElements.push({
                    clientAccountingId: element.clientAccountingId,
                    trademark: element.name,
                    image: 'data:image/jpeg;base64,'.concat(element.logo),
                    trademarkOwner: this.rows.map(u => u.clientName.split("|")[0]).join(),
                    applicationNumber: element.applicationNo,
                    applicationDate: this.$formatFn(element.applicationDate),
                    registrationNo: element.registerNo,
                    registrationDate: this.$formatFn(element.registerDate),
                    classes: element.niceClass,
                    nextRenewalDate: this.$formatFn(element.nextRenewalDate),
                    status: element.trademarkStatus,
                    stockReference: element.recordNo
                });
            } else this.selectedElements = []
        });
      },
    }
  }                   
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .styled-table {
    width: 100%;
    table-layout: fixed; 
  }
  
  .styled-table td, .styled-table th {
    word-wrap: break-word; 
    padding: 0.5rem;
    text-align: left;
  }
  </style>
  