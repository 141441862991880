<template>
<div>
    <national-trademark-form :getNationalTrademarkList="getNationalTrademarkList" ref="nationalTrademarkFormModal"></national-trademark-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="national-trademark-national-trademark-folder-no-input">{{ $t('nationalTrademark.national_trademark_folder_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="recordNo" v-on:keyup.enter="getNationalTrademarkList()" :placeholder="$t('nationalTrademark.national_trademark_folder_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="national-trademark-agent-name-input">{{ $t('nationalTrademark.agent_name') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="agent" v-on:keyup.enter="getNationalTrademarkList()" :placeholder="$t('nationalTrademark.agent_name')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="national-trademark-application-no-input">{{ $t('nationalTrademark.application_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="applicationNo" v-on:keyup.enter="getNationalTrademarkList()" :placeholder="$t('nationalTrademark.application_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="national-trademark-name-input">{{ $t('nationalTrademark.name') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="name" v-on:keyup.enter="getNationalTrademarkList()" :placeholder="$t('nationalTrademark.name')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-4  mt-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getNationalTrademarkList()" @click="getNationalTrademarkList()">{{ $t('nationalTrademark.list') }}</b-button>
                    <b-button class="d-inline-block mr-05" size="sm" variant="outline-primary" @click="getInfo()">{{ $t('nationalTrademark.national_trademark_add') }}</b-button>
                    <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1 mt-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="national-trademark-name-by-client-input">{{ $t('nationalTrademark.name_by_client') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input id="national-trademark-name-by-client-input" size="sm" v-model="nameByClient" v-on:keyup.enter="getNationalTrademarkList()" :placeholder="$t('nationalTrademark.name_by_client')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="national-trademark-application-date-start-input">{{ $t('nationalTrademark.application_date_start') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" id="national-trademark-application-date-start-input" :placeholder="$t('nationalTrademark.application_date_start')" size="sm" v-model="applicationDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="national-trademark-application-date-end-input">{{ $t('nationalTrademark.application_date_end') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" id="national-trademark-application-date-end-input" :placeholder="$t('nationalTrademark.application_date_end')" size="sm" v-model="applicationDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="national-trademark-instruction-date-start-input">{{ $t('nationalTrademark.instruction_date_start') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" id="national-trademark-instruction-date-start-input" :placeholder="$t('nationalTrademark.instruction_date_start')" size="sm" v-model="instructionDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="national-trademark-instruction-date-end-input">{{ $t('nationalTrademark.instruction_date_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" id="national-trademark-instruction-date-end-input" :placeholder="$t('nationalTrademark.instruction_date_end')" size="sm" v-model="instructionDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3">
                                <label for="national-trademark-bulletin-date-start-input">{{ $t('nationalTrademark.bulletin_date_start') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" id="national-trademark-bulletin-date-start-input" :placeholder="$t('nationalTrademark.bulletin_date_start')" size="sm" v-model="bulletinDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3">
                                <label for="national-trademark-bulletin-date-end-input">{{ $t('nationalTrademark.bulletin_date_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" id="national-trademark-bulletin-date-end-input" :placeholder="$t('nationalTrademark.bulletin_date_end')" size="sm" v-model="bulletinDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3">
                                <label for="national-trademark-trademark-application-type-input">{{ $t('nationalTrademark.trademark_type') }}</label>
                                <b-form-select id="national-trademark-trademark-application-type-input" v-model="trademarkApplicationTypeSelect" @change="getNationalTrademarkList()" v-on:keyup.enter="getNationalTrademarkList()" size="sm" value-field="id" text-field="name" :options="trademarkApplicationTypeOption" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3 mt-1">
                                <label for="national-trademark-application-type-input">{{ $t('nationalTrademark.national_trademark_application_type') }}</label>
                                <b-form-select id="national-trademark-application-type-input" v-model="nationalTrademarkApplicationTypeSelect" @change="getNationalTrademarkList()" v-on:keyup.enter="getNationalTrademarkList()" size="sm" value-field="id" text-field="name" :options="nationalTrademarkApplicationTypeOption" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="national-trademark-bulletin-no-input">{{ $t('nationalTrademark.bulletin_no') }}</label>
                                <b-form-input id="national-trademark-bulletin-no-input" size="sm" v-model="bulletinNo" v-on:keyup.enter="getNationalTrademarkList()" :placeholder="$t('nationalTrademark.bulletin_no')" type="text" class="d-inline-block" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="national-trademark-owner-input">{{ $t('nationalTrademark.owner') }}</label>
                                <b-form-input id="national-trademark-owner-input" size="sm" v-model="owner" v-on:keyup.enter="getNationalTrademarkList()" :placeholder="$t('nationalTrademark.owner')" type="text" class="d-inline-block" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="national-trademark-country-input">{{ $t('nationalTrademark.country') }}</label>
                                <b-form-select id="national-trademark-country-input" v-model="countrySelect" @change="getNationalTrademarkList()" v-on:keyup.enter="getNationalTrademarkList()" size="sm" value-field="id" text-field="name" :options="countryOption" />
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="nationalTrademarkTable" ref="nationalTrademarkTable" :columns="columns" :rows="rows" :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
                }" styleClass="vgt-table condensed tableSize" :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }" :pagination-options="{
                    enabled: true,
                    perPage: pageLength
                  }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'logo'" class="text-nowrap">
                {{ $t('nationalTrademark.logo') }}
            </span>
            <span v-else-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('nationalTrademark.national_trademark_folder_no') }}
            </span>
            <span v-else-if="props.column.field === 'applicationNo'" class="text-nowrap">
                {{ $t('nationalTrademark.application_no') }}
            </span>
            <span v-else-if="props.column.field === 'applicationDate'" class="text-nowrap">
                {{ $t('nationalTrademark.application_date') }}
            </span>
            <span v-else-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('nationalTrademark.name') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('nationalTrademark.agent_name') }}
            </span>
            <span v-else-if="props.column.field === 'ownerList'" class="text-nowrap">
                {{ $t('nationalTrademark.owner') }}
            </span>
            <span v-else-if="props.column.field === 'countryList'" class="text-nowrap">
                {{ $t('nationalTrademark.country') }}
            </span>
            <span v-else-if="props.column.field === 'nationalTrademarkApplicationType'" class="text-nowrap">
                {{ $t('nationalTrademark.national_trademark_application_type') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Logo -->
            <span v-if="props.column.field === 'logo'" class="text-nowrap">
                <b-row>
                    <b-col v-if="props.row.logo != null" class="font-size: x-small">
                        <b-img>{{ 'data:image/jpeg;base64,'.concat(props.row.logo) }}</b-img>
                        <b-img :src="'data:image/jpeg;base64,'.concat(props.row.logo)" fluid alt="Responsive image" />
                    </b-col>
                </b-row>
            </span>
            <!-- Column: Agent Name -->
            <span v-else-if="props.column.field === 'agent'">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.agent }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ props.row.clientAccounting }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'countryList'">
                <b-col>
                    <b-row v-for="country in props.row.countryList" v-bind:data="country" v-bind:key="country.id" style="font-size: small;">
                        <tr v-if="props.row.countryList.length != 1">
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ country.country }} </span> <br />
                        </tr>
                        <tr v-else-if="props.row.countryList.length == 1">
                            <span>{{ country.country }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'applicationType'">
                <b-col>
                    <b-row v-for="applicationType in props.row.applicationType" v-bind:data="applicationType" v-bind:key="applicationType.name" style="font-size: small;">
                        <tr v-if="props.row.applicationType.length != 1">
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ applicationType.name }} </span> <br />
                        </tr>
                        <tr v-else-if="props.row.applicationType.length == 1">
                            <span>{{ applicationType.name }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'ownerList'">
                <b-row v-for="(owner, ownerIndex) in props.row.ownerList" :key="owner.clientName + '-' + ownerIndex" style="font-size: small;">
                    <tr v-if="props.row.ownerList.length != 1">
                        <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                        <span>{{ owner.clientName }} </span> <br />
                    </tr>
                    <tr v-else-if="props.row.ownerList.length == 1">
                        <span>{{ owner.clientName }} </span> <br />
                    </tr>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <!-- ,'100', '1000' -->
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getNationalTrademarkList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

    
<script>
export default {
    watch: {
        applicationDateStart(newValue) {
            if (newValue == '') {
                this.applicationDateStart = null
            }
        },
        applicationDateEnd(newValue) {
            if (newValue == '') {
                this.applicationDateEnd = null
            }
        },
        instructionDateStart(newValue) {
            if (newValue == '') {
                this.instructionDateStart = null
            }
        },
        instructionDateEnd(newValue) {
            if (newValue == '') {
                this.instructionDateEnd = null
            }
        },
        bulletinDateStart(newValue) {
            if (newValue == '') {
                this.bulletinDateStart = null
            }
        },
        bulletinDateEnd(newValue) {
            if (newValue == '') {
                this.bulletinDateEnd = null
            }
        },
    },
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('nationalTrademark.logo'),
                    field: `logo`,
                },
                {
                    label: this.$t('nationalTrademark.national_trademark_folder_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('nationalTrademark.application_no'),
                    field: `applicationNo`,
                },
                {
                    label: this.$t('nationalTrademark.application_date'),
                    field: `applicationDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('nationalTrademark.name'),
                    field: `name`,
                },
                {
                    label: this.$t('nationalTrademark.agent_name'),
                    field: `agent`,
                },
                {
                    label: this.$t('nationalTrademark.owner'),
                    field: `ownerList`,
                },
                {
                    label: this.$t('nationalTrademark.country'),
                    field: `countryList`,
                },
                {
                    label: this.$t('nationalTrademark.national_trademark_application_type'),
                    field: `nationalTrademarkApplicationType`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            agent: '',
            recordNo: '',
            trademarkApplicationTypeSelect: 0,
            trademarkApplicationTypeOption: [],
            nationalTrademarkApplicationTypeOption: [],
            nationalTrademarkApplicationTypeSelect: 0,
            applicationNo: '',
            applicationDateStart: null,
            applicationDateEnd: null,
            name: '',
            nameByClient: '',
            owner: '',
            instructionDateStart: null,
            instructionDateEnd: null,
            bulletinDateStart: null,
            bulletinDateEnd: null,
            bulletinNo: '',
            countrySelect: 0,
            countryOption: [],
        }
    },
    mounted() {
        this.getNationalTrademarkList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'NationalTrademark'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getNationalTrademarkList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getNationalTrademarkList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.nationalTrademarkFormModal.showInfo(id)
        },
        getNationalTrademarkList() {
            this.rows = []
            const controller = {
                name: 'NationalTrademark'
            }
            const data = {
                recordNo: this.recordNo,
                agent: this.agent,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                applicationNo: this.applicationNo,
                applicationDateStart: this.applicationDateStart,
                applicationDateEnd: this.applicationDateEnd,
                name: this.name,
                nameByClient: this.nameByClient,
                owner: this.owner,
                instructionDateStart: this.instructionDateStart,
                instructionDateEnd: this.instructionDateEnd,
                bulletinDateStart: this.bulletinDateStart,
                bulletinDateEnd: this.bulletinDateEnd,
                bulletinNo: this.bulletinNo,
                nationalTrademarkApplicationTypeId: this.nationalTrademarkApplicationTypeSelect,
                trademarkApplicationTypeId: this.trademarkApplicationTypeSelect,
                countryId: this.countrySelect
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    this.trademarkApplicationTypeOption = response.data.trademarkApplicationTypeList
                    this.nationalTrademarkApplicationTypeOption = response.data.nationalTrademarkApplicationTypeList
                    this.countryOption = response.data.countryList
                    this.trademarkApplicationTypeOption.splice(0, 0, this.$nullSelected())
                    this.nationalTrademarkApplicationTypeOption.splice(0, 0, this.$nullSelected())
                    this.countryOption.splice(0, 0, this.$nullSelected())
                    response.data.nationalTrademarkList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            var excelColumns = [{
                    label: this.$t('nationalTrademark.national_trademark_folder_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('nationalTrademark.name'),
                    field: `name`,
                },
                {
                    label: this.$t('nationalTrademark.owners'),
                    field: `ownerList`,
                },
                {
                    label: this.$t('nationalTrademark.agent_name'),
                    field: `agent`,
                },
                {
                    label: this.$t('nationalTrademark.nice_class'),
                    field: `fileNiceClass`,
                },
                {
                    label: this.$t('nationalTrademark.national_trademark_status'),
                    field: `countryList.status`,
                },
                {
                    label: this.$t('nationalTrademark.application_date'),
                    field: `applicationDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('nationalTrademark.bulletin_date'),
                    field: `bulletinDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('nationalTrademark.register_date'),
                    field: `registerDate`,
                },
                {
                    label: this.$t('nationalTrademark.register_no'),
                    field: `registerNo`,
                },
                {
                    label: this.$t('nationalTrademark.next_renewal_date'),
                    field: `nextRenewalDate`,
                },

                {
                    label: this.$t('nationalTrademark.renewal_date'),
                    field: `renewalDate`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ]

            var controller = {
                name: 'NationalTrademark'
            }
            var data = {
                recordNo: this.recordNo,
                agent: this.agent,
                pageLength: 5000,
                pageNo: 1,
                applicationNo: this.applicationNo,
                applicationDateStart: this.applicationDateStart,
                applicationDateEnd: this.applicationDateEnd,
                name: this.name,
                nameByClient: this.nameByClient,
                owner: this.owner,
                instructionDateStart: this.instructionDateStart,
                instructionDateEnd: this.instructionDateEnd,
                bulletinDateStart: this.bulletinDateStart,
                bulletinDateEnd: this.bulletinDateEnd,
                bulletinNo: this.bulletinNo,
                nationalTrademarkApplicationTypeId: this.nationalTrademarkApplicationTypeSelect,
                trademarkApplicationTypeId: this.trademarkApplicationTypeSelect,
                countryId: this.countrySelect,
                isShowLogo: false
            }
            var payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {

                    controller = {
                        name: 'NationalTrademark',
                        actionName: 'CanExport'
                    }
                    data = {
                        columnList: excelColumns,
                        DataList: response.data.nationalTrademarkList
                    }
                    payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$downloadFile("Yurtdışı Marka", response.data.base64, 'xlsx')
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

    
<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.featherIconReSize {
    width: 12px;
    height: 12px;
}

.mr-02 {
    margin-right: 1em;
}
</style>
