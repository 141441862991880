<template>
    <div>
      <!-- modal -->
      <b-modal
        id="clientNoteFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="clientNoteFormModal"
        :title=" $t('clientNote.note_add')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
      <validation-observer ref="simpleRules">
      <b-row>
        <b-col class="col-lg-12">
          <b-form>
            <validation-provider :name=" $t('clientNote.note_type')" :rules="{ excluded:0}" v-slot="{ errors }" >
              <label for="client-note-type">{{ $t('clientNote.note_type') }}</label>
                <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="noteTypeSelect" value-field="id" text-field="name" :options="noteTypeOption" />
                <small class="text-danger">{{ errors[0] }}</small>       
            </validation-provider>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <validation-provider :name=" $t('clientNote.note')" #default="{ errors }" rules="required">
            <label for="textarea-default">{{ $t('clientNote.note') }}</label>
            <b-form-textarea size="sm" :state="errors.length > 0 ? false:null" id="textarea-default" :placeholder=" $t('clientNote.note')" v-model="note" rows="10" />
            <small class="text-danger">{{ errors[0] }}</small>       
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getClientNoteList: {type: Function},
    },
    data() {
      return {
        note: '',
        noteTypeSelect: 0,
        noteTypeOption: [],
        clientId:0
      }
    },
    methods: {
      showInfo(id, clientId) {
        this.$refs['clientNoteFormModal'].show()
        this.id = id
        this.clientId = clientId;
        const data = {id: this.id}
        const controller = {name : 'ClientNote'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.noteTypeOption = response.data.noteTypeList
                this.noteTypeSelect = response.data.clientNote.noteTypeId
                this.note = response.data.clientNote.note
                this.noteTypeOption.splice(0, 0, this.$nullSelected())
            } else {
                this.$bvModal.hide('clientNoteFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, clientId: this.clientId, noteTypeList: this.noteTypeOption, noteTypeId: this.noteTypeSelect, note: this.note}
        const controller = {name : 'ClientNote'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('clientNoteFormModal')
                    this.getClientNoteList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  