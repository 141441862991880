<template>
<div>
    <!-- modal -->
    <b-modal id="wipoXmlFormModal" centered size="xl" no-close-on-backdrop ref="wipoXmlFormModal" :title="$t('wipoXml.wipo_xml_info')" :cancel-title="$t('others.cancel_title')" cancel-variant="outline-secondary" :ok-title="$t('others.ok_title')" @ok.prevent="addOrUpdate">
        <!--Patent-->

        <agent-note-form ref="agentNoteFormModal"></agent-note-form>
        <b-card :title="$t('wipoXml.patent_info')">
            <b-list-group>
                <validation-observer ref="simpleRules">
                    <b-row>
                        <b-col>
                            <b-col>
                                <b-row>
                                    <b-col class="col-md-4 mb-1">
                                        <b-list-group-item class="flex-column align-items-start">
                                            <b-row>
                                                <b-col>
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <h6>
                                                            {{ $t('wipoXml.patent_no') }}
                                                        </h6>
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <b-card-text>
                                                        {{ this.patentNo }}
                                                    </b-card-text>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-col>
                                    <b-col class="col-md-4 mb-1">
                                        <b-list-group-item class="flex-column align-items-start">
                                            <b-row>
                                                <b-col>
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <h6>
                                                            {{ $t('wipoXml.patent_ipc_code') }}
                                                        </h6>
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <b-card-text>
                                                        {{ this.ipcCode }}
                                                    </b-card-text>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-col>
                                    <b-col class="col-md-4 mb-1">
                                        <b-list-group-item class="flex-column align-items-start">
                                            <b-row>
                                                <b-col>
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <h6>
                                                            {{ $t('wipoXml.pct_application_no') }}
                                                        </h6>
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <b-card-text>
                                                        {{ this.pctApplicationNo }}
                                                    </b-card-text>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-col>
                                    </b-row>
                                    <b-row>
                                    <b-col class="col-md-4 mb-1">
                                        <b-list-group-item class="flex-column align-items-start">
                                            <b-row>
                                                <b-col>
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <h6>
                                                            {{ $t('wipoXml.pct_application_date') }}
                                                        </h6>
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <b-card-text>
                                                        {{ $formatFn(this.pctApplicationDate) }}
                                                    </b-card-text>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-col>
                                    <b-col class="col-md-4 mb-1">
                                        <b-list-group-item class="flex-column align-items-start">
                                            <b-row>
                                                <b-col>
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <h6>
                                                            {{ $t('wipoXml.pct_publication_no') }}
                                                        </h6>
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <b-card-text>
                                                        {{ this.pctPublicationNo }}
                                                    </b-card-text>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-col>
                                    <b-col class="col-md-4 mb-1">
                                        <b-list-group-item class="flex-column align-items-start">
                                            <b-row>
                                                <b-col>
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <h6>
                                                            {{ $t('wipoXml.pct_publication_date') }}
                                                        </h6>
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <b-card-text>
                                                        {{ $formatFn(this.pctPublicationDate) }}
                                                    </b-card-text>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="col-md-4 mb-1">
                                        <b-list-group-item class="flex-column align-items-start">
                                            <b-row>
                                                <b-col>
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <h6>
                                                            {{ $t('wipoXml.patent_date_of_grant') }}
                                                        </h6>
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <b-card-text>
                                                        {{ $formatFn(this.dateOfGrant) }}
                                                    </b-card-text>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-col>
                                    <b-col class="col-md-4 mb-1">
                                        <b-list-group-item class="flex-column align-items-start">
                                            <b-row>
                                                <b-col>
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <h6>
                                                            {{ $t('wipoXml.patent_is_tr_designation') }}
                                                        </h6>
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <b-form-checkbox class="custom-control-success" name="country-isTrMain-input" id="country-isTrMain-input" v-model="this.isTrDesignation" switch disabled>
                                                        <span class="switch-icon-left">
                                                            <feather-icon icon="CheckIcon" />
                                                        </span>
                                                        <span class="switch-icon-right">
                                                            <feather-icon icon="XIcon" />
                                                        </span>
                                                    </b-form-checkbox>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-col>
                                    </b-row>
                                <b-row>
                                    <b-col class="col-md-12 mb-1">
                                        <b-list-group-item class="flex-column align-items-start">
                                            <b-row>
                                                <b-col>
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <h6>
                                                            {{ $t('wipoXml.patent_title_en') }}
                                                        </h6>
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <b-card-text>
                                                        {{ this.titleEn }}
                                                    </b-card-text>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-row class="mt-2">
                                <b-col class="col-md-6 mb-1">
                                    <b-list-group-item class="flex-column align-items-start">
                                        <b-row>
                                            <b-col>
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h6>
                                                        {{ $t('wipoXml.client_name') }}
                                                    </h6>
                                                </div>
                                            </b-col>
                                            <b-col>
                                                <b-card-text>
                                                    {{ this.clientName }}
                                                </b-card-text>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>
                                </b-col>
                                <b-col class="col-md-6 mb-1">
                                    <b-list-group-item class="flex-column align-items-start" :key="patentOwnerList.id" v-for="owner in this.patentOwnerList ">
                                        <b-row>
                                            <b-col>
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h6>
                                                        {{ $t('wipoXml.owner_name') }}
                                                    </h6>
                                                </div>
                                            </b-col>
                                            <b-col>
                                                <b-card-text>
                                                    {{ owner.name }}
                                                </b-card-text>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-lg-12 mt-1">
                                    <b-form-group>
                                        <label for="patent-agent-input"> {{ $t('patent.agent') }} </label>
                                        <b-input-group>
                                            <validation-provider :name=" $t('patent.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                                <b-form-group label-for="patent-agent-input" :state="errors.length > 0 ? false:null">
                                                    <v-select class="agentInput" id="patent-agent-input" v-model="patentAgentSelect" :options="patentAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                                                    </v-select>
                                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                        {{ errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                          <b-input-group-append>
                                            <b-dropdown style="width: 4em; height: 2.5em; margin-bottom: 1em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                                <template #button-content>
                                                    <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                                </template>
                                                <b-dropdown-item @click="getClientInfo(0)">
                                                    <span>{{this.$t('others.add_client')}}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="getAgentNoteInfo(patentAgentSelect.clientAccountingId)">
                                                    <span>{{this.$t('others.show_note')}}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="getClientInfo(patentAgentSelect.clientId)">
                                                    <span>{{this.$t('others.show_agent')}}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                          </b-input-group-append>
                                        </b-input-group>
                                      </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-md-12 mt-1">
                                    <b-form>
                                        <validation-provider :name="$t('patentOwner.owner_name')" #default="{ errors }" rules="required">
                                            <b-form-group :label="$t('patentOwner.owner_name')" label-for="patentOwner" :state="errors.length > 0 ? false : null">
                                                <v-select id="patentOwner" v-model="patentOwnerSelect" :options="patentOwnerOption" label="name" @search="onSearchOwner">
                                                </v-select>
                                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                                    {{ errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-form>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </validation-observer>
            </b-list-group>
        </b-card>
        <!--Patent Division-->
        <b-card :title="$t('wipoXml.division_info')">
            <b-col class="col-md-12 mb-1">
                <vue-good-table id="departmentTable" ref="departmentTable" :columns="columns" :rows="this.patentDivisionList" styleClass="vgt-table condensed tableSize" :search-options="{
                enabled: false,
              }" :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }" :pagination-options="{
              enabled: false,
              }" theme="my-theme">
                    <div slot="emptystate">
                        {{ $t('dataTable.data_not_found') }}
                    </div>
                    <template slot="table-column" slot-scope="props">
                        <span v-if="props.column.field === 'kind'" class="text-nowrap">
                            {{ $t('wipoXml.division_kind') }}
                        </span>
                        <span v-else-if="props.column.field === 'docNumber'" class="text-nowrap">
                            {{ $t('wipoXml.division_doc_number') }}
                        </span>
                        <span v-else-if="props.column.field === 'country'" class="text-nowrap">
                            {{ $t('wipoXml.division_country') }}
                        </span>
                    </template>
                </vue-good-table>
            </b-col>
        </b-card>
        <!--Patent Inventor-->
        <b-card :title="$t('wipoXml.inventor_info')">
            <b-col class="col-md-12 mb-1">
                <vue-good-table id="departmentTable" ref="departmentTable" :columns="columnsInventor" :rows="this.patentInventorList" styleClass="vgt-table condensed tableSize" :search-options="{
                enabled: false,
              }" :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }" :pagination-options="{
              enabled: false,
              }" theme="my-theme">
                    <div slot="emptystate">
                        {{ $t('dataTable.data_not_found') }}
                    </div>
                    <template slot="table-column" slot-scope="props">
                        <span v-if="props.column.field === 'name'" class="text-nowrap">
                            {{ $t('wipoXml.inventor_name') }}
                        </span>
                        <span v-else-if="props.column.field === 'nationalityIDNumber'" class="text-nowrap">
                            {{ $t('wipoXml.inventor_nationality_id_number') }}
                        </span>
                        <span v-else-if="props.column.field === 'country'" class="text-nowrap">
                            {{ $t('wipoXml.inventor_country') }}
                        </span>
                        <span v-else-if="props.column.field === 'address'" class="text-nowrap">
                            {{ $t('wipoXml.inventor_address') }}
                        </span>
                    </template>
                </vue-good-table>
            </b-col>
        </b-card>
        <!--Patent Priority-->
        <b-card :title="$t('wipoXml.priority_info')">
            <b-col class="col-md-12 mb-1">
                <vue-good-table id="departmentTable" ref="departmentTable" :columns="columnsPriority" :rows="this.patentPriorityList" styleClass="vgt-table condensed tableSize" :search-options="{
                enabled: false,
              }" :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }" :pagination-options="{
              enabled: false,
              }" theme="my-theme">
                    <div slot="emptystate">
                        {{ $t('dataTable.data_not_found') }}
                    </div>
                    <template slot="table-column" slot-scope="props">
                        <span v-if="props.column.field === 'no'" class="text-nowrap">
                            {{ $t('wipoXml.priority_no') }}
                        </span>
                        <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                            {{ $t('wipoXml.priority_date') }}
                        </span>
                        <span v-else-if="props.column.field === 'country'" class="text-nowrap">
                            {{ $t('wipoXml.priority_country') }}
                        </span>
                    </template>
                </vue-good-table>
            </b-col>
        </b-card>
    </b-modal>
</div>
</template>

    
<script>
export default {
    props: {
        getPatentList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            columns: [{
                    label: this.$t('wipoXml.division_kind'),
                    field: 'kind',
                },
                {
                    label: this.$t('wipoXml.division_doc_number'),
                    field: `docNumber`,
                },
                {
                    label: this.$t('wipoXml.division_country'),
                    field: 'country',
                },
            ],
            columnsInventor: [{
                    label: this.$t('wipoXml.inventor_name'),
                    field: 'name',
                },
                {
                    label: this.$t('wipoXml.inventor_nationality_id_number'),
                    field: `nationalityIDNumber`,
                },
                {
                    label: this.$t('wipoXml.inventor_country'),
                    field: 'country',
                },
                {
                    label: this.$t('wipoXml.inventor_address'),
                    field: 'address',
                },
            ],
            columnsPriority: [{
                    label: this.$t('wipoXml.priority_no'),
                    field: 'no',
                },
                {
                    label: this.$t('wipoXml.priority_date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('wipoXml.priority_country'),
                    field: 'country',
                },
            ],
            patentAgentSelect: 0,
            patentAgentOption: [],
            patentOwnerSelect: 0,
            patentOwnerOption: [],
            patentNo: '',
            pctPatentNo: '',
            epB1PubDate: '',
            ipcCode: '',
            pctApplicationNo: '',
            pctApplicationDate: '',
            dateOfGrant: '',
            isTrDesignation: '',
            titleEn: '',
            clientName: '',
            patentOwnerList: [],
            patentDivisionList: [],
            patentInventorList: [],
            patentPriorityList: [],
            patentId: 0,
            address: '',
            nationalityIDNumber: '',
            pctPublicationDate: '',
            pctPublicationNo: '',
        }
    },
    methods: {
        showInfo(xmlData) {
            this.$refs['wipoXmlFormModal'].show() 
            this.patentAgentSelect = 0,
                this.patentAgentOption = [],
                this.patentOwnerSelect = 0,
                this.patentOwnerOption = [],
                this.patentOwnerList = []
            this.patentDivisionList = []
            this.patentInventorList = []
            this.patentPriorityList = []
            this.patentNo = xmlData.patent.patentNo
            this.pctPatentNo = xmlData.patent.pctPatentNo
            this.epB1PubDate = xmlData.patent.epB1PubDate
            this.ipcCode = xmlData.patent.ipcCode
            this.pctApplicationNo = xmlData.patent.pctApplicationNo
            this.pctApplicationDate = xmlData.patent.pctApplicationDate
            this.pctPublicationDate = xmlData.patent.pctPublicationDate
            this.pctPublicationNo = xmlData.patent.pctPublicationNo
            this.dateOfGrant = xmlData.patent.dateOfGrant
            this.isTrDesignation = xmlData.patent.isTrDesignation
            this.titleEn = xmlData.patent.titleEn
            this.clientName = xmlData.xmlClient.name
            this.patentOwnerList = xmlData.patentOwnerList
            if (xmlData.patentDivisionList != null) {
                this.patentDivisionList = xmlData.patentDivisionList

            }
            if (xmlData.patentInventorList != null) {
                this.patentInventorList = xmlData.patentInventorList

            }
            if (xmlData.patentPriorityList != null) {
                this.patentPriorityList = xmlData.patentPriorityList

            }

        },
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
            } else {
                this.clientId = null;
            }
        },
        onSearchAgent(search, loading) {
            if (search.length) {
                loading(true);
                this.searchAgent(loading, search, this);
            }
        },
        searchAgent(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.patentAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        onSearchOwner(search, loading) {
            if (search.length) {
                loading(true);
                this.searchOwner(loading, search, this);
            }
        },
        searchOwner(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    name: search
                }
                const controller = {
                    name: 'Client',
                    actionName: 'GetClientByName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.patentOwnerOption = response.data.clientList
                    loading(false)
                })
            }
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        addOrUpdate() {
            this.$refs.simpleRules.validate().then(success => {
                let data = {
                    id: 0,
                    patentNo: this.patentNo,
                    pctApplicationNo: this.pctApplicationNo,
                    pctApplicationDate: this.pctApplicationDate,
                    title: this.titleEn,
                    titleEn: this.titleEn,
                    epB1PubDate: this.epB1PubDate,
                    ipcCode: this.ipcCode,
                    patentPublicationTypeId: 3,
                    clientAccountingId: this.patentAgentSelect.clientAccountingId,
                    isTrDesignation: this.isTrDesignation,
                    patentTypeId: 1,
                    invoiceClientId: this.invoiceClientId = 0,
                    pctPatentNo: this.pctPatentNo,
                    pctPublicationDate: this.pctPublicationDate,
                    pctPublicationNo: this.pctPublicationNo
                }

                let controller = {
                    name: 'Patent'
                }
                let payload = {
                    data: data,
                    controller: controller
                }

                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.patentId = response.data.patentId;
                            if (this.patentId > 0) {
                                data = {
                                    id: 0,
                                    patentId: this.patentId,
                                    clientId: this.patentOwnerSelect.id
                                }
                                controller = {
                                    name: 'PatentOwner'
                                }
                                payload = {
                                    data: data,
                                    controller: controller
                                }
                                this.$store.dispatch('moduleAuth/save', payload)
                                if (this.patentDivisionList.length > 0) {
                                    this.patentDivisionList.forEach(division => {
                                        data = {
                                            id: 0,
                                            patentId: this.patentId,
                                            docNumber: division.docNumber,
                                            countryId: division.countryId,
                                            kind: division.kind,
                                        }

                                        controller = {
                                            name: 'PatentDivision'
                                        }

                                        payload = {
                                            data: data,
                                            controller: controller
                                        }
                                        this.$store.dispatch('moduleAuth/save', payload)
                                    })
                                }
                                if (this.patentInventorList.length > 0) {
                                    this.patentInventorList.forEach(inventor => {
                                        if (inventor.nationalityIDNumber != null) {
                                            this.nationalityIDNumber = inventor.nationalityIDNumber
                                        }
                                        if (inventor.address != null) {
                                            this.address = inventor.address
                                        }
                                        data = {
                                            id: 0,
                                            patentId: this.patentId,
                                            name: inventor.name,
                                            address: this.address,
                                            countryId: inventor.countryId,
                                            nationalityIDNumber: this.nationalityIDNumber
                                        }
                                        const controller = {
                                            name: 'PatentInventor'
                                        }
                                        const payload = {
                                            data: data,
                                            controller: controller
                                        }

                                        this.$store.dispatch('moduleAuth/save', payload)

                                    })
                                }
                                if (this.patentPriorityList.length > 0) {
                                    this.patentPriorityList.forEach(priority => {
                                        data = {
                                            id: 0,
                                            patentId: this.patentId,
                                            countryId: priority.countryId,
                                            date: priority.date,
                                            no: priority.no
                                        }
                                        const controller = {
                                            name: 'PatentPriority'
                                        }
                                        const payload = {
                                            data: data,
                                            controller: controller
                                        }
                                        this.$store.dispatch('moduleAuth/save', payload)
                                    })
                                }
                                this.$SaveAlert()
                                this.getPatentList();
                                this.$bvModal.hide('wipoXmlFormModal')
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
    }
}
</script>

    
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}
[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
.agentInput{
    max-width: 87em!important;
    width: 86em!important;
}
</style>
