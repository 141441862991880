<template>
  <div>
    <!-- modal -->
    <b-modal 
      id="countryFormModal"
      centered size="lg" 
      no-close-on-backdrop 
      ref="countryFormModal" 
      :title=" $t('country.country_info')"
      :cancel-title=" $t('others.cancel_title')"
      :ok-title=" $t('others.ok_title')"
      cancel-variant="outline-secondary" 
      @ok.prevent="addOrUpdate">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col>
              <validation-provider #default="{ errors }" :name=" $t('country.name')" rules="required">
                <label for="country-name-input">{{ $t('country.name') }}</label>
                <b-form-input id="country-name-input" v-model="countryName" maxlength="50"
                  :state="errors.length > 0 ? false : null" size="sm"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider #default="{ errors }" :name=" $t('country.name_en')" rules="required">
                  <label for="country-nameEn-input">{{ $t('country.name_en') }}</label>
                  <b-form-input id="country-nameEn-input" v-model="countryNameEn" maxlength="50"
                    :state="errors.length > 0 ? false : null" size="sm" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider #default="{ errors }" :name=" $t('country.code')" rules="required">
                  <label for="country-code-input">{{ $t('country.code') }}</label>
                  <b-form-input id="country-code-input" 
                    v-model="code"  
                    size="sm"
                    maxlength="3"
                    oninput="javascript: if (this.value.length > 3) this.value = this.value.slice(0, 3);" 
                    :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <validation-provider #default="{ errors }" :name=" $t('country.accounting_code')" rules="required">
                  <label for="country-AccountingCode-input">{{ $t('country.accounting_code') }}</label>
                  <b-form-input 
                    id="country-accountingCode-input" 
                    v-model="accountingCode"
                    type="number"
                    oninput="javascript: if (this.value.length > 3) this.value = this.value.slice(0, 3);" 
                    onKeyPress="if(this.value.length==3) return false"
                    size="sm"
                    :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider #default="{ errors }" :name=" $t('country.accounting_kktc_code')" rules="required">
                  <label for="country-accountingKktcCode-input">{{ $t('country.accounting_kktc_code') }}</label>
                  <b-form-input id="country-accountingKktcCode-input" 
                    v-model="accountingKktcCode"
                    oninput="javascript: if (this.value.length > 3) this.value = this.value.slice(0, 3);" 
                    type="number"
                    size="sm"
                    onKeyPress="if(this.value.length==3) return false"
                    :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- <validation-provider #default="{ errors }" name="IsMemberOfMadrid" rules="required"> -->
                  <label for="country-isMemberOfMadrid-input">{{ $t('country.is_member_of_madrid') }}</label>
                  <br>
                  <b-form-checkbox
                    class="custom-control-success"
                    name="country-isMemberOfMadrid-input"
                    id="country-isMemberOfMadrid-input"
                    v-model="isMemberOfMadrid"
                    switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    getCountryList: { type: Function },
  },
  data() {
    return {
      id: 0,
      countryName: '',
      countryNameEn: '',
      code: '',
      accountingCode: '',
      accountingKktcCode: '',
      isMemberOfMadrid: false,
    }
  },
  methods: {
    showInfo(id) {
      this.$refs['countryFormModal'].show()
      this.id = id
        const data = {id: this.id}
      const controller = { name: 'Country' }
      const payload = { data: data, controller: controller }
      this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
        if (response.data.resultStatus == true) {
          this.countryName = response.data.country.name
          this.countryNameEn = response.data.country.nameEn
          this.code = response.data.country.code
          this.accountingCode = response.data.country.accountingCode
          this.accountingKktcCode = response.data.country.accountingKktcCode
          this.isMemberOfMadrid = response.data.country.isMemberOfMadrid
        } else {
          this.$bvModal.hide('countryFormModal')
          this.$WarningAlert(response.data.resultMessage)
        }
      })
    },
    addOrUpdate() {
      const data = { id: this.id, name: this.countryName, nameEn: this.countryNameEn, code: this.code, accountingCode: this.accountingCode, accountingKktcCode: this.accountingKktcCode, isMemberOfMadrid: this.isMemberOfMadrid }
      const controller = { name: 'Country' }
      const payload = { data: data, controller: controller }
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('moduleAuth/save', payload).then((response) => {
            if (response.data.resultStatus == true) {
              this.$bvModal.hide('countryFormModal')
              this.getCountryList()
              this.$SaveAlert()
            } else {
              this.$WarningAlert(response.data.resultMessage)
            }
          })
        }
      })
    },
  }
}
</script>
<style lang="scss">
[dir=ltr] .modal-header .close {
  margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
  margin-bottom: 0.2857rem;
  font-weight: bold;
}

.modal .modal-content {
  overflow: visible;
  margin-bottom: auto;
}

[dir] .invalid-tooltip {
  padding: 0.4rem 0.775rem !important;
  margin-top: -1.5rem !important;
  background-color: #ea5455 !important;
  border-radius: 0.358rem !important;
  left: 20px !important
}
</style>
