<template>
    <div style="width: 70em;">
        <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="LockIcon" size="18" />
                <span class="align-middle ml-50">{{  $t('permissionTemplate.permission')  }}</span>
              </b-card-title>
            </b-card-header>
            <!--eslint-disable-->
            <div class="table-container">
              <table class="table table-striped table-bordered">
                <tbody>
                  <tr v-for="group in employeePermissionTableList" :key="group.id">
                    <td>{{  group.name  }}</td>
                    <td v-for="permission in group.permissionList">
                      <b-row>
                        <b-form-checkbox :checked="permission.selected" @change="changePermission($event, permission.id, permission.tableId)" />
                          {{  permission.name  }}                  
                      </b-row>
                    </td>
                  </tr>
                </tbody>
              </table>
           </div>

          </b-card>
    </div>
</template>
<script>
export default{
  data(){
    return {
        employeePermissionTableList:[],
        employeeId: 0
    }
  },
  methods:{
    showInfoPermission(employeeId) {
      this.employeeId = employeeId  
      const data = { id: this.employeeId }
      const controller = { name: 'EmployeePermission', actionName: 'GetEmployeePermissionListByEmployeeId' }
      const payload = { data: data, controller: controller }
      this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
        if (response.data.resultStatus == true) {
          this.employeePermissionTableList = response.data.permissionGroupList
        } else {
          this.$WarningAlert(response.data.resultMessage)
        }
      })
    },
    changePermission(selected, permissionId, tableId) {
      const controller = { name: 'EmployeePermission' }
      if (selected == true) {
        const data = { employeeId: this.employeeId  , permissionId: permissionId }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.$SaveAlert()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
      else {
        const data = { id: tableId }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.$SaveAlert()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
    },
  }
}
</script>
<style>
.tab-content > .active {
  display: block;
  width: 75em;
}
.table-container {
  max-height: 400px; /* Tablonun maksimum yüksekliği, gereksinimlerinize göre ayarlayın */
  overflow-y: auto; /* Dikey kaydırma çubuğunu etkinleştir */
}
</style>