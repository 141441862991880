<template>
<div>
    <!-- modal -->
    <b-modal id="employmentContractFormModal" centered size="lg" no-close-on-backdrop ref="employmentContractFormModal" :title=" $t('employmentContract.contract_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-12">
                    <validation-provider :name="$t('employmentContract.employee')" #default="{ errors }" :rules="{ excluded: 0 }">
                        <b-form-group :label="$t('employmentContract.employee')" label-for="name" :state="errors.length > 0 ? false : null">
                            <v-select size="sm" id="employee" v-model="employeeSelect" :options="employeeOption" @input="setEmployeeId" label="name" @search="onSearch">
                            </v-select>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12">
                    <validation-provider :name=" $t('employmentContract.employment_contract_type')" #default="{ errors }" :rules="{ excluded: 0 }">
                        <b-form>
                            <label for="employent-contract-contract-type-input">{{ $t('employmentContract.employment_contract_type') }}</label>
                            <b-form-select :disabled="id != 0" v-model="employmentContractTypeSelect" value-field="id" size="sm" text-field="name" :options="employmentContractTypeOption" :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form>
                    </validation-provider>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6 mt-1">
                        <label for="employent-contract-starting-date-input">{{ $t('employmentContract.contract_start_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="1" v-model="contractStartDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-lg-6 mt-1">
                    <b-form-group>
                        <label for="employent-contract-ending-date-input">{{ $t('employmentContract.contract_ending_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="contractEndingDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>

export default {
    props: {
        getUserContractList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            contractStartDate: null,
            contractEndingDate: null,
            employmentContractTypeSelect: 0,
            employmentContractTypeOption: [],
            employeeId: 0,
            employeeOption: [],
            employeeSelect: 0,
            employeeId: 0,
        }
    },
    computed: {
        isDisable() {
            return this.employeeId > 0
        }
    },
    methods: {
        showInfo(id, employeeId) {
            this.$refs['employmentContractFormModal'].show()
            this.id = id
            this.employeeId = employeeId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'EmploymentContract'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.employmentContractTypeOption = response.data.employmentContractTypeList
                    this.employmentContractTypeOption.splice(0, 0, this.$nullSelected())
                    if (response.data.employmentContract.employmentContractTypeId > 0) {
                        this.employmentContractTypeSelect = response.data.employmentContract.employmentContractTypeId
                    }
                    this.contractStartDate = response.data.employmentContract.contractStartDate
                    this.contractEndingDate = response.data.employmentContract.contractEndingDate
                    if (response.data.employmentContract.employeeId > 0) {
                        const usersData = {
                            employeeId: response.data.employmentContract.employeeId
                        }
                        const usersController = {
                            name: 'Employee',
                            actionName: 'GetEmployeeListByName'
                        }
                        const usersPayload = {
                            data: usersData,
                            controller: usersController
                        }
                        this.$store.dispatch('moduleAuth/customService', usersPayload).then((usersResponse) => {
                            this.employeeOption = usersResponse.data.employeeList
                            this.employeeSelect = usersResponse.data.employeeList[0]
                            this.employeeId = usersResponse.data.employeeList[0].id

                        })
                    } else if (employeeId > 0) {
                        const usersData2 = {
                            employeeId: employeeId
                        }
                        const usersController2 = {
                            name: 'Employee',
                            actionName: 'GetEmployeeListByName'
                        }
                        const usersPayload2 = {
                            data: usersData2,
                            controller: usersController2
                        }
                        this.$store.dispatch('moduleAuth/customService', usersPayload2).then((usersResponse2) => {
                            this.employeeOption = usersResponse2.data.employeeList
                            this.employeeSelect = usersResponse2.data.employeeList[0]
                            this.employeeId = usersResponse2.data.employeeList[0].id
                        })
                    } else {
                        this.employeeOption = []
                        this.employeeSelect = 0;
                    }
                } else {
                    this.$bvModal.hide('employmentContractFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                    this.employeeOption = []
                    this.employeeSelect = 0;
                    this.employmentContractTypeOption = [];
                    this.employmentContractTypeSelect = 0;
                }
            })
        },
        setEmployeeId(event) {
            this.employeeId = event.id
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    string: search
                }
                const controller = {
                    name: 'Employee',
                    actionName: 'GetEmployeeListByName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.employeeOption = response.data.employeeList
                    this.employeeId = response.data.employeeList[0].id
                    loading(false)
                })
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        addOrUpdate() {
            if (this.employeeId > 0) {
                this.employeeId = this.employeeId
            }
            const data = {
                id: this.id,
                employeeId: this.employeeId,
                contractEndingDate: this.contractEndingDate,
                contractStartDate: this.contractStartDate,
                employmentContractTypeId: this.employmentContractTypeSelect
            }
            const controller = {
                name: 'EmploymentContract'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('employmentContractFormModal')
                            if (this.userId > 0) {
                                this.getUserContractList()
                            } else {
                                this.getUserContractList()
                            }
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                        this.employeeId = 0;
                        this.employmentContractTypeOption = [];
                        this.employmentContractTypeSelect = 0;
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
