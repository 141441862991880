<template>
    <div>
      <!-- modal -->
      <b-modal
        id="clientMergeFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="clientMergeFormModal"
        :title=" $t('clientMerge.add_merge_company_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
      <validation-observer ref="infoRules" tag="form">
      <b-row>
        <b-col>
                <b-form>
                  <validation-provider
                    :name=" $t('clientMerge.deleted_company')"
                    #default="{ errors }"
                    rules="required"
                  >
                  <b-form-group
                    :label=" $t('clientMerge.deleted_company')"
                    label-for="clientMerge"
                    :state="errors.length > 0 ? false:null"
                  >
                        <v-select 
                        id="clientMerge"
                        v-model="clientSelect"
                        :options="clientOption" 
                        label="name" 
                        @search="onSearch">
                        <!-- :filterable="false"
                        :state="errors.length > 0 ? false:null"
                        value-field="id"
                        text-field="name"
                        :selectable="option => ! option.value.includes('select_value')" -->
                        </v-select>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback> 
                  </b-form-group>
                  </validation-provider>
                </b-form>
        </b-col>
      </b-row>
    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      clientId: Number,
      getClientList: {type: Function}
    },
    data() {
      return {
        clientSelect: '',
        clientOption: []
      }
    },
    methods: {
      onSearch(search, loading) {
      if(search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search(loading, search, vm) {
      if(search.length > 2){
        const data = {clientId: this.clientId, name: search }
        const controller = { name: 'Client', actionName:'GetClientByName' }
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          this.clientOption = response.data.clientList
          loading(false)
        })
    }
    },
      addOrUpdate() {
        if(this.clientSelect == null){
          this.$WarningAlert("Lütfen birleştirmek iztediğiniz müvekkili seçiniz.")
          this.$bvModal.hide('clientMergeFormModal')
          this.$bvModal.hide('clientFormModal')
          if(this.getClientList != null)
            this.getClientList()
        }
        else{
        const data = {clientId: this.clientId, mergeClientId: this.clientSelect.id}
        const controller = { name: 'Client', actionName: 'MergeClient' }
        const payload = { data: data, controller: controller }
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            this.$DeleteClientConfirm((this.$t('alerts.delete_confirm_client')), this.clientSelect.name + ' ' + this.$t('alerts.delete_confirm_question_client'), this.$t('alerts.merge_button')).then(result => {
              if (result.value) {
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('clientMergeFormModal')
                    this.$bvModal.hide('clientFormModal')
                    if(this.getClientList != null)
                      this.getClientList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
              }
            })
          }
        })
        }
      },
    }
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  [dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
  </style>
  