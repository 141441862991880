<template>
<div>
    <!-- modal -->
    <b-modal id="trademarkProvisionalFormModal" centered size="xl" no-close-on-backdrop ref="trademarkProvisionalFormModal" :title=" $t('trademarkProvisional.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" hide-footer>
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-md-3">
                    <b-form>
                        <label for="trademark-provisional-case-number-input">{{ $t('trademarkProvisional.case_number') }}</label>
                        <b-form-group>
                            <b-form-input id="trademark-provisional-case-number-input" v-model="caseNumber" size="sm" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-date">{{ $t('trademarkProvisional.date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <label for="trademark-provisional-status">{{ $t('trademarkProvisional.provisional_status') }}</label>
                    <b-form>
                        <validation-provider :name="$t('trademarkProvisional.provisional_status')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="trademarkProvisionalStatusSelect" value-field="id" text-field="name" :options="trademarkProvisionalStatusOption" size="sm" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <label for="trademark-provisional-case-instruction">{{ $t('trademarkProvisional.case_instruction') }}</label>
                    <b-form>
                        <validation-provider :name="$t('trademarkProvisional.case_instruction')" :rules="{ excluded: 0 }" v-slot="{ errors }" size="sm">
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="trademarkProvisionalCaseInstructionSelect" value-field="id" text-field="name" :options="trademarkProvisionalCaseInstructionOption" size="sm" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-deadline">{{ $t('trademarkProvisional.deadline') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="deadline"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <label for="trademark-provisional-employee">{{ $t('trademarkProvisional.employee') }}</label>
                    <b-form>
                        <validation-provider :name="$t('trademarkProvisional.employee')" :rules="{ excluded: 0 }" v-slot="{ errors }" size="sm">
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" size="sm" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <label for="trademark-provisional-no-yidk-objection-instruction-employee">{{ $t('trademarkProvisional.no_yidk_objection_instruction_employee') }}</label>
                    <b-form>
                        <validation-provider :name="$t('trademarkProvisional.employee')" :rules="{ excluded: 0 }" v-slot="{ errors }" size="sm">
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="noYidkObjectionInstructionEmployeeSelect" value-field="id" text-field="name" :options="noYidkObjectionInstructionEmployeeOption" size="sm" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <label for="trademark-provisional-term-of-litigation-employee">{{ $t('trademarkProvisional.term_of_litigation_employee') }}</label>
                    <b-form>
                        <validation-provider :name="$t('trademarkProvisional.term_of_litigation_employee')" :rules="{ excluded: 0 }" v-slot="{ errors }" size="sm">
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="termOfLitigationEmployeeOption" value-field="id" text-field="name" :options="termOfLitigationEmployeeSelect" size="sm" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-notification-date">{{ $t('trademarkProvisional.notification_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="notificationDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-yidk-date">{{ $t('trademarkProvisional.yidk_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="yidkDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-yidk-warning-date">{{ $t('trademarkProvisional.yidk_warning_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="yidkWarningDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-yidk-deadline">{{ $t('trademarkProvisional.yidk_deadline') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="yidkDeadline"></CustomDatePicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-no-yidk-objection-instruction-date">{{ $t('trademarkProvisional.no_yidk_objection_instruction_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="noYidkObjectionInstructionDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-yidk-notification-date">{{ $t('trademarkProvisional.yidk_notification_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="yidkNotificationDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-term-of-litigation-deadline-date">{{ $t('trademarkProvisional.term_of_litigation_deadline_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="termOfLitigationDeadlineDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-term-of-litigation-warning-date">{{ $t('trademarkProvisional.term_of_litigation_warning_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="termOfLitigationWarningDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-no-term-of-litigation-date">{{ $t('trademarkProvisional.no_term_of_litigation_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="noTermOfLitigationDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-yidk-bulletin-date">{{ $t('trademarkProvisional.yidk_bulletin_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="yidkBulletinDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-client-yidk-instruction-date">{{ $t('trademarkProvisional.client_yidk_instruction_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="clientYidkInstructionDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-notification-date-of-the-decision">{{ $t('trademarkProvisional.notification_date_of_the_decision') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="notificationDateOfTheDecision"></CustomDatePicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-notification-date-of-the-yidk-decision">{{ $t('trademarkProvisional.notification_date_of_the_yidk_decision') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="notificationDateOfTheYidkDecision"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-opinion-will-be-given-date">{{ $t('trademarkProvisional.opinion_will_be_given_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="opinionWillBeGivenDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-provisional-opinion-given-date">{{ $t('trademarkProvisional.opinion_given_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="opinionGivenDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-6">
                    <b-form>
                        <label for="trademark-provisional-clause-input">{{ $t('trademarkProvisional.clause') }}</label>
                        <b-form-group>
                            <b-form-input id="trademark-provisional-clause-input" v-model="clause" size="sm" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-6">
                    <b-form>
                        <label for="trademark-provisional-description-input">{{ $t('trademarkProvisional.description') }}</label>
                        <b-form-group>
                            <b-form-input id="trademark-provisional-description-input" v-model="description" size="sm" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12">
                    <label for="trademark-provisional-ground-of-objection-input">{{ $t('trademarkProvisional.ground_of_objection') }}</label>
                    <b-form-textarea id="trademark-provisional-ground-of-objection-input" :placeholder="$t('trademarkProvisional.ground_of_objection')" v-model="groundOfObjection" rows="2" maxlength="500" />
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>     
export default {
    props: {
        getTrademarkProvisionalList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            trademarkId: 0,
            trademarkProvisionalStatusOption: [],
            trademarkProvisionalStatusSelect: 0,
            trademarkProvisionalCaseInstructionOption: [],
            trademarkProvisionalCaseInstructionSelect: 0,
            noYidkObjectionInstructionEmployeeSelect: 0,
            noYidkObjectionInstructionEmployeeOption: [],
            termOfLitigationEmployeeSelect: 0,
            termOfLitigationEmployeeOption: [],
            employeeSelect: 0,
            employeeOption: [],
            clause: '',
            groundOfObjection: '',
            description: '',
            caseNumber: '',
            date: null,
            deadline: null,
            notificationDate: null,
            yidkDate: null,
            yidkWarningDate: null,
            yidkDeadline: null,
            noYidkObjectionInstructionDate: null,
            yidkNotificationDate: null,
            termOfLitigationDeadlineDate: null,
            termOfLitigationWarningDate: null,
            noTermOfLitigationDate: null,
            yidkBulletinDate: null,
            clientYidkInstructionDate: null,
            notificationDateOfTheDecision: null,
            notificationDateOfTheYidkDecision: null,
            opinionWillBeGivenDate: null,
            opinionGivenDate: null,
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['trademarkProvisionalFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TrademarkProvisional'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.trademarkProvisionalCaseInstructionOption = response.data.trademarkProvisionalCaseInstructionList
                    this.trademarkProvisionalStatusOption = response.data.trademarkProvisionalStatusList
                    this.noYidkObjectionInstructionEmployeeOption = response.data.noYidkObjectionInstructionEmployeeList
                    this.termOfLitigationEmployeeOption = response.data.termOfLitigationEmployeeList
                    this.employeeOption = response.data.employeeList
                    this.groundOfObjection = response.data.trademarkProvisional.groundOfObjection
                    this.clause = response.data.trademarkProvisional.clause
                    this.description = response.data.trademarkProvisional.description
                    this.caseNumber = response.data.trademarkProvisional.caseNumber
                    this.deadline = response.data.trademarkProvisional.deadline
                    this.notificationDate = response.data.trademarkProvisional.notificationDate
                    this.yidkDate = response.data.trademarkProvisional.yidkDate
                    this.yidkWarningDate = response.data.trademarkProvisional.yidkWarningDate
                    this.yidkDeadline = response.data.trademarkProvisional.yidkDeadline
                    this.noYidkObjectionInstructionDate = response.data.trademarkProvisional.noYidkObjectionInstructionDate
                    this.yidkNotificationDate = response.data.trademarkProvisional.yidkNotificationDate
                    this.termOfLitigationDeadlineDate = response.data.trademarkProvisional.termOfLitigationDeadlineDate
                    this.termOfLitigationWarningDate = response.data.trademarkProvisional.termOfLitigationWarningDate
                    this.noTermOfLitigationDate = response.data.trademarkProvisional.noTermOfLitigationDate
                    this.yidkBulletinDate = response.data.trademarkProvisional.yidkBulletinDate
                    this.clientYidkInstructionDate = response.data.trademarkProvisional.clientYidkInstructionDate
                    this.notificationDateOfTheDecision = response.data.trademarkProvisional.notificationDateOfTheDecision
                    this.notificationDateOfTheYidkDecision = response.data.trademarkProvisional.notificationDateOfTheYidkDecision
                    this.opinionWillBeGivenDate = response.data.trademarkProvisional.opinionWillBeGivenDate
                    this.opinionGivenDate = response.data.trademarkProvisional.opinionGivenDate
                    if (response.data.trademarkProvisional.trademarkProvisionalCaseInstructionId > 0) {
                        this.trademarkProvisionalCaseInstructionSelect = response.data.trademarkProvisional.trademarkProvisionalCaseInstructionId

                    } else {
                        this.trademarkProvisionalCaseInstructionSelect = 0
                    }
                    if (response.data.trademarkProvisional.trademarkProvisionalStatusId > 0) {
                        this.trademarkProvisionalStatusSelect = response.data.trademarkProvisional.trademarkProvisionalStatusId

                    } else {
                        this.trademarkProvisionalStatusSelect = 0
                    }
                    if (response.data.trademarkProvisional.noYidkObjectionInstructionEmployeeId > 0) {
                        this.noYidkObjectionInstructionEmployeeSelect = response.data.trademarkProvisional.noYidkObjectionInstructionEmployeeId
                    } else {
                        this.noYidkObjectionInstructionEmployeeSelect = 0

                    }
                    if (response.data.trademarkProvisional.termOfLitigationEmployeeId > 0) {
                        this.termOfLitigationEmployeeSelect = response.data.trademarkProvisional.termOfLitigationEmployeeId
                    } else {
                        this.termOfLitigationEmployeeSelect = 0
                    }
                    if (response.data.trademarkProvisional.employeeId > 0) {
                        this.employeeSelect = response.data.trademarkProvisional.employeeId
                    } else {
                        this.employeeSelect = 0
                    }
                    this.employeeOption.splice(0, 0, this.$nullSelected())
                    this.termOfLitigationEmployeeOption.splice(0, 0, this.$nullSelected())
                    this.noYidkObjectionInstructionEmployeeOption.splice(0, 0, this.$nullSelected())
                    this.trademarkProvisionalCaseInstructionOption.splice(0, 0, this.$nullSelected())
                    this.trademarkProvisionalStatusOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('trademarkProvisionalFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    height: 2.7em;
}

[dir] .btn-secondary {
    border: 1px solid #7367f0 !important;
    background-color: transparent !important;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
