<template>
    <div>
        <b-modal id="invoiceDiscountFormModal" centered size="lg" no-close-on-backdrop ref="invoiceDiscountFormModal" :title=" $t('invoiceDiscount.info')" :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
          <b-row>
            <b-col class="col-lg-12">
              <label for="invoice-discount-rate-input">{{ $t('invoiceDiscount.discountRate') }}</label>
              <validation-provider #default="{ errors }" :name=" $t('invoiceDiscount.discountRate')" rules="required" >
                <cleave id="invoice-discount-amount-input" v-model="rate" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
      </validation-observer>
    </b-modal>

    </div>
</template>
<script>
export default{
  props: {
    showInvoiceInfo: {
      type: Function
    }
  },
  data(){    
    return {
      invoiceId: 0,
      rate : 0,
      currencyId : 0,
      count:0,
      cleaveOptions: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralIntegerScale: 9,
        numeralDecimalScale: 2
      },
    }
  },
  methods:{
    showInfo(invoiceId, currencyId, count) {
      this.invoiceId = invoiceId
      this.currencyId = currencyId
      this.count = count
      this.rate = 0;
      this.$refs['invoiceDiscountFormModal'].show()
    },
    addOrUpdate() {      
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = {
              id: 0,
              invoiceId:this.invoiceId,
              discount: parseFloat(this.rate).toFixed(2),
              currencyId : this.currencyId,
              sequenceNo : this.count
          }
          
          const controller = {
            name: 'invoiceDetail',
            actionName : 'applyDiscount'
          }
          const payload = {
            data: data,
            controller: controller
          }

           this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
              this.$bvModal.hide('invoiceDiscountFormModal')
              this.showInvoiceInfo(this.invoiceId)

              this.$SaveAlert()
            } else {
              this.$WarningAlert(response.data.resultMessage)
            }
            })
        }
        })
      },  
    }
}
</script>
<style>
.tab-content > .active {
  display: block;
  width: 75em;
}
.cleaveStyle {
  max-height: 3em!important;
  height: 2.2em!important;
}
</style>