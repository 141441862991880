<template>
    <div>
        <b-modal
            id="patentResponsibleFormModal" centered size="lg" no-close-on-backdrop ref="patentResponsibleFormModal" :title=" $t('patentResponsible.responsible_info')" :cancel-title=" $t('others.cancel_title')"
            :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" hide-footer>
            <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon icon="LockIcon" size="18" />
                    <span class="align-middle ml-50">{{  $t('patentResponsible.responsible')  }}</span>
                  </b-card-title>
                </b-card-header>
                <!--eslint-disable-->
                <table class="table table-striped table-bordered">
                  <tbody>
                    <tr v-for="patentResponsibleItem in patentResponsibleList" :key="patentResponsibleItem.id">
                      <td >
                          <b-form-checkbox @change="changeResponsible($event, patentResponsibleItem.id)" />                                 
                      </td>
                      <td>{{  patentResponsibleItem.name  }}</td>

                    </tr>
                  </tbody>
                </table>
            </b-card>
        </b-modal>
    </div>
</template>
<script>
export default{
  props: {
      getPatentResponsibleList: {type: Function},
    },
  data(){
    return {
      id: 0,
        patentResponsibleList:[]
    }
  }, 
  methods:{
    changeResponsible(selected, clientResponsibleId) {
      const controller = { name: 'PatentResponsible' }
      if (selected == true) {
        const data = { patentId: this.patentId, clientResponsibleId: clientResponsibleId }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.getPatentResponsibleList()
            this.$SaveAlert()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
    },
    showInfo(patentId, clientId) {
      this.$refs['patentResponsibleFormModal'].show()
          this.patentId= patentId
          const data = {clientId: clientId,patentId:patentId}
          const controller = {name: 'ClientResponsible', actionName:'GetClientResponsibleListForModules'}
          const payload = {data: data, controller: controller}
          this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
              if(response.data.resultStatus == true){    
                  this.patentResponsibleList = response.data.clientResponsibleList
              } else {
                  this.$bvModal.hide('patentResponsibleFormModal')
                  this.$WarningAlert(response.data.resultMessage)
              }  
          }) 
      },
  }
}
</script>