<template>
    <div>
        <!-- modal -->
        <b-modal 
            id="trademarkViennaClassesFormModal" 
            centered size="lg" 
            no-close-on-backdrop ref="trademarkViennaClassesFormModal"
            :title="$t('trademarkViennaClasses.vienna_classes_info')" 
            :cancel-title="$t('others.cancel_title')"
            :ok-title="$t('others.ok_title')" 
            cancel-variant="outline-secondary" 
            @close="clear()"
            @ok.prevent="addOrUpdate"
        >
            <validation-observer ref="infoRules" tag="form">
                <b-row>
                    <b-col class="col-md-12">
                        <b-form>
                            <label for="trademark-vienna-classes-name-input">{{ $t('trademarkViennaClasses.name') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('trademarkViennaClasses.name')"
                                    rules="required">
                                    <b-form-input size="sm" id="trademark-vienna-classes-name-input" v-model="name" maxlength="50"
                                        :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        getTrademarkViennaClassesList: {
            type: Function
        }, 
    },
    data() {
        return {
            id: 0,
            trademarkId:0,
            name: '',
        }
    },
    methods: {
        showInfo(id, trademarkId) {
            this.$refs['trademarkViennaClassesFormModal'].show()
            this.id= id
            this.trademarkId= trademarkId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TrademarkViennaClass'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.name = response.data.trademarkViennaClass.name
                } else {
                    this.$bvModal.hide('trademarkViennaClassesFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
            this.name = null
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                trademarkId: this.trademarkId,
                name: this.name
            }
            const controller = {
                name: 'TrademarkViennaClass'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('trademarkViennaClassesFormModal')
                            this.getTrademarkViennaClassesList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        }
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
