<template>
    <div>
      <agent-transactions-report-trademark-list ref="agentTransactionsReportTrademarkList"></agent-transactions-report-trademark-list>
    <agent-transactions-report-national-trademark-list ref="agentTransactionsReportNationalTrademarkList"></agent-transactions-report-national-trademark-list>
    <agent-transactions-report-trademark-provisional-list ref="agentTransactionsReportTrademarkProvisionalList"></agent-transactions-report-trademark-provisional-list>
    <agent-transactions-report-trademark-objection-to-us-list ref="agentTransactionsReportTrademarkObjectionToUsList"></agent-transactions-report-trademark-objection-to-us-list>
    <agent-transactions-report-trademark-opposition-list ref="agentTransactionsReportTrademarkOppositionList"></agent-transactions-report-trademark-opposition-list>
    <agent-transactions-report-patent-list ref="agentTransactionsReportPatentList"></agent-transactions-report-patent-list>
    <agent-transactions-report-design-list ref="agentTransactionsReportDesignList"></agent-transactions-report-design-list>
    <agent-transactions-report-design-opposition-list ref="agentTransactionsReportDesignOppositionList"></agent-transactions-report-design-opposition-list>
    <agent-transactions-report-domain-list ref="agentTransactionsReportDomainList"></agent-transactions-report-domain-list>
    
    <b-row>
      <b-col class="col-lg-6">
        <table class="styled-table">
          <thead>
            <tr>
              <th>{{ $t('clientInfo.ownerFiles') }}</th>
              <th>{{ $t('clientInfo.count') }}</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="(value, key) in clientOwnershipInfo" :key="key" v-if="key !== 'clientId'">
                <td>{{ $t(`clientInfo.${key}`) }}</td>
                <td @click="handleClick(1,key, clientOwnershipInfo.clientId)" class="clickable">
                  {{ value }}
                </td>
              </tr>
            </tbody>
        </table>
      </b-col>

      <b-col class="col-lg-6">
        <table class="styled-table">
          <thead>
            <tr>
              <th>{{ $t('clientInfo.agentFiles') }}</th>
              <th>{{ $t('clientInfo.count') }}</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="(value, key) in clientAccountingInformation" :key="key" v-if="key !== 'id' && key !== 'code' && key !== 'forAgent'
              && key !== 'forInvoice' && key !== 'note' && key !== 'number'">
                <td>{{ $t(`clientInfo.${key}`) }}</td>
                <td @click="handleClick(0, key, clientAccountingInformation.id)" class="clickable">
                  {{ value }}
                </td>
              </tr>
            </tbody>
        </table>
      </b-col>
    </b-row>      
    </div>
  </template>
  
  <script>
  export default {    
    props: {
        getClientList: {type:Function},
        id: {type:Number},
        clientOwnershipInfo: {},
        clientAccountingInformation:{}
    },
    data() {
      return {
        pageLength: 10,
        dir: false,
      }
    },
    methods: {
        handleClick(clientType, recordType, id) {
          if (clientType == 0) {
                if (recordType == "trademarkActiveCount") {
                    this.$refs.agentTransactionsReportTrademarkList.getAgentTransactionsReportList(1, id)
                } else if (recordType == "trademarkPassiveCount") {
                    this.$refs.agentTransactionsReportTrademarkList.getAgentTransactionsReportList(2, id)
                } else if (recordType == "patentCount") {
                    this.$refs.agentTransactionsReportPatentList.getAgentTransactionsReportList(7, id)
                } else if (recordType == "designCount") {
                    this.$refs.agentTransactionsReportDesignList.getAgentTransactionsReportList(9, id)
                } else if (recordType == "nationalTrademarkCount") {
                    this.$refs.agentTransactionsReportNationalTrademarkList.getAgentTransactionsReportList(3, id)
                } else if (recordType == "trademarkProvisionalCount") {
                    this.$refs.agentTransactionsReportTrademarkProvisionalList.getAgentTransactionsReportList(4, id)
                } else if (recordType == "trademarkObjectionToUsCount") {
                    this.$refs.agentTransactionsReportTrademarkObjectionToUsList.getAgentTransactionsReportList(5, id)
                } else if (recordType == "trademarkOppositionCount") {
                    this.$refs.agentTransactionsReportTrademarkOppositionList.getAgentTransactionsReportList(6, id)
                } else if (recordType == "designOppositionCount") {
                    this.$refs.agentTransactionsReportDesignOppositionList.getAgentTransactionsReportList(10, id)
                } else if (recordType == "domainCount") {
                    this.$refs.agentTransactionsReportDomainList.getAgentTransactionsReportList(11, id)
                }
            }

            if (clientType == 1) {
                if (recordType == "trademarkActiveCount" ) {
                    this.$refs.agentTransactionsReportTrademarkList.getOwnerTransactionsReportList(1, id)
                } else if (recordType == "trademarkPassiveCount") {
                    this.$refs.agentTransactionsReportTrademarkList.getOwnerTransactionsReportList(2, id)
                } else if (recordType == "nationalTrademarkCount") {
                    this.$refs.agentTransactionsReportNationalTrademarkList.getOwnerTransactionsReportList(3, id)
                } else if (recordType == "trademarkProvisionalCount") {
                    this.$refs.agentTransactionsReportTrademarkProvisionalList.getOwnerTransactionsReportList(4, id)
                } else if (recordType == "trademarkObjectionToUsCount") {
                    this.$refs.agentTransactionsReportTrademarkObjectionToUsList.getOwnerTransactionsReportList(5, id)
                } else if (recordType == "trademarkOppositionCount") {
                    this.$refs.agentTransactionsReportTrademarkOppositionList.getOwnerTransactionsReportList(6, id)
                } else if (recordType == "patentCount") {
                    this.$refs.agentTransactionsReportPatentList.getOwnerTransactionsReportList(7, id)
                } else if (recordType == "designCount") {
                    this.$refs.agentTransactionsReportDesignList.getOwnerTransactionsReportList(9, id)
                } else if (recordType == "designOppositionCount") {
                    this.$refs.agentTransactionsReportDesignOppositionList.getOwnerTransactionsReportList(10, id)
                } else if (recordType == "domainCount") {
                    this.$refs.agentTransactionsReportDomainList.getOwnerTransactionsReportList(11, id)
                }
            }
        }
    },
  }
  </script>
  <style lang="scss" >
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 14px;
    text-align: left;
  }
  .styled-table th, .styled-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
  }
  .styled-table tr:nth-child(even) {
    background-color: #f3f3f3;
  }
  .styled-table tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
  .clickable {
    color: #3498db;
    text-decoration: underline;
    cursor: pointer; /* Mouse işaretçisini tıklanabilir yapar */
  }
  </style>
  