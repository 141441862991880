<template>
<div>
    <b-row>
        <b-col class="col-12">
            <div style="float:left;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-col class="col-md-6">
                            <b-form-select v-model="yearSelect" class="mr-1" size="sm" value-field="id" text-field="no" v-on:keyup.enter="getServiceFeeList()" :options="yearOption" />
                        </b-col>
                        <b-col class="col-md-6">
                            <b-form-select v-model="typeSelect" class="mr-1" size="sm" value-field="id" text-field="name" v-on:keyup.enter="getServiceFeeList()" :options="typeOption" />
                        </b-col>
                        <b-col class="col-md-6">
                            <b-form-select v-model="paymentTypeSelect" size="sm" value-field="id" text-field="name" v-on:keyup.enter="getServiceFeeList()" :options="paymentTypeOption" />
                        </b-col>
                    </div>
                </b-form-group>
            </div>
            <div style="float:right;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-button style="float:right;" size="sm" variant="outline-primary" v-on:keyup.enter="getServiceFeeList()" @click="getServiceFeeList()">{{ $t('others.list') }}</b-button>
                        <b-button style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    </div>
                </b-form-group>
            </div>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table ref="serviceFeeTable" styleClass="vgt-table condensed tableSize" :columns="columns" :rows="rows" :search-options="{
            enabled: true,
            externalQuery: searchTerm}" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
          enabled: true,
          perPage:pageLength
        }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field ==='tpmkCode'" class="text-nowrap">
                {{ $t('serviceFee.tpmk_code') }}
            </span>
            <span v-else-if="props.column.field ==='ledesCode'" class="text-nowrap">
                {{ $t('serviceFee.code') }}
            </span>
            <span v-else-if="props.column.field ==='service'" class="text-nowrap">
                {{ $t('serviceFee.service_name') }}
            </span>
            <span v-else-if="props.column.field ==='tpmkName'" class="text-nowrap">
                {{ $t('serviceFee.tpmk_name') }}
            </span>
            <span v-else-if="props.column.field ==='price'" class="text-nowrap">
                {{ $t('serviceFee.price') }}
            </span>
            <span v-else-if="props.column.field ==='onlinePrice'" class="text-nowrap">
                {{ $t('serviceFee.online_price') }}
            </span>
            <span v-else-if="props.column.field ==='officialFee'" class="text-nowrap">
                {{ $t('serviceFee.fees') }}
            </span>
            <span v-else-if="props.column.field ==='paymentType'" class="text-nowrap">
                {{ $t('serviceFee.payment_type') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title=" $t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title=" $t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }} </span>
                    <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

  
<script>
export default {
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [{
                    label: this.$t('serviceFee.tpmk_code'),
                    field: `tpmkCode`,
                },
                {
                    label: this.$t('serviceFee.code'),
                    field: `ledesCode`,
                },
                {
                    label: this.$t('serviceFee.service_name'),
                    field: `service`,
                },
                {
                    label: this.$t('serviceFee.tpmk_name'),
                    field: `tpmkName`,
                },
                {
                    label: this.$t('serviceFee.price'),
                    field: `price`,
                },
                {
                    label: this.$t('serviceFee.online_price'),
                    field: `onlinePrice`,
                },
                {
                    label: this.$t('serviceFee.fees'),
                    field: `officialFee`,
                },
                {
                    label: this.$t('serviceFee.payment_type'),
                    field: `paymentType`,
                },
            ],
            typeOption :[
            { id: 0, name: this.$t('serviceFee.all')},
            { id: 1, name: this.$t('serviceFee.normal')},
            { id: 2, name: this.$t('serviceFee.penalty')},
            ],
            typeSelect : 0,
            paymentTypeSelect: 0,
            paymentTypeOption: [],
            rows: [],
            searchTerm: '',
            yearOption: [],
            yearSelect: new Date().getFullYear(),
        }
    },
    mounted() {
        this.getServiceFeeList()
    },
    methods: {
        getServiceFeeList() {
            this.rows = []
            const controller = {
                name: 'ServiceFee',
                actionName: 'GetServiceFeeListByYear'
            }
            const data = {
                year: this.yearSelect,
                type : this.typeSelect,
                paymentType: this.paymentTypeSelect
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.serviceFeeList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.yearOption = response.data.yearList
                    this.paymentTypeOption = response.data.paymentTypeList
                    this.paymentTypeOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'ServiceFee',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Service Fee", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>
  
<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}
.tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
