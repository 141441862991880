<template>
<div>
    <!-- modal -->
    <b-modal id="clientConflictFormModal" centered size="lg" no-close-on-backdrop ref="clientConflictFormModal" :title=" $t('clientConflict.add_conflict_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col>
                    <b-form>
                        <label for="client-conflict-company-name">{{ $t('clientConflict.company_name') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('clientConflict.company_name')" rules="required">
                                <b-form-input id="client-conflict-company-name" size="sm" v-model="companyName" maxlength="50" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col>
                    <b-form>
                        <label for="client-conflict-owner-code-input">{{ $t('clientConflict.owner_code') }}</label>
                        <b-form-group>
                            <cleave style="height: 2em;" id="ownerCode" size="sm" v-model="ownerCode" class="form-control" :raw="false" :options="ownerCodeOptions" placeholder="000000" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form>
                        <label for="module-input">{{ $t('clientConflict.module') }}</label>
                        <b-form-group>
                            <b-form>
                                <validation-provider :name=" $t('clientConflict.module')" :rules="{ excluded:0}" v-slot="{ errors }">
                                    <b-form-select size="sm" v-model="moduleSelect" value-field="id" text-field="name" :options="moduleOption" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col>
                    <b-form>
                        <label for="client-conflict-agent-reference-no-name">{{ $t('clientConflict.agent_reference_no') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('clientConflict.agent_reference_no')" rules="required">
                                <b-form-input size="sm" id="client-conflict-agent-reference-no-name" v-model="agentReferenceNo" maxlength="50" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getClientConflictList: {
            type: Function
        },
    },
    data() {
        return {
            companyName: '',
            moduleSelect: 0,
            moduleOption: [],
            agentReferenceNo: '',
            ownerCode: null,
            ownerCodeOptions: {
                blocks: [6],
                numericOnly: true
            },
            id: 0,
            clientId: 0
        }
    },
    methods: {
        showInfo(id, clientId) {
            this.$refs['clientConflictFormModal'].show()
            this.id = id
            this.clientId = clientId;
            const data = {
                id: this.id
            }
            const controller = {
                name: 'ClientConflict'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.agentReferenceNo = response.data.clientConflict.agentReferenceNo
                    this.companyName = response.data.clientConflict.name
                    this.ownerCode = response.data.clientConflict.ownerCode
                    this.moduleOption = response.data.moduleList
                    this.invoiceSendTypeOption.splice(0, 0, this.$nullSelected())
                    if (response.data.clientConflict.moduleId > 0) {
                        this.moduleSelect = response.data.clientConflict.moduleId
                    } else {
                        this.moduleSelect = 0
                    }
                } else {
                    this.$bvModal.hide('clientConflictFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                clientId: this.clientId,
                name: this.companyName,
                ownerCode: this.ownerCode,
                moduleId: this.moduleSelect,
                agentReferenceNo: this.agentReferenceNo
            }
            const controller = {
                name: 'ClientConflict'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('clientConflictFormModal')
                            this.getClientConflictList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
