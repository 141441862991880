import { render, staticRenderFns } from "./ClientMergeCompanyForm.vue?vue&type=template&id=07486bbe&"
import script from "./ClientMergeCompanyForm.vue?vue&type=script&lang=js&"
export * from "./ClientMergeCompanyForm.vue?vue&type=script&lang=js&"
import style0 from "./ClientMergeCompanyForm.vue?vue&type=style&index=0&id=07486bbe&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports