<template>
<div>
    <!-- modal -->
    <b-modal id="dailyworkOfficialFeeFormModal" centered size="xl" no-close-on-backdrop ref="dailyworkOfficialFeeFormModal" :title="$t('dailywork.dailywork_officialFee_title')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-2">
                    <label for="dailywork-invoice-accounting-credit-card-input">{{ $t('multiDailyworkSave.credit_card') }}</label>
                    <b-form>
                        <validation-provider v-slot="{ errors }" :name="$t('multiDailyworkSave.credit_card')" :rules="{ excluded:0}" >
                            <b-form-select size="sm" v-model="creditCardSelect" :options="creditCardOption" value-field="id" name="dailywork-invoice-accounting-credit-card-input" text-field="name" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{  errors[0]  }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-lg-2">
                    <b-form>
                        <label for="dailywork-invoice-accounting-invoice-receipt-no-input">{{ $t('multiDailyworkSave.receipt_no') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('multiDailyworkSave.receipt_no')" rules="required">
                                <b-form-input id="dailywork-invoice-accounting-invoice-receipt-no-input" size="sm" v-model="receiptNo" type="number" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-2">
                    <b-form>
                        <b-form-group>
                            <label for="dailywork-invoice-accounting-receipt-date-input">{{ $t('multiDailyworkSave.receipt_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="receiptDate"></CustomDatePicker>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-2">
                    <b-form>
                        <label for="dailywork-invoice-accounting-document-no-input">{{ $t('multiDailyworkSave.document_no') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('multiDailyworkSave.document_no')" rules="required">
                                <b-form-input id="dailywork-invoice-accounting-document-no-input" size="sm" v-model="documentNo" type="number" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-invoice-accounting-document-date-input">{{ $t('multiDailyworkSave.document_date') }}</label>
                    <b-form>
                        <b-form-group>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="documentDate"></CustomDatePicker>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDailyworkList: {
            type: Function
        },
    },
    data() {
        return {
        id: 0,
        moduleId:0,
        recordId:0,
        creditCardOption: [],
        creditCardSelect: 0,
        receiptNo: '',
        receiptDate: null,
        documentDate: null,
        documentNo: '',
        }
    },
    methods: {
        showInfo(id, moduleId, recordId) {
            this.$refs['dailyworkOfficialFeeFormModal'].show()
            this.id = id
            this.moduleId = moduleId
            this.recordId = recordId

            const data = {
                id: this.id,
                moduleId: this.moduleId,
                recordId: this.recordId,
            }
            const controller = {
                name: 'DailyWork',
                actionName: 'GetDailyworkOfficialFeeForm'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.creditCardOption = response.data.creditCardList
                    this.receiptNo = response.data.multiDailyWork.receiptNo
                    this.receiptDate = response.data.multiDailyWork.receiptDate
                    this.documentNo = response.data.multiDailyWork.documentNo
                    this.documentDate = response.data.multiDailyWork.documentDate
                    this.creditCardOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('dailyworkOfficialFeeFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },       
        addOrUpdate() {            
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const data = {
                        id : this.id,
                        CreditCardId: this.creditCardSelect,
                        receiptNo: this.receiptNo,
                        receiptDate: this.receiptDate,
                        documentNo: this.documentNo,
                        documentDate: this.documentDate,
                        employeeId: localStorage.getItem('EmployeeId'),
                    }
                    const controller = {
                        name: 'DailyWork',
                        actionName: 'SaveDailyworkOfficialFee'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }

                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                                if (response.data.resultStatus == true) {0
                                    this.$bvModal.hide('dailyworkOfficialFeeFormModal')
                                    this.getDailyworkList()
                                    this.$SaveAlert()
                                } else {
                                    this.$WarningAlert(response.data.resultMessage)
                                }
                            })
                        }
                })
            }
        }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
