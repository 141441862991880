<template>
    <div>
      <!-- modal -->
      <b-modal
        id="nationalPatentHistoryFormModal" centered size="lg" no-close-on-backdrop ref="nationalPatentHistoryFormModal" :title=" $t('nationalPatentHistory.national_patent_history_info')" :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
              <b-col class="col-md-12">
                <b-form-group>
                    <label for="national-rademark-history-date">{{ $t('nationalPatentHistory.national_patent_history_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-md-12 mb-1">
                <label for="national-patent-history-time">{{ $t('nationalPatentHistory.national_patent_history_time') }}</label>
                <b-input-group>
                  <cleave style="height: 2em;" id='example-input' v-model="time" class="form-control" :raw="false" :options="options.time" placeholder="00:00:00"/>
                  <b-input-group-append>
                    <b-form-timepicker style="height: 2em;" v-model="time" :locale="$i18n.locale" v-bind="labels[locale] || {}" button-only now-button  button-variant="outline-primary"
                      reset-button no-close-button  aria-controls="example-input" size="sm"></b-form-timepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-md-12">
                  <label for="national-patent-history-status">{{ $t('nationalPatentHistory.national_patent_history_status') }}</label>
                  <b-form>
                    <validation-provider :name="$t('nationalPatentHistory.national_patent_history_status')" :rules="{ excluded: 0 }"
                      v-slot="{ errors }">
                      <b-form-select :state="errors.length > 0 ? false : null" v-model="nationalPatentHistoryStatusSelect" value-field="id"
                        text-field="name" :options="nationalPatentHistoryStatusOption" size="sm" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form>
              </b-col>
            </b-row>
        </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>    
  export default {
    props: {
      getNationalPatentList: {type: Function},
      getNationalPatentHistoryList: {type: Function},
      getNationalPatentCountryList: {type: Function},
    },
    watch: {
      time(val){
        if(val == null){
          val = new Date()
          let valVar = val.getHours() + ":" + val.getMinutes();
          this.time = valVar
        }
      },
    },
    data() {
      return {
        id: 0,
        nationalPatentHistoryStatusSelect: 0,
        nationalPatentHistoryStatusOption: [],
        options: {
          time: {
            time: true,
            timePattern: ['h', 'm'],
          },
        },
        date: null,
        time: '',
        locale: this.$i18n.locale,
        labels: {
          tr: {
            labelHours: 'Saat',
            labelMinutes: 'Dakika',
            labelSeconds: 'Saniye',
            labelIncrement: 'Arttır',
            labelDecrement: 'Azalt',
            labelSelected: 'Seçiniz..',
            labelNoTimeSelected: 'Henüz tarih seçilmedi',
            labelCloseButton: 'Kapat',
            labelResetButton: 'Temizle',
            labelNowButton: 'Şu an'
          },
        },
      }
    },
    methods: {
      showInfo(id, nationalPatentCountryId) {
        this.$refs['nationalPatentHistoryFormModal'].show()
        this.id = id
        this.nationalPatentCountryId = nationalPatentCountryId
        const data = { id: this.id, nationalPatentCountryId: this.nationalPatentCountryId }
        const controller = { name: 'NationalPatentHistory' }
        const payload = { data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
          if (response.data.resultStatus == true) {
            var newDate = response.data.nationalPatentHistory.date.split('T', -1)
            this.nationalPatentHistoryStatusSelect = response.data.nationalPatentHistory.nationalPatentStatusId
            this.nationalPatentHistoryStatusOption = response.data.nationalPatentStatusList
            this.date = response.data.nationalPatentHistory.date
            this.time = newDate[1]
            this.nationalPatentHistoryStatusOption.splice(0, 0, this.$nullSelected())
          } else {
            this.$bvModal.hide('nationalPatentHistoryFormModal')
            this.$WarningAlert(response.data.resultMessage)
          }
      })
        },
      addOrUpdate() {
        var newDate = this.date.split('T', -1)
        var dateAndTime = newDate[0] + 'T' + this.time
        const data = {id: this.id, nationalPatentCountryId: this.nationalPatentCountryId, nationalPatentStatusId: this.nationalPatentHistoryStatusSelect, employeeId: localStorage.getItem('EmployeeId'), date: dateAndTime}
        const controller = {name : 'NationalPatentHistory'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('nationalPatentHistoryFormModal')
                    this.getNationalPatentHistoryList()
                    this.getNationalPatentCountryList()
                    this.getNationalPatentList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  .btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    height: 2.7em;
}
[dir] .btn-secondary {
  border: 1px solid #7367f0 !important;
  background-color: transparent !important;
}
.datepickerStyle{
  max-height: 2.2em;
  font-family: fangsong;
}
  </style>