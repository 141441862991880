<template>
    <div>
      <!-- modal -->
      <b-modal id="accountingBooksAccountsFormModal" centered size="lg" no-close-on-backdrop ref="accountingBooksAccountsFormModal" :title=" $t('accountingBooksAccounts.info')"
        :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" :ok-disabled="disabledOkButton" >
        <validation-observer ref="simpleRules">
        <b-row>
            <b-col class="col-md-12">
                <b-form>
                    <label for="accounting-books-accounts-account-no-input">{{ $t('accountingBooksAccounts.account_no') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('accountingBooksAccounts.account_no')" rules="required">
                        <b-form-input @change="checkAccountingNoNameList()" size="sm" id="accounting-books-accounts-account-no-input" v-model="accountNo" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-12">
                <b-form>
                    <label for="accounting-books-accounts-account-name-input">{{ $t('accountingBooksAccounts.account_name') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('accountingBooksAccounts.account_name')" rules="required">
                        <b-form-input size="sm" id="accounting-books-accounts-account-name-input" v-model="accountName" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-12">
                <b-form>
                    <label for="accounting-books-accounts-currency-input">{{ $t('accountingBooksAccounts.currency') }}</label>
                    <b-form-group>
                        <validation-provider :name="$t('accountingBooksAccounts.currency')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select @change="checkAccountingNoNameList()" size="sm" :state="errors.length > 0 ? false : null" v-model="currencySelect" value-field="id" text-field="name" :options="currencyOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-md-12">
                <b-form>
                    <label for="accounting-books-accounts-isParent-input">{{ $t('accountingBooksAccounts.isParent') }}</label>
                    <b-form-group>
                        <b-form-checkbox class="custom-control-success" name="accounting-books-accounts-isParent-input" id="accounting-books-accounts-isParent-input" v-model="isParent" switch>
                            <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon"/>
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                            </span>
                        </b-form-checkbox>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getAccountingBooksList: {type: Function},
      getAccountingBooksAccountsList: {type: Function},
      subsidiaryLedgerId: {Number}
    },
    data() {
      return {
        id: 0,
        accountNo: '',
        accountName: '',
        currencySelect: 0,
        isParent : true,
        currencyOption: [],
        disabledOkButton: false
      }
    },
    methods: {
      checkAccountingNoNameList(){
        const data = {accountingNo: this.accountNo, subsidiaryLedgerId: this.currencySelect}
        const controller = {name : 'Accounting', actionName: 'SearchAccountingByAccountingNo'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if(response.data.resultStatus == true){
                if (response.data.accountingList && response.data.accountingList.length !== 0) {
                    this.disabledOkButton = true
                } else {
                    this.disabledOkButton = false
                }
            } else {
                this.$bvModal.hide('accountingBooksAccountsFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        }) 
      },
      showInfo(id) {  
        this.$refs['accountingBooksAccountsFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'Accounting'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.currencySelect = response.data.accounting.currencyId
                this.accountNo = response.data.accounting.number
                this.accountName = response.data.accounting.name
                this.isParent = response.data.accounting.isParent
                this.currencyOption = response.data.currencyList
                this.currencyOption.splice(0, 0, this.$nullSelected())
            } else {
                this.$bvModal.hide('accountingBooksAccountsFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        this.$refs.simpleRules.validate().then(success => {
                if (success) {
        const data = {id: this.id, number: this.accountNo, name: this.accountName, currencyId: this.currencySelect, isParent: this.isParent, subsidiaryLedgerId: this.subsidiaryLedgerId}
        const controller = {name : 'Accounting'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/save',payload).then((response) => {
            if(response.data.resultStatus == true){
                this.$bvModal.hide('accountingBooksAccountsFormModal')
                this.getAccountingBooksList()
                this.getAccountingBooksAccountsList()
                this.$SaveAlert()
            } else {
                this.$WarningAlert(response.data.resultMessage)
            }
        })
    }
            })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  