<template>
<div>
    <b-row>
        <b-col class="col-12">
            <div style="float:left;" class="custom-search d-flex justify-content-end">
                <b-row>
                    <b-col clas="col-7">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <b-form-input size="sm" v-model="searchTerm" :placeholder="$t('others.search_name')" type="text" class="d-inline-block" />
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-5">
                        <div class="d-flex align-items-center ml-2">
                            <b-form-group>
                                <b-form-select v-model="yearSelect" @change="getHourlyAnnualLeaveReportList()" size="sm" value-field="id" text-field="name" :options="yearOption" />
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <b-col>
                <div style="float: right" class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getHourlyAnnualLeaveReportList()" @click="getHourlyAnnualLeaveReportList()()">{{ $t('domain.list') }}</b-button>
                            <b-button style="float: right"  size="sm" variant="outline-primary" @click="exportExcel()">{{ $t("excel.excel") }}</b-button>
                        </div>
                    </b-form-group>
                </div>
            </b-col>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="hourlyAnnualLeaveReportTable" ref="hourlyAnnualLeaveReportTable" styleClass="vgt-table condensed tableSize" :columns="columns" :rows="rows" :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }" theme="my-theme">
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field ==='employee'" class="text-nowrap">
                {{ $t('hourlyAnnualLeave.employee') }}
            </span>
            <span v-else-if="props.column.field ==='used'" class="text-nowrap">
                {{ $t('hourlyAnnualLeave.total') }}
            </span>
            <span v-else-if="props.column.field ==='left'" class="text-nowrap">
                {{ $t('hourlyAnnualLeave.remaining') }}
            </span>
            <span v-else-if="props.column.field ==='process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: CheckBox -->
            <!-- Column: Process -->
        </template>
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                        {{ $t("dataTable.total") }} {{ props.total }}
                        {{ $t("dataTable.record") }}
                    </span>
                    <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  " />
                    <span class="text-nowrap"> {{ $t("dataTable.row_is_show") }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [{
                    label: this.$t("hourlyAnnualLeave.employee"),
                    field: "employee",
                },
                {
                    label: this.$t("hourlyAnnualLeave.total"),
                    field: "used",
                },
                {
                    label: this.$t("hourlyAnnualLeave.remaining"),
                    field: "left",
                },
            ],
            rows: [],
            searchTerm: "",
            yearOption: [],
            yearSelect: 0,
        };
    },
    mounted() {
        this.getHourlyAnnualLeaveReportList();
    },
    methods: {
        getHourlyAnnualLeaveReportList() {
            this.rows = [];
            const data = {
                year: this.yearSelect
            };
            const controller = {
                name: "HourlyAnnualLeave",
                actionName: "HourlyAnnualLeaveRequestReportByYear",

            };
            const payload = {
                data: data,
                controller: controller,
            };
            this.$store.dispatch("moduleAuth/customService", payload)
                .then((response) => {
                    if (response.data.resultStatus == true) {
                        response.data.hourlyAnnualLeaveReportList.forEach((element) => {
                            this.rows.push(element);
                        });
                        this.yearOption = response.data.yearList
                        this.yearSelect = response.data.year
                    } else {
                        this.$WarningAlert(response.data.resultMessage);
                    }
                });
        },
        exportExcel() {
            const controller = {
                name: "HourlyAnnualLeave",
                actionName: "CanExport",
            };
            const data = {
                columnList: this.columns,
                DataList: this.rows,
            };
            const payload = {
                data: data,
                controller: controller,
            };
            this.$store
                .dispatch("moduleAuth/customService", payload)
                .then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("SaatlikIzınler", response.data.base64, "xlsx");
                    } else {
                        this.$WarningAlert(response.data.resultMessage);
                    }
                });
        },
    },
};
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
