<template>
<div>
    <job-update-note-form  :recordId="this.recordId":getJobDetailList="getJobDetailList" ref="jobUpdateNoteForm"></job-update-note-form>
    <b-modal id="jobDetailFormModal" centered size="xl" no-close-on-backdrop ref="jobDetailFormModal" :title="$t('jobDetail.info') + '-' + recordNo" hide-footer>
        <b-row>
            <b-col class="col-12">
                <div style="float:left;" class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <b-form-input size="sm" v-model="searchTerm" :placeholder="$t('others.search_name')" type="text" class="d-inline-block" />
                        </div>
                    </b-form-group>
                </div>
            </b-col>
        </b-row>
        <!-- table -->
        <vue-good-table id="jobDetailTable" ref="jobDetailTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
          enabled: false,
          perPage: pageLength
        }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'jobStatus'" class="text-nowrap">
                    {{ $t('jobDetail.job_status') }}
                </span>
                <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                    {{ $t('jobDetail.date') }}
                </span>
                <span v-else-if="props.column.field === 'note'" class="text-nowrap">
                    {{ $t('jobDetail.note') }}
                </span>
                <span v-else-if="props.column.field === 'nextJobDeadline'" class="text-nowrap">
                    {{ $t('jobDetail.next_job_deadline') }}
                </span>
                <span v-else-if="props.column.field === 'tpmkDeadline'" class="text-nowrap">
                    {{ $t('jobDetail.tpmk_deadline') }}
                </span>
                <span v-if="props.column.field === 'employee'" class="text-nowrap">
                    {{ $t('jobDetail.employee') }}
                </span>
                <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'process'">
                    <span v-if="props.row.isNoteEditable == true">
                        <span>
                            <b-button v-b-tooltip.html :title="$t('others.note')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getJobNoteInfo(props.row.id)">
                                <feather-icon icon="EditIcon" />
                            </b-button>
                        </span>
                    </span>
                </span>
            </template>
        </vue-good-table>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getJobList: {
            type: Function
        },
        getJobHistoryList: {
            type: Function
        },
        recordNo: String,
        recordId: Number,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [{
                    label: this.$t('jobDetail.job_status'),
                    field: `jobStatus`,
                },
                {
                    label: this.$t('jobDetail.date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('jobDetail.note'),
                    field: `note`,
                },
                {
                    label: this.$t('jobDetail.next_job_deadline'),
                    field: `nextJobDeadline`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('jobDetail.tpmk_deadline'),
                    field: `tpmkDeadline`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('jobDetail.employee'),
                    field: `employee`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            jobId : 0,
        }
    },
    methods: {
        getJobDetailList(id) {
          this.jobId = id
            this.$refs.jobDetailFormModal.show()
            this.rows = []
            const data = {
                id: id
            }
            const controller = {
                name: 'JobHistory',
                actionName: 'GetJobHistoryListByJobId'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.jobHistoryList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        getJobNoteInfo(id) {
            this.$refs['jobUpdateNoteForm'].showInfo(id, this.jobId)
        },
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
