<template>
<div>
    <!-- modal -->
    <b-modal id="reminderFormModal" centered size="lg" no-close-on-backdrop ref="reminderFormModal" :title=" $t('reminder.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @close="clear()" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row v-if="searchControl">
                    <b-col class="col-12">
                        <b-form style="margin-top: 0.2em">
                            <validation-provider :name="$t('totalImageUpload.record_no')" #default="{ errors }" :rules="{ excluded: 0 }">
                                <b-form-group :label="$t('totalImageUpload.record_no')" label-for="total-image-upload-record-no-input"
                                :state="errors.length > 0 ? false : null">
                                <v-select id="total-image-upload-record-no-input" size="sm" v-model="recordName" label="recordName" @input="setValues" @search="onFastSearch"
                                :options="searchRecordOption.map(option => ({ ...option, recordName: option.recordName}))" >
                                </v-select>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-form>
                    </b-col> 
                </b-row>
                <b-row>
                    <b-col class="col-lg-6 mt-1">
                        <label for="reminder-employee-input">{{ $t('reminder.employee') }}</label>
                        <b-form>
                            <validation-provider :name="$t('reminder.employee')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                                <b-form-select :state="errors.length > 0 ? false : null" size="sm" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-6 mt-1">
                        <b-form-group>
                            <label for="reminder-reminder_date">{{ $t('reminder.reminder_to_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="reminderToDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <label for="reminder-note-input">{{ $t('reminder.note') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('reminder.note')" rules="required">
                                <b-form-textarea id="reminder-note-input" v-model="reminderNote" rows="5" maxlength="500" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getReminderList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            reminderNote: '',
            searchControl : true,
            moduleId: 0,
            employeeId: 0,
            recordId: 0,
            recordName:'',
            reminderToDate: null,
            employeeSelect: 0,
            employeeOption: [],
            searchRecordOption:[],
            dateOption: {
                date: true,
                delimiter: '.',
                datePattern: ['d', 'm', 'Y'],
            },
        }
    },
    methods: {
        clear() {
            this.searchRecordOption = []
            this.recordName = ''
            this.reminderGroupSelect = 0
            this.moduleId = 0
            this.recordId = 0    
        },
        onFastSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.fastSearch(loading, search, this);
            }
        },
        fastSearch(loading, search) {
            if (search.length > 2) {
                const data = {
                    recordNo: search,
                    isOnlyOperationRecord : true
                }

                const controller = {
                    name: 'Module',
                    actionName: 'GetRecordListByRecordNo'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.searchRecordOption = response.data.recordList
                    loading(false)
                })
            }
        },
        setValues(event) {
            this.moduleId = event.moduleId
            this.recordId = event.recordId            
        },
        showInfo(id, recordId, moduleId) {
            if(recordId > 0)
                this.searchControl = false
            
            this.$refs['reminderFormModal'].show()
            this.id = id
            this.recordId = recordId
            this.moduleId = moduleId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Reminder'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.employeeOption = response.data.employeeList
                    this.reminderNote = response.data.reminder.note
                    this.reminderToDate = response.data.reminder.reminderToDate
                    this.moduleId = response.data.reminder.moduleId,
                        this.employeeOption.splice(0, 0, this.$nullSelected())
                    if (response.data.reminder.employeeId > 0) {
                        this.employeeSelect = response.data.reminder.employeeId
                    } 
                    else {
                        this.employeeSelect = localStorage.getItem('EmployeeId')
                    }
                    this.recordId = response.data.reminder.recordId
                    if (this.moduleId == 0) {
                        this.moduleId = moduleId
                    }
                    if (this.recordId == 0) {

                        this.recordId = recordId
                    }

                } else {
                    this.$bvModal.hide('reminderFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                note: this.reminderNote,
                reminderToDate: this.reminderToDate,
                employeeId: this.employeeSelect,
                recordId: this.recordId,
                moduleId: this.moduleId
            }
            const controller = {
                name: 'Reminder'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            //Stock-778
                            if (this.getReminderList != null) {
                                this.getReminderList()
                            }
                            //
                            this.$SaveAlert()
                            this.clear()
                            this.$bvModal.hide('reminderFormModal')
                            this.employeeSelect = 0
                            
                        } else {
                            this.$CustomWarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
