<template>
<div>
    <!-- modal -->
    <b-modal id="domainOppositionBasisForOppositionFormModal" centered size="lg" no-close-on-backdrop ref="domainOppositionBasisForOppositionFormModal" :title=" $t('domainOppositionBasisForOpposition.opposition_basis_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" @close="clear()">
        <validation-observer ref="infoRules" tag="form">
            <b-row>
                <b-col class="col-md-12 mt-1">
                    <b-form>
                        <label for="domainOppositionBasisForOpposition-Name-input">{{ $t('domainOppositionBasisForOpposition.name') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('domainOppositionBasisForOpposition.name')" rules="required">
                                <b-form-input id="domainOppositionBasisForOpposition-Name-input" v-model="domainOppositionBasisForOppositionName" size="sm" maxlength="50" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-12 mt-1">
                    <b-form>
                        <b-form-group :label=" $t('domainOppositionBasisForOpposition.trademark_record_no')" label-for="trademarkRecordNo">
                            <v-select id="trademarkRecordNo" v-model="trademarkRecordNoSelect" :options="trademarkRecordNoOption" @input="setTrademarkId" size ="sm" label="recordNo" @search="onSearch">
                            </v-select>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDomainOppositionBasisForOppositionList: {
            type: Function
        },
        getDomainOppositionList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            domainOppositionId: 0,
            domainOppositionBasisForOppositionName: '',
            trademarkRecordNoOption: [],
            trademarkRecordNoSelect: 0,
            trademarkId: 0,
        }
    },
    methods: {
        setTrademarkId(event) {
            this.trademarkId = event.id
            this.domainOppositionBasisForOppositionName = event.name
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        showInfo(id, domainOppositionId) {
            this.$refs['domainOppositionBasisForOppositionFormModal'].show()
            this.id = id
            this.domainOppositionId = domainOppositionId

            const data = {
                id: this.id
            }
            const controller = {
                name: 'DomainOppositionBasisForOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                this.domainOppositionBasisForOppositionName = response.data.domainOppositionBasisForOpposition.name
                if (response.data.domainOppositionBasisForOpposition.trademarkId > 0) {
                    const trademarkData = {
                        trademarkId: response.data.domainOppositionBasisForOpposition.trademarkId
                    }
                    const trademarkController = {
                        name: 'Trademark',
                        actionName: 'GetTrademarkListWithRecordNo'
                    }
                    const trademarkPayload = {
                        data: trademarkData,
                        controller: trademarkController
                    }
                    this.$store.dispatch('moduleAuth/customService', trademarkPayload).then((trademarkResponse) => {
                        this.trademarkRecordNoOption = trademarkResponse.data.trademarkList
                        this.trademarkRecordNoSelect = trademarkResponse.data.trademarkList[0]
                        this.trademarkId = trademarkResponse.data.trademarkList[0].id
                    })
                } else {
                    this.trademarkRecordNoOption = []
                    this.trademarkRecordNoSelect = 0;
                }
            })
        },
        clear() {
            this.domainOppositionBasisForOppositionName = null
            this.trademarkRecordNoOption = []
            this.trademarkRecordNoSelect = 0
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    trademarkId: 0,
                    recordNo: search
                }
                const controller = {
                    name: 'Trademark',
                    actionName: 'GetTrademarkListWithRecordNo'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkRecordNoOption = response.data.trademarkList
                    loading(false)
                })
            }
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                domainOppositionId: this.domainOppositionId,
                name: this.domainOppositionBasisForOppositionName,
                trademarkId: this.trademarkId
            }
            const controller = {
                name: 'DomainOppositionBasisForOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('domainOppositionBasisForOppositionFormModal')
                            this.domainOppositionBasisForOppositionName = ''
                            this.getDomainOppositionBasisForOppositionList()
                            this.getDomainOppositionList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
</style>
