<template>
<div>
    <!-- modal -->
    <b-modal id="trademarkRenewalsFormModal" ref="trademarkRenewalsFormModal" centered size="lg" no-close-on-backdrop :title=" $t('trademarkRenewals.renewals_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-md-6">
                <label for="trademark-renewals-renewal-date-input">{{ $t('trademarkRenewals.renewal_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="1" v-model="renewalDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-6">
                <label for="trademark-renewals-reminder-date-input">{{ $t('trademarkRenewals.reminder_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="reminderDate"></CustomDatePicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-6">
                <label for="trademark-renewals-instruction-date-input">{{ $t('trademarkRenewals.instruction_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="instructionDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-6">
                <label for="trademark-renewals-next-renewal-date-input">{{ $t('trademarkRenewals.next_renewal_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="nextRenewalDate"></CustomDatePicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-12">
                <b-form>
                    <label for="trademark-agent-ref-code-input">{{ $t('trademark.trademark_agent_ref_code') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="trademark-agent-ref-code-input" v-model="agentReferenceNo" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12 mt-1">
                <b-form-group>
                    <label for="trademark-renewals-agent-input"> {{ $t('trademarkRenewals.agent') }} </label>
                    <b-input-group>
                        <validation-provider :name=" $t('trademarkRenewals.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <b-form-group label-for="trademark-renewals-agent-input" :state="errors.length > 0 ? false:null">
                                <v-select class="agentInput" id="trademark-renewals-agent-input" v-model="trademarkRenewalAgentSelect" :options="trademarkRenewalAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                                </v-select>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                      <b-input-group-append>
                        <b-dropdown style="width: 4em; height: 2.5em; margin-bottom: 1em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                            <template #button-content>
                                <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                            </template>
                            <b-dropdown-item @click="getClientInfo(0)">
                                <span>{{this.$t('others.add_client')}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="getAgentNoteInfo(trademarkRenewalAgentSelect.clientAccountingId)">
                                <span>{{this.$t('others.show_note')}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="getClientInfo(trademarkRenewalAgentSelect.clientId)">
                                <span>{{this.$t('others.show_agent')}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>    
export default {
    props: {
        // trademarkId: Number,
        getTrademarkRenewalsList: {
            type: Function
        },
        getTrademarkList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            reminderDate: null,
            instructionDate: null,
            renewalDate: null,
            nextRenewalDate: null,
            trademarkRenewalAgentOption: [],
            trademarkRenewalAgentSelect: 0,
            agentReferenceNo: '',
        }
    },
    methods: {
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
            } else {
                this.clientId = null;
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkRenewalAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        showInfo(id, trademarkId) {
            this.$refs['trademarkRenewalsFormModal'].show()
            this.id = id
            this.trademarkId = trademarkId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TrademarkRenewal'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.instructionDate = response.data.trademarkRenewal.instructionDate
                    this.reminderDate = response.data.trademarkRenewal.reminderDate
                    this.renewalDate = response.data.trademarkRenewal.renewalDate
                    this.nextRenewalDate = response.data.trademarkRenewal.nextRenewalDate
                    this.agentReferenceNo = response.data.trademarkRenewal.agentReferenceNo
                    if (response.data.trademarkRenewal.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.trademarkRenewal.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.trademarkRenewalAgentOption = clientAccountingResponse.data.clientList
                            this.trademarkRenewalAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.trademarkRenewalAgentOption = []
                        this.trademarkRenewalAgentSelect = 0;
                    }
                } else {
                    this.$bvModal.hide('trademarkRenewalsFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                trademarkId: this.trademarkId,
                instructionDate: this.instructionDate,
                reminderDate: this.reminderDate,
                renewalDate: this.renewalDate,
                nextRenewalDate: this.nextRenewalDate,
                clientAccountingId: this.trademarkRenewalAgentSelect.clientAccountingId,
                agentReferenceNo: this.agentReferenceNo,
            }
            const controller = {
                name: 'TrademarkRenewal'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('trademarkRenewalsFormModal')
                            this.getTrademarkRenewalsList()
                            this.$SaveAlert()
                            this.trademarkRenewalAgentSelect = 0
                            this.trademarkRenewalAgentOption = []
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
.agentInput{
    max-width: 87em!important;
    width: 86em!important;
}
</style>
