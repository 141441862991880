<template>
    <div>
      <accounting-books-form :getAccountingBooksList="getAccountingBooksList" ref="accountingBooksFormModal"></accounting-books-form>
      <b-row class="align-items-center mb-1">
        <b-col class="d-flex align-items-center col-lg-8">
            <b-col class="col-md-5">
              <label for="accounting-books-company-input">{{ $t('accountingBooks.company') }}</label>
              <b-form-select id="accounting-books-company-select-input" v-model="companySelect" @change="getAccountingBooksList()" v-on:keyup.enter="getAccountingBooksList()" size="sm" value-field="id" text-field="name" :options="companyOption" />
            </b-col>
            <b-col class="col-md-5">
              <label for="accounting-books-name-input">{{ $t('accountingBooks.name') }}</label>
              <b-form-input size="sm" v-model="name" @change="getAccountingBooksList()" :placeholder="$t('accountingBooks.name')" type="text" class="d-inline-block" />
            </b-col>
        </b-col>
        <b-col class="d-flex justify-content-end col-lg-4 mt-2">
          <b-button style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>         
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table 
      id="accountingBooksTable"
      ref="accountingBooksTable" 
      :columns="columns" 
      :rows="rows" 
      styleClass="vgt-table condensed tableSize" 
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" 
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            {{ $t('accountingBooks.description') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
  
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </span>
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                  <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                  <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
              </div>
              <div>
                  <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getDesignList()">
                      <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                  </b-pagination>
              </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        total: 0,
        pageLength: 100,
        dir: false,
        pageNo: 1,
        companySelect: 1,
        companyOption: [],
        columns: [
          {
            label: this.$t('accountingBooks.description'),
            field: `name`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
        name: '',
      }
    },
    mounted() {
      const controller = { name: 'Company' }
      const payload = { controller: controller }
      this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.companySelect = 1
            this.companyOption = response.data.companyList
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      this.getAccountingBooksList()
    },
    methods: {
      async getInfo(id) {
        this.$refs.accountingBooksFormModal.showInfo(id)
      },
      getAccountingBooksList() {
        this.rows = []
        const controller = { name: 'SubsidiaryLedger' }
        const data = { companyId: this.companySelect, pageLength: this.pageLength, pageNo: this.pageNo, name: this.name}
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.name = response.data.subsidiaryLedgerList.name
            response.data.subsidiaryLedgerList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      },
      exportExcel() {
        const controller = { name: 'AccountingBooks', actionName: 'CanExport'}            
        const data = { columnList : this.columns, DataList : this.rows}
        const payload = { data : data, controller: controller}
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.$downloadFile("AccountingBooks", response.data.base64, 'xlsx')
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
    },
  }
  </script>
  <style lang="scss" >
  .excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  </style>
  