<template>
    <div>
      <mail-to-read-form :getMailToReadList="getMailToReadList" ref="mailToReadFormModal" ></mail-to-read-form>                  
      <b-row>
          <b-col class="col-12">
              <div style="float:left;" class="custom-search d-flex justify-content-end">
                  <b-form-group>
                        <div class="d-flex align-items-center">
                          <b-form-input
                              size="sm"
                              v-model="searchTerm"
                              :placeholder= "$t('others.search_name')"
                              type="text"
                              class="d-inline-block"
                          />
                        </div>        
                  </b-form-group>
              </div>
              <div style="float:right;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <b-button style="float:right;" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('mailToRead.mail_to_read_add') }}</b-button>
                    <b-button style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>                                     
                  </div>
                </b-form-group>
              </div>
          </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table
          ref="mailToReadTable"
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table condensed tableSize" 
          :search-options="{
          enabled: true,
          externalQuery: searchTerm}"
          :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
          :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
          theme="my-theme"
      >
        <div slot="emptystate">
           {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span
            v-if="props.column.field ==='id'"
            class="text-nowrap"
          >
            {{ $t('others.Id') }}
          </span>
          <span
            v-else-if="props.column.field ==='senderMail'"
            class="text-nowrap"
          >
            {{ $t('mailToRead.sender_mail') }}
          </span>
          <span
            v-else-if="props.column.field ==='mailContent'"
            class="text-nowrap"
          >
            {{ $t('mailToRead.mail_content') }}
          </span>
          <span
            v-else-if="props.column.field ==='module'"
            class="text-nowrap"
          >
            {{ $t('mailToRead.module') }}
          </span>
          <span
            v-else-if="props.column.field ==='documentTypeDetail'"
            class="text-nowrap"
          >
            {{ $t('mailToRead.document_type_detail') }}
          </span>
          <span
            v-else-if="props.column.field ==='mailReadType'"
            class="text-nowrap"
          >
            {{ $t('mailToRead.mail_read_type') }}
          </span>
          <span
            v-else-if="props.column.field ==='process'"
            class="text-nowrap"
          >
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template
            slot="table-row"
            slot-scope="props"
        >
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button
                  v-b-tooltip.html
                  :title=" $t('others.edit')"
                  v-b-modal.modal-top
                  variant="primary"
                  class="btn-icon mb-1 ml-1"
                  size="sm"
                  @click="getInfo(props.row.id)"
              >
  
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                  v-b-tooltip.html
                  :title=" $t('others.delete')"
                  variant="warning"
                  class="btn-icon mb-1 ml-1"
                  size="sm"
                  @click="deleted(props.row)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>
  
        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }} </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['10','50','100', '1000']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        pageLength: 10,
        dir: false,
        columns: [
          {
            label: this.$t('others.Id'),
            field: `id`,
          },
          {
            label: this.$t('mailToRead.sender_mail'),
            field: `senderMail`,
          },
          {
            label: this.$t('mailToRead.mail_content'),
            field: `mailContent`,
          },
          {
            label: this.$t('mailToRead.module'),
            field: `module`,
          },
          {
            label: this.$t('mailToRead.document_type_detail'),
            field: `documentTypeDetail`,
          },
          {
            label: this.$t('mailToRead.mail_read_type'),
            field: `mailReadType`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted(){
      this.getMailToReadList()
    },
    methods: {
      async deleted(data) {
        data = {id: data.id}
        const controller = {name : 'MailToRead'}
        const payload = {data: data, controller: controller}
       this.$DeleteConfirm().then(result => {
         if(result.value){
            this.$store.dispatch('moduleAuth/delete',payload).then((response) => {
              if(response.data.resultStatus == true){
                this.$DeleteAlert()
                this.getMailToReadList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getMailToReadList()
              }     
            })
         }
       })  
      },
      async getInfo(id){
          this.$refs.mailToReadFormModal.showInfo(id)      
      },
      getMailToReadList(){
            this.rows = []
            const controller = {name : 'MailToRead'}
            const payload = {controller: controller}
            this.$store.dispatch('moduleAuth/getList',payload).then((response) => {
              if(response.data.resultStatus == true){
                response.data.mailToReadList.forEach(element => {
                this.rows.push(element)
                })
              }
              else{
                this.$WarningAlert(response.data.resultMessage)
              }
            }
          )
      },
      exportExcel() {
              const controller = { name: 'MailToRead', actionName: 'CanExport'}            
              const data = { columnList : this.columns, DataList : this.rows}
              const payload = { data : data, controller: controller}
              this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                  if (response.data.resultStatus == true) {
                      this.$downloadFile("MailSablonlari", response.data.base64, 'xlsx')
                  }
                   else {
                      this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
    },
  }
  </script>
  <style lang="scss" >
  .excel-css{
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem; 
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  </style>
  