<template>
    <div>
      <trademark-form  ref="trademarkFormModal"></trademark-form>
      <b-modal id="agentTransactionsReportTrademarkProvisionalModal" centered size="xl" no-close-on-backdrop ref="agentTransactionsReportTrademarkProvisionalModal" :title=" $t('agentTransactionsReportTrademarkProvisional.title')" hide-footer>
        <!-- table -->
      <vue-good-table
        id="agentTransactionsReportTrademarkProvisionalTable"
        ref="agentTransactionsReportTrademarkProvisionalTable" 
        :columns="columns"
        :rows="rows"
        styleClass="vgt-table condensed tableSize"
        :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" 
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          
          <span v-if="props.column.field === 'ownerList'">
                <b-col>
                    <b-row v-for="owner in props.row.ownerList" v-bind:key="owner.clientName" style="font-size: small;">
                        <tr v-if="props.row.ownerList.length != 1">
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                        <tr v-else-if="props.row.ownerList.length == 1">
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
          </template>
     <!-- pagination -->
     <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
            </span>
            <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      </vue-good-table>
    </b-modal>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        total: 0,
        pageLength: 50,
        dir: false,
        pageNo: 1,
        columns: [
          {
            label: this.$t('agentTransactionsReportTrademarkProvisional.referance_no'),
            field: 'recordNo',
          },
          {
            label: this.$t('agentTransactionsReportTrademarkProvisional.name'),
            field: 'name',
          },
          {
            label: this.$t('agentTransactionsReportTrademarkProvisional.agent'),
            field: 'agent',
          },
          {
            label: this.$t('agentTransactionsReportTrademarkProvisional.owner'),
            field: 'ownerList',
          },
          {
            label: this.$t('agentTransactionsReportTrademarkProvisional.clause'),
            field: 'registerNo',
          },
          {
            label: this.$t('agentTransactionsReportTrademarkProvisional.ground_of_objection'),
            field: 'applicationNo',
          },
          {
            label: this.$t('agentTransactionsReportTrademarkProvisional.date'),
            field: 'applicationDate',
          },
          {
            label: this.$t('agentTransactionsReportTrademarkProvisional.deadline'),
            field: 'registerDate',
          },
          {
            label: this.$t('agentTransactionsReportTrademarkProvisional.status'),
            field: 'trademarkStatus',
          }
        ],
        rows: [],
        searchTerm: '',
        isAgent:false,
        isOwner:false,
        name:''
      }
    },
    methods: {
      showTrademarkInfoForm(row){
          this.$refs.trademarkFormModal.showInfo(row.id)
      },
      getAgentTransactionsReportList(recordType, clientAccountingId) {
        this.$refs.agentTransactionsReportTrademarkProvisionalModal.show()
        this.rows = []
        const data = { recordType: recordType, clientAccountingId: clientAccountingId}
        const controller = { name: 'Client', actionName: 'AgentTransactionsReportDetail'}
        const payload = { data: data, controller: controller }
        let loader = this.$loading.show()
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
              this.rows = response.data.trademarkList
          } else {
              this.$WarningAlert(response.data.resultMessage)
          }
          loader.hide()
        })
      },
      getOwnerTransactionsReportList(recordType, clientId) {
        this.$refs.agentTransactionsReportTrademarkProvisionalModal.show()
        this.rows = []
        const data = { recordType: recordType, clientId: clientId}
        const controller = { name: 'Client', actionName: 'AgentTransactionsReportDetail'}
        const payload = { data: data, controller: controller }
        let loader = this.$loading.show()
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
              this.rows = response.data.trademarkList
          } else {
              this.$WarningAlert(response.data.resultMessage)
          }
          loader.hide()
        })
      }
    },
  }
  </script>
<style>
   .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
  