<template>
  <div>
    <b-modal id="myAccountFormModal" ref="myAccountFormModal" modal-class="modal-primary" centered :title="$t('myAccount.photo_form')"
      no-close-on-backdrop hide-footer>
      <b-row style="text-align: -webkit-center;">
        <b-col>
          <Cropper ref="cropper" class="cropper" :src="image"
            :stencil-component="$options.components.Stencil" />
        </b-col>
      </b-row>
      <b-row style="justify-content:center;" class="mt-2">
        <b-button class="ml-2" variant="primary" @click="onSubmit">{{ $t('others.upload') }}
        </b-button>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

import Stencil from "../../../views/pages/Stencil.vue";
export default {
  props: {
    uploadImage: { type: Function },
    image: null
  },
  components: {
    Cropper,
    Stencil
  },
  data() {
    return {
      imageOnlyBase64: null,
      myAccountForm: {},
    }
  },
  methods: {

    onSubmit() {
      const result = this.$refs.cropper.getResult();
      //this.imageOnlyBase64 = result.image.src.split(",")[1]
      this.imageOnlyBase64 = result.canvas.toDataURL("image/png").split(",")[1]

      const data = { employeeId: localStorage.getItem('EmployeeId'), base64: this.imageOnlyBase64 }
      const controller = { name: 'Employee', actionName: 'UploadEmployeePhoto' }
      const payload = { data: data, controller: controller }
      this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
        if (response.data.resultStatus == true) {
          localStorage.setItem('employeePhoto', this.imageOnlyBase64)
          this.$SaveAlert()
          window.location.reload();
        } else {
          this.$WarningAlert(response.data.resultMessage)
        }
      })      
    },
  },
}
</script>