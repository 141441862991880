<template>
    <div>
      <!-- modal -->
      <b-modal
        id="patentBulletinFormModal"
        centered
        size="xl"
        no-close-on-backdrop
        ref="patentBulletinFormModal"
        :title=" $t('patentBulletin.patent_bulletin_info')"
        @ok.prevent="addOrUpdate"
        hide-footer
      >
      <b-row>
        <!--Patent--> 
          <b-col>
            <b-card :title=" $t('patentBulletin.bulletin_patent_title') ">
              <b-list-group >
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletin.application_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.applicationNo != this.tpmkApplicationNo" style="color:red">
                            {{applicationNo}}
                          </b-card-text>
                          <b-card-text v-else-if="this.applicationNo == this.tpmkApplicationNo">
                            {{applicationNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletin.application_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.applicationDate != this.tpmkApplicationDate" style="color:red">
                            {{this.$formatFn(applicationDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.applicationDate == this.tpmkApplicationDate">
                            {{this.$formatFn(applicationDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletin.register_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.recordNo != this.tpmkRecordNo" style="color:red">
                           -
                          </b-card-text>
                          <b-card-text v-else-if="this.recordNo == this.tpmkRecordNo">
                            -
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletin.register_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.recordDate != this.tpmkRecordDate" style="color:red">
                            {{this.$formatFn(recordDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.recordDate == this.tpmkRecordDate">
                            {{this.$formatFn(recordDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
              </b-list-group>
            </b-card>
          </b-col>
          <!--TPMK Patent--> 
          <b-col>
            <b-card :title=" $t('patentBulletin.tpmk_bulletin_patent_title') ">
              <b-list-group>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletin.tpmk_application_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkApplicationNo != this.applicationNo" style="color:red">
                            {{tpmkApplicationNo}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkApplicationNo == this.applicationNo">
                            {{tpmkApplicationNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletin.tpmk_application_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkApplicationDate != this.applicationDate" style="color:red">
                            {{this.$formatFn(tpmkApplicationDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkApplicationDate == this.applicationDate">
                            {{this.$formatFn(tpmkApplicationDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletin.tpmk_register_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkRecordNo != this.recordNo" style="color:red">
                            {{tpmkRecordNo}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkRecordNo == this.recordNo">
                            {{tpmkRecordNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletin.tpmk_register_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkRecordDate != this.recordDate" style="color:red">
                            {{this.$formatFn(tpmkRecordDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkRecordDate == this.recordDate">
                            {{this.$formatFn(tpmkRecordDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-12">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletin.tpmk_attorney') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAttorney}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
              </b-list-group>
            </b-card>
          </b-col>
      </b-row>
      <b-row>
      <!--Patent Priority--> 
        <b-col>
          <b-card :title=" $t('patentBulletin.bulletin_priority_title') ">
            <b-list-group v-for="(nameAndAddress, index) in patentBulletinNameAndAddress" :key="nameAndAddress.id">
              <b-list-group-item class="flex-column align-items-start">
                <b-row>
                    <b-col>
                      <b-card-text>
                        {{ nameAndAddress.no }}
                        <br>
                        {{ nameAndAddress.country }}
                      </b-card-text>
                    </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
        <!--TPMK Patent Priority--> 
        <b-col>
          <b-card :title=" $t('patentBulletin.tpmk_bulletin_priority_title') ">
            <b-list-group v-for="(nameAndAddress, index) in tpmkPatentBulletinNameAndAddress" :key="nameAndAddress.id">
              <b-list-group-item class="flex-column align-items-start">
                    <b-row>
                      <b-col>
                        <b-card-text>
                          {{ nameAndAddress.no }}
                          <br>
                          {{ nameAndAddress.country }}
                        </b-card-text>
                      </b-col>
                    </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <!--Design Owner--> 
          <b-col>
            <b-card :title=" $t('patentBulletin.bulletin_patent_owner_title') ">
              <b-list-group  v-for="(nameAddress, index) in patentOwnerNameAndAddress" :key="nameAddress.id">
                <b-list-group-item class="flex-column align-items-start">
                  <b-row>
                    <b-col>
                      <b-card-text>
                        {{ nameAddress.clientName }}
                        <br>
                        {{ nameAddress.address }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
          <!--TPMK Design Owner--> 
          <b-col>
            <b-card :title=" $t('patentBulletin.tpmk_bulletin_patent_owner_title') ">
              <b-list-group v-for="(nameAddress, index) in tpmkPatentOwnerNameAndAddress" :key="nameAddress.id">
                <b-list-group-item class="flex-column align-items-start">
                  <b-row>
                    <b-col>
                      <b-card-text>
                        {{ nameAddress.name }}
                        <br>
                        {{ nameAddress.address }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
      </b-row>
      <b-row>
        <!--Patent Inventor--> 
          <b-col>
            <b-card :title=" $t('patentBulletin.bulletin_patent_inventor_title') ">
              <b-list-group  v-for="(nameAddress, index) in patentInventorNameAndAddress" :key="nameAddress.id">
                <b-list-group-item class="flex-column align-items-start">
                  <b-row>
                    <b-col>
                      <b-card-text>
                        {{ nameAddress.name }}
                        <br>
                        {{ nameAddress.address }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
          <!--TPMK Patent Inventor--> 
          <b-col>
            <b-card :title=" $t('patentBulletin.tpmk_bulletin_patent_inventor_title') ">
              <b-list-group v-for="(nameAddress, index) in tpmkPatentInventorNameAndAddress" :key="nameAddress.id">
                <b-list-group-item class="flex-column align-items-start">
                  <b-row>
                    <b-col>
                      <b-card-text>
                        {{ nameAddress.name }}
                        <br>
                        {{ nameAddress.address }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
      </b-row>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getPatentBulletinCheckList: {type: Function},
    },
    data() {
      return {
        id: 0,
        applicationNo: '',
        applicationDate: null,
        recordNo: '',
        recordDate: null,
        bulletinNo: 0,
        bulletinDate: 0,
        tpmkApplicationNo: '',
        tpmkApplicationDate: null,
        tpmkRecordNo: '',
        tpmkRecordDate: null,
        tpmkBulletinNo: 0,
        tpmkBulletinDate: 0,
        tpmkAttorney: '',
        patentBulletinNameAndAddress: [],
        tpmkPatentBulletinNameAndAddress: [],
        patentOwnerNameAndAddress: [],
        tpmkPatentOwnerNameAndAddress: [],
        patentInventorList: [],
        tpmkPatentInventorList: [],
        patentInventorNameAndAddress: [],
        tpmkPatentInventorNameAndAddress: [],
        no: 0,
        productName: '',
        productNameEn: '',
        locarnoCodes: '',
        designImages: [],
        tpmkNo: 0,
        tpmkProductName: '',
        tpmkProductNameEn: '',
        tpmkLocarnoCodes: '',
        tpmkImages: [],
        searchTerm: '',
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['patentBulletinFormModal'].show()
        this.rows = []
        this.tpmkRows = []
        this.id = id
        const data = { id: this.id }
        const controller = { name: 'Bulletin', actionName: 'GetPatentBulletinInfoByPatentId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
                  this.tpmkImages = []
                  this.designImages = []
                  //Patent
                  this.applicationNo = response.data.patent.applicationNo
                  this.applicationDate = response.data.patent.applicationDate
                  // this.recordNo = response.data.patent.dateOfGrant
                  this.recordDate = response.data.patent.dateOfGrant
                  this.dateOfGrant = response.data.patent.dateOfGrant
                  this.bulletinNo = response.data.patent.bulletinNo
                  this.bulletinDate = response.data.patent.bulletinDate
                  this.tpmkApplicationNo = response.data.tpmkPatent.applicationNo
                  this.tpmkApplicationDate = response.data.tpmkPatent.applicationDate
                  this.tpmkRecordNo = response.data.tpmkPatent.registerNo
                  this.tpmkRecordDate = response.data.tpmkPatent.registerDate
                  this.tpmkBulletinNo = response.data.tpmkPatent.bulletinNo
                  this.tpmkBulletinDate = response.data.tpmkPatent.bulletinDate
                  this.tpmkAttorney = response.data.tpmkPatent.attorney

                  //Priority
                  this.patentBulletinNameAndAddress = response.data.patentPriorityList
                  this.tpmkPatentBulletinNameAndAddress = response.data.tpmkPatentPriorityList

                  //Owner
                  this.patentOwnerNameAndAddress = response.data.patentOwnerList
                  this.tpmkPatentOwnerNameAndAddress = response.data.tmpkPatentHolderList

                  //Detail
                  this.patentInventorNameAndAddress = response.data.patentInventorList
                  this.tpmkPatentInventorNameAndAddress = response.data.tpmkPatentInventorList
        }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  [dir] .dark-layout .card {
    background-color: #1c2333!important;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.24)!important;
}
.tableSize {
  font-size: 13px!important;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}
  </style>
  