var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"lexologyFormModal",attrs:{"id":"lexologyFormModal","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('lexology.lexology_info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_c('vue-good-table',{ref:"lexologyFormTable",attrs:{"id":"lexologyFormTable","styleClass":"vgt-table condensed tableSize","columns":_vm.columns,"rows":_vm.rows,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm
      },"select-options":{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },"pagination-options":{
        enabled: false,
        perPage: _vm.pageLength
      },"theme":"my-theme"},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field === 'type')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('lexology.type'))+" ")]):(props.column.field === 'organisation')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('lexology.organisation'))+" ")]):(props.column.field === 'totalReads')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('lexology.total_reads'))+" ")]):(props.column.field === 'totalInteractions')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('lexology.total_interactions'))+" ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'type')?_c('span',[_c('b-col',[_c('b-row',{staticStyle:{"font-size":"small"}},[_c('tr',[_c('span',[_vm._v(_vm._s(props.row.type)+" ")]),_vm._v(" "),_c('br')])])],1)],1):(props.column.field === 'organisation')?_c('span',[_c('b-col',[_c('b-row',{staticStyle:{"font-size":"small"}},[_c('tr',[_c('span',[_vm._v(_vm._s(props.row.organisation)+" ")]),_vm._v(" "),_c('br')])])],1)],1):(props.column.field === 'totalReads')?_c('span',[_c('b-col',[_c('b-row',{staticStyle:{"font-size":"small"}},[_c('tr',[_c('span',[_vm._v(_vm._s(props.row.totalReads)+" ")]),_vm._v(" "),_c('br')])])],1)],1):(props.column.field === 'organisation')?_c('span',[_c('b-col',[_c('b-row',{staticStyle:{"font-size":"small"}},[_c('tr',[_c('span',[_vm._v(_vm._s(props.row.totalInteractions)+" ")]),_vm._v(" "),_c('br')])])],1)],1):_vm._e()]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.data_not_found'))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }