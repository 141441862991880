<template>
<div>
    <!-- modal -->
    <b-modal id="priceListsFormModal" centered size="xl" no-close-on-backdrop ref="priceListsFormModal" :title=" $t('priceList.price_list_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @close="clear()" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-md-4 mb-1 mt-1">
                    <label for="priceList-name-input">{{ $t('priceList.name') }}</label>
                    <validation-provider #default="{ errors }" :name=" $t('priceList.name')" rules="required">
                        <b-form-input size="sm" id="priceList-name-input" v-model="priceListName" maxlength="100" :state="errors.length > 0 ? false:null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
                <b-col class="col-md-4 mb-1 mt-1">
                    <label for="priceList-year-input">{{ $t('priceList.year') }}</label>
                    <validation-provider #default="{ errors }" :name=" $t('priceList.year')" rules="required">
                        <b-form-input size="sm" id="priceList-year-input" v-model="priceListYear" oninput="javascript: if (this.value.length > 4) this.value = this.value.slice(0, 4);" onKeyPress="if(this.value.length==4) return false" type="number" :state="errors.length > 0 ? false:null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
                <b-col class="col-md-4 mb-1 mt-1">
                    <label for="priceList-basePriceList-input">{{ $t('priceList.base_price_list') }}</label>
                    <validation-provider :name=" $t('priceList.base_price_list')" :rules="{ excluded:0}" v-slot="{ errors }">
                        <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="basePriceListSelect" value-field="id" text-field="name" :options="basePriceListOption" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-4 mb-1 mt-1">
                    <b-row>
                        <b-col class="col-md-4">
                            <!-- İs Main List  -->
                            <label for="country-isMainList-input">{{ $t('priceList.is_main_list') }}</label>
                            <br>
                            <b-form-checkbox class="custom-control-success" name="country-isMainList-input" id="country-isMainList-input" v-model="isMainList" switch>
                                <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                </span>
                                <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                </span>
                            </b-form-checkbox>
                        </b-col>
                        <b-col class="col-md-4">
                            <!-- İs Approved  -->
                            <label for="country-isApproved-input">{{ $t('priceList.is_approved') }}</label>
                            <br>
                            <b-form-checkbox class="custom-control-success" name="country-isApproved-input" id="country-isApproved-input" v-model="isApproved" switch>
                                <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                </span>
                                <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                </span>
                            </b-form-checkbox>
                        </b-col>
                        <b-col class="col-md-4">
                            <!-- İs Passive  -->
                            <label for="country-isPassive-input">{{ $t('priceList.is_passive') }}</label>
                            <br>
                            <b-form-checkbox class="custom-control-success" name="country-isPassive-input" id="country-isPassive-input" v-model="isPassive" switch>
                                <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                </span>
                                <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                </span>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-4 mb-1 mt-1">
                    <label for="priceList-country-input">{{ $t('priceList.country') }}</label>
                    <validation-provider :name=" $t('priceList.country')" :rules="{ excluded:0}" v-slot="{ errors }">
                        <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="countrySelect" value-field="id" text-field="name" :options="countryOption" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12">
                    <label for="priceList-note-input">{{ $t('priceList.note') }}</label>
                    <validation-provider #default="{ errors }" :name=" $t('priceList.note')">
                        <b-form-textarea size="sm" id="priceList-note-input" v-model="note" rows="3" max-rows="8" maxlength="250" :state="errors.length > 0 ? false:null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
        </validation-observer>
        <b-card :hidden="hiddenControlForNewRecords" class="mt-1">
            <b-row>
                <b-col>
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title=" $t('priceList.details')" active style="width:100%;">
                            <b-card-text>
                                <priceList-detail-list :priceListId="this.id"></priceList-detail-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('priceList.using_agents')" style="width:100%;">
                            <b-card-text>
                                <priceList-agents-list :priceListId="this.id"></priceList-agents-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getPriceListList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            priceListName: '',
            priceListYear: 0,
            basePriceListSelect: 0,
            basePriceListOption: [],
            note: '',
            isMainList: false,
            isPassive: false,
            isApproved: false,
            hiddenControlForNewRecords: true,
            countryOption: [],
            countrySelect: 0,
        }
    },
    methods: {
        clear() {
            this.basePriceListSelect = 0
        },
        showInfo(id) {
            this.$refs['priceListsFormModal'].show()
            this.id = id
            if (this.id == 0) {
                this.hiddenControlForNewRecords = true
            } else {
                this.hiddenControlForNewRecords = false
            }
            const data = {
                id: this.id
            }
            const controller = {
                name: 'PriceList'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.priceListName = response.data.priceList.name
                    this.priceListYear = response.data.priceList.year
                    this.note = response.data.priceList.note
                    this.basePriceListOption = response.data.basePriceList
                    this.basePriceListSelect = response.data.priceList.basePriceListId
                    this.isMainList = response.data.priceList.isMainList
                    this.isApproved = response.data.priceList.isApproved
                    this.isPassive = response.data.priceList.isPassive
                    if (response.data.priceList.countryId > 0) {
                        this.countrySelect = response.data.priceList.countryId
                    }
                    this.basePriceListOption.splice(0, 0, this.$nullSelected())
                    this.countryOption = response.data.countryList
                    this.countryOption.splice(0, 0, this.$nullSelected())

                } else {
                    this.$bvModal.hide('priceListsFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.priceListName,
                year: this.priceListYear,
                basePriceListId: this.basePriceListSelect,
                isMainList: this.isMainList,
                isPassive: this.isPassive,
                isApproved: this.isApproved,
                note: this.note,
                countryId: this.countrySelect
            }
            const controller = {
                name: 'PriceList'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('priceListsFormModal')
                            this.getPriceListList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
