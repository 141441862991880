<template>
<div>
    <!-- modal -->
    <b-modal id="jobStatusFormModal" centered size="lg" no-close-on-backdrop ref="jobStatusFormModal" :title=" $t('jobStatus.job_status_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="col-md-12">
                        <label for="job-status-name-input">{{ $t('jobStatus.job_status_name') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('jobStatus.job_status_name')" rules="required">
                                <b-form-input id="job_status-name-input" v-model="jobStatusName" maxlength="50" size="sm" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12">
                        <b-form>
                            <label for="job-status-service-type-input">{{ $t('jobStatus.service_type') }}</label>
                            <validation-provider :name=" $t('jobStatus.service_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <b-form-select :state="errors.length > 0 ? false:null" size="sm" v-model="serviceTypeSelect" value-field="id" text-field="name" :options="serviceTypeOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <b-form>
                            <label for="job-status-path-input">{{ $t('jobStatus.path') }}</label>
                            <validation-provider :name=" $t('jobStatus.path')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <b-form-select :state="errors.length > 0 ? false:null" size="sm" v-model="jobStatusPathSelect" value-field="id" text-field="name" :options="jobStatusPathOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getJobStatusList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            jobStatusName: '',
            serviceTypeOption: [],
            serviceTypeSelect: 0,
            jobStatusPathOption: [],
            jobStatusPathSelect: 0,
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['jobStatusFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'JobStatus'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.jobStatusName = response.data.jobStatus.name
                    this.serviceTypeOption = response.data.serviceTypeList
                    this.serviceTypeSelect = response.data.jobStatus.serviceTypeId
                    this.jobStatusPathSelect = response.data.jobStatus.jobStatusPathId
                    this.jobStatusPathOption = response.data.jobStatusPathList
                    this.jobStatusPathOption.splice(0, 0, this.$nullSelected())
                    this.serviceTypeOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('jobStatusFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.jobStatusName,
                serviceTypeId: this.serviceTypeSelect,
                jobStatusPathId: this.jobStatusPathSelect
            }
            const controller = {
                name: 'JobStatus'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('jobStatusFormModal')
                            this.getJobStatusList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
