var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('job-update-note-form',{ref:"jobUpdateNoteForm",attrs:{"recordId":this.recordId,"getJobDetailList":_vm.getJobDetailList}}),_c('b-modal',{ref:"jobDetailFormModal",attrs:{"id":"jobDetailFormModal","centered":"","size":"xl","no-close-on-backdrop":"","title":_vm.$t('jobDetail.info') + '-' + _vm.recordNo,"hide-footer":""}},[_c('b-row',[_c('b-col',{staticClass:"col-12"},[_c('div',{staticClass:"custom-search d-flex justify-content-end",staticStyle:{"float":"left"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"size":"sm","placeholder":_vm.$t('others.search_name'),"type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1)])],1),_c('vue-good-table',{ref:"jobDetailTable",attrs:{"id":"jobDetailTable","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table condensed tableSize","search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm
        },"select-options":{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        },"pagination-options":{
          enabled: false,
          perPage: _vm.pageLength
        },"theme":"my-theme"},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field === 'jobStatus')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('jobDetail.job_status'))+" ")]):(props.column.field === 'date')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('jobDetail.date'))+" ")]):(props.column.field === 'note')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('jobDetail.note'))+" ")]):(props.column.field === 'nextJobDeadline')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('jobDetail.next_job_deadline'))+" ")]):(props.column.field === 'tpmkDeadline')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('jobDetail.tpmk_deadline'))+" ")]):_vm._e(),(props.column.field === 'employee')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('jobDetail.employee'))+" ")]):(props.column.field === 'process')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('others.process'))+" ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'process')?_c('span',[(props.row.isNoteEditable == true)?_c('span',[_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",modifiers:{"html":true}},{name:"b-modal",rawName:"v-b-modal.modal-top",modifiers:{"modal-top":true}}],staticClass:"btn-icon mb-1 ml-1",attrs:{"title":_vm.$t('others.note'),"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.getJobNoteInfo(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)],1)]):_vm._e()]):_vm._e()]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.data_not_found'))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }