<template>
    <div>
        <!-- modal -->
        <b-modal 
            id="designDesignerFormModal" 
            centered size="lg" 
            no-close-on-backdrop ref="designDesignerFormModal"
            :title="$t('designDesigner.designer_info')" 
            :cancel-title="$t('others.cancel_title')"
            :ok-title="$t('others.ok_title')" 
            cancel-variant="outline-secondary" @close="clear()"
            @ok.prevent="addOrUpdate"
        >
            <validation-observer ref="infoRules" tag="form">
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <b-form>
                            <label for="designDesigner-Name-input">{{ $t('designDesigner.name') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('designDesigner.name')"
                                    rules="required">
                                    <b-form-input id="designDesigner-Name-input" v-model="designDesignerName"
                                        maxlength="50" :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6 mt-1">
                        <b-form>
                            <validation-provider :name="$t('designDesigner.country')" #default="{ errors }"
                                :rules="{ excluded: 0 }">
                                <label for="country-input">{{ $t('designDesigner.country') }}</label>
                                <b-form-select v-model="countrySelect" value-field="id" text-field="name"
                                    :options="countryOption" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-6 mt-1">
                        <b-form>
                            <label for="nationality-input">{{ $t('designDesigner.nationality') }}</label>
                            <b-form-select v-model="nationalitySelect" value-field="id" text-field="name"
                                :options="nationalityOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <label for="textarea-default">{{ $t('designDesigner.address') }}</label>
                        <validation-provider #default="{ errors }" :name="$t('designDesigner.address')"
                            rules="required">
                            <b-form-textarea id="textarea-default" :placeholder="$t('designDesigner.address')"
                                v-model="designerAddress" rows="2" maxlength="250"
                                :state="errors.length > 0 ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6 mt-1">
                        <b-form>
                            <label for="designPriorityRight-input">{{
                                $t('designDesigner.design_priority_right')
                            }}</label>
                            <b-form-select v-model="designPriorityRightSelect" value-field="id" text-field="name"
                                :options="designPriorityRightOption" />

                        </b-form>
                    </b-col>
                    <b-col class="col-md-6 mt-1">
                        <b-form>
                            <label for="isPrivacyRequest-input">{{ $t('designDesigner.is_privacy_request') }}</label>
                            <b-form-checkbox v-model="isPrivacyRequest" name="checkbox-validation">
                            </b-form-checkbox>
                        </b-form>
                    </b-col>
                </b-row>

            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        getDesignDesignerList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            designId:0,
            designDesignerName: '',
            designerAddress: '',
            countryOption: [],
            countrySelect: 0,
            nationalityOption: [],
            nationalitySelect: 0,
            isPrivacyRequest: false,
            designPriorityRightOption: [],
            designPriorityRightSelect: 0,
        }
    },
    methods: {
        showInfo(id, designId) {
            this.$refs['designDesignerFormModal'].show()
            this.id= id
            this.designId= designId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'DesignDesigner'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.isPrivacyRequest = response.data.designDesigner.isPrivacyRequest
                    this.designDesignerName = response.data.designDesigner.name
                    this.designerAddress = response.data.designDesigner.address

                    this.countrySelect = response.data.designDesigner.countryId
                    this.countryOption = response.data.countryList
                    this.countryOption.splice(0, 0, this.$nullSelected())

                    this.designPriorityRightSelect = response.data.designDesigner.designPriorityRightId
                    this.designPriorityRightOption = response.data.designPriorityRightList
                    this.designPriorityRightOption.splice(0, 0, this.$nullSelected())

                    this.nationalitySelect = response.data.designDesigner.nationalityId
                    this.nationalityOption = response.data.countryList
                } else {
                    this.$bvModal.hide('designDesignerFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
                this.countrySelect = 0,
                this.designerAddress = null,
                this.designDesignerName = null,
                this.nationalitySelect = 0,
                this.designPriorityRightSelect = 0
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                designId: this.designId,
                name: this.designDesignerName,
                address: this.designerAddress,
                countryId: this.countrySelect,
                nationalityId: this.nationalitySelect,
                designPriorityRightId: this.designPriorityRightSelect,
                isPrivacyRequest: this.isPrivacyRequest

            }
            const controller = {
                name: 'DesignDesigner'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('designDesignerFormModal')
                            this.getDesignDesignerList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        }
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
