<template>
<div>
    <!-- modal -->
    <b-modal id="nationalTrademarkCountryFormModal" centered size="xl" no-close-on-backdrop ref="nationalTrademarkCountryFormModal" :title=" this.transportToCountryName" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="infoRules" tag="form">
            <b-row>
                <b-col class="col-lg-12">
                    <b-form>
                        <validation-provider :name=" $t('nationalTrademarkCountry.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <b-form-group :label=" $t('nationalTrademarkCountry.agent')" label-for="nationalTrademarkCountryAgent" :state="errors.length > 0 ? false:null">
                                <v-select id="nationalTrademarkCountryAgent" v-model="nationalTrademarkCountryAgentSelect" :options="nationalTrademarkCountryAgentOption" @input="setClientId" label="name" @search="onSearch">
                                </v-select>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="national-trademark-country-agent-ref-code-input">{{ $t('nationalTrademarkCountry.national_trademark_country_agent_ref_code') }}</label>
                        <b-form-group>
                            <b-form-input id="national-trademark-country-agent-ref-code-input" size="sm" v-model="agentReferenceNo" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="national-trademark-application-no-input">{{ $t('nationalTrademark.application_no') }}</label>
                        <b-form-group>
                            <b-form-input id="national-trademark-application-no-input" size="sm" v-model="applicationNo" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <label for="national-trademark-application-date-input">{{ $t('nationalTrademark.application_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="applicationDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-lg-3">
                    <label for="national-trademar-additional-country-application">{{ $t('nationalTrademarkCountry.additional_country_application') }}</label>
                    <b-form-checkbox v-model="additionalCountryApplication" :state="state" name="checkbox-validation">
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="national-trademark-bulletin-no-input">{{ $t('nationalTrademark.bulletin_no') }}</label>
                        <b-form-group>
                            <b-form-input id="national-trademark-bulletin-no-input" size="sm" v-model="bulletinNo" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <label for="national-trademark-bulletin-date-input">{{ $t('nationalTrademark.bulletin_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="bulletinDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="national-trademark-register-no-input">{{ $t('nationalTrademark.register_no') }}</label>
                        <b-form-group>
                            <b-form-input id="national-trademark-register-no-input" size="sm" v-model="registerNo" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <label for="national-trademark-register-date-input">{{ $t('nationalTrademark.register_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="registerDate"></CustomDatePicker>
                </b-col>
            </b-row>
            <b-card :hidden="hiddenControl">
                <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                    <b-row>
                        <b-col class="mt-2">
                            <b-tab :title=" $t('nationalTrademark.nice_class')" style="width:100%;">
                                <b-card-text>
                                    <national-trademark-country-nice-class-list :getNationalTrademarkList="getNationalTrademarkList" :nationalTrademarkCountryId="this.id"></national-trademark-country-nice-class-list>
                                </b-card-text>
                            </b-tab>
                        </b-col>
                    </b-row>
                </b-tabs>
            </b-card>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        nationalTrademarkId: Number,
        getNationalTrademarkCountryList: {
            type: Function
        },
        getNationalTrademarkList: {
            type: Function
        },
        transportToCountryName: String
    },
    data() {
        return {
            id: 0,
            nationalTrademarkCountryAgentSelect: 0,
            nationalTrademarkCountryAgentOption: [],
            agentReferenceNo: '',
            applicationNo: '',
            applicationDate: null,
            bulletinDate: null,
            registerDate: null,
            bulletinNo: '',
            registerNo: '',
            hiddenControl: true,
            additionalCountryApplication: false,
            state: false
        }
    },
    methods: {
        async getAgentNoteInfo(clientId) {
            this.$refs.agentNoteFormModal.getNoteListById(clientId)
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        setClientId(event) {
            this.clientId = event.id
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientId: 0,
                    name: search
                }
                const controller = {
                    name: 'Client',
                    actionName: 'GetAgentClientByName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.nationalTrademarkCountryAgentOption = response.data.clientList
                        loading(false)
                    } else {
                        this.nationalTrademarkCountryAgentOption = []
                        this.nationalTrademarkCountryAgentSelect = 0
                        this.clientId = 0
                    }

                })
            }
        },
        showInfo(id) {
            this.$refs['nationalTrademarkCountryFormModal'].show()
            this.id = id
            if (this.id == 0) {
                this.hiddenControl = true
            } else {
                this.hiddenControl = false
            }
            const data = {
                id: this.id
            }
            const controller = {
                name: 'NationalTrademarkCountry'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.agentReferenceNo = response.data.nationalTrademarkCountry.agentReferenceNo
                    this.applicationNo = response.data.nationalTrademarkCountry.applicationNo
                    this.applicationDate = response.data.nationalTrademarkCountry.applicationDate
                    this.bulletinNo = response.data.nationalTrademarkCountry.bulletinNo
                    this.bulletinDate = response.data.nationalTrademarkCountry.bulletinDate
                    this.registerNo = response.data.nationalTrademarkCountry.registerNo
                    this.registerDate = response.data.nationalTrademarkCountry.registerDate
                    this.additionalCountryApplication = response.data.nationalTrademarkCountry.additionalCountryApplication
                    if (response.data.nationalTrademarkCountry.clientId > 0) {
                        const clientData = {
                            clientId: response.data.nationalTrademarkCountry.clientId
                        }
                        const clientController = {
                            name: 'Client',
                            actionName: 'GetAgentClientByName'
                        }
                        const clientPayload = {
                            data: clientData,
                            controller: clientController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientPayload).then((clientResponse) => {
                            this.nationalTrademarkCountryAgentOption = clientResponse.data.clientList
                            this.nationalTrademarkCountryAgentSelect = clientResponse.data.clientList[0]
                            this.clientId = clientResponse.data.clientList[0].id
                        })
                    } else {
                        this.nationalTrademarkCountryAgentOption = []
                        this.nationalTrademarkCountryAgentSelect = 0
                        this.clientId = 0
                    }
                } else {
                    this.$bvModal.hide('nationalTrademarkCountryFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                agentReferenceNo: this.agentReferenceNo,
                clientId: this.clientId,
                applicationNo: this.applicationNo,
                applicationDate: this.applicationDate,
                bulletinNo: this.bulletinNo,
                bulletinDate: this.bulletinDate,
                registerNo: this.registerNo,
                registerDate: this.registerDate,
                additionalCountryApplication: this.additionalCountryApplication
            }
            const controller = {
                name: 'NationalTrademarkCountry'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.getNationalTrademarkList()
                    this.getNationalTrademarkCountryList()
                    this.$SaveAlert()
                    this.$bvModal.hide('nationalTrademarkCountryFormModal')
                    this.applicationNo = '',
                        this.applicationDate = null,
                        this.bulletinNo = '',
                        this.bulletinDate = null,
                        this.registerNo = '',
                        this.registerDate = null

                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
