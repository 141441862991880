<template>
    <div>
      <!-- modal -->
      <b-modal
        id="officialFeeFormModal"
        centered
        size="xl"
        no-close-on-backdrop
        ref="officialFeeFormModal"
        :title=" $t('officialFee.info')"
        hide-footer
      >
        <b-row>
            <b-col class="col-lg-4">
                <b-form-group>
                    <validation-provider :name="$t('officialFee.currency')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                        <label>{{ $t('officialFee.currency')  }}</label>
                        <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="currencySelect" value-field="id" text-field="name" :options="currencyOption" />
                        <small class="text-danger">{{ errors[0]  }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
        <b-card v-if="this.currencySelect == 4">
            <b-row>
                <b-col class="col-lg-3">
                    <label for="official-fee-receipt-date-input">{{ $t('officialFee.receipt_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="receiptDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-lg-3">
                    <label for="official-fee-receipt-no-input">{{ $t('officialFee.receipt_no') }}</label>
                    <b-form-input size="sm" id="official-fee-receipt-no-input" :placeholder="$t('officialFee.receipt_no')" v-model="receiptNo" />
                </b-col>
                <b-col class="col-lg-3">
                    <label for="official-fee-date-input">{{ $t('officialFee.date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                </b-col>
                <b-col class="col-lg-3">
                    <label>{{ $t('officialFee.official_fee_type')  }}</label>
                    <b-form-select size="sm" v-model="officialFeeTypeSelect" value-field="id" text-field="name" :options="officialFeeTypeOption" />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3">
                    <label for="official-fee-application-no-input">{{ $t('officialFee.application_no') }}</label>
                    <b-form-input size="sm" id="official-fee-application-no-input" :placeholder="$t('officialFee.application_no')" v-model="applicationNo" />
                </b-col>
                <b-col class="col-lg-3">
                    <label for="official-fee-name-input">{{ $t('officialFee.name') }}</label>
                    <b-form-input size="sm" id="official-fee-name-input" :placeholder="$t('officialFee.name')" v-model="name" />
                </b-col>
                <b-col class="col-lg-3">
                    <label for="official-fee-record-no-input">{{ $t('officialFee.record_no') }}</label>
                    <b-form-input size="sm" id="official-fee-record-no-input" :placeholder="$t('officialFee.record_no')" v-model="recordNo" />
                </b-col>
                <b-col class="col-lg-3">
                    <label for="official-fee-price-input">{{ $t('officialFee.price') }}</label>
                    <b-form-input size="sm" id="official-fee-price-input" :placeholder="$t('officialFee.price')" v-model="price" />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3 mt-1">
                    <label for="official-fee-official-fee-input">{{ $t('officialFee.official_fee') }}</label>
                    <b-form-input size="sm" id="official-fee-official-fee-input" :placeholder="$t('officialFee.official_fee')" v-model="officialFee" disabled />
                </b-col>
                <b-col class="col-lg-3 mt-1">
                    <label for="official-fee-tpmk-base-official-fee-input">{{ $t('officialFee.tpmk_base_official_fee') }}</label>
                    <b-form-input size="sm" id="official-fee-tpmk-base-official-fee-input" :placeholder="$t('officialFee.tpmk_base_official_fee')" v-model="tpmkBaseOfficialFee" disabled />
                </b-col>
                <b-col class="col-lg-3 mt-1">
                    <label for="official-fee-tpmk-base-service-fee-input">{{ $t('officialFee.tpmk_base_service_fee') }}</label>
                    <b-form-input size="sm" id="official-fee-tpmk-base-service-fee-input" :placeholder="$t('officialFee.tpmk_base_service_fee')" v-model="tpmkBaseServiceFee" disabled />
                </b-col>
                <b-col class="col-lg-3 mt-1">
                    <label for="official-fee-tpmk-base-service-vat-input">{{ $t('officialFee.tpmk_base_service_vat') }}</label>
                    <b-form-input size="sm" id="official-fee-tpmk-base-service-vat-input" :placeholder="$t('officialFee.tpmk_base_service_vat')" v-model="tpmkBaseServiceVat" disabled />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3 mt-1">
                    <label for="official-fee-name-input">{{ $t('officialFee.name') }}</label>
                    <b-form-input size="sm" id="official-fee-name-input" :placeholder="$t('officialFee.name')" v-model="employeeName" disabled />
                </b-col>
                <b-col class="col-lg-3 mt-1">
                    <label for="official-fee-refund-status-input">{{ $t('officialFee.official_fee_refund_status') }}</label>
                    <b-form-select size="sm" v-model="officialFeeRefundStatusSelect" value-field="id" text-field="name" :options="officialFeeRefundStatusOption" />
                </b-col>
            </b-row>
        </b-card>
        <b-card v-if="this.currencySelect != 4">
            <b-row>
                <b-col class="col-lg-3">
                    <label>{{ $t('officialFee.official_fee_type')  }}</label>
                    <b-form-select size="sm" v-model="officialFeeTypeSelect" value-field="id" text-field="name" :options="officialFeeTypeOption" />
                </b-col>
                <b-col class="col-lg-3">
                    <label>{{ $t('officialFee.official_fee_owner_type')  }}</label>
                    <b-form-select size="sm" v-model="officialFeeOwnerTypeSelect" value-field="id" text-field="name" :options="officialFeeOwnerTypeOption" />
                </b-col>
                <b-col class="col-lg-3">
                    <label for="official-fee-receipt-no-input">{{ $t('officialFee.receipt_no') }}</label>
                    <b-form-input size="sm" id="official-fee-receipt-no-input" :placeholder="$t('officialFee.receipt_no')" v-model="receiptNo" />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3">
                    <label for="official-fee-price-input">{{ $t('officialFee.price') }}</label>
                    <b-form-input size="sm" id="official-fee-price-input" :placeholder="$t('officialFee.price')" v-model="price" />
                </b-col>
                <b-col class="col-lg-3">
                    <label for="official-fee-invoice-date-input">{{ $t('officialFee.invoice_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="invoiceDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-lg-3">
                    <label for="official-fee-invoice-no-input">{{ $t('officialFee.invoice_no') }}</label>
                    <b-form-input size="sm" id="official-fee-invoice-no-input" :placeholder="$t('officialFee.invoice_no')" v-model="invoiceNo" />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3">
                    <label for="official-fee-application-no-input">{{ $t('officialFee.application_no') }}</label>
                    <b-form-input size="sm" id="official-fee-application-no-input" :placeholder="$t('officialFee.application_no')" v-model="applicationNo" />
                </b-col>
                <b-col class="col-lg-3">
                    <label for="official-fee-name-input">{{ $t('officialFee.name') }}</label>
                    <b-form-input size="sm" id="official-fee-name-input" :placeholder="$t('officialFee.name')" v-model="name" />
                </b-col>
                <b-col class="col-lg-3">
                    <label for="official-fee-record-no-input">{{ $t('officialFee.record_no') }}</label>
                    <b-form-input size="sm" id="official-fee-record-no-input" :placeholder="$t('officialFee.record_no')" v-model="recordNo" />
                </b-col>
                <b-col class="col-lg-3">
                    <label for="official-fee-refund-status-input">{{ $t('officialFee.official_fee_refund_status') }}</label>
                    <b-form-select size="sm" v-model="officialFeeRefundStatusSelect" value-field="id" text-field="name" :options="officialFeeRefundStatusOption" />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12">
                    <label class="mt-2" for="official-fee-note-input">{{ $t('officialFee.note') }}</label>
                    <b-form-textarea id="official-fee-note-input" :placeholder=" $t('officialFee.note')" rows="3" v-model="note" />
                </b-col>
            </b-row>
        </b-card>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getOfficialFeeList: {type: Function},
    },
    data() {
      return {
        id: 0,
        currencySelect: 0,
        currencyOption: [],
        receiptDate: null,
        date: null,
        invoiceDate: null,
        receiptNo: '',
        officialFeeTypeSelect: 0,
        officialFeeTypeOption: [],
        applicationNo: '',
        name: '',
        recordNo: '',
        price: '',
        officialFee: '',
        tpmkBaseOfficialFee: '',
        tpmkBaseServiceFee: '',
        tpmkBaseServiceVat: '',
        employeeName: '',
        officialFeeRefundStatusSelect: 0,
        officialFeeRefundStatusOption: [],
        officialFeeOwnerTypeSelect: 0,
        officialFeeOwnerTypeOption: [],
        invoiceNo: '',
        note: '',
      }
    },
    methods: {
      showInfo(id) { 
        this.$refs['officialFeeFormModal'].show() 
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'OfficialFee'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.currencyOption = response.data.currencyList
                if(this.id == 0){
                    this.currencySelect = 4
                } else{
                    this.currencySelect = response.data.officialFee.currencyId
                }
                if(this.currencySelect == 4){
                    this.officialFeeRefundStatusOption = response.data.officialFeeRefundStatusList
                    this.officialFeeRefundStatusSelect = response.data.officialFee.officialFeeRefundStatusId
                    this.employeeName = response.data.officialFee.employee
                    this.tpmkBaseServiceVat = response.data.officialFee.tpmkBaseServiceVat
                    this.tpmkBaseServiceFee = response.data.officialFee.tpmkBaseServiceFee
                    this.tpmkBaseOfficialFee = response.data.officialFee.tpmkBaseOfficialFee
                    this.officialFee = response.data.officialFee.officialFeeValue
                    this.price = response.data.officialFee.price
                    this.recordNo = response.data.officialFee.operationRecordNo
                    this.name = response.data.officialFee.name
                    this.applicationNo = response.data.officialFee.applicationNo
                    this.officialFeeTypeOption = response.data.officialFeeTypeList
                    this.officialFeeTypeSelect = response.data.officialFee.officialFeeTypeId
                    this.date = response.data.officialFee.date
                    this.receiptNo = response.data.officialFee.receiptNo
                    this.receiptDate =  response.data.officialFee.receiptDate
                    this.officialFeeRefundStatusOption.splice(0, 0, this.$nullSelected('name'))
                    this.officialFeeTypeOption.splice(0, 0, this.$nullSelected('name'))
                } else{
                    this.receiptDate =  response.data.officialFee.receiptDate
                    this.officialFeeTypeOption = response.data.officialFeeTypeList
                    this.officialFeeTypeSelect = response.data.officialFee.officialFeeTypeId
                    this.officialFeeOwnerTypeOption = response.data.officialFeeOwnerTypeList
                    this.officialFeeOwnerTypeSelect = response.data.officialFee.officialFeeOwnerTypeId
                    this.receiptNo = response.data.officialFee.receiptNo
                    this.date = response.data.officialFee.date
                    this.price = response.data.officialFee.price
                    this.invoiceDate = response.data.officialFee.invoiceDate
                    this.invoiceNo = response.data.officialFee.invoiceNo
                    this.applicationNo = response.data.officialFee.applicationNo
                    this.name = response.data.officialFee.name
                    this.recordNo = response.data.officialFee.operationRecordNo
                    this.officialFeeRefundStatusOption = response.data.officialFeeRefundStatusList
                    this.note = response.data.officialFee.note
                    this.officialFeeRefundStatusOption.splice(0, 0, this.$nullSelected('name'))
                    this.officialFeeOwnerTypeOption.splice(0, 0, this.$nullSelected('name'))
                    this.officialFeeTypeOption.splice(0, 0, this.$nullSelected('name'))
                }
            } else {
                this.$bvModal.hide('officialFeeFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  .datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
  </style>
  