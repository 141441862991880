<template>
    <div>
      <b-row class="align-items-center">

      </b-row>
      <b-row class="align-items-center">
        <b-col class="col-lg-5">
          <label>{{ $t('statement.agent') }}</label>
          <v-select id="agent" v-model="agentSelect" :options="agentOption" @input="setClientId" label="clientName" @search="onSearch">
          </v-select>
        </b-col>

            <b-col class="col-lg-2 mt-1">
              <label for="statement-start-date-input">{{ $t('statement.startDate') }}</label>
              <CustomDatePicker :datePickerValidateOption="0" v-model="startDate"></CustomDatePicker>
            </b-col>

            <b-col class="col-lg-2 mt-1">
              <label for="statement-end-date-input">{{ $t('statement.endDate') }}</label>
              <CustomDatePicker :datePickerValidateOption="0" v-model="endDate"></CustomDatePicker>
            </b-col>
        <b-col class="d-flex justify-content-end col-lg-3 mt-2">
          <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getStatementList()">{{$t('statement.list')}}</b-button>       
          <b-button style="float:right;"   size="sm" variant="outline-primary" @click="exportExcel()">{{$t('excel.excel') }}</b-button>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table id="statementTable" ref="statementTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{ enabled: true, externalQuery: searchTerm }" 
      :select-options="{ enabled: false, selectOnCheckboxOnly: true, selectionInfoClass: 'custom-class', selectionText: 'rows selected', clearSelectionText: 'clear', disableSelectInfo: true, selectAllByGroup: true}" 
      :pagination-options="{ enabled: true, perPage: pageLength}" theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('statement.extre')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-1 ml-1" size="sm" @click="downloadExtre(props.row.clientAccountingId)">
                <feather-icon icon="ListIcon" />
              </b-button>
            </span>
          </span>
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                  <b-form-select size="sm" v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                  <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
              </div>
              <div>
                  <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getStatementList()">
                      <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                  </b-pagination>
              </div>
          </div>
      </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        countryOption: [],
        agentSelect: 0,
        agentOption: [],   
        clientAccountingId:0,
        total: 0,
        pageLength: 50,
        dir: false,            
        pageNo: 1,
        number: '',
        startDate: null,
        endDate: null,
        columns: [
          {
            label: this.$t('statement.date'),
            field: `date`,
          },
          {
            label: this.$t('statement.description'),
            field: `description`,
          },
          {
            label: this.$t('statement.amount'),
            field: `amount`,
          },
          {
            label: this.$t('statement.isProformo'),
            field: `isProformo`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '8em',
            sortable: false,
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
            const controller = { 
                name: 'InvoiceStatement',
                actionName:'getStatementReportInfo'
            }
            const payload = { controller: controller}
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.countryOption = response.data.countryList
                    this.countryOption.splice(0, 0, this.$nullSelected())         
                    this.startDate = response.data.startDate               
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
    },
    methods: {
      onSearch(search, loading) {
        if (search.length) {
          loading(true);
          this.search(loading, search, this);
        }
      },
      search(loading, search, vm) {
                if (search.length > 2) {
                    const data = {
                        clientAccountingId: 0,
                        clientName: search
                    }
                    const controller = {
                        name: 'ClientAccounting',
                        actionName: 'GetClientAccountingByClientName'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        this.agentOption = response.data.clientList
                        loading(false)
                    })
                }
      },
      setClientId(event) {
        this.clientAccountingId = event.clientAccountingId
      },
      getStatementList() {
        if(this.startDate == null  && this.clientAccountingId == 0 && this.number == '')
        {
          this.$WarningAlert(this.$t("alerts.empty_search"))
          return
        }

        this.rows = []
        const controller = { name: 'InvoiceStatement',
        actionName : 'getStatementReportByClientAccountingId' }
        const data = { 
          pageLength: this.pageLength, 
          pageNo: this.pageNo, 
          startDate: this.startDate,
          endDate: this.endDate,
          clientAccountingId: this.clientAccountingId, 
          number: this.number
          }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.total = response.data.total
            this.rows = response.data.statementReportList;
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      },
      downloadExtre(){
        const controller = { name: 'InvoiceStatement', actionName: 'DownloadExtre'}            
        const data = { 
          startDate: this.startDate,
          endDate: this.endDate,
          clientAccountingId: this.clientAccountingId, 
          number: this.number
          }
          const payload = { data : data, controller: controller}
          this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
              this.$downloadFile(response.data.document.name, response.data.base64, 'pdf')
            }
            else {
              this.$WarningAlert(response.data.resultMessage)
          }
        })
      },
      exportExcel() {
              const controller = { name: 'InvoiceStatement', actionName: 'CanExport'}            
              const data = { columnList : this.columns, DataList : this.rows}
              const payload = { data : data, controller: controller}
              this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                  if (response.data.resultStatus == true) {
                      this.$downloadFile("Statement", response.data.base64, 'xlsx')
                  }
                   else {
                      this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
    },
  }
  </script>
  <style lang="scss" >
  .excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  .datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
  </style>
  