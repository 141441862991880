<template>
<div>
    <!-- modal -->
    <b-modal id="hourlyAnnualLeaveRequestFormModal" centered size="ml" no-close-on-backdrop ref="hourlyAnnualLeaveRequestFormModal" :title=" $t('hourlyAnnualLeave.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <b-form-group>
                            <label for="hourly-annual-leave-leave-date">{{ $t('hourlyAnnualLeave.leave_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="leaveDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6 mt-1">
                        <label for="hourly-annual-leave-request-starting-time">{{ $t('hourlyAnnualLeave.starting_time') }}</label>
                        <b-input-group>
                            <cleave id='example-input' v-model="startingTime" class="form-control" :raw="false" :options="options.time" placeholder="00:00:00" />
                            <b-input-group-append>
                                <b-form-timepicker v-model="startingTime" :locale="$i18n.locale" v-bind="labels[locale] || {}" button-only now-button button-variant="outline-primary" reset-button no-close-button aria-controls="example-input" size="sm"></b-form-timepicker>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col class="col-md-6 mt-1">

                        <label for="hourly-annual-leave-request-ending-time">{{ $t('hourlyAnnualLeave.ending_time') }}</label>
                        <b-input-group>
                            <cleave id='example-input' v-model="endingTime" class="form-control" :raw="false" :options="options.time" placeholder="00:00:00" />
                            <b-input-group-append>
                                <b-form-timepicker v-model="endingTime" :locale="$i18n.locale" v-bind="labels[locale] || {}" button-only now-button button-variant="outline-primary" reset-button no-close-button aria-controls="example-input" size="sm"></b-form-timepicker>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <label for="hourly-annual-leave-request-description-input">{{ $t('hourlyAnnualLeave.request_description') }}</label>
                        <validation-provider #default="{ errors }" :name=" $t('hourlyAnnualLeave.note')">
                            <b-form-textarea size="sm" id="hourly-annual-leave-request-description-input" v-model="requestDescription" rows="3" max-rows="8" maxlength="250" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-8 mt-1">
                        <b-form>
                            <label for="hourlyAnnualLeave-status-input">{{ $t('hourlyAnnualLeave.status') }}</label>
                            <b-form-select size="sm" v-model="annualLeaveStatusSelect" value-field="id" text-field="name" disabled :options="annualLeaveStatusOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form>
                            <label for="hourlyAnnualLeave-total-input">{{ $t('hourlyAnnualLeave.total') }}</label>
                            <b-form-group>
                                <b-form-input id="hourlyAnnualLeave-total-input" v-model="total" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-2 mt-1">
                        <b-form>
                            <label for="hourlyAnnualLeave-remaining-input">{{ $t('hourlyAnnualLeave.remaining') }}</label>
                            <b-form-group>
                                <b-form-input id="hourlyAnnualLeave-remaining-input" v-model="remaining" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>    
export default {
    props: {
        getHourlyAnnualLeaveListByEmployee: {
            type: Function
        },
        employeeId: Number
    },
    data() {
        return {
            id: 0,
            startingTime: '',
            endingTime: '',
            locale: this.$i18n.locale,
            leaveDate: null,
            requestDescription: '',
            labels: {
                tr: {
                    labelHours: 'Saat',
                    labelMinutes: 'Dakika',
                    labelSeconds: 'Saniye',
                    labelIncrement: 'Arttır',
                    labelDecrement: 'Azalt',
                    labelSelected: 'Seçiniz..',
                    labelNoTimeSelected: 'Henüz tarih seçilmedi',
                    labelCloseButton: 'Kapat',
                    labelResetButton: 'Temizle',
                    labelNowButton: 'Şu an'
                },
            },
            options: {
                time: {
                    time: true,
                    timePattern: ['h', 'm'],
                },
            },
            annualLeaveStatusSelect: 0,
            annualLeaveStatusOption: [],
            tokenEmployeeId: 0,
            employeeId2: 0,
            hourlyAnnualLeaveDto: [],
            total: 0,
            remaining: 0,
        }
    },
    methods: {
        showInfo(id, employeeId2) {
            this.$refs['hourlyAnnualLeaveRequestFormModal'].show()
            this.id = id
            this.tokenEmployeeId = localStorage.getItem('EmployeeId')
            if (employeeId2 > 0 && employeeId2 != this.tokenEmployeeId) {
                this.employeeId2 = employeeId2

            } else {
                employeeId2 = this.tokenEmployeeId
            }
            const data = {
                id: this.id,
                employeeId: this.employeeId2

            }
            const controller = {
                name: 'HourlyAnnualLeave'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.startingTime = response.data.hourlyAnnualLeave.startingTime
                    this.endingTime = response.data.hourlyAnnualLeave.endingTime
                    this.requestDescription = response.data.hourlyAnnualLeave.requestDescription
                    this.leaveDate = response.data.hourlyAnnualLeave.leaveDate
                    this.annualLeaveStatusOption = response.data.annualLeaveStatusList
                    this.total = response.data.total
                    this.remaining = response.data.remaining
                    this.annualLeaveStatusOption.splice(0, 0, this.$nullSelected())
                    if (response.data.hourlyAnnualLeave.annualLeaveStatusId > 0) {
                        this.annualLeaveStatusSelect = response.data.hourlyAnnualLeave.annualLeaveStatusId
                    }
                    // this.status = response.data.hourlyAnnualLeave
                } else {
                    this.$bvModal.hide('hourlyAnnualLeaveRequestFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                    this.startingTime = ''
                    this.endingTime = ''
                    this.requestDescription = ''
                    this.annualLeaveStatusSelect = 0
                }
            })
        },
        addOrUpdate() {
            this.tokenEmployeeId = localStorage.getItem('EmployeeId')
            if (this.employeeId2 > 0 && this.employeeId2 != this.tokenEmployeeId) {
                this.employeeId2 = this.employeeId2

            } else {
                this.employeeId2 = this.tokenEmployeeId
            }
            const data = {
                hourlyAnnualLeaveDto: {
                    id: this.id,
                    employeeId: this.employeeId2,
                    startingTime: this.startingTime,
                    endingTime: this.endingTime,
                    leaveDate: this.leaveDate,
                    requestDescription: this.requestDescription
                },
                updateEmployeeId: localStorage.getItem('EmployeeId')
            }
            const controller = {
                name: 'HourlyAnnualLeave'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('hourlyAnnualLeaveRequestFormModal')
                            this.getHourlyAnnualLeaveListByEmployee()
                            this.$SaveAlert()
                            this.startingTime = ''
                            this.endingTime = ''
                            this.requestDescription = ''
                            this.annualLeaveStatusSelect = 0
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
