<template>
    <div>
      <!-- modal -->
      <b-modal
        id="trademarkSearchSuccessRateFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="trademarkSearchSuccessRateFormModal"
        :title=" $t('trademarkSearchSuccessRate.trademark_search_success_rate_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
      <validation-observer ref="simpleRules">
      <b-row>
        <b-col class="col-lg-12">
          <b-form>
            <label for="trademark-search-success-rate-name-input">{{ $t('trademarkSearchSuccessRate.name') }}</label>
              <b-form-group>
                <validation-provider #default="{ errors }" :name=" $t('trademarkSearchSuccessRate.name')" rules="required">
                  <b-form-input id="trademark-search-success-rate-name-input" v-model="name" maxlength="100" size="sm" :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-lg-12">
          <b-form>
            <label for="trademark-search-success-rate-name-en-input">{{ $t('trademarkSearchSuccessRate.name_en') }}</label>
              <b-form-group>
                <validation-provider #default="{ errors }" :name=" $t('trademarkSearchSuccessRate.name_en')" rules="required">
                  <b-form-input id="trademark-search-success-rate-name-en-input" v-model="nameEn" maxlength="100" size="sm" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-lg-12">
          <b-form>
            <label for="trademark-search-success-rate-rate-input">{{ $t('trademarkSearchSuccessRate.rate') }}</label>
              <b-form-group>
                <validation-provider #default="{ errors }" :name=" $t('trademarkSearchSuccessRate.rate')" rules="required" >
                  <b-form-input id="trademark-search-success-rate-rate-input" v-model="rate" size="sm" type: number :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getTrademarkSearchSuccessRateList: {type: Function},
    },
    data() {
      return {
        id: 0,
        name: '',
        nameEn: '',
        rate : 0,
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['trademarkSearchSuccessRateFormModal'].show() 
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'TrademarkSearchSuccessRate'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.name = response.data.trademarkSearchSuccessRate.name
                this.nameEn = response.data.trademarkSearchSuccessRate.nameEn
                this.rate = response.data.trademarkSearchSuccessRate.rate
            } else {
                this.$bvModal.hide('trademarkSearchSuccessRateFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, name: this.name, nameEn: this.nameEn,rate: this.rate}
        const controller = {name : 'TrademarkSearchSuccessRate'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('trademarkSearchSuccessRateFormModal')
                    this.getTrademarkSearchSuccessRateList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  