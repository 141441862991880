<template>
    <div>
      <!-- modal -->
      <b-modal
        id="annuityDetailStatusFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="annuityDetailStatusFormModal"
        :title=" $t('AnnuityDetailStatus.annuity_detail_status_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-md-12">
                    <b-form>
                        <label for="annuity-detail-status-name-input">{{ $t('AnnuityDetailStatus.name') }}</label>
                        <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          :name=" $t('AnnuityDetailStatus.name')"
                          rules="required"
                        >
                        <b-form-input
                          id="annuity-detail-status-name-input"
                          v-model="name"
                          maxlength="50"
                          size="sm"
                          :state="errors.length > 0 ? false:null"
                        />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12">
                    <label for="textarea-default">{{ $t('AnnuityDetailStatus.explanation') }}</label>
                    <validation-provider #default="{ errors }" :name="$t('AnnuityDetailStatus.explanation')"
                        rules="required">
                        <b-form-textarea id="textarea-default" v-model="explanation" rows="3" maxlength="400"
                            :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
      </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getAnnuityDetailStatusList: {type: Function},
    },
    data() {
      return {
        id: 0,
        name: '',
        explanation: ''
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['annuityDetailStatusFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'AnnuityDetailStatus', actionName: 'GetAnnuityDetailStatusInfo'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.name = response.data.annuityDetailStatus.name
                this.explanation = response.data.annuityDetailStatus.explanation
            } else {
                this.$bvModal.hide('annuityDetailStatusFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, name: this.name, explanation: this.explanation}
        const controller = {name : 'AnnuityDetailStatus'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('annuityDetailStatusFormModal')
                    this.getAnnuityDetailStatusList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  