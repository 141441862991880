<template>
  <div>
    <!-- modal -->
    <b-modal id="designProductFormModal" centered size="lg" no-close-on-backdrop ref="designProductFormModal"
      :title="$t('designProduct.design_product_info')" hide-footer @show="showInfo()">
      <div class="custom-search d-flex justify-content-end">
        <b-row>
          <b-col>
            <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="addOrUpdate">
              {{ $t('others.ok_title') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
            <!-- Serial Number -->
            <b-col md="2">
              <b-form-group :label="$t('designProduct.serial_number')" label-for="serialNumber">
                <b-form-input id="serialNumber" type="number" :placeholder="$t('designProduct.serial_number')"
                  v-model="serialNumber" />
              </b-form-group>
            </b-col>
            <b-col md="10">
              <b-form-group :label="$t('designProduct.design_name')" label-for="designName">
                <b-form-input id="designName" type="text" :placeholder="$t('designProduct.design_name')"
                  v-model="designName" />
              </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <!-- Locarno -->
            <b-col md="2">
              <b-form-group :label="$t('designProduct.locarno')" label-for="locarno">
                <b-form-input id="locarno" type="text" placeholder="00-00" v-model="locarno" />
              </b-form-group>
            </b-col>
            <b-col md="10">
              <b-form-group :label="$t('designProduct.design_name_en')" label-for="design-name-en-input">
                <b-form-input id="design-name-en-input" type="text" :placeholder="$t('designProduct.design_name_en')"
                  v-model="designNameEn" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row style="width: 160%">
              <b-col>
                <b-img
                  :src="imageEmpty"
                  fluid
                  v-bind="mainProps"
                  alt="Img"
                  onclick="document.getElementById('designProductFileSelect').click()"
                  class="ml-2"
                  style="width: 4em; height: 4em;"
                />
              </b-col>
              <hr class="vr"/>
              <b-col>
                <b-row style="max-width: 15em">
                  <div v-for="image in imageUp" :key="image.id">
                    <feather-icon v-if="imageUp" type="button" style="color:red; margin-top:0.5em" icon="XIcon" @click="deleteImage(image)" />
                    <br/>
                    <b-img
                      :src="image"
                      fluid
                      v-bind="mainProps"
                      alt="Img"
                      onclick="document.getElementById('designProductFileSelect').click()"
                      class="ml-2"
                      style="width: 4em; height: 4em;"
                    />
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <input hidden id="designProductFileSelect" type="file" ref="file" multiple="multiple" @change="uploadImage($event)"
              accept="image/*" />
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    designProductForm: Object,
    getDesignProductList: {type: Function},
  },
  data() {
    return {
      id: 0,
      designId:0,
      mainProps: {
        blank: false,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },
      designName: '',
      designNameEn: '',
      locarno: '',
      serialNumber: '',
      imageList: [],
      imageUp: [],
      showImage: '',
      imageShow: [],
      imageEmpty: [],
      imageDeleteShow: []
    }
  },
  methods: {
    deleteImage(image){
      for(var i = 0; i < this.imageList.length; i++){
        this.imageDeleteShow.push('data:image/png;base64,' + this.imageList[i].base64)
        if(image == 'data:image/png;base64,' + this.imageList[i].base64){
          this.imageList.splice(this.imageList[i], 1)
        }
        const index = this.imageUp.indexOf(image);
        if(index > -1){
          var deletedImageUp = this.imageUp.splice(index, 1)
        }
        this.imageUp.splice(deletedImageUp, -1)
      }
    },
    async uploadImage(event) {
      this.imageList = []
      var input = event.target;
      if (input.files) {
        var filelist = input.files;
        for (var i = 0; i < filelist.length; i++) {
          var fileInfo = await this.$getFileInfo(filelist[i]) 
          this.imageList.push(fileInfo);  
          var fileInfoBase64 = 'data:image/png;base64,' + fileInfo.base64
          this.imageUp.push(fileInfoBase64)
        }
      }
    },
    showInfo(id, designId) {
      this.$refs['designProductFormModal'].show()
      this.id = id
      this.designId = designId
      const data = {
        id: this.id
      }
      const controller = {
        name: 'DesignDetail'
      }
      const payload = {
        data: data,
        controller: controller
      }
      this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
        if (response.data.resultStatus == true) {
          this.designName = response.data.designDetail.productName
          this.designNameEn = response.data.designDetail.productNameEn
          this.locarno = response.data.designDetail.locarnoCodes
          this.serialNumber = response.data.designDetail.no
          this.imageList = response.data.designDetail.images
          if(this.imageList == null){
            this.imageUp = []
            this.imageEmpty = []
            this.imageEmpty.push(require('@/assets/images/select-icon.jpeg'))
          } else{
            this.imageUp = []
            this.imageEmpty = []
            for(var i = 0; i < this.imageList.length; i++){
              this.imageShow = this.imageList[i]
              this.imageUp.push('data:image/png;base64,' + this.imageShow.base64)
            }
            this.imageEmpty.push(require('@/assets/images/select-icon.jpeg'))
          }
        } else {
          this.$bvModal.hide('designProductFormModal')
          this.$WarningAlert(response.data.resultMessage)
        }
      })
    },
    addOrUpdate() {
      var imageData = [];
      if(this.imageList != null){
      for (var i = 0; i < this.imageList.length; i++) {
        const image = {
          fileName : this.serialNumber + '_' + (i+1) + '.' + this.imageList[i].contentType,
          base64 : this.imageList[i].base64
        }
        imageData.push(image)
      }
    }
      const data = {
        designId: this.designId,
        id: this.id,
        productName: this.designName,
        productNameEn: this.designNameEn,
        locarnoCodes: this.locarno,
        no: this.serialNumber,
        images : imageData
      }
      const controller = {
        name: 'DesignDetail'
      }
      const payload = {
        data: data,
        controller: controller
      }
      let loader = this.$loading.show()
      this.$store.dispatch('moduleAuth/save', payload).then((response) => {
        if (response.data.resultStatus == true) {
          loader.hide()
          this.getDesignProductList()
          this.$SaveAlert()
          this.$bvModal.hide('designProductFormModal')
        } else {
          if (response.data.resultMessage == "ThereIsDesignDetailWithNo") {
            this.$ThereIsDesignDetailWithNoAlert()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
      })
    },
  },
}
</script>
  
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.vr{ 
  border: none;
  border-left: 1px solid hsla(100, 1%, 5%,100);
  height: 20vh;
  width: 1px;
  margin-top: 0.5em;
}
</style>
