<template>
    <div>
      <national-trademark-search-history-form :nationalTrademarkSearchId="this.nationalTrademarkSearchId" :getNationalTrademarkSearchList="getNationalTrademarkSearchList" :getNationalTrademarkSearchHistoryList="getNationalTrademarkSearchHistoryList" ref="nationalTrademarkSearchHistoryFormModal">
      </national-trademark-search-history-form>
      <b-row>
        <b-col class="col-12">
          <div style="float:right;" class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-button style="float:right;" size="sm"  variant="outline-primary"
                  @click="getInfo(0)">{{ $t('nationalTrademarkSearchHistory.add') }}</b-button>
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table 
      id="nationalTrademarkSearchHistoryTable"
      ref="nationalTrademarkSearchHistoryTable" 
      :columns="columns" 
      :rows="rows" 
      styleClass="vgt-table condensed tableSize"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" 
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"  theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'status'" class="text-nowrap">
            {{ $t('nationalTrademarkSearchHistory.status') }}
          </span>
          <span v-else-if="props.column.field === 'date'" class="text-nowrap">
            {{ $t('nationalTrademarkSearchHistory.date') }}
          </span>
          <span v-else-if="props.column.field === 'perpetrator'" class="text-nowrap">
            {{ $t('nationalTrademarkSearchHistory.perpetrator') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
  
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      nationalTrademarkSearchId: Number,
      getNationalTrademarkSearchList: {type: Function},
    },
    data() {
      return {
        pageLength: 10,
        dir: false,
        columns: [
          {
            label: this.$t('nationalTrademarkSearchHistory.status'),
            field: `nationalTrademarkSearchStatus`,
          },
          {
            label: this.$t('nationalTrademarkSearchHistory.date'),
            field: `date`,
            formatFn: this.$formatFn
          },
          {
            label: this.$t('nationalTrademarkSearchHistory.perpetrator'),
            field: `employee`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      this.getNationalTrademarkSearchHistoryList()
    },
    methods: {
      async deleted(data) {
        data = { id: data.id }
        const controller = { name: 'NationalTrademarkSearchHistory' }
        const payload = { data: data, controller: controller }
        this.$DeleteConfirm().then(result => {
          if (result.value) {
            this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$DeleteAlert()
                this.getNationalTrademarkSearchHistoryList()
                this.getNationalTrademarkSearchList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getNationalTrademarkSearchHistoryList()
              }
            })
          }
        })
      },
      async getInfo(id) {
        this.$refs.nationalTrademarkSearchHistoryFormModal.showInfo(id, this.nationalTrademarkSearchId)
      },
      getNationalTrademarkSearchHistoryList() {
        this.rows = []
        const data = { id: this.nationalTrademarkSearchId }
        const controller = { name: 'NationalTrademarkSearchHistory', actionName:'GetNationalTrademarkSearchHistoryListByNationalTrademarkSearchId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            response.data.nationalTrademarkSearchHistoryList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
    },
  }
  </script>
  <style lang="scss" >
  .excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
  </style>
  