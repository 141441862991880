<template>
    <div>
      <national-patent-country-form ref="nationalPatentCountryFormModal" :transportToCountryName="transportToCountryName" :nationalPatentId="nationalPatentId" :getNationalPatentList="getNationalPatentList" :getNationalPatentCountryList="getNationalPatentCountryList"></national-patent-country-form>
      <national-patent-country-show-history-list-form ref="nationalPatentCountryShowHistoryListFormModal" :transportToCountryName="transportToCountryName" :getNationalPatentList="getNationalPatentList" :getNationalPatentCountryList="getNationalPatentCountryList"></national-patent-country-show-history-list-form>
      <!-- table -->
      <vue-good-table 
      id="nationalPatentCountryTable"
      ref="nationalPatentCountryTable" 
      :columns="columns" 
      :rows="rows" 
      styleClass="vgt-table condensed tableSize"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" 
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: false,
      }"  theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'country'" class="text-nowrap">
            {{ $t('nationalPatentCountry.name') }}
          </span>
          <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
            {{ $t('nationalPatentCountry.agent') }}
          </span>
          <span v-else-if="props.column.field === 'status'" class="text-nowrap">
            {{ $t('nationalPatentCountry.status') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'isMemberOfMadrid'">
                <b-form-checkbox disabled v-model="props.row.isMemberOfMadrid"/>
            </span>
          <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                    class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id, props.row.country)">
    
                    <feather-icon icon="EditIcon" />
                </b-button>
                <b-button v-b-tooltip.html :title="$t('others.history')" v-b-modal.modal-top variant="info"
                    class="btn-icon mb-1 ml-1" size="sm" @click="getHistoryInfo(props.row.id, props.row.country)">
                    <feather-icon icon="ArchiveIcon" />
                </b-button>
                <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                  size="sm" @click="deleted(props.row)">
                  <feather-icon icon="XIcon" />
                </b-button>
                </span>
            </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      nationalPatentId: Number,
      getNationalPatentList: {type: Function},
      nationalPatentApplicationTypeSelect: Number
    },
    watch: {

    },
    data() {
      return {
        dir: false,
        columns: [
          {
            label: this.$t('nationalPatentCountry.name'),
            field: `country`,
          },
          {
            label: this.$t('nationalPatentCountry.agent'),
            field: `agent`,
          },
          {
            label: this.$t('nationalPatentCountry.status'),
            field: `status`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '15em'
          },
        ],
        rows: [],
        transportToCountryName: '',
        searchTerm: '',
      }
    },
    mounted() {
      this.getNationalPatentCountryList()
    },
    methods: {
      async deleted(data) {
        data = { id: data.id }
        const controller = { name: 'NationalPatentCountry' }
        const payload = { data: data, controller: controller }
        this.$DeleteConfirm().then(result => {
          if (result.value) {
            this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$DeleteAlert()
                this.getNationalPatentCountryList()
                this.getNationalPatentList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getNationalPatentCountryList()
              }
            })
          }
        })
      },
      async getInfo(id, country) {
        this.$refs.nationalPatentCountryFormModal.showInfo(id) 
        this.transportToCountryName = country
      },
      async getHistoryInfo(id, country) {
        this.$refs.nationalPatentCountryShowHistoryListFormModal.showInfo(id)
        this.transportToCountryName = country
      },
      getNationalPatentCountryList() {
        this.rows = []
        const data = { nationalPatentId: this.nationalPatentId, applicationTypeId: this.nationalPatentApplicationTypeSelect }
        const controller = { name: 'NationalPatentCountry', actionName:'GetNationalPatentCountryListByNationalPatentId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            if(response.data.nationalPatentCountryList != []){
                response.data.nationalPatentCountryList.forEach(element => {
                this.rows.push(element)
              })
            } else {
            this.$WarningAlert(response.data.resultMessage)
          } 
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
    },
  }
  </script>
  <style lang="scss" >
  .excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
  </style>
  