<template>
    <div>
      <!-- modal -->
      <b-modal
        id="clientLiableFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="clientLiableFormModal"
        :title=" $t('clientLiable.liable_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
      <validation-observer ref="simpleRules">
      <b-row>
        <b-col class="col-lg-12">
          <b-form>
            <validation-provider :name=" $t('clientLiable.liable')" :rules="{ excluded:0}"  v-slot="{ errors }">
              <label for="client-liable-input">{{ $t('clientLiable.liable') }}</label>
                <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="liableSelect" value-field="id" text-field="fullName" :options="liableOption"/>
                <small class="text-danger">{{ errors[0] }}</small>       
            </validation-provider>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-lg-12 mt-1">
          <b-form>
            <validation-provider :name=" $t('clientLiable.responsible_type')" :rules="{ excluded:0}" v-slot="{ errors }" >
              <label for="responsible-type">{{ $t('clientLiable.responsible_type') }}</label>
                <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="responsibleTypeSelect"  value-field="id" text-field="name" :options="responsibleTypeOption"/>
                <small class="text-danger">{{ errors[0] }}</small>       
            </validation-provider>
          </b-form>
        </b-col>
      </b-row>
    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>

  export default {
    props: {
      getClientLiableList: {type: Function},
    },
    data() {
      return {
        id: 0,
        liableSelect: 0,
        liableOption: [],
        responsibleTypeSelect: 0,
        responsibleTypeOption: [],
        clientId:0
      }
    },
    methods: {
      showInfo(id, clientId) {
        this.$refs['clientLiableFormModal'].show()
        this.id = id
        this.clientId = clientId;
        const data = {id: this.id}
        const controller = {name : 'ClientLiable'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){              
                this.liableOption = response.data.employeeList
                this.liableSelect = response.data.clientLiable.employeeId
                this.responsibleTypeOption = response.data.liableFieldList
                this.responsibleTypeSelect = response.data.clientLiable.liableFieldId
                this.liableOption.splice(0, 0, this.$nullSelected("fullName"))
                this.responsibleTypeOption.splice(0, 0, this.$nullSelected())
            } else {
                this.$bvModal.hide('clientLiableFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, clientId: this.clientId, employeeId: this.liableSelect, liableFieldId: this.responsibleTypeSelect}
        const controller = {name : 'ClientLiable'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('clientLiableFormModal')
                    this.getClientLiableList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  