<template>
<div>
    <!-- modal -->
    <b-modal id="hourlyAnnualLeaveRequestApproveFormModal" centered size="ml" no-close-on-backdrop ref="hourlyAnnualLeaveRequestApproveFormModal" :title=" $t('hourlyAnnualLeave.info')" hide-footer>
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <b-form>
                            <label for="hourly-annual-leave-employee-input">{{ $t('hourlyAnnualLeave.employee') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="hourly-annual-leave-employee-input" disabled v-model="employee" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <b-form-group>
                            <label for="hourly-annual-leave-leave-date">{{ $t('hourlyAnnualLeave.leave_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="leaveDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6 mt-1">
                        <label for="hourly-annual-leave-request-starting-time">{{ $t('hourlyAnnualLeave.starting_time') }}</label>
                        <b-input-group>
                            <cleave id='example-input' v-model="startingTime" disabled class="form-control" :raw="false" :options="options.time" placeholder="00:00:00" />
                            <b-input-group-append>
                                <b-form-timepicker v-model="startingTime" :locale="$i18n.locale" disabled v-bind="labels[locale] || {}" button-only now-button button-variant="outline-primary" reset-button no-close-button aria-controls="example-input" size="sm"></b-form-timepicker>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col class="col-md-6 mt-1">

                        <label for="hourly-annual-leave-request-ending-time">{{ $t('hourlyAnnualLeave.ending_time') }}</label>
                        <b-input-group>
                            <cleave id='example-input' v-model="endingTime" disabled class="form-control" :raw="false" :options="options.time" placeholder="00:00:00" />
                            <b-input-group-append>
                                <b-form-timepicker v-model="endingTime" :locale="$i18n.locale" disabled v-bind="labels[locale] || {}" button-only now-button button-variant="outline-primary" reset-button no-close-button aria-controls="example-input" size="sm"></b-form-timepicker>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <label for="hourly-annual-leave-request-description-input">{{ $t('hourlyAnnualLeave.request_description') }}</label>
                        <validation-provider #default="{ errors }" :name=" $t('hourlyAnnualLeave.request_description')">
                            <b-form-textarea size="sm" id="hourly-annual-leave-request-description-input" v-model="requestDescription" disabled rows="3" max-rows="8" maxlength="250" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <b-form>
                            <label for="hourlyAnnualLeave-status-input">{{ $t('hourlyAnnualLeave.status') }}</label>
                            <b-form-select size="sm" v-model="annualLeaveStatusSelect" value-field="id" text-field="name" disabled :options="annualLeaveStatusOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <label for="hourly-annual-leave-approve-description-input">{{ $t('hourlyAnnualLeave.approve_description') }}</label>
                        <validation-provider #default="{ errors }" :name=" $t('hourlyAnnualLeave.approve_description')">
                            <b-form-textarea size="sm" id="hourly-annual-leave-request-description-input" v-model="approveDescription" rows="3" max-rows="8" maxlength="250" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6 mt-1">
                        <b-button v-b-tooltip.html :title="$t('hourlyAnnualLeave.decline')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" disable @click="approveHourlyAnnualLeave(id,false)">
                            {{ $t('hourlyAnnualLeave.decline') }}
                        </b-button>
                    </b-col>
                    <b-col class="col-md-6 mt-1">
                        <b-button v-b-tooltip.html :title="$t('hourlyAnnualLeave.accept')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" disable @click="approveHourlyAnnualLeave(id,true)">
                            {{ $t('hourlyAnnualLeave.accept') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>    
export default {
    props: {
        getHourlyAnnualLeaveListByDepartmentId: {
            type: Function
        },

    },
    data() {
        return {
            id: 0,
            startingTime: '',
            endingTime: '',
            locale: this.$i18n.locale,
            leaveDate: null,
            employeeId: 0,
            requestDescription: '',
            approveDescription: '',
            labels: {
                tr: {
                    labelHours: 'Saat',
                    labelMinutes: 'Dakika',
                    labelSeconds: 'Saniye',
                    labelIncrement: 'Arttır',
                    labelDecrement: 'Azalt',
                    labelSelected: 'Seçiniz..',
                    labelNoTimeSelected: 'Henüz tarih seçilmedi',
                    labelCloseButton: 'Kapat',
                    labelResetButton: 'Temizle',
                    labelNowButton: 'Şu an'
                },
            },
            options: {
                time: {
                    time: true,
                    timePattern: ['h', 'm'],
                },
            },
            annualLeaveStatusOption: [],
            annualLeaveStatusSelect: 0,
            approval: false,
            employee: '',
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['hourlyAnnualLeaveRequestApproveFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'HourlyAnnualLeave'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.startingTime = response.data.hourlyAnnualLeave.startingTime
                    this.endingTime = response.data.hourlyAnnualLeave.endingTime
                    this.requestDescription = response.data.hourlyAnnualLeave.requestDescription
                    this.leaveDate = response.data.hourlyAnnualLeave.leaveDate
                    this.approveDescription = response.data.hourlyAnnualLeave.approveDescription
                    this.employee = response.data.hourlyAnnualLeave.employee
                    this.annualLeaveStatusOption = response.data.annualLeaveStatusList
                    this.annualLeaveStatusOption.splice(0, 0, this.$nullSelected())
                    if (response.data.hourlyAnnualLeave.annualLeaveStatusId > 0) {
                        this.annualLeaveStatusSelect = response.data.hourlyAnnualLeave.annualLeaveStatusId
                    }

                    // this.status = response.data.hourlyAnnualLeave
                } else {
                    this.$bvModal.hide('hourlyAnnualLeaveRequestApproveFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        approveHourlyAnnualLeave(id, approval) {
            this.id = id,
                this.approval = approval
            const controller = {
                name: 'HourlyAnnualLeave',
                actionName: 'ApproveHourlyAnnualLeave'
            }
            const data = {
                managerId: localStorage.getItem('EmployeeId'),
                id: this.id,
                approval: this.approval,
                approveDescription: this.approveDescription
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('hourlyAnnualLeaveRequestApproveFormModal')
                            this.getHourlyAnnualLeaveListByDepartmentId()
                            this.$SaveAlert()
                        } else {
                            this.$bvModal.hide('hourlyAnnualLeaveRequestApproveFormModal')
                            this.$WarningAlert(response.data.resultMessage)
                            this.approveDescription = '',
                                this.approval = false
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
