<template>
    <div>
        <!-- modal -->
        <b-modal id="patentAnnuityFormModal" centered size="lg" no-close-on-backdrop :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')"
            cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" ref="patentAnnuityFormModal" :title="$t('patentAnnuity.patent_annuity_info')" @close="clear()">
            <validation-observer ref="infoRules" tag="form">
                <b-row>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <validation-provider #default="{ errors }" :name="$t('patentAnnuity.year')" :rules="{ excluded: 0 }">
                                <label for="patent-annuity-year-input">{{ $t('patentAnnuity.year') }}</label>
                                <b-form-select v-model="yearSelect" value-field="id" text-field="name" size="sm" :options="yearOption" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                            <label for="patent-annuity-date-input">{{ $t('patentAnnuity.date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="date"></CustomDatePicker>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <label for="patent-annuity-payment-date-input">{{ $t('patentAnnuity.payment_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="paymentDate"></CustomDatePicker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6">
                        <b-form>
                            <validation-provider :name="$t('patentAnnuity.patent_annuity_will_pay')" #default="{ errors }" :rules="{ excluded: 0 }">
                                <label for="language-input">{{ $t('patentAnnuity.patent_annuity_will_pay') }}</label>
                                <b-form-select v-model="patentAnnuityWillPaySelect" value-field="id" text-field="name" size="sm" :options="patentAnnuityWillPayOption" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-6">
                        <b-form>
                            <label for="language-input">{{ $t('patentAnnuity.patent_annuity_status') }}</label>
                            <b-form-select v-model="patentAnnuityStatusSelect" value-field="id" size="sm" text-field="name" :options="patentAnnuityStatusOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <label for="textarea-default">{{ $t('patentAnnuity.explanation') }}</label>
                            <b-form-textarea id="textarea-default" :placeholder="$t('patentAnnuity.explanation')"
                                v-model="explanation" rows="6" maxlength="150" />
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
    </template>
    
    <script>    
    export default {
        props: {
            getPatentAnnuityList: {
                type: Function
            },
            getPatentList: {
                type: Function
            },
            item: {
                type: Number
            }
        },
        data() {
            return {
                id: 0,
                patentId: 0,
                yearOption: [],
                yearSelect: 0,
                patentAnnuityWillPayOption: [],
                patentAnnuityWillPaySelect: 0,
                date: null,
                paymentDate: null,
                patentAnnuityStatusSelect: 0,
                patentAnnuityStatusOption: [],
                explanation: '',
            }
        },
        methods: {
            showInfo(id, patentId) {
                this.$refs['patentAnnuityFormModal'].show()
                this.id = id
                this.patentId = patentId
                const data = {
                    id: this.id,
                    patentId: this.patentId
                }
                const controller = {
                    name: 'PatentAnnuity'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.yearSelect = response.data.patentAnnuity.year
                        this.yearOption = response.data.patentAnnuityYearList
                        this.patentAnnuityWillPaySelect = response.data.patentAnnuity.patentAnnuityWillPayId
                        this.patentAnnuityWillPayOption = response.data.patentAnnuityWillPayList
                        this.date = response.data.patentAnnuity.date
                        this.patentAnnuityStatusSelect = response.data.patentAnnuity.patentAnnuityStatusId
                        this.patentAnnuityStatusOption = response.data.patentAnnuityStatusList
                        this.paymentDate = response.data.patentAnnuity.paymentDate
                        this.explanation = response.data.patentAnnuity.explanation
                        this.patentAnnuityWillPayOption.splice(0, 0, this.$nullSelected('name'))
                        this.patentAnnuityStatusOption.splice(0, 0, this.$nullSelected('name'))
                    } else {
                        this.$bvModal.hide('patentAnnuityFormModal')
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            clear() {
                this.translatorSelect = 0
                this.languageSelect = 0
            },
            addOrUpdate() {
                const data = { id: this.id, patentId: this.patentId, year: this.yearSelect, patentAnnuityWillPayId: this.patentAnnuityWillPaySelect,
                    date: this.date, patentAnnuityStatusId: this.patentAnnuityStatusSelect, paymentDate: this.paymentDate, explanation: this.explanation}
                const controller = {
                    name: 'PatentAnnuity'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$refs.infoRules.validate().then(success => {
                    if (success) {
                        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$bvModal.hide('patentAnnuityFormModal')
                                this.getPatentAnnuityList()
                                this.$SaveAlert()
                                this.getPatentList()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                })
            },
        },
    }
    </script>
    
    <style lang="scss">
    [dir=ltr] .modal-header .close {
        margin: -0.4rem -0.4rem -0.8rem auto;
    }
    
    [dir] label {
        margin-bottom: 0.2857rem;
        font-weight: bold;
    }
    
    .modal .modal-content {
        overflow: visible;
        margin-bottom: auto;
    }
    
    [dir] .invalid-tooltip {
        padding: 0.4rem 0.775rem !important;
        margin-top: -1.5rem !important;
        border-radius: 0.358rem !important;
        left: 20px !important
    }
    
    .customList {
        border: 1px solid #999;
        padding: 20px;
    }
    .datepickerStyle{
        max-height: 2.2em;
        font-family: fangsong;
    }
    </style>
    