<template>
    <div>
        <!-- modal -->
        <b-modal 
            id="trademarkNiceClassFormModal" 
            centered size="lg" 
            no-close-on-backdrop ref="trademarkNiceClassFormModal"
            :title="$t('trademarkNiceClass.nice_class_info')" 
            :cancel-title="$t('others.cancel_title')"
            :ok-title="$t('others.ok_title')" 
            cancel-variant="outline-secondary" 
            @close="clear()"
            @ok.prevent="addOrUpdate"
        >
            <validation-observer ref="infoRules" tag="form">
                <b-row>
                    <b-col class="col-md-12">
                        <b-form>
                            <validation-provider :name="$t('trademarkNiceClass.nice_class_type')" #default="{ errors }"
                                :rules="{ excluded: 0 }">
                                <b-form>
                                    <label for="trademark-nice-class-type-input">{{ $t('trademarkNiceClass.nice_class_type') }}</label>
                                    <b-form-select v-model="niceClassTypeSelect" value-field="id" size="sm" text-field="name"
                                        :options="niceClassTypeOption" />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <label for="trademark-nice-class-type-input">{{ $t('trademarkNiceClass.nice_class') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" disabled :value="niceClassSelectString" maxlength="250"/>
                            <b-form-checkbox-group id="trademark-nice-class-type-input" v-model="niceClassSelect" :options="sortedArray" value-field="name" text-field="name" class="demo-inline-spacing"/>
                        </b-form-group>
                    </b-col>
                </b-row>    
                <b-row>
                    <b-col class="col-md-12">
                        <b-form>
                            <label for="trademark-goods-input">{{ $t('trademarkNiceClass.goods') }}</label>
                            <b-form-group>
                                <b-form-textarea size="sm" id="trademark-goods-input" :placeholder=" $t('trademarkNiceClass.goods')" v-model="goods" rows="9" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>              
                <b-row>
                    <b-col class="col-md-12">
                        <b-form>
                            <label for="trademark-goods-en-input">{{ $t('trademarkNiceClass.goods_en') }}</label>
                            <b-form-group>
                                <b-form-textarea size="sm" id="trademark-goods-en-input" :placeholder=" $t('trademarkNiceClass.goods_en')" v-model="goodsEn" rows="9" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12">
                        <b-form>
                            <label for="trademark-extracted-goods-input">{{ $t('trademarkNiceClass.extracted_goods') }}</label>
                            <b-form-group>
                                <b-form-textarea size="sm" id="trademark-extracted-goods-input" :placeholder=" $t('trademarkNiceClass.extracted_goods')" v-model="extractedGoods" rows="9" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        getTrademarkNiceClassList: {
            type: Function
        }, 
    },
    computed: {
        niceClassSelectString() {
            return this.niceClassSelect.sort().join(' ');
        },
        sortedArray() {
            return this.niceClassOption.sort((a, b) => a.id - b.id);
        }
    },
    data() {
        return {
            id: 0,
            trademarkId:0,
            niceClassTypeSelect: 0,
            niceClassTypeOption: [],
            niceClassInput: '',
            niceClassOption: [],
            niceClassSelect: [],
            goods: '',
            goodsEn: '',
            extractedGoods: ''
        }
    },
    methods: {
        showInfo(id, trademarkId) {
            this.$refs['trademarkNiceClassFormModal'].show()
            this.id= id
            this.trademarkId= trademarkId
            const data = { id: this.id, trademarkId: this.trademarkId}
            const controller = {name: 'TrademarkNiceClass'}
            const payload = {data: data, controller: controller}
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.niceClassTypeSelect = response.data.trademarkNiceClass.niceClassTypeId
                    this.niceClassTypeOption = response.data.niceClassTypeList
                    this.niceClassOption = response.data.niceClassList
                    this.extractedGoods = response.data.trademarkNiceClass.extractedGoods
                    this.niceClassSelect = response.data.trademarkNiceClass.niceClass.split(' ')
                    this.goods = response.data.trademarkNiceClass.goods
                    this.goodsEn = response.data.trademarkNiceClass.goodsEn
                    this.niceClass = response.data.trademarkNiceClass.niceClass
                    this.niceClassTypeOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('trademarkNiceClassFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
            this.name = null
        },
        addOrUpdate() {
            const data = {id: this.id, trademarkId: this.trademarkId, niceClassTypeId: this.niceClassTypeSelect, extractedGoods:this.extractedGoods, goods:this.goods, goodsEn: this.goodsEn, niceClass: this.niceClassSelectString}
            const controller = { name: 'TrademarkNiceClass'}
            const payload = { data: data, controller: controller}
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('trademarkNiceClassFormModal')
                            this.getTrademarkNiceClassList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        }
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.vs__selected .vs__deselect {
    color: red;
}
</style>
