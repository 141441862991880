<template>
<div>
    <!-- modal -->
    <b-modal id="ledesDetailFormModal" centered size="lg" no-close-on-backdrop ref="ledesDetailFormModal" :title=" $t('ledesDetail.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" @close="clear()">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="mb-1">
                        <validation-provider :name="$t('ledesDetail.ledes_code_type')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <label for="ledes-detail-ledes-code-type-input">{{ $t('ledesDetail.ledes_code_type') }}</label>
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="ledesCodeTypeSelect" value-field="id" text-field="name" :options="ledesCodeTypeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <validation-provider :name="$t('ledesDetail.name')" rules="required" v-slot="{ errors }">
                            <label for="ledes-detail-name-input">{{ $t('ledesDetail.name') }}</label>
                            <b-form-group>
                                <b-form-textarea id="ledes-detail-name-input" v-model="name" size="sm" maxlength="250" rows="2" :state="errors.length > 0 ? false : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <validation-provider :name="$t('ledesDetail.code')" rules="required" v-slot="{ errors }">
                            <label for="ledes-detail-code-input">{{ $t('ledesDetail.code') }}</label>
                            <b-form-group>
                                <b-form-input id="ledes-detail-code-input" v-model="code" size="sm" maxlength="20" :state="errors.length > 0 ? false : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <validation-provider :name="$t('ledesDetail.tmpt')" rules="required" v-slot="{ errors }">
                            <label for="ledes-detail-tmpt-input">{{ $t('ledesDetail.tmpt') }}</label>
                            <b-form-group>
                                <b-form-input id="ledes-detail-tmpt-input" v-model="tmpt" size="sm" maxlength="50" :state="errors.length > 0 ? false : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label for="ledes-detail-description-input">{{ $t('ledesDetail.description') }}</label>
                        <b-form-group>
                            <b-form-textarea id="ledes-detail-description-input" v-model="description" size="sm" maxlength="1500" rows="3" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getLedesDetailList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            ledesCodeTypeSelect: 0,
            ledesCodeTypeOption: [],
            code: '',
            tmpt: '',
            name: '',
            description: '',
        }
    },
    methods: {
        clear() {
            this.ledesCodeTypeSelect = 0;
            this.code = '';
            this.name = '';
            this.tmpt = '';
            this.description = '';
        },
        showInfo(id, ledesId) {
            this.$refs['ledesDetailFormModal'].show()
            this.id = id
            this.ledesId = ledesId
            const data = {
                id: this.id,
                ledesId: this.ledesId,
            }
            const controller = {
                name: 'LedesDetail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.name = response.data.ledesDetail.name
                    this.description = response.data.ledesDetail.description
                    this.code = response.data.ledesDetail.code
                    this.tmpt = response.data.ledesDetail.tmpt
                    this.ledesCodeTypeOption = response.data.ledesCodeTypeList
                    this.ledesCodeTypeOption.splice(0, 0, this.$nullSelected('name'))
                    if (response.data.ledesDetail.ledesCodeTypeId > 0) {
                        this.ledesCodeTypeSelect = response.data.ledesDetail.ledesCodeTypeId;
                    } else {
                        this.ledesCodeTypeSelect = 0
                    }
                } else {
                    this.$bvModal.hide('ledesDetailFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                ledesCodeTypeId: this.ledesCodeTypeSelect,
                ledesId: this.ledesId,
                name: this.name,
                tmpt: this.tmpt,
                description: this.description,
                code: this.code
            }
            const controller = {
                name: 'LedesDetail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('ledesDetailFormModal')
                            this.getLedesDetailList()
                            this.$SaveAlert()
                            this.ledesCodeTypeSelect = 0;
                            this.code = '';
                            this.name = '';
                            this.tmpt = '';
                            this.description = '';

                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-template-detail .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
