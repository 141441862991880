<template>
    <div>
      <!-- modal -->
      <b-modal
        id="trademarkOppositionOwnerFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="trademarkOppositionOwnerFormModal"
        :title=" $t('trademarkOppositionOwner.owner_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
      <validation-observer ref="infoRules" tag="form">
        <b-row>
          <b-col>
                  <b-form>
                    <validation-provider
                      :name=" $t('trademarkOppositionOwner.owner_name')"
                      #default="{ errors }"
                      rules="required"
                    >
                    <b-form-group
                      :label=" $t('trademarkOppositionOwner.owner_name')"
                      label-for="trademarkOppositionOwner"
                      :state="errors.length > 0 ? false:null"
                    >
                        <v-select 
                        id="trademarkOppositionOwner"
                        v-model="trademarkOppositionOwnerSelect"
                        :options="trademarkOppositionOwnerOption" 
                        label="name" 
                        @search="onSearch">
                        </v-select>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback> 
                    </b-form-group>
                    </validation-provider>
                  </b-form>
          </b-col>
        </b-row>
      </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>

  export default {
    props: {
      trademarkOppositionId: Number, 
      getTrademarkOppositionOwnerList: {type: Function},
      getTrademarkOppositionList: {
            type: Function
        },
    },
    data() {
      return {
        id: 0,
        trademarkOppositionOwnerSelect: '',
        trademarkOppositionOwnerOption: [],
      }
    },
    methods: {
      showInfo(){
        this.$refs['trademarkOppositionOwnerFormModal'].show()
      },
      onSearch(search, loading) {
      if(search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search(loading, search, vm) {
      if(search.length > 2){
        const data = {name: search}
        const controller = { name: 'Client', actionName:'GetOwnerClientByName' }
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          this.trademarkOppositionOwnerOption = response.data.clientList
          loading(false)
        })
    }
    },
      addOrUpdate() {
        const data = {id: this.id, trademarkOppositionId: this.trademarkOppositionId, clientId: this.trademarkOppositionOwnerSelect.id}
        const controller = { name: 'TrademarkOppositionOwner' }
        const payload = { data: data, controller: controller }
        this.$refs.infoRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('trademarkOppositionOwnerFormModal')
                    this.getTrademarkOppositionOwnerList()
                    this.trademarkOppositionOwnerSelect = ''
                    this.$SaveAlert()
                    this.getTrademarkOppositionList()

                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
              }
            })
          }
      },
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  [dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
  </style>
  