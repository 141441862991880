<template>
    <div>
      <national-trademark-form  ref="nationalTrademarkForm"></national-trademark-form>
  
      <b-modal id="trademarkTransactionReportDetailModal" centered size="xl" no-close-on-backdrop ref="trademarkTransactionReportDetailModal" :title=" $t('trademarkTransactionReportDetail.title')" hide-footer>
        
        <b-row>
          <b-col class="col-11">
  
          </b-col>
        <b-col class="col-1">
          <div class="d-flex align-items-right">
          <b-button class="d-inline-block mr-05" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
        </div>
      </b-col>
    </b-row>
  <br/>
      <div class="custom-search">
  
        <!--Row 1-->
        <b-row>
          <b-col>            
            <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('recordNo', $event.target.checked)">
              {{ $t('trademarkTransactionReportDetail.recordNo') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('name', $event.target.checked)">
              {{ $t('trademarkTransactionReportDetail.name') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('agent', $event.target.checked)">
              {{ $t('trademarkTransactionReportDetail.agent') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('owner', $event.target.checked)">
              {{ $t('trademarkTransactionReportDetail.owner') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('applicationNo', $event.target.checked)">
              {{ $t('trademarkTransactionReportDetail.applicationNo') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox name="checkbox" @change.native="changeColumns('applicationDate', $event.target.checked)">
              {{ $t('trademarkTransactionReportDetail.applicationDate') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <!--Row 2-->
      <b-row>
        <b-col>
          <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('registerNo', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.registerNo') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox name="checkbox" @change.native="changeColumns('registerDate', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.registerDate') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('firstRegisterNo', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.firstRegisterNo') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('trademarkNiceClass.niceClass', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.trademarkNiceClass_niceClass') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('trademarkStatus', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.trademarkStatus') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('note', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.note') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
  
      <!--Row 3-->
      <b-row>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('trademarkRenewal.nextRenewalDate', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.trademarkRenewal_nextRenewalDate') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('trademarkRenewal.renewalDate', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.trademarkRenewal_renewalDate') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('priorityDate', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.priorityDate') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('instructionDate', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.instructionDate') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('trademarkNiceClass.goodsEn', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.trademarkNiceClass_goodsEn') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('trademarkNiceClass.goods', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.trademarkNiceClass_goods') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
  
      <!--Row 4-->
      <b-row>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentAddress.country', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.agentAddress_country') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentAddress.countryCode', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.agentAddress_countryCode') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentAddress.address', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.agentAddress_address') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('ownerAddress.country', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.ownerAddress_country') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('ownerAddress.countryCode', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.ownerAddress_countryCode') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('ownerAddress.address', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.ownerAddress_address') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
  
      <!--Row 5-->
      <b-row>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentReferenceNo', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.agentReferenceNo') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentResponsible.name', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.agentResponsible_name') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentResponsible.email', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.agentResponsible_email') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('renewalAgent', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.renewalAgent') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('renewalAgentResponsible.name', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.renewalAgentResponsible_name') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('renewalAgentResponsible.email', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.renewalAgentResponsible_email') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
  
      <!--Row 6-->
      <b-row>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('bulletinDate', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.bulletinDate') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('yiDKDate', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.yiDKDate') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('ownerNote', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.ownerNote') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentNote', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.agentNote') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox name="checkbox" @change.native="changeColumns('invoiceNote', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.invoiceNote') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <div class="d-flex align-items-right">
            <b-form-checkbox name="checkbox" @change.native="changeColumns('isTakeOver', $event.target.checked)">
            {{ $t('trademarkTransactionReportDetail.isTakeOver') }}
          </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
    
    </div>
        
        <!-- table -->
      <vue-good-table
        id="trademarkTransactionReportDetailTable"
        ref="trademarkTransactionReportDetailTable" 
        :columns="columns"
        :rows="rows"
        styleClass="vgt-table condensed tableSize"
        :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" 
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
  
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'recordNo'">
                <b-row >
                    <b-col class="font-size: x-small">  
                      <b-badge @click="showTrademarkInfoForm(props.row)" variant="primary" style="cursor: pointer;">
                        <feather-icon icon="MousePointerIcon" class="mr-25" />
                        <span>{{ props.row.recordNo }}</span>
                      </b-badge>                     
                    </b-col>
                </b-row>
            </span>
          </template>
     <!-- pagination -->
     <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
            </span>
            <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      </vue-good-table>
    </b-modal>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        total: 0,
        pageLength: 50,
        dir: false,
        pageNo: 1,
        columns: [],
        rows: [],
        searchTerm: '',
        isAgent:false,
        isOwner:false,
        name:''
      }
    },
    methods: {
      changeColumns(fieldName, status){
        if(status)
        {
          if(fieldName.indexOf("Date") > -1){
            var item = {
              label: this.$t('trademarkTransactionReportDetail.XXX'.replace("XXX", fieldName.replace(".", "_"))),
              field: fieldName,
              formatFn: this.$formatFn
            }
          this.columns.push(item);
          }
          else{
            var item = {
              label: this.$t('trademarkTransactionReportDetail.XXX'.replace("XXX", fieldName.replace(".", "_"))),
              field: fieldName,
            }
          this.columns.push(item);
          }
  
        }
        else
        {
          const index = this.columns.findIndex(i => i.field === fieldName)
          this.columns.splice(index, 1);
        }
      },
      showTrademarkInfoForm(row){
        
          this.$refs.nationalTrademarkForm.showInfo(row.id)
      },
      showData(data) {
        this.$refs.trademarkTransactionReportDetailModal.show()
        this.columns = [
          {
            label: this.$t('trademarkTransactionReportDetail.recordNo'),
            field: 'recordNo',
          },
          {
            label: this.$t('trademarkTransactionReportDetail.name'),
            field: 'name',
          },
          {
            label: this.$t('trademarkTransactionReportDetail.agent'),
            field: 'agent',
          },          
          {
            label: this.$t('trademarkTransactionReportDetail.owner'),
            field: 'owner',
          },
          {
            label: this.$t('trademarkTransactionReportDetail.applicationNo'),
            field: 'applicationNo',
          },
          {
            label: this.$t('trademarkTransactionReportDetail.registerNo'),
            field: 'registerNo',
          },
          {
            label: this.$t('trademarkTransactionReportDetail.trademarkNiceClass_niceClass'),
            field: 'trademarkNiceClass.niceClass',
          },
          {
            label: this.$t('trademarkTransactionReportDetail.trademarkStatus'),
            field: 'trademarkStatus',
          }
        ]
        this.$refs.trademarkTransactionReportDetailModal.show()
        this.rows = data
      },
      exportExcel() {
        var controller = {
            name: 'Trademark',
            actionName: 'CanExport'
        }
  
        var data = {
            columnList: this.columns,
            dataList: this.rows
        }
        var payload = {
            data: data,
            controller: controller
        }
        let loader = this.$loading.show()
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
                this.$downloadFile("Marka Listesi", response.data.base64, 'xlsx')
            } else {
                this.$WarningAlert(response.data.resultMessage)
            }
            loader.hide()
        })
      }      
    },
  }
  </script>
  <style>
   .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  </style>
  