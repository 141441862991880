<template>
    <div>
        <etebs-form :getEtebsList="getEtebsList" ref="etebsFormModal"></etebs-form>
        <open-job-list-form :getEtebsList="getEtebsList" ref="openJobListFormModal"></open-job-list-form>
        <upload-from-etebs-form :getEtebsList="getEtebsList" ref="uploadFromEtebsFormModal"></upload-from-etebs-form>
        <div class="custom-search">
            <b-row>
                <b-col class="col-md-4 mb-1">
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="moduleSelect" @input="getEtebsList()" size="sm" value-field="id" text-field="name" :options="moduleOption" />
                    </div>
                </b-col>
                <b-col class="col-md-2 mb-1">
                    <div class="d-flex align-items-center">
                        <label for="etebs-is-transferred-input">{{ $t('etebs.transferred') }}</label>
                        <b-form-checkbox class="custom-control-success" @input="getEtebsList()" name="etebs-is-transferred-input" id="etebs-is-transferred-input" v-model="isTransfered" switch>
                            <span class="switch-icon-left"> 
                                <feather-icon icon="CheckIcon" /> 
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                            </span>
                        </b-form-checkbox>                 
                    </div>
                </b-col>
                <b-col class="col-md-6 mb-1 custom-search d-flex justify-content-end">
                    <b-row>
                        <div class="d-flex align-items-center">
                            <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getUploadFromEtebsInfo()">{{$t('etebs.upload_from_etebs')}}</b-button>
                        </div>
                    </b-row>
                </b-col>
            </b-row>
        </div>   
        <!-- table -->
        <vue-good-table id="etebsTable" ref="etebsTable" :columns="columns" :rows="rows"  
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }" styleClass="vgt-table condensed tableSize" 
        :select-options="{ 
            enabled: false, 
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }" :pagination-options="{ enabled: true, perPage: pageLength }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'module'" class="text-nowrap">
                    {{ $t('etebs.module') }}
                </span>
                <span v-else-if="props.column.field === 'notificationDate'" class="text-nowrap">
                    {{ $t('etebs.notification_date') }}
                </span>
                <span v-else-if="props.column.field === 'applicationNo'" class="text-nowrap">
                    {{ $t('etebs.application_no') }}
                </span>
                <span v-else-if="props.column.field === 'documentNo'" class="text-nowrap">
                    {{ $t('etebs.document_no') }}
                </span>
                <span v-else-if="props.column.field === 'documentEntryNumber'" clfass="text-nowrap">
                    {{ $t('etebs.document_entry_no') }}
                </span>
                <span v-else-if="props.column.field === 'responsible'" class="text-nowrap">
                    {{ $t('etebs.responsible') }}
                </span>
                <span v-else-if="props.column.field === 'documentType'" class="text-nowrap">
                    {{ $t('etebs.document_type') }}
                </span>
                <span v-else-if="props.column.field === 'note'" class="text-wrap">
                    {{ $t('etebs.note') }}
                </span>
                <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>
            <!-- Column: Table Column -->
            <template  slot="table-row" slot-scope="props">
                <span  v-if="props.column.field === 'process'">                    
                    <span>
                        <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                            <feather-icon icon="EditIcon" />
                        </b-button>

                        <b-button v-if="props.row.documentId != 0" v-b-tooltip.html :title="$t('others.download')" v-b-modal.modal-center variant="success"
                    class="btn-icon mb-1 ml-1" size="sm" @click="download(props.row)">
                        <feather-icon icon="DownloadIcon" />
                        </b-button>

                        <span v-if="props.row.isTransfered == 0">  
                            <b-button v-for="operationRecord in props.row.operationRecordList" v-bind:data="operationRecord" v-bind:key="operationRecord.recordId" v-b-tooltip.html :title="$t('etebs.transfer')" v-b-modal.modal-center variant="success"
                            class="btn-icon mb-1 ml-1" size="sm" @click="transfered(operationRecord, props.row.documentId)">
                                {{operationRecord.recordNo}} <feather-icon icon="SendIcon" />
                            </b-button>
                        </span>      
                    </span>
                </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }} </span>
                        <b-form-select v-model="pageLength" :options="['50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getEtebsList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
    </template>
    
    <script>
    export default {
        data() {
            return {
                total: 0,
                pageLength: 50,
                dir: false,
                pageNo: 1,
                columns: [{
                        label: this.$t('etebs.module'),
                        field: `module`,
                    },
                    {
                        label: this.$t('etebs.notification_date'),
                        field: `notificationDate`,
                        formatFn: this.$formatFn,
                    },
                    {
                        label: this.$t('etebs.application_no'),
                        field: `applicationNo`,
                    },
                    {
                        label: this.$t('etebs.document_no'),
                        field: `documentNo`,
                    },
                    {
                        label: this.$t('etebs.document_entry_no'),
                        field: `documentEntryNumber`,
                    },
                    {
                        label: this.$t('etebs.responsible'),
                        field: `responsible`,
                    },
                    {
                        label: this.$t('etebs.document_type'),
                        field: `documentType`,
                    },
                    {
                        label: this.$t('etebs.note'),
                        field: `note`,
                    },
                    {
                        label: 'Process',
                        field: 'process',
                        width: '15em'
                    },
                ],
                rows: [],
                searchTerm: '',
                moduleSelect: 2,
                moduleOption: [],
                password: '',
                isTransfered: false,
                moduleId:0,
                recordId:0
            }
        },
        mounted() {
            this.getEtebsList()
        },
        methods: {
            async getInfo(id) {
                this.$refs.etebsFormModal.showInfo(id)
             },
             async getUploadFromEtebsInfo() {
                this.$refs.uploadFromEtebsFormModal.showInfo()
             },
            getEtebsList() {
                this.rows = []
                const controller = { name: 'Etebs' }
                const data = { moduleId: this.moduleSelect, 
                    pageLength: this.pageLength, 
                    pageNo: this.pageNo, 
                    isTransfered: this.isTransfered}
                const payload = { data: data, controller: controller}
                let loader = this.$loading.show()
                this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        response.data.etebsList.forEach(element => {
                            this.rows.push(element)
                        })
                        this.moduleOption = response.data.moduleList
                        this.moduleOption.splice(0, 0, this.$nullSelected())
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                    loader.hide()
                })
            },
            async download(data) {
                data = { id: data.documentId }
                const controller = { name: 'Document' }
                const payload = { data: data, controller: controller }
                let loader = this.$loading.show()
                this.$store.dispatch('moduleAuth/download', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile(response.data.document.name, response.data.base64, response.data.document.type)
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                    loader.hide()
                })        
            },
            transfered(operationRecord, documentId) {
                this.$refs.openJobListFormModal.showInfo(operationRecord.moduleId, operationRecord.recordId, documentId)
            }
        },
    }
    </script>
    
    <style lang="scss">
    .excel-css {
        float: right;
        margin-right: 1em;
        width: 9.5em;
        color: #7367f0;
        border: 1px solid #7367f0;
        background-color: transparent;
        margin-left: 1em;
        height: 2em;
        border-radius: 0.358rem;
    }
    
    [dir] .dark-layout .card {
        background-color: #252d3f !important;
        box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
    }
    
    .akista-d-flex {
        display: contents !important;
    }
    
    /* Solid border */
    hr.solid {
        border-top: 3px solid #bbb;
    }
    
    .tableSize {
        font-size: 14px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    
    .featherIconReSize {
        width: 12px;
        height: 12px;
    }
    </style>
    