<template>
    <div>
        <!-- modal -->
        <b-modal id="nationalPatentInventorFormModal" centered size="lg" no-close-on-backdrop ref="nationalPatentInventorFormModal" :title="$t('nationalPatentInventor.inventor_info')" 
        @close="clear()" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
            <validation-observer ref="infoRules" tag="form">
                <b-row>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <label for="national-patent-inventor-Name-input">{{ $t('nationalPatentInventor.name') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('nationalPatentInventor.name')"
                                    rules="required">
                                    <b-form-input size="sm" id="national-patent-inventor-Name-input" v-model="nationalPatentInventorName"
                                        maxlength="50" :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <label for="national-patent-inventor-nationality-id-number-input">{{ $t('nationalPatentInventor.nationality_id_number') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('nationalPatentInventor.nationality_id_number')"
                                    rules="required">
                                    <b-form-input size="sm" id="national-patent-inventor-nationality-id-number-input" v-model="nationalityIDNumber"
                                        maxlength="11" :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <validation-provider :name="$t('nationalPatentInventor.country')" #default="{ errors }"
                                :rules="{ excluded: 0 }">
                                <label for="country-input">{{ $t('nationalPatentInventor.country') }}</label>
                                <b-form-select v-model="countrySelect" value-field="id" text-field="name" size="sm"
                                    :options="countryOption" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12">
                        <label for="textarea-default">{{ $t('nationalPatentInventor.address') }}</label>
                        <validation-provider #default="{ errors }" :name="$t('nationalPatentInventor.address')"
                            rules="required">
                            <b-form-textarea id="textarea-default" :placeholder="$t('nationalPatentInventor.address')"
                                v-model="inventorAddress" rows="2" maxlength="250"
                                :state="errors.length > 0 ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>
 
<script>
export default {
    props: {
        getNationalPatentInventorList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            nationalPatentId:0,
            nationalPatentInventorName: '',
            inventorAddress: '',
            countryOption: [],
            countrySelect: 0,
            nationalityIDNumber : 0,
        }
    },
    methods: {
        showInfo(id, nationalPatentId) {
            this.$refs['nationalPatentInventorFormModal'].show()
            this.id= id
            this.nationalPatentId= nationalPatentId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'NationalPatentInventor'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.nationalPatentInventorName = response.data.nationalPatentInventor.name
                    this.inventorAddress = response.data.nationalPatentInventor.address
                    this.nationalityIDNumber = response.data.nationalPatentInventor.nationalityIDNumber
                    this.countrySelect = response.data.nationalPatentInventor.countryId
                    this.countryOption = response.data.countryList
                    this.countryOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('nationalPatentInventorFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
            this.nationalPatentInventorName = null
            this.inventorAddress = null
            this.countrySelect = 0
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                nationalPatentId: this.nationalPatentId,
                name: this.nationalPatentInventorName,
                address: this.inventorAddress,
                countryId: this.countrySelect,
                nationalityIDNumber : this.nationalityIDNumber
            }
            const controller = {
                name: 'NationalPatentInventor'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('nationalPatentInventorFormModal')
                            this.getNationalPatentInventorList()
                            this.$SaveAlert()
                            this.getNationalPatentList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        }
    },
}
</script>

    
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
