<template>
<div>
    <!-- modal -->
    <b-modal id="annualLeaveTypeFormModal" centered size="lg" no-close-on-backdrop ref="annualLeaveTypeFormModal" :title="$t('annualLeaveType.days_off_reason_info')" :cancel-title="$t('others.cancel_title')" :ok-title="$t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-10">
                    <b-form>
                        <label for="annualLeaveType-daysDoesntCountAsDaysOff-input">{{ $t("annualLeaveType.days_doesnt_count_as_days_off") }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('annualLeaveType.days_doesnt_count_as_days_off')" rules="required">
                                <b-form-input size="sm" id="annualLeaveType-daysDoesntCountAsDaysOff-input" v-model="daysDoesntCountAsDaysOff" maxlength="50" type="number" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-2">
                    <label for="annualLeaveType-isOfficial-input">{{ $t('annualLeaveType.is_official') }}</label>
                    <br>
                    <b-form-checkbox class="custom-control-success" name="is-official-input" id="is-official-input" v-model="isOfficial" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12">
                    <b-form>
                        <label for="annualLeaveType-name-input">{{$t("annualLeaveType.name")}}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('annualLeaveType.name')" rules="required">
                                <b-form-input size="sm" id="annualLeaveType-name-input" v-model="name" maxlength="100" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getAnnualLeaveTypeList: {
            type: Function,
        },
    },
    data() {
        return {
            id: 0,
            name: "",
            daysDoesntCountAsDaysOff: 0,
            isOfficial: false,
        };
    },
    methods: {
        showInfo(id) {
            this.$refs['annualLeaveTypeFormModal'].show()
            this.id = id;
            const data = {
                id: this.id,
            };
            const controller = {
                name: "AnnualLeaveType",
            };
            const payload = {
                data: data,
                controller: controller,
            };
            this.$store.dispatch("moduleAuth/getInfo", payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.name = response.data.annualLeaveType.name;
                    this.daysDoesntCountAsDaysOff = response.data.annualLeaveType.daysDoesntCountAsDaysOff;
                    this.isOfficial = response.data.annualLeaveType.isOfficial;
                } else {
                    this.$bvModal.hide("annualLeaveTypeFormModal");
                    this.$WarningAlert(response.data.resultMessage);
                }
            });
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.name,
                daysDoesntCountAsDaysOff: this.daysDoesntCountAsDaysOff,
                isOfficial: this.isOfficial,
            };
            const controller = {
                name: "AnnualLeaveType",
            };
            const payload = {
                data: data,
                controller: controller,
            };
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    this.$store.dispatch("moduleAuth/save", payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide("annualLeaveTypeFormModal");
                            this.getAnnualLeaveTypeList();
                            this.$SaveAlert();
                        } else {
                            this.$WarningAlert(response.data.resultMessage);
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss">
[dir="ltr"] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important;
}
</style>
