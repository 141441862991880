var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"documentsFormModal",attrs:{"id":"documentsFormModal","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('documents.documents_info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('documents.jurisdiction'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-jurisdiction-input"}},[_vm._v(_vm._s(_vm.$t('documents.jurisdiction')))]),_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null,"value-field":"id","size":"sm","text-field":"name","options":_vm.documentJurisdictionOption},model:{value:(_vm.documentJurisdictionSelect),callback:function ($$v) {_vm.documentJurisdictionSelect=$$v},expression:"documentJurisdictionSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('documents.document_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wordTemplates-input"}},[_vm._v(_vm._s(_vm.$t('documents.document_name')))]),_c('b-input-group',[_c('b-form-input',{attrs:{"size":"sm","id":"document-name-input","maxlength":"50","state":errors.length > 0 ? false:null},model:{value:(_vm.documentName),callback:function ($$v) {_vm.documentName=$$v},expression:"documentName"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"size":"sm","variant":"outline-primary","onclick":"document.getElementById('documentsFileSelect').click()"}},[_vm._v(_vm._s(_vm.$t('others.select'))+" ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-file',{staticStyle:{"display":"none"},attrs:{"size":"sm","id":"documentsFileSelect","accept":"*.*","placeholder":_vm.$t('documents.select_documents'),"drop-placeholder":_vm.$t('documents.select_documents_info')},on:{"change":_vm.uploadFile}})],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('documents.documents_type'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-documents-type-input"}},[_vm._v(_vm._s(_vm.$t('documents.documents_type')))]),_c('b-form-select',{attrs:{"size":"sm","state":errors.length > 0 ? false:null,"value-field":"id","text-field":"name","options":_vm.documentTypeOption},on:{"change":function($event){return _vm.getDocumentDetailList()}},model:{value:(_vm.documentTypeSelect),callback:function ($$v) {_vm.documentTypeSelect=$$v},expression:"documentTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('documents.documents_content'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-documents-content-input"}},[_vm._v(_vm._s(_vm.$t('documents.document_content')))]),_c('b-form-select',{attrs:{"size":"sm","state":errors.length > 0 ? false:null,"value-field":"id","text-field":"name","options":_vm.documentContentOption},model:{value:(_vm.documentContentSelect),callback:function ($$v) {_vm.documentContentSelect=$$v},expression:"documentContentSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"col-6"},[_c('label',{attrs:{"for":"document-date"}},[_vm._v(_vm._s(_vm.$t('documents.date')))]),_c('CustomDatePicker',{attrs:{"datePickerValidateOption":0},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('b-col',{staticClass:"col-6"},[_c('b-form',[_c('label',{attrs:{"for":"document-type"}},[_vm._v(_vm._s(_vm.$t('documents.type')))]),_c('b-form-group',[_c('b-form-input',{attrs:{"size":"sm","id":"document-type","disabled":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('b-form',[_c('label',{attrs:{"for":"note-input"}},[_vm._v(_vm._s(_vm.$t('documents.note')))]),_c('b-form-group',[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('documents.note'),"rows":"3"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }