<template>
<div>
    <!-- modal -->
    <b-modal id="mailHistoryFormModal" centered size="lg" no-close-on-backdrop ref="mailHistoryFormModal" :title=" $t('mailHistory.info')" :cancel-title=" $t('others.cancel_title')" cancel-variant="outline-secondary" hide-footer>
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col>
                        <label for="mail-history-record-no-input">{{ $t('mailHistory.record_no') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="mail-history-record-no-input" v-model="recordNo" disabled />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-6 mb-1">
                        <label for="mail-history-module-input">{{ $t('mailHistory.module') }}</label>
                        <b-form-select v-model="moduleSelect" size="sm" value-field="id" text-field="name" disabled :options="moduleOption" />
                    </b-col>
                    <b-col class="col-lg-6 mb-1">
                        <label for="mail-history-mailTemplate-input">{{ $t('mailHistory.mail_template') }}</label>
                        <b-form-select v-model="mailTemplateSelect" size="sm" value-field="id" text-field="explanation" disabled :options="mailTemplateOption" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-6">
                        <label for="mail-history-mailAccount-input">{{ $t('mailHistory.mail_account') }}</label>
                        <b-form-select v-model="mailAccountSelect" size="sm" value-field="id" text-field="displayName" disabled :options="mailAccountOption" />
                    </b-col>
                    <b-col class="col-lg-6">
                        <label for="mail-history-date-input">{{ $t('mailHistory.date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <label for="mail-history-mailTo-input">{{ $t('mailHistory.mail_to') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="mail-history-mailTo-textarea" v-model="mailTo" disabled />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <label for="mail-history-subject">{{ $t('mailHistory.subject') }}</label>
                        <b-form-textarea size="sm" id="mail-history-subject" v-model="subject" disabled rows="3" max-rows="8" maxlength="250" />
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getMailHistoryList: {
            type: Function
        },
    },
    data() {
        return {
            subject: '',
            mailTo: '',
            mailAccountSelect: 0,
            mailAccountOption: [],
            moduleSelect: 0,
            moduleOption: [],
            mailTemplateSelect: 0,
            mailTemplateOption: [],
            recordNo: '',
            date: null,
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['mailHistoryFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'MailHistory'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.moduleOption = response.data.moduleList
                    this.mailTemplateOption = response.data.mailTemplateList
                    this.mailAccountOption = response.data.mailAccountList
                    if (response.data.mailHistory.moduleId > 0) {
                        this.moduleSelect = response.data.mailHistory.moduleId

                    } else {
                        this.moduleSelect = 0
                    }
                    if (response.data.mailHistory.mailAccountId > 0) {
                        this.mailAccountSelect = response.data.mailHistory.mailAccountId
                    } else {
                        this.mailAccountSelect = 0
                    }
                    if (response.data.mailHistory.mailTemplateId > 0) {
                        this.mailTemplateSelect = response.data.mailHistory.mailTemplateId
                    } else {
                        this.mailTemplateSelect = 0
                    }
                    this.subject = response.data.mailHistory.subject
                    this.mailTo = response.data.mailHistory.mailTo
                    this.date = response.data.mailHistory.date
                    this.recordNo = response.data.mailHistory.recordNo
                    this.mailTemplateOption.splice(0, 0, this.$nullSelected('explanation'))
                    this.mailAccountOption.splice(0, 0, this.$nullSelected('displayName'))
                    this.moduleOption.splice(0, 0, this.$nullSelected())

                } else {
                    this.$bvModal.hide('mailHistoryFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.vueditor {
    min-width: 300px !important;
    min-height: 50em !important;
    height: 95% !important;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
