<template>
    <div>
      <!-- table -->
      <vue-good-table 
      id="clientAccountingDifferentInvoiceReportTable"
      ref="clientAccountingDifferentInvoiceReportTable" 
      :columns="columns" 
      :rows="rows" 
      styleClass="vgt-table condensed tableSize"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" 
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'client'" class="text-nowrap">
            {{ $t('clientAccounting.client') }}
          </span>
          <span v-else-if="props.column.field === 'clientAccountingNo'" class="text-nowrap">
            {{ $t('clientAccounting.account_number') }}
          </span>
          <span v-else-if="props.column.field === 'specialInvoiceAccountingNo'" class="text-nowrap">
            {{ $t('clientAccounting.special_invoice_client_accounting_no') }}
          </span>
          <span v-else-if="props.column.field === 'speciallnvoiceClient'" class="text-nowrap">
            {{ $t('clientAccounting.special_invoice_client') }}
          </span>
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
              </span>
              <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        pageLength: 10,
        dir: false,
        columns: [
          {
            label: this.$t('clientAccounting.client'),
            field: `client`,
            filterOptions: {
                enabled: true,
                placeholder: this.$t('clientAccounting.client'),
            },
          },
          {
            label: this.$t('clientAccounting.account_number'),
            field: 'clientAccountingNo',
            filterOptions: {
                enabled: true,
                placeholder: this.$t('clientAccounting.account_number'),
            },
          },
          {
            label: this.$t('clientAccounting.special_invoice_client_accounting_no'),
            field: `specialInvoiceAccountingNo`,
            filterOptions: {
                enabled: true,
                placeholder: this.$t('clientAccounting.special_invoice_client_accounting_no'),
            },
          },
          {
            label: this.$t('clientAccounting.special_invoice_client'),
            field: `specialInvoiceClient`,
            filterOptions: {
                enabled: true,
                placeholder: this.$t('clientAccounting.special_invoice_client'),
            },
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      this.getDifferentInvoiceReportList()
    },
    methods: {
      getDifferentInvoiceReportList() {
        this.rows = []
        const controller = { name: 'ClientAccounting', actionName:'SpecialInvoiceReportList' }
        const payload = { controller: controller }
        this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
          if (response.data.resultStatus == true) {
            response.data.clientAccountingSpecialInvoiceReportList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
    },
  }
  </script>
  <style lang="scss" >
  .excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
  }
  .tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
  </style>
  