<template>
<div>
    <!-- modal -->
    <b-modal id="dailyworkFormModal" centered size="xl" no-close-on-backdrop ref="dailyworkFormModal" :title="dailyworkTitle +' '+recordNo" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-3">
                    <validation-provider :name="$t('dailywork.service')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                        <label for="dailywork-not-accounting-service-input">{{ $t('dailywork.service') }}</label>
                        <b-form-select size="sm" :state="errors.length > 0 ? false : null" @change="serviceChange()" v-model="serviceSelect" value-field="serviceId" text-field="service" :options="serviceOption" />
                        <small class="text-danger">{{ errors[0]  }}</small>
                    </validation-provider>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <b-form-group :label="$t('dailywork.invoice_agent')" label-for="dailywork-not-accounting-invoice-agent-input">
                            <v-select size="sm" id="dailywork-not-accounting-invoice-agent-input" v-model="dailyworkInvoiceAgentSelect" :options="dailyworkInvoiceAgentOption" @input="setClientId" label="clientName" @search="onInvoiceClientAccountingSearch">
                            </v-select>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3 ">
                    <b-form>
                        <label for="dailywork-not-accounting-agent-input">{{ $t('dailywork.agent') }}</label>
                        <b-form-group>
                            <b-form-input disabled size="sm" id="dailywork-not-accounting-agent-input" v-model="agent" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="dailywork-not-accounting-owner-input">{{ $t('dailywork.owner') }}</label>
                        <b-form-group>
                            <b-form-input disabled size="sm" id="dailywork-not-accounting-owner-input" v-model="ownerClient" />
                        </b-form-group>
                    </b-form>
                </b-col>

            </b-row>
            <b-row>
                <b-col class="col-lg-3">
                    <label for="dailywork-date-input">{{ $t('dailywork.date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="1" v-model="date"></CustomDatePicker>
                </b-col>

                <b-col class="col-lg-3">
                    <label for="dailywork-not-accounting-not-document-input">{{ $t('dailywork.not_document') }}</label>
                    <b-form>
                        <b-form-checkbox class="custom-control-success" name="dailywork-not-accounting-not-document-input" id="dailywork-not-accounting-not-document-input" v-model="notDocument" switch>
                            <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                            </span>
                        </b-form-checkbox>
                    </b-form>
                </b-col>

                <b-col v-if="notDocument == false" class="col-lg-3">
                    <label for="dailywork-not-accounting-invoice-agent-input">{{ $t('dailywork.document_no') }}</label>
                    <b-form-input size="sm" id="dailywork-not-accounting-document-no-input" v-model="documentNo" :placeholder="$t('dailywork.document_no')" />
                </b-col>

                <b-col v-if="notDocument == false && paymentTypeId == 3" class="col-lg-3">
                    <label for="dailywork-not-accounting-date-input">{{ $t('dailywork.document_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="1" v-model="documentDate"></CustomDatePicker>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3 mt-1">
                    <label for="dailywork-invoice-accounting-billing-status-input">{{ $t('dailywork.billing_status') }}</label>
                    <b-form-group>
                        <b-form-select size="sm" v-model="billingStatusSelect" value-field="id" text-field="name" :options="billingStatusOption" />
                    </b-form-group>
                </b-col>

                <b-col class="col-lg-3 mt-1">
                    <label for="dailywork-invoice-accounting-ledes-input">{{ $t('dailywork.ledes') }}</label>
                    <b-form-group>
                        <b-form-select size="sm" v-model="ledesSelect" value-field="id" text-field="name" :options="ledesOption" />
                    </b-form-group>
                </b-col>

                <b-col class="col-lg-3 mt-1">
                    <label for="job-employee-input">{{ $t('dailywork.employee') }}</label>
                    <b-form>
                        <validation-provider :name="$t('dailywork.employee')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false : null" size="sm" v-model="employeeSelect" value-field="id" @change="costingTypeChange()" text-field="name" :options="employeeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6">
                    <label for="dailywork-not-accounting-description-input">{{ $t('dailywork.description') }}</label>
                    <b-form-textarea size="sm" id="dailywork-not-accounting-description-input" :placeholder="$t('dailywork.description')" v-model="description" rows="3" max-rows="8" maxlength="250" />
                </b-col>
                <b-col class="col-lg-6">
                    <label for="dailywork-not-not-accounting-description-input">{{ $t('dailywork.accounting_description') }}</label>
                    <b-form-textarea id="dailywork-not-not-accounting-description-input" :placeholder="$t('dailywork.accounting_description')" size="sm" v-model="accountingDescription" rows="3" maxlength="250" max-rows="8" />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-2 mt-1">
                    <label for="dailywork-invoice-accounting-const-type-input">{{ $t('dailywork.costing_type') }}</label>
                    <b-form-group>
                        <b-form-select size="sm" v-model="costingTypeSelect" value-field="id" text-field="name" @change="costingTypeChange()" :options="costingTypeOption" />
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label v-if="costingTypeSelect == 3" for="dailywork-not-accounting-count-input">{{ $t('dailywork.hours') }}</label>
                    <label v-else for="dailywork-not-accounting-count-input">{{ $t('dailywork.count') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-count-input" v-model="count" :onChange="priceCalculation()" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label v-if="costingTypeSelect == 2" for="dailywork-unit-price-input">{{ $t('dailywork.price') }}</label>
                    <label v-else for="dailywork-unit-price-input">{{ $t('dailywork.unit_price') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-unit-price-input" v-model="unitPrice" :onChange="priceCalculation()" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label for="dailywork-not-accounting-currency-input">{{ $t('dailywork.currency') }}</label>
                    <b-form-group>
                        <b-form-select size="sm" v-model="currencySelect" value-field="id" text-field="code" :options="currencyOption" />
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label for="dailywork-withholding-tax-input">{{ $t('dailywork.withholding_tax') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-withholding-tax-input" v-model="withholdingTax" :onChange="priceCalculation()" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label for="dailywork-not-accounting-amount-input">{{ $t('dailywork.amount') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-amount-input" disabled v-model="price" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2" v-if="isHourVisibility == true"></b-col>
                <b-col class="col-lg-2" v-if="isHourVisibility == false">
                    <label for="dailywork-not-accounting-hour-input">{{ $t('dailywork.hour') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="dailywork-not-accounting-hour-input" v-model="hour" type="number" maxlength="6" />
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-not-accounting-discount-rate-input">{{ $t('dailywork.discount_rate') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-discount-rate-input" v-model="discountRate" :onChange="priceCalculation()" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-not-accounting-discount-input">{{ $t('dailywork.discount') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-discount-input" disabled v-model="discount" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-not-accounting-vat-rate-input">{{$t('dailywork.vat_rate')}}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-vat-rate-input" v-model="vatRate" :onChange="priceCalculation()" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-not-accounting-vat-input">{{ $t('dailywork.vat') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-vat-input" disabled v-model="vat" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-not-accounting-total-input">{{ $t('dailywork.total') }}</label>
                    <b-form-group>
                        <cleave disabled id="dailywork-not-accounting-total-input" v-model="total" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDailyworkList: {
            type: Function
        },
    },
    data() {
        return {
            number: {
                numeral: true,
            },
            id: 0,
            paymentTypeId: 0,
            recordId: 0,
            moduleId: 0,
            count: 0,
            priceListId: 0,
            currencyOption: [],
            currencySelect: 0,
            employeeOption: [],
            employeeSelect: 0,
            costingTypeOption: [],
            costingTypeSelect: 0,
            billingStatusOption: [],
            billingStatusSelect: 0,
            price: 0.0,
            withholdingTax: 0.0,
            hour: 0,
            vat: 0,
            vatRate: 0,
            discount: 0,
            discountRate: 0,
            total: 0,
            serviceOption: [],
            serviceSelect: 0,
            ownerClient: '',
            agent: '',
            invoiceAgent: '',
            ledesOption: [],
            ledesSelect: 0,
            notDocument: false,
            documentNo: '',
            date: null,
            documentDate: null,
            description: '',
            accountingDescription: '',
            dailyworkInvoiceAgentSelect: 0,
            dailyworkInvoiceAgentOption: [],
            isHourVisibility: true,
            dailyworkTitle: '',
            employeeId: 0,
            officialFeeId: 0,
            recordNo: '',
            unitPrice: 0,
            cleaveOptions: {
                prefix: '',
                numeral: true,
                numeralPositiveOnly: true,
                noImmediatePrefix: true,
                rawValueTrimPrefix: true,
                numeralIntegerScale: 9,
                numeralDecimalScale: 2
            },
            // cleaveOptionsForCount: {
            //     delimiters: [',', ',', ','],
            //     blocks: [3, 2, 2, 2],
            //     uppercase: true
            // },
        }
    },
    methods: {
        setClientId(event) {
            if (event != null) {
                if (this.dailyworkInvoiceAgentSelect.forInvoice == false) {
                    this.clientId = null;
                    this.dailyworkInvoiceAgentSelect = null;
                    this.$ForInvoiceAlert()
                } else {
                    this.clientId = event.clientId
                }
            } else {
                this.clientId = null;
            }
        },
        async serviceChange() {
            this.description = this.serviceOption.find(p => p.serviceId == this.serviceSelect).service.split('|')[1]
            await this.getServiceValue()
            this.getLedesCodes()
            this.priceCalculation()
        },
        async getServiceValue() {
            var priceListDetailId = this.serviceOption.find(p => p.serviceId == this.serviceSelect).priceListDetailId
            const data = {
                id: priceListDetailId
            }
            const controller = {
                name: 'Service',
                actionName: 'GetServiceByPriceListDetailId'
            }
            const payload = {
                data: data,
                controller: controller
            }
            await this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                this.count = 1
                this.costingTypeSelect = response.data.service.costingTypeId
                this.vatRate = 0
            })
            const priceData = {
                priceListDetailId: priceListDetailId,
                currencyId: this.currencySelect
            }
            const priceController = {
                name: 'Service',
                actionName: 'GetServicePriceByPriceListDetailIdAndCurrencyId'
            }
            const pricePayload = {
                data: priceData,
                controller: priceController
            }
            await this.$store.dispatch('moduleAuth/customService', pricePayload).then((priceResponse) => {
                this.unitPrice = parseFloat(priceResponse.data.price).toFixed(2)
            })
        },
        async getLedesCodes() {
            this.ledesOption = []
            const data = {
                serviceId: this.serviceSelect,
                invoiceClientAccountingId: this.dailyworkInvoiceAgentSelect.clientAccountingId
            }

            const controller = {
                name: 'LedesDetail',
                actionName: 'GetLedesDetailListByServiceIdAndInvoiceClientAccountingId'
            }
            const payload = {
                data: data,
                controller: controller
            }
            await this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                this.ledesOption = response.data.ledesDetailList
                if (this.ledesOption == null) {
                    this.ledesOption = []
                }
                if (this.ledesOption != null) {
                    this.ledesOption.splice(0, 0, this.$nullSelected())
                }
            })

        },
        costingTypeChange() {
            if (this.costingTypeSelect == 3) {
                this.isHourVisibility = false;
                //STOCK-1144 Nevzat
                if (this.paymentTypeId == 2) {
                    this.getEmployeePrice();
                }
                //
            } else {
                this.isHourVisibility = true;

            }
        },
        priceCalculation() {
            this.price = (parseFloat(this.count * parseFloat(this.$decimalValFunc(this.unitPrice)).toFixed(2)) + parseFloat(this.$decimalValFunc(this.withholdingTax))).toFixed(2)
            this.discount = (this.price * this.discountRate / 100).toFixed(2)
            this.vat = ((this.price - this.discount) * this.vatRate / 100).toFixed(2)
            this.total = (parseFloat(this.price) - parseFloat(this.discount) + parseFloat(this.vat)).toFixed(2)
        },
        onInvoiceClientAccountingSearch(invoiceClientAccountingSearch, loading) {
            if (invoiceClientAccountingSearch.length) {
                loading(true);
                this.invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, this);
            }
        },
        invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, vm) {
            if (invoiceClientAccountingSearch.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: invoiceClientAccountingSearch
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.dailyworkInvoiceAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        showInfo(id, moduleId, recordId, paymentTypeId) {
            this.$refs['dailyworkFormModal'].show()
            if (paymentTypeId == 1) {
                this.dailyworkTitle = this.$t('dailywork.invoice_and_accounting')
            } else if (paymentTypeId == 2) {
                this.dailyworkTitle = this.$t('dailywork.not_invoice')
            } else if (paymentTypeId == 3) {
                this.dailyworkTitle = this.$t('dailywork.not_accounting')
            }

            this.id = id
            this.recordId = recordId
            this.moduleId = moduleId
            this.paymentTypeId = paymentTypeId

            const data = {
                id: this.id,
                moduleId: this.moduleId,
                recordId: this.recordId,
                paymentTypeId: this.paymentTypeId,
            }
            const controller = {
                name: 'DailyWork'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.serviceOption = response.data.priceListDetailForDailyWorkList
                    if (response.data.priceListDetailForDailyWorkList == null) {
                        this.priceListId = 0
                    } else {
                        this.priceListId = response.data.priceListDetailForDailyWorkList[0].priceListId
                    }
                    this.price = parseFloat(response.data.dailywork.price).toFixed(2)
                    this.unitPrice = parseFloat(response.data.dailywork.unitPrice).toFixed(2)
                    this.discountRate = response.data.dailywork.discount;
                    this.vatRate = response.data.dailywork.vat
                    this.withholdingTax = parseFloat(response.data.dailywork.withholdingTax).toFixed(2)
                    this.count = parseFloat(response.data.dailywork.count)
                    this.ledesSelect = response.data.dailywork.ledesDetailId
                    this.notDocument = response.data.dailywork.isDocumentNoNotAvailable
                    this.moduleId = response.data.dailywork.moduleId
                    this.documentNo = response.data.dailywork.documentNo
                    this.documentDate = response.data.dailywork.documentDate
                    this.date = response.data.dailywork.date
                    this.description = response.data.dailywork.description
                    this.currencyOption = response.data.currencyList
                    this.currencySelect = response.data.dailywork.currencyId
                    this.employeeOption = response.data.employeeList
                    this.employeeSelect = response.data.dailywork.employeeId
                    this.costingTypeOption = response.data.costingTypeList
                    this.costingTypeSelect = response.data.dailywork.costingTypeId
                    this.billingStatusOption = response.data.billingStatusList
                    this.agent = response.data.dailywork.agent
                    this.ownerClient = response.data.dailywork.ownerClient
                    this.officialFeeId = response.data.dailywork.officialFeeId
                    this.serviceSelect = response.data.dailywork.serviceId
                    this.hour = parseFloat(response.data.dailywork.hour)
                    this.recordNo = response.data.dailywork.recordNo
                    if (this.serviceOption != null) {
                        this.serviceOption.splice(0, 0, this.$nullSelectedByNames('serviceId', 'service'))
                    } else if (this.currencyOption != null) {
                        this.currencyOption.splice(0, 0, this.$nullSelected('code'))
                    } else if (this.employeeOption != null) {
                        this.employeeOption.splice(0, 0, this.$nullSelected())
                    } else if (this.costingTypeOption != null) {
                        this.costingTypeOption.splice(0, 0, this.$nullSelected())
                    } else if (this.billingStatusOption != null) {
                        this.billingStatusOption.splice(0, 0, this.$nullSelected())
                    }
                    this.costingTypeChange()
                    this.priceCalculation()
                    if (response.data.dailywork.id == 0)
                        this.billingStatusSelect = 1
                    else
                        this.billingStatusSelect = response.data.dailywork.billingStatusId

                    if (response.data.dailywork.employeeId != null && response.data.dailywork.employeeId != 0) {
                        this.employeeSelect = response.data.dailywork.employeeId
                    } else {
                        this.employeeSelect = localStorage.getItem('EmployeeId')
                    }

                    if (response.data.dailywork.invoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.dailywork.invoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.dailyworkInvoiceAgentOption = clientAccountingResponse.data.clientList
                            this.dailyworkInvoiceAgentSelect = clientAccountingResponse.data.clientList[0]
                        })
                        this.getLedesCodes()
                    } else {
                        this.dailyworkInvoiceAgentOption = []
                        this.dailyworkInvoiceAgentSelect = 0;
                    }
                } else {
                    this.$bvModal.hide('dailyworkFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                moduleId: this.moduleId,
                recordId: this.recordId,
                currencyId: this.currencySelect,
                price: parseFloat(this.total),
                unitPrice: parseFloat(this.unitPrice),
                vat: this.vatRate,
                withholdingTax: parseFloat(this.withholdingTax),
                count: parseFloat(this.count),
                ledesDetailId: this.ledesSelect,
                isDocumentNoNotAvailable: this.notDocument,
                documentNo: this.documentNo,
                documentDate: this.documentDate,
                date: this.date,
                description: this.description,
                invoiceClientAccountingId: this.dailyworkInvoiceAgentSelect.clientAccountingId,
                employeeId: this.employeeSelect,
                serviceId: this.serviceSelect,
                costingTypeId: this.costingTypeSelect,
                discount: this.discountRate,
                priceListId: this.priceListId,
                hour: parseFloat(this.hour),
                officialFeeId: this.officialFeeId,
                billingStatusId: this.billingStatusSelect,
            }
            const controller = {
                name: 'DailyWork'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('dailyworkFormModal')
                            this.getDailyworkList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    //STOCK-1144
        getEmployeePrice() {
            const data = {
                employeeId: this.employeeSelect,
                currencyId : this.currencySelect
            }
            const controller = {
                name: 'EmployeePrice',
                actionName: "GetEmployeePriceByCurrencyIdAndEmployeeId"
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService',payload).then((response) =>{
                 if(response.data.resultStatus == true && response.data.employeePrice != null){
                    this.unitPrice = parseFloat(response.data.employeePrice.price).toFixed(2)
                 }
            })
        },
    }
    //
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.cleaveStyle {
    max-height: 3em !important;
    height: 2.2em !important;
}
</style>
