<template>
<div>
    <b-row>
        <b-col class="col-12">
            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-input size="sm" v-model="searchTerm" :placeholder="$t('others.search_name')" type="text" class="d-inline-block" />
                    </div>
                </b-form-group>
            </div>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="priceListAgentsTable" ref="priceListAgentsTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
        searchFn: myFunc
      }" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: false,
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'code'" class="text-nowrap">
                {{ $t('priceListAgents.agent_with_account_code') }}

            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">

            </span>
        </template>
    </vue-good-table>
</div>
</template>

  
<script>
export default {
    props: {
        priceListId: Number,
    },
    data() {
        return {
            dir: false,
            columns: [{
                label: this.$t('priceListAgents.agent_with_account_code'),
                field: `code`,
            }, ],
            rows: [],
            searchTerm: '',
        }
    },
    computed: {
        myFunc(data) {
            var text = data.searchTerm
            var trMap = {
                'çÇ': 'c',
                'ğĞ': 'g',
                'şŞ': 's',
                'üÜ': 'u',
                'ıİ': 'i',
                'öÖ': 'o',
                'i': 'i',
                'İ': 'İ',
                'ı': 'ı',
                'I': 'I'
            };
            for (var key in trMap) {
                text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
            }
            return text.replace(/[^-a-zA-Z0-9\s]+/ig, '')
                .replace(/\s/gi, "-")
                .replace(/[-]+/gi, "-")
                .toLowerCase();
        },
    },
    mounted() {
        this.getPriceListAgentsList()
    },
    methods: {
        getPriceListAgentsList() {
            this.rows = []
            const data = {
                id: this.priceListId
            }
            const controller = {
                name: 'PriceList',
                actionName: 'ListPriceListUsingClients'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    if (response.data.clientAccountingList != null) {
                        response.data.clientAccountingList.forEach(element => {
                            this.rows.push(element)
                        })
                    }
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    },
}
</script>
  
<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
