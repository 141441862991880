<template>
<div>
    <!-- modal -->
    <b-modal id="trademarkSearchFormModal" centered size="xl" no-close-on-backdrop ref="trademarkSearchFormModal" :title=" $t('trademarkSearch.trademark_search_info')" hide-footer @close="clear()">
        <agent-note-form ref="agentNoteFormModal"></agent-note-form>
        <client-form ref="clientFormModal"></client-form>
        <reminder-form ref="reminderFormModal"></reminder-form>
        <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
        <div class="custom-search d-flex justify-content mb-1">
            <b-row style="margin-right: auto;">
                <b-media no-body>
                    <b-media-aside>
                        <b-img :src="image" style="height:auto;" class="uploading-image" />
                    </b-media-aside>
                    <b-media-body>
                        <b-dropdown style="width: 4em; height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                            <template #button-content>
                                <feather-icon icon="DownloadIcon" size="10" class="align-middle" />
                            </template>
                            <b-form-file ref="refInputEl" accept=".jpg, .png, .gif, .jpeg" :hidden="true" plain @change="uploadImage" />
                            <b-dropdown-item @click="$refs.refInputEl.$el.click()" size="sm">
                                <span> {{$t('others.select_image')}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="downloadLogo" size="sm">
                                <span> {{$t('others.download')}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-media-body>
                </b-media>
            </b-row>
            <b-row style="margin-left: auto;">
                <b-col>
                    <b-button variant="outline-primary" size="sm" class="ml-05" @click.prevent="addOrUpdate">
                        {{$t('others.ok_title')}}
                    </b-button>
                    <b-dropdown v-if="id > 0" class="ml-05" style="height: 2.1em;" size="sm" :text="$t('others.invoices')" variant="outline-primary">
                        <b-dropdown-item @click="getClientInvoiceReport(2)">{{$t('others.InvoicesToBePaid')}}: {{ invoicesToBePaid }}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(3)">{{$t('others.AllInvoices')}}: {{ allInvoices }}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(4)">{{$t('others.InvoicesToBePaidForThisJob')}}: {{invoicesToBePaidForThisJob}}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(1)">{{$t('others.AllInvoicesRelatedToThisJob')}}: {{ allInvoicesRelatedToThisJob }}</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                        <template #button-content>
                            <feather-icon icon="PrinterIcon" size="10" class="align-middle" />
                        </template>
                        <div class="dropdown-scroll">
                            <b-dropdown-item @click="downloadTrademarkSearchWordFile(wordTemplate.id, wordTemplate.name)" v-for="wordTemplate in wordTemplateList" :key="wordTemplate.id">
                                <span>{{ wordTemplate.name }}</span>
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                        <template #button-content>
                            <feather-icon icon="SendIcon" size="10" class="align-middle" />
                        </template>
                        <div class="dropdown-scroll">
                            <b-dropdown-item @click="prepareTrademarkSearchMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                <span>{{mailTemplate.explanation}}</span>
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <b-button variant="outline-primary" class="ml-05" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                        <feather-icon icon="BellIcon" size="10" class="align-middle" />
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <b-row>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-search-record-no-input">{{ $t('trademarkSearch.record_no') }}</label>
                    <b-form-group>
                        <b-form-input id="trademark-search-record-no-input" size="sm" v-model="recordNo" disabled />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-search-type-input">{{ $t('trademarkSearch.trademark_search_type') }}</label>
                    <b-form-select v-model="trademarkSearchTypeSelect" value-field="id" size="sm" text-field="name" :options="trademarkSearchTypeOption" />
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <validation-observer ref="simpleRules">
                    <b-form>
                        <label for="trademark-search-name-input">{{ $t('trademarkSearch.name') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('trademarkSearch.name')" rules="required">
                                <b-form-input id="trademark-search-trademark-name-input" size="sm" v-model="trademarkName" maxlength="250" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-search-application-type-input">{{ $t('trademarkSearch.trademark_search_application_type') }}</label>
                    <b-form-select v-model="trademarkSearchApplicationTypeSelect" value-field="id" size="sm" text-field="name" :options="trademarkSearchApplicationTypeOption" />
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-search-registration-chance-input">{{ $t('trademarkSearch.registration_chance') }}</label>
                    <b-input-group>
                        <b-form-select v-model="trademarkSearchRegistrationChanceSelect" value-field="id" size="sm" text-field="name" :options="trademarkSearchRegistrationChanceOption" />
                        <b-input-group-append style="width: 15%;">
                            <b-form-input id="trademark-search-registration-chance-rate-input" size="sm" v-model="registrationChanceRate" type="number" />
                        </b-input-group-append>
                    </b-input-group>
                    <b-input-group>
                    </b-input-group>
                </b-form>
            </b-col>
            <!--ToDo-->
            <!-- <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-search-registration-chance-input">{{ $t('trademarkSearch.registration_chance') }}</label>
                    <b-form-select v-model="trademarkSearchRegistrationChanceSelect" value-field="id" size="sm" text-field="name" :options="trademarkSearchRegistrationChanceOption" />
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <validation-observer ref="simpleRules">
                    <b-form>
                        <label for="trademark-search-registration-chance-rate-input">{{ $t('trademarkSearch.registration_chance_rate') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('trademarkSearch.registration_chance_rate')" rules="required">
                                <b-form-input id="trademark-search-registration-chance-rate-input" size="sm" v-model="registrationChanceRate" type:number :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </b-col> -->
            <!--ToDo-->
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-search-use-chance-input">{{ $t('trademarkSearch.use_chance') }}</label>
                    <b-input-group>
                        <b-form-select v-model="trademarkSearchUseChanceSelect" value-field="id" size="sm" text-field="name" :options="trademarkSearchUseChanceOption" />
                        <b-input-group-append style="width: 15%;">
                            <b-form-input size="sm" v-model="useChanceRate" type="number" />
                        </b-input-group-append>
                    </b-input-group>
                    <b-input-group>
                    </b-input-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="trademark-search-agent-ref-no-input">{{ $t('trademarkSearch.agent_ref_no') }}</label>
                    <b-form-group>
                        <b-form-input id="trademark-search-agent-ref-no-input" size="sm" v-model="agentReferenceNo" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <label for="trademark-search-instruction-date-input">{{ $t('trademarkSearch.instruction_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="instructionDate"></CustomDatePicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-3">
                <label for="trademark-search-critical-date-input">{{ $t('trademarkSearch.critical_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="criticalDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-3">
                <label for="trademark-search-client-critical-date-input">{{ $t('trademarkSearch.client_critical_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="clientCriticalDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-2">
                <label for="trademark-search-is51CRisk-input">{{ $t('trademarkSearch.is_51CRisk') }}</label>
                <br>
                <b-form-checkbox class="custom-control-success" name="trademark-search-is51CRisk-input" id="trademark-search-is51CRisk-input" v-model="is51CRisk" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
            <b-col class="col-md-2">
                <label for="trademark-search-isUrgent-input">{{ $t('trademarkSearch.is_urgent') }}</label>
                <br>
                <b-form-checkbox class="custom-control-success" name="trademark-search-isUrgent-input" id="trademark-search-isUrgent-input" v-model="isUrgent" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
            <b-col class="col-md-2">
                <label for="trademark-search-isConflict-input">{{ $t('trademarkSearch.is_conflict') }}</label>
                <br>
                <b-form-checkbox class="custom-control-success" name="trademark-search-isConflict-input" id="trademark-search-isConflict-input" v-model="isConflict" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row v-if="hiddenControl == false">
            <b-col class="col-lg-12 mt-1">
                <b-form-group>
                    <label for="trademark-search-agent-input"> {{ $t('trademarkSearch.agent') }} </label>
                    <b-input-group class="d-flex align-items-center">
                        <b-form-group label-for="trademark-search-agent-input" class="flex-grow-1 mb-0">
                            <v-select id="trademark-search-agent-input" v-model="trademarkSearchAgentSelect" :options="trademarkSearchAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                        </b-form-group>
                        <b-input-group-append>
                            <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                <template #button-content>
                                    <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                </template>
                                <b-dropdown-item @click="getClientInfo()">
                                    <span>{{this.$t('others.add_client')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getAgentNoteInfo(trademarkSearchAgentSelect.clientAccountingId)">
                                    <span>{{this.$t('others.show_note')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getClientShowInfo(trademarkSearchAgentSelect.clientId)">
                                    <span>{{this.$t('others.show_agent')}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-else-if="hiddenControl == true">
            <b-col class="col-lg-12 mt-1">
                <b-form-group>
                    <label for="trademark-search-agent-input"> {{ $t('trademarkSearch.agent') }} </label>
                    <b-input-group class="d-flex align-items-center">
                        <b-form-group label-for="trademark-search-agent-input" class="flex-grow-1 mb-0">
                            <v-select id="trademark-search-agent-input" v-model="trademarkSearchAgentSelect" :options="trademarkSearchAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                        </b-form-group>
                        <b-input-group-append>
                            <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                <template #button-content>
                                    <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                </template>
                                <b-dropdown-item @click="getClientInfo()">
                                    <span>{{this.$t('others.add_client')}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="getClientShowInfo(trademarkSearchAgentSelect.clientId)">
                                    <span>{{this.$t('others.show_agent')}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <b-form-group :label="$t('trademarkSearch.invoice_client_accounting')" label-for="trademarkSearchInvoiceClientAccounting">
                        <b-input-group class="d-flex align-items-center">
                            <b-form-group label-for="trademark-search-agent-input" class="flex-grow-1 mb-0">
                                <v-select id="trademarkSearchInvoiceClientAccounting" v-model="trademarkSearchInvoiceClientSelect" :options="trademarkSearchInvoiceClientOption" @input="setClientIdWithInvoiceClientCheck" label="clientName" @search="onInvoiceClientAccountingSearch">
                                </v-select>
                            </b-form-group>
                            <b-input-group-append>
                                <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                    <template #button-content>
                                        <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                    </template>
                                    <b-dropdown-item @click="getClientInfo()">
                                        <span>{{this.$t('others.add_client')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getAgentNoteInfo(trademarkSearchInvoiceClientSelect.clientAccountingId)">
                                        <span>{{this.$t('others.show_note')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getClientShowInfo(trademarkSearchInvoiceClientSelect.clientId)">
                                        <span>{{this.$t('others.show_agent')}}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>

                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-12 mt-1">
                <h4 style="text-align: center; place-content: center;">{{ $t('trademarkSearch.nice_class') }}</h4>
                <b-form-group>
                    <b-form-input size="sm" disabled :value="niceClassSelectString" maxlength="250" />
                    <b-form-checkbox-group id="trademark-nice-class-type-input" v-model="niceClassSelect" :options="sortedArray" value-field="name" text-field="name" class="demo-inline-spacing" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-6 mt-1">
                <label for="goods-textarea-default">{{ $t('trademarkSearch.goods') }}</label>
                <b-form-textarea id="goods-textarea-default" v-model="goods" rows="5" maxlength="400" />
            </b-col>
            <b-col class="col-md-6 mt-1">
                <label for="note-textarea-default">{{ $t('trademarkSearch.note') }}</label>
                <b-form-textarea id="note-textarea-default" v-model="note" rows="5" maxlength="400" />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-12 mt-3 mb-3">
                <h4 style="text-align: center; place-content: center;">{{ $t('trademarkSearch.database') }}</h4>
                <b-form-checkbox-group id="trademarkSearch-database-input" :state="databaseSelected" v-model="databaseSelect" :options="databaseOption" value-field="id" text-field="name" class="demo-inline-spacing" />
            </b-col>
        </b-row>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title=" $t('trademarkSearch.responsible')" style="width:100%;">
                            <b-card-text>
                                <trademark-search-responsible-list :trademarkSearchId="this.id" :clientId="this.clientId"></trademark-search-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab active :title=" $t('trademarkSearch.owner')" style="width:100%;">
                            <b-card-text>
                                <trademark-search-owner-list :getClientInfo="getClientInfo" :getTrademarkSearchList="getTrademarkSearchList" :trademarkSearchId="this.id"></trademark-search-owner-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('trademarkSearch.history')" style="width:100%;">
                            <b-card-text>
                                <trademark-search-history-list :getTrademarkSearchList="getTrademarkSearchList" :trademarkSearchId="this.id" :clientId="this.clientId"></trademark-search-history-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('trademarkSearch.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="3"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.job')" style="width:100%;">
                            <b-card-text>
                                <job-list :recordId="this.id" :moduleId="3" :recordNo="this.recordNo"></job-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="3"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getTrademarkSearchList: {
            type: Function
        },
    },
    computed: {
        niceClassSelectString() {
            return this.niceClassSelect.sort().join(' ');
        },
        sortedArray() {
            return this.niceClassOption.sort((a, b) => a.id - b.id);
        },
        databaseSelected() {
            if (this.id == 0) {
                if (this.niceClassSelect.indexOf('05') != -1) {
                    this.databaseOption.forEach(el => {
                        if (el.id == 12) {
                            this.databaseIdList.push(el.id)
                        } else if (el.id == 11) {
                            this.databaseIdList.push(el.id)
                        } else if (el.id == 10) {
                            this.databaseIdList.push(el.id)
                        }
                        this.databaseSelect = this.databaseIdList
                    })
                } else if (this.niceClassSelect.indexOf('03') != -1) {
                    this.databaseOption.forEach(el => {
                        if (el.id == 11) {
                            this.databaseIdList.push(el.id)
                        }
                        this.databaseSelect = this.databaseIdList
                    })
                } else if (this.niceClassSelect.indexOf('10') != -1) {
                    this.databaseOption.forEach(el => {
                        if (el.id == 10) {
                            this.databaseIdList.push(el.id)
                        }
                        this.databaseSelect = this.databaseIdList
                    })
                } else if (this.niceClassSelect.indexOf('41') != -1) {
                    this.databaseOption.forEach(el => {
                        if (el.id == 13) {
                            this.databaseIdList.push(el.id)
                        } else if (el.id == 14) {
                            this.databaseIdList.push(el.id)
                        }
                        this.databaseSelect = this.databaseIdList
                    })
                } else if (this.niceClassSelect.indexOf('09') != -1) {
                    this.databaseOption.forEach(el => {
                        if (el.id == 14) {
                            this.databaseIdList.push(el.id)
                        }
                        this.databaseSelect = this.databaseIdList
                    })
                }
            }
        }
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            recordNo: '',
            trademarkName: '',
            trademarkSearchTypeSelect: 0,
            trademarkSearchTypeOption: [],
            trademarkSearchRegistrationChanceSelect: 0,
            trademarkSearchRegistrationChanceOption: [],
            trademarkSearchUseChanceSelect: 0,
            trademarkSearchUseChanceOption: [],
            trademarkSearchApplicationTypeSelect: 0,
            trademarkSearchApplicationTypeOption: [],
            trademarkSearchAgentSelect: 0,
            trademarkSearchAgentOption: [],
            trademarkSearchInvoiceClientOption: [],
            trademarkSearchInvoiceClientSelect: 0,
            agentReferenceNo: '',
            agentagentReferenceNoRefNo: '',
            instructionDate: null,
            criticalDate: null,
            clientCriticalDate: null,
            niceClass: '',
            database: '',
            goods: '',
            note: '',
            hiddenControl: true,
            niceClassOption: [],
            niceClassSelect: [],
            databaseOption: [],
            databaseSelect: [],
            image: null,
            isUrgent: false,
            isConflict: false,
            is51CRisk: false,
            registrationChanceRate: 0,
            useChanceRate: 0,
            databaseIdList: [],
            wordTemplateList: [],
            mailTemplateList: [],
            invoicesToBePaidForThisJob: 0,
            invoicesToBePaid: 0,
            allInvoices: 0,
            allInvoicesRelatedToThisJob: 0,
            clientAccountingId: 0,
        }
    },
    methods: {
        getClientInvoiceReport(type) {
            this.$refs.clientInvoiceReportListCallForm.showInfo(type, this.clientAccountingId, 3, this.id)
        },
        onInvoiceClientAccountingSearch(invoiceClientAccountingSearch, loading) {
            if (invoiceClientAccountingSearch.length) {
                loading(true);
                this.invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, this);
            }
        },
        invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, vm) {
            if (invoiceClientAccountingSearch.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: invoiceClientAccountingSearch
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkSearchInvoiceClientOption = response.data.clientList
                    loading(false)
                })
            }
        },
        uploadImage(event) {
            var input = event.target;
            if (this.id != 0) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.image = e.target.result;
                        var base64 = this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                        const data = {
                            fileName: this.trademarkSearchId,
                            base64: base64
                        }
                        const controller = {
                            name: 'TrademarkSearch',
                            actionName: 'SaveTrademarkSearchLogo'
                        };
                        const payload = {
                            data: data,
                            controller: controller
                        };
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.getTrademarkSearchList()
                                this.$PleaseClickSave()
                            }
                        })
                    }
                    reader.readAsDataURL(input.files[0]);
                }
            } else if (this.id == 0) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.image = e.target.result;
                    };
                    reader.readAsDataURL(input.files[0]);
                }
            }
        },
        downloadLogo() {
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TrademarkSearch',
                actionName: 'DownloadTrademarkSearchLogo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    const link = document.createElement('a')
                    link.href = 'data:image/jpeg;base64,' + response.data.base64
                    link.download = this.recordNo + '.jpg'
                    link.click()
                    this.getTrademarkSearchList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getClientShowInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        async getReminderInfo() {
            this.$refs.reminderFormModal.showInfo(0, this.id, 2)
        },
        setClientId(event) {
            this.clientId = event.clientId
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkSearchAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        clear() {
            this.image = null
            this.databaseIdList = [],
                this.databaseOption = [],
                this.databaseSelect = []
        },
        showInfo(id) {
            this.$refs['trademarkSearchFormModal'].show()
            this.id = id
            this.databaseSelect = []

            const data = {
                id: this.id
            }
            const controller = {
                name: 'TrademarkSearch'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.trademarkName = response.data.trademarkSearch.name
                    this.recordNo = response.data.trademarkSearch.recordNo
                    this.trademarkSearchTypeSelect = response.data.trademarkSearch.trademarkSearchTypeId
                    this.trademarkSearchTypeOption = response.data.trademarkSearchTypeList
                    this.trademarkSearchRegistrationChanceSelect = response.data.trademarkSearch.registrationChanceId
                    this.trademarkSearchRegistrationChanceOption = response.data.trademarkSearchRegistrationChanceRateList
                    this.trademarkSearchUseChanceSelect = response.data.trademarkSearch.useChanceId
                    this.trademarkSearchUseChanceOption = response.data.trademarkSearchUseChanceRateList
                    this.trademarkSearchApplicationTypeSelect = response.data.trademarkSearch.trademarkApplicationTypeId
                    this.trademarkSearchApplicationTypeOption = response.data.trademarkApplicationTypeList
                    this.agentReferenceNo = response.data.trademarkSearch.agentReferenceNo
                    this.instructionDate = response.data.trademarkSearch.instructionDate
                    this.criticalDate = response.data.trademarkSearch.criticalDate
                    this.clientCriticalDate = response.data.trademarkSearch.clientCriticalDate
                    this.registrationChanceRate = response.data.trademarkSearch.registrationChanceRate
                    this.useChanceRate = response.data.trademarkSearch.useChanceRate
                    this.is51CRisk = response.data.trademarkSearch.is51CRisk
                    this.isUrgent = response.data.trademarkSearch.isUrgent
                    this.isConflict = response.data.trademarkSearch.isConflict
                    this.trademarkSearchTypeOption.splice(0, 0, this.$nullSelected())
                    this.trademarkSearchUseChanceOption.splice(0, 0, this.$nullSelected())
                    this.trademarkSearchRegistrationChanceOption.splice(0, 0, this.$nullSelected())
                    this.trademarkSearchApplicationTypeOption.splice(0, 0, this.$nullSelected())
                    this.mailTemplateList = response.data.mailTemplateList
                    this.wordTemplateList = response.data.wordTemplateList
                    this.$emit('passToisUrgent', this.isUrgent);
                    if (response.data.trademarkSearch.niceClass !== null) {
                        this.niceClass = response.data.trademarkSearch.niceClass
                        this.niceClassSelect = response.data.trademarkSearch.niceClass.split(' ').sort()
                    } else {
                        this.niceClassSelect = []
                        this.niceClass = null
                    }
                    this.goods = response.data.trademarkSearch.goods
                    this.note = response.data.trademarkSearch.note
                    this.trademarkSearchInvoiceClientSelect = response.data.trademarkSearch.invoiceClientAccountingId
                    this.niceClassOption = response.data.niceClassList
                    this.databaseOption = response.data.trademarkSearchDatabaseList
                    // if (response.data.trademarkSearchSelectedDatabaseList != null) {
                    //     response.data.trademarkSearchSelectedDatabaseList.forEach(ele => {
                    //         this.databaseSelect.push(ele.id)
                    //     })
                    // }
                    if (this.id == 0) {
                        this.hiddenControl = true
                        if (response.data.trademarkSearchDatabaseList != null) {
                            response.data.trademarkSearchDatabaseList.forEach(ele => {
                                if (ele.id == 4) {
                                    this.databaseIdList.push(ele.id)
                                } else if (ele.id == 6) {
                                    this.databaseIdList.push(ele.id)
                                } else if (ele.id == 7) {
                                    this.databaseIdList.push(ele.id)
                                } else if (ele.id == 8) {
                                    this.databaseIdList.push(ele.id)
                                } else if (ele.id == 9) {
                                    this.databaseIdList.push(ele.id)
                                }
                                this.databaseSelect = this.databaseIdList
                            })
                        }
                    } else {
                        //trademarkSearchHistoryDatabase
                        this.hiddenControl = false
                        const databaseData = {
                            id: this.id
                        }
                        const databaseController = {
                            name: 'TrademarkSearchDatabaseHistory',
                            actionName: 'GetTrademarkSearchDatabaseHistoryListByTrademarkSearchId'
                        }
                        const databasePayload = {
                            data: databaseData,
                            controller: databaseController
                        }
                        this.$store.dispatch('moduleAuth/customService', databasePayload).then((databaseResponse) => {
                            databaseResponse.data.trademarkSearchDatabaseHistoryList.forEach(ele => {
                                this.databaseIdList.push(ele.trademarkSearchDatabaseId)
                            })
                            this.databaseSelect = this.databaseIdList
                        })
                    }
                    var nonBase64Image = 'data:image/jpeg;base64,'.concat(response.data.trademarkSearch.logo)
                    this.image = nonBase64Image

                    // Client Accounting Codes 
                    if (response.data.trademarkSearch.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.trademarkSearch.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.trademarkSearchAgentOption = clientAccountingResponse.data.clientList
                            this.trademarkSearchAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.trademarkSearchAgentOption = []
                        this.trademarkSearchAgentSelect = 0;
                    }
                    if (response.data.trademarkSearch.invoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.trademarkSearch.invoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.trademarkSearchInvoiceClientOption = clientAccountingResponse.data.clientList
                            this.trademarkSearchInvoiceClientSelect = clientAccountingResponse.data.clientList[0]
                        })
                    } else {
                        this.trademarkSearchInvoiceClientOption = []
                        this.trademarkSearchInvoiceClientSelect = 0;
                    }
                    if (this.id > 0) {
                        let accountingId = 0
                        if (response.data.trademarkSearch.clientAccountingId > 0)
                            accountingId = response.data.trademarkSearch.clientAccountingId
                        if (response.data.trademarkSearch.invoiceClientAccountingId > 0)
                            accountingId = response.data.trademarkSearch.invoiceClientAccountingId
                        this.clientAccountingId = accountingId

                        const invoiceData = {
                            clientAccountingId: accountingId,
                            moduleId: 3,
                            recordId: this.id
                        }
                        const invoiceController = {
                            name: 'ClientAccounting',
                            actionName: 'GetInvoiceCountByClientAccountId'
                        }
                        const invoicePayload = {
                            data: invoiceData,
                            controller: invoiceController
                        }
                        this.$store.dispatch('moduleAuth/customService', invoicePayload).then((invoiceResponse) => {
                            this.invoicesToBePaid = invoiceResponse.data.invoicesToBePaidCount
                            this.allInvoices = invoiceResponse.data.allInvoiceCount
                            this.allInvoicesRelatedToThisJob = invoiceResponse.data.allInvoicesRelatedToThisJobCount
                            this.invoicesToBePaidForThisJob = invoiceResponse.data.invoicesToBePaidForThisJobCount
                        })

                    } else {
                        this.invoicesToBePaid = 0
                        this.allInvoices = 0;
                        this.allInvoicesRelatedToThisJob = 0;
                        this.invoicesToBePaidForThisJob = 0;
                    }
                } else {
                    this.$bvModal.hide('trademarkSearchFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadTrademarkSearchWordFile(documentId, name) {
            const data = {
                moduleId: 3,
                documentId: documentId,
                trademarkSearchId: this.id
            }
            const controller = {
                name: 'TrademarkSearch',
                actionName: 'DownloadTrademarkSearchWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            debugger
            const data = {
                id: this.id,
                name: this.trademarkName,
                recordNo: this.recordNo,
                trademarkSearchTypeId: this.trademarkSearchTypeSelect,
                registrationChanceId: this.trademarkSearchRegistrationChanceSelect,
                useChanceId: this.trademarkSearchUseChanceSelect,
                is51CRisk: this.is51CRisk,
                isConflict: this.isConflict,
                isUrgent: this.isUrgent,
                registrationChanceRate: this.registrationChanceRate,
                useChanceRate: this.useChanceRate,
                trademarkApplicationTypeId: this.trademarkSearchApplicationTypeSelect,
                agentReferenceNo: this.agentReferenceNo,
                instructionDate: this.instructionDate,
                criticalDate: this.criticalDate,
                clientCriticalDate: this.clientCriticalDate,
                niceClass: this.niceClass,
                goods: this.goods,
                note: this.note,
                niceClass: this.niceClassSelectString,
                trademarkSearchDatabaseHistoryList: this.databaseSelect,
                clientAccountingId: this.trademarkSearchAgentSelect.clientAccountingId,
                invoiceClientAccountingId: this.trademarkSearchInvoiceClientSelect.clientAccountingId,
                logo: this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
            }
            const controller = {
                name: 'TrademarkSearch'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (this.id == 0) {
                        var base64 = this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                        const data = {
                            fileName: this.trademarkSearchId,
                            base64: base64
                        }
                        const controller = {
                            name: 'TrademarkSearch',
                            actionName: 'SaveTrademarkSearchLogo'
                        }
                        const payload = {
                            data: data,
                            controller: controller
                        }
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.getTrademarkSearchList()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getTrademarkSearchList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.trademarkSearchId
                                this.trademarkSearchRefCode = response.data.recordNo
                                this.hiddenControl = false
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        prepareTrademarkSearchMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                trademarkSearchId: this.id
            }
            const controller = {
                name: 'TrademarkSearch',
                actionName: 'PrepareTrademarkSearchMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        setClientIdWithInvoiceClientCheck(event) {
            if (event != null) {
                if (this.trademarkSearchInvoiceClientSelect.forInvoice == false) {
                    this.clientId = null;
                    this.trademarkSearchInvoiceClientSelect = null;
                    this.$ForInvoiceAlert()
                } else {
                    this.clientId = event.clientId
                }
            } else {
                this.clientId = null;
            }
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] body.dark-layout .b-calendar-grid.form-control .small {
    background: #161d31;
    display: none !important;
}

.b-calendar .b-calendar-grid-help {
    color: #6e6b7b !important;
    display: none;
}

[dir=ltr] [class*=collapse-] .card .card-header {
    padding: 1rem 2.8rem 1rem 1rem;
    place-content: center;
}

.uploading-image {
    display: flex;
    width: 35em;
    height: 30em;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
