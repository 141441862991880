<template>
<div>
    <!-- modal -->
    <patent-trados-upload-form @tradosValues="tradosValuesFunction" ref="patentTradosUploadFormModal"></patent-trados-upload-form>
    <b-modal id="patentTranslateFormModal" centered size="xl" no-close-on-backdrop hide-footer ref="patentTranslateFormModal" :title="$t('translate.translate_info')" @close="clear()" @ok.prevent="addOrUpdate">
        <div class="custom-search d-flex justify-content-end">
            <b-row>
                <b-col>
                    <b-button size="sm" variant="outline-primary" class="mr-1" @click="addTrados()">{{
                        $t('translate.add_from_trados') }}
                    </b-button>
                    <b-button variant="outline-primary" size="sm" @click.prevent="addOrUpdate">
                        {{$t('others.ok_title')}}
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <validation-observer ref="infoRules" tag="form">
            <b-row>
                <b-col class="col-md-6 mt-1">
                    <b-form>
                        <validation-provider :name="$t('translate.translator')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <label for="translator-input">{{ $t('translate.translator') }}</label>
                            <b-form-select v-model="translatorSelect" value-field="id" text-field="name" size="sm" :options="translatorOption" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-6 mt-1">
                    <b-form>
                        <validation-provider :name="$t('translate.language')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <label for="language-input">{{ $t('translate.language') }}</label>
                            <b-form-select v-model="languageSelect" value-field="id" text-field="name" size="sm" :options="languageOption" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3 mt-1">
                    <label for="translate-delivery-to-translator-date-input">{{ $t('translate.delivery_to_translator_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0"  v-model="deliveryToTranslatorDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-md-3 mt-1">
                    <label for="translate-translator-deadline-date-input">{{ $t('translate.translator_deadline_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="translatorDeadlineDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-md-3 mt-1">
                    <label for="translate-translator-delivery-date-input">{{ $t('translate.translator_delivery_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="translatorDeliveryDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-md-2 mt-1">
                    <label for="translate-is-turkish-translate-came-from-agent-input">{{ $t('translate.is_turkish_translate_came_from_agent') }}</label>
                    <b-form-checkbox class="custom-control-success" name="translate-is-turkish-translate-came-from-agent-input" id="translate-is-turkish-translate-came-from-agent-input" v-model="isTurkishTranslateCameFromAgent" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <b-form>
                        <label for="translate-formula-count-input">{{ $t('translate.formula_count') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('translate.formula_count')" rules="required">
                                <b-form-input id="translate-formula-count-input" size="sm" v-model="formulaCount" type="number" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <b-form>
                        <label for="translate-image_count-input">{{ $t('translate.image_count') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('translate.image_count')" rules="required">
                                <b-form-input id="translate-image-count-input" size="sm" v-model="imageCount" type="number" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <b-form>
                        <label for="translate-table-count-input">{{ $t('translate.table_count') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('translate.table_count')" rules="required">
                                <b-form-input id="translate-table-count-input" size="sm" v-model="tableCount" type="number" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <b-form>
                        <label for="translate-word-count-input">{{ $t('translate.word_count') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('translate.word_count')" rules="required">
                                <b-form-input id="translate-word-count-input" size="sm" v-model="wordCount" type="number" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <b-form>
                        <label for="translate-page-count-input">{{ $t('translate.page_count') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('translate.page_count')" rules="required">
                                <b-form-input id="translate-page-count-input" size="sm" v-model="pageCount" type="number" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-2">
                    <label for="translate-payment-date-input">{{ $t('translate.payment_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="paymentDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-md-2">
                    <b-form>
                        <label for="translate-payment-amount-input">{{ $t('translate.payment_amount') }}</label>
                        <b-form-group>
                            <b-form-input id="translate-payment-amount-input" size="sm" v-model="paymentAmount" type="number" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-2">
                    <label for="translate-is-payment-done-input">{{ $t('translate.is_payment_done') }}</label>
                    <b-form-checkbox class="custom-control-success" name="translate-is-payment-done-input" id="translate-is-payment-done-input" v-model="isPaymentDone" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </validation-observer>
        <b-row v-if="((this.perfectMatch * 0) + (this.contextMatch * 0) + (this.repetitions * 0) + (this.crossFileRepetitions * 0) + (this.hunderedPercent * 0) + (this.ninetyFiveBetweenNinetyNinePercent * 0.1) + (this.eightyFiveBetweenNinetyFourPercent * 0.2) + (this.seventyFiveBetweenEightyFourPercent * 0.5) + (this.fiftyBetweenSeventyFourPercent * 1) + (this.newAt * 1)) != 0">
            <b-col class="col-md-12 mt-1">
                <table style="width: inherit;">
                    <thead>
                        <tr class="customList">
                            <th class="primary customList">{{ $t('patentTranslate.type') }}</th>
                            <th class="primary customList">{{ $t('patentTranslate.words') }}</th>
                            <th class="primary customList">{{ $t('patentTranslate.percentile') }}</th>
                            <th class="primary customList">{{ $t('patentTranslate.rate') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="customList">
                            <td class="customList">{{ $t('patentTranslate.perfect_match') }}</td>
                            <td class="customList">{{ this.perfectMatch }}</td>
                            <td class="customList">0</td>
                            <td class="customList">{{ this.perfectMatch * 0 }}</td>
                        </tr>
                        <tr class="customList">
                            <td class="customList">{{ $t('patentTranslate.context_match') }}</td>
                            <td class="customList">{{ this.contextMatch }}</td>
                            <td class="customList">0</td>
                            <td class="customList">{{ this.contextMatch * 0 }}</td>
                        </tr>
                        <tr class="customList">
                            <td class="customList">{{ $t('patentTranslate.repetitions') }}</td>
                            <td class="customList">{{ this.repetitions }}</td>
                            <td class="customList">0</td>
                            <td class="customList">{{ this.repetitions * 0 }}</td>
                        </tr>
                        <tr class="customList">
                            <td class="customList">{{ $t('patentTranslate.cross_file_repetitions') }}</td>
                            <td class="customList">{{ this.crossFileRepetitions }}</td>
                            <td class="customList">0</td>
                            <td class="customList">{{ this.crossFileRepetitions * 0 }}</td>
                        </tr>
                        <tr class="customList">
                            <td class="customList">{{ $t('patentTranslate.hundered_percent') }}</td>
                            <td class="customList">{{ this.hunderedPercent }}</td>
                            <td class="customList">0</td>
                            <td class="customList">{{ this.hunderedPercent * 0 }}</td>
                        </tr>
                        <tr class="customList">
                            <td class="customList">{{ $t('patentTranslate.ninety_five_between_ninety_nine_percent') }}</td>
                            <td class="customList">{{ this.ninetyFiveBetweenNinetyNinePercent }}</td>
                            <td class="customList">10</td>
                            <td class="customList">{{ this.ninetyFiveBetweenNinetyNinePercent * 0.1 }}</td>
                        </tr>
                        <tr class="customList">
                            <td class="customList">{{ $t('patentTranslate.eighty_five_between_ninety_four_percent') }}</td>
                            <td class="customList">{{ this.eightyFiveBetweenNinetyFourPercent }}</td>
                            <td class="customList">20</td>
                            <td class="customList">{{ this.eightyFiveBetweenNinetyFourPercent * 0.2 }}</td>
                        </tr>
                        <tr class="customList">
                            <td class="customList">{{ $t('patentTranslate.seventy_five_between_eighty_four_percent') }}</td>
                            <td class="customList">{{ this.seventyFiveBetweenEightyFourPercent }}</td>
                            <td class="customList">50</td>
                            <td class="customList">{{ this.seventyFiveBetweenEightyFourPercent * 0.5 }}</td>
                        </tr>
                        <tr class="customList">
                            <td class="customList">{{ $t('patentTranslate.fifty_between_seventy_four_percent') }}</td>
                            <td class="customList">{{ this.fiftyBetweenSeventyFourPercent }}</td>
                            <td class="customList">100</td>
                            <td class="customList">{{ this.fiftyBetweenSeventyFourPercent * 1 }}</td>
                        </tr>
                        <tr class="customList">
                            <td class="customList">{{ $t('patentTranslate.new_at') }}</td>
                            <td class="customList">{{ this.newAt }}</td>
                            <td class="customList">100</td>
                            <td class="customList">{{ this.newAt * 1 }}</td>
                        </tr>
                        <tr class="customList">
                            <td class="customList">{{ $t('patentTranslate.total') }}</td>
                            <td class="customList">{{ this.perfectMatch + this.contextMatch + this.repetitions + this.crossFileRepetitions + this.hunderedPercent + this.ninetyFiveBetweenNinetyNinePercent + this.eightyFiveBetweenNinetyFourPercent + this.seventyFiveBetweenEightyFourPercent + this.fiftyBetweenSeventyFourPercent + this.newAt }}</td>
                            <td class="customList">0</td>
                            <td class="customList">{{ (this.perfectMatch * 0) + (this.contextMatch * 0) + (this.repetitions * 0) + (this.crossFileRepetitions * 0) + (this.hunderedPercent * 0) + (this.ninetyFiveBetweenNinetyNinePercent * 0.1) + (this.eightyFiveBetweenNinetyFourPercent * 0.2) + (this.seventyFiveBetweenEightyFourPercent * 0.5) + (this.fiftyBetweenSeventyFourPercent * 1) + (this.newAt * 1) }}</td>
                        </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>    
export default {
    props: {
        getPatentTranslateList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            patentId: 0,
            translatorOption: [],
            translatorSelect: 0,
            languageOption: [],
            languageSelect: 0,
            isTurkishTranslateCameFromAgent: false,
            isPaymentDone: false,
            paymentAmount: 0,
            newAt: 0,
            fiftyBetweenSeventyFourPercent: 0,
            seventyFiveBetweenEightyFourPercent: 0,
            eightyFiveBetweenNinetyFourPercent: 0,
            ninetyFiveBetweenNinetyNinePercent: 0,
            hunderedPercent: 0,
            contextMatch: 0,
            perfectMatch: 0,
            crossFileRepetitions: 0,
            repetitions: 0,
            wordCount: 0,
            tableCount: 0,
            pageCount: 0,
            imageCount: 0,
            formulaCount: 0,
            isTurkishTranslateCameFromAgent: false,
            paymentDate: null,
            translatorDeliveryDate: null,
            translatorDeadlineDate: null,
            deliveryToTranslatorDate: null,
            total: 0,
            tradosValuesList : null,
        }
    },
    methods: {
        tradosValuesFunction(val){
            this.contextMatch = val[0].tradosValues.contextMatch
            this.crossFileRepetitions = val[0].tradosValues.crossFileRepetitions
            this.eightyFiveBetweenNinetyFourPercent = val[0].tradosValues.eightyFiveBetweenNinetyFourPercent
            this.fiftyBetweenSeventyFourPercent = val[0].tradosValues.fiftyBetweenSeventyFourPercent
            this.hunderedPercent = val[0].tradosValues.hunderedPercent
            this.newAt = val[0].tradosValues.newAt
            this.ninetyFiveBetweenNinetyNinePercent = val[0].tradosValues.ninetyFiveBetweenNinetyNinePercent
            this.perfectMatch = val[0].tradosValues.perfectMatch
            this.repetitions = val[0].tradosValues.repetitions
            this.seventyFiveBetweenEightyFourPercent = val[0].tradosValues.seventyFiveBetweenEightyFourPercent
        },
        showInfo(id, patentId) {
            this.$refs['patentTranslateFormModal'].show()
            this.id = id
            this.patentId = patentId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'PatentTranslate'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.newAt = response.data.patentTranslate.newAt,
                    this.fiftyBetweenSeventyFourPercent = response.data.patentTranslate.fiftyBetweenSeventyFourPercent,
                    this.seventyFiveBetweenEightyFourPercent = response.data.patentTranslate.seventyFiveBetweenEightyFourPercent,
                    this.eightyFiveBetweenNinetyFourPercent = response.data.patentTranslate.eightyFiveBetweenNinetyFourPercent,
                    this.ninetyFiveBetweenNinetyNinePercent = response.data.patentTranslate.ninetyFiveBetweenNinetyNinePercent,
                    this.hunderedPercent = response.data.patentTranslate.hunderedPercent,
                    this.contextMatch = response.data.patentTranslate.contextMatch,
                    this.perfectMatch = response.data.patentTranslate.perfectMatch,
                    this.crossFileRepetitions = response.data.patentTranslate.crossFileRepetitions,
                    this.repetitions = response.data.patentTranslate.repetitions,
                    this.languageSelect = response.data.patentTranslate.languageId
                    this.languageOption = response.data.languageList
                    this.translatorSelect = response.data.patentTranslate.translatorId
                    this.translatorOption = response.data.translatorList
                    this.isTurkishTranslateCameFromAgent = response.data.patentTranslate.isTurkishTranslateCameFromAgent
                    this.isPaymentDone = response.data.patentTranslate.isPaymentDone
                    this.paymentAmount = response.data.patentTranslate.paymentAmount
                    this.paymentDate = response.data.patentTranslate.paymentDate
                    this.wordCount = response.data.patentTranslate.wordCount
                    this.tableCount = response.data.patentTranslate.tableCount
                    this.pageCount = response.data.patentTranslate.pageCount
                    this.imageCount = response.data.patentTranslate.imageCount
                    this.formulaCount = response.data.patentTranslate.formulaCount
                    this.isTurkishTranslateCameFromAgent = response.data.patentTranslate.isTurkishTranslateCameFromAgent
                    this.translatorDeliveryDate = response.data.patentTranslate.translatorDeliveryDate
                    this.translatorDeadlineDate = response.data.patentTranslate.translatorDeadlineDate
                    this.deliveryToTranslatorDate = response.data.patentTranslate.deliveryToTranslatorDate
                    this.languageOption.splice(0, 0, this.$nullSelected())
                    this.translatorOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('patentTranslateFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
            this.translatorSelect = 0
            this.languageSelect = 0
        },
        addOrUpdate() {
            const data = {
                patentTranslateDto: {
                    id: this.id,
                    patentId: this.patentId,
                    languageId: this.languageSelect,
                    translatorId: this.translatorSelect,
                    isTurkishTranslateCameFromAgent: this.isTurkishTranslateCameFromAgent,
                    isPaymentDone: this.isPaymentDone,
                    paymentAmount: this.paymentAmount,
                    paymentDate: this.paymentDate,
                    wordCount: this.wordCount,
                    tableCount: this.tableCount,
                    pageCount: this.pageCount,
                    imageCount: this.imageCount,
                    formulaCount: this.formulaCount,
                    isTurkishTranslateCameFromAgent: this.isTurkishTranslateCameFromAgent,
                    translatorDeliveryDate: this.translatorDeliveryDate,
                    translatorDeadlineDate: this.translatorDeadlineDate,
                    deliveryToTranslatorDate: this.deliveryToTranslatorDate,
                    perfectMatch: this.perfectMatch,
                    contextMatch: this.contextMatch,
                    repetitions: this.repetitions,
                    crossFileRepetitions: this.crossFileRepetitions,
                    hunderedPercent: this.hunderedPercent,
                    ninetyFiveBetweenNinetyNinePercent: this.ninetyFiveBetweenNinetyNinePercent,
                    eightyFiveBetweenNinetyFourPercent: this.eightyFiveBetweenNinetyFourPercent,
                    seventyFiveBetweenEightyFourPercent: this.seventyFiveBetweenEightyFourPercent,
                    fiftyBetweenSeventyFourPercent: this.fiftyBetweenSeventyFourPercent,
                    newAt: this.newAt,
                }
            }
            const controller = {
                name: 'PatentTranslate'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('patentTranslateFormModal')
                            this.getPatentTranslateList()
                            this.$SaveAlert()
                            this.getPatentList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        addTrados() {
            this.$refs.patentTradosUploadFormModal.showInfo()
        },
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.customList {
    border: 1px solid #999;
    padding: 20px;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
