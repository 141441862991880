<template>
<div>
    <!-- modal -->
    <b-modal id="jobStatusRelationDetailFormModal" centered size="lg" no-close-on-backdrop ref="jobStatusRelationDetailFormModal" :title=" $t('jobStatusRelationDetail.relation_detail_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-form>
                <label for="job-status-relation-detail-status-select">{{ $t('jobStatusRelationDetail.job_status') }}</label>
                <b-form-group>
                    <validation-provider #default="{ errors }" :name=" $t('jobStatusRelationDetail.job_status')" rules="required">
                        <b-form>
                            <b-form-select size="sm" v-model="jobStatusSelect" value-field="id" text-field="name" :options="jobStatusOption" />
                        </b-form>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getJobStatusRelationDetailList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            jobStatusSelect: 0,
            jobStatusOption: [],
            jobStatusRelationId: 0,
        }
    },
    methods: {
        showInfo(id, jobStatusRelationId) {
            this.$refs['jobStatusRelationDetailFormModal'].show()
            this.id = id
            this.jobStatusRelationId = jobStatusRelationId
            const data = {
                id: this.id,
                jobStatusRelationId: this.jobStatusRelationId
            }
            const controller = {
                name: 'JobStatusRelationDetail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.jobStatusOption = response.data.jobStatusList
                    this.jobStatusOption.splice(0, 0, this.$nullSelected())
                    if (response.data.jobStatusRelationDetail.jobStatusId > 0) {
                        this.jobStatusSelect = response.data.jobStatusRelationDetail.jobStatusId
                    } else {
                        this.jobStatusSelect = 0
                    }
                } else {
                    this.$bvModal.hide('jobStatusRelationDetailFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                jobStatusRelationId: this.jobStatusRelationId,
                jobStatusId: this.jobStatusSelect
            }
            const controller = {
                name: 'JobStatusRelationDetail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('jobStatusRelationDetailFormModal')
                            this.getJobStatusRelationDetailList()
                            this.$SaveAlert()
                            this.jobStatusSelect = 0
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
