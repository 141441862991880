<template>
<div>
    <!-- modal -->
    <b-modal id="tpmkExcelSaveFormModal" centered size="xl" no-close-on-backdrop ref="tpmkExcelSaveFormModal" :title="$t('tpmkExcelSaveForm.title')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="saveTpmkExcel">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-4">
                    <label for="dailywork-invoice-accounting-credit-card-input">{{ $t('tpmkExcelSaveForm.credit_card') }}</label>
                    <b-form>
                        <validation-provider v-slot="{ errors }" :name="$t('tpmkExcelSaveForm.credit_card')" :rules="{ excluded:0}" >
                            <b-form-select size="sm" v-model="creditCardSelect" :options="creditCardOption" value-field="id" name="dailywork-invoice-accounting-credit-card-input" text-field="name" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{  errors[0]  }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>

                <b-col class="col-lg-8">
                    <validation-provider #default="{ errors }" :name=" $t('tpmkExcelSaveForm.folder_name')" rules="required">
                        <label for="agent-annuity-folder-name-input">{{ $t('tpmkExcelSaveForm.folder_name') }}</label>
                        <b-input-group>
                            <b-form-input size="sm" id="folder-name-input" v-model="documentName" maxlength="50" :state="errors.length > 0 ? false:null" />
                            <b-input-group-append>
                                <b-button  size="sm" variant="outline-primary" onclick="document.getElementById('tpmkFileSelect').click()">{{ $t('others.select') }}
                                </b-button>
                                <b-form-file size="sm" style="display:none" id="tpmkFileSelect" accept="*.*" @change="uploadTmpkReceipt" :placeholder=" $t('tpmkExcelSaveForm.select_folder')" :drop-placeholder=" $t('tpmkExcelSaveForm.select_folder_info')" />
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>

            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getAnnuityList: {
            type: Function
        },
    },
    data() {
        return {        
            annuityId: 0,
            creditCardOption: [],
            creditCardSelect: 0,    
            documentName: '',
            documentFile: '',
            documentType: '',    
        }
    },
    methods: {
        showInfo(annuityId) {
            this.$refs['tpmkExcelSaveFormModal'].show()
            this.annuityId = annuityId

            const controller = { name: 'CreditCard' }
            const payload = { controller: controller }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.creditCardOption = response.data.creditCardList
                    this.creditCardOption.splice(0, 0, this.$nullSelected())
                }
                else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }, 
        async uploadTmpkReceipt(e) {
            var fileInfo = await this.$getFileInfo(e.target.files[0])
            this.documentName = fileInfo.name
            this.documentType = fileInfo.contentType
            this.documentFile = fileInfo.base64
        },
        async saveTpmkExcel() {
            this.$refs.simpleRules.validate().then(success => {
                const data = {
                    annuityId: this.annuityId,
                    base64: this.documentFile,
                    fileName: this.documentName,
                    documentType: this.documentType ,
                    employeeId: localStorage.getItem('EmployeeId'),
                    creditCardId: this.creditCardSelect    
                }

                const controller = {
                    name: 'annuityDetail',
                    actionName: 'readAnnuityDetailPaymentExcel'
                }
                const payload = {
                    data: data,
                    controller: controller
                }

                if (success) {
                    let loader = this.$loading.show()
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('tpmkExcelSaveFormModal')
                            this.$SaveAlert()
                            this.getAnnuityList()                            
                            this.hiddenClientControl = false
                        } 
                        else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                        loader.hide()
                    })                   
                }
            })      
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
