<template>
<div>
    <patent-form :getPatentList="getPatentList" ref="patentFormModal"></patent-form>
    <patent-xml-upload-form ref="patentXmlUploadForm" :getPatentList="getPatentList"></patent-xml-upload-form>
    <patent-wipo-xml-upload-form ref="patentWipoXmlUploadForm" :getPatentList="getPatentList"></patent-wipo-xml-upload-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="patent-record-no-input">{{ $t('patent.record_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="recordNo" :placeholder="$t('patent.record_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="patent-agent-input">{{ $t('patent.agent') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="agentName" :placeholder="$t('patent.agent')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="patent-owner-name-input">{{ $t('patent.owner_name') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="ownerName" :placeholder="$t('patent.owner_name')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="patent-application-no-input">{{ $t('patent.application_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="applicationNo" :placeholder="$t('patent.application_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-4 mt-2 custom-search d-flex justify-content-end">
                    <div class="d-flex align-items-center">
                        <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getPatentList()" @click="getPatentList()">{{ $t('others.list') }}</b-button>
                        <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{$t('patent.patent_add') }}</b-button>
                        <b-dropdown  style="width: 7em; height: 2.1em;" id="dropdown-grouped" variant="outline-primary" right size="sm" :text="$t('patent.process')">
                            <b-dropdown-item @click="getWipoXml(0)">
                                <span>{{ $t('patent.add_from_wipo_xml') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="getEpoXml(0)">
                                <span>{{ $t('patent.add_from_epo_xml') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="exportExcel(0)">
                                <span>{{ $t('excel.excel') }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1 mt-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-3">
                                <label for="ep_application_no">{{ $t('patent.ep_application_no') }}</label>
                                <div class="d-flex align-items-center">
                                    <b-form-input size="sm" v-model="epApplicationNo" :placeholder="$t('patent.ep_application_no')" type="text" class="d-inline-block" />
                                </div>
                            </b-col>
                            <b-col class="col-3">
                                <label for="ep_patent_no">{{ $t('patent.ep_patent_no') }}</label>
                                <div class="d-flex align-items-center">
                                    <b-form-input size="sm" v-model="epPatentNo" :placeholder="$t('patent.ep_patent_no')" type="text" class="d-inline-block" />
                                </div>
                            </b-col>
                            <b-col class="col-3">
                                <label for="pct_application_no">{{ $t('patent.pct_application_no') }}</label>
                                <div class="d-flex align-items-center">
                                    <b-form-input size="sm" v-model="pctApplicationNo" :placeholder="$t('patent.pct_application_no')" type="text" class="d-inline-block" />
                                </div>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="publication_type">{{ $t('patent.publication_type') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-select v-model="patentPublicationTypeSelect" size="sm" value-field="id" text-field="name" :options="patentPublicationTypeOption" />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="application_date_start">{{ $t('patent.application_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('patent.application_date_start')" size="sm" v-model="applicationDateStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="application_date_end">{{ $t('patent.application_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('patent.application_date_end')" size="sm" v-model="applicationDateEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group class="mr-05">
                                    <label for="patent_type">{{ $t('patent.patent_type') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-select v-model="patentTypeSelect" size="sm" value-field="id" text-field="name" :options="patentTypeOption" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="patent_status">{{ $t('patent.status') }}</label>
                                <div class="d-flex align-items-center">
                                    <b-form-select v-model="patentStatusSelect" size="sm" value-field="id" text-field="name" :options="patentStatusOption" />
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="epb1_publication_date_start">{{ $t('patent.epb1_publication_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.epb1_publication_date_start')" size="sm" v-model="epb1PubDateStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="epb1_publication_date_end">{{ $t('patent.epb1_publication_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.epb1_publication_date_end')" size="sm" v-model="epb1PubDateEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="epb2_publication_date_start">{{ $t('patent.epb2_publication_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.epb2_publication_date_start')" size="sm" v-model="epb2PubDateStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="epb2_publication_date_end">{{ $t('patent.epb2_publication_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.epb2_publication_date_end')" size="sm" v-model="epb2PubDateEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="epb3_publication_date_start">{{ $t('patent.epb3_publication_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.epb3_publication_date_start')" size="sm" v-model="epb3PubDateStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="epb3_publication_date_end">{{ $t('patent.epb3_publication_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.epb3_publication_date_end')" size="sm" v-model="epb3PubDateEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="b2_filing_date_start">{{ $t('patent.b2_filing_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.b2_filing_date_start')" size="sm" v-model="b2FilingDateStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="b2_filing_date_end">{{ $t('patent.b2_filing_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.b2_filing_date_end')" size="sm" v-model="b2FilingDateEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="priority_document_date_start">{{ $t('patent.priority_document_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.priority_document_date_start')" size="sm" v-model="priorityDocumentDateStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="priority_document_date_end">{{ $t('patent.priority_document_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.priority_document_date_end')" size="sm" v-model="priorityDocumentDateEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="priority_document_deadline_start">{{ $t('patent.priority_document_deadline_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.priority_document_deadline_start')" size="sm" v-model="priorityDocumentDeadlineStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="priority_document_deadline_end">{{ $t('patent.priority_document_deadline_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.priority_document_deadline_end')" size="sm" v-model="priorityDocumentDeadlineEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="date_of_grant_notification_start">{{ $t('patent.date_of_grant_notification_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.date_of_grant_notification_start')" size="sm" v-model="dateOfGrantNotificationStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="date_of_grant_notification_end">{{ $t('patent.date_of_grant_notification_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.date_of_grant_notification_end')" size="sm" v-model="dateOfGrantNotificationEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="date_of_grant_start">{{ $t('patent.date_of_grant_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.date_of_grant_start')" size="sm" v-model="dateOfGrantStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="date_of_grant_end">{{ $t('patent.date_of_grant_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.date_of_grant_end')" size="sm" v-model="dateOfGrantEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="date_of_payment_grant_fees_start">{{ $t('patent.date_of_payment_grant_fees_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.date_of_payment_grant_fees_start')" size="sm" v-model="dateOfPaymentOfGrantFeesStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="date_of_payment_grant_fees_end">{{ $t('patent.date_of_payment_grant_fees_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.date_of_payment_grant_fees_end')" size="sm" v-model="dateOfPaymentOfGrantFeesEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="date_of_working_declaration_start">{{ $t('patent.date_of_working_declaration_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.date_of_working_declaration_start')" size="sm" v-model="dateOfWorkingDeclarationStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="date_of_working_declaration_end">{{ $t('patent.date_of_working_declaration_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.date_of_working_declaration_end')" size="sm" v-model="dateOfWorkingDeclarationEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="working_declaration_deadline_start">{{ $t('patent.working_declaration_deadline_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.working_declaration_deadline_start')" size="sm" v-model="workingDeclarationDeadlineStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="working_declaration_deadline_end">{{ $t('patent.working_declaration_deadline_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.working_declaration_deadline_end')" size="sm" v-model="workingDeclarationDeadlineEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="ep_b9_publication_date_start">{{ $t('patent.ep_b9_publication_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.ep_b9_publication_date_start')" size="sm" v-model="ePB9PubDateStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="ep_b9_publication_date_end">{{ $t('patent.ep_b9_publication_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.ep_b9_publication_date_end')" size="sm" v-model="ePB9PubDateEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="ep_publication_date_start">{{ $t('patent.ep_publication_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.ep_publication_date_start')" size="sm" v-model="ePPublicationDateStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="ep_publication_date_end">{{ $t('patent.ep_publication_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.ep_publication_date_end')" size="sm" v-model="ePPublicationDateEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="pct_application_date_start">{{ $t('patent.pct_application_date_start') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.pct_application_date_start')" size="sm" v-model="pCTApplicationDateStart" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="pct_application_date_end">{{ $t('patent.pct_application_date_end') }}</label>
                                <b-form-group>
                                    <b-form-datepicker :placeholder="$t('patent.pct_application_date_end')" size="sm" v-model="pCTApplicationDateEnd" class="mb1" defaultDate:null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="patentTable" ref="patentTable" :columns="columns" :rows="rows" :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }" styleClass="vgt-table condensed tableSizePatent" :select-options="{
  enabled: false,
  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
  selectionInfoClass: 'custom-class',
  selectionText: 'rows selected',
  clearSelectionText: 'clear',
  disableSelectInfo: true, // disable the select info panel on top
  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
  enabled: true,
  perPage: pageLength
}" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('patent.patent_folder_no') }}
            </span>
            <span v-else-if="props.column.field === 'applicationNo'" class="text-nowrap">
                {{ $t('patent.application_info') }}
            </span>
            <span v-else-if="props.column.field === 'title'" class="text-nowrap">
                {{ $t('patent.title') }}
            </span>
            <span v-else-if="props.column.field === 'patentType'" class="text-nowrap">
                {{ $t('patent.patent_type') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('patent.agent_name') }}
            </span>
            <span v-else-if="props.column.field === 'ownerList'" class="text-nowrap">
                {{ $t('patent.owners') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Agent Name -->
            <span v-if="props.column.field === 'agent'" class="text-nowrap">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.agent }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ props.row.clientAccounting }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'applicationInfo'" class="text-nowrap">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.applicationNo }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ $formatFn(props.row.applicationDate) }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'patentType'" class="text-nowrap">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.patentType }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ props.row.patentPublicationType }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'ownerList'">
                <b-col>
                    <b-row v-for="owner in props.row.ownerList" v-bind:data="owner" v-bind:key="owner.clientName" style="font-size: small;">
                        <tr v-if="props.row.ownerList.length != 1">
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                        <tr v-else-if="props.row.ownerList.length == 1">
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }} </span>
                    <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getPatentList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    watch: {
        applicationDateStart(newValue) {
            if (newValue == '') {
                this.applicationDateStart = null
            }
        },
        applicationDateEnd(newValue) {
            if (newValue == '') {
                this.applicationDateEnd = null
            }
        },
        pCTApplicationDateStart(newValue) {
            if (newValue == '') {
                this.pCTApplicationDateStart = null
            }
        },
        pCTApplicationDateEnd(newValue) {
            if (newValue == '') {
                this.pCTApplicationDateEnd = null
            }
        },
        ePPublicationDateStart(newValue) {
            if (newValue == '') {
                this.ePPublicationDateStart = null
            }
        },
        ePPublicationDateEnd(newValue) {
            if (newValue == '') {
                this.ePPublicationDateEnd = null
            }
        },
        ePB9PubDateStart(newValue) {
            if (newValue == '') {
                this.ePB9PubDateStart = null
            }
        },
        ePB9PubDateEnd(newValue) {
            if (newValue == '') {
                this.ePB9PubDateEnd = null
            }
        },
        workingDeclarationDeadlineStart(newValue) {
            if (newValue == '') {
                this.workingDeclarationDeadlineStart = null
            }
        },
        workingDeclarationDeadlineEnd(newValue) {
            if (newValue == '') {
                this.workingDeclarationDeadlineEnd = null
            }
        },
        dateOfWorkingDeclarationStart(newValue) {
            if (newValue == '') {
                this.dateOfWorkingDeclarationStart = null
            }
        },
        dateOfWorkingDeclarationEnd(newValue) {
            if (newValue == '') {
                this.dateOfWorkingDeclarationEnd = null
            }
        },
        epb1PubDateStart(newValue) {
            if (newValue == '') {
                this.epb1PubDateStart = null
            }
        },
        epb1PubDateEnd(newValue) {
            if (newValue == '') {
                this.epb1PubDateEnd = null
            }
        },
        epb2PubDateStart(newValue) {
            if (newValue == '') {
                this.epb2PubDateStart = null
            }
        },
        epb2PubDateEnd(newValue) {
            if (newValue == '') {
                this.epb2PubDateEnd = null
            }
        },
        epb3PubDateStart(newValue) {
            if (newValue == '') {
                this.epb3PubDateStart = null
            }
        },
        epb3PubDateEnd(newValue) {
            if (newValue == '') {
                this.epb3PubDateEnd = null
            }
        },
        b2FilingDateStart(newValue) {
            if (newValue == '') {
                this.b2FilingDateStart = null
            }
        },
        b2FilingDateEnd(newValue) {
            if (newValue == '') {
                this.b2FilingDateEnd = null
            }
        },
        priorityDocumentDateStart(newValue) {
            if (newValue == '') {
                this.priorityDocumentDateStart = null
            }
        },
        priorityDocumentDateEnd(newValue) {
            if (newValue == '') {
                this.priorityDocumentDateEnd = null
            }
        },
        priorityDocumentDeadlineStart(newValue) {
            if (newValue == '') {
                this.priorityDocumentDeadlineStart = null
            }
        },
        priorityDocumentDeadlineEnd(newValue) {
            if (newValue == '') {
                this.priorityDocumentDeadlineEnd = null
            }
        },
        dateOfGrantNotificationStart(newValue) {
            if (newValue == '') {
                this.dateOfGrantNotificationStart = null
            }
        },
        dateOfGrantNotificationEnd(newValue) {
            if (newValue == '') {
                this.dateOfGrantNotificationEnd = null
            }
        },
        dateOfGrantStart(newValue) {
            if (newValue == '') {
                this.dateOfGrantStart = null
            }
        },
        dateOfGrantEnd(newValue) {
            if (newValue == '') {
                this.dateOfGrantEnd = null
            }
        },
        dateOfPaymentOfGrantFeesStart(newValue) {
            if (newValue == '') {
                this.dateOfPaymentOfGrantFeesStart = null
            }
        },
        dateOfPaymentOfGrantFeesEnd(newValue) {
            if (newValue == '') {
                this.dateOfPaymentOfGrantFeesEnd = null
            }
        },
        priorityDocumentDateStart(newValue) {
            if (newValue == '') {
                this.priorityDocumentDateStart = null
            }
        },
        priorityDocumentDateEnd(newValue) {
            if (newValue == '') {
                this.priorityDocumentDateEnd = null
            }
        },
        priorityDocumentDeadlineStart(newValue) {
            if (newValue == '') {
                this.priorityDocumentDeadlineStart = null
            }
        },
        priorityDocumentDeadlineEnd(newValue) {
            if (newValue == '') {
                this.priorityDocumentDeadlineEnd = null
            }
        },
        dateOfGrantNotificationStart(newValue) {
            if (newValue == '') {
                this.dateOfGrantNotificationStart = null
            }
        },
        dateOfGrantNotificationEnd(newValue) {
            if (newValue == '') {
                this.dateOfGrantNotificationEnd = null
            }
        },
        dateOfGrantStart(newValue) {
            if (newValue == '') {
                this.dateOfGrantStart = null
            }
        },
        dateOfGrantEnd(newValue) {
            if (newValue == '') {
                this.dateOfGrantEnd = null
            }
        },
        priorityDocumentDateStart(newValue) {
            if (newValue == '') {
                this.priorityDocumentDateStart = null
            }
        },
        priorityDocumentDateEnd(newValue) {
            if (newValue == '') {
                this.priorityDocumentDateEnd = null
            }
        },
        priorityDocumentDeadlineStart(newValue) {
            if (newValue == '') {
                this.priorityDocumentDeadlineStart = null
            }
        },
        priorityDocumentDeadlineEnd(newValue) {
            if (newValue == '') {
                this.priorityDocumentDeadlineEnd = null
            }
        },
        dateOfGrantNotificationStart(newValue) {
            if (newValue == '') {
                this.dateOfGrantNotificationStart = null
            }
        },
        dateOfGrantNotificationEnd(newValue) {
            if (newValue == '') {
                this.dateOfGrantNotificationEnd = null
            }
        },
    },
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('patent.patent_folder_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('patent.application_info'),
                    field: `applicationNo`,
                },
                {
                    label: this.$t('patent.title'),
                    field: `title`,
                },
                {
                    label: this.$t('patent.patent_type'),
                    field: `patentType`,
                },
                {
                    label: this.$t('patent.agent_name'),
                    field: `agent`,
                },
                {
                    label: this.$t('patent.owners'),
                    field: `ownerList`,
                    sortable: false,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em',
                    sortable: false,
                },
            ],
            rows: [],
            searchTerm: '',
            agentName: '',
            recordNo: '',
            epApplicationNo: '',
            applicationNo: '',
            pctApplicationNo: '',
            epPatentNo: '',
            patentStatusSelect: 0,
            patentStatusOption: [],
            patentPublicationTypeSelect: 0,
            patentPublicationTypeOption: [],
            patentTypeSelect: 0,
            patentTypeOption: [],
            ownerName: '',
            applicationDateStart: null,
            applicationDateEnd: null,
            pCTApplicationDateStart: null,
            pCTApplicationDateEnd: null,
            ePPublicationDateStart: null,
            ePPublicationDateEnd: null,
            ePB9PubDateStart: null,
            ePB9PubDateEnd: null,
            workingDeclarationDeadlineStart: null,
            workingDeclarationDeadlineEnd: null,
            dateOfWorkingDeclarationStart: null,
            dateOfWorkingDeclarationEnd: null,
            epb1PubDateStart: null,
            epb1PubDateEnd: null,
            epb2PubDateStart: null,
            epb2PubDateEnd: null,
            epb3PubDateStart: null,
            epb3PubDateEnd: null,
            b2FilingDateStart: null,
            b2FilingDateEnd: null,
            priorityDocumentDateStart: null,
            priorityDocumentDateEnd: null,
            priorityDocumentDeadlineStart: null,
            priorityDocumentDeadlineEnd: null,
            dateOfGrantNotificationStart: null,
            dateOfGrantNotificationEnd: null,
            dateOfGrantStart: null,
            dateOfGrantEnd: null,
            dateOfPaymentOfGrantFeesStart: null,
            dateOfPaymentOfGrantFeesEnd: null,
            priorityDocumentDateStart: null,
            priorityDocumentDateEnd: null,
            priorityDocumentDeadlineStart: null,
            priorityDocumentDeadlineEnd: null,
            dateOfGrantNotificationStart: null,
            dateOfGrantNotificationEnd: null,
            dateOfGrantStart: null,
            dateOfGrantEnd: null,
            priorityDocumentDateStart: null,
            priorityDocumentDateEnd: null,
            priorityDocumentDeadlineStart: null,
            priorityDocumentDeadlineEnd: null,
            dateOfGrantNotificationStart: null,
            dateOfGrantNotificationEnd: null
        }
    },
    mounted() {
        this.getPatentList()
    },
    methods: {
        async getEpoXml() {
            this.$refs.patentXmlUploadForm.showInfo(0)
        },
        async getWipoXml() {
            this.$refs.patentWipoXmlUploadForm.showInfo(0)
        },
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'Patent'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getPatentList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getPatentList()
                        }
                    })
                }
            })
        },

        async getInfo(id) {
            this.$refs.patentFormModal.showInfo(id, this.patentId)
        },

        getPatentList() {
            let loader = this.$loading.show()
            this.rows = []
            if (this.applicationDateStart == '') {
                this.applicationDateStart = null

            }
            if (this.applicationDateEnd == '') {
                this.applicationDateEnd = null

            }
            const controller = {
                name: 'Patent'
            }
            const data = {
                recordNo: this.recordNo,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                agentName: this.agentName,
                patentStatusId: this.patentStatusSelect,
                ownerName: this.ownerName,
                patentPublicationTypeId: this.patentPublicationTypeSelect,
                patentTypeId: this.patentTypeSelect,
                applicationDateStart: this.applicationDateStart,
                applicationDateEnd: this.applicationDateEnd,
                epApplicationNo: this.epApplicationNo,
                applicationNo: this.applicationNo,
                pctApplicationNo: this.pctApplicationNo,
                epPatentNo: this.epPatentNo,
                pCTApplicationDateStart: this.pCTApplicationDateStart,
                pCTApplicationDateEnd: this.pCTApplicationDateEnd,
                ePPublicationDateStart: this.ePPublicationDateStart,
                ePPublicationDateEnd: this.ePPublicationDateEnd,
                ePB9PubDateStart: this.ePB9PubDateStart,
                ePB9PubDateEnd: this.ePB9PubDateEnd,
                workingDeclarationDeadlineStart: this.workingDeclarationDeadlineStart,
                workingDeclarationDeadlineEnd: this.workingDeclarationDeadlineEnd,
                dateOfWorkingDeclarationStart: this.dateOfWorkingDeclarationStart,
                dateOfWorkingDeclarationEnd: this.dateOfWorkingDeclarationEnd,
                epb1PubDateStart: this.epb1PubDateStart,
                epb1PubDateEnd: this.epb1PubDateEnd,
                epb2PubDateStart: this.epb2PubDateStart,
                epb2PubDateEnd: this.epb2PubDateEnd,
                epb3PubDateStart: this.epb3PubDateStart,
                epb3PubDateEnd: this.epb3PubDateEnd,
                b2FilingDateStart: this.b2FilingDateStart,
                b2FilingDateEnd: this.b2FilingDateEnd,
                priorityDocumentDateStart: this.priorityDocumentDateStart,
                priorityDocumentDateEnd: this.priorityDocumentDateEnd,
                priorityDocumentDeadlineStart: this.priorityDocumentDeadlineStart,
                priorityDocumentDeadlineEnd: this.priorityDocumentDeadlineEnd,
                dateOfGrantNotificationStart: this.dateOfGrantNotificationStart,
                dateOfGrantNotificationEnd: this.dateOfGrantNotificationEnd,
                dateOfGrantStart: this.dateOfGrantStart,
                dateOfGrantEnd: this.dateOfGrantEnd,
                priorityDocumentDateStart: this.priorityDocumentDateStart,
                priorityDocumentDateEnd: this.priorityDocumentDateEnd,
                priorityDocumentDeadlineStart: this.priorityDocumentDeadlineStart,
                priorityDocumentDeadlineEnd: this.priorityDocumentDeadlineEnd,
                dateOfGrantNotificationStart: this.dateOfGrantNotificationStart,
                dateOfGrantNotificationEnd: this.dateOfGrantNotificationEnd,
                dateOfGrantStart: this.dateOfGrantStart,
                dateOfGrantEnd: this.dateOfGrantEnd,
                dateOfPaymentOfGrantFeesStart: this.dateOfPaymentOfGrantFeesStart,
                dateOfPaymentOfGrantFeesEnd: this.dateOfPaymentOfGrantFeesEnd,
                priorityDocumentDateStart: this.priorityDocumentDateStart,
                priorityDocumentDateEnd: this.priorityDocumentDateEnd,
                priorityDocumentDeadlineStart: this.priorityDocumentDeadlineStart,
                priorityDocumentDeadlineEnd: this.priorityDocumentDeadlineEnd,
                dateOfGrantNotificationStart: this.dateOfGrantNotificationStart,
                dateOfGrantNotificationEn: this.dateOfGrantNotificationEnd
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    response.data.patentList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.patentStatusOption = response.data.patentStatusList
                    this.patentStatusOption.splice(0, 0, this.$nullSelected()),
                        this.patentTypeOption = response.data.patentTypeList
                    this.patentTypeOption.splice(0, 0, this.$nullSelected()),
                        this.patentPublicationTypeOption = response.data.patentPublicationTypeList
                    this.patentPublicationTypeOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        },
        exportExcel() {

            if (this.applicationDateStart == '') {
                this.applicationDateStart = null

            }
            if (this.applicationDateEnd == '') {
                this.applicationDateEnd = null

            }
            var controller = {
                name: 'Patent'
            }
            var data = {
                recordNo: this.recordNo,
                pageLength: 100000,
                pageNo: 1,
                agentName: this.agentName,
                patentStatusId: this.patentStatusSelect,
                ownerName: this.ownerName,
                patentPublicationTypeId: this.patentPublicationTypeSelect,
                patentTypeId: this.patentTypeSelect,
                applicationDateStart: this.applicationDateStart,
                applicationDateEnd: this.applicationDateEnd,
                epApplicationNo: this.epApplicationNo,
                applicationNo: this.applicationNo,
                pctApplicationNo: this.pctApplicationNo,
                epPatentNo: this.epPatentNo
            }
            var payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    controller = {
                        name: 'Patent',
                        actionName: 'CanExport'
                    }
                    data = {
                        columnList: this.columns,
                        DataList: response.data.patentList
                    }
                    payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$downloadFile("Patent", response.data.base64, 'xlsx')
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },        
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
}

.tableSizePatent {
    font-size: 14px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.featherIconReSize {
    width: 12px;
    height: 12px;
}
</style>
