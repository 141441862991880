<template>
    <div>
        <b-modal
            id="nationalPatentResponsibleFormModal" centered size="lg" no-close-on-backdrop ref="nationalPatentResponsibleFormModal" :title=" $t('nationalPatentResponsible.responsible_info')" :cancel-title=" $t('others.cancel_title')"
            :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" hide-footer>
            <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon icon="LockIcon" size="18" />
                    <span class="align-middle ml-50">{{  $t('nationalPatentResponsible.responsible')  }}</span>
                  </b-card-title>
                </b-card-header>
                <!--eslint-disable-->
                <table class="table table-striped table-bordered">
                  <tbody>
                    <tr v-for="nationalPatentResponsibleItem in nationalPatentResponsibleList" :key="nationalPatentResponsibleItem.id">
                      <td >
                          <b-form-checkbox @change="changeResponsible($event, nationalPatentResponsibleItem.id)" />                                 
                      </td>
                      <td>{{  nationalPatentResponsibleItem.name  }}</td>

                    </tr>
                  </tbody>
                </table>
            </b-card>
        </b-modal>
    </div>
</template>
<script>
export default{
  props: {
      getNationalPatentResponsibleList: {type: Function},
    },
  data(){
    return {
      id: 0,
      nationalPatentResponsibleList:[]
    }
  }, 
  methods:{
    changeResponsible(selected, clientResponsibleId) {
      const controller = { name: 'NationalPatentResponsible' }
      if (selected == true) {
        const data = { nationalPatentId: this.nationalPatentId, clientResponsibleId: clientResponsibleId }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.getNationalPatentResponsibleList()
            this.$SaveAlert()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
    },
    showInfo(nationalPatentId, clientId) {
      this.$refs['nationalPatentResponsibleFormModal'].show()
          this.nationalPatentId= nationalPatentId
          const data = {clientId: clientId, nationalPatentId: nationalPatentId}
          const controller = {name: 'ClientResponsible', actionName:'GetClientResponsibleListForModules'}
          const payload = {data: data, controller: controller}
          this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
              if(response.data.resultStatus == true){    
                  this.nationalPatentResponsibleList = response.data.clientResponsibleList
              } else {
                  this.$bvModal.hide('nationalPatentResponsibleFormModal')
                  this.$WarningAlert(response.data.resultMessage)
              }  
          }) 
      },
  }
}
</script>