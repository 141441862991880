var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('agent-annuity-price-detail-form',{ref:"agentAnnuityPriceDetailFormModal",attrs:{"getAgentAnnuityPriceDetailList":_vm.getAgentAnnuityPriceDetailList,"getAgentAnnuityPriceList":_vm.getAgentAnnuityPriceList}}),_c('vue-good-table',{ref:"agentAnnuityPriceDetailTable",attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table condensed tableSize vgt-responsive-custom","search-options":{
    enabled: false,
    externalQuery: _vm.searchTerm },"select-options":{ 
  enabled: false,
  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
  selectionInfoClass: 'custom-class',
  selectionText: 'rows selected',
  clearSelectionText: 'clear',
  disableSelectInfo: true, // disable the select info panel on top
  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
},"pagination-options":{
  enabled: false,
  perPage:_vm.pageLength
},"theme":"my-theme"},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field ==='tpmkCode')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('agentAnnuityPriceDetail.tpmk_code'))+" ")]):(props.column.field ==='service')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('agentAnnuityPriceDetail.service'))+" ")]):(props.column.field ==='tpmkName')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('agentAnnuityPriceDetail.tpmk_name'))+" ")]):(props.column.field ==='stockFee')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('agentAnnuityPriceDetail.stock_fee'))+" ")]):(props.column.field ==='tpmkFee')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('agentAnnuityPriceDetail.tpmk_fee'))+" ")]):(props.column.field ==='officialFee')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('agentAnnuityPriceDetail.official_fee'))+" ")]):(props.column.field ==='officialFineFee')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('agentAnnuityPriceDetail.official_fine_fee'))+" ")]):(props.column.field ==='process')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('others.process'))+" ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'process')?_c('span',[_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",modifiers:{"html":true}},{name:"b-modal",rawName:"v-b-modal.modal-top",modifiers:{"modal-top":true}}],staticClass:"btn-icon mb-1 ml-1",attrs:{"title":_vm.$t('others.edit'),"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.getInfo(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)],1)]):_vm._e()]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.data_not_found'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }