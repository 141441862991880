var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('trademark-provisional-form',{ref:"trademarkProvisionalFormModal",attrs:{"getTrademarkList":_vm.getTrademarkList,"getTrademarkProvisionalList":_vm.getTrademarkProvisionalList}}),_c('vue-good-table',{ref:"trademarkProvisionalTable",attrs:{"id":"trademarkProvisionalTable","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table condensed tableSize","select-options":{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"theme":"my-theme"},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field === 'date')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('trademarkProvisional.date'))+" ")]):(props.column.field === 'owner')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('trademarkProvisional.owner'))+" ")]):(props.column.field === 'groundOfObjection')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('trademarkProvisional.ground_of_objection'))+" ")]):(props.column.field === 'decision')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('trademarkProvisional.decision'))+" ")]):(props.column.field === 'status')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('trademarkProvisional.provisional_status'))+" ")]):(props.column.field === 'process')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('others.process'))+" ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'process')?_c('span',[_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",modifiers:{"html":true}}],staticClass:"btn-icon mb-1 ml-1",attrs:{"title":_vm.$t('others.detail'),"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.getInfo(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)],1)]):_vm._e()]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.data_not_found'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }