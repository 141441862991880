<template>
    <div>
      <!-- modal -->
      <b-modal
        id="agentAnnuityPriceFormModal"
        centered
        size="xl"
        no-close-on-backdrop
        ref="agentAnnuityPriceFormModal"
        :title=" $t('agentAnnuityPrice.agent_annuity_price_detail_info')"
        hide-footer
      >
      <validation-observer ref="simpleRules">
        <div class="custom-search d-flex justify-content-end">
          <b-row>
              <b-col>
                  <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="addOrUpdate">
                      {{$t('others.ok_title')}}
                  </b-button>
              </b-col>
          </b-row>
      </div>
      <b-row>
        <b-col class="col-lg-6">
          <b-form>
            <validation-provider :name="$t('agentAnnuityPrice.client')" #default="{ errors }" :rules="{ excluded: 0 }">
                <b-form-group :label="$t('agentAnnuityPrice.client')" label-for="agent-annuity-price-client-input"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="agent-annuity-price-client-input" size="sm" v-model="agentSelect" :options="agentOption" label="name"
                  @input="setAgentId"
                    @search="onSearchAgent">
                  </v-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
        </b-form>
        </b-col>
        <b-col class="col-lg-6">
          <b-form>
            <validation-provider :name=" $t('agentAnnuityPrice.currency')" :rules="{ excluded:0}" v-slot="{ errors }">
                <label for="agent-annuity-price-code-input">{{ $t('agentAnnuityPrice.currency') }}</label>
                <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="currencySelect" value-field="id" text-field="name" :options="currencyOption"/>
                <small class="text-danger">{{ errors[0] }}</small>       
            </validation-provider>
        </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-lg-6">
          <b-form>
              <label for="agent-annuity-price-stock-fee-input">{{ $t('agentAnnuityPrice.stock_fee') }}</label>
              <b-form-group>
                  <validation-provider #default="{ errors }" :name=" $t('agentAnnuityPrice.stock_fee')" rules="required">
                      <b-form-input id="agent-annuity-price-stock-fee-input" size="sm" v-model="stockFee" type="number" :state="errors.length > 0 ? false:null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
          </b-form>
        </b-col>
        <b-col class="col-lg-6">
          <b-form>
            <label for="agent-annuity-price-currency-input">{{ $t('agentAnnuityPrice.stabil_currency') }}</label>
            <b-form-group>
                <validation-provider #default="{ errors }" :name=" $t('agentAnnuityPrice.stabil_currency')" rules="required">
                    <b-form-input id="agent-annuity-price-currency-input" size="sm" v-model="currencyValue" type="number" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
        </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-lg-6">
          <b-form>
              <label for="agent-annuity-price-explanation-input">{{ $t('agentAnnuityPrice.explanation') }}</label>
              <b-form-group>
                  <b-form-input id="agent-annuity-price-explanation-input" size="sm" v-model="explanation" maxlength="250" />
              </b-form-group>
          </b-form>
        </b-col>
        <b-col class="col-lg-6">
          <b-form>
            <label for="agent-annuity-price-status-input"> {{ $t('agentAnnuityPrice.status') }} </label>
              <b-form-checkbox class="custom-control-success" name="check-button" v-model="status" switch>
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
        </b-form>
        </b-col>
      </b-row>
      </validation-observer>
      <b-card :hidden="hiddenClientControl">
        <b-row>
            <b-col>
              <b-card-text>
                <agent-annuity-price-detail-list :getAgentAnnuityPriceList="getAgentAnnuityPriceList" :id="this.id"></agent-annuity-price-detail-list>
              </b-card-text>
            </b-col>
        </b-row>
    </b-card>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
        getAgentAnnuityPriceList: {type: Function},
    },
    data() {
      return {
        id: 0,
        client: '',
        currencySelect: 0,
        currencyOption: [],
        stockFee: null,
        currencyValue: null,
        explanation:'',
        status: true,
        hiddenClientControl: true,
        agentSelect : 0,
        agentOption : [],
        agentId:0,
      }
    },
    methods: {
      setAgentId(event){
          this.agentId =  event.id
      },
      onSearchAgent(search, loading) {
        if (search.length) {
          loading(true);
          this.searchAgent(loading, search, this);
        }
      },
      searchAgent(loading, search, vm) {
        if (search.length > 2) {
          const data = { name: search }
          const controller = { name: 'Client', actionName: 'GetAgentClientByName' }
          const payload = { data: data, controller: controller }
          this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            this.agentOption = response.data.clientList
            loading(false)
          })
        }
      },
      showInfo(id) {  
        this.$refs['agentAnnuityPriceFormModal'].show()
        this.rows = []
        this.id = id
        if (this.id == 0) {
            this.hiddenClientControl = true
        } else {
            this.hiddenClientControl = false
        }
        const data = {id: this.id}
        const controller = {name: 'AgentAnnuity', actionName: 'GetAgentAnnuityInfo'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.stockFee = response.data.agentAnnuity.stockFee
                this.currencyValue = response.data.agentAnnuity.currencyValue
                this.explanation = response.data.agentAnnuity.explanation
                this.status = response.data.agentAnnuity.isActive
                this.currencySelect = response.data.agentAnnuity.currencyId
                this.currencyOption = response.data.currencyList
                this.currencyOption.splice(0, 0, this.$nullSelected())
                if(response.data.agentAnnuity.clientId > 0){
                  const data = { clientId : response.data.agentAnnuity.clientId}
                  const controller = { name: 'Client', actionName: 'GetAgentClientByName' }
                  const payload = { data: data, controller: controller }
                  this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                  this.agentOption = response.data.clientList
                  this.agentSelect = response.data.clientList[0]
                  })
                }
                else
                {
                  this.agentOption = []
                  this.agentSelect = 0;
                }

            } else {
                this.$bvModal.hide('agentAnnuityPriceFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
      const data = {id: this.id, clientId: this.agentId, currencyId: this.currencySelect, stockFee: this.stockFee, currencyValue: this.currencyValue, explanation: this.explanation, isActive: this.status}
      const controller = {name: 'AgentAnnuity', actionName: 'SaveAgentAnnuity'}
      const payload = {data: data, controller: controller}
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
              this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                if(response.data.resultStatus == true){
                  this.$bvModal.hide('agentAnnuityPriceFormModal')
                  this.getAgentAnnuityPriceList()
                  this.$SaveAlert()
                  this.hiddenClientControl = false
                } else {
                  this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
      })
    },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  [dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
  </style>
  