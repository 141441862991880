<template>
  <div>
    <owner-note-form ref="ownerNoteFormModal"></owner-note-form>
    <trademark-owner-form ref="trademarkOwnerForm" :getTrademarkList="getTrademarkList" :getTrademarkOwnerList="getTrademarkOwnerList" :trademarkId="this.trademarkId"></trademark-owner-form>
    <b-row>
      <b-col class="col-12">
        <div style="float:right;" class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-button style="float:right;" size="sm" @click="getInfo()" variant="outline-primary"
                >{{ $t('trademarkOwner.owner_add') }}</b-button>
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table 
    id="trademarkOwnerTable"
    ref="trademarkOwnerTable" 
    :columns="columns" 
    :rows="rows"
    styleClass="vgt-table condensed tableSize"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }" 
      theme="my-theme">
      <div slot="emptystate">
        {{ $t('dataTable.data_not_found') }}
      </div>
      <!-- Row: Table Row -->
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field === 'clientName'" class="text-nowrap">
          {{ $t('trademarkOwner.owner_name') }}
        </span>
        <span v-else-if="props.column.field === 'process'" class="text-nowrap">
          {{ $t('others.process') }}
        </span>
      </template>
      <!-- Column: Table Column -->
      <template slot="table-row" slot-scope="props">
        <!-- Column: Process -->
        <span v-if="props.column.field === 'process'">
          <span>
            <b-button v-b-tooltip.html :title="$t('note.note')" variant="light" class="btn-icon mb-1 ml-1"
              size="sm" @click="getOwnerNoteInfo(props.row.clientId)">
              <feather-icon icon="AlignRightIcon" />
            </b-button>
            <b-button v-b-tooltip.html :title="$t('others.show_owner')" variant="info" class="btn-icon mb-1 ml-1"
              size="sm" @click="getShowOwner(props.row.clientId)">
              <feather-icon icon="UsersIcon" />
            </b-button>
            <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
              size="sm" @click="deleted(props.row)">
              <feather-icon icon="XIcon" />
            </b-button>
          </span>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  props: {
    trademarkId: Number,
    getTrademarkList: {type: Function},
    getClientInfo: {type: Function},
  },
  data() {
    return {
      dir: false,
      columns: [
        {
          label: this.$t('trademarkOwner.owner_name'),
          field: `clientName`,
        },
        {
          label: 'Process',
          field: 'process',
          width: '15em'
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  mounted() {
    this.getTrademarkOwnerList()
  },
  methods: {
    async getInfo() {
        this.$refs.trademarkOwnerForm.showInfo()
    },
    async getOwnerNoteInfo(clientId) {
      this.$refs.ownerNoteFormModal.getNoteListByClientId(clientId)        
    }, 
    async getShowOwner(clientId) {
      this.getClientInfo(clientId)      
    }, 
    async deleted(data) {
      data = { id: data.id }
      const controller = { name: 'TrademarkOwner' }
      const payload = { data: data, controller: controller }
      this.$DeleteConfirm().then(result => {
        if (result.value) {
          this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
            if (response.data.resultStatus == true) {
              this.$DeleteAlert()
              this.getTrademarkOwnerList()
              this.getTrademarkList()
            } else {
              this.$WarningAlert(response.data.resultMessage)
              this.getTrademarkOwnerList()
            }
          })
        }
      })
    },
    getTrademarkOwnerList() {
      this.rows = [] 
      const data = { id: this.trademarkId }
      const controller = { name: 'TrademarkOwner', actionName:'GetTrademarkOwnerListByTrademarkId' }
      const payload = { data: data, controller: controller }
      this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
        if (response.data.resultStatus == true) {
          response.data.trademarkOwnerList.forEach(element => {
            this.rows.push(element)
          })
        }
        else {
          this.$WarningAlert(response.data.resultMessage)
        }
      }
      )
    },
  },
}
</script>
<style lang="scss" >
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>