<template>
    <div>
        <!-- modal -->
        <b-modal id="annuityDocumentsFormModal" centered size="lg" no-close-on-backdrop ref="annuityDocumentsFormModal" :title=" $t('documents.documents_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col>
                        <b-form>
                            <validation-provider :name=" $t('documents.jurisdiction')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <label for="client-jurisdiction-input">{{ $t('documents.jurisdiction') }}</label>
                                <b-form-select :state="errors.length > 0 ? false:null" v-model="documentJurisdictionSelect" value-field="id" size="sm" text-field="name" :options="documentJurisdictionOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col>
                        <validation-provider #default="{ errors }" :name=" $t('documents.document_name')" rules="required">
                            <label for="wordTemplates-input">{{ $t('documents.document_name') }}</label>
                            <b-input-group>
                                <b-form-input size="sm" id="document-name-input" v-model="documentName" maxlength="50" :state="errors.length > 0 ? false:null" />
                                <b-input-group-append>
                                    <b-button size="sm" variant="outline-primary" onclick="document.getElementById('annuityDocumentsFileSelect').click()">{{ $t('others.select') }}
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-form-file size="sm" style="display:none" id="annuityDocumentsFileSelect" accept="*.*" @change="uploadFile" :placeholder=" $t('documents.select_documents')" :drop-placeholder=" $t('documents.select_documents_info')" />
                </b-row>
                <b-row class="mt-1">
                    <b-col>
                        <b-form>
                            <validation-provider :name=" $t('documents.documents_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <label for="client-documents-type-input">{{ $t('documents.documents_type') }}</label>
                                <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="documentTypeSelect" value-field="id" text-field="name" @change="getDocumentDetailList()" :options="documentTypeOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col>
                        <b-form>
                            <validation-provider :name=" $t('documents.documents_content')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <label for="client-documents-content-input">{{ $t('documents.document_content') }}</label>
                                <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="documentContentSelect" value-field="id" text-field="name" :options="documentContentOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col class="col-6">
                        <label for="document-date">{{ $t('documents.date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                    </b-col>
                    <b-col class="col-6">
                        <b-form>
                            <label for="document-type">{{ $t('documents.type') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="document-type" v-model="type" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col>
                        <b-form>
                            <label for="note-input">{{ $t('documents.note') }}</label>
                            <b-form-group>
                                <b-form-textarea id="textarea-default" :placeholder=" $t('documents.note')" v-model="note" rows="3" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            getAnnuityDocumentsList: {
                type: Function
            }
        },
        data() {
            return {
                id: 0,
                recordId: 0,
                moduleId: 0,
                documentJurisdictionSelect: 0,
                documentJurisdictionOption: [],
                documentTypeSelect: 0,
                documentTypeOption: [],
                documentContentSelect: 0,
                documentContentOption: [],
                documentName: '',
                documentFile: '',
                documentType: '',
                note: '',
                date: null,
                type: '',
            }
        },
        methods: {
            async uploadFile(e) {
                var fileInfo = await this.$getFileInfo(e.target.files[0])
                this.documentName = fileInfo.name
                this.documentType = fileInfo.contentType
                this.documentFile = fileInfo.base64
    
            },
            getAnnuityDocumentDetailList() {
                if (this.documentTypeSelect > 0) {
                    this.documentContentOption = this.documentTypeOption.find(p => p.id == this.documentTypeSelect).documentTypeDetailList
                    this.documentContentSelect = 0;
                    this.documentContentOption.splice(0, 0, this.$nullSelected())
                }
            },
            showInfo(id, recordId, moduleId) {
                this.$refs['annuityDocumentsFormModal'].show()
                this.id = id
                this.recordId = recordId
                this.moduleId = moduleId
                const data = {
                    id: this.id,
                    moduleId: this.moduleId
                }
                const controller = {
                    name: 'Document'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.documentName = response.data.document.name
                        this.documentType = response.data.document.documentType
                        this.documentJurisdictionOption = response.data.jurisdictionList
                        this.documentJurisdictionSelect = response.data.document.jurisdictionId
                        this.note = response.data.document.note
                        this.documentJurisdictionOption.splice(0, 0, this.$nullSelected())
                        this.type = response.data.document.type
                        this.documentTypeOption = response.data.documentTypeList
                        this.documentTypeSelect = response.data.document.documentTypeId
                        this.documentTypeOption.splice(0, 0, this.$nullSelected())
                        this.date = response.data.document.date
                        if (response.data.document.documentTypeId != 0) {
                            this.documentContentOption = response.data.documentTypeList.find(p => p.id == this.documentTypeSelect).documentTypeDetailList
                            this.documentContentSelect = response.data.document.documentTypeDetailId;
                            this.documentContentOption.splice(0, 0, this.$nullSelected())
                        }
                    } else {
                        this.$bvModal.hide('annuityDocumentsFormModal')
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            addOrUpdate() {
                const data = {
                    id: this.id,
                    moduleId: this.moduleId,
                    recordId: this.recordId,
                    jurisdictionId: this.documentJurisdictionSelect,
                    documentTypeDetailId: this.documentContentSelect,
                    note: this.note,
                    name: this.documentName,
                    type: this.documentType,
                    base64: this.documentFile,
                    employeeId: localStorage.getItem('EmployeeId')
                }
                const controller = {
                    name: 'Document'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        if (this.id == 0) {
                            if (this.documentFile == '') {
                                this.$WarningAlert(this.$t('alerts.base64_null'))
                            } else{
                                let loader = this.$loading.show()
                                this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                                    if (response.data.resultStatus == true) {
                                        this.$bvModal.hide('annuityDocumentsFormModal')
                                        this.getAnnuityDocumentsList()
                                        this.$SaveAlert()
                                    } else {
                                        this.$WarningAlert(response.data.resultMessage)
                                    }
                                    loader.hide()
                                })
                            }
                        } else {
                            let loader = this.$loading.show()
                            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                                if (response.data.resultStatus == true) {
                                    this.$bvModal.hide('annuityDocumentsFormModal')
                                    this.getAnnuityDocumentsList()
                                    this.$SaveAlert()
                                } else {
                                    this.$WarningAlert(response.data.resultMessage)
                                }
                                loader.hide()
                            })
                        }
                    }
                })
            },
        }
    }
    </script>
    
    <style lang="scss">
    [dir=ltr] .modal-header .close {
        margin: -0.4rem -0.4rem -0.8rem auto;
    }
    
    [dir] label {
        margin-bottom: 0.2857rem;
        font-weight: bold;
    }
    
    .modal .modal-content {
        overflow: visible;
        margin-bottom: auto;
    }
    
    [dir] .invalid-tooltip {
        padding: 0.4rem 0.775rem !important;
        margin-top: -1.5rem !important;
        background-color: #ea5455 !important;
        border-radius: 0.358rem !important;
        left: 20px !important
    }
    .datepickerStyle{
        max-height: 2.2em;
        font-family: fangsong;
    }
    </style>
    