<template>
    <div>
      <invoice-form :getDailyworkList="getDailyworkList" ref="invoiceForm"></invoice-form>
      <multi-dailywork-save-form :getDailyworkList="getDailyworkList" ref="multiDailyworkSaveFormModal"></multi-dailywork-save-form>
      <dailywork-form :getDailyworkList="getDailyworkList" ref="dailyworkFormModal"></dailywork-form>
      <dailywork-official-fee-form :getDailyworkList="getDailyworkList" ref="dailyworkOfficialFeeFormModal"></dailywork-official-fee-form>
      <b-row>
        <b-col class="col-12">
          <div style="float:left;" class="custom-search d-flex justify-content-end">
                <b-form-group class="d-flex align-items-center">
                  <b-form-checkbox  v-model="notInvoiced" @input="changeNotInvoice()" name="check-button" switch inline>
                        {{ $t('dailywork.not_invoiced') }}
                    </b-form-checkbox>
                 </b-form-group> 
          </div> 
          <div style="float:right;" class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-dropdown
                style="height: 2.2em;"
                size="sm"
                class="mr-05" 
                :text="$t('dailywork.dailywork_add')"
                variant="outline-primary"
              >
                <b-dropdown-item v-if="this.moduleId != 15" @click="openMultiDailyworkSaveForm(0)">{{$t('dailywork.invoice_and_accounting')}}</b-dropdown-item>
                <b-dropdown-item @click="openDailyworkForm(0,2)">{{$t('dailywork.not_invoice')}}</b-dropdown-item>
                <b-dropdown-item @click="openDailyworkForm(0,3)">{{$t('dailywork.not_accounting')}}</b-dropdown-item>
              </b-dropdown> 
              
              <b-button variant="outline-primary" size="sm"  @click.prevent="showInvoiceForm()">
                        {{$t('dailywork.invoice')}}
                    </b-button>
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table 
      id="dailyworkTable"
      ref="dailyworkTable" 
      :columns="columns" 
      :rows="rows" 
      styleClass="vgt-table condensed tableSize" 
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" 
      :select-options="{
        enabled: this.checkedStatus,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" @on-selected-rows-change="handleSelectedRows" theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'client'" class="text-nowrap">
            {{ $t('dailywork.client') }}
          </span>
          <span v-else-if="props.column.field === 'clientAccounting'" class="text-nowrap">
            {{ $t('dailywork.client_accounting') }}
          </span>
          <span v-else-if="props.column.field === 'billingStatus'">
            {{ $t('dailywork.billing_status') }}
          </span>
          <span v-else-if="props.column.field === 'paymentType'" class="text-nowrap">
            {{ $t('dailywork.payment_type') }}
          </span>
          <span v-else-if="props.column.field === 'currency'" class="text-nowrap">
            {{ $t('dailywork.currency') }}
          </span>
          <span v-else-if="props.column.field === 'date'" class="text-nowrap">
            {{ $t('dailywork.date') }}
          </span>
          <span v-else-if="props.column.field === 'description'" class="text-nowrap">
            {{ $t('dailywork.description') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props" >
          <span :style="[ props.row.documentDate === null && props.row.officialFeeId > 0 ? { 'color': 'red' } : '']">
          <span v-if="props.column.field === 'debitNo'">
                <b-badge variant="primary" style="cursor: pointer;" @click="getInvoiceInfo(props.row.invoiceId)">
                    <feather-icon icon="MousePointerIcon" class="mr-25" />
                    <span>{{ props.row.debitNo }}</span>
                </b-badge>
          </span>
          <span v-else-if="props.column.field === 'isProforma'">
              <b-form-checkbox disabled v-model="props.row.isProforma" value-field="name" text-field="name" class="demo-inline-spacing"/>
          </span>
          <span v-else-if="props.column.field === 'agent'">
            <span>{{props.row.agent}}</span> 
          </span>
          <span v-else-if="props.column.field === 'invoiceClientAccounting'">
              <span>{{ props.row.invoiceClientAccounting }} </span>
          </span>
          <span v-else-if="props.column.field === 'billingStatus'" style="cursor:pointer" :title="props.row.billingStatus">
            <feather-icon v-if="props.row.billingStatusId == 1" color="green" icon="CircleIcon" />
            <feather-icon v-if="props.row.billingStatusId == 2" color="red" icon="CircleIcon" />
            <feather-icon v-if="props.row.billingStatusId == 3" color="orange" icon="CircleIcon" />
          </span>

          <span v-else-if="props.column.field === 'paymentType'">
              <span>{{ props.row.paymentType }} </span>
          </span>
          <span v-else-if="props.column.field === 'currency'">
              <span>{{ props.row.currency }} </span>
          </span>
          <span v-else-if="props.column.field === 'date'">
              <span>{{ $formatFn(props.row.date) }} </span>
          </span>
          <span v-else-if="props.column.field === 'serviceType'">
              <span>{{ props.row.serviceType }} </span>
          </span>
          <span v-else-if="props.column.field === 'description'">
              <span>{{ props.row.description }} </span>
          </span>
          <span v-else-if="props.column.field === 'count'">
              <span>{{ props.row.count }} </span>
          </span>
          <span v-else-if="props.column.field === 'unitPrice'">
              <span>{{ $formatMoney(props.row.unitPrice) }} </span>
          </span>
          <span v-else-if="props.column.field === 'price'">
              <span>{{ $formatMoney(props.row.price) }} </span>
          </span>
          <span v-else-if="props.column.field === 'employee'">
              <span>{{ props.row.employee }} </span>
          </span>
          <!-- Column: Process -->
          <span v-else-if="props.column.field === 'process'">
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-1 ml-1" size="sm" @click="openDailyworkForm(props.row.id, props.row.paymentTypeId)">
  
                <feather-icon icon="EditIcon" />
              </b-button>


              <b-button v-if="props.row.officialFeeId == 0" v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button>

              <span v-if="props.row.documentDate == null && props.row.officialFeeId > 0">
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-1 ml-1" size="sm" @click="openDailyworkOfficialFeeForm(props.row.id)">
  
                <feather-icon icon="PlusIcon" />
              </b-button>
            </span>
          </span>
        </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
            </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number
              last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => getDailyworkList()">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      recordId: Number,
      moduleId: Number
    },
    data() {
      return {
        checkedStatus: true,
        columns: [
          {
            label: this.$t('dailywork.proforma'),
            field: `isProforma`,
          },
          {
            label: this.$t('dailywork.date'),
            field: 'date',
            formatFn: this.$formatFn
          },
          {
            label: this.$t('dailywork.service_type'),
            field: `serviceType`,
          },
          {
            label: this.$t('dailywork.description'),
            field: `description`,
          },
          {
            label: this.$t('dailywork.client_accounting'),
            field: `invoiceClientAccounting`,
          },
          {
            label: this.$t('dailywork.count'),
            field: `count`,
          }, 
          {
            label: this.$t('dailywork.currency'),
            field: `currency`,
          }, 
          {
            label: this.$t('dailywork.unit_price'),
            field: `unitPrice`,
          },     
          {
            label: this.$t('dailywork.amount'),
            field: `price`,
          },   
          {
            label: this.$t('dailywork.employee'),
            field: `employee`,
          },  
          {
            label: this.$t('dailywork.billing_status'),
            field: `billingStatus`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        total: 0,
        notInvoiced : true,
        pageLength: 10,
        pageNo: 1,
        dir: false,
        searchTerm: '',
        total : 0,
        officialFeeId: 0,
        documentDate: null,
        selectedRows: [],
        selectedRowsArray: []
      }
    },
    mounted() {
      this.getDailyworkList()
    },
    methods: {
      handleSelectedRows(selectedRows) {
        this.selectedRows = selectedRows;
      },
      openMultiDailyworkSaveForm(id){
        this.$refs.multiDailyworkSaveFormModal.showInfo(id, this.moduleId, this.recordId, 1)
      },
      openDailyworkForm(id, paymentTypeId){
        this.$refs.dailyworkFormModal.showInfo(id, this.moduleId, this.recordId, paymentTypeId)
      },
      openDailyworkOfficialFeeForm(id){
        this.$refs.dailyworkOfficialFeeFormModal.showInfo(id, this.moduleId, this.recordId)
      },
      async deleted(data) {
        data = { id: data.id }
        const controller = { name: 'DailyWork' }
        const payload = { data: data, controller: controller }
        this.$DeleteConfirm().then(result => {
          if (result.value) {
            this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$DeleteAlert()
                this.getDailyworkList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getDailyworkList()
              }
            })
          }
        })
      },
      getDailyworkList() {
        this.rows = []
        const controller = { name: 'DailyWork' }
        const data = { moduleId: this.moduleId, pageLength: this.pageLength, pageNo: this.pageNo, recordId: this.recordId, isNotInvoice: this.notInvoiced}
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.total = response.data.total
            response.data.dailyWorkList.forEach(element => {
              this.rows.push(element)
              this.officialFeeId = element.officialFeeId
              this.documentDate = element.documentDate
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
      changeNotInvoice() {     
        if(this.notInvoiced)
        {
          this.checkedStatus = true
          this.columns = [
          {
            label: this.$t('dailywork.isSelected'),
            field: `isSelected`,
          },
          {
            label: this.$t('dailywork.proforma'),
            field: `isProforma`,
          },
          {
            label: this.$t('dailywork.date'),
            field: 'date',
            formatFn: this.$formatFn
          },
          {
            label: this.$t('dailywork.service_type'),
            field: `serviceType`,
          },
          {
            label: this.$t('dailywork.description'),
            field: `description`,
          },
          {
            label: this.$t('dailywork.client_accounting'),
            field: `invoiceClientAccounting`,
          },
          {
            label: this.$t('dailywork.count'),
            field: `count`,
          }, 
          {
            label: this.$t('dailywork.currency'),
            field: `currency`,
          }, 
          {
            label: this.$t('dailywork.unit_price'),
            field: `unitPrice`,
          },     
          {
            label: this.$t('dailywork.amount'),
            field: `price`,
          },   
          {
            label: this.$t('dailywork.employee'),
            field: `employee`,
          },  
          {
            label: this.$t('dailywork.type'),
            field: `billingStatus`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ]
        }
        else
        {
          this.checkedStatus = false
          this.columns =  [
          {
            label: this.$t('dailywork.debitNo'),
            field: `debitNo`,
          },
          {
            label: this.$t('dailywork.proforma'),
            field: `isProforma`,
          },
          {
            label: this.$t('dailywork.date'),
            field: 'date',
            formatFn: this.$formatFn
          },
          {
            label: this.$t('dailywork.service_type'),
            field: `serviceType`,
          },
          {
            label: this.$t('dailywork.description'),
            field: `description`,
          },
          {
            label: this.$t('dailywork.client_accounting'),
            field: `invoiceClientAccounting`,
          },          
          {
            label: this.$t('dailywork.count'),
            field: `count`,
          }, 
          {
            label: this.$t('dailywork.currency'),
            field: `currency`,
          }, 
          {
            label: this.$t('dailywork.unit_price'),
            field: `unitPrice`,
          },     
          {
            label: this.$t('dailywork.amount'),
            field: `price`,
          },   
          {
            label: this.$t('dailywork.employee'),
            field: `employee`,
          }, 
          {
            label: this.$t('dailywork.billing_status'),
            field: `billingStatus`,
          },
        ]
        }

        this.getDailyworkList()
      },
      changeChecked(id, target){
        const status = target.checked

        if(status) {
            var dailyworkControl = this.rows.find(p=> p.id == id)
            if(dailyworkControl.billingStatusId == 2){
                this.$WarningAlert(this.$t('others.NotSelectedDoNotInvoice'))
                target.checked = false;  
                return
            }
        }

        this.rows = this.rows.map((dailywork) => {
          if (dailywork.id === id) {
            return {
                ...dailywork,
                isSelected : status
            };
          }
          return dailywork;
        });
      },
      showInvoiceForm(){
        this.selectedRowsArray = [];
        this.selectedRows.selectedRows.forEach(element => {
          this.selectedRowsArray.push(element)
        })
        if(this.selectedRowsArray.length == 0){
          this.$ErrorSaveAlert()
          return
        }
        this.$refs.invoiceForm.showInfoFromDailyWorkList(this.selectedRowsArray)
      },
      async getInvoiceInfo(id) {
            this.$refs.invoiceForm.showInfo(id)
        },
    },
  }
  </script>
  <style lang="scss" >
  .excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  .alternate-row {
    background-color: #f0f0f0; /* Change to your desired color */
  }
  </style>
  