var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"col-12"},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"size":"sm","placeholder":_vm.$t('others.search_name'),"type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1)])],1),_c('vue-good-table',{ref:"priceListDetailTable",attrs:{"id":"priceListDetailTable","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table condensed tableSize","search-options":{
    enabled: true,
    externalQuery: _vm.searchTerm,
  },"select-options":{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
  },"pagination-options":{
    enabled: false,
  },"theme":"my-theme"},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field === 'code')?_c('span',{staticClass:"text-nowrap"}):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'process')?_c('span'):_vm._e()]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.data_not_found'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }