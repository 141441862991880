<template>
<div>
    <!-- modal -->
    <b-modal id="trademarkNoteFormModal" ref="trademarkNoteFormModal" centered size="lg" no-close-on-backdrop :title=" $t('trademarkNote.note_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules" tag="form">
            <b-row>
                <b-col class="col-md-12">
                    <label for="trademark-note-date-input">{{ $t('trademarkNote.date') }}</label>
                    <validation-provider #default="{ errors }" :name="$t('trademarkNote.date')" rules="required">
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" id="date" v-model="date" :defaultDate="null" reset-button class="mb-1" size="sm" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :placeholder="$t('trademarkNote.date')" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small><small class="text-danger">{{
                    errors[0]
                }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12">
                    <label for="textarea-default">{{ $t('trademarkNote.note') }}</label>
                    <validation-provider #default="{ errors }" :name="$t('trademarkNote.note')" rules="required">
                        <b-form-textarea id="textarea-default" :placeholder="$t('trademarkNote.note')" size="sm" v-model="note" rows="2" maxlength="250" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

  
<script>
export default {
    props: {
        trademarkId: Number,
        getTrademarkNoteList: {
            type: Function
        },
        getTrademarkList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            note: '',
            date: null,
        }
    },
    methods: {
        showInfo(id, trademarkId) {
            this.$refs['trademarkNoteFormModal'].show()
            this.id = id
            this.trademarkId = trademarkId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TrademarkNote'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.note = response.data.trademarkNote.note
                    this.date = response.data.trademarkNote.date
                } else {
                    this.$bvModal.hide('trademarkNoteFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                trademarkId: this.trademarkId,
                note: this.note,
                date: this.date
            }
            const controller = {
                name: 'TrademarkNote'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('trademarkNoteFormModal')
                            this.getTrademarkNoteList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
