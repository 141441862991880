<template>
<div>
    <!-- modal -->
    <b-modal id="patentNoteFormModal" centered size="lg" no-close-on-backdrop ref="patentNoteFormModal" :title="$t('patentNote.patent_note_info')" @close="clear()" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="infoRules" tag="form">
            <b-row>
                <b-col class="col-lg-12">
                    <b-form>
                        <validation-provider :name="$t('patentNote.patent_note_type')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <label for="patent-note-type-input">{{ $t('patentNote.patent_note_type') }}</label>
                            <b-form-select v-model="noteTypeSelect" value-field="id" text-field="name" size="sm" :options="noteTypeOption" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12 mt-1">
                    <b-form>
                        <label for="patent-note-date-input">{{ $t('patentNote.date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12">
                    <label for="patent-note">{{ $t('patentNote.note') }}</label>
                    <validation-provider #default="{ errors }" :name="$t('patentNote.note')" rules="required">
                        <b-form-textarea id="patent-note" :placeholder="$t('patentNote.note')" v-model="note" rows="9" maxlength="250" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getPatentNoteList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            patentId: 0,
            noteTypeOption: [],
            noteTypeSelect: 0,
            date: null,
            note: '',
        }
    },
    methods: {
        showInfo(id, patentId) {
            this.$refs['patentNoteFormModal'].show()
            this.id = id
            this.patentId = patentId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'PatentNote'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.note = response.data.patentNote.note
                    this.date = response.data.patentNote.date
                    this.noteTypeSelect = response.data.patentNote.patentNoteTypeId
                    this.noteTypeOption = response.data.patentNoteTypeList
                    this.noteTypeOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('patentNoteFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
            this.noteTypeSelect = 0
            this.noteTypeOption = []
            this.date = null
            this.note = ''
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                patentId: this.patentId,
                note: this.note,
                date: this.date,
                patentNoteTypeId: this.noteTypeSelect,
                employeeId: localStorage.getItem('EmployeeId')
            }
            const controller = {
                name: 'PatentNote'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('patentNoteFormModal')
                            this.getPatentNoteList()
                            this.$SaveAlert()
                            this.getPatentList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        }
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
