<template>
  <div>
    <!-- modal -->
    <b-modal
      id="permissionGroupFormModal"
      centered
      size="lg"
      no-close-on-backdrop
      ref="permissionGroupFormModal"
      :title=" $t('permissionGroup.permission_group_info')"
      :cancel-title=" $t('others.cancel_title')"
      :ok-title=" $t('others.ok_title')"
      cancel-variant="outline-secondary"
      @ok.prevent="addOrUpdate"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <label for="permission-group-name-input">{{ $t('permissionGroup.permission_group_name') }}</label>
          <b-form-group>
          <validation-provider
            #default="{ errors }"
            :name=" $t('permissionGroup.permission_group_name')"
            rules="required"
          >
          <b-form-input
            size="sm"
            id="permission-group-name-input"
            v-model="permissionGroupName"
            maxlength="50"
            :state="errors.length > 0 ? false:null"
          />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        </b-form>
    </validation-observer>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    getPermissionGroupList: {type: Function}
  },
  data() {
    return {
      id: 0,
      permissionGroupName: '',
    }
  },
  methods: {
    showInfo(id) {
      this.$refs['permissionGroupFormModal'].show()
      this.id = id
      const data = {id: this.id}
      const controller = {name : 'PermissionGroup'}
      const payload = {data: data, controller: controller}
      this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
          if(response.data.resultStatus == true){
              this.permissionGroupName = response.data.permissionGroup.name
          } else {
              this.$bvModal.hide('permissionGroupFormModal')
              this.$WarningAlert(response.data.resultMessage)
          }  
      })  
    },
    addOrUpdate() {
      const data = {id: this.id, name: this.permissionGroupName}
      const controller = {name : 'PermissionGroup'}
      const payload = {data: data, controller: controller}
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
              this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                if(response.data.resultStatus == true){
                  this.$bvModal.hide('permissionGroupFormModal')
                  this.getPermissionGroupList()
                  this.$SaveAlert()
                } else {
                  this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
      })
    },
  }
}
</script>
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}
[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}
.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}
[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem!important;
    margin-top: -1.5rem!important;
    background-color: #ea5455!important;
    border-radius: 0.358rem!important;
    left: 20px!important
}
</style>
