<template>
    <div>
      <b-card
        no-body
        class="card-statistics"
      >
        <b-card-header>
          <b-card-title>{{ $t('permissionsPendingApproval.information_card') }}</b-card-title>
          <b-card-text class="mr-25 mb-0">
              {{ $t('permissionsPendingApproval.updated_info') + ' ' + this.date }}
          </b-card-text>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col md="2" sm="6" class="mb-2 mb-md-0">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    :to="{ path: '/ReminderReport' }"
                    size="48"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="24"
                      icon="BellIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ reminderCount }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('permissionsPendingApproval.reminder_count') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="2" sm="6" class="mb-2 mb-md-0">
                  <b-media no-body>
                      <b-media-aside class="mr-2">
                      <b-avatar
                      :to="{ path: '/DailyworkReport', query: { billingStatusId: 1 } }"
                          size="48"
                          variant="light-danger"
                      >
                          <feather-icon
                          size="24"
                          icon="BriefcaseIcon"
                          />
                      </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                          {{ activeDailyworkCountForUser }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                          {{ $t('permissionsPendingApproval.Dailywork') }}
                      </b-card-text>
                      </b-media-body>
                  </b-media>
              </b-col>
            <b-col md="3" sm="6" class="mb-2 mb-md-0">
                  <b-media no-body>
                      <b-media-aside class="mr-2">
                      <b-avatar
                          :to="{ path: '/EmployeeHourlyAnnualLeave' }"
                          size="48"
                          variant="light-info"
                      >
                          <feather-icon
                          size="24"
                          icon="WatchIcon"
                          />
                      </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                          {{ hourlyAnnualToApprove }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                          {{ $t('permissionsPendingApproval.hourly_annual_to_approve') }}
                      </b-card-text>
                      </b-media-body>
                  </b-media>
              </b-col>
              <b-col md="2" sm="6" class="mb-2 mb-md-0">
                <b-media no-body>
                    <b-media-aside class="mr-2">
                    <b-avatar
                        :to="{ path: '/EmployeeAnnualLeave', query: { annualLeaveToApprove: this.annualLeaveToApprove } }"
                        size="48"
                        variant="light-info"
                    >
                        <feather-icon
                        size="24"
                        icon="ClockIcon"
                        />
                    </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                        {{ annualLeaveToApprove }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                        {{ $t('permissionsPendingApproval.yearly_annual_to_approve') }}
                    </b-card-text>
                    </b-media-body>
                </b-media>
            </b-col>
              <b-col md="3" sm="6" class="mb-2 mb-md-0">
                <b-media no-body>
                    <b-media-aside class="mr-2">
                    <b-avatar
                        :to="{ path: '/PatentBulletinAnalyseReport', query: { lastPatentBulletinId: this.lastPatentBulletinId } }"
                        size="48"
                        variant="light-success"
                    >
                        <feather-icon
                        size="24"
                        icon="DiscIcon"
                        />
                    </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                        {{ patentPublisedLastBulletin }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                        {{ $t('permissionsPendingApproval.patent_publised_last_bulletin') }}
                    </b-card-text>
                    </b-media-body>
                </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card>
        <b-card-body class="statistics-body">
          <job-report-list></job-report-list>
        </b-card-body>
      </b-card>
    </div>
  
    </template>
    
    <script>
  
  export default {
      data() {
        return {
          date: this.$formatFn(new Date()),
          reminderCount: 0,
          hourlyAnnualToApprove: 0,
          activeDailyworkCountForUser: 0,
          patentPublisedLastBulletin: 0,
          lastPatentBulletinId: 0,
          annualLeaveToApprove: 0,
        }
      },
      beforeMount() {
        const data = { id: localStorage.getItem('EmployeeId') }
        const controller = { name: 'Dashboard' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
        if (response.data.resultStatus == true) {
          this.reminderCount = response.data.dashboard.reminderCount
          this.hourlyAnnualToApprove = response.data.dashboard.hourlyAnnualToApprove
          this.patentPublisedLastBulletin = response.data.dashboard.relasedPatentsOnLastBulletin
          this.lastPatentBulletinId = response.data.dashboard.lastPatentBulletinId
          this.annualLeaveToApprove = response.data.dashboard.annualLeaveToApprove
          this.activeDailyworkCountForUser = response.data.dashboard.activeDailyworkCountForUser
        } else {
            this.$WarningAlert(response.data.resultMessage)
        }
      })
    }
  }
    </script>
    