<template>
<div>
    <!-- modal -->
    <b-modal id="nationalPatentFormModal" centered size="xl" no-close-on-backdrop ref="nationalPatentFormModal" :title="$t('nationalPatent.national_patent_info')" hide-footer>
        <agent-note-form ref="agentNoteFormModal"></agent-note-form>
        <client-form ref="clientFormModal"></client-form>
        <reminder-form ref="reminderFormModal"></reminder-form>
        <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
        <b-card>
            <div class="custom-search d-flex justify-content-end mb-1">
                <b-row>
                    <b-col>
                        <b-button variant="outline-primary" size="sm" class="ml-05" @click.prevent="addOrUpdate">
                            {{ $t('others.ok_title') }}
                        </b-button>
                        <b-dropdown v-if="id > 0" style="height: 2.1em;" class="ml-05" size="sm" :text="$t('others.invoices')" variant="outline-primary">
                            <b-dropdown-item @click="getClientInvoiceReport(2)">{{$t('others.InvoicesToBePaid')}}: {{ invoicesToBePaid }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(3)">{{$t('others.AllInvoices')}}: {{ allInvoices }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(4)">{{$t('others.InvoicesToBePaidForThisJob')}}: {{invoicesToBePaidForThisJob}}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(1)">{{$t('others.AllInvoicesRelatedToThisJob')}}: {{ allInvoicesRelatedToThisJob }}</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                            <template #button-content>
                                <feather-icon icon="PrinterIcon" size="10" class="align-middle" />
                            </template>
                            <div class="dropdown-scroll">
                                <b-dropdown-item @click="downloadNationalPatentWordFile(wordTemplate.id, wordTemplate.name)" v-for="wordTemplate in wordTemplateList" :key="wordTemplate.id">
                                    <span>{{ wordTemplate.name }}</span>
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                        <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                            <template #button-content>
                                <feather-icon icon="SendIcon" size="10" class="align-middle" />
                            </template>
                            <div class="dropdown-scroll">
                                <b-dropdown-item @click="prepareNationalPatentMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                    <span>{{mailTemplate.explanation}}</span>
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                        <b-button variant="outline-primary" class="ml-05" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                            <feather-icon icon="BellIcon" size="10" class="align-middle" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col">
                        <b-form>
                            <label for="national-patent-name-input">{{ $t('nationalPatent.national_patent_folder_no') }}</label>
                            <b-form-group>
                                <b-form-input id="national-patent-name-input" v-model="nationalPatentRefCode" size="sm" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col">
                        <b-form>
                            <label for="national-patent-title-input">{{ $t('patent.title') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('nationalPatent.title')" rules="required">
                                    <b-form-input id="national-patent-title-input" v-model="title" maxlength="250" size="sm" :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col">
                        <b-form>
                            <label for="national-patent-title-en-input">{{ $t('nationalPatent.title_en') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('nationalPatent.title_en')" rules="required">
                                    <b-form-input id="national-patent-title-en-input" v-model="titleEN" maxlength="250" size="sm" :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col">
                        <b-form>
                            <label for="national-patent-register-no-input">{{ $t('nationalPatent.register_no') }}</label>
                            <b-form-group>
                                <b-form-input id="national-patent-register-no-input" v-model="registerNo" maxlength="250" size="sm" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col">
                        <label for="national-patent-last-application-date-input">{{ $t('nationalPatent.last_application_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="lastApplicationDate"></CustomDatePicker>
                    </b-col>
                    <b-col class="col">
                        <label for="national-patent-deadline-date-input">{{ $t('nationalPatent.deadline_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="deadlineDate"></CustomDatePicker>
                    </b-col>
                    <b-col class="col">
                        <label for="national-patent-instruction-date-input">{{ $t('nationalPatent.instruction_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="instructionDate"></CustomDatePicker>
                    </b-col>
                    <b-col class="col">
                        <label for="national-patent-type">{{ $t('nationalPatent.national_patent_type') }}</label>
                        <b-form>
                            <validation-provider :name="$t('nationalPatent.national_patent_type')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                                <b-form-select :state="errors.length > 0 ? false : null" class="custom-height" v-model="patentTypeSelect" value-field="id" text-field="name" :options="patentTypeOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col">
                        <b-form>
                            <label for="national-patent-ipc-code-input">{{ $t('nationalPatent.ipc_code') }}</label>
                            <b-form-group>
                                <b-form-input id="national-patent-ipc-code-input" v-model="ipcCode" maxlength="250" size="sm" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col">
                        <label for="national-patent-register-date-input">{{ $t('nationalPatent.register_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="registerDate"></CustomDatePicker>
                    </b-col>
                    <b-col class="col">
                        <b-form>
                            <label for="national-patent-application-no-input">{{ $t('nationalPatent.application_no') }}</label>
                            <b-form-group>
                                <b-form-input id="national-patent-application-no-input" v-model="applicationNo" maxlength="250" size="sm" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col">
                        <label for="national-patent-application-date-input">{{ $t('nationalPatent.application_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="applicationDate"></CustomDatePicker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col">
                        <b-form>
                            <label for="national-patent-application-type-input">{{ $t('nationalPatent.national_patent_application') }}</label>
                            <b-form-select @change="countryTypeSelect($event)" :disabled="id != 0" v-model="nationalPatentApplicationTypeSelect" value-field="id" size="sm" text-field="name" :options="nationalPatentApplicationTypeOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col">
                        <b-form>
                            <label for="national-patent-abstract-input">{{ $t('nationalPatent.abstract') }}</label>
                            <b-form-group>
                                <b-form-input id="national-patent-abstract-input" v-model="abstract" maxlength="250" size="sm" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col">
                        <b-form>
                            <label for="national-patent-agent-ref-code-input">{{ $t('nationalPatent.national_patent_agent_ref_code') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="national-patent-agent-ref-code-input" v-model="agentReferenceNo" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <div class="mb-1">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <b-card class="checkbox-scroll">
                                        <b-form-checkbox-group v-model="countrySelect">
                                            <b-form-checkbox class="checkbox-container" v-for="(country, index) in countryOption" :key="index" :value="country.id" :disabled="isChecked(country.id)">
                                                {{ country.name }}
                                            </b-form-checkbox>
                                        </b-form-checkbox-group>
                                    </b-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-row>
                <b-row v-if="hiddenControl == false">
                    <b-col class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="national-patent-agent-input"> {{ $t('nationalPatent.agent') }} </label>
                            <b-input-group class="d-flex align-items-center">
                                <b-form-group label-for="national-patent-agent-input" class="flex-grow-1 mb-0">
                                    <v-select id="national-patent-agent-input" v-model="nationalPatentAgentSelect" :options="nationalPatentAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                                </b-form-group>
                                <b-input-group-append>
                                    <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                        <template #button-content>
                                            <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                        </template>
                                        <b-dropdown-item @click="getClientInfo(0)">
                                            <span>{{this.$t('others.add_client')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getAgentNoteInfo(nationalPatentAgentSelect.clientAccountingId)">
                                            <span>{{this.$t('others.show_note')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getClientInfo(nationalPatentAgentSelect.clientId)">
                                            <span>{{this.$t('others.show_agent')}}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-else-if="hiddenControl == true">
                    <b-col class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="national-patent-agent-input"> {{ $t('nationalPatent.agent') }} </label>
                            <b-input-group class="d-flex align-items-center">
                                <b-form-group label-for="national-patent-agent-input" class="flex-grow-1 mb-0">
                                    <v-select id="national-patent-agent-input" v-model="nationalPatentAgentSelect" :options="nationalPatentAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                                </b-form-group>
                                <b-input-group-append>
                                    <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                        <template #button-content>
                                            <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                        </template>
                                        <b-dropdown-item @click="getClientInfo(0)">
                                            <span>{{this.$t('others.add_client')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getClientInfo(nationalPatentAgentSelect.clientId)">
                                            <span>{{this.$t('others.show_agent')}}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <b-form>
                            <b-form-group>
                                <label for="national-patent-invoice-client-accounting-input"> {{ $t('nationalPatent.invoice_client_accounting') }} </label>
                                <b-input-group class="d-flex align-items-center">
                                    <b-form-group label-for="national-patent-invoice-client-accounting-input" class="flex-grow-1 mb-0">
                                        <v-select id="national-patent-invoice-client-accounting-input" v-model="nationalPatentInvoiceClientSelect" :options="nationalPatentInvoiceClientOption" @input="setClientIdWithInvoiceClientCheck" label="clientName" @search="onInvoiceClientAccountingSearch" class="w-100"></v-select>
                                    </b-form-group>
                                    <b-input-group-append>
                                        <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                            <template #button-content>
                                                <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                            </template>
                                            <b-dropdown-item @click="getClientInfo(0)">
                                                <span>{{this.$t('others.add_client')}}</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="getAgentNoteInfo(nationalPatentInvoiceClientSelect.clientAccountingId)">
                                                <span>{{this.$t('others.show_note')}}</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="getClientInfo(nationalPatentInvoiceClientSelect.clientId)">
                                                <span>{{this.$t('others.show_agent')}}</span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-card>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab active :title="$t('nationalPatent.owner')" style="width:100%;">
                            <b-card-text>
                                <national-patent-owner-list :getClientInfo="getClientInfo" :getNationalPatentList="getNationalPatentList" :nationalPatentId="this.id"></national-patent-owner-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('nationalPatent.inventor')" style="width:100%;">
                            <b-card-text>
                                <national-patent-inventor-list :getNationalPatentList="getNationalPatentList" :nationalPatentId="this.id"></national-patent-inventor-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('nationalPatent.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="16"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('nationalPatent.national_patent_annuity')" style="width:100%;">
                            <b-card-text>
                                <national-patent-annuity-list :getNationalPatentList="getNationalPatentList" :nationalPatentId="this.id"></national-patent-annuity-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('nationalPatent.country_and_history')" style="width:100%;">
                            <b-card-text>
                                <national-patent-country-list ref="childComponentReference" :getNationalPatentList="getNationalPatentList" :nationalPatentApplicationTypeSelect="nationalPatentApplicationTypeSelect" :nationalPatentId="this.id"></national-patent-country-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('nationalPatent.responsible')" style="width:100%;">
                            <b-card-text>
                                <national-patent-responsible-list :nationalPatentId="this.id" :clientId="this.clientId"></national-patent-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.job')" style="width:100%;">
                            <b-card-text>
                                <job-list :recordId="this.id" :moduleId="16" :recordNo="this.nationalPatentRefCode"></job-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="16"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        nationalPatentForm: Object,
        getNationalPatentList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            nationalPatentRefCode: '',
            applicationNo: '',
            registerNo: '',
            applicationDate: null,
            registerDate: null,
            lastApplicationDate: null,
            instructionDate: null,
            deadlineDate: null,
            title: '',
            titleEN: '',
            ipcCode: '',
            nationalPatentAgentSelect: 0,
            nationalPatentAgentOption: [],
            nationalPatentApplicationTypeSelect: 0,
            nationalPatentApplicationTypeOption: [],
            patentTypeSelect: 0,
            patentTypeOption: [],
            agentReferenceNo: '',
            hiddenControl: true,
            nationalPatentInvoiceClientSelect: 0,
            nationalPatentInvoiceClientOption: [],
            wordTemplateList: [],
            mailTemplateList: [],
            nationalPatentApplicationTypeSelect: 0,
            nationalPatentApplicationTypeOption: [],
            countryOption: [],
            countrySelect: [],
            countrySelectControl: [],
            abstract: '',
            invoicesToBePaidForThisJob: 0,
            invoicesToBePaid: 0,
            allInvoices: 0,
            allInvoicesRelatedToThisJob: 0,
            clientAccountingId: 0,
        }
    },
    methods: {
        getClientInvoiceReport(type) {
            this.$refs.clientInvoiceReportListCallForm.showInfo(type, this.clientAccountingId, 16, this.id)
        },
        isChecked(countryId) {
            return this.countrySelectControl.includes(countryId);
        },
        countryTypeSelect(selected) {
            this.countryOption = []
            const countryData = {
                applicationTypeId: selected,
                nationalPatentId: this.id
            }
            const countryController = {
                name: 'NationalPatentCountry',
                actionName: 'GetNationalPatentCountryListByNationalPatentId'
            }
            const countryPayload = {
                data: countryData,
                controller: countryController
            }
            this.$store.dispatch('moduleAuth/customService', countryPayload).then((countryResponse) => {
                this.countryOption = countryResponse.data.countryList
                if (countryResponse.data.countryList != null) {
                    this.countryOption = []
                    countryResponse.data.countryList.forEach(element => {
                        this.countryOption.push(element)
                    })
                }
            })
        },
        async getReminderInfo() {
            this.$refs.reminderFormModal.showInfo(0, this.id, 4)
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
            } else {
                this.clientId = null;
            }
        },
        setClientIdWithInvoiceClientCheck(event) {
            if (event != null) {
                if (this.nationalPatentInvoiceClientSelect.forInvoice == false) {
                    this.clientId = null;
                    this.nationalPatentInvoiceClientSelect = null;
                    this.$ForInvoiceAlert()
                } else {
                    this.clientId = event.clientId
                }
            } else {
                this.clientId = null;
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.nationalPatentAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        onInvoiceClientAccountingSearch(invoiceClientAccountingSearch, loading) {
            if (invoiceClientAccountingSearch.length) {
                loading(true);
                this.invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, this);
            }
        },
        invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, vm) {
            if (invoiceClientAccountingSearch.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: invoiceClientAccountingSearch
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.nationalPatentInvoiceClientOption = response.data.clientList
                    loading(false)
                })
            }
        },
        showInfo(id) {
            this.$refs['nationalPatentFormModal'].show()
            this.countryOption = []
            this.countrySelectControl = []
            this.countrySelect = []
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'NationalPatent'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.wordTemplateList = response.data.wordTemplateList
                    this.mailTemplateList = response.data.mailTemplateList
                    this.applicationDate = response.data.nationalPatent.applicationDate
                    this.applicationNo = response.data.nationalPatent.applicationNo
                    this.registerDate = response.data.nationalPatent.registerDate
                    this.registerNo = response.data.nationalPatent.registerNo
                    this.lastApplicationDate = response.data.nationalPatent.lastApplicationDate
                    this.ipcCode = response.data.nationalPatent.ipcCode
                    this.title = response.data.nationalPatent.title
                    this.titleEN = response.data.nationalPatent.titleEn
                    this.instructionDate = response.data.nationalPatent.instructionDate
                    this.deadlineDate = response.data.nationalPatent.deadline
                    this.nationalPatentRefCode = response.data.nationalPatent.recordNo
                    this.agentReferenceNo = response.data.nationalPatent.agentReferenceNo
                    this.nationalPatentApplicationTypeOption = response.data.nationalPatentApplicationTypeList
                    this.patentTypeOption = response.data.patentTypeList
                    this.nationalPatentApplicationTypeSelect = response.data.nationalPatent.nationalPatentApplicationTypeId
                    this.patentTypeSelect = response.data.nationalPatent.patentTypeId
                    this.abstract = response.data.nationalPatent.abstract
                    this.patentTypeOption.splice(0, 0, this.$nullSelected())
                    // Country 
                    if (response.data.nationalPatent.nationalPatentApplicationTypeId != null) {
                        const countryData = {
                            applicationTypeId: response.data.nationalPatent.nationalPatentApplicationTypeId,
                            nationalPatentId: this.id
                        }
                        const countryController = {
                            name: 'NationalPatentCountry',
                            actionName: 'GetNationalPatentCountryListByNationalPatentId'
                        }
                        const countryPayload = {
                            data: countryData,
                            controller: countryController
                        }
                        this.$store.dispatch('moduleAuth/customService', countryPayload).then((countryResponse) => {
                            this.countryOption = countryResponse.data.countryList
                            if (countryResponse.data.countryList != null) {
                                countryResponse.data.nationalPatentCountryList.forEach(element => {
                                    this.countrySelect.push(element.countryId)
                                })
                                this.countrySelectControl = this.countrySelect;
                            }
                        })
                    }
                    if (this.nationalPatentApplicationTypeOption != []) {
                        this.nationalPatentApplicationTypeOption.splice(0, 0, this.$nullSelected())
                    }
                    var link = response.data.nationalPatent.epApplicationNo
                    if (link != null) {
                        var linkSplice = link.indexOf('.')
                        link = link.substring(0, linkSplice);
                        this.linkPlusApplicationNo = 'https://register.epo.org/application?number=' + 'EP' + link
                    }
                    if (response.data.nationalPatent.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.nationalPatent.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.nationalPatentAgentOption = clientAccountingResponse.data.clientList
                            this.nationalPatentAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.nationalPatentAgentOption = []
                        this.nationalPatentAgentSelect = 0;
                    }
                    if (response.data.nationalPatent.invoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.nationalPatent.invoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.nationalPatentInvoiceClientOption = clientAccountingResponse.data.clientList
                            this.nationalPatentInvoiceClientSelect = clientAccountingResponse.data.clientList[0]
                        })
                    } else {
                        this.nationalPatentInvoiceClientOption = []
                        this.nationalPatentInvoiceClientSelect = 0;
                    }
                    if (this.id > 0) {
                        let accountingId = 0
                        if (response.data.nationalPatent.clientAccountingId > 0)
                            accountingId = response.data.nationalPatent.clientAccountingId
                        if (response.data.nationalPatent.invoiceClientAccountingId > 0)
                            accountingId = response.data.nationalPatent.invoiceClientAccountingId
                        this.clientAccountingId = accountingId
                        const invoiceData = {
                            clientAccountingId: accountingId,
                            moduleId: 16,
                            recordId: this.id
                        }
                        const invoiceController = {
                            name: 'ClientAccounting',
                            actionName: 'GetInvoiceCountByClientAccountId'
                        }
                        const invoicePayload = {
                            data: invoiceData,
                            controller: invoiceController
                        }
                        this.$store.dispatch('moduleAuth/customService', invoicePayload).then((invoiceResponse) => {
                            this.invoicesToBePaid = invoiceResponse.data.invoicesToBePaidCount
                            this.allInvoices = invoiceResponse.data.allInvoiceCount
                            this.allInvoicesRelatedToThisJob = invoiceResponse.data.allInvoicesRelatedToThisJobCount
                            this.invoicesToBePaidForThisJob = invoiceResponse.data.invoicesToBePaidForThisJobCount
                        })
                    } else {
                        this.invoicesToBePaid = 0
                        this.allInvoices = 0;
                        this.allInvoicesRelatedToThisJob = 0;
                        this.invoicesToBePaidForThisJob = 0;
                    }
                } else {
                    this.$bvModal.hide('nationalPatentFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadNationalPatentWordFile(documentId, name) {
            const data = {
                moduleId: 4,
                documentId: documentId,
                nationalPatentId: this.id
            }
            const controller = {
                name: 'NationalPatent',
                actionName: 'DownloadNationalPatentWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            if (this.nationalPatentInvoiceClientSelect === null) {
                this.invoiceClientId = 0
            } else {
                this.invoiceClientId = this.nationalPatentInvoiceClientSelect.clientAccountingId
            }

            const data = {
                id: this.id,
                agentReferenceNo: this.agentReferenceNo,
                clientAccountingId: this.nationalPatentAgentSelect.clientAccountingId,
                applicationDate: this.applicationDate,
                applicationNo: this.applicationNo,
                registerDate: this.registerDate,
                registerNo: this.registerNo,
                lastApplicationDate: this.lastApplicationDate,
                deadline: this.deadlineDate,
                instructionDate: this.instructionDate,
                ipcCode: this.ipcCode,
                title: this.title,
                titleEn: this.titleEN,
                epApplicationNo: this.epApplicationNo,
                nationalPatentApplicationTypeId: this.nationalPatentApplicationTypeSelect,
                patentTypeId: this.patentTypeSelect,
                invoiceClientAccountingId: this.invoiceClientId,
                countryList: this.countrySelect,
                abstract: this.abstract,
            }

            const controller = {
                name: 'NationalPatent'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getNationalPatentList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.nationalPatentId
                                this.nationalPatentRefCode = response.data.recordNo
                                this.hiddenControl = false
                            }
                            const saveCountryData = {
                                nationalPatentId: this.id,
                                countryList: this.countrySelect
                            }
                            const saveCountryController = {
                                name: 'NationalPatentCountry'
                            }
                            const saveCountryPayload = {
                                data: saveCountryData,
                                controller: saveCountryController
                            }
                            this.$store.dispatch('moduleAuth/save', saveCountryPayload).then((saveCountryResponse) => {
                                if (saveCountryResponse.data.resultStatus == true) {
                                    this.getNationalPatentList()
                                    this.$refs.childComponentReference.getNationalPatentCountryList();
                                } else {
                                    this.$WarningAlert(response.data.resultMessage)
                                }
                            })
                            // this.correctApplicationNo = applicationNo
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        prepareNationalPatentMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                nationalPatentId: this.id
            }
            const controller = {
                name: 'NationalPatent',
                actionName: 'PrepareNationalPatentMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] body.dark-layout .b-calendar-grid.form-control .small {
    background: #161d31;
    display: none !important;
}

.b-calendar .b-calendar-grid-help {
    color: #6e6b7b !important;
    display: none;
}

[dir] .custom-height {
    height: 30px;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    /* Satırların sonunda bir sonraki satıra geçmesini sağlar */
}

.checkbox-container .custom-control {
    flex-basis: calc(100% / 7);
    /* Her satırda 7 eleman olacak şekilde hesaplanır */
    padding: 1px;
}

/* Scroll içine almak için bir scroll kutusu oluşturuyoruz */
.checkbox-scroll {
    max-height: 200px;
    /* İstenilen yüksekliği belirleyin */
    overflow-y: auto;
    /* Dikey yönde scrollbar görünür */
}

[dir=ltr] .custom-control-inline {
    margin-right: 1rem !important;
    margin-top: 1em !important;
    display: inline-table !important;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
