<template>
    <div>
      <!-- modal -->
      <b-modal
        id="clientAddressFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="clientAddressFormModal"
        :title=" $t('clientAddress.add_address_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
      <validation-observer ref="simpleRules">
      <b-row>
        <b-col class="col-lg-12">
          <b-form>
            <validation-provider :name=" $t('clientAddress.address_type')" :rules="{ excluded:0}"  v-slot="{ errors }">
              <label for="client-address-type-input">{{ $t('clientAddress.address_type') }}</label>
                <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="addressTypeSelect" value-field="id" text-field="name"
                  :options="addressTypeOption" />
                <small class="text-danger">{{ errors[0] }}</small>       
            </validation-provider>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-lg-12 mt-1">
          <b-form>
            <validation-provider :name=" $t('clientAddress.country')" :rules="{ excluded:0}" v-slot="{ errors }">
              <label for="country-input">{{ $t('clientAddress.country') }}</label>
                <b-form-select size="sm" :state="errors.length > 0 ? false:null" v-model="countrySelect" value-field="id" text-field="name"
                :options="countryOption" />
                <small class="text-danger">{{ errors[0] }}</small>       
            </validation-provider>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="mt-2" for="client-address-input">{{ $t('clientAddress.address') }}</label>
          <validation-provider #default="{ errors }" :name=" $t('clientAddress.address')" rules="required" >
            <b-form-textarea id="client-address-input" :placeholder=" $t('clientAddress.address_input')" rows="3" size="sm" v-model="addressInput"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>

  export default {
    props: {
      getClientAddressList: {type: Function}
    },
    data() {
      return {
        addressTypeSelect: 0,
        addressTypeOption: [],
        addressInput: '',
        countrySelect: 0,
        countryOption: [],
        id: 0,
        clientId:0
      }
    },
    methods: {
      showInfo(id, clientId) {
        this.$refs['clientAddressFormModal'].show()
        this.id = id
        this.clientId = clientId;
        const data = {id: this.id}
        const controller = {name : 'ClientAddress'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.addressInput = response.data.clientAddress.address
                this.addressTypeOption = response.data.addressTypeList
                this.addressTypeSelect = response.data.clientAddress.addressTypeId
                this.countryOption = response.data.countryList
                this.countrySelect = response.data.clientAddress.countryId
                this.countryOption.splice(0, 0, this.$nullSelected())
                this.addressTypeOption.splice(0, 0, this.$nullSelected())
            } else {
                this.$bvModal.hide('clientAddressFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, clientId: this.clientId, address: this.addressInput, addressTypeId: this.addressTypeSelect, countryId: this.countrySelect}
        const controller = {name : 'ClientAddress'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('clientAddressFormModal')
                    this.getClientAddressList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  