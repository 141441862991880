<template>
<div>
    <!-- modal -->
    <find-record-form ref="findRecordFormModal" :moduleId="this.moduleId" @handleImportedRecordId="handleImportedRecordId"></find-record-form>
    <b-modal id="annuityDetailFormModal" centered size="xl" no-close-on-backdrop ref="annuityDetailFormModal" :title=" $t('annuityDetail.annuity_detail_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" hide-footer>
        <validation-observer ref="simpleRules">
            <div class="custom-search d-flex justify-content-end">
                <b-row>
                    <div style="float:right;" class="custom-search d-flex justify-content-end">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <b-button v-if="recordIdCheck == false" style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click.prevent="findRecord">
                                    {{$t('annuityDetail.find_record')}}
                                </b-button>                   
              
                                <b-button style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click.prevent="addOrUpdate" :disabled="(canSave)">
                                    {{$t('others.ok_title')}}
                                </b-button>                  
                            </div>
                        </b-form-group>
                    </div>                
                </b-row>
            </div>
            <b-row>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="annuity-detail-record-no-input">{{ $t('annuityDetail.record_no') }}</label>
                        <b-form-group>
                            <b-form-input id="annuity-detail-record-no-input" size="sm" disabled v-model="recordNo" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="annuity-detail-module-input">{{ $t('annuityDetail.module') }}</label>
                        <b-form-group>
                            <b-form-input id="annuity-detail-module-input" size="sm" disabled v-model="module" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="annuity-detail-application-no-input">{{ $t('annuityDetail.application_no') }}</label>
                        <b-form-group>
                            <b-form-input id="annuity-detail-application-no-input" size="sm" v-model="applicationNo" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form-group>
                        <label for="annuity-detail-application-date-input">{{ $t('annuityDetail.application_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="applicationDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3">
                    <b-form-group>
                        <label for="annuity-detail-status-input">{{ $t('annuityDetail.status')  }}</label>
                        <b-form-select v-model="annuityDetailStatusSelect" size="sm" :options="annuityDetailStatusOption" value-field="id" name="annuity-detail-status-input" text-field="name" />
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="annuity-detail-receipt-no-input">{{ $t('annuityDetail.receipt_no') }}</label>
                        <b-form-group>
                            <b-form-input id="annuity-detail-receipt-no-input" size="sm" v-model="receiptNo" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="annuity-detail-agent-year-input">{{ $t('annuityDetail.agent_year') }}</label>
                        <b-form-group>
                            <b-form-input id="annuity-detail-agent-year-input" disabled size="sm" v-model="agent_year" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="annuity-detail-stock-year-input">{{ $t('annuityDetail.stock_year') }}</label>
                        <b-form-group>
                            <b-form-input id="annuity-detail-stock-year-input" disabled size="sm" v-model="stock_year" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="annuity-detail-holder-name-input">{{ $t('annuityDetail.holder_name') }}</label>
                        <b-form-group>
                            <b-form-input id="annuity-detail-holder-name-input" disabled size="sm" v-model="holderName" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="annuity-detail-stock-agent-input">{{ $t('annuityDetail.stock_agent') }}</label>
                        <b-form-group>
                            <b-form-input id="annuity-detail-stock-agent-input" disabled size="sm" v-model="stockAgent" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="annuity-detail-stock-owner-input">{{ $t('annuityDetail.stock_owner') }}</label>
                        <b-form-group>
                            <b-form-input id="annuity-detail-stock-owner-input" disabled size="sm" v-model="stockOwner" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="annuity-detail-price-input">{{ $t('annuityDetail.price') }}</label>
                        <b-form-group>
                            <b-form-input id="annuity-detail-price-input" size="sm" v-model="price" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3 mb-2">
                    <label for="annuity-detail-is-late-fee-input">{{ $t('annuityDetail.is_late_fee') }}</label>
                    <b-form-checkbox class="custom-control-success" name="annuityDetail-is-late-fee-input" id="country-is-late-fee-input" v-model="isLateFee" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
                <b-col class="col-lg-3 mb-2">
                    <label for="annuity-detail-is-application-invalid-input">{{ $t('annuityDetail.is_application_invalid') }}</label>
                    <b-form-checkbox class="custom-control-success" name="annuityDetail-is-application-invalid-input" id="annuityDetail-is-application-invalid-input" v-model="isApplicationInvalid" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
                <b-col class="col-lg-3 mb-2">
                    <label for="annuity-detail-is-patent-input">{{ $t('annuityDetail.is_patent') }}</label>
                    <b-form-checkbox class="custom-control-success" name="annuityDetail-is-patent-input" id="annuityDetail-is-patent-input" v-model="isPatent" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
                <b-col class="col-lg-3 mb-2">
                    <label for="annuity-detail-is-no-payment-input">{{ $t('annuityDetail.is_no_payment') }}</label>
                    <b-form-checkbox class="custom-control-success" name="annuityDetail-is-no-payment-input" id="annuityDetail-is-no-payment-input" v-model="isNoPayment" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getAnnuityList: {
            type: Function
        },
        getAnnuityDetailList: {
            type: Function
        },
    },
    data() {
        return {
            recordNo: '',
            module: '',
            applicationNo: '',
            agent_year: '',
            stock_year: '',
            isLateFee: '',
            annuityDetailStatusOption: [],
            annuityDetailStatusSelect: 0,
            receiptNo: '',
            isApplicationInvalid: '',
            isPatent: '',
            isNoPayment: '',
            holderName: '',
            stockAgent: '',
            price: '',
            stockOwner: '',
            applicationDate: null,
            recordId: '',
            canSave: false,
            officialFeeId: 0,
            documentId: 0,
            recordIdCheck: false,
            moduleId: 0,
        }
    },
    methods: {
        handleImportedRecordId(importedRecordId) {
            this.recordId = importedRecordId;
        },
        showInfo(id) {
            this.$refs['annuityDetailFormModal'].show()
            this.rows = []
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'AnnuityDetail',
                actionName: 'GetAnnuityDetailInfo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.annuityDetailStatusOption = response.data.annuityDetailStatusList
                    this.recordNo = response.data.annuityDetail.recordNo
                    this.module = response.data.annuityDetail.module
                    this.applicationNo = response.data.annuityDetail.applicationNo
                    this.agent_year = response.data.annuityDetail.year
                    this.isLateFee = response.data.annuityDetail.isLateFee
                    this.isApplicationInvalid = response.data.annuityDetail.isApplicationInvalid
                    this.isPatent = response.data.annuityDetail.isPatent
                    this.isNoPayment = response.data.annuityDetail.isNoPayment
                    this.stock_year = response.data.annuityDetail.stockYear
                    this.holderName = response.data.annuityDetail.holderName
                    this.stockAgent = response.data.annuityDetail.stockAgent
                    this.stockOwner = response.data.annuityDetail.stockOwner
                    this.price = response.data.annuityDetail.price
                    this.applicationDate = response.data.annuityDetail.applicationDate
                    this.recordId = response.data.annuityDetail.recordId
                    this.moduleId = response.data.annuityDetail.moduleId
                    this.officialFeeId = response.data.annuityDetail.officialFeeId
                    this.documentId = response.data.annuityDetail.documentId
                    this.receiptNo = response.data.annuityDetail.receiptNo
                    this.annuityDetailStatusOption.splice(0, 0, this.$nullSelected())

                    if (response.data.annuityDetail.annuityDetailStatusId > 0) {
                        this.annuityDetailStatusSelect = response.data.annuityDetail.annuityDetailStatusId
                    } else {
                        this.annuityDetailStatusSelect = 0
                    }
                    if (this.officialFeeId > 0) {
                        this.canSave = true
                    } else {
                        this.canSave = false
                    }
                    if (this.recordId > 0) {
                        this.recordIdCheck = true
                    } else {
                        this.recordIdCheck = false
                    }
                } else {
                    this.$bvModal.hide('annuityDetailFormModal')
                    this.$bvModal.hide('annuityFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                isNoPayment: this.isNoPayment,
                isApplicationInvalid: this.isApplicationInvalid,
                isPatent: this.isPatent,
                isLateFee: this.isLateFee,
                annuityDetailStatusId: this.annuityDetailStatusSelect,
                applicationNo: this.applicationNo,
                applicationDate: this.applicationDate,
                recordNo: this.recordNo,
                moduleId: this.moduleId,
                documentId: this.documentId,
                price: this.price,
                officialFeeId: this.officialFeeId,
                recordId : this.recordId
            }
            const controller = {
                name: 'AnnuityDetail',
                actionName: 'SaveAnnuityDetail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('annuityDetailFormModal')
                            this.getAnnuityDetailList()
                            this.getAnnuityList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        findRecord() {
            this.$refs.findRecordFormModal.showInfo()
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
