<template>
<div>
    <!-- modal -->
    <b-modal id="designObjectionToUsFormModal" centered size="lg" no-close-on-backdrop ref="designObjectionToUsFormModal" :title=" $t('designObjectionToUs.objectiontous_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @close="clear()" @ok.prevent="addOrUpdate">
        <validation-observer ref="infoRules" tag="form">
            <b-row>
                <b-col class="col-md-12 mt-1">
                    <b-form>
                        <label for="designObjectionToUs-No-input">{{ $t('designObjectionToUs.no') }}</label>
                        <b-form-group>
                            <b-form-input id="designObjectionToUs-applicationNo-input" v-model="applicationNo" maxlength="20" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12 mt-1">
                    <label for="design-date-input">{{ $t('designObjectionToUs.date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label for="designObjectionToUs_note_text_area">{{ $t('designObjectionToUs.note') }}</label>
                    <b-form-textarea id="designObjectionToUs_note_text_area" maxlength="500" :placeholder=" $t('designObjectionToUs.note')" v-model="note" rows="3" />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12 mt-1">
                    <b-form>
                        <label for="designObjectionToUs-owner-input">{{ $t('designObjectionToUs.owner') }}</label>
                        <b-form-group>
                            <b-form-input id="designObjectionToUs-owner-input" v-model="owner" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12 mt-1">
                    <b-form>
                        <label for="status-option">{{ $t('designObjectionToUs.status') }}</label>
                        <b-form-select v-model="designObjectionToUsStatusSelect" value-field="id" text-field="name" :options="designObjectionToUsStatusOption" />
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDesignObjectionToUsList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            desginId:0,
            owner: '',
            note: '',
            applicationNo: '',
            date: null,
            designObjectionToUsStatusOption: [],
            designObjectionToUsStatusSelect: '',
        }
    },
    methods: {
        showInfo(id, desginId) {
            this.$refs['designObjectionToUsFormModal'].show()
            this.id= id
            this.desginId= desginId

            const data = {
                id: this.id
            }
            const controller = {
                name: 'DesignObjectionToUs'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {                
                    this.owner = response.data.designObjectionToUs.owner
                    this.note = response.data.designObjectionToUs.note
                    this.date = response.data.designObjectionToUs.date
                    this.applicationNo = response.data.designObjectionToUs.applicationNo
                    this.designObjectionToUsStatusSelect = response.data.designObjectionToUs.designObjectionToUsStatusId
                    this.designObjectionToUsStatusOption = response.data.designObjectionToUsStatusList
                    this.designObjectionToUsStatusOption.splice(0, 0, this.$nullSelected())
            })
        },
        clear() {
            this.owner = null,
            this.note = null,
            this.date = null,
            this.applicationNo = null
            this.designObjectionToUsStatusSelect = 0
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                designId: this.desginId,
                applicationNo: this.applicationNo,
                note: this.note,
                owner: this.owner,
                date: this.date,
                designObjectionToUsStatusId: this.designObjectionToUsStatusSelect
            }
            const controller = {
                name: 'DesignObjectionToUs'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('designObjectionToUsFormModal')
                            this.date = null
                            this.getDesignObjectionToUsList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        }

    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
