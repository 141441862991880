<template>
<div>
    <employee-hourly-annual-leave-request-form :getHourlyAnnualLeaveList="getHourlyAnnualLeaveList" ref="employeeHourlyAnnualLeaveRequestFormModal">
    </employee-hourly-annual-leave-request-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="annual-leave-employee-input">{{ $t('annualLeave.employee') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="employee" v-on:keyup.enter="getHourlyAnnualLeaveList()" :placeholder="$t('annualLeave.employee')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="annual-leave-status-input">{{ $t('annualLeave.status') }}</label>
                <b-form-select v-model="annualLeaveStatusSelect" @change="getHourlyAnnualLeaveList()" size="sm" value-field="id" text-field="name" :options="annualLeaveStatusOption" />
            </b-col>
            <b-col class="col-md-2">
                <label for="annual-leave-request-date-start-input">{{ $t('annualLeave.request_date_start') }}</label>
                <b-form-group>
                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('annualLeave.request_date_start')" size="sm" v-model="requestDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-form-group>
            </b-col>
            <b-col class="col-md-2">
                <label for="annual-leave-request-date-end-input">{{ $t('annualLeave.request_date_end') }}</label>
                <b-form-group>
                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('annualLeave.request_date_end')" size="sm" v-model="requestDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-form-group>
            </b-col>
            <b-col class="col-md-2"></b-col>
            <b-col class="col-md-2 custom-search d-flex justify-content-end">
                    <div class="d-flex align-items-center">
                        <b-button style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    </div>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="employeeHourlyAnnualLeaveTable" ref="employeeHourlyAnnualLeaveTable" :columns="columns" styleClass="vgt-table condensed tableSize" :rows="rows" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                  }" :select-options="{
                    enabled: false,
                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                  }" :pagination-options="{
                    enabled: true,
                    perPage: pageLength
                  }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('hourlyAnnualLeave.status') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" disable @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <!-- ,'100', '1000'
                        Bu alanları kaldıran : Nevzat PEKER 11/07/2023 -->
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getHourlyAnnualLeaveList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            pageLength: 10,
            dir: false,
            pageNo: 1,
            total: 0,
            columns: [{
                    label: this.$t('hourlyAnnualLeave.employee'),
                    field: `employee`,
                },
                {
                    label: this.$t('hourlyAnnualLeave.department'),
                    field: `department`,
                },
                {
                    label: this.$t('hourlyAnnualLeave.leave_date'),
                    field: `leaveDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('hourlyAnnualLeave.starting_time'),
                    field: `startingTime`,

                },
                {
                    label: this.$t('hourlyAnnualLeave.ending_time'),
                    field: `endingTime`,

                },
                {
                    label: this.$t('hourlyAnnualLeave.request_description'),
                    field: `requestDescription`,
                },
                {
                    label: this.$t('hourlyAnnualLeave.status'),
                    field: `annualLeaveStatus`,
                },
                {
                    label: this.$t('hourlyAnnualLeave.approving_manager'),
                    field: `approvingManager`,
                },
                {
                    label: this.$t('hourlyAnnualLeave.approve_description'),
                    field: `approveDescription`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '7em'
                },
            ],
            rows: [],
            searchTerm: '',
            isButtonDisabled: true,
            annualLeaveStatusSelect: 0,
            annualLeaveStatusOption: [],
            requestDateStart: null,
            requestDateEnd: null,
            employee: '',
        }
    },
    mounted() {
        this.getHourlyAnnualLeaveList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'HourlyAnnualLeave'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getHourlyAnnualLeaveList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getHourlyAnnualLeaveList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.employeeHourlyAnnualLeaveRequestFormModal.showInfo(id)
        },
        getHourlyAnnualLeaveList() {
            this.rows = []
            const controller = {
                name: 'HourlyAnnualLeave',
                actionName: 'GetHourlyAnnualLeaveList'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                employee: this.employee,
                annualLeaveStatusId: this.annualLeaveStatusSelect,
                annualLeaveTypeId: this.annualLeaveTypeSelect,
                requestDateStart: this.requestDateStart,
                requestDateEnd: this.requestDateEnd
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.annualLeaveStatusSelect = this.annualLeaveStatusSelect
                    this.annualLeaveStatusOption = response.data.annualLeaveStatusList
                    this.annualLeaveStatusOption.splice(0, 0, this.$nullSelected())
                    this.total = response.data.total
                    response.data.hourlyAnnualLeaveList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'HourlyAnnualLeave',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("AnnualLeaveDurumlari", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
