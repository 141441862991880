<template>
    <div>
      <!-- modal -->
      <b-modal id="accountingBooksReceiptStandartFormModal" centered size="lg" no-close-on-backdrop ref="accountingBooksReceiptStandartFormModal" :title=" $t('accountingBooksReceiptStandart.info')"
        :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" >
        <validation-observer ref="simpleRules">
        <b-row>
            <b-col class="col-md-6">
                <b-form>
                    <label for="accounting-books-receipt-standart-b-a-input">{{ $t('accountingBooksReceiptStandart.b_a') }}</label>
                    <b-form-group>
                    <validation-provider :name="$t('accountingBooksReceiptStandart.b_a')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                        <b-form-select size="sm" v-model="bASelect" value-field="id" text-field="name" :options="bAOption" />
                        <small class="text-danger">{{ errors[0]  }}</small>
                    </validation-provider></b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-6">
                <validation-provider :name=" $t('accountingBooksReceiptStandart.account_no')" #default="{ errors }" :rules="{ excluded: 0 }">
                    <b-form-group :label=" $t('accountingBooksReceiptStandart.account_no')" label-for="name" :state="errors.length > 0 ? false:null">
                        <v-select id="accountNo" v-model="accountSelect" :options="accountOption" @input="setAccountId" label="name" @search="onSearch"> </v-select>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null"> {{ errors[0] }} </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-6">
                <b-form>
                    <label for="accounting-books-receipt-standart-recordDate-input">{{ $t('accountingBooksReceiptStandart.record_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="1" v-model="recordDate"></CustomDatePicker>
                </b-form>
            </b-col>

            <b-col class="col-md-6">
                <b-form>
                    <label for="accounting-books-receipt-standart-documet-no-input">{{ $t('accountingBooksReceiptStandart.document_no') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="accounting-books-receipt-standart-document-no-input" v-model="reference" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-4">
                <b-form>
                    <label for="accounting-books-receipt-standart-currency-input">{{ $t('accountingBooksReceiptStandart.currency') }}</label>
                    <b-form-group>
                    <validation-provider :name="$t('accountingBooksReceiptStandart.currency')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                        <b-form-select size="sm" v-model="currencySelect" value-field="id" text-field="name" :options="currencyOption" />
                        <small class="text-danger">{{ errors[0]  }}</small>
                    </validation-provider></b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-4">
                <b-form>
                    <label for="accounting-books-receipt-standart-total-input">{{ $t('accountingBooksReceiptStandart.total') }}</label>
                    <cleave id="accounting-books-receipt-standart-total-input" v-model="total" @input="calculateRate()" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                </b-form>
            </b-col>
            <b-col class="col-md-4">
                <b-form>
                    <label for="accounting-books-receipt-standart-total-tl-input">{{ $t('accountingBooksReceiptStandart.total_tl') }}</label>
                    <cleave disabled id="accounting-books-receipt-standart-total-tl-input" v-model="totalTl" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-12">
                <b-form>
                    <label for="accounting-books-receipt-standart-description-input">{{ $t('accountingBooksReceiptStandart.description') }}</label>
                    <b-form-group>
                        <b-form-textarea id="accounting-books-receipt-standart-description-input" :placeholder=" $t('accountingBooksReceiptStandart.description')" rows="3" v-model="description" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getAccountingBooksList: {type: Function},
      getAccountingBooksReceiptList: {type: Function},
      getAccountingBooksReceiptStandartList :{type: Function},
    },
    data() {
      return {
        id: 0,
        accountId:0,
        accountingTransactionId:0,
        accountNo: '',
        accountName: '',
        reference: '',
        bASelect: 0,
        bAOption: [],
        currencySelect: 0,
        currencyOption: [],
        total: '',
        totalTl: '',
        recordDate: null,
        description: '',
        accountSelect: 0,
        accountOption: [],
        subsidiaryLedgerId:0,
        cleaveOptions: {
            prefix: '',
            numeral: true,
            numeralPositiveOnly: true,
            noImmediatePrefix: true,
            rawValueTrimPrefix: true,
            numeralIntegerScale: 9,
            numeralDecimalScale: 2
        },
      }
    },
    methods: {
        calculateRate(){
            const currency = this.currencyOption.find(item => item.id === this.currencySelect);
            const data = { requestDate: this.recordDate, currencyToSell: 'TL', currencyToBuy: currency.name, amount: this.total}
            const controller = { name: 'Currency', actionName: 'ExchangeCurrency'}
            const payload = { data: data, controller: controller}
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    if(this.bASelect == 1){
                        this.totalTl = (response.data.currencyToBuy.selling * this.total)
                    } else this.totalTl = (response.data.currencyToBuy.buying * this.total)
                }
            })
        },
        setAccountId(event) {
            if (event != null) {
                this.accountId = event.id
                this.currencySelect = event.currencyId
            } else {
                this.accountId = null;
                this.currencySelect = 0;
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = { id: 0, number: search, subsidiaryLedgerId: this.subsidiaryLedgerId}
                const controller = { name: 'Accounting', actionName: 'SearchParentAccounting'}
                const payload = { data: data, controller: controller }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.accountOption = response.data.accountingList
                    loading(false)
                })
            }
        },
      showInfo(id, subsidiaryLedgerId, accountingTransactionId) {  
        this.accountingTransactionId = accountingTransactionId
        this.subsidiaryLedgerId = subsidiaryLedgerId
        this.$refs['accountingBooksReceiptStandartFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = { name: 'AccountingTransactionDetail' }
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.reference = response.data.accountingTransactionDetail.reference
                this.bASelect = response.data.accountingTransactionDetail.accountingTransactionDetailTypeId
                this.bAOption = response.data.accountingTransactionDetailTypeList
                this.currencySelect = response.data.accountingTransactionDetail.currencyId
                this.currencyOption = response.data.currencyList
                this.total = response.data.accountingTransactionDetail.amount
                this.totalTl = response.data.accountingTransactionDetail.amountCv
                this.recordDate = response.data.accountingTransactionDetail.recordDate
                this.description = response.data.accountingTransactionDetail.description
                this.bAOption.splice(0, 0, this.$nullSelected())
                this.currencyOption.splice(0, 0, this.$nullSelected())
                if (response.data.accountingTransactionDetail.accountingId > 0) {
                        const accountingData = { id: response.data.accountingTransactionDetail.accountingId}
                        const accountingController = { name: 'Accounting', actionName: 'SearchParentAccounting' }
                        const accountingPayload = { data: accountingData, controller: accountingController }
                        this.$store.dispatch('moduleAuth/customService', accountingPayload).then((accountingResponse) => {
                            this.accountOption = accountingResponse.data.accountingList
                            this.accountSelect = accountingResponse.data.accountingList[0]
                            this.accountId = accountingResponse.data.accountingList[0].id
                        })
                    } else {
                        this.accountOption = []
                        this.accountSelect = 0;
                    }
            } else {
                this.$bvModal.hide('accountingBooksReceiptStandartFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        this.$refs.simpleRules.validate().then(success => {
            if (success) {
                const data = {id: this.id, accountingTransactionId:this.accountingTransactionId, accountingId: this.accountId, reference: this.reference, accountingTransactionDetailTypeId: this.bASelect, amount: this.total,
                    amountCv: this.totalTl, currencyId: this.currencySelect, description: this.description, exchangeDate: this.recordDate, recordDate: this.recordDate}
                const controller = {name : 'AccountingTransactionDetail'}
                const payload = {data: data, controller: controller}
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                    if(response.data.resultStatus == true){
                        this.$bvModal.hide('accountingBooksReceiptStandartFormModal')
                        this.getAccountingBooksReceiptList()
                        this.getAccountingBooksReceiptStandartList()
                        this.$SaveAlert()
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  .datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
.cleaveStyle {
    max-height: 3em!important;
    height: 2.2em!important;
  }
  </style>
  