<template>
<div>
    <translator-form :getTranslatorList="getTranslatorList" ref="translatorFormModal">
    </translator-form>
    <b-row>
        <b-col class="col-12">
            <div style="float:left;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-input size="sm" v-model="searchTerm" :placeholder="$t('others.search_name')" type="text" class="d-inline-block" />
                    </div>
                </b-form-group>
            </div>
            <div style="float:right;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-button style="float:right;" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('translator.translator_add') }}</b-button>
                        <b-button style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    </div>
                </b-form-group>
            </div>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="translatorTable" ref="translatorTable" :columns="columns" :rows="rows" :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }" styleClass="vgt-table condensed tableSizeTranslator" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('translator.name') }}
            </span>
            <span v-else-if="props.column.field === 'surname'" class="text-nowrap">
                {{ $t('translator.surname') }}
            </span>
            <span v-else-if="props.column.field === 'email'" class="text-nowrap">
                {{ $t('translator.email') }}
            </span>
            <span v-else-if="props.column.field === 'address'" class="text-nowrap">
                {{ $t('translator.address') }}
            </span>
            <span v-else-if="props.column.field === 'cityName'" class="text-nowrap">
                {{ $t('translator.city_name') }}
            </span>
            <span v-else-if="props.column.field === 'isInvoice'" class="text-nowrap">
                {{ $t('translator.is_invoice') }}
            </span>
            <span v-else-if="props.column.field === 'note'" class="text-nowrap">
                {{ $t('translator.note') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Translator Name -->
            <span v-if="props.column.field === 'name'" class="text-nowrap">
                <span>{{ props.row.name }}</span>
            </span>
            <span v-else-if="props.column.field === 'surname'" class="text-nowrap">
                <span>{{ props.row.surname }}</span>
            </span>
            <span v-else-if="props.column.field === 'address'" class="text-nowrap">
                <span>{{ props.row.address }}</span>
            </span>
            <span v-else-if="props.column.field === 'email'" class="text-nowrap">
                <span>{{ props.row.email }}</span>
            </span>
            <span v-else-if="props.column.field === 'cityName'" class="text-nowrap">
                <span>{{ props.row.cityName }}</span>
            </span>
            <span v-else-if="props.column.field === 'note'" class="text-nowrap">
                <span>{{ props.row.note }}</span>
            </span>
            <span v-if="props.column.field === 'isInvoice'">
                <b-form-checkbox disabled v-model="props.row.isInvoice" />
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }} </span>
                    <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            excelTrueOrFalse: null,
            columns: [{
                    label: this.$t('translator.name'),
                    field: `name`,
                },
                {
                    label: this.$t('translator.surname'),
                    field: `surname`,
                },
                {
                    label: this.$t('translator.email'),
                    field: `email`,
                },
                {
                    label: this.$t('translator.is_invoice'),
                    field: `isInvoice`,
                },
                {
                    label: this.$t('others.process'),
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getTranslatorList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'Translator'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getTranslatorList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getTranslatorList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.translatorFormModal.showInfo(id)
        },
        getTranslatorList() {
            this.rows = []
            const controller = {
                name: 'Translator'
            }
            const payload = {
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.translatorList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'Translator',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Translator", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
}

.tableSizeTranslator {
    font-size: 14px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.featherIconReSize {
    width: 12px;
    height: 12px;
}
</style>
