<template>
    <div>
      <translator-price-form :getTranslatorList="getTranslatorList" :translatorId="this.translatorId" :getTranslatorPriceList="getTranslatorPriceList" ref="translatorPriceFormModal">
                </translator-price-form>
      <b-row>
        <b-col class="col-12">
          <div style="float:right;" class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-button style="float:right;" size="sm" variant="outline-primary"
                  @click="getInfo()">{{ $t('translatorPrice.price_add') }}</b-button>
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table 
      id="translatorPriceTable"
      ref="translatorPriceTable" 
      :columns="columns" 
      :rows="rows"
      styleClass="vgt-table condensed tableSize"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
        theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'formulaPrice'" class="text-nowrap">
            {{ $t('translatorPrice.formula_price') }}
          </span>
          <span v-else-if="props.column.field === 'date'" class="text-nowrap">
            {{ $t('translatorPrice.date') }}
          </span>
          <span v-else-if="props.column.field === 'wordPrice'" class="text-nowrap">
            {{ $t('translatorPrice.word_price') }}
          </span>
          <span v-else-if="props.column.field === 'tablePrice'" class="text-nowrap">
            {{ $t('translatorPrice.table_price') }}
          </span>
          <span v-else-if="props.column.field === 'pagePrice'" class="text-nowrap">
            {{ $t('translatorPrice.page_price') }}
          </span>
          <span v-else-if="props.column.field === 'imagePrice'" class="text-nowrap">
            {{ $t('translatorPrice.image_price') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
              class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
              <feather-icon icon="EditIcon" />
            </b-button>
              <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      translatorId: Number,
      getTranslatorList: {type: Function},
    },
    data() {
      return {
        dir: false,
        columns: [
          {
            label: this.$t('translatorPrice.formula_price'),
            field: `formulaPrice`,
          },
          {
            label: this.$t('translatorPrice.image_price'),
            field: `imagePrice`,
          },
          {
            label: this.$t('translatorPrice.page_price'),
            field: `pagePrice`,
          },
          {
            label: this.$t('translatorPrice.table_price'),
            field: `tablePrice`,
          },
          {
            label: this.$t('translatorPrice.word_price'),
            field: `wordPrice`,
          },
          {
            label: this.$t('translatorPrice.date'),
            field: `date`,
            formatFn: this.$formatFn
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      this.getTranslatorPriceList()
    },
    methods: {
      async deleted(data) {
        data = { id: data.id }
        const controller = { name: 'TranslatorPrice' }
        const payload = { data: data, controller: controller }
        this.$DeleteConfirm().then(result => {
          if (result.value) {
            this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$DeleteAlert()
                this.getTranslatorPriceList()
                this.getTranslatorList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getTranslatorPriceList()
              }
            })
          }
        })
      },
      async getInfo(id) {
        this.$refs.translatorPriceFormModal.showInfo(id, this.translatorId)
      },
      getTranslatorPriceList() {
        this.rows = [] 
        const data = { id: this.translatorId }
        const controller = { name: 'TranslatorPrice', actionName:'GetTranslatorPriceListByTranslatorId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            response.data.translatorPriceList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
    },
  }
  </script>
  <style lang="scss" >
    .tableSize {
      font-size: 13px!important;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      table-layout: auto;
  }
  </style>
  