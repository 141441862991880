<template>
<div>
    <!-- modal -->
    <b-modal id="employmentContractTypeFormModal" centered size="lg" no-close-on-backdrop ref="employmentContractTypeFormModal" :title=" $t('employmentContractType.contract_type_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-form>
                <label for="contract-type-name-input">{{ $t('employmentContractType.contract_type_name') }}</label>
                <b-form-group>
                    <validation-provider #default="{ errors }" :name=" $t('employmentContractType.contract_type_name')" rules="required">
                        <b-form-input id="contract-type-name-input" v-model="name" size="sm" maxlength="50" :state="errors.length > 0 ? false:null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

  
<script>
export default {
    props: {
        getEmploymentContractTypeList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            name: '',
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['employmentContractTypeFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'EmploymentContractType'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.name = response.data.employmentContractType.name
                } else {
                    this.$bvModal.hide('employmentContractTypeFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.name
            }
            const controller = {
                name: 'EmploymentContractType'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('employmentContractTypeFormModal')
                            this.getEmploymentContractTypeList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
