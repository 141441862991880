<template>
<div>
    <domain-form :getDomainList="getDomainList" ref="domainFormModal"></domain-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="domain-record-no-input">{{ $t('domain.record_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="recordNo" v-on:keyup.enter="getDomainList()" :placeholder="$t('domain.record_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="domain-domain-name-input">{{ $t('domain.domain_name') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="name" v-on:keyup.enter="getDomainList()" :placeholder="$t('domain.domain_name')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="domain-agent-input">{{ $t('domain.agent') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="agent" v-on:keyup.enter="getDomainList()" :placeholder="$t('domain.agent')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="domain-owner-input">{{ $t('domain.owner') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="owner" v-on:keyup.enter="getDomainList()" :placeholder="$t('domain.owner')" type="text" class="d-inline-block" />
                </div>
            </b-col>

            <b-col class="col-md-4 mt-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-right">
                    <b-button class="d-inline-block mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getDomainList()" @click="getDomainList()">{{ $t('others.list') }}</b-button>
                    <b-button class="d-inline-block mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('domain.domain_add') }}</b-button>
                    <b-button class="d-inline-block " size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1 mt-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="domain-status-input">{{ $t('domain.status') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-select v-model="domainStatusSelect" @change="getDomainList()" v-on:keyup.enter="getDomainList()" size="sm" value-field="id" text-field="name" :options="domainStatusOption" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                  <label for="domain-renewal-date-start-input">{{ $t('domain.renewal_date_start') }}</label>
                                  <CustomDatePicker :datePickerValidateOption="0"  v-model="renewalDateStart"></CustomDatePicker>
                                </b-form-group>
                              </b-col>
                              <b-col class="col-3">
                                <b-form-group>
                                  <label for="domain-renewal-date-end-input">{{ $t('domain.renewal_date_end') }}</label>
                                  <CustomDatePicker :datePickerValidateOption="0" v-model="renewalDateEnd"></CustomDatePicker>
                                </b-form-group>
                              </b-col>
                              <b-col class="col-3">
                                <b-form-group>
                                  <label for="domain-register-date-start-input">{{ $t('domain.register_date_start') }}</label>
                                  <CustomDatePicker :datePickerValidateOption="0"  v-model="registerDateStart"></CustomDatePicker>
                                </b-form-group>
                              </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <b-form-group>
                                  <label for="domain-register-date-end-input">{{ $t('domain.register_date_end') }}</label>
                                  <CustomDatePicker :datePickerValidateOption="0" v-model="registerDateEnd"></CustomDatePicker>
                                </b-form-group>
                              </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                  <label for="domain-record-date-start-input">{{ $t('domain.record_date_start') }}</label>
                                  <CustomDatePicker :datePickerValidateOption="0" v-model="recordDateStart"></CustomDatePicker>
                                </b-form-group>
                              </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                  <label for="domain-record-date-end-input">{{ $t('domain.record_date_end') }}</label>
                                  <CustomDatePicker :datePickerValidateOption="0" v-model="recordDateEnd"></CustomDatePicker>
                                </b-form-group>
                              </b-col>
                            <b-col class="col-3">
                                <label for="domain-agent-reference-no-input">{{ $t('domain.agent_reference_no') }}</label>
                                <div class="d-flex align-items-center">
                                    <b-form-input size="sm" v-model="agentReferenceNo" v-on:keyup.enter="getDomainList()" :placeholder="$t('domain.agent_reference_no')" type="text" class="d-inline-block" />
                                </div>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse> 
            </b-col>
        </b-row>
    </div>    
    <!-- table -->
    <vue-good-table id="domainTable" ref="domainTable" :columns="columns" :rows="rows" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" styleClass="vgt-table condensed tableSize" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
          enabled: true,
          perPage: pageLength
        }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('domain.domain_ref_code') }}
            </span>
            <span v-else-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('domain.domain_name') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('domain.agent_name') }}
            </span>
            <span v-else-if="props.column.field === 'ownerList'" class="text-nowrap">
                {{ $t('domain.owners') }}
            </span>
            <span v-else-if="props.column.field === 'registerDate'" class="text-nowrap">
                {{ $t('domain.allocation_date') }}
            </span>
            <span v-else-if="props.column.field === 'renewalDate'" class="text-nowrap">
                {{ $t('domain.renewal_date') }}
            </span>
            <span v-else-if="props.column.field === 'domainStatusName'" class="text-nowrap">
                {{ $t('domain.status') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Agent Name -->
            <span v-if="props.column.field === 'agent'" class="text-nowrap">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.agent }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ props.row.clientAccounting }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'ownerList'">
                <b-col>
                    <b-row v-for="owner in props.row.ownerList" v-bind:data="owner" v-bind:key="owner.clientName" style="font-size: small;">
                        <tr v-if="props.row.ownerList.length != 1">
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                        <tr v-else-if="props.row.ownerList.length == 1">
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <!-- Column: Regrestration Date Format -->
            <span v-else-if="props.column.field === 'Allocation Date'" class="text-nowrap">
                <span>{{ props.row.registerDate }}</span>
            </span>
            <!-- Column: Regrestration Date Format -->
            <span v-else-if="props.column.field === 'Renewal Date'" class="text-nowrap">
                <span>{{ props.row.renewalDate }}</span>
            </span>
            <span v-else-if="props.column.field === 'domainStatusName'" class="text-nowrap">
                <span v-if="props.row.domainStatusName === 'aktif'">
                    <b-form-checkbox v-b-tooltip.hover title="Aktif" class="custom-control-success" v-model="selected" value="A" disabled />
                </span>
                <span v-else-if="props.row.domainStatusName === 'inaktif'">
                    <b-form-checkbox v-b-tooltip.hover title="In Aktif" v-model="selected" value="B" disabled />
                </span>
                <span v-else-if="props.row.domainStatusName === 'transOut'">
                    <b-form-checkbox v-b-tooltip.hover title="Trans Out" class="custom-control-warning" v-model="selected" value="A" disabled />
                </span>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getDomainList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
       data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            selected: ['A', 'C'],
            columns: [{
                    label: this.$t('domain.domain_ref_code'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('domain.domain_name'),
                    field: `name`,
                },
                {
                    label: this.$t('domain.agent_name'),
                    field: `agent`,
                },
                {
                    label: this.$t('domain.owners'),
                    field: `ownerList`,
                },
                {
                    label: this.$t('domain.allocation_date'),
                    field: `registerDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('domain.renewal_date'),
                    field: `renewalDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('domain.status'),
                    field: `domainStatusName`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            recordNo: '',
            agent: '',
            name: '',
            renewalDateStart: null,
            renewalDateEnd: null,
            registerDateStart: null,
            registerDateEnd: null,
            recordDateStart: null,
            recordDateEnd: null,
            domainStatusSelect: 0,
            domainStatusOption: [],
            owner: '',
            agentReferenceNo: '',
            selected: '',
        }
    },
    mounted() {
        this.getDomainList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'Domain'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getDomainList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getDomainList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.domainFormModal.showInfo(id)
        },
        getDomainList() {
            this.rows = []
            const controller = {
                name: 'Domain'
            }
            const data = {
                recordNo: this.recordNo,
                agent: this.agent,
                name: this.name,
                owner: this.owner,
                note: this.note,
                renewalDateStart: this.renewalDateStart,
                renewalDateEnd: this.renewalDateEnd,
                registerDateStart: this.registerDateStart,
                registerDateEnd: this.registerDateEnd,
                domainStatusId: this.domainStatusSelect,
                agentReferenceNo: this.agentReferenceNo,
                recordDateStart: this.recordDateStart,
                recordDateEnd: this.recordDateEnd,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    response.data.domainList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.domainStatusOption = response.data.domainStatusList
                    this.domainStatusOption.splice(0, 0, this.$nullSelected())

                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            var controller = {
                name: 'Domain'
            }
            var data = {
                recordNo: this.recordNo,
                agent: this.agent,
                name: this.name,
                owner: this.owner,
                note: this.note,
                renewalDateStart: this.renewalDateStart,
                renewalDateEnd: this.renewalDateEnd,
                registerDateStart: this.registerDateStart,
                registerDateEnd: this.registerDateEnd,
                domainStatusId: this.domainStatusSelect,
                agentReferenceNo: this.agentReferenceNo,
                recordDateStart: this.recordDateStart,
                recordDateEnd: this.recordDateEnd,
                pageLength: 1000,
                pageNo: 1,
            }
            var payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    controller = {
                        name: 'Domain',
                        actionName: 'CanExport'
                    }
                    data = {
                        columnList: this.columns,
                        DataList: response.data.domainList
                    }
                    payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$downloadFile("AlanAdi", response.data.base64, 'xlsx')
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
            
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.featherIconReSize {
    width: 12px;
    height: 12px;
}

.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
