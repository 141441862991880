<template>
<div>
    <b-modal id="mailSendFormModal" centered size="lg" no-close-on-backdrop ref="mailSendFormModal" :title=" $t('mailSendForm.title')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.send')" cancel-variant="outline-secondary" @ok.prevent="sendMail" @close="clear()" >
        <b-card style="height: 100%">
            <b-row>
                <b-col md="12">
                    <b-input-group size="sm" :prepend="this.$t('mailSendForm.from')">
                        <b-form-input v-model="from" placeholder="test@stock.com" />
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col md="4">
                    <b-input-group size="sm" :prepend="this.$t('mailSendForm.receiver')">
                        <b-form-input v-model="reciever" placeholder="test@stock.com" />
                    </b-input-group>
                </b-col>
                <b-col md="4">
                    <b-input-group size="sm" :prepend="this.$t('mailSendForm.bcc')">
                        <b-form-input v-model="bcc" placeholder="test@stock.com" />
                    </b-input-group>
                </b-col>
                <b-col md="4">
                    <b-input-group size="sm" :prepend="this.$t('mailSendForm.cc')">
                        <b-form-input v-model="cc" placeholder="test@stock.com" />
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col md="12">
                    <b-input-group size="sm" :prepend="this.$t('mailSendForm.subject')">
                        <b-form-input v-model="subject" :prepend="this.$t('mailSendForm.subject')" />
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col md="12">
                    <b-input-group size="sm" :prepend="this.$t('mailSendForm.adds')">
                        <b-form-input disabled v-model="adds" :prepend="this.$t('mailSendForm.adds')" />
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col md="12">
                    <label for="patent-history-time" class="messageEdit mb-05">{{ $t('mailSendForm.message') }}</label>
                    <Vueditor ref="editor"></Vueditor>
                </b-col>
            </b-row>
        </b-card>
        
    </b-modal>
</div>
</template>  
<script>
export default {
    data() {
        return {
            documentTypeDetailId: 0,
            mailAccountId: 0,
            moduleId: 0,
            bcc: '',
            cc: '',
            explanation: '',
            mailAccountName: '',
            subject: '',
            mailTemplate: [],
            from: '',
            reciever: '',
            body:'',
            adds: '',
            patentId: 0,
            id: 0,
            fileList: [],
            mailAccountId: 0
        }
    },
    methods: {
        clear() {
            this.from= ''
            this.reciever= ''
            this.bcc= ''
            this.cc= ''
            this.subject= ''
            this.adds= ''
            this.$refs.editor.setContent("")
        },
        formSubmitted() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Form Submitted',
                    icon: 'EditIcon',
                    variant: 'success',
                },
            })
        },
        async showInfo(mailContent) {  

            this.$refs['mailSendFormModal'].show()
            await new Promise(resolve => setTimeout(resolve, 100));
      
            if(mailContent.fileList != [] && mailContent.fileList){
                var addList = mailContent.fileList
                addList.forEach(element => {
                this.adds = element.fileName
                })
            }

            this.$refs.editor.setContent(mailContent.mailTemplate.body);      

                 
            this.documentTypeDetailId = mailContent.mailTemplate.documentTypeDetailId
            this.explanation = mailContent.mailTemplate.explanation
            this.mailAccountId = mailContent.mailTemplate.mailAccountId
            this.mailAccountName = mailContent.mailTemplate.mailAccountName
            this.moduleId = mailContent.mailTemplate.moduleId
            this.subject = mailContent.mailTemplate.subject
            this.from = mailContent.mailTemplate.mailAccountAddress
            this.patentId = mailContent.patentId
            this.id = mailContent.mailTemplate.id
            this.mailTemplate = mailContent.mailTemplate
            this.fileList = mailContent.fileList
            this.mailAccountId = mailContent.mailTemplate.mailAccountId

            if(mailContent.toMailList != [] && mailContent.toMailList){
                mailContent.toMailList.forEach(toMail => {
                    this.reciever +=   toMail + ';' 
                })
            }
            if(mailContent.toBccList != [] && mailContent.toBccList){
                mailContent.toBccList.forEach(toBcc => {
                   this.bcc += toBcc + ';'
                })
            }
            if(mailContent.toCcList != [] && mailContent.toCcList){
                mailContent.toCcList.forEach(toCc => {
                    this.cc += toCc + ';'
                })
            }
        },
        async sendMail() {
            const toMailList=[];
            const toBccList=[];
            const toCcList=[];          

            var mailList = this.reciever.split(";");

            mailList.forEach(mailAdress => {
                if(mailAdress.trim() != ''){
                    toMailList.push(mailAdress.trim())
                }
            })

            mailList = this.bcc.split(";");
            mailList.forEach(mailAdress => {
                if(mailAdress.trim() != ''){
                    toBccList.push(mailAdress.trim())
                }
            })

            mailList = this.cc.split(";");
            mailList.forEach(mailAdress => {
                if(mailAdress.trim() != ''){
                    toCcList.push(mailAdress.trim())
                }
            })

            this.mailTemplate.subject = this.subject
            this.mailTemplate.body = this.$refs.editor.getContent()


            const data = {
                employeeId: this.id,
                recordId: this.patentId,
                toBccList: toBccList,
                toCcList: toCcList,
                toMailList: toMailList,
                mailTemplate: this.mailTemplate,
                AttachmentList: this.fileList,
                mailAccountId: this.mailAccountId,
            }


            const controller = {
                name: 'MailTemplate',
                actionName: 'SendPreparedMailTemplate'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.clear();
                    this.$bvModal.hide('mailSendFormModal')
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

[dir=ltr] .input-group-prepend {
    margin-right: -1px;
    height: 2.1em;
}

.text-area {
    margin-right: -1px;
    height: 5em;
}

.vueditor {
    min-width: 350px !important;
    min-height: 25em !important;
    height: 95% !important;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
}

.dark-layout label {
    color: #b4b7bd;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bolder;
}

.messageEdit {
    padding: 0.438rem 1rem;
    margin-bottom: 0;
    text-align: center;
    background-color: transparent;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    font-weight: lighter !important;
}

[dir] .dark-layout .messageEdit:focus-within .messageEdit-text {
    border-color: #7367f0;
    box-shadow: none;
}

[dir] .messageEdit:not(.bootstrap-touchspin):focus-within .messageEdit-text {
    border-color: #7367f0;
    box-shadow: none;
}
</style>
