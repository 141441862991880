<template>
    <div>
      <!-- modal -->
      <b-modal
        id="patentBulletinAnalyseFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="patentBulletinAnalyseFormModal"
        :title=" $t('patentBulletinAnalyse.tpmk_patent_analyse_bulletin_info')"
        hide-footer
      >
      <p-d-f-viewer ref="bulletinInfoModal"></p-d-f-viewer>
      <b-row>
        <!--TPMK Patent--> 
        <b-col>
            <b-card :title=" $t('patentBulletinAnalyse.tpmk_analyse_bulletin_patent_title') ">
              <b-list-group>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletinAnalyse.tpmk_analyse_application_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAnalyseApplicationNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletinAnalyse.tpmk_analyse_application_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{this.$formatFn(tpmkAnalyseApplicationDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletinAnalyse.tpmk_analyse_register_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAnalyseRegisterNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletinAnalyse.tpmk_analyse_register_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{this.$formatFn(tpmkAnalyseRegisterDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletinAnalyse.tpmk_analyse_bulletin_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAnalyseBulletinNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('patentBulletinAnalyse.tpmk_analyse_bulletin_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{this.$formatFn(tpmkAnalyseBulletinDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6 mb-1">
                      <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                          <b-col>
                            <div class="d-flex w-100 justify-content-between">
                              <h6>
                                {{ $t('patentBulletinAnalyse.tpmk_analyse_publication_no') }}
                              </h6>
                            </div>
                          </b-col>
                          <b-col>
                            <b-card-text>
                              {{tpmkAnalysePublicationNo}}
                            </b-card-text>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-col>
                    <b-col class="col-md-6 mb-1">
                      <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                          <b-col>
                            <div class="d-flex w-100 justify-content-between">
                              <h6>
                                {{ $t('patentBulletinAnalyse.tpmk_analyse_publication_type') }}
                              </h6>
                            </div>
                          </b-col>
                          <b-col>
                            <b-card-text>
                              {{tpmkAnalysePublicationType}}
                            </b-card-text>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="col-md-6 mb-1">
                      <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                          <b-col>
                            <div class="d-flex w-100 justify-content-between">
                              <h6>
                                {{ $t('patentBulletinAnalyse.tpmk_analyse_patent_type') }}
                              </h6>
                            </div>
                          </b-col>
                          <b-col>
                            <b-card-text>
                              {{tpmkAnalysePatentType}}
                            </b-card-text>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-col>
                    <b-col class="col-md-6 mb-1">
                      <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                          <b-col>
                            <div class="d-flex w-100 justify-content-between">
                              <h6>
                                {{ $t('patentBulletinAnalyse.tpmk_analyse_ipc_code') }}
                              </h6>
                            </div>
                          </b-col>
                          <b-col>
                            <b-card-text>
                              {{tpmkAnalyseIpcCode}}
                            </b-card-text>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="col-md-12 mb-1">
                      <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                          <b-col>
                            <div class="d-flex w-100 justify-content-between">
                              <h6>
                                {{ $t('patentBulletinAnalyse.tpmk_analyse_title') }}
                              </h6>
                            </div>
                          </b-col>
                          <b-col>
                            <b-card-text>
                              {{tpmkAnalyseTitle}}
                            </b-card-text>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="col-md-12 mb-1">
                      <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                          <b-col>
                            <div class="d-flex w-100 justify-content-between">
                              <h6>
                                {{ $t('patentBulletinAnalyse.tpmk_analyse_attorney') }}
                              </h6>
                            </div>
                          </b-col>
                          <b-col>
                            <b-card-text>
                              {{tpmkAnalyseAttorney}}
                            </b-card-text>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-col>
                  </b-row>
                <b-row>
                  <b-col class="col-md-12">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <h6>
                            {{ $t('patentBulletinAnalyse.tpmk_analyse_abstract') }}
                          </h6>
                          <b-card-text>
                            {{tpmkAnalyseAbstract}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
              </b-list-group>
            </b-card>
        </b-col>
      </b-row>
      <!--TPMK Patent Holder--> 
      <b-row>
        <b-col class="col-md-12 mb-1">
          <b-card :title=" $t('patentBulletinAnalyse.tpmk_analyse_bulletin_patent_holder_title') ">
            <b-list-group v-for="(nameAndAddress, index) in tpmkPatentPatentOwnerNameAndAddress" :key="nameAndAddress.id">
              <b-list-group-item class="flex-column align-items-start">
                <b-row>
                  <b-col>
                    <b-card-text>
                      {{ nameAndAddress.name }} {{ ',' }}
                      <!-- <br> -->
                      {{ nameAndAddress.address }} {{ ',' }}
                      <!-- <br> -->
                      {{ nameAndAddress.state }} {{ '/' }} {{ nameAndAddress.cityName }} {{ '/' }} {{ nameAndAddress.countryName }}
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
      <!--TPMK Patent Inventor--> 
      <b-row>
        <b-col class="col-md-12 mb-1">
          <b-card :title=" $t('patentBulletinAnalyse.tpmk_analyse_bulletin_patent_inventor_title') ">
            <b-list-group v-for="(nameAndAddress, index) in tpmkPatentPatentInventorNameAndAddress" :key="nameAndAddress.id">
              <b-list-group-item class="flex-column align-items-start">
                <b-row>
                  <b-col>
                    <b-card-text>
                      {{ nameAndAddress.name }}
                      <br>
                      {{ nameAndAddress.address }}
                    </b-card-text>
                  </b-col>
                </b-row>                
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
      <!--TPMK Patent Priority--> 
      <b-row v-if="tpmkPatentPatentPriorityNameAndAddress.length != 0">
        <b-col>
            <b-card :title=" $t('patentBulletinAnalyse.tpmk_analyse_bulletin_patent_priority_title') ">
                <b-list-group v-for="(nameAndLocarno, index) in tpmkPatentPatentPriorityNameAndAddress" :key="nameAndLocarno.id">
                     <b-list-group-item class="flex-column align-items-start">
                         <b-col>
                            <b-card-text>
                                {{nameAndLocarno.no + ' - ' + $formatFn(nameAndLocarno.date) + ' - ' + nameAndLocarno.countryName}}
                            </b-card-text>
                         </b-col>
                      </b-list-group-item>
                </b-list-group>
            </b-card>
        </b-col>
      </b-row>
      <!--TPMK Patent History--> 
      <b-row>
        <b-col>
            <b-card :title=" $t('patentBulletinAnalyse.tpmk_analyse_bulletin_patent_history_title') ">
              <b-list-group v-for="(nameAndAddress, index) in tpmkPatentHistoryNameAndAddress" :key="nameAndAddress.id">
                <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <span>
                            {{ nameAndAddress.bulletinNo + '  ' + nameAndAddress.designPublication + ' - '}}
                            <b-button style="float: right;" variant="outline-warning" size="sm" class="btn-icon ml-1" @click="openBulletin(nameAndAddress.bulletinNo, nameAndAddress.bulletinPage)">
                                <feather-icon icon="BoldIcon" />
                            </b-button>
                          </span>
                        </b-col>
                      </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
        </b-col>
      </b-row>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getPatentBulletinList: {type: Function},
    },
    data() {
      return {
        id: 0,
        tpmkAnalyseApplicationNo: '',
        tpmkAnalyseApplicationDate: null,
        tpmkAnalyseRegisterNo: '',
        tpmkAnalyseRegisterDate: null,
        tpmkAnalyseBulletinNo: null,
        tpmkAnalyseBulletinPage: 0,
        tpmkAnalyseBulletinDate: 0,
        bulletinType: 2,
        tpmkAnalyseAttorney: '',
        tpmkAnalysePublicationNo: 0,
        tpmkAnalysePublicationType: null,
        tpmkAnalysePatentType: null,
        tpmkAnalyseTitle: '',
        tpmkAnalyseAbstract: '',
        tpmkAnalyseIpcCode: '',
        tpmkPatentHistoryNameAndAddress: [],
        tpmkPatentPatentOwnerNameAndAddress: [],
        tpmkPatentPatentInventorNameAndAddress: [],
        tpmkPatentPatentPriorityNameAndAddress: [],
        searchTerm: '',
      }
    },
    methods: {
        async openBulletin(bulletinNo, bulletinPage){
            this.$refs.bulletinInfoModal.showInfo(bulletinNo, bulletinPage, this.bulletinType)
        },
      showInfo(id) {  
        this.$refs['patentBulletinAnalyseFormModal'].show()
        this.id = id
        const data = { id: this.id }
        const controller = { name: 'Bulletin', actionName: 'GetPatentBulletinAnalyseInfo' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
                  //Patent
                  this.tpmkAnalyseApplicationNo = response.data.tpmkPatent.applicationNo
                  this.tpmkAnalyseApplicationDate = response.data.tpmkPatent.applicationDate
                  this.tpmkAnalyseRegisterNo = response.data.tpmkPatent.registerNo
                  this.tpmkAnalyseRegisterDate = response.data.tpmkPatent.registerDate
                  this.tpmkAnalyseBulletinNo = response.data.tpmkPatent.bulletinNo
                  this.tpmkAnalyseBulletinPage = response.data.tpmkPatent.bulletinPage
                  this.tpmkAnalyseBulletinDate = response.data.tpmkPatent.bulletinDate
                  this.tpmkAnalyseAttorney = response.data.tpmkPatent.attorney

                  this.tpmkAnalysePublicationNo = response.data.tpmkPatent.publicationNo
                  this.tpmkAnalysePublicationType = response.data.tpmkPatent.patentPublicationType
                  this.tpmkAnalysePatentType = response.data.tpmkPatent.patentType
                  this.tpmkAnalyseTitle = response.data.tpmkPatent.title
                  this.tpmkAnalyseAbstract = response.data.tpmkPatent.abstract
                  this.tpmkAnalyseIpcCode = response.data.tpmkPatent.ipcCode

                  //Patent History
                  this.tpmkPatentHistoryNameAndAddress = response.data.tpmkPatentHistoryList

                  //Patent Holder
                  this.tpmkPatentPatentOwnerNameAndAddress = response.data.tpmkPatentHolderList

                  //Patent Inventor
                  this.tpmkPatentPatentInventorNameAndAddress = response.data.tpmkPatentInventorList

                  //Patent Priority
                  this.tpmkPatentPatentPriorityNameAndAddress = response.data.tpmkPatentPriorityList
        }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
.tableSize {
  font-size: 13px!important;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}
  </style>
  