<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
      <b-link class="brand-logo">
        <img src="@/assets/images/stock-logo.png" width="155" alt="Stock Yazılım" style="max-width: 100%; vertical-align: middle; line-height: 100%; border: 0;">
      </b-link>
        <!-- form -->
                <validation-observer 
        ref="simpleRules"
        #default="{invalid}">
                  <b-form-group > 
            <label for="myaccount-new-password">{{ $t('myAccount.new_password_value') }}</label>
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required||min:3"
            >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="myaccount-new-password"
                v-model="newPasswordValue"
                name="new-password"
                :type="passwordFieldTypeNew"
                :placeholder=" $t('myAccount.new_password_value')"
                maxlength="50"
                :state="errors.length > 0 ? false:null"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click.prevent="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
                    <b-form-group>
            <label for="myaccount-new-password">{{ $t('myAccount.retype_password') }}</label>
            <validation-provider
              #default="{ errors }"
              name="Password Confirm"
              rules="required|confirmed:Password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="c-password"
                  v-model="RetypePassword"
                  :type="passwordFieldTypeRetype"
                  name="retype-password"
                  :placeholder=" $t('myAccount.retype_password')"
                  maxlength="50"
                  :state="errors.length > 0 ? false:null"
                />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click.prevent="togglePasswordRetype"
                    />
                  </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        <b-button
            variant="primary"
            class="mt-1 mr-1"
            block
             :disabled="invalid"
            @click.preivent="changePassword"
          >
            {{ $t('myAccount.submit') }}
          </b-button>
        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> {{ $t('login.turn_on_login') }}
          </b-link>
        </b-card-text>
                        </validation-observer> 
      </b-card>
    <!-- /Forgot Password v1 -->

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      cipheredUserId: this.$route.query.Id
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    changePassword(){
          const data = {cipheredUserId: this.cipheredUserId , newPassword : this.newPasswordValue}
          const controller = {name : 'Employee', actionName:'ChangeForgottenPassword'}
          const payload = {data: data, controller: controller}
          this.$store.dispatch('moduleAuth/customService',payload).then((response) => {
          if(response.data.resultStatus == true){ 
          this.$ChangePasswordAlert()
          this.$router.push({ name: 'login' })
          }
          else{
          this.$WarningAlert(response.data.resultMessage)
          }
          })
     },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
