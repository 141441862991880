<template>
    <div>
      <!-- modal -->
      <b-modal
        id="uploadFromEtebsFormModal" centered size="lg" no-close-on-backdrop ref="uploadFromEtebsFormModal" :title=" $t('etebs.upload_from_etebs')" :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" >
        <validation-observer ref="simpleRules">
        <b-row>
            <b-col class="col-lg-12">
                <label for="etebs-password-input">{{ $t('etebs.password') }}</label>
                <b-form-group>
                    <validation-provider #default="{ errors }" :name=" $t('etebs.password')" rules="required">
                        <b-form-input size="sm" id="etebs-password-input" v-model="password" maxlength="50" :state="errors.length > 0 ? false:null"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
              </b-form-group>
            </b-col>
        </b-row>
      </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
        getEtebsList: {type: Function},
    },
    data() {
      return {
        id: 0,
        userName: '',
        password: ''
      }
    },
    methods: {
      showInfo() {  
        this.$refs['uploadFromEtebsFormModal'].show()
        this.password = ""
      },
      addOrUpdate() {
        const data = {string: this.password}        
        const controller = {name : 'Etebs', actionName: 'GetEtebsListFromApi'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            let loader = this.$loading.show()
                this.$store.dispatch('moduleAuth/customService',payload).then((response) => {
                  
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('uploadFromEtebsFormModal')
                    this.getEtebsList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
                  loader.hide()
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  