<template>
    <div>
        <!-- modal -->
        <b-modal id="nationalPatentAnnuityFormModal" centered size="lg" no-close-on-backdrop :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')"
            cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" ref="nationalPatentAnnuityFormModal" :title="$t('nationalPatentAnnuity.national_patent_annuity_info')" @close="clear()">
            <validation-observer ref="infoRules" tag="form">
                <b-row>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <validation-provider #default="{ errors }" :name="$t('nationalPatentAnnuity.year')" :rules="{ excluded: 0 }">
                                <label for="national-patent-annuity-annuity-year-input">{{ $t('nationalPatentAnnuity.year') }}</label>
                                <b-form-select v-model="yearSelect" value-field="id" text-field="name" size="sm" :options="yearOption" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                            <label for="national-patent-annuity-date-input">{{ $t('nationalPatentAnnuity.date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="date"></CustomDatePicker>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <label for="national-patent-annuity-payment-date-input">{{ $t('nationalPatentAnnuity.payment_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="paymentDate"></CustomDatePicker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6">
                        <b-form>
                            <validation-provider :name="$t('nationalPatentAnnuity.national_patent_annuity_will_pay')" #default="{ errors }" :rules="{ excluded: 0 }">
                                <label for="national-patent-language-input">{{ $t('nationalPatentAnnuity.national_patent_annuity_will_pay') }}</label>
                                <b-form-select v-model="nationalPatentAnnuityWillPaySelect" value-field="id" text-field="name" size="sm" :options="nationalPatentAnnuityWillPayOption" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-6">
                        <b-form>
                            <label for="national-patent-language-input">{{ $t('nationalPatentAnnuity.national_patent_annuity_status') }}</label>
                            <b-form-select v-model="nationalPatentAnnuityStatusSelect" value-field="id" size="sm" text-field="name" :options="nationalPatentAnnuityStatusOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12 mt-1">
                        <label for="textarea-default">{{ $t('nationalPatentAnnuity.explanation') }}</label>
                            <b-form-textarea id="textarea-default" :placeholder="$t('nationalPatentAnnuity.explanation')"
                                v-model="explanation" rows="6" maxlength="150" />
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
    </template>
    
    <script>    
    export default {
        props: {
            getNationalPatentAnnuityList: {
                type: Function
            },
            getNationalPatentList: {
                type: Function
            },
        },
        data() {
            return {
                id: 0,
                nationalPatentId: 0,
                yearOption: [],
                yearSelect: 0,
                nationalPatentAnnuityWillPayOption: [],
                nationalPatentAnnuityWillPaySelect: 0,
                nationalPatentAnnuityStatusSelect: 0,
                nationalPatentAnnuityStatusOption: [],
                paymentDate: null,
                date: null,
                explanation: '',
            }
        },
        methods: {
            showInfo(id, nationalPatentId) {
                this.$refs['nationalPatentAnnuityFormModal'].show()
                this.id = id
                this.nationalPatentId = nationalPatentId
                const data = {
                    id: this.id,
                    nationalPatentId: this.nationalPatentId
                }
                const controller = {
                    name: 'NationalPatentAnnuity'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.yearSelect = response.data.nationalPatentAnnuity.year
                        this.yearOption = response.data.nationalPatentAnnuityYearList
                        this.nationalPatentAnnuityWillPaySelect = response.data.nationalPatentAnnuity.patentAnnuityWillPayId
                        this.nationalPatentAnnuityWillPayOption = response.data.patentAnnuityWillPayList
                        this.date = response.data.nationalPatentAnnuity.date
                        this.nationalPatentAnnuityStatusSelect = response.data.nationalPatentAnnuity.patentAnnuityStatusId
                        this.nationalPatentAnnuityStatusOption = response.data.patentAnnuityStatusList
                        this.paymentDate = response.data.nationalPatentAnnuity.paymentDate
                        this.explanation = response.data.nationalPatentAnnuity.explanation
                        this.nationalPatentAnnuityWillPayOption.splice(0, 0, this.$nullSelected())
                        this.yearOption.splice(0, 0, this.$nullSelected())
                        this.nationalPatentAnnuityStatusOption.splice(0, 0, this.$nullSelected('name'))
                    } else {
                        this.$bvModal.hide('nationalPatentAnnuityFormModal')
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            clear() {
                this.translatorSelect = 0
                this.languageSelect = 0
            },
            addOrUpdate() {
                const data = { id: this.id, nationalPatentId: this.nationalPatentId, year: this.yearSelect, patentAnnuityWillPayId: this.nationalPatentAnnuityWillPaySelect,
                    date: this.date, patentAnnuityStatusId: this.nationalPatentAnnuityStatusSelect, paymentDate: this.paymentDate, explanation: this.explanation}
                const controller = {
                    name: 'NationalPatentAnnuity'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$refs.infoRules.validate().then(success => {
                    if (success) {
                        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$bvModal.hide('nationalPatentAnnuityFormModal')
                                this.getNationalPatentAnnuityList()
                                this.$SaveAlert()
                                this.getNationalPatentList()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                })
            },
        },
    }
    </script>
    
    <style lang="scss">
    [dir=ltr] .modal-header .close {
        margin: -0.4rem -0.4rem -0.8rem auto;
    }
    
    [dir] label {
        margin-bottom: 0.2857rem;
        font-weight: bold;
    }
    
    .modal .modal-content {
        overflow: visible;
        margin-bottom: auto;
    }
    
    [dir] .invalid-tooltip {
        padding: 0.4rem 0.775rem !important;
        margin-top: -1.5rem !important;
        border-radius: 0.358rem !important;
        left: 20px !important
    }
    
    .customList {
        border: 1px solid #999;
        padding: 20px;
    }
    .datepickerStyle{
        max-height: 2.2em;
        font-family: fangsong;
    }
    </style>
    