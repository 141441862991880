var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"jobStatusRelationFormModal",attrs:{"id":"jobStatusRelationFormModal","centered":"","size":"xl","no-close-on-backdrop":"","title":_vm.$t('jobStatusRelation.job_status_relation_info'),"hide-footer":""}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_vm._v(" "+_vm._s(_vm.$t('others.ok_title'))+" ")])],1)],1)],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"col-md-6"},[_c('label',{attrs:{"for":"job-status-relation-job-status-input"}},[_vm._v(_vm._s(_vm.$t('jobStatusRelation.job_status')))]),_c('b-form-group',[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('jobStatusRelation.job_status'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","value-field":"id","text-field":"name","options":_vm.jobStatusOption},model:{value:(_vm.jobStatusSelect),callback:function ($$v) {_vm.jobStatusSelect=$$v},expression:"jobStatusSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-md-4"},[_c('label',{attrs:{"for":"job-status-relation-job-reminder-criterion-input"}},[_vm._v(_vm._s(_vm.$t('jobStatusRelation.job_reminder_criterion')))]),_c('b-form-group',[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('jobStatusRelation.job_reminder_criterion'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","value-field":"id","text-field":"name","options":_vm.jobReminderCriterionOption},model:{value:(_vm.jobReminderCriterionSelect),callback:function ($$v) {_vm.jobReminderCriterionSelect=$$v},expression:"jobReminderCriterionSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-md-2"},[_c('label',{attrs:{"for":"job-status-relation-isFirstStep-input"}},[_vm._v(_vm._s(_vm.$t('jobStatusRelation.is_first_step')))]),_c('br'),_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"name":"job-status-relation-isFirstStep-input","id":"job-status-relation-isFirstStep-input","switch":""},model:{value:(_vm.isFirstStep),callback:function ($$v) {_vm.isFirstStep=$$v},expression:"isFirstStep"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)])],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-md-3"},[_c('label',{attrs:{"for":"job-status-relation-next-job-reminder-time-type-input"}},[_vm._v(_vm._s(_vm.$t('jobStatusRelation.next_job_reminder_time_type')))]),_c('b-form-group',[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('jobStatusRelation.next_job_reminder_time_type'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","value-field":"id","text-field":"name","options":_vm.nextJobReminderTimeTypeOption},model:{value:(_vm.nextJobReminderTimeTypeSelect),callback:function ($$v) {_vm.nextJobReminderTimeTypeSelect=$$v},expression:"nextJobReminderTimeTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-md-3"},[_c('label',{attrs:{"for":"job-status-relation-next-job-reminder-input"}},[_vm._v(_vm._s(_vm.$t('jobStatusRelation.next_job_reminder_value')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('jobStatusRelation.next_job_reminder_value'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"job-status-relation-next-job-reminder-input","type":"number","size":"sm"},model:{value:(_vm.nextJobReminderValue),callback:function ($$v) {_vm.nextJobReminderValue=$$v},expression:"nextJobReminderValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-md-3"},[_c('label',{attrs:{"for":"job-status-relation-tpmk-reminder-time-type-input"}},[_vm._v(_vm._s(_vm.$t('jobStatusRelation.tpmk_reminder_time_type')))]),_c('b-form-group',[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('jobStatusRelation.tpmk_reminder_time_type'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","value-field":"id","text-field":"name","options":_vm.tPMKReminderTimeTypeOption},model:{value:(_vm.tPMKReminderTimeTypeSelect),callback:function ($$v) {_vm.tPMKReminderTimeTypeSelect=$$v},expression:"tPMKReminderTimeTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-md-3"},[_c('label',{attrs:{"for":"job-status-relation-tpmk-reminder-input"}},[_vm._v(_vm._s(_vm.$t('jobStatusRelation.tpmk_reminder_value')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('jobStatusRelation.tpmk_reminder_value'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"job-status-relation-tpmk-reminder-input","type":"number","size":"sm"},model:{value:(_vm.tpmkReminderValue),callback:function ($$v) {_vm.tpmkReminderValue=$$v},expression:"tpmkReminderValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-card',{attrs:{"hidden":_vm.hiddenControl}},[_c('b-row',[_c('b-col',[_c('b-tabs',{attrs:{"pills":"","vertical":"","nav-wrapper-class":"nav-vertical"}},[_c('b-tab',{staticStyle:{"width":"100%"},attrs:{"title":_vm.$t('jobStatusRelation.job_status_relation_detail')}},[_c('b-card-text',[_c('job-status-relation-detail-list',{attrs:{"jobStatusRelationId":this.id}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }