<template>
    <b-modal
      id="employeePriceFormModal"
      centered
      size="lg"
      no-close-on-backdrop
      ref="employeePriceFormModal"
      :title=" $t('employee.employee_price_info')"
      :cancel-title=" $t('others.cancel_title')"
      :ok-title=" $t('others.ok_title')"
      cancel-variant="outline-secondary"
      @ok.prevent="addOrUpdate"
    >
        <validation-observer ref="simpleRules">
            <b-form>
              <b-row class="mb-3">
                <b-col>
                  <validation-provider
                  #default="{ errors }"
                  :name=" $t('employeePrice.price')"
                  rules="required"
                >
                <label for="employeeprice-price-input">{{ $t('employeePrice.price') }}</label>
                  <b-form-group>
                    <b-form-input
                        id="employeeprice-name-input"
                        v-model="employeePrice"
                        maxlength="50"
                        size="sm"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  :name=" $t('employeePrice.currency')"
                  :rules="{ excluded:0}" 
                  v-slot="{ errors }"
                >
                <label for="employeeprice-currency-input">{{ $t('employeePrice.currency') }}</label>
                    <b-input-group>
                    <b-form-select
                        :state="errors.length > 0 ? false:null"
                        v-model="employeePriceSelect"
                        value-field="id"
                        text-field="name"
                        size="sm"
                        :options="employeePriceOption"/> 
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small> 
                </validation-provider>
                </b-col>
              </b-row>
            </b-form>
        </validation-observer>  
      </b-modal>
</template>
<script>
export default {
  props: {
    getEmployeePriceList: {type: Function}
  },
  data() {
    return {
      id: 0,
      employeePrice: null,
      employeePriceSelect: 0,
      employeePriceOption: [],
      employeeId:0
    }
  },
  methods: {
    showInfo(id, employeeId){
      this.$refs['employeePriceFormModal'].show()
      this.id = id
      this.employeeId = employeeId
      const data = {id: this.id}
      const controller = {name : 'EmployeePrice'}
      const payload = {data: data, controller: controller}
      this.$store.dispatch('moduleAuth/getInfo',payload).then((response) => {
        if(response.data.resultStatus == true){
            this.employeePriceOption = response.data.currencyList
            this.employeePrice = response.data.employeePrice.price
            this.employeePriceSelect = response.data.employeePrice.currencyId
            this.employeePriceOption.splice(0, 0, this.$nullSelected("name"))
            this.getEmployeePriceList();
           } else {
            this.$bvModal.hide('employeePriceFormModal')
            this.$WarningAlert(response.data.resultMessage)
           }  
       }) 
    },
    addOrUpdate() {
      const data = {id: this.id,employeeId: this.employeeId , currencyId: this.employeePriceSelect, price : this.employeePrice}
      const controller = {name : 'EmployeePrice'}
      const payload = {data: data, controller: controller}
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
              this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                if(response.data.resultStatus == true){
                  this.$bvModal.hide('employeePriceFormModal')
                  this.getEmployeePriceList();
                  this.$SaveAlert()                  
                } else {
                  this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
      })
    },
  }
}
</script>
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}
[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}
.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}
[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem!important;
    margin-top: -1.5rem!important;
    background-color: #ea5455!important;
    border-radius: 0.358rem!important;
    left: 20px!important
}
.tableSize {
  font-size: 13px!important;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}
</style>