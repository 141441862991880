<template>
<div>
    <agent-note-form ref="agentNoteFormModal"></agent-note-form>
    <client-form ref="clientFormModal"></client-form>
    <!-- modal -->
    <b-modal id="translatorFormModal" centered size="xl" no-close-on-backdrop ref="translatorFormModal" :title=" $t('translator.translator_info')" hide-footer>
        <div class="custom-search d-flex justify-content-end">
            <b-row>
                <b-col>
                    <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="addOrUpdate">
                        {{$t('others.ok_title')}}
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-3 mt-1">
                    <label for="translator-name-input">{{ $t('translator.name') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('translator.name')" rules="required">
                            <b-form-input size="sm" id="translator-name-input" v-model="name" maxlength="50" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-3 mt-1">
                    <label for="translator-surname-input">{{ $t('translator.surname') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('translator.surname')" rules="required">
                            <b-form-input size="sm" id="translator-surname-input" v-model="surname" maxlength="50" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label for="translator-email-input">{{ $t('translator.email') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('translator.email')" rules="required|email">
                            <b-form-input size="sm" id="translator-email-input" v-model="email" placeholder="test@stock.com" maxlength="50" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label for="translator-cityName-input">{{ $t('translator.city_name') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('translator.city_name')" rules="required">
                            <b-form-input size="sm" id="translator-cityName-input" v-model="cityName" maxlength="50" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label for="translator-isInvoice-input">{{ $t('translator.is_invoice') }}</label>
                    <br>
                    <b-form-checkbox class="custom-control-success" name="is-invoice-input" id="is-invoice-input" v-model="isInvoice" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-6 mb-1">
                    <label for="translator-address-input">{{ $t('translator.address') }}</label>
                    <validation-provider #default="{ errors }" :name="$t('translator.address')"
                        rules="required">
                        <b-form-textarea size="sm" id="textarea-default" :placeholder="$t('translator.address')"
                         v-model="address" rows="4" maxlength="250" :state="errors.length > 0 ? false : null"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
                <b-col class="col-md-6">
                    <label for="textarea-default">{{ $t('translator.note') }}</label>
                    <validation-provider #default="{ errors }" :name="$t('translator.note')"
                        rules="required">
                        <b-form-textarea size="sm" id="textarea-default" :placeholder=" $t('translator.note')" 
                        v-model="note" rows="4" maxlength="250" :state="errors.length > 0 ? false : null"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
        </validation-observer>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab active :title=" $t('translator.price')" style="width:100%;">
                            <b-card-text>
                                <translator-price-list :getTranslatorList="getTranslatorList" :translatorId="this.id"></translator-price-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('translator.contact_information')" style="width:100%;">
                            <b-card-text>
                                <translator-contact-information-list :getTranslatorList="getTranslatorList" :translatorId="this.id"></translator-contact-information-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        translatorForm: Object,
        getTranslatorList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            note: '',
            name: '',
            surname: '',
            email: '',
            address: '',
            cityName: '',
            isInvoice: false,
            hiddenControl: true,
        }
    },
    methods: {
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        showInfo(id) {
            this.$refs['translatorFormModal'].show() 
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Translator'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.name = response.data.translator.name,
                        this.surname = response.data.translator.surname,
                        this.address = response.data.translator.address,
                        this.cityName = response.data.translator.cityName,
                        this.note = response.data.translator.note,
                        this.isInvoice = response.data.translator.isInvoice
                    this.email = response.data.translator.email
                } else {
                    this.$bvModal.hide('translatorFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.name,
                surname: this.surname,
                note: this.note,
                address: this.address,
                cityName: this.cityName,
                isInvoice: this.isInvoice,
                email: this.email
            }
            const controller = {
                name: 'Translator'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getTranslatorList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.translatorId
                                this.hiddenControl = false
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] body.dark-layout .b-calendar-grid.form-control .small {
    background: #161d31;
    display: none !important;
}

.b-calendar .b-calendar-grid-help {
    color: #6e6b7b !important;
    display: none;
}
</style>
