var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"agentAnnuityPriceDetailFormModal",attrs:{"id":"agentAnnuityPriceDetailFormModal","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('agentAnnuityPriceDetail.info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{staticClass:"col-lg-12"},[_c('b-form',[_c('label',{attrs:{"for":"agent-annuity-detail-stock-fee-input"}},[_vm._v(_vm._s(_vm.$t('agentAnnuityPriceDetail.stock_fee')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('agentAnnuityPriceDetail.stock_fee'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"agent-annuity-detail-stock-fee-input","size":"sm","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.stockFee),callback:function ($$v) {_vm.stockFee=$$v},expression:"stockFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('label',{attrs:{"for":"agent-annuity-detail-tpmk-fee-input"}},[_vm._v(_vm._s(_vm.$t('agentAnnuityPriceDetail.tpmk_fee')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('agentAnnuityPriceDetail.tpmk_fee'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"agent-annuity-detail-tpmk-fee-input","size":"sm","disabled":"","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.tpmkFee),callback:function ($$v) {_vm.tpmkFee=$$v},expression:"tpmkFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('label',{attrs:{"for":"agent-annuity-detail-official-fee-input"}},[_vm._v(_vm._s(_vm.$t('agentAnnuityPriceDetail.official_fee')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('agentAnnuityPriceDetail.official_fee'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"agent-annuity-detail-official-fee-input","size":"sm","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.officialFee),callback:function ($$v) {_vm.officialFee=$$v},expression:"officialFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('label',{attrs:{"for":"agent-annuity-detail-tpmk-fee-input"}},[_vm._v(_vm._s(_vm.$t('agentAnnuityPriceDetail.tpmk_fine_fee')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('agentAnnuityPrice.tpmk_fine_fee'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"agent-annuity-detail-tpmk-fee-input","size":"sm","disabled":"","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.tpmkFineFee),callback:function ($$v) {_vm.tpmkFineFee=$$v},expression:"tpmkFineFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('label',{attrs:{"for":"agent-annuity-detail-official-fine-fee-input"}},[_vm._v(_vm._s(_vm.$t('agentAnnuityPriceDetail.official_fine_fee')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('agentAnnuityPriceDetail.official_fine_fee'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"agent-annuity-detail-official-fine-fee-input","size":"sm","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.officialFineFee),callback:function ($$v) {_vm.officialFineFee=$$v},expression:"officialFineFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }