<template>
<div>
    <design-form ref="designFormModal"></design-form>
    <b-row>
        <b-col class="col-md-2">
            <label style="font-size: x-small;" for="start-date-input">{{ $t('dateTime.start_date') }}</label>
            <CustomDatePicker :datePickerValidateOption="0" v-model="startDate"></CustomDatePicker>
        </b-col>
        <b-col class="col-md-2">
            <label style="font-size: x-small;" for="end-date-input">{{ $t('dateTime.end_date') }}</label>
            <CustomDatePicker :datePickerValidateOption="0" v-model="endDate"></CustomDatePicker>
        </b-col>
        <b-col class="col-md-8  mt-2 custom-search d-flex justify-content-end">
            <b-form-group>
                <b-button size="sm" variant="outline-primary" v-on:keyup.enter="getDesignDeadlineReportList()" @click="getDesignDeadlineReportList()">{{ $t('client.list') }}</b-button>
            </b-form-group>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="designDeadlineReportTable" :columns="columns" :rows="rows"  styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme" @on-cell-click="onCellClick">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('designDeadlineReport.record_no') }}
            </span>
            <span v-else-if="props.column.field === 'lastApplicationDate'" class="text-nowrap">
                {{ $t('designDeadlineReport.last_application_date') }}
            </span>
            <span v-else-if="props.column.field === 'priorityFillingDate'" class="text-nowrap">
                {{ $t('designDeadlineReport.priortiy_filling_date') }}
            </span>
            <span v-else-if="props.column.field === 'requestedPublishDate'" class="text-nowrap">
                {{ $t('designDeadlineReport.requested_publish_date') }}
            </span>
            <span v-else-if="props.column.field === 'status'" class="text-nowrap">
                {{ $t('designDeadlineReport.status') }}
            </span>
            <span v-else-if="props.column.field === 'field'" class="text-nowrap">
                {{ $t('designDeadlineReport.field') }}
            </span>
            <span v-else-if="props.column.field === 'explanation'" class="text-nowrap">
                {{ $t('designDeadlineReport.explanation') }}
            </span>
            <span v-else-if="props.column.field === 'day_left'" class="text-nowrap">
                {{ $t('designDeadlineReport.day_left') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Agent Name -->
            <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
                <b-badge variant="primary" style="cursor: pointer;">
                    <feather-icon
                        icon="MousePointerIcon"
                        class="mr-25"
                    />
                        <span>{{ props.row.recordNo }}</span>
                </b-badge>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                    </span>
                    <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination size="sm" :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>    
export default {
    data() {
        return {
            pageLength: 10,
            dir: false,
            startDate: null,
            endDate: null,
            columns: [{
                    label: this.$t('designDeadlineReport.record_no'),
                    field: `recordNo`,
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('designDeadlineReport.record_no'),
                    },
                    class: 'cursorPoint'
                },
                {
                    label: this.$t('designDeadlineReport.priortiy_filling_date'),
                    field: 'priorityFillingDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('designDeadlineReport.requested_publish_date') ,
                    field: 'requestedPublishDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('designDeadlineReport.last_application_date') ,
                    field: 'lastApplicationDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('designDeadlineReport.status'),
                    field: 'status'
                },
                {
                    label: this.$t('designDeadlineReport.field'),
                    field: 'field'
                },
                {
                    label: this.$t('designDeadlineReport.explanation'),
                    field: 'explanation'
                },
                {
                    label: this.$t('designDeadlineReport.day_left'),
                    field: 'day_left'
                }
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.startDate = new Date().toJSON()
        this.endDate = new Date(new Date().setMonth(new Date().getMonth()+6)).toJSON();
        this.getDesignDeadlineReportList()

    },
    methods: {
        onCellClick(params) {
            if (params.column.field == "recordNo") {
                this.$refs.designFormModal.showInfo(params.row.designId)
            }
        },
        getDesignDeadlineReportList() {
            this.rows = []
            const data = {
                startDate: this.startDate,
                endDate: this.endDate
            }
            const controller = {
                name: 'Report',
                actionName: 'GetDesignDeadlineReportList'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.designDeadlineReportList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    },
}
</script>

<style lang="scss">
.tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
.recordCursor{
    cursor: pointer;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
