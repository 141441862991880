<template>
    <div>    
    <patent-transaction-report-detail ref="patentTransactionReportDetail"></patent-transaction-report-detail>
    <b-card>
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row1SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row1FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row1Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getPatentList()" @click="getPatentList()">{{ $t('domain.list') }}</b-button>
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row2SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row2FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row2Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row3SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row3FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row3Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row4SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row4FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row4Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row5SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row5FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row5Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row6SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row6FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row6Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row7SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row7FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row7Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row8SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row8FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row8Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row9SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row9FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row9Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row10SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row10FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row10Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row11SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row11FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row11Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row12SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row12FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row12Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row13SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row13FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row13Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
        <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row14SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row14FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType1" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-input size="sm" v-model="row14Value" type="text" class="d-inline-block" />
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row15SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row15FieldTypeSelected" size="sm" value-field="id" text-field="name" :options="fieldType2" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-datepicker  v-model="row15StartDateValue" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('patentTransactionReport.application_date_start')" size="sm" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <b-form-datepicker  v-model="row15EndDateValue" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('patentTransactionReport.application_date_start')" size="sm" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-form-group>
            </b-col>
        </b-row>
        
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row16SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="patentStatusSelect" size="sm" value-field="id" text-field="name" :options="patentStatusOption" />
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row17SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="patentAnnuityWillPaySelect" size="sm" value-field="id" text-field="name" :options="patentAnnuityWillPayOption" />
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="row18SearchTypeSelected" size="sm" value-field="id" text-field="name" :options="searchType" />
                    </div>
                </b-form-group>
            </b-col>
    
            <b-col class="col-3">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="translatorSelect" size="sm" value-field="id" text-field="name" :options="translatorOption" />
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card>
    
</div>
</template>

<script>
export default {
    data() {
        return {
            rows: [],
            searchTerm: '',
            total: 0,
            pageLength: 50,
            dir: false,
            pageNo: 1,
            searchType: [
                { id: '1', name: 'And' },
                { id: '2', name: 'Or' }
            ],
            fieldType1: [
                { id: '1', name: 'Referans No' },
                { id: '2', name: 'Başlık' },
                { id: '3', name: 'Başlık ING' },
                { id: '4', name: 'Ajan Adı' },
                { id: '5', name: 'Sahip Adı' },
                { id: '6', name: 'Başvuru No' },
                { id: '7', name: 'EP Patent No' },
                { id: '8', name: 'EP Başvuru No' },
                { id: '9', name: 'PCT Başvuru No' },
                { id: '10', name: 'Patent No' },
                { id: '11', name: 'Ajan Referans No' },
                { id: '12', name: 'Not' },
                { id: '13', name: 'Resim Sayısı' },
                { id: '14', name: 'IPC Kodu' },
            ],
            fieldType2: [
                { id: '1', name: 'Başvuru Tarihi' },
                { id: '2', name: 'Geçici Koruma Başvuru Tarihi' },
                { id: '3', name: 'PCT Başvuru Tarihi' },
                { id: '4', name: 'EP Başvuru Tarihi' },                
                { id: '5', name: 'Tescil Tarihi' },
                { id: '6', name: 'Rüçhan Tarihi' },
                { id: '7', name: 'Yıllık Harç Tarihi' },
                { id: '8', name: 'Kullanım Beyanı Son Günü' },      
                { id: '9', name: 'Kullanım Beyanı Tarihi' },      
                { id: '10', name: 'EP B1 Yayın Tarihi' },      
                { id: '11', name: 'EP B2 Yayın Tarihi' },      
                { id: '12', name: 'EP B3 Yayın Tarihi' },      
                { id: '13', name: 'B2 Başvuru Tarihi ' },    
            ],

            row1SearchTypeSelected:1,
            row1FieldTypeSelected:1,
            row1Value:'',
            row2SearchTypeSelected:1,
            row2FieldTypeSelected:2,
            row2Value:'',
            row3SearchTypeSelected:1,
            row3FieldTypeSelected:3,
            row3Value:'',
            row4SearchTypeSelected:1,
            row4FieldTypeSelected:4,
            row4Value:'',
            row5SearchTypeSelected:1,
            row5FieldTypeSelected:5,
            row5Value:'',
            row6SearchTypeSelected:1,
            row6FieldTypeSelected:6,
            row6Value:'',
            row7SearchTypeSelected:1,
            row7FieldTypeSelected:7,
            row7Value:'',
            row8SearchTypeSelected:1,
            row8FieldTypeSelected:8,
            row8Value:'',
            row9SearchTypeSelected:1,
            row9FieldTypeSelected:9,
            row9Value:'',
            row10SearchTypeSelected:1,
            row10FieldTypeSelected:10,
            row10Value:'',
            row11SearchTypeSelected:1,
            row11FieldTypeSelected:11,
            row11Value:'',
            row12SearchTypeSelected:1,
            row12FieldTypeSelected:12,
            row12Value:'',
            row13SearchTypeSelected:1,
            row13FieldTypeSelected:13,
            row13Value:'',
            row14SearchTypeSelected:1,
            row14FieldTypeSelected:14,
            row14Value:'',
            row15SearchTypeSelected:1,
            row15FieldTypeSelected:2,
            row15StartDateValue:null,
            row15EndDateValue:null,
            row16SearchTypeSelected:1,
            patentStatusOption: [],
            patentStatusSelect: 0,
            row17SearchTypeSelected:1,
            patentAnnuityWillPayOption: [],
            patentAnnuityWillPaySelect: 0,
            row18SearchTypeSelected:1,
            translatorOption: [],
            translatorSelect: 0,    
        }
    },
    mounted() {
        const controller = {
            name: 'Patent',
            actionName: 'GetPatentTransactionReportInfo'
        }

        const payload = {
            controller: controller
        }

        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
                this.patentStatusOption = response.data.patentStatusList
                this.patentAnnuityWillPayOption = response.data.patentAnnuityWillPayList
                this.translatorOption = response.data.translatorList
                this.patentStatusOption.splice(0, 0, this.$nullSelected())
                this.patentAnnuityWillPayOption.splice(0, 0, this.$nullSelected())
                this.translatorOption.splice(0, 0, this.$nullSelected())  
            } else {
                this.$WarningAlert(response.data.resultMessage)
            }
        })
    },
    methods: {
        getPatentList() {
            this.rows = []
            const controller = {
                name: 'Patent',
                actionName: 'PatentTransactionReport'
            }
            const data = {
                row1SearchType: this.row1SearchTypeSelected,
                row1FieldType: this.row1FieldTypeSelected,
                row1Value: this.row1Value,                
                row2SearchType: this.row2SearchTypeSelected,
                row2FieldType: this.row2FieldTypeSelected,
                row2Value: this.row2Value,
                row3SearchType: this.row3SearchTypeSelected,
                row3FieldType: this.row3FieldTypeSelected,
                row3Value: this.row3Value,
                row4SearchType: this.row4SearchTypeSelected,
                row4FieldType: this.row4FieldTypeSelected,
                row4Value: this.row4Value,
                row5SearchType: this.row5SearchTypeSelected,
                row5FieldType: this.row5FieldTypeSelected,
                row5Value: this.row5Value,
                row6SearchType: this.row6SearchTypeSelected,
                row6FieldType: this.row6FieldTypeSelected,
                row6Value: this.row6Value,
                row7SearchType: this.row7SearchTypeSelected,
                row7FieldType: this.row7FieldTypeSelected,
                row7Value: this.row7Value,
                row8SearchType: this.row8SearchTypeSelected,
                row8FieldType: this.row8FieldTypeSelected,
                row8Value: this.row8Value,
                row9SearchType: this.row9SearchTypeSelected,
                row9FieldType: this.row9FieldTypeSelected,
                row9Value: this.row9Value,
                row10SearchType: this.row10SearchTypeSelected,
                row10FieldType: this.row10FieldTypeSelected,
                row10Value: this.row10Value,
                row11SearchType: this.row11SearchTypeSelected,
                row11FieldType: this.row11FieldTypeSelected,
                row11Value: this.row11Value,
                row12SearchType: this.row12SearchTypeSelected,
                row12FieldType: this.row12FieldTypeSelected,
                row13Value: this.row120Value,
                row13SearchType: this.row13SearchTypeSelected,
                row13FieldType: this.row13FieldTypeSelected,
                row13Value: this.row13Value,
                row14SearchType: this.row14SearchTypeSelected,
                row14FieldType: this.row14FieldTypeSelected,
                row14Value: this.row14Value,
                row15SearchType: this.row15SearchTypeSelected,
                row15FieldType: this.row15FieldTypeSelected,
                row15StartDateValue: this.row15StartDateValue,
                row15EndDateValue: this.row15EndDateValue,  
                row16SearchType: this.row16SearchTypeSelected,
                patentStatusId: this.patentStatusSelect,
                row17SearchType: this.row17SearchTypeSelected,
                patentAnnuityWillPayId: this.patentAnnuityWillPaySelect,   
                row18SearchType: this.row18SearchTypeSelected,
                translatorId: this.translatorSelect,       
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$refs.patentTransactionReportDetail.showData(response.data.patentList)     
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.featherIconReSize {
    width: 12px;
    height: 12px;
}

[dir=ltr] .mr-05,
[dir=ltr] .mx-1 {
    margin-right: 0.5rem !important;
}
</style>
