<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img src="@/assets/images/stock-logo.png" width="155" alt="Stock Yazılım"
            style="max-width: 100%; vertical-align: middle; line-height: 100%; border: 0;">
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('login.welcome') }} 👋
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('login.sub_title') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules" #default="{ invalid }">
          <b-form class="auth-login-form mt-2">
            <!-- email -->
            <label for="username">{{ $t('login.username') }}</label>
            <b-form-group>
              <validation-provider #default="{ errors }" :name="$t('login.username')" rules="required|email">
                <b-form-input id="username" placeholder="test@stock.com" v-model="username" />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <label for="password">{{ $t('login.password') }}</label>
              <validation-provider #default="{ errors }" :name="$t('login.password')" rules="required">
                <b-input-group class="input-group-merge">


                  <b-form-input id="password" :type="passwordFieldType" placeholder="············" v-model="password"
                    @keyup.enter="login" />

                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <b-link style="float:right" :to="{ name: 'ForgotPassword' }">
                <small>{{ $t('login.forgot_password') }}</small>
              </b-link>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" type="submit" block :disabled="invalid" @click.prevent="login">
              {{ $t('login.login') }}
            </b-button>
          </b-form>
        </validation-observer>

        <div class="divider my-2">
          <div class="divider-text">
            {{ $t('login.login_or') }}
          </div>
        </div>

        <!-- social button -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button href="https://www.google.com.tr/drive/about.html" variant="primary">
            <feather-icon icon="CloudIcon" />
          </b-button>
          <b-button href="https://www.google.com/sheets/about/" variant="warning">
            <feather-icon icon="ListIcon" />
          </b-button>
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { localize } from 'vee-validate';
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      status: '',
      // validation rules
      required,
      email
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  beforeMount() {
    // sessionStorage.language = this.$cookies.get("languageValue");
    sessionStorage.language = sessionStorage.getItem("languageValue");
    this.$i18n.locale = sessionStorage.language;
    localize(sessionStorage.language);
    localStorage.removeItem("accessToken")
  },
  mounted() {
    if (!this.checkLogin()) {
      // this.$router.push({ name: 'Home' })
      this.$router.push({name: 'Home'}).catch(error => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(error)
        }
      })
    }
    // sessionStorage.language = this.$cookies.get("languageValue");
    sessionStorage.language = localStorage.getItem("languageValue");
  },
  methods: {
    checkLogin() {
      if (this.$store.state.moduleAuth.isUserLoggedIn()) {
        return false
      }
      return true
    },
    login() {
      const payload = {
        username: this.username,
        password: this.password
      }
      /* eslint-disable */
      this.$store.dispatch('moduleAuth/login', payload).then(() => {
        // this.$router.push({ name: 'Home' })
        this.$router.push({name: 'Home'}).catch(error => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(error)
        }
      })
      })
        .catch(error => {
          this.$LoginError()
        })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
[dir] .input-group-text {
  padding: 0.768rem 1rem!important;
  margin-bottom: 0;
  text-align: center;
  background-color: white;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
</style>
