<template>
    <div>
        <b-modal id="employeeEducationInformationFormModal" centered size="lg" no-close-on-backdrop ref="employeeEducationInformationFormModal" :title=" $t('employeeEducationInformation.educationInformation_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col-md-4">
                        <b-form>
                            <validation-provider :name=" $t('employeeEducationInformation.education_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <label for="employee-educationInformation-type-input">{{ $t('employeeEducationInformation.education_type') }}</label>
                                <b-form-select :state="errors.length > 0 ? false:null" size="sm" v-model="educationInformationTypeSelect" value-field="id" text-field="name" :options="educationInformationTypeOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4">
                        <b-form>
                            <validation-provider #default="{ errors }" :name="$t('employeeEducationInformation.starting_year')" rules="required">
                                    <label>{{ $t('employeeEducationInformation.starting_year')  }}</label>
                                    <b-form-input size="sm" type="number" v-model="startingYear"  :placeholder="$t('employeeEducationInformation.starting_year')" />
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4">
                        <b-form>
                            <validation-provider #default="{ errors }" :name="$t('employeeEducationInformation.graduation_year')" rules="required">
                                    <label>{{ $t('employeeEducationInformation.graduation_year')  }}</label>
                                    <b-form-input size="sm" type="number" v-model="graduationYear" :placeholder="$t('employeeEducationInformation.graduation_year')" />
                                    <small class="text-danger">{{ errors[0]  }}</small>
                                </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label class="mt-2" for="employee-educationInformation-school-input">{{ $t('employeeEducationInformation.school') }}</label>
                        <validation-provider #default="{ errors }" :name=" $t('employeeEducationInformation.school')" rules="required">
                            <b-form-textarea id="employee-educationInformation-school-input" maxlength="250" :placeholder=" $t('employeeEducationInformation.school')" rows="1" v-model="school" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label class="mt-2" for="employee-educationInformation-section-input">{{ $t('employeeEducationInformation.section') }}</label>
                        <validation-provider #default="{ errors }" :name=" $t('employeeEducationInformation.section')" rules="required">
                            <b-form-textarea id="employee-educationInformation-section-input" maxlength="250" :placeholder=" $t('employeeEducationInformation.section')" rows="1" v-model="section" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            getEmployeeEducationInformationList: {
                type: Function
            }
        },
        data() {
            return {
                educationInformationTypeSelect: 0,
                educationInformationTypeOption: [],
                educationInformationInput: '',
                school: '',
                graduationYear : 0,
                startingYear : 0,
                section: '',
                id: 0,
                employeeId: 0,
            }
        },
        methods: {
            showInfo(id, employeeId) {
                this.$refs['employeeEducationInformationFormModal'].show()
                this.id = id
                this.employeeId = employeeId;
                if(this.employeeId == null){
                    this.employeeId = localStorage.getItem("EmployeeId")
                }
                const data = {
                    id: this.id
                }
                const controller = {
                    name: 'EmployeeEducationInformation'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.educationInformationInput = response.data.employeeEducationInformation.educationInformation
                        this.school = response.data.employeeEducationInformation.school
                        this.section = response.data.employeeEducationInformation.section
                        this.educationInformationTypeOption = response.data.educationTypeList
                        this.educationInformationTypeSelect = response.data.employeeEducationInformation.educationTypeId
                        this.educationInformationTypeOption.splice(0, 0, this.$nullSelected())
                        this.startingYear = response.data.employeeEducationInformation.startingYear
                        this.graduationYear = response.data.employeeEducationInformation.graduationYear
                    } else {
                        this.$bvModal.hide('employeeEducationInformationFormModal')
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            addOrUpdate() {
                if(this.employeeId == null){
                    this.employeeId = localStorage.getItem("EmployeeId")
                }
                const data = {
                    id: this.id,
                    employeeId: this.employeeId,
                    section : this.section,
                    school : this.school,
                    graduationYear : this.graduationYear,
                    startingYear : this.startingYear,
                    educationTypeId : this.educationInformationTypeSelect
                }
                const controller = {
                    name: 'EmployeeEducationInformation'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$bvModal.hide('employeeEducationInformationFormModal')
                                this.getEmployeeEducationInformationList()
                                this.$SaveAlert()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                })
            },
        }
    }
    </script>
    
    <style lang="scss">
    [dir=ltr] .modal-header .close {
        margin: -0.4rem -0.4rem -0.8rem auto;
    }
    
    [dir] label {
        margin-bottom: 0.2857rem;
        font-weight: bold;
    }
    
    .modal .modal-content {
        overflow: visible;
        margin-bottom: auto;
    }
    
    [dir] .invalid-tooltip {
        padding: 0.4rem 0.775rem !important;
        margin-top: -1.5rem !important;
        background-color: #ea5455 !important;
        border-radius: 0.358rem !important;
        left: 20px !important
    }
    </style>
    