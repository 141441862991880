<template>
<div>

    <!-- modal -->
    <b-modal id="designOppositionFormModal" centered size="xl" no-close-on-backdrop ref="designOppositionFormModal" :title=" $t('designOpposition.design_opposition_info')" hide-footer @close="clear()">
        <reminder-form ref="reminderFormModal"></reminder-form>
        <agent-note-form ref="agentNoteFormModal"></agent-note-form>
        <client-form ref="clientFormModal"></client-form>
        <p-d-f-viewer ref="bulletinInfoModal"></p-d-f-viewer>
        <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
        <b-card>
            <div class="custom-search d-flex justify-content-end mb-1">
                <b-row>
                    <b-col>
                        <b-button variant="outline-primary" size="sm" class="ml-05" @click.prevent="addOrUpdate">
                            {{$t('others.ok_title')}}
                        </b-button>

                        <b-dropdown v-if="id > 0" style="height: 2.1em;" size="sm" :text="$t('others.invoices')" class="ml-05" variant="outline-primary">
                            <b-dropdown-item @click="getClientInvoiceReport(2)">{{$t('others.InvoicesToBePaid')}}: {{ invoicesToBePaid }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(3)">{{$t('others.AllInvoices')}}: {{ allInvoices }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(4)">{{$t('others.InvoicesToBePaidForThisJob')}}: {{invoicesToBePaidForThisJob}}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(1)">{{$t('others.AllInvoicesRelatedToThisJob')}}: {{ allInvoicesRelatedToThisJob }}</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                            <template #button-content>
                                <feather-icon icon="PrinterIcon" size="10" class="align-middle" />
                            </template>
                            <div class="dropdown-scroll">
                                <b-dropdown-item @click="downloadDesignOppositionWordFile(wordTemplate.id, wordTemplate.name)" v-for="wordTemplate in wordTemplateList" :key="wordTemplate.id">
                                    <span>{{wordTemplate.name}}</span>
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                        <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                            <template #button-content>
                                <feather-icon icon="SendIcon" size="10" class="align-middle" />
                            </template>
                            <div class="dropdown-scroll">
                                <b-dropdown-item @click="prepareDesignOppositionMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                    <span>{{mailTemplate.explanation}}</span>
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                        <b-button variant="outline-primary" class="ml-05" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                            <feather-icon icon="BellIcon" size="10" class="align-middle" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="designOpposition-record-no-input">{{ $t('designOpposition.record_no') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="designOpposition-record-no-input" v-model="designOppositionRecordNo" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>

                    <b-col class="col-md-3">
                        <b-form-group>
                            <label for="designOpposition-oppositionDate-input">{{ $t('designOpposition.opposition_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="oppositionDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>

                    <b-col class="col-md-3">
                        <b-form>
                            <label for="designOpposition-bulletinNo-input">{{ $t('designOpposition.bulletin_no') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('designOpposition.bulletinNo')" rules="required">
                                    <b-input-group>
                                        <b-form-input size="sm" id="designOpposition-bulletinNo-input" v-model="bulletinNo" maxlength="50" :state="errors.length > 0 ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <b-input-group-append>
                                            <b-button v-if="bulletinPage > 0" v-b-tooltip.html :title="$t('others.bulletin')" variant="warning" size="sm" @click="openBulletin()">
                                                <feather-icon icon="BoldIcon" />
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form-group>
                            <label for="designOpposition-bulletinDate-input">{{ $t('designOpposition.bulletin_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="bulletinDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="designOpposition-applicationNo-input">{{ $t('designOpposition.application_no') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="designOpposition-applicationNo-input" v-model="applicationNo" maxlength="20" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="designOpposition-designname-input">{{ $t('designOpposition.design_name') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name=" $t('designOpposition.design_name')" rules="required">
                                    <b-form-input size="sm" id="designOpposition-designname-input" v-model="designOppositionDesignName" maxlength="50" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="designOpposition-owner-input">{{ $t('designOpposition.holder_name') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name=" $t('designOpposition.holder_name')" rules="required">
                                    <b-form-input size="sm" id="designOpposition-owner-input" v-model="owner" maxlength="250" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>

                    <b-col class="col-md-3">
                        <b-form>
                            <label for="status-option">{{ $t('designOpposition.status') }}</label>
                            <b-form-select size="sm" v-model="statusSelect" value-field="id" text-field="name" :options="statusOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <b-form-group>
                            <label for="designOpposition-submit-answer-deadline-date-input">{{ $t('designOpposition.submit_answer_deadline_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="submitAnswerDeadlineDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12">
                        <label for="textarea-default">{{ $t('designOpposition.note') }}</label>
                        <b-form-textarea size="sm" id="textarea-default" :placeholder=" $t('designOpposition.note')" v-model="note" rows="1" maxlength="250" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="hiddenControl == false" class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="design-opposition-agent-input"> {{ $t('designOpposition.agent_name') }} </label>
                            <b-input-group>
                                <validation-provider :name=" $t('designOpposition.agent_name')" #default="{ errors }" :rules="{ excluded: 0 }">
                                    <b-form-group label-for="design-opposition-agent-input" :state="errors.length > 0 ? false:null">
                                        <v-select class="agentInput" id="design-opposition-agent-input" v-model="designOppositionAgentSelect" :options="designOppositionAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                                        </v-select>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-input-group-append>
                                    <b-dropdown style="width: 4em; height: 2.5em; margin-bottom: 1em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                        <template #button-content>
                                            <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                        </template>
                                        <b-dropdown-item @click="getClientInfo(0)">
                                            <span>{{this.$t('others.add_client')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getAgentNoteInfo(designOppositionAgentSelect.clientAccountingId)">
                                            <span>{{this.$t('others.show_note')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getClientInfo(designOppositionAgentSelect.clientId)">
                                            <span>{{this.$t('others.show_agent')}}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="hiddenControl == false" class="col-lg-12">
                        <b-form>
                            <label for="designOpposition-agentReferenceNo-input">{{ $t('designOpposition.agent_ref_code') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="designOpposition-agentReferenceNo-input" v-model="agentReferenceNo" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="hiddenControl == true" class="col-lg-12 mt-1">
                        <label for="design-opposition-agent-input"> {{ $t('designOpposition.agent_name') }} </label>
                        <b-input-group>
                            <validation-provider :name=" $t('designOpposition.agent_name')" #default="{ errors }" :rules="{ excluded: 0 }">
                                <b-form-group label-for="design-opposition-agent-input" :state="errors.length > 0 ? false:null">
                                    <v-select class="agentInput" id="design-opposition-agent-input" v-model="designOppositionAgentSelect" :options="designOppositionAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                                    </v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <b-input-group-append>
                                <b-button variant="light" style="width: 3em; height: 3em; margin-bottom: 1em; margin-right: 0.5em" size="sm" v-b-tooltip.html :title="$t('others.show_note')" @click="getAgentNoteInfo(designOppositionAgentSelect.clientAccountingId)">
                                    <feather-icon icon="AlignRightIcon" />
                                </b-button>
                                <b-button variant="success" style="width: 3em; height: 3em; margin-bottom: 1em" size="sm" v-b-tooltip.html :title="$t('others.add_client')" @click="getClientInfo()">
                                    <feather-icon icon="PlusCircleIcon" />
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="hiddenControl == true" class="col-lg-12">
                        <b-form-group>
                            <label for="designOpposition-agentReferenceNo-input">{{ $t('designOpposition.agent_ref_code') }}</label>
                            <b-input-group>
                                <b-form-group>
                                    <b-form-input size="sm" id="designOpposition-agentReferenceNo-input" v-model="agentReferenceNo" maxlength="50" />
                                </b-form-group>
                                <b-input-group-append>
                                    <b-button variant="success" style="width: 4em; height: 2.5em; margin-bottom: 1em" size="sm" v-b-tooltip.html :title="$t('others.show_agent')" @click="getClientShowInfo(domainAgentSelect.clientAccountingId)">
                                        <feather-icon icon="TerminalIcon" />
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-card>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title=" $t('designOpposition.owner')" active style="width:100%;">
                            <b-card-text>
                                <designOpposition-owner-list :getClientInfo="getClientInfo" :designOppositionId="this.id"></designOpposition-owner-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('designOpposition.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="9"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('designOpposition.responsible')" style="width:100%;">
                            <b-card-text>
                                <designOpposition-responsible-list :designOppositionId="this.id" :clientId="this.clientId"></designOpposition-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('designOpposition.basis_for_opposition')" style="width:100%;">
                            <b-card-text>
                                <designOpposition-basis-for-opposition-list :getDesignOppositionList="getDesignOppositionList" :designOppositionId="this.id"></designOpposition-basis-for-opposition-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.job')" style="width:100%;">
                            <b-card-text>
                                <job-list :recordId="this.id" :moduleId="9" :recordNo="this.designOppositionRecordNo"></job-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="9"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDesignOppositionList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            designOppositionDesignName: '',
            owner: '',
            designOppositionRecordNo: '',
            oppositionDate: null,
            bulletinDate: null,
            bulletinNo: null,
            bulletinPage: null,
            designOppositionAgentSelect: 0,
            designOppositionAgentOption: [],
            applicationNo: '',
            agentReferenceNo: '',
            designOppositionStatusId: 0,
            statusSelect: 0,
            statusOption: [],
            wordTemplateList: [],
            mailTemplateList: [],
            note: '',
            applicationNo: '',
            hiddenControl: true,
            submitAnswerDeadlineDate: null,
            invoicesToBePaidForThisJob: 0,
            invoicesToBePaid: 0,
            allInvoices: 0,
            allInvoicesRelatedToThisJob: 0,
            clientAccountingId: 0,
        }
    },
    methods: {
        getClientInvoiceReport(type) {
            this.$refs.clientInvoiceReportListCallForm.showInfo(type, this.clientAccountingId, 9, this.id)
        },
        async getClientInfo() {
            this.$refs.clientFormModal.showInfo(0)
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        async getReminderInfo() {
            this.$refs.reminderFormModal.showInfo(0, this.id, 9)
        },
        async openBulletin() {
            this.$refs.bulletinInfoModal.showInfo(this.bulletinNo.toString(), this.bulletinPage, 1)
        },
        setClientId(event) {
            this.clientId = event.clientId
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.designOppositionAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        clear() {
            this.designOppositionStatusId = null,
                this.designOppositionAgentSelect = null,
                this.statusSelect = null
        },
        showInfo(id) {
            this.$refs['designOppositionFormModal'].show()
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'DesignOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.wordTemplateList = response.data.wordTemplateList
                    this.mailTemplateList = response.data.mailTemplateList
                    this.designOppositionRecordNo = response.data.designOpposition.recordNo
                    this.designOppositionDesignName = response.data.designOpposition.designName
                    this.owner = response.data.designOpposition.owner
                    this.recordDate = response.data.designOpposition.recordDate
                    this.oppositionDate = response.data.designOpposition.oppositionDate
                    this.agentReferenceNo = response.data.designOpposition.agentReferenceNo
                    this.note = response.data.designOpposition.note
                    this.bulletinNo = response.data.designOpposition.bulletinNo
                    this.bulletinPage = response.data.designOpposition.bulletinPage
                    this.bulletinDate = response.data.designOpposition.bulletinDate
                    this.applicationNo = response.data.designOpposition.applicationNo
                    this.statusSelect = response.data.designOpposition.designOppositionStatusId
                    this.statusOption = response.data.designOppositionStatusList
                    this.submitAnswerDeadlineDate = response.data.designOpposition.submitAnswerDeadlineDate
                    this.statusOption.splice(0, 0, this.$nullSelected())
                    if (response.data.designOpposition.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.designOpposition.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.designOppositionAgentOption = clientAccountingResponse.data.clientList
                            this.designOppositionAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.designOppositionAgentOption = []
                        this.designOppositionAgentSelect = 0;
                    }
                    if (this.id > 0) {
                        let accountingId = 0
                        if (response.data.designOpposition.clientAccountingId > 0)
                            accountingId = response.data.designOpposition.clientAccountingId
                        if (response.data.designOpposition.invoiceClientAccountingId > 0)
                            accountingId = response.data.designOpposition.invoiceClientAccountingId
                        this.clientAccountingId = accountingId

                        const invoiceData = {
                            clientAccountingId: accountingId,
                            moduleId: 9,
                            recordId: this.id
                        }
                        const invoiceController = {
                            name: 'ClientAccounting',
                            actionName: 'GetInvoiceCountByClientAccountId'
                        }
                        const invoicePayload = {
                            data: invoiceData,
                            controller: invoiceController
                        }
                        this.$store.dispatch('moduleAuth/customService', invoicePayload).then((invoiceResponse) => {
                            this.invoicesToBePaid = invoiceResponse.data.invoicesToBePaidCount
                            this.allInvoices = invoiceResponse.data.allInvoiceCount
                            this.allInvoicesRelatedToThisJob = invoiceResponse.data.allInvoicesRelatedToThisJobCount
                            this.invoicesToBePaidForThisJob = invoiceResponse.data.invoicesToBePaidForThisJobCount
                        })

                    } else {
                        this.invoicesToBePaid = 0
                        this.allInvoices = 0;
                        this.allInvoicesRelatedToThisJob = 0;
                        this.invoicesToBePaidForThisJob = 0;
                    }
                } else {
                    this.$bvModal.hide('designOppositionFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadDesignOppositionWordFile(documentId, name) {
            const data = {
                moduleId: 9,
                documentId: documentId,
                designOppositionId: this.id
            }
            const controller = {
                name: 'DesignOpposition',
                actionName: 'DownloadDesignOppositionWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            if (this.oppositionDate == "") {
                this.oppositionDate = null
            }
            const data = {
                id: this.id,
                designName: this.designOppositionDesignName,
                owner: this.owner,
                clientAccountingId: this.designOppositionAgentSelect.clientAccountingId,
                oppositionDate: this.oppositionDate,
                agentReferenceNo: this.agentReferenceNo,
                note: this.note,
                trademarkId: this.trademarkId,
                employeeId: localStorage.getItem('EmployeeId'),
                applicationNo: this.applicationNo,
                designOppositionStatusId: this.statusSelect,
                bulletinNo: this.bulletinNo,
                bulletinDate: this.bulletinDate,
                submitAnswerDeadlineDate: this.submitAnswerDeadlineDate
            }
            const controller = {
                name: 'DesignOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getDesignOppositionList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.designOppositionId
                                this.designOppositionRecordNo = response.data.recordNo
                                this.hiddenControl = false
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        prepareDesignOppositionMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                designOppositionId: this.id
            }
            const controller = {
                name: 'DesignOpposition',
                actionName: 'PrepareDesignOppositionMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
