<template>
    <div>
        <agent-transactions-report-trademark-list ref="agentTransactionsReportTrademarkList"></agent-transactions-report-trademark-list>
        <agent-transactions-report-national-trademark-list ref="agentTransactionsReportNationalTrademarkList"></agent-transactions-report-national-trademark-list>
        <agent-transactions-report-trademark-provisional-list ref="agentTransactionsReportTrademarkProvisionalList"></agent-transactions-report-trademark-provisional-list>
        <agent-transactions-report-trademark-objection-to-us-list ref="agentTransactionsReportTrademarkObjectionToUsList"></agent-transactions-report-trademark-objection-to-us-list>
        <agent-transactions-report-trademark-opposition-list ref="agentTransactionsReportTrademarkOppositionList"></agent-transactions-report-trademark-opposition-list>
        <agent-transactions-report-patent-list ref="agentTransactionsReportPatentList"></agent-transactions-report-patent-list>
        <agent-transactions-report-design-list ref="agentTransactionsReportDesignList"></agent-transactions-report-design-list>
        <agent-transactions-report-design-opposition-list ref="agentTransactionsReportDesignOppositionList"></agent-transactions-report-design-opposition-list>
        <agent-transactions-report-domain-list ref="agentTransactionsReportDomainList"></agent-transactions-report-domain-list>

        <client-form ref="clientFormModal"></client-form>
        <div class="custom-search">
            <b-row>
                <b-col class="col-md-6">
                    <label for="agent-transaction-name-input">{{ $t('agentTransactionsReport.title') }}</label>
                    <div class="d-flex align-items-center">
                        <b-form-input id="agent-transaction-name-input" size="sm" v-model="name" v-on:keyup.enter="getAgentTransactionsReportList()" :placeholder="$t('agentTransactionsReport.title')" type="text" class="d-inline-block" />
                    </div>
                </b-col>
                <b-col class="col-md-4">
                    <label for="agent-transaction-name-input">{{ $t('agentTransactionsReport.agent_owner') }}</label>
                    <div class="d-flex align-items-center">
                        <b-form-radio-group v-model="clientType" :options="options"  name="radio-options"></b-form-radio-group>
                    </div>
                </b-col>
                <b-col class="col-md-2  custom-search d-flex justify-content-end">
                    <div class="d-flex align-items-center">
                        <b-button class="d-inline-block  mr-05" size="sm" variant="outline-primary" @click="getAgentTransactionsReportList()" v-on:keyup.enter="getAgentTransactionsReportList()">{{ $t('client.list') }}</b-button>
                    </div> 
                    <div class="d-flex align-items-center">
                        <b-button class="d-inline-block" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    </div>             
                </b-col>
            </b-row>
        </div>  

      <!-- table -->
      <vue-good-table
        id="agentTransactionsReportTable"
        ref="agentTransactionsReportTable" 
        :columns="columns"
        :rows="rows"
        styleClass="vgt-table condensed tableSize"
        :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" 
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('agentTransactionsReport.agent_name') }}
            </span>
            <span v-else-if="props.column.field === 'clientAccountingNumber'" class="text-nowrap">
                {{ $t('agentTransactionsReport.account_no') }}
            </span>
            <span v-else-if="props.column.field === 'trademarkActive'" class="text-nowrap">
                {{ $t('agentTransactionsReport.trademark_active') }}
            </span>
            <span v-else-if="props.column.field === 'appealDecision'" class="text-nowrap">
                {{ $t('agentTransactionsReport.appeal_decision') }}
            </span>
            <span v-else-if="props.column.field === 'incomingDecision'" class="text-nowrap">
                {{ $t('agentTransactionsReport.incoming_decision') }}
            </span>
            <span v-else-if="props.column.field === 'nationalTrademark'" class="text-nowrap">
                {{ $t('agentTransactionsReport.national_trademark') }}
            </span>
            <span v-else-if="props.column.field === 'patent'" class="text-nowrap">
                {{ $t('agentTransactionsReport.patent') }}
            </span>
            <!--
            <span v-else-if="props.column.field === 'nationalPatent'" class="text-nowrap">
                {{ $t('agentTransactionsReport.national_patent') }}
            </span>
            -->
            <span v-else-if="props.column.field === 'design'" class="text-nowrap">
                {{ $t('agentTransactionsReport.design') }}
            </span>
            <span v-else-if="props.column.field === 'nationalDesign'" class="text-nowrap">
                {{ $t('agentTransactionsReport.national_design') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props" >
            
            <span v-if="props.column.field === 'name'">
                <b-row >
                    <b-col class="font-size: x-small">                       
                        <span @click="showClientForm(props.row)" style="cursor: pointer; font-weight:bold">
                            {{ props.row.name }}
                        </span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'clientAccountingNumber'">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.clientAccountingNumber }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ props.row.accountCurrency }}</span>
                    </b-col>
                </b-row>
            </span>

            <span v-if="props.column.field === 'trademarkActive'">
                <b-row>
                    <b-col >
                            <span style="cursor: pointer; font-weight:bold; color: blue;" @click="showDetailList(1, props.row)">{{ props.row.trademarkActive }}</span>
                        
             / 
                            <span style="cursor: pointer;font-weight:bold" @click="showDetailList(2, props.row)">{{ props.row.trademarkPassive }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'nationalTrademark'">
                <b-row>
                    <b-col>
                        <span style="cursor: pointer;font-weight:bold" @click="showDetailList(3, props.row)">{{ props.row.nationalTrademark }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'trademarkProvisional'">
                <b-row>
                    <b-col>
                        <span style="cursor: pointer;font-weight:bold" @click="showDetailList(4, props.row)" >{{ props.row.trademarkProvisional }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'trademarkObjectionToUs'">
                <b-row>
                    <b-col>
                        <span style="cursor: pointer;font-weight:bold" @click="showDetailList(5, props.row)" >{{ props.row.trademarkObjectionToUs }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'trademarkOpposition'">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span style="cursor: pointer;font-weight:bold" @click="showDetailList(6, props.row)" >{{ props.row.trademarkOpposition }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'patent'">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span style="cursor: pointer;font-weight:bold" @click="showDetailList(7, props.row)" >{{ props.row.patent }}</span>
                    </b-col>
                </b-row>
            </span>
            <!--
            <span v-else-if="props.column.field === 'nationalPatent'">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span style="cursor: pointer;font-weight:bold" @click="showDetailList(8, props.row)" >{{ props.row.nationalPatent }}</span>
                    </b-col>
                </b-row>
            </span> -->
            <span v-else-if="props.column.field === 'design'">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span style="cursor: pointer;font-weight:bold" @click="showDetailList(9, props.row)" >{{ props.row.design }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'designOpposition'">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span style="cursor: pointer;font-weight:bold" @click="showDetailList(10, props.row)" >{{ props.row.designOpposition }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'domain'">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span style="cursor: pointer;font-weight:bold" @click="showDetailList(11, props.row)" >{{ props.row.domain }}</span>
                    </b-col>
                </b-row>
            </span>
          </template>
     <!-- pagination -->
     <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
            </span>
            <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
import { BFormRadioGroup } from 'bootstrap-vue';
import { mounted } from 'vue-echarts';

  
  export default {
    data() {
      return {
        total: 0,
        pageLength: 50,
        dir: false,
        pageNo: 1,
        columns: [
          {
            label: this.$t('agentTransactionsReport.agent_name'),
            field: 'name',
          },
          {
            label: this.$t('agentTransactionsReport.account_no'),
            field: 'clientAccountingNumber',
          },
          {
            label: this.$t('agentTransactionsReport.trademark_active'),
            field: 'trademarkActive',
          },
          {
            label: this.$t('agentTransactionsReport.national_trademark'),
            field: 'nationalTrademark',
          },
          {
            label: this.$t('agentTransactionsReport.appeal_decision'),
            field: 'trademarkProvisional',
          },
          {
            label: this.$t('agentTransactionsReport.incoming_decision'),
            field: 'trademarkObjectionToUs',
          },
          {
            label: this.$t('agentTransactionsReport.trademark_opposition'),
            field: 'trademarkOpposition',
          },
          {
            label: this.$t('agentTransactionsReport.patent'),
            field: 'patent',
          },
          //{
          //  label: this.$t('agentTransactionsReport.national_patent'),
          //  field: 'nationalPatent',
          //},
          {
            label: this.$t('agentTransactionsReport.design'),
            field: 'design',
          },
          {
            label: this.$t('agentTransactionsReport.design_opposition'),
            field: 'designOpposition',
          },
          {
            label: this.$t('agentTransactionsReport.domain'),
            field: 'domain',
          },
        ],
        rows: [],
        searchTerm: '',
        clientType:0,
        options: [
          { text: this.$t('client.agent'), value: '0' },
          { text: this.$t('client.owner'), value: '1' }
        ],
        name:''
      }
    },
    methods: {
        showClientForm(row) {
            this.$refs.clientFormModal.showInfo(row.id)     
        }, 
        showDetailList(recordType, row) {     

            if(this.clientType == 0)
            {
                if(recordType == 1 || recordType == 2) {
                    
                    this.$refs.agentTransactionsReportTrademarkList.getAgentTransactionsReportList(recordType, row.clientAccountingId)     
                }
                else if(recordType == 3){
                    this.$refs.agentTransactionsReportNationalTrademarkList.getAgentTransactionsReportList(recordType, row.clientAccountingId)     
                }
                else if(recordType == 4){
                    this.$refs.agentTransactionsReportTrademarkProvisionalList.getAgentTransactionsReportList(recordType, row.clientAccountingId)     
                }
                else if(recordType == 5){
                    this.$refs.agentTransactionsReportTrademarkObjectionToUsList.getAgentTransactionsReportList(recordType, row.clientAccountingId)     
                }
                else if(recordType == 6){
                    this.$refs.agentTransactionsReportTrademarkOppositionList.getAgentTransactionsReportList(recordType, row.clientAccountingId)     
                }
                else if(recordType == 7){
                    this.$refs.agentTransactionsReportPatentList.getAgentTransactionsReportList(recordType, row.clientAccountingId)     
                }
                else if(recordType == 9){
                    this.$refs.agentTransactionsReportDesignList.getAgentTransactionsReportList(recordType, row.clientAccountingId)     
                }
                else if(recordType == 10){
                    this.$refs.agentTransactionsReportDesignOppositionList.getAgentTransactionsReportList(recordType, row.clientAccountingId)     
                }
                else if(recordType == 11){
                    this.$refs.agentTransactionsReportDomainList.getAgentTransactionsReportList(recordType, row.clientAccountingId)     
                }
            }

            if(this.clientType == 1)
            {
                if(recordType == 1 || recordType == 2) {
                    this.$refs.agentTransactionsReportTrademarkList.getOwnerTransactionsReportList(recordType, row.id)     
                }
                else if(recordType == 3){
                    this.$refs.agentTransactionsReportNationalTrademarkList.getOwnerTransactionsReportList(recordType, row.id)     
                }
                else if(recordType == 4){
                    this.$refs.agentTransactionsReportTrademarkProvisionalList.getOwnerTransactionsReportList(recordType, row.id)     
                }
                else if(recordType == 5){
                    this.$refs.agentTransactionsReportTrademarkObjectionToUsList.getOwnerTransactionsReportList(recordType, row.id)     
                }
                else if(recordType == 6){
                    this.$refs.agentTransactionsReportTrademarkOppositionList.getOwnerTransactionsReportList(recordType, row.id)     
                }
                else if(recordType == 7){
                    this.$refs.agentTransactionsReportPatentList.getOwnerTransactionsReportList(recordType, row.id)     
                }
                else if(recordType == 9){
                    this.$refs.agentTransactionsReportDesignList.getOwnerTransactionsReportList(recordType, row.id)     
                }
                else if(recordType == 10){
                    this.$refs.agentTransactionsReportDesignOppositionList.getOwnerTransactionsReportList(recordType, row.id)     
                }
                else if(recordType == 11){
                    this.$refs.agentTransactionsReportDomainList.getOwnerTransactionsReportList(recordType, row.id)     
                }
            }
        },
        getAgentTransactionsReportList() {

        this.rows = []
        const data = { name: this.name, isAgent: (this.clientType == 0), isOwner:(this.clientType == 1)}
        const controller = { name: 'Client', actionName: 'AgentTransactionsReport'}
        const payload = { data: data, controller: controller }
        let loader = this.$loading.show()
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
              response.data.agentTransactionList.forEach(element => {
                this.rows.push(element)
              })
          } else {
              this.$WarningAlert(response.data.resultMessage)
          }
          loader.hide()
        })
      },
      exportExcel() {
            const controller = {
                name: 'Client',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Tüm İşlemler Raporu", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
  }
  </script>
<style>
   .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
  