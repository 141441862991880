<template>
    <div>
        <b-card>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col-lg-3">
                        <label for="bulletin-type">{{ $t('bulletinUpload.bulletin_type') }}</label>
                        <validation-provider :name="$t('bulletinUpload.bulletin_type')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select size="sm" :state="errors.length > 0 ? false : null" class="custom-height"
                            v-model="bulletinTypeSelect" value-field="id" text-field="name" :options="bulletinTypeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col class="col-lg-3">
                        <label for="bulletin-no-input">{{ $t('bulletinUpload.bulletin_no') }}</label>
                        <validation-provider #default="{ errors }" :name=" $t('bulletinUpload.bulletin_no')" rules="required">
                            <b-form-group>
                                <b-form-input id="bulletin-no-input" v-model="bulletinNo" maxlength="7" size="sm" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="col-lg-3">
                        <b-form-group>
                            <label for="bulletin-upload-date-input">{{ $t('bulletinUpload.date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-1 mt-2">
                        <b-button size="sm" variant="outline-primary" onclick="document.getElementById('bulletinUploadFileSelect').click()">{{ $t('others.select') }}</b-button>            
                    </b-col>
                    <b-col class="col-lg-2 mt-2">
                        <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="uploadBulletin" >
                        {{$t('bulletinUpload.start_transfer')}}
                        </b-button>
                    </b-col>
                    <input hidden id="bulletinUploadFileSelect" type="file" ref="file" multiple="multiple" @change="uploadFiles($event)" accept="application/pdf, .log" />
                </b-row>
            </validation-observer>
        </b-card>
    </div>
</template>
<script>
export default{
    data(){
        return {
            bulletinTypeSelect: null,
            bulletinTypeOption: [
                { id: 0, name: this.$t('bulletinUpload.select')},
                { id: 1, name: this.$t('bulletinUpload.brand_bulletin')},
                { id: 2, name: this.$t('bulletinUpload.patent_bulletin') },
                { id: 3, name: this.$t('bulletinUpload.design_bulletin') },
                { id: 4, name: this.$t('bulletinUpload.brand_newspaper') },
            ],
            bulletinNo: '',
            selectFolder: '',
            fileList:[],
            date: null,
        }
    },
    methods: {
        async uploadFiles(e){
            this.fileList = []
      var input = e.target;
      if (input.files) {

        for (var i = 0; i < input.files.length; i++) {
          var fileInfo = await this.$getFileInfo(input.files[i]) 
          this.fileList.push(fileInfo);  
        }
      }
        },
        uploadBulletin() { 
            var logFile = null;
            var pdfFile = null;

            for (var i = 0; i < this.fileList.length; i++) {

                    if(this.fileList[i].contentType == 'log')
                    logFile = this.fileList[i]

                    if(this.fileList[i].contentType == 'pdf')
                        pdfFile = this.fileList[i]
                }
                
            if(logFile == null || pdfFile == null) {
                this.$WarningAlert('Lütfen bültenlerin olduğu klasörü seçiniz')
                return;
            }

            this.$refs.simpleRules.validate().then(success => {
                
            if (success) {
            var data = {bulletinTypeId: this.bulletinTypeSelect, bulletinNo: this.bulletinNo, date: this.date, base64: logFile.base64}
            var controller = {name : 'Bulletin', actionName:'UploadBulletin'}
            var payload = {data: data, controller: controller}
                this.$store.dispatch('moduleAuth/customService',payload).then((response) => {
                    if(response.data.resultStatus == true){
                        data = {bulletinTypeId: this.bulletinTypeSelect, bulletinNo: this.bulletinNo, date: this.date, base64: pdfFile.base64}
                        controller = {name : 'Bulletin', actionName:'ReadBulletinPdfAndSavePageNumbers'}
                        payload = {data: data, controller: controller}
                        this.$store.dispatch('moduleAuth/customService',payload).then((response) => {
                            if(response.data.resultStatus == true){
                                this.$SuccessControl('Bülten Başarıya Yüklendi','success')
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                })
            }
        })
        },
    },
}
</script>
<style>
[dir] .btn-sm, [dir] .btn-group-sm > .btn {
    padding: 0.536rem 1rem!important;
    border-radius: 0.358rem!important;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>