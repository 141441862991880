var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"patentInventorFormModal",attrs:{"id":"patentInventorFormModal","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('patentInventor.inventor_info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"close":function($event){return _vm.clear()},"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"col-md-12"},[_c('b-form',[_c('label',{attrs:{"for":"patentInventor-Name-input"}},[_vm._v(_vm._s(_vm.$t('patentInventor.name')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('patentInventor.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"patentInventor-Name-input","maxlength":"50","state":errors.length > 0 ? false : null},model:{value:(_vm.patentInventorName),callback:function ($$v) {_vm.patentInventorName=$$v},expression:"patentInventorName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-md-12"},[_c('b-form',[_c('label',{attrs:{"for":"patentInventor-nationalityIDNumber-input"}},[_vm._v(_vm._s(_vm.$t('patentInventor.nationality_id_number')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('patentInventor.nationality_id_number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"patentInventor-nationalityIDNumber-input","maxlength":"11","state":errors.length > 0 ? false : null},model:{value:(_vm.nationalityIDNumber),callback:function ($$v) {_vm.nationalityIDNumber=$$v},expression:"nationalityIDNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-md-12 mb-1"},[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('patentInventor.country'),"rules":{ excluded: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"country-input"}},[_vm._v(_vm._s(_vm.$t('patentInventor.country')))]),_c('b-form-select',{attrs:{"value-field":"id","text-field":"name","size":"sm","options":_vm.countryOption},model:{value:(_vm.countrySelect),callback:function ($$v) {_vm.countrySelect=$$v},expression:"countrySelect"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-md-12"},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v(_vm._s(_vm.$t('patentInventor.address')))]),_c('validation-provider',{attrs:{"name":_vm.$t('patentInventor.address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"size":"sm","id":"textarea-default","placeholder":_vm.$t('patentInventor.address'),"rows":"2","maxlength":"250","state":errors.length > 0 ? false : null},model:{value:(_vm.inventorAddress),callback:function ($$v) {_vm.inventorAddress=$$v},expression:"inventorAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }