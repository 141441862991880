<template>
    <div>
        <overtime-work-approve-form :getOvertimeWorkList="getOvertimeWorkList" ref="overtimeWorkApproveFormModal"></overtime-work-approve-form>
        <div class="custom-search">
            <b-row>
                <b-col class="col-md-2">
                    <label for="overtimeWork-employee-end-input">{{ $t('overtimeWork.employee') }}</label>
                    <div class="d-flex align-items-center">
                        <b-form-input size="sm" v-model="employee" @input="getOvertimeWorkList()" :placeholder="$t('overtimeWork.employee')" type="text" class="d-inline-block" />
                    </div>
                </b-col>
                <b-col class="col-md-2">
                    <label for="overtimeWork-type-input">{{ $t('overtimeWork.type') }}</label>
                    <b-form-select v-model="overtimeWorkTypeSelect" @change="getOvertimeWorkList()" size="sm" value-field="id" text-field="name" :options="overtimeWorkTypeOption" />
                </b-col>
                <b-col class="col-md-2">
                    <label for="overtimeWork-reason-input">{{ $t('overtimeWork.reason') }}</label>
                    <b-form-select v-model="overtimeWorkReasonSelect" @change="getOvertimeWorkList()" size="sm" value-field="id" text-field="name" :options="overtimeWorkReasonOption" />
                </b-col>
                <b-col class="col-md-2">
                    <label for="overtimeWork-status-input">{{ $t('overtimeWork.status') }}</label>
                    <b-form-select v-model="annualLeaveStatusSelect" @change="getOvertimeWorkList()" size="sm" value-field="id" text-field="name" :options="annualLeaveStatusOption" />
                </b-col>
                <b-col class="col-md-2">
                    <label for="overtimeWork-request-date-start-input">{{ $t('overtimeWork.request_date_start') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" @input="getOvertimeWorkList()" reset-value :placeholder="$t('overtimeWork.request_date_start')" size="sm" v-model="startingDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-col>
                <b-col class="col-md-2">
                    <label for="overtimeWork-request-date-end-input">{{ $t('overtimeWork.request_date_end') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" @input="getOvertimeWorkList()" reset-value :placeholder="$t('overtimeWork.request_date_end')" size="sm" v-model="endingDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <!-- table -->
        <vue-good-table id="overtimeWorkEmployeeTable" ref="overtimeWorkEmployeeTable" :columns="columns" styleClass="vgt-table condensed tableSize" :rows="rows" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                  }" :select-options="{
                    enabled: false,
                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                  }" :pagination-options="{
                    enabled: true,
                    perPage: pageLength
                  }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'name'" class="text-nowrap">
                    {{ $t('overtimeWork.status') }}
                </span>
                <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>
            <!-- Column: Table Column -->
            <template slot="table-row" slot-scope="props">
                <!-- Column: Process -->
                <span v-if="props.column.field === 'process'">
                    <span>
                        <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id,employeeId)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                            <feather-icon icon="XIcon" />
                        </b-button>
                    </span>
                </span>
            </template>
    
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                        <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <!-- ,'100', '1000'
                            Bu alanları kaldıran : Nevzat PEKER 11/07/2023 -->
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getOvertimeWorkList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
    </template>
    
    <script>
    export default {
        data() {
            return {
                total: 0,
                pageLength: 10,
                dir: false,
                pageNo: 1,
                columns: [{
                        label: this.$t('overtimeWork.employee'),
                        field: 'employee',
                    },
                    {
                        label: this.$t('overtimeWork.overtime_work_type'),
                        field: 'overtimeWorkType',
                    },
                    {
                        label: this.$t('overtimeWork.overtime_work_reason'),
                        field: 'overtimeWorkReason',
                    },
                    {
                        label: this.$t('overtimeWork.annual_leave_status'),
                        field: 'annualLeaveStatus',
                    },
                    {
                        label: this.$t('overtimeWork.starting_date'),
                        field: 'startingDate',
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('overtimeWork.ending_date'),
                        field: 'endingDate',
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('overtimeWork.request_description'),
                        field: 'requestDescription',
                    },
                    {
                        label: this.$t('overtimeWork.approving_manager'),
                        field: 'manager',
                    },
                    {
                        label: this.$t('overtimeWork.approve_description'),
                        field: 'approveDescription',
                    },
                    {
                        label: 'Process',
                        field: 'process',
                        width: '10em'
                    },
                ],
                rows: [],
                searchTerm: '',
                tokenEmployeeId: 0,
                id: 0,
                annualLeaveStatusSelect: 0,
                annualLeaveStatusOption: [],
                overtimeWorkReasonSelect: 0,
                overtimeWorkReasonOption: [],
                overtimeWorkTypeSelect: 0,
                overtimeWorkTypeOption: [],
                startingDate: null,
                endingDate: null,
                employee : '',
                employeeId : 0,
                overtimeWorkToApprove: null
            }
        },
        mounted() {
            this.overtimeWorkToApprove = this.$route.query.overtimeWorkToApprove;
            if (this.overtimeWorkToApprove != null) {
                this.overtimeWorkStatusSelect = 2
            } else this.overtimeWorkStatusSelect = 0
            this.getOvertimeWorkList()
            if (this.employeeId == 0) {
                this.employeeId = localStorage.getItem('EmployeeId')
            }
        },
        methods: {
            async deleted(data) {
                data = {
                    id: data.id,
                    pageLength: this.pageLength,
                    pageNo: this.pageNo
                }
                const controller = {
                    name: 'EmployeeOvertimeWork'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$DeleteConfirm().then(result => {
                    if (result.value) {
                        this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$DeleteAlert()
                                this.getOvertimeWorkList()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                                this.getOvertimeWorkList()
                            }
                        })
                    }
                })
            },
            async getInfo(id) {
                this.$refs.overtimeWorkApproveFormModal.showInfo(id)
            },
            getOvertimeWorkList() {
                this.rows = []
                const controller = {
                    name: 'EmployeeOvertimeWork',
                    actionName: 'GetEmployeeOvertimeWorkList'
                }
                const data = {
                    pageLength: this.pageLength,
                    pageNo: this.pageNo,
                    employee: this.employee,
                    overtimeWorkTypeId: this.overtimeWorkTypeSelect,
                    overtimeWorkReasonId: this.overtimeWorkReasonSelect,
                    annualLeaveStatusId: this.annualLeaveStatusSelect,
                    startingDate: this.startingDate,
                    endingDate: this.endingDate
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.overtimeWorkReasonSelect = this.overtimeWorkReasonSelect
                        this.overtimeWorkReasonOption = response.data.overtimeWorkReasonList
                        this.overtimeWorkTypeSelect = this.overtimeWorkTypeSelect
                        this.overtimeWorkTypeOption = response.data.overtimeWorkTypeList
                        this.annualLeaveStatusSelect = this.annualLeaveStatusSelect
                        this.annualLeaveStatusOption = response.data.annualLeaveStatusList
                        this.overtimeWorkReasonOption.splice(0, 0, this.$nullSelected('name'))
                        this.overtimeWorkTypeOption.splice(0, 0, this.$nullSelected('name'))
                        this.annualLeaveStatusOption.splice(0, 0, this.$nullSelected('name'))
                        this.total = response.data.total
                        this.startingDate = response.data.startingDate
                        this.endingDate = response.data.endingDate
                        response.data.employeeOvertimeWorkList.forEach(element => {
                            this.rows.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
        },
    }
    </script>
    
    <style lang="scss">    
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    </style>
    