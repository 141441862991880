var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('patent-note-form',{ref:"patentNoteFormModal",attrs:{"getPatentList":_vm.getPatentList,"getPatentNoteList":_vm.getPatentNoteList}}),_c('b-row',[_c('b-col',{staticClass:"col-12"},[_c('div',{staticClass:"custom-search d-flex justify-content-end",staticStyle:{"float":"right"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{staticStyle:{"float":"right"},attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.getInfo(0)}}},[_vm._v(_vm._s(_vm.$t('patentNote.note_add')))])],1)])],1)])],1),_c('vue-good-table',{ref:"patentNoteTable",attrs:{"id":"patentNoteTable","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table condensed tableSize","select-options":{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
  },"theme":"my-theme"},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field === 'patentNoteType')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('patentNote.patent_note_type'))+" ")]):(props.column.field === 'date')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('patentNote.date'))+" ")]):(props.column.field === 'note')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('patentNote.note'))+" ")]):(props.column.field === 'employee')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('patentNote.employee'))+" ")]):(props.column.field === 'process')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('others.process'))+" ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'process')?_c('span',[_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",modifiers:{"html":true}},{name:"b-modal",rawName:"v-b-modal.modal-top",modifiers:{"modal-top":true}}],staticClass:"btn-icon mb-1 ml-1",attrs:{"title":_vm.$t('others.edit'),"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.getInfo(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",modifiers:{"html":true}}],staticClass:"btn-icon mb-1 ml-1",attrs:{"title":_vm.$t('others.delete'),"variant":"warning","size":"sm"},on:{"click":function($event){return _vm.deleted(props.row)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)]):_vm._e()]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.data_not_found'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }