<template>
    <div>
      <!-- modal -->
      <b-modal
        id="wordTemplatesFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="wordTemplatesFormModal"
        :title=" $t('wordTemplates.word_templates_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col class="col-lg-12">
          <validation-provider
            :name=" $t('wordTemplates.modules_name')"
            :rules="{ excluded:0}" 
            v-slot="{ errors }"
          >
            <label for="wordTemplates-address-input">{{ $t('wordTemplates.modules_name') }}</label>
            <b-form-select
              :state="errors.length > 0 ? false:null"
              v-model="modulesSelect"
              value-field="id"
              text-field="name"
              size="sm"
              :options="modulesOption"
            />
            <small class="text-danger">{{ errors[0] }}</small>       

          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-lg-12 mt-1">
          <validation-provider
                #default="{ errors }"
                :name=" $t('wordTemplates.documents_name')"
                rules="required"
            >
            <label for="wordTemplates-input">{{ $t('wordTemplates.documents_name') }}</label>
          <b-input-group>
            <b-form-input
                id="wordTemplates-input"
                v-model="wordFileName"
                maxlength="50"
                size="sm"
                :state="errors.length > 0 ? false:null"
            />

            <b-input-group-append>
              <b-button style="font-size: 0.5em;" variant="outline-primary" onclick="document.getElementById('wordTemplatesFileSelect').click()">{{$t('others.select') }}</b-button>
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-col>
        <b-form-file
        style="display:none"
        id="wordTemplatesFileSelect"
        accept=".docx"
        @change="uploadFile"
        :placeholder=" $t('wordTemplates.documents_select')"
        :drop-placeholder=" $t('wordTemplates.documents_select_info')"
        />
      </b-row>
    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>

  export default {
    props: {
      getWordTemplatesList: {type: Function},
    },
    data() {
      return {
        id: 0,
        modulesSelect: 0,
        modulesOption: [],        
        wordFileName: '',
        wordFile: '',
        fileInfo : []
      }
    },
    methods: {
    async uploadFile(e){
      var fileInfo = await this.$getFileInfo( e.target.files[0])
      this.wordFileName = fileInfo.name
      this.wordFile = fileInfo.base64
    },
    showInfo(id) {
      this.$refs['wordTemplatesFormModal'].show() 
      this.id = id
      const data = {id: this.id}
      const controller = {name : 'WordTemplate'}
      const payload = {data: data, controller: controller}
      this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
          if(response.data.resultStatus == true){
              this.modulesOption = response.data.moduleList
              this.modulesSelect = response.data.wordTemplate.moduleId
              this.wordFileName = response.data.wordTemplate.name
              this.modulesOption.splice(0, 0, this.$nullSelected("name"))
          } else {
              this.$bvModal.hide('wordTemplatesFormModal')
              this.$WarningAlert(response.data.resultMessage)
          }  
      })  
    },
    addOrUpdate() {
      const data = {id: this.id, moduleId: this.modulesSelect, name: this.wordFileName, base64: this.wordFile}
      const controller = {name : 'WordTemplate'}
      const payload = {data: data, controller: controller}
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
              this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                if(response.data.resultStatus == true){
                  this.$bvModal.hide('wordTemplatesFormModal')
                  this.getWordTemplatesList()
                  this.$SaveAlert()
                } else {
                  this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
      })
    },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: end!important;
    width: 100%;
}
  </style>
  