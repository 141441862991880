<template>
<div>
    <!-- modal -->
    <b-modal id="designPriorityFormModal" centered size="lg" no-close-on-backdrop ref="designPriorityFormModal" :title=" $t('designPriority.priority_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @close="clear()" @ok.prevent="addOrUpdate">
        <validation-observer ref="infoRules" tag="form">
            <b-row>
                <b-col class="col-md-4 mt-1">
                    <b-form>
                        <label for="designPriority-no-input">{{ $t('designPriority.no') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('designPriority.no')" rules="required">
                                <b-form-input id="designPriority-no-input" size="sm" v-model="no" maxlength="20" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-4 mt-1">
                    <validation-provider :name=" $t('designPriority.country')" #default="{ errors }" :rules="{ excluded: 0 }">
                        <b-form>
                            <label for="country-input">{{ $t('designPriority.country') }}</label>
                            <b-form-select size="sm" v-model="countrySelect" value-field="id" text-field="name" :options="countryOption" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form>
                    </validation-provider>
                </b-col>
                <b-col class="col-md-4 mt-1">
                    <label for="designPriority-date-input">{{ $t('designPriority.date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="1" v-model="date"></CustomDatePicker>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-4 mt-1">
                    <label for="country-input">{{ $t('designPriority.priority_type') }}</label>
                    <b-form-radio v-model="applicationOrExhibition" name="some-radios" value="0">
                        {{ $t('designPriority.application') }}
                    </b-form-radio>
                    <b-form-radio v-model="applicationOrExhibition" name="some-radios" value="1">
                        {{ $t('designPriority.exhibition') }}
                    </b-form-radio>
                </b-col>
                <b-col v-if="applicationOrExhibition == 1" class="col-md-4 mt-1">
                    <label for="design-priority-official-opening-date-input">{{ $t('designPriority.official_opening_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="1" v-model="officialOpeningDate"></CustomDatePicker>
                </b-col>
                <b-col v-if="applicationOrExhibition == 1" class="col-md-4 mt-1">
                    <label for="design-priority-display-date-input">{{ $t('designPriority.display_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="1" v-model="displayDate"></CustomDatePicker>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-4 mt-1">
                    <b-form-group>
                        <label for="design-priority-priority-fillingDate-input">{{ $t('designPriority.priority_filling_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="priorityFillingDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDesignPriorityList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            desginId: 0,
            lastApplicationDate: null,
            no: '',
            countryOption: [],
            countrySelect: 0,
            applicationOrExhibition: 0,
            displayDate: null,
            officialOpeningDate: null,
            date: null,
            priorityFillingDate: null,
        }
    },
    methods: {
        showInfo(id, desginId) {
            this.$refs['designPriorityFormModal'].show()
            this.id = id
            this.desginId = desginId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'DesignPriority'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.date = response.data.designPriority.date
                    this.no = response.data.designPriority.no
                    this.countrySelect = response.data.designPriority.countryId
                    this.countryOption = response.data.countryList
                    this.officialOpeningDate = response.data.designPriority.officialOppeningDate
                    this.priorityFillingDate = response.data.designPriority.priorityFillingDate
                    this.displayDate = response.data.designPriority.expositionDate
                    this.applicationOrExhibition = response.data.designPriority.type
                    this.countryOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('designPriorityFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
            this.countrySelect = 0,
                this.date = null,
                this.no = null
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                designId: this.desginId,
                countryId: this.countrySelect,
                date: this.date,
                no: this.no,
                expositionDate: this.displayDate,
                officialOppeningDate: this.officialOpeningDate,
                type: this.applicationOrExhibition,
                priorityFillingDate: this.priorityFillingDate
            }
            const controller = {
                name: 'DesignPriority'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.lastApplicationDate = response.data.lastApplicationDate
                            this.$emit('passToLastApplicationDate', this.lastApplicationDate);
                            this.$bvModal.hide('designPriorityFormModal')
                            this.getDesignPriorityList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        }
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
