<template>
    <div>
      <!-- modal -->
      <b-modal
        id="documentTypeFormModal"
        centered
        size="xl"
        no-close-on-backdrop
        ref="documentTypeFormModal"
        :title=" $t('documentType.document_type_info')"
        @close="clear()"
        hide-footer
      >
      <div class="custom-search d-flex justify-content-end">
        <b-row>
          <b-col>
            <b-button
              variant="outline-primary"
              size="sm"
              class="mr-1"
              @click.prevent="addOrUpdate"
            >
              {{$t('others.ok_title')}}
            </b-button>
          </b-col>
        </b-row>
      </div>
        <validation-observer ref="simpleRules">
          <b-row class="mt-1">
            <b-col class="col-lg-3">
              <b-form>
                <label for="documentType-name-input">{{ $t('documentType.name') }}</label>
                <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name=" $t('documentType.name')"
                  rules="required"
                >
                <b-form-input
                  id="documentType-name-input"
                  v-model="documentTypeName"
                  size="sm"
                  maxlength="100"
                  :state="errors.length > 0 ? false:null"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-form>
            </b-col>
            <b-col class="col-lg-3">
              <b-form>
                <label for="documentType-sequenceNo-input">{{ $t('documentType.sequence_no') }}</label>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name=" $t('documentType.sequence_no')"
                    rules="required"
                  >
                  <b-form-input
                    id="documentType-sequenceNo-input"
                    v-model="documentTypeSequenceNo"
                    type="number"
                    size="sm"
                    oninput="javascript: if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                    onKeyPress="if(this.value.length==3) return false"
                    :state="errors.length > 0 ? false:null"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col class="col-lg-3">
              <b-form>
                <label for="documentType-module-input">{{ $t('documentType.module') }}</label>
                <b-form-group>
                <validation-provider
                    :name=" $t('documentType.module')"
                    :rules="{ excluded:0}" 
                    v-slot="{ errors }"
                  >
                <b-form-select
                      :state="errors.length > 0 ? false:null"
                      v-model="moduleSelect"
                          value-field="id"
                          size="sm"
                          text-field="name"
                          :options="moduleOption"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>   
                  </validation-provider>
                </b-form-group> 
              </b-form>
            </b-col>
            <b-col class="col-lg-3">
              <b-row>
                <b-col class="col-md-6">
                  <label for="country-isTrMain-input">{{ $t('documentType.is_TR') }}</label>
                  <br>
                    <b-form-checkbox
                      class="custom-control-success"
                      name="country-isTrMain-input"
                      id="country-isTrMain-input"
                      v-model="isTrMain"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                </b-col>
                <b-col class="col-md-6">
                  <label for="country-isKKTCMain-input">{{ $t('documentType.is_KKTC') }}</label>
                  <br>
                  <b-form-checkbox
                    class="custom-control-success"
                    name="country-isKKTCMain-input"
                    id="country-isKKTCMain-input"
                    v-model="isKKTCMain"
                    switch>
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
          </b-row>        
      </validation-observer>
        <b-card :hidden="hiddenDocumentTypeControl">
          <b-row>
            <b-col class="mt-2">
              <b-card-text>
                  <document-type-detail-list :documentTypeId="this.id"></document-type-detail-list>  
              </b-card-text>
            </b-col>
          </b-row>
        </b-card>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getDocumentTypeList: {type: Function}
    },
    data() {
      return {
        id: 0,
        documentTypeName: '',
        documentTypeSequenceNo : 0,
        hiddenDocumentTypeControl: true,
        moduleSelect: 0,
        moduleOption: [],
        isTrMain: false,
        isKKTCMain : false,
      }
    },
    methods: {
      clear() {
      this.moduleSelect = 0  
    },
      showInfo(id) {
        this.$refs['documentTypeFormModal'].show()
        this.id = id
        if (this.id == 0) {
            this.hiddenDocumentTypeControl = true
          }
        else{
          this.hiddenDocumentTypeControl = false
        }
        const data = {id: this.id}
        const controller = {name : 'DocumentType'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.documentTypeName = response.data.documentType.name
                this.documentTypeSequenceNo = response.data.documentType.sequenceNo
                this.moduleOption = response.data.moduleList
                this.moduleSelect = response.data.documentType.moduleId
                this.isTrMain = response.data.documentType.isTr
                this.isKKTCMain = response.data.documentType.isKKTC
                this.moduleOption.splice(0, 0, this.$nullSelected())
            } else {
                this.$bvModal.hide('documentTypeFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {
          id: this.id,
          name: this.documentTypeName,
          sequenceNo: this.documentTypeSequenceNo,
          moduleId : this.moduleSelect,
          isTr : this.isTrMain,
          isKKTC : this.isKKTCMain,
          note : this.note,
        }
        const controller = {name : 'DocumentType'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.getDocumentTypeList()
                    this.$SaveAlert()
                    if (this.id == 0) {
                      this.id = response.data.documentTypeId
                      this.hiddenDocumentTypeControl = false
                    }
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                    this.$bvModal.hide('documentTypeFormModal')
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  