<template>
<div>
    <!-- modal -->
    <b-modal id="overtimeWorkFormModal" centered size="lg" no-close-on-backdrop ref="overtimeWorkFormModal" :title=" $t('overtimeWork.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="col-md-3 mt-1">
                        <b-form>
                            <label for="overtime-work-employee-input">{{ $t('overtimeWork.employee') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="overtime-work-employee-input" v-model="employee" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3 mt-1">
                        <label for="overtime-work-starting-date-input">{{ $t('overtimeWork.starting_date') }}</label>
                        <flat-pickr v-model="startingDate" class="form-control" size="sm" time_24hr="true" :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}" />
                    </b-col>
                    <b-col class="col-md-3 mt-1">
                        <label for="overtime-work-ending-date-input">{{ $t('overtimeWork.ending_date') }}</label>
                        <b-form-group>
                            <flat-pickr v-model="endingDate" class="form-control" size="sm" time_24hr="true" :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}" />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-3 mt-1">
                        <b-form>
                            <label for="overtime-work-total-input">{{ $t('overtimeWork.total') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="overtime-work-employee-vesting-input" v-model="total" type="number" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <label for="overtime-work-status-input">{{ $t('overtimeWork.annual_leave_status') }}</label>
                            <b-form-select disabled size="sm" v-model="annualLeaveStatusSelect" value-field="id" text-field="name" :options="annualLeaveStatusOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <label for="overtime-work-status-input">{{ $t('overtimeWork.overtime_work_type') }}</label>
                            <b-form-select size="sm" v-model="overtimeWorkTypeSelect" value-field="id" text-field="name" :options="overtimeWorkTypeOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <label for="overtime-work-type-input">{{ $t('overtimeWork.overtime_work_reason') }}</label>
                            <b-form-select size="sm" v-model="overtimeWorkReasonSelect" value-field="id" text-field="name" :options="overtimeWorkReasonOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6 mt-1">
                        <label for="overtime-work-request-description-input">{{ $t('overtimeWork.request_description') }}</label>
                        <b-form-textarea size="sm" id="overtime-work-request-description-input" v-model="requestDescription" rows="3" max-rows="8" maxlength="250" />
                    </b-col>
                    <b-col class="col-md-6 mt-1">
                        <label for="overtime-work-approve-description-input">{{ $t('overtimeWork.approve_description') }}</label>
                        <b-form-textarea disabled size="sm" id="overtime-work-approve-description-input" v-model="approveDescription" rows="3" max-rows="8" maxlength="250" />
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getOvertimeWorkList: {
            type: Function
        },
        employeeId: Number
    },
    data() {
        return {
            id: 0,
            employee: '',
            startingDate: null,
            endingDate: null,
            requestDate: null,
            overtimeWorkTypeSelect: 0,
            overtimeWorkTypeOption: [],
            overtimeWorkReasonSelect: 0,
            overtimeWorkReasonOption: [],
            annualLeaveStatusSelect: 0,
            annualLeaveStatusOption: [],
            requestDescription: '',
            approveDescription: '',
            total: 0,
            employeeId2: 0,
            total: 0,
        }
    },
    methods: {
        convertToISODateTime(dateTimeString) {
            const [datePart, timePart] = dateTimeString.split(' ');
            const [year, month, day] = datePart.split('-');
            const [hours, minutes] = timePart.split(':');
            // Note: JavaScript months are 0-based (0 = January, 1 = February, ...).
            const isoDate = new Date(year, month - 1, day, hours, minutes);
            const isoString = isoDate.toISOString();
            return isoString;
        },
        showInfo(id, employeeId2) {
            this.$refs['overtimeWorkFormModal'].show()
            this.tokenEmployeeId = localStorage.getItem('EmployeeId')
            if (employeeId2 > 0 && employeeId2 != this.tokenEmployeeId) {
                this.employeeId2 = employeeId2
            } else {
                this.employeeId2 = this.tokenEmployeeId
            }
            const data = {
                id: id,
                employeeId: this.employeeId2
            }
            const controller = {
                name: 'EmployeeOvertimeWork'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.startingDate = response.data.employeeOvertimeWork.startingDate
                    this.endingDate = response.data.employeeOvertimeWork.endingDate
                    this.requestDate = response.data.employeeOvertimeWork.requestDate
                    this.overtimeWorkTypeSelect = response.data.employeeOvertimeWork.overtimeWorkTypeId
                    this.overtimeWorkTypeOption = response.data.overtimeWorkTypeList
                    this.overtimeWorkReasonSelect = response.data.employeeOvertimeWork.overtimeWorkReasonId
                    this.overtimeWorkReasonOption = response.data.overtimeWorkReasonList
                    this.annualLeaveStatusOption = response.data.annualLeaveStatusList
                    this.annualLeaveStatusSelect = response.data.employeeOvertimeWork.annualLeaveStatusId
                    this.requestDescription = response.data.employeeOvertimeWork.requestDescription
                    this.approveDescription = response.data.employeeOvertimeWork.approveDescription
                    this.overtimeWorkReasonOption.splice(0, 0, this.$nullSelected())
                    this.overtimeWorkTypeOption.splice(0, 0, this.$nullSelected())
                    this.annualLeaveStatusOption.splice(0, 0, this.$nullSelected())
                    this.employee = response.data.employee
                    this.total = response.data.employeeOvertimeWork.total
                } else {
                    this.$bvModal.hide('overtimeWorkFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                    this.startingTime = ''
                    this.endingTime = ''
                    this.requestDescription = ''
                    this.overtimeWorkTypeSelect = 0
                }
            })
        },
        addOrUpdate() {
            this.tokenEmployeeId = localStorage.getItem('EmployeeId')
            if (this.employeeId2 > 0 && this.employeeId2 != this.tokenEmployeeId) {
                this.employeeId2 = this.employeeId2

            } else {
                this.employeeId2 = this.tokenEmployeeId
            }
            const data = {
                startingDate: this.convertToISODateTime(this.startingDate),
                endingDate: this.convertToISODateTime(this.endingDate),
                overtimeWorkTypeId: this.overtimeWorkTypeSelect,
                overtimeWorkReasonId: this.overtimeWorkReasonSelect,
                requestDescription: this.requestDescription,
                approveDescription: this.approveDescription,
                employeeId: this.employeeId2,
            }
            const controller = {
                name: 'EmployeeOvertimeWork'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('overtimeWorkFormModal')
                            this.$SaveAlert()
                            this.getOvertimeWorkList()
                            this.startingTime = ''
                            this.endingTime = ''
                            this.requestDescription = ''
                            this.overtimeWorkTypeSelect = 0
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
