<template>
  <div>
    <!-- modal -->
    <b-modal
      id="mailAccountFormModal"
      centered
      size="lg"
      no-close-on-backdrop
      ref="mailAccountFormModal"
      :title=" $t('mailAccount.mail_account_info')"
      :cancel-title=" $t('others.cancel_title')"
      :ok-title=" $t('others.ok_title')"
      cancel-variant="outline-secondary"
      @ok.prevent="addOrUpdate"
    >
       <validation-observer ref="simpleRules">
      <b-form>
      <b-row>
        <b-col class="col-lg-12">
          <label for="mail-address-input">{{ $t('mailAccount.mail_address_name') }}</label>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              :name=" $t('mailAccount.mail_address_name')"
              rules="required|email"
            >
            <b-form-input
              size="sm"
              id="mail-address-input"
              v-model="mailAddress"
              maxlength="50"
              :state="errors.length > 0 ? false:null"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-lg-12">
          <label for="display-name-input">{{ $t('mailAccount.display_name') }}</label>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              :name=" $t('mailAccount.display_name')"
              rules="required"
            >
            <b-form-input
              size="sm"
              id="display-name-input"
              v-model="displayName"
              maxlength="50"
              :state="errors.length > 0 ? false:null"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="mail-smtp-input">{{ $t('mailAccount.mail_smtp_name') }}</label>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              :name=" $t('mailAccount.mail_smtp_name')"
              rules="required"
            >
            <b-form-input
              size="sm"
              id="mail-smtp-input"
              v-model="mailSMTP"
              maxlength="50"
              :state="errors.length > 0 ? false:null"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <label for="mail-port-input">{{ $t('mailAccount.port_name') }}</label>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              :name=" $t('mailAccount.port_name')"
              rules="required|between:1,1000"
            >
            <b-form-input
              size="sm"
              id="mail-port-input"
              type="number"
              v-model="mailPort"
              maxlength="1000"
              :state="errors.length > 0 ? false:null"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <label for="mail-pass-input">{{ $t('mailAccount.password') }}</label>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              :name=" $t('mailAccount.password')"
              rules="required"
            >
            <b-input-group class="input-group-merge">
            <b-form-input
              size="sm"
              id="mail-pass-input"
              v-model="mailPass"
              maxlength="50"
              :type="passwordFieldType"
              :state="errors.length > 0 ? false:null"
            />
              <b-input-group-append is-text>
                <feather-icon
                style="height: 0.5em;"
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      </b-form>
    </validation-observer>
    </b-modal>
  </div>
</template>
<script>

export default {
  props: {
    getMailAccountList: {type: Function},
  },
  data() {
    return {
      id: 0,
      mailAddress: '',
      displayName: '',
      mailSMTP: '',
      mailPort: 587,
      mailPass: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    showInfo(id) {
      this.$refs['mailAccountFormModal'].show()
      this.id = id
      const data = {id: this.id}
      const controller = {name : 'MailAccount'}
      const payload = {data: data, controller: controller}
      this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
          if(response.data.resultStatus == true){
              this.displayName = response.data.mailAccount.displayName
              this.mailAddress = response.data.mailAccount.mailAddress
              this.mailSMTP = response.data.mailAccount.mailSMTP
              this.mailPort = response.data.mailAccount.mailPort
              this.mailPass = response.data.mailAccount.mailPass
          } else {
              this.$bvModal.hide('mailAccountFormModal')
              this.$WarningAlert(response.data.resultMessage)
          }  
      })  
    },
    addOrUpdate() {
      const data = {id: this.id, displayName: this.displayName, mailAddress: this.mailAddress, mailPass: this.mailPass, mailPort: this.mailPort, mailSMTP: this.mailSMTP}
      const controller = {name : 'MailAccount'}
      const payload = {data: data, controller: controller}
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
              this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                if(response.data.resultStatus == true){
                  this.$bvModal.hide('mailAccountFormModal')
                  this.getMailAccountList()
                  this.$SaveAlert()
                } else {
                  this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
      })
    },
  }
}
</script>
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}
[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}
.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}
[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem!important;
    margin-top: -1.5rem!important;
    background-color: #ea5455!important;
    border-radius: 0.358rem!important;
    left: 20px!important
}
</style>
