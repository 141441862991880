<template>
<div>
    <!-- modal -->
    <b-modal id="trademarkObjectionToUsFormModal" centered size="xl" no-close-on-backdrop ref="trademarkObjectionToUsFormModal" :title=" $t('trademarkObjectionToUs.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-date">{{ $t('trademarkObjectionToUs.date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-notification-date">{{ $t('trademarkObjectionToUs.notification_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="notificationDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-opposing-view-warning-date">{{ $t('trademarkObjectionToUs.opposing_view_warning_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="opposingViewWarningDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-opposing-view-deadline">{{ $t('trademarkObjectionToUs.opposing_view_deadline') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="opposingViewDeadline"></CustomDatePicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-term-of-litigation-warning-date">{{ $t('trademarkObjectionToUs.term_of_litigation_warning_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="termOfLitigationWarningDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-term-of-litigation-deadline-date">{{ $t('trademarkObjectionToUs.term_of_litigation_deadline_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="termOfLitigationDeadline"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form>
                        <label for="trademark-objection-to-us-case-number-input">{{ $t('trademarkObjectionToUs.case_number') }}</label>
                        <b-form-group>
                            <b-form-input id="trademark-objection-to-us-case-number-input" size="sm" v-model="caseNumber" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-notification-date-to-client">{{ $t('trademarkObjectionToUs.notification_date_to_client') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="notificationDateToClient"></CustomDatePicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-opposing-view-instruction-date">{{ $t('trademarkObjectionToUs.opposing_view_instruction_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="opposingViewInstructionDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-opposing-view-date">{{ $t('trademarkObjectionToUs.opposing_view_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="opposingViewInstructionDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-owner-yidk-date">{{ $t('trademarkObjectionToUs.owner_yidk_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0"  v-model="ownerYidkDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-yidk-notification-date">{{ $t('trademarkObjectionToUs.yidk_notification_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="yidkNotificationDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-yidk-client-notification-date">{{ $t('trademarkObjectionToUs.yidk_client_notification_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="yidkClientNotificationDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-decision-date">{{ $t('trademarkObjectionToUs.decision_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="decisionDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-yidk-opposing-view-instruction-date">{{ $t('trademarkObjectionToUs.yidk_opposing_view_instruction_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="yidkOpposingViewInstructionDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-yidk-opposing-view-date">{{ $t('trademarkObjectionToUs.yidk_opposing_view_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="yidkOpposingViewDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-case-instruction-date">{{ $t('trademarkObjectionToUs.case_instruction_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="caseInstructionDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-case-instruction-warning-date">{{ $t('trademarkObjectionToUs.case_instruction_warning_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="caseInstructionWarningDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <b-form-group>
                        <label for="trademark-objection-to-us-case-instruction-deadline">{{ $t('trademarkObjectionToUs.case_instruction_deadline') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="caseInstructionDeadline"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <label for="trademark-objection-to-us-status">{{ $t('trademarkObjectionToUs.objection_to_us_status') }}</label>
                    <b-form>
                        <validation-provider :name="$t('trademarkObjectionToUs.objection_to_us_status')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="trademarkObjectionToUsStatusSelect" value-field="id" text-field="name" :options="trademarkObjectionToUsStatusOption" size="sm" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3">
                    <label for="yidk-trademark-objection-to-opposing-view-status">{{ $t('trademarkObjectionToUs.yidk_trademark_objection_to_opposing_view_status') }}</label>
                    <b-form>
                        <validation-provider :name="$t('trademarkObjectionToUs.yidk_trademark_objection_to_opposing_view_status')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="yidkTrademarkObjectionToOpposingViewStatusSelect" value-field="id" text-field="name" :options="yidkTrademarkObjectionToOpposingViewStatusOption" size="sm" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <label for="trademark-objection-to-opposing-view-status">{{ $t('trademarkObjectionToUs.to_opposing_view_status') }}</label>
                    <b-form>
                        <validation-provider :name="$t('trademarkObjectionToUs.to_opposing_view_status')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="trademarkObjectionToOpposingViewStatusSelect" value-field="id" text-field="name" :options="trademarkObjectionToOpposingViewStatusOption" size="sm" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <label for="trademark-objection-to-us-yidk-status">{{ $t('trademarkObjectionToUs.owner_trademark_objection_to_us_yidk_status') }}</label>
                    <b-form>
                        <validation-provider :name="$t('trademarkObjectionToUs.owner_trademark_objection_to_us_yidk_status')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="ownerTrademarkObjectionToUsYidkStatusSelect" value-field="id" text-field="name" :options="ownerTrademarkObjectionToUsYidkStatusOption" size="sm" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <label for="yidk-trademark-objection-to-us-decision">{{ $t('trademarkObjectionToUs.yidk_trademark_objection_to_us_decision') }}</label>
                    <b-form>
                        <validation-provider :name="$t('trademarkObjectionToUs.yidk_trademark_objection_to_us_decision')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="yidkTrademarkObjectionToUsDecisionSelect" value-field="id" text-field="name" :options="yidkTrademarkObjectionToUsDecisionOption" size="sm" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-3 mt-1">
                    <label for="trademark-objection-to-us-case-decision">{{ $t('trademarkObjectionToUs.objection_to_us_case_decision') }}</label>
                    <b-form>
                        <b-form-select v-model="trademarkObjectionToUsCaseDecisionSelect" value-field="id" text-field="name" :options="trademarkObjectionToUsCaseDecisionOption" size="sm" />
                    </b-form>
                </b-col>
                <b-col class="col-md-3 mt-1">
                    <label for="trademark-objection-to-us-decision">{{ $t('trademarkObjectionToUs.objection_to_us_decision') }}</label>
                    <b-form>
                        <validation-provider :name="$t('trademarkObjectionToUs.objection_to_us_decision')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false : null" v-model="trademarkObjectionToUsDecisionSelect" value-field="id" text-field="name" :options="trademarkObjectionToUsDecisionOption" size="sm" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>

            </b-row>
            <b-row>
                <b-col class="col-md-6 mt-1">
                    <label for="trademark-objection-to-us-owner-input">{{ $t('trademarkObjectionToUs.owner') }}</label>
                    <b-form-textarea id="trademark-objection-to-us-owner-input" :placeholder="$t('trademarkObjectionToUs.owner')" v-model="owner" rows="2" maxlength="200" />
                </b-col>
                <b-col class="col-md-6 mt-1">
                    <label for="trademark-objection-to-us-ground-of-objection-input">{{ $t('trademarkObjectionToUs.ground_of_objection') }}</label>
                    <b-form-textarea id="trademark-objection-to-us-ground-of-objection-input" :placeholder="$t('trademarkObjectionToUs.ground_of_objection')" v-model="groundOfObjection" rows="2" maxlength="200" />
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getTrademarkObjectionToUsList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            trademarkId: 0,
            trademarkObjectionToUsStatusSelect: 0,
            trademarkObjectionToUsStatusOption: [],
            trademarkObjectionToOpposingViewStatusSelect: 0,
            trademarkObjectionToOpposingViewStatusOption: [],
            ownerTrademarkObjectionToUsYidkStatusSelect: 0,
            ownerTrademarkObjectionToUsYidkStatusOption: [],
            trademarkObjectionToUsCaseDecisionSelect: 0,
            trademarkObjectionToUsCaseDecisionOption: [],
            trademarkObjectionToUsDecisionSelect: 0,
            trademarkObjectionToUsDecisionOption: [],
            yidkTrademarkObjectionToOpposingViewStatusSelect: 0,
            yidkTrademarkObjectionToOpposingViewStatusOption: [],
            yidkTrademarkObjectionToUsDecisionSelect: 0,
            yidkTrademarkObjectionToUsDecisionOption: [],
            owner: '',
            groundOfObjection: '',
            date: null,
            notificationDate: null,
            opposingViewWarningDate: null,
            opposingViewDeadline: null,
            termOfLitigationWarningDate: null,
            termOfLitigationDeadline: null,
            caseNumber: null,
            notificationDateToClient: null,
            opposingViewInstructionDate: null,
            opposingViewDate: null,
            ownerYidkDate: null,
            yidkNotificationDate: null,
            yidkClientNotificationDate: null,
            decisionDate: null,
            yidkOpposingViewInstructionDate: null,
            yidkOpposingViewDate: null,
            caseInstructionDate: null,
            caseInstructionWarningDate: null,
            caseInstructionDeadline: null,
        }
    },
    methods: {
        showInfo(id, trademarkId) {
            this.$refs['trademarkObjectionToUsFormModal'].show()
            this.id = id
            this.trademarkId = trademarkId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TrademarkObjectionToUs'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.owner = response.data.trademarkObjectionToUs.owner
                    this.groundOfObjection = response.data.trademarkObjectionToUs.groundOfObjection
                    this.date = response.data.trademarkObjectionToUs.date
                    this.notificationDate = response.data.trademarkObjectionToUs.notificationDate
                    this.opposingViewWarningDate = response.data.trademarkObjectionToUs.opposingViewWarningDate
                    this.opposingViewDeadline = response.data.trademarkObjectionToUs.opposingViewDeadline
                    this.termOfLitigationWarningDate = response.data.trademarkObjectionToUs.termOfLitigationWarningDate
                    this.termOfLitigationDeadline = response.data.trademarkObjectionToUs.termOfLitigationDeadline
                    this.caseNumber = response.data.trademarkObjectionToUs.caseNumber
                    this.notificationDateToClient = response.data.trademarkObjectionToUs.notificationDateToClient
                    this.opposingViewInstructionDate = response.data.trademarkObjectionToUs.opposingViewInstructionDate
                    this.opposingViewDate = response.data.trademarkObjectionToUs.opposingViewDate
                    this.ownerYidkDate = response.data.trademarkObjectionToUs.ownerYidkDate
                    this.yidkNotificationDate = response.data.trademarkObjectionToUs.yidkNotificationDate
                    this.yidkClientNotificationDate = response.data.trademarkObjectionToUs.yidkClientNotificationDate
                    this.decisionDate = response.data.trademarkObjectionToUs.decisionDate
                    this.yidkOpposingViewInstructionDate = response.data.trademarkObjectionToUs.yidkOpposingViewInstructionDate
                    this.yidkOpposingViewDate = response.data.trademarkObjectionToUs.yidkOpposingViewDate
                    this.caseInstructionDate = response.data.trademarkObjectionToUs.caseInstructionDate
                    this.caseInstructionWarningDate = response.data.trademarkObjectionToUs.caseInstructionWarningDate
                    this.caseInstructionDeadline = response.data.trademarkObjectionToUs.caseInstructionDeadline
                    this.trademarkObjectionToUsStatusSelect = response.data.trademarkObjectionToUs.trademarkObjectionToUsStatusId
                    this.trademarkObjectionToUsStatusOption = response.data.trademarkObjectionToUsStatusList
                    this.trademarkObjectionToOpposingViewStatusSelect = response.data.trademarkObjectionToUs.trademarkObjectionToOpposingViewStatusId
                    this.trademarkObjectionToOpposingViewStatusOption = response.data.trademarkObjectionToOpposingViewStatusList
                    this.ownerTrademarkObjectionToUsYidkStatusSelect = response.data.trademarkObjectionToUs.ownerTrademarkObjectionToUsYidkStatusId
                    this.ownerTrademarkObjectionToUsYidkStatusOption = response.data.ownerTrademarkObjectionToUsYidkStatusList
                    if (response.data.trademarkObjectionToUs.trademarkObjectionToUsCaseDecisionId == null) {
                        this.trademarkObjectionToUsCaseDecisionSelect = 0
                    } else {
                        this.trademarkObjectionToUsCaseDecisionSelect = response.data.trademarkObjectionToUs.trademarkObjectionToUsCaseDecisionId
                    }
                    this.trademarkObjectionToUsCaseDecisionOption = response.data.trademarkObjectionToUsCaseDecisionList
                    this.trademarkObjectionToUsDecisionSelect = response.data.trademarkObjectionToUs.trademarkObjectionToUsDecisionId
                    this.trademarkObjectionToUsDecisionOption = response.data.trademarkObjectionToUsDecisionList
                    this.yidkTrademarkObjectionToOpposingViewStatusSelect = response.data.trademarkObjectionToUs.yidkTrademarkObjectionToOpposingViewStatusId
                    this.yidkTrademarkObjectionToOpposingViewStatusOption = response.data.yidkTrademarkObjectionToOpposingViewStatusList
                    this.yidkTrademarkObjectionToUsDecisionSelect = response.data.trademarkObjectionToUs.yidkTrademarkObjectionToUsDecisionId
                    this.yidkTrademarkObjectionToUsDecisionOption = response.data.yidkTrademarkObjectionToUsDecisionList
                    this.yidkTrademarkObjectionToUsDecisionOption.splice(0, 0, this.$nullSelected())
                    this.trademarkObjectionToUsStatusOption.splice(0, 0, this.$nullSelected())
                    this.trademarkObjectionToOpposingViewStatusOption.splice(0, 0, this.$nullSelected())
                    this.ownerTrademarkObjectionToUsYidkStatusOption.splice(0, 0, this.$nullSelected())
                    this.trademarkObjectionToUsCaseDecisionOption.splice(0, 0, this.$nullSelected())
                    this.trademarkObjectionToUsDecisionOption.splice(0, 0, this.$nullSelected())
                    this.yidkTrademarkObjectionToOpposingViewStatusOption.splice(0, 0, this.$nullSelected())

                } else {
                    this.$bvModal.hide('trademarkObjectionToUsFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                trademarkId: this.trademarkId,
                owner: this.owner,
                groundOfObjection: this.groundOfObjection,
                date: this.date,
                notificationDate: this.notificationDate,
                opposingViewWarningDate: this.opposingViewWarningDate,
                opposingViewDeadline: this.opposingViewDeadline,
                termOfLitigationWarningDate: this.termOfLitigationWarningDate,
                termOfLitigationDeadline: this.termOfLitigationDeadline,
                caseNumber: this.caseNumber,
                notificationDateToClient: this.notificationDateToClient,
                opposingViewInstructionDate: this.opposingViewInstructionDate,
                opposingViewDate: this.opposingViewDate,
                ownerYidkDate: this.ownerYidkDate,
                yidkNotificationDate: this.yidkNotificationDate,
                yidkClientNotificationDate: this.yidkClientNotificationDate,
                decisionDate: this.decisionDate,
                yidkOpposingViewInstructionDate: this.yidkOpposingViewInstructionDate,
                yidkOpposingViewDate: this.yidkOpposingViewDate,
                caseInstructionDate: this.caseInstructionDate,
                caseInstructionWarningDate: this.caseInstructionWarningDate,
                caseInstructionDeadline: this.caseInstructionDeadline,
                trademarkObjectionToUsStatusId: this.trademarkObjectionToUsStatusSelect,
                trademarkObjectionToOpposingViewStatusId: this.trademarkObjectionToOpposingViewStatusSelect,
                ownerTrademarkObjectionToUsYidkStatusId: this.ownerTrademarkObjectionToUsYidkStatusSelect,
                trademarkObjectionToUsCaseDecisionId: this.trademarkObjectionToUsCaseDecisionSelect,
                trademarkObjectionToUsDecisionId: this.trademarkObjectionToUsDecisionSelect,
                yidkTrademarkObjectionToOpposingViewStatusId: this.yidkTrademarkObjectionToOpposingViewStatusSelect,
                yidkTrademarkObjectionToUsDecisionId: this.yidkTrademarkObjectionToUsDecisionSelect
            }
            const controller = {
                name: 'TrademarkObjectionToUs'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('trademarkObjectionToUsFormModal')
                            this.getTrademarkObjectionToUsList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    height: 2.7em;
}

[dir] .btn-secondary {
    border: 1px solid #7367f0 !important;
    background-color: transparent !important;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
