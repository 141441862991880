<template>
    <div>
        <patent-form ref="patentForm"></patent-form>               
        <national-patent-form ref="nationalPatentForm"></national-patent-form>               
        <trademark-form ref="trademarkForm"></trademark-form>               
        <trademark-search-form ref="trademarkSearchForm"></trademark-search-form>               
        <trademark-opposition-form ref="trademarkOppositionForm"></trademark-opposition-form>               
        <national-trademark-form ref="nationalTrademarkForm"></national-trademark-form>               
        <design-form ref="designForm"></design-form>               
        <design-opposition-form ref="designOppositionForm"></design-opposition-form>               
        <domain-form ref="domainForm"></domain-form> 
        <domain-opposition-form ref="domainOppositionForm"></domain-opposition-form> 
        <invoice-form :getDailyworkList="getDailyworkList" ref="invoiceForm"></invoice-form>
        <dailywork-form :getDailyworkList="getDailyworkList" ref="dailyworkForm"></dailywork-form>

        <div class="custom-search">
            <b-row>
                <b-col class="col-md-3">
                <label>{{ $t('dailyworkReport.recordNo') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="recordNo" :placeholder="$t('dailyworkReport.recordNo')" type="text" class="d-inline-block" />
                </div>
                </b-col>

                <b-col class="col-md-3">
                    <label>{{ $t('dailyworkReport.employee') }}</label>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="employeeSelect" size="sm" value-field="id" text-field="name" :options="employeeOption" />
                    </div>
                </b-col>
                <b-col class="col-md-3">
                    <label >{{ $t('dailyworkReport.module') }}</label>
                    <div class="d-flex align-items-center">
                        <b-form-select v-model="moduleSelect" size="sm" value-field="id" text-field="name" :options="moduleOption" />
                    </div>
                </b-col>
                <b-col class="col-md-3">
                    <label >{{ $t('dailyworkReport.billingStatus') }}</label>
                    <div class="d-flex align-items-center">
                        <b-form-select  v-model="billingStatusSelect" size="sm" value-field="id" text-field="name" :options="billingStatusOption" />
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-6 mt-1">
                    <label>{{ $t('dailyworkReport.agent') }}</label>
                    <v-select id="agent" v-model="agentSelect" :options="agentOption" @input="setClientId" label="clientName" @search="onSearch">
                    </v-select>
                </b-col>
                <b-col class="col-md-3 mt-1"> </b-col>
                <b-col class="col-md-3 mt-4 custom-search d-flex justify-content-end">
                    <div class="d-flex align-items-right">
                        <b-button style="float:right;"  class="mr-05" size="sm" v-on:keyup.enter="getDailyworkList()" variant="outline-primary" @click="getDailyworkList()">{{$t('dailyworkReport.list')}}</b-button>
                        <b-button variant="outline-primary" class="mr-05" size="sm"  @click.prevent="showInvoiceForm()">  {{$t('dailywork.invoice')}} </b-button>
                        <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{$t('excel.excel') }}</b-button>                    
                    </div>
                </b-col>
            </b-row>
        </div>
        <br/>
        <!-- table -->
        <vue-good-table id="dailyworkReportTable" ref="dailyworkReportTable" :columns="columns" :rows="rows" :sort-options="{
            enabled: false,
          }" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm}" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
              enabled: true,
              perPage: pageLength
            }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Column: Table Column -->
            <template slot="table-column" slot-scope="props">
            </template>
              
            <!-- Row: Table Row -->
            <template slot="table-row" slot-scope="props" >
                
                <span v-if="props.column.field === 'isSelected'">
                    <b-form-checkbox  @change.native="changeChecked(props.row.id, $event.target)" text-field="name" class="demo-inline-spacing"/>
                </span>

                <span v-else-if="props.column.field === 'price'">
                    <span>
                        {{  $formatMoney(props.row.price) }}
                    </span>
                </span>

                <span v-else-if="props.column.field === 'recordNo'" class="text-nowrap">
                <b-badge variant="primary" style="cursor: pointer;" @click="showOperationForm(props.row)">
                    <feather-icon icon="MousePointerIcon" class="mr-25" />
                    <span>{{ props.row.recordNo }}</span>
                </b-badge>
                </span>

                <!-- Column: Process -->
                <span v-else-if="props.column.field === 'process'">
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                        class="btn-icon mb-1 ml-1" size="sm" @click="openDailyworkForm(props.row.id, props.row.moduleId, props.row.recordId,props.row.paymentTypeId)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </span>
            </template>            
    
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                        </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getDailyworkList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
    </template>
    
    <script>
    export default {
        data() {
            return {
                id: 0,
                employeeOption: [],
                employeeSelect: 0,
                moduleOption: [],
                moduleSelect: 0,
                billingStatusOption: [],
                billingStatusSelect: 0,     
                agentSelect: 0,
                agentOption: [],           
                recordNo:'',
                isSpecialInvoice: false,
                clientAccountingId:0,
                columns: [
                    {
                        label: '',
                        field: `isSelected`,
                    },
                    {
                        label: this.$t('dailyworkReport.recordNo'),
                        field: `recordNo`,
                    },
                    {
                        label: this.$t('dailyworkReport.date'),
                        field: `date`,
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('dailyworkReport.recordName'),
                        field: `recordName`,
                    },
                    {
                        label: this.$t('dailyworkReport.description'),
                        field: `description`,
                    },
                    {
                        label: this.$t('dailyworkReport.invoiceAgent'),
                        field: `invoiceClientAccounting`,
                    },
                    {
                        label: this.$t('dailyworkReport.amount'),
                        field: `price`,
                    },
                    {
                        label: this.$t('dailyworkReport.currency'),
                        field: `currency`,
                    },
                    {
                        label: this.$t('dailyworkReport.employee'),
                        field: `employee`,
                    },
                    {
                        label: this.$t('dailyworkReport.billingStatus'),
                        field: `billingStatus`,
                    },
                    {
                        label: 'Process',
                        field: 'process',
                        width: '5em'
                    },
                ],
                rows: [],
                total: 0,
                pageLength: 20,
                pageNo: 1,
                dir: false,
                searchTerm: ''
            }
        },
        mounted() {
            this.billingStatusSelect = this.$route.query.billingStatusId;
            const controller = { 
                name: 'Dailywork',
                actionName:'GetDailyworkReportInfo'
            }
            const payload = { controller: controller}
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.employeeOption = response.data.employeeList
                    this.moduleOption = response.data.moduleList
                    this.billingStatusOption = response.data.billingStatusList
                    this.employeeOption.splice(0, 0, this.$nullSelected())
                    this.moduleOption.splice(0, 0, this.$nullSelected())
                    this.billingStatusOption.splice(0, 0, this.$nullSelected())
                    this.employeeSelect = localStorage.getItem('EmployeeId')
                    
                    this.getDailyworkList()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        methods: {
            openDailyworkForm(id, moduleId, recordId, paymentTypeId){
                this.$refs.dailyworkForm.showInfo(id, moduleId, recordId, paymentTypeId)
            },
            changeChecked(id, target){
                const status = target.checked

                if(status) {

                    var dailyworkControl = this.rows.find(p=> p.id == id)
                    if(dailyworkControl.billingStatusId == 2){
                        this.$WarningAlert(this.$t('others.NotSelectedDoNotInvoice'))
                        target.checked = false;    
                        return
                    }

                    var agentControl = this.rows.filter(p=> p.isSelected == true)

                    if(agentControl.length > 0) {


                        if(agentControl.filter(p => p.invoiceClientAccountingId == dailyworkControl.invoiceClientAccountingId).length == 0){
                            this.$WarningAlert(this.$t('others.NotSameAgentWarning'))
                            target.checked = false;    
                            return
                        }
                    }
                }

                this.rows = this.rows.map((dailyWork) => {
                    if (dailyWork.id === id) {
                        return {
                            ...dailyWork,
                            isSelected : status
                        };
                    }
                    return dailyWork;
                });
            },
            onSearch(search, loading) {
                if (search.length) {
                    loading(true);
                    this.search(loading, search, this);
                }
            },
            search(loading, search, vm) {
                if (search.length > 2) {
                    const data = {
                        clientAccountingId: 0,
                        clientName: search
                    }
                    const controller = {
                        name: 'ClientAccounting',
                        actionName: 'GetClientAccountingByClientName'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        this.agentOption = response.data.clientList
                        loading(false)
                    })
                }
            },
            setClientId(event) {
                this.clientAccountingId = event.clientAccountingId
            },
            getDailyworkList() {
                this.rows = []
                const controller = {
                    name: 'Dailywork',
                    actionName: 'GetDailyworkReport'
                }
                const data = {
                    employeeId: this.employeeSelect,
                    pageLength: this.pageLength,
                    pageNo: this.pageNo,
                    moduleId: this.moduleSelect,
                    recordNo: this.recordNo,
                    billingStatusId: this.billingStatusSelect,
                    invoiceClientAccountingId: this.clientAccountingId
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                let loader = this.$loading.show()
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        this.rows = response.data.dailyWorkList
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                    loader.hide()
                })
                
            },
            showOperationForm(row) {
                    if(row.moduleId == 2)
                    {
                        this.$refs.trademarkForm.showInfo(row.recordId)
                    }
                    else if(row.moduleId == 3)
                    {
                        this.$refs.trademarkSearchForm.showInfo(row.recordId)
                    }
                    else if(row.moduleId == 4)
                    {
                        this.$refs.patentForm.showInfo(row.recordId)
                    }
                    else if(row.moduleId == 5)
                    {
                        this.$refs.designForm.showInfo(row.recordId)
                    }
                    else if(row.moduleId == 6)
                    {
                        this.$refs.trademarkOppositionForm.showInfo(row.recordId)
                    }
                    else if(row.moduleId == 7)
                    {
                        this.$refs.domainForm.showInfo(row.recordId)
                    }
                    else if(row.moduleId == 8)
                    {
                        this.$refs.domainForm.showInfo(row.recordId)
                    }
                    else if(row.moduleId == 9)
                    {
                        this.$refs.designOppositionForm.showInfo(row.recordId)
                    }
                    else if(row.moduleId == 15)
                    {
                        this.$refs.nationalTrademarkForm.showInfo(row.recordId)
                    }
                    else if(row.moduleId == 16)
                    {
                        this.$refs.nationalPatentForm.showInfo(row.recordId)
                    }
            },
            showInvoiceForm(){
                var dailyworks = this.rows.filter(i => i.isSelected === true);
                if(dailyworks.length == 0){
                    this.$ErrorSaveAlert()
                    return
                }
                this.$refs.invoiceForm.showInfoFromDailyWorkList(dailyworks)
            },
            exportExcel() {
                const controller = {
                    name: 'Dailywork',
                    actionName: 'CanExport'
                }
                const data = {
                    columnList: this.columns,
                    DataList: this.rows
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("Dailywork", response.data.base64, 'xlsx')
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
        },
    }
    </script>
    
    <style lang="scss">
    .excel-css {
        float: right;
        margin-right: 1em;
        width: 9.5em;
        color: #7367f0;
        border: 1px solid #7367f0;
        background-color: transparent;
        margin-left: 1em;
        height: 2em;
        border-radius: 0.358rem;
    }
    
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    </style>
    