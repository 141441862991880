<template>
    <div>
      <patent-update-fields-form :getUpdateFieldsList="getUpdateFieldsList" ref="updateFieldsFormModal"></patent-update-fields-form>
        <b-row>
              <b-col class="col-12">
                  <div style="float:left;" class="custom-search d-flex justify-content-end">
                      <b-form-group>
                          <div class="d-flex align-items-center">
                              <b-col class="col-md-6">
                                <b-form-input size="sm" id="patent-update-fields-agent-name-input" v-model="agent" :placeholder="this.$t('patentUpdateFields.agent_name')" />                     
                              </b-col>
                              <b-col class="col-md-6">
                                  <b-form-input size="sm" id="patent-update-fields-owner-name-input" v-model="owner" :placeholder="this.$t('patentUpdateFields.owner_name')"  />  
                              </b-col>
                              <b-col class="col-md-6">
                                  <b-form-input size="sm" id="patent-update-fields-email-input" v-model="email" :placeholder="this.$t('patentUpdateFields.email')" />
                              </b-col>
                          </div>
                      </b-form-group>
                  </div>
                  <div style="float:right;" class="custom-search d-flex justify-content-end">
                      <b-form-group>
                          <div class="d-flex align-items-center">
                            <b-button style="float:right;" size="sm" class="mr-1" variant="outline-primary" v-on:keyup.enter="getUpdateFieldsList()" @click="getUpdateFieldsList()">{{ $t('patentUpdateFields.list') }}</b-button>     
                            <b-button v-if="this.rowSelection.length != 0" class="mr-1" style="float:right;" size="sm" variant="outline-primary" @click="getInfo(rowSelection)">{{ $t('others.edit') }}</b-button>    
                            <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                          </div>
                      </b-form-group>
                  </div>
              </b-col>
          </b-row>
          <!-- table -->
          <vue-good-table 
          id="updateFieldsTable"
          ref="updateFieldsTable" 
          :columns="columns" 
          :rows="rows" 
          styleClass="vgt-table condensed tableSize"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" 
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" 
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme" @on-selected-rows-change="selectionChanged">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'refCode'" class="text-nowrap">
            {{ $t('patentUpdateFields.folder_no') }}
          </span>
          <span v-else-if="props.column.field === 'title'" class="text-nowrap">
            {{ $t('patentUpdateFields.ref_code') }}
          </span>
          <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
            {{ $t('patentUpdateFields.agent') }}
          </span>
          <span v-else-if="props.column.field === 'owner'" class="text-nowrap">
            {{ $t('patentUpdateFields.owner') }}
          </span>
          <span v-else-if="props.column.field === 'payerInInstallments'" class="text-nowrap">
            {{ $t('patentUpdateFields.email') }}
          </span>
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
              </span>
              <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        pageLength: 10,
        dir: false,
        columns: [
          {
            label: this.$t('patentUpdateFields.folder_no'),
            field: `patentNo`,
          },
          {
            label: this.$t('patentUpdateFields.ref_code'),
            field: 'clientAccounting'
          },
          {
            label: this.$t('patentUpdateFields.agent'),
            field: 'accountingClientName'
          },
          {
            label: this.$t('patentUpdateFields.owner'),
            field: 'patentOwnerClientName'
          },
          {
            label: this.$t('patentUpdateFields.email'),
            field: 'patentResponsibleEmail'
          }
        ],
        rows: [],
        searchTerm: '',
        agent: '',
        owner: '',
        email: '',
        rowSelect: [],
        rowSelection: []
      }
    },
    methods: {
      selectionChanged(params){
        this.rowSelection = params.selectedRows;
      },
      async getInfo(element) {
        this.$refs.updateFieldsFormModal.showInfo(this.rowSelection)
      },
      getUpdateFieldsList() {
        this.rows = []
        const data = {email: this.email, agentName : this.agent , ownerName : this.owner}
        const controller = { name: 'Patent' ,actionName: 'SearchPatentsForUpdate' }
        const payload = { data: data,controller: controller }
        if(this.agent || this.owner || this.email != '') {
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            response.data.patentList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      } else{
        this.$WarningAlert(this.$t('alerts.empty_search'))
      }
      },
      exportExcel() {
              const controller = { name: 'UpdateFields', actionName: 'CanExport'}            
              const data = { columnList : this.columns, DataList : this.rows}
              const payload = { data : data, controller: controller}
              this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                  if (response.data.resultStatus == true) {
                      this.$downloadFile("Departmanlar", response.data.base64, 'xlsx')
                  }
                   else {
                      this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
    },
  }
  </script>
  <style lang="scss" >
  .excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
  </style>