<template>
    <div>
      <!-- modal -->
      <b-modal
        id="mailContactTypeFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="mailContactTypeFormModal"
        :title=" $t('mailContactType.mail_contact_type_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
  
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col>
              <validation-provider
                #default="{ errors }"
                :name=" $t('mailContactType.name')"
                rules="required"
              >
              <label for="contact-type-name-input">{{ $t('mailContactType.name') }}</label>
              <b-form-group>
                <b-form-input
                  id="contact-type-name-input"
                  v-model="mailContactTypeName"
                  maxlength="50"
                  size="sm"
                  :state="errors.length > 0 ? false:null"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
      </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getMailContactTypeList: {type: Function}
    },
    data() {
      return {
        id: 0,
        mailContactTypeName: '',
      }
    },
    methods: {
      showInfo(id) {
        this.$refs['mailContactTypeFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'MailContactType'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.mailContactTypeName = response.data.mailContactType.name
            } else {
                this.$bvModal.hide('mailContactTypeFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, name: this.mailContactTypeName}
        const controller = {name : 'MailContactType'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('mailContactTypeFormModal')
                    this.getMailContactTypeList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  