<template>
    <div>
        <!-- modal -->
        <b-modal id="patentDivisionFormModal" centered size="lg" no-close-on-backdrop ref="patentDivisionFormModal" :title="$t('patentDivision.division_info')"  
        :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" @close="clear()">
            <validation-observer ref="infoRules" tag="form">
                <b-row>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <label for="patentDivision-docNumber-input">{{
                                $t('patentDivision.doc_number') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('patentDivision.doc_number')"
                                    rules="required">
                                    <b-form-input id="patentDivision-docNumber-input" v-model="docNumber" maxlength="11" size="sm"
                                        :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <label for="patentDivision-kind-input">{{
                                $t('patentDivision.kind') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('patentDivision.kind')"
                                    rules="required">
                                    <b-form-input id="patentDivision-kind-input" size="sm" v-model="kind" maxlength="5"
                                        :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <validation-provider :name="$t('patentDivision.country')" #default="{ errors }"
                            :rules="{ excluded: 0 }">
                            <b-form>
                                <label for="country-input">{{ $t('patentDivision.country') }}</label>
                                <b-form-select v-model="countrySelect" value-field="id" text-field="name" size="sm"
                                    :options="countryOption" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form>
                        </validation-provider>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>
 
<script>
export default {
    props: {
        getPatentDivisionList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            patentId: 0,
            countryOption: [],
            countrySelect: 0,
            docNumber: '',
            kind: ''
        }
    },
    methods: {
        showInfo(id, patentId) {
            this.$refs['patentDivisionFormModal'].show()
            this.id = id
            this.patentId = patentId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'PatentDivision'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.docNumber = response.data.patentDivision.docNumber
                    this.countrySelect = response.data.patentDivision.countryId
                    this.countryOption = response.data.countryList
                    this.kind = response.data.patentDivision.kind
                    this.countryOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('patentDivisionFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
            this.docNumber = null
            this.countrySelect = 0
            this.kind = null
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                patentId: this.patentId,
                docNumber: this.docNumber,
                countryId: this.countrySelect,
                kind: this.kind,
            }
            const controller = {
                name: 'PatentDivision'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('patentDivisionFormModal')
                            this.getPatentDivisionList()
                            this.$SaveAlert()
                            this.getPatentList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        }
    },
}
</script>

    
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
