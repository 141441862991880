<template>
<div>
    <!-- modal -->
    <b-modal id="serviceTypeInvoiceHeaderFormModal" centered size="lg" no-close-on-backdrop ref="serviceTypeInvoiceHeaderFormModal" :title=" $t('serviceTypeInvoiceHeader.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="mb-1">
                        <validation-provider :name="$t('serviceTypeInvoiceHeader.service')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <label for="invoice-template-detail-service-input">{{ $t('serviceTypeInvoiceHeader.service') }}</label>
                            <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="serviceSelect" value-field="id" text-field="name" :options="serviceOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <validation-provider :name="$t('serviceTypeInvoiceHeader.invoice_header')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <label for="invoice-template-detail-invoice-header-input">{{ $t('serviceTypeInvoiceHeader.invoice_header') }}</label>
                            <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="invoiceHeaderSelect" value-field="id" text-field="name" :options="invoiceHeaderOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getServiceTypeInvoiceHeaderList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            invoiceHeaderSelect: 0,
            invoiceHeaderOption: [],
            serviceSelect: 0,
            serviceOption: [],
            serviceTypeId: 0,
        }
    },
    methods: {
        showInfo(id, serviceTypeId) {
            this.$refs['serviceTypeInvoiceHeaderFormModal'].show()
            this.id = id
            this.serviceTypeId = serviceTypeId
            const data = {
                id: this.id,
                serviceTypeId: this.serviceTypeId,
            }
            const controller = {
                name: 'ServiceTypeInvoiceHeader'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.invoiceHeaderOption = response.data.invoiceHeaderList
                    this.invoiceHeaderOption.splice(0, 0, this.$nullSelected('name'))
                    if (response.data.serviceTypeInvoiceHeader.invoiceHeaderId > 0) {
                        this.invoiceHeaderSelect = response.data.serviceTypeInvoiceHeader.invoiceHeaderId;
                    }
                    this.serviceOption = response.data.serviceList
                    this.serviceOption.splice(0, 0, this.$nullSelected('name'))
                    if (response.data.serviceTypeInvoiceHeader.serviceId > 0) {
                        this.serviceSelect = response.data.serviceTypeInvoiceHeader.serviceId;
                    }
                } else {
                    this.$bvModal.hide('serviceTypeInvoiceHeaderFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                invoiceHeaderId: this.invoiceHeaderSelect,
                serviceId: this.serviceSelect,
                serviceTypeId: this.serviceTypeId
            }
            const controller = {
                name: 'ServiceTypeInvoiceHeader'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('serviceTypeInvoiceHeaderFormModal')
                            this.getServiceTypeInvoiceHeaderList()
                            this.$SaveAlert()
                            this.invoiceHeaderId = 0,
                            this.serviceId = 0
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-template-detail .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
