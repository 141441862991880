<template>
    <div>
      <national-patent-annuity-form :item="item" :getNationalPatentList="getNationalPatentList" :getNationalPatentAnnuityList="getNationalPatentAnnuityList" ref="nationalPatentAnnuityFormModal"></national-patent-annuity-form>
      <b-row>
        <b-col class="col-12">
          <div style="float:right;" class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-button style="float:right;" size="sm" variant="outline-primary"
                  @click="getInfo(0)">{{ $t('nationalPatentAnnuity.national_patent_annuity_add') }}</b-button>
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table 
      id="nationalPatentAnnuityTable"
      ref="nationalPatentAnnuityTable" 
      :columns="columns" 
      :rows="rows"
      styleClass="vgt-table condensed tableSize"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
        theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'year'" class="text-nowrap">
            {{ $t('nationalPatentAnnuity.year') }}
          </span>
          <span v-else-if="props.column.field === 'patentAnnuityWillPay'" class="text-nowrap">
            {{ $t('nationalPatentAnnuity.national_patent_annuity_will_pay') }}
          </span>
          <span v-else-if="props.column.field === 'date'" class="text-nowrap">
            {{ $t('nationalPatentAnnuity.date') }}
          </span>
          <span v-else-if="props.column.field === 'patentAnnuityStatus'" class="text-nowrap">
            {{ $t('nationalPatentAnnuity.national_patent_annuity_status') }}
          </span>
          <span v-else-if="props.column.field === 'paymentDate'" class="text-nowrap">
            {{ $t('nationalPatentAnnuity.payment_date') }}
          </span>
          <span v-else-if="props.column.field === 'explanation'" class="text-nowrap">
            {{ $t('nationalPatentAnnuity.explanation') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
              class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
              <feather-icon icon="EditIcon" />
            </b-button>
              <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      nationalPatentId: Number,
      getNationalPatentList: {type: Function},
    },
    data() {
      return {
        dir: false,
        columns: [
          {
            label: this.$t('nationalPatentAnnuity.year'),
            field: 'year',
          },
          {
            label : this.$t('nationalPatentAnnuity.national_patent_annuity_will_pay'),
            field : 'patentAnnuityWillPay', 
          },
          {
            label : this.$t('nationalPatentAnnuity.date'),
            field : 'date',
            formatFn: this.$formatFn
          },
          {
            label : this.$t('nationalPatentAnnuity.national_patent_annuity_status'),
            field : 'patentAnnuityStatus',
          },
          {
            label : this.$t('nationalPatentAnnuity.payment_date'),
            field : 'paymentDate',
            formatFn: this.$formatFn
          },
          {
            label : this.$t('nationalPatentAnnuity.explanation'),
            field : 'explanation',
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
        item: 0
      }
    },
    mounted() {
      this.getNationalPatentAnnuityList()
    },
    methods: {
      async deleted(data) {
        data = { id: data.id }
        const controller = { name: 'NationalPatentAnnuity' }
        const payload = { data: data, controller: controller }
        this.$DeleteConfirm().then(result => {
          if (result.value) {
            this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$DeleteAlert()
                this.getNationalPatentAnnuityList()
                this.getNationalPatentList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getNationalPatentAnnuityList()
              }
            })
          }
        })
      },
      async getInfo(id) {
        this.$refs.nationalPatentAnnuityFormModal.showInfo(id, this.nationalPatentId)
      },
      getNationalPatentAnnuityList() {
        this.item = 0
        this.rows = [] 
        const data = { id: this.nationalPatentId }
        const controller = { name: 'NationalPatentAnnuity', actionName:'GetNationalPatentAnnuityListByNationalPatentId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            response.data.nationalPatentAnnuityList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
    },
  }
  </script>
  <style lang="scss" >
    .tableSize {
      font-size: 13px!important;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      table-layout: auto;
  }
  </style>
  