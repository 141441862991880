<template>
  <div>
    <!-- modal -->
    <b-modal
    id="documentTypeDetailFormModal"
    centered
    size="lg"
    no-close-on-backdrop
    ref="documentTypeDetailFormModal"
    :title=" $t('documentType.document_type_detail_info')"
    :cancel-title=" $t('others.cancel_title')"
    :ok-title=" $t('others.ok_title')"
    cancel-variant="outline-secondary"
    @ok.prevent="addOrUpdate"
  >
    <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name=" $t('documentType.name')"
                  rules="required"
                >
                <label for="documentTypeDetail-name-input">{{ $t('documentType.name') }}</label>
                <b-form-input
                  id="documentTypeDetail-name-input"
                  v-model="documentTypeDetailName"
                  size="sm"
                  maxlength="100"
                  :state="errors.length > 0 ? false:null"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>         
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name=" $t('documentType.sequenceNo')"
                  rules="required"
                >
                <label for="documentTypeDetail-sequenceNo-input">{{ $t('documentType.sequence_no') }}</label>
                <b-form-input
                  id="documentTypeDetail-sequenceNo-input"
                  v-model="sequenceNo"
                  size="sm"
                  type="number"
                  oninput="javascript: if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                  onKeyPress="if(this.value.length==3) return false"
                  :state="errors.length > 0 ? false:null"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-row class="mt-1">
               <b-col>
                  <!-- İs isTr  -->
                  <label for="country-isTrContent-input">{{ $t('documentType.is_TR') }}</label>
                    <b-form-checkbox
                      class="custom-control-success"
                      name="country-isTrContent-input"
                      id="country-isTrContent-input"
                      v-model="isTrContent"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                </b-col>
                <b-col>
                <!-- İs isKKTC  -->
                  <label for="country-isKKTCContent-input">{{ $t('documentType.is_KKTC') }}</label>
                    <b-form-checkbox
                      class="custom-control-success"
                      name="country-isKKTCContent-input"
                      id="country-isKKTCContent-input"
                      v-model="isKKTCContent"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
            </b-col>
          </b-row>
    </b-form>
    </validation-observer>  
    </b-modal>  
  </div>
</template>
<script>
export default {
  props: {
    getDocumentTypeDetailList: {type: Function}
  },
  data() {
    return {
      id: 0,
      documentTypeDetailName : '',
      documentTypeDetailList : '',
      isKKTCContent : false,
      isTrContent : false,
      sequenceNo : 0,
      documentTypeId: 0
    }
  },
  methods: {
    showInfo(id, documentTypeId) {
      this.$refs['documentTypeDetailFormModal'].show()
      this.id = id
      this.documentTypeId = documentTypeId
      const data = {id: this.id}
      const controller = {name : 'DocumentTypeDetail'}
      const payload = {data: data, controller: controller}
      this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
      if(response.data.resultStatus == true){
        this.documentTypeDetailName = response.data.documentTypeDetail.name
        this.sequenceNo = response.data.documentTypeDetail.sequenceNo
        this.isKKTCContent = response.data.documentTypeDetail.isKKTCContent
        this.isTrContent = response.data.documentTypeDetail.isTrContent
        } else {
          this.$bvModal.hide('documentTypeDetailFormModal')
          this.$WarningAlert(response.data.resultMessage)
        }  
       })  
  },
    addOrUpdate() {
      const data = {
        id: this.id,
        documentTypeId: this.documentTypeId,
        name : this.documentTypeDetailName,
        sequenceNo : this.sequenceNo,
        isTrContent : this.isTrContent,
        isKKTCContent : this.isKKTCContent
         }
      const controller = {name : 'DocumentTypeDetail'}
      const payload = {data: data, controller: controller}
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
              this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                if(response.data.resultStatus == true){
                  this.getDocumentTypeDetailList()
                  this.$SaveAlert()
                  this.$bvModal.hide('documentTypeDetailFormModal')
                } else {
                  this.$WarningAlert(response.data.resultMessage)
                  this.$bvModal.hide('documentTypeDetailFormModal')
                }
            })
        }
      })
    },
  }
}
</script>
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}
[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}
.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}
[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem!important;
    margin-top: -1.5rem!important;
    background-color: #ea5455!important;
    border-radius: 0.358rem!important;
    left: 20px!important
}
</style>