<template>
    <div>
      <patent-form  ref="patentForm"></patent-form>
      <b-modal id="patentTransactionReportDetailModal" centered size="xl" no-close-on-backdrop ref="patentTransactionReportDetailModal" :title=" $t('patentTransactionReportDetail.modalTitle')" hide-footer>
        
      <div class="custom-search">

        <!--Row 1-->
        <b-row>
          <b-col>            
            <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('recordNo', $event.target.checked)">
              {{ $t('patentTransactionReportDetail.recordNo') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('title', $event.target.checked)">
              {{ $t('patentTransactionReportDetail.title') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('titleEn', $event.target.checked)">
              {{ $t('patentTransactionReportDetail.titleEn') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('agent', $event.target.checked)">
              {{ $t('patentTransactionReportDetail.agent') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('owner', $event.target.checked)">
              {{ $t('patentTransactionReportDetail.owner') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentReferenceNo', $event.target.checked)">
              {{ $t('patentTransactionReportDetail.agentReferenceNo') }}
            </b-form-checkbox>
        </b-col>
        </b-row>
        <!--Row 2-->
      <b-row>
        <b-col>
            <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('applicationNo', $event.target.checked)">
              {{ $t('patentTransactionReportDetail.applicationNo') }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox name="checkbox" @change.native="changeColumns('applicationDate', $event.target.checked)">
              {{ $t('patentTransactionReportDetail.applicationDate') }}
            </b-form-checkbox>
          </b-col>

          <b-col>
          <b-form-checkbox name="checkbox" @change.native="changeColumns('patentNo', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.patentNo') }}
          </b-form-checkbox>
        </b-col>

        <b-col>
          <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('dateOfGrant', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.dateOfGrant') }}
          </b-form-checkbox>
        </b-col>

        <b-col>
          <b-form-checkbox :checked="true" name="checkbox" @change.native="changeColumns('patentHistory.patentStatus', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.patentHistory_patentStatus') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox name="checkbox" @change.native="changeColumns('patentHistory.date', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.patentHistory_date') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <!--Row 3-->
      <b-row>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('ipcCode', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.iPCCode') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('note', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.note') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('dateOfGrantNotification', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.dateOfGrantNotification') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('deadlineForPaymentOfGrantFees', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.deadlineForPaymentOfGrantFees') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('deadline', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.deadline') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('provisionalApplicationDate', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.provisionalApplicationDate') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <!--Row 4-->
      <b-row>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('dateOfWorkingDeclaration', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.dateOfWorkingDeclaration') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('workingDeclarationDeadline', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.workingDeclarationDeadline') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('b2FilingDate', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.b2FilingDate') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('b3FilingDate', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.b3FilingDate') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('epB2PubDate', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.epB2PubDate') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('epB3PubDate', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.epB3PubDate') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <!--Row 5-->
      <b-row>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('epPatentNo', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.epPatentNo') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('epApplicationNo', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.epApplicationNo') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('epApplicationDate', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.epApplicationDate') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('epPublicationNo', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.epPublicationNo') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('epPublicationDate', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.epPublicationDate') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('translator', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.translator') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <!--Row 6-->
      <b-row>

        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('pctApplicationNo', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.pctApplicationNo') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('pctApplicationDate', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.pctApplicationDate') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentAddress.country', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.agentAddress_country') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentAddress.countryCode', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.agentAddress_countryCode') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentAddress.address', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.agentAddress_address') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('agentResponsible.email', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.agentResponsible_email') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <!--Row 7-->
     <b-row>
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('ownerAddress.country', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.ownerAddress_country') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('ownerAddress.countryCode', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.ownerAddress_countryCode') }}
          </b-form-checkbox>
        </b-col>  
        <b-col>
          <b-form-checkbox  name="checkbox" @change.native="changeColumns('ownerAddress.address', $event.target.checked)">
            {{ $t('patentTransactionReportDetail.ownerAddress_address') }}
          </b-form-checkbox>
        </b-col>
        <b-col>
        </b-col>
        <b-col>
        </b-col>
        <b-col>
          <div class="d-flex align-items-right">
          <b-button class="d-inline-block mr-05" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
        </div>
        </b-col>
      </b-row>
    
    </div>
        
        <!-- table -->
      <vue-good-table
        id="patentTransactionReportDetailTable"
        ref="patentTransactionReportDetailTable" 
        :columns="columns"
        :rows="rows"
        styleClass="vgt-table condensed tableSize"
        :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" 
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">

        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'recordNo'">
                <b-row >
                    <b-col class="font-size: x-small">  
                      <b-badge @click="showpatentInfoForm(props.row)" variant="primary" style="cursor: pointer;">
                        <feather-icon icon="MousePointerIcon" class="mr-25" />
                        <span>{{ props.row.recordNo }}</span>
                      </b-badge>                     
                    </b-col>
                </b-row>
            </span>
          </template>
     <!-- pagination -->
     <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
            </span>
            <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      </vue-good-table>
    </b-modal>
    </div>
  </template>
  
  <script>

  export default {
    data() {
      return {
        total: 0,
        pageLength: 50,
        dir: false,
        pageNo: 1,
        columns: [],
        rows: [],
        searchTerm: '',
        isAgent:false,
        isOwner:false,
        name:''
      }
    },
    methods: {
      changeColumns(fieldName, status){
        if(status)
        {
          if(fieldName.indexOf("Date") > -1 || fieldName.indexOf("date") > -1 || fieldName.indexOf("eadline") > -1){
            var item = {
              label: this.$t('patentTransactionReportDetail.XXX'.replace("XXX", fieldName.replace(".", "_"))),
              field: fieldName,
              formatFn: this.$formatFn
            }
          this.columns.push(item);
          }
          else{
            var item = {
              label: this.$t('patentTransactionReportDetail.XXX'.replace("XXX", fieldName.replace(".", "_"))),
              field: fieldName,
            }
          this.columns.push(item);
          }

        }
        else
        {
          const index = this.columns.findIndex(i => i.field === fieldName)
          this.columns.splice(index, 1);
        }
      },
      showpatentInfoForm(row){
          this.$bvModal.show('patentForm')
          this.$refs.patentForm.showInfo(row.id)
      },
      showData(data) {
        this.columns = [
          {
            label: this.$t('patentTransactionReportDetail.recordNo'),
            field: 'recordNo',
          },
          {
            label: this.$t('patentTransactionReportDetail.title'),
            field: 'title',
          },
          {
            label: this.$t('patentTransactionReportDetail.titleEn'),
            field: 'titleEn',
          },
          {
            label: this.$t('patentTransactionReportDetail.agent'),
            field: 'agent',
          },          
          {
            label: this.$t('patentTransactionReportDetail.owner'),
            field: 'owner',
          },
          {
            label: this.$t('patentTransactionReportDetail.applicationNo'),
            field: 'applicationNo',
          },
          {
            label: this.$t('patentTransactionReportDetail.dateOfGrant'),
            field: 'dateOfGrant',
            formatFn: this.$formatFn
          },
          {
            label: this.$t('patentTransactionReportDetail.patentHistory_patentStatus'),
            field: 'patentHistory.patentStatus',
          }
        ]
        this.$refs.patentTransactionReportDetailModal.show()
        this.rows = data
      },
      exportExcel() {
        var controller = {
            name: 'Patent',
            actionName: 'CanExport'
        }

        var data = {
            columnList: this.columns,
            dataList: this.rows
        }
        var payload = {
            data: data,
            controller: controller
        }
        let loader = this.$loading.show()
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
                this.$downloadFile("Patent Listesi", response.data.base64, 'xlsx')
            } else {
                this.$WarningAlert(response.data.resultMessage)
            }
            loader.hide()
        })
      }      
    },
  }
  </script>
<style>
   .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
  