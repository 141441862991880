<template>
    <div>
      <!-- modal -->
      <b-modal id="einvoiceFormModal" centered size="xl" no-close-on-backdrop ref="einvoiceFormModal" :title=" $t('einvoice.info')" hide-footer>
        <div class="custom-search d-flex justify-content-end">
          <b-row>
              <b-col>
                  <b-button variant="outline-primary" size="sm" @click.prevent="addOrUpdate">
                    {{ $t('others.ok_title') }}
                  </b-button>
              </b-col>
          </b-row>
        </div>
        <b-row>
            <b-col class="col-lg-6">
                <label for="einvoice-vkn-input">{{ $t('einvoice.vkn') }}</label>
                <b-form-input disabled id="einvoice-vkn-input" v-model="vkn" size="sm"  maxlength="100"/>
            </b-col>
            <b-col class="col-lg-6">
                <label for="einvoice-name-input">{{ $t('einvoice.name') }}</label>
                <b-form-input disabled id="einvoice-name-input" v-model="name" size="sm"  maxlength="100"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4 mt-1">
                <label for="einvoice-street-input">{{ $t('einvoice.street') }}</label>
                <b-form-input disabled id="einvoice-street-input" v-model="street" size="sm"  maxlength="100"/>
            </b-col>
            <b-col class="col-lg-4 mt-1">
                <label for="einvoice-city-subdivision-input">{{ $t('einvoice.city_subdivision') }}</label>
                <b-form-input disabled id="einvoice-city-subdivision-input" v-model="citySubdivision" size="sm"  maxlength="100"/>
            </b-col>
            <b-col class="col-lg-4 mt-1">
                <label for="einvoice-city-input">{{ $t('einvoice.city') }}</label>
                <b-form-input disabled id="einvoice-city-input" v-model="city" size="sm"  maxlength="100"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <label class="mt-1" for="einvoice-address-input">{{ $t('einvoice.address') }}</label>
                <b-form-textarea disabled id="einvoice-address-input" :placeholder=" $t('einvoice.address')" rows="3" v-model="address" />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12 mt-1">
                <label for="einvoice-seller-account-number-input">{{ $t('einvoice.seller_account_number') }}</label>
                <b-form-input id="einvoice-seller-account-number-input" v-model="sellerAccountNumber" size="sm"  maxlength="100"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12 mt-1 mb-1">
                <label for="einvoice-expense-account-number-input">{{ $t('einvoice.expense_account_number') }}</label>
                <b-form-input id="einvoice-expense-account-number-input" v-model="expenseAccountNumber" size="sm"  maxlength="100"/>
            </b-col>
        </b-row>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab active :title=" $t('einvoice.einvoice_awaiting_approval')" style="width:100%;">
                            <b-card-text>
                                <e-invoice-awaiting-approval-list :statusSelect="this.statusSelect" :incomeInvoiceClientId="this.id" ref="einvoiceAwaitingApprovalList" :getEInvoiceList="getEInvoiceList"></e-invoice-awaiting-approval-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getEInvoiceList: {type: Function},
      statusSelect: {type:Number}
    },
    data() {
      return {
        id: 0,
        clientId: 0,
        vkn: '',
        name: '',
        street: '',
        citySubdivision: '',
        city: '',
        address: '',
        sellerAccountNumber: '',
        expenseAccountNumber: '',
        template: '',
        hiddenControl: true,
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['einvoiceFormModal'].show()
        this.id = id
        if (this.id == 0)
          this.hiddenControl = true
        else this.hiddenControl = false
        const data = {id: this.id}
        const controller = {name : 'IncomeInvoiceClient'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.vkn = response.data.incomeInvoiceClient.vkn
                this.name = response.data.incomeInvoiceClient.name
                this.street = response.data.incomeInvoiceClient.street
                this.citySubdivision = response.data.incomeInvoiceClient.citySubDivision
                this.city = response.data.incomeInvoiceClient.city
                this.address = this.street + ' , ' + this.citySubdivision + ' / ' + this.city
                this.sellerAccountNumber = response.data.incomeInvoiceClient.vendorAccnum
                this.expenseAccountNumber = response.data.incomeInvoiceClient.expenseAccnum
            } else {
                this.$bvModal.hide('einvoiceFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, vendorAccnum: this.sellerAccountNumber, expenseAccnum: this.expenseAccountNumber}
        const controller = {
            name : 'IncomeInvoiceClient',
            actionName: 'UpdateAccnum'
        }
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/customService',payload).then((response) => {
            if(response.data.resultStatus == true){
                this.$bvModal.hide('einvoiceFormModal')
                this.getEInvoiceList()
                this.$SaveAlert()
                if (this.id == 0) {
                    this.hiddenControl = false
                }
            } else {
                this.$WarningAlert(response.data.resultMessage)
            }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  