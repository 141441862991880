<template>
<div>
    <p-d-f-viewer ref="bulletinInfoModal"></p-d-f-viewer>
    <trademark-bulletin-analyse-report-form :getTrademarkBulletinAnalyseReportList="getTrademarkBulletinAnalyseReportList" ref="trademarkBulletinAnalyseForm"></trademark-bulletin-analyse-report-form>
    <b-row>
        <b-col>
            <b-card>
                <b-row>
                    <b-col>
                        <b-card-title style="text-align: right;">{{$t('others.search_criteria')}}</b-card-title>
                    </b-col>
                    <b-col>
                        <b-button style="float:right;" size="sm" variant="outline-primary" v-on:keyup.enter="getTrademarkBulletinAnalyseReportList()" @click="getTrademarkBulletinAnalyseReportList()">{{ $t('translate.list') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <label for="trademarkBulletinAnalyseReport-bulletinNo-select">{{ $t('trademarkBulletinAnalyseReport.bulletin_select') }}</label>
                        <b-form-select size="sm" v-model="bulletinSelect" value-field="id" text-field="no" :options="bulletinOption" />
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="trademarkBulletinAnalyseReport-applicationNo-input">{{ $t('trademarkBulletinAnalyseReport.application_no') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="trademarkBulletinAnalyseReport-applicationNo-input" v-model="applicationNo" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="trademarkBulletinAnalyseReport-trademarkName-input">{{ $t('trademarkBulletinAnalyseReport.trademark_name') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="trademarkBulletinAnalyseReport-trademarkName-input" v-model="name" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="trademarkBulletinAnalyseReport-niceClasses-input">{{ $t('trademarkBulletinAnalyseReport.nice_classes') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="trademarkBulletinAnalyseReport-niceClasses-input" v-model="niceClasses" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="trademarkBulletinAnalyseReport-holder-input">{{ $t('trademarkBulletinAnalyseReport.holder') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="trademarkBulletinAnalyseReport-holder-input" v-model="holderName" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="trademarkBulletinAnalyseReport-attorney-input">{{ $t('trademarkBulletinAnalyseReport.attorney') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="trademarkBulletinAnalyseReport-attorney-input" v-model="attorney" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <label for="trademarkBulletinAnalyseReport-origin-select">{{ $t('trademarkBulletinAnalyseReport.origin') }}</label>
                        <b-form-select size="sm" v-model="originSelect" value-field="id" text-field="name" :options="originOption" />
                    </b-col>
                    <b-col class="col-md-3">
                        <label for="trademarkBulletinAnalyseReport-country-select">{{ $t('trademarkBulletinAnalyseReport.country') }}</label>
                        <b-form-select size="sm" v-model="countrySelect" value-field="id" text-field="name" :options="countryOption" />
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="trademarkBulletinAnalyseReportTable" ref="trademarkBulletinAnalyseReportTable" :columns="columns" :rows="rows"
                    styleClass="vgt-table condensed tableSize" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                  }" :select-options="{
                    enabled: false,
                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                  }" :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                  }" theme="my-theme">
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field ==='process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'holderList'">
                <b-row v-for="trademarkHolder in props.row.holderList" v-bind:key="trademarkHolder.id" style="font-size: x-small;">
                    <tr  v-if="props.row.holderList.length != 1">
                        <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                        <span style=" font-size: small" class="mb-05 text-nowrap">
                            {{trademarkHolder.name }}<br>
                            <tr v-if="trademarkHolder.address !== ''">
                                {{trademarkHolder.address}}
                            </tr>
                        </span>
                    </tr>
                    <tr  v-else-if="props.row.holderList.length == 1">
                        <span style=" font-size: small" class="mb-05 text-nowrap">
                            {{trademarkHolder.name }}<br>
                            <tr v-if="trademarkHolder.address !== ''">
                                {{trademarkHolder.address}}
                            </tr>
                        </span>
                    </tr>
                </b-row>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title=" $t('others.show')" v-b-modal.modal-top variant="info" 
                    class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.bulletin')" variant="warning" class="btn-icon mb-1 ml-1"
                    size="sm" @click="openBulletin(props.row.bulletinNo, props.row.bulletinPage, 1)">
                        <feather-icon icon="BoldIcon" />
                  </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                    </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getTrademarkBulletinAnalyseReportList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [{
                    label: this.$t("trademarkBulletinAnalyseReport.trademark_name"),
                    field: "name",
                },
                {
                    label: this.$t("trademarkBulletinAnalyseReport.application_no"),
                    field: "applicationNo",
                },
                {
                    label: this.$t("trademarkBulletinAnalyseReport.application_date"),
                    field: `applicationDate`,
                    formatFn: this.$formatFn,
                },
                {
                    label: this.$t("trademarkBulletinAnalyseReport.bulletin_no"),
                    field: "bulletinNo",
                },
                {
                    label: this.$t("trademarkBulletinAnalyseReport.holder_name"),
                    field: "holderList",
                },
                {
                    label: this.$t("trademarkBulletinAnalyseReport.attorney"),
                    field: "attorney",
                },
                {
                    label: "Process",
                    field: "process",
                    width: "10em",
                },
            ],
            rows: [],
            bulletinType: 3,
            searchTerm: "",
            bulletinOption: [],
            bulletinSelect: 0,
            name: null,
            niceClasses: null,
            attorney: null,
            holderName: null,
            originSelect: 0,
            applicationNo : null,
            pageNo: 1,
            pageLength: 10,
            total: 0,
            domestic: false,
            abroad: false,
            countryOption: [],
            countrySelect: 0,
            originOption: [{
                    id: 0,
                    name: this.$t('translate.select')
                },
                {
                    id: 1,
                    name: this.$t('trademarkBulletinAnalyseReport.domestic')
                },
                {
                    id: 2,
                    name: this.$t('trademarkBulletinAnalyseReport.abroad')
                },
            ],

        };
    },
    mounted() {
        this.getTrademarkBulletinAnalyseReportList();
    },
    methods: {
        async openBulletin(bulletinNo, bulletinPage){
            var bulletinString = bulletinNo.toString()
            this.$refs.bulletinInfoModal.showInfo(bulletinString, bulletinPage, this.bulletinType)
        },
        async getInfo(id) {
            this.$refs.trademarkBulletinAnalyseFormModal.showInfo(id);
        },
        getTrademarkBulletinAnalyseReportList() {
            if (this.originSelect == 1) {
                this.domestic = true;
                this.abroad = false
            } else if (this.originSelect == 2) {
                this.abroad = true;
                this.domestic = false;

            }
            const data = {
                bulletinId: this.bulletinSelect,
                productName: this.name,
                countryId: this.countrySelect,
                niceClasses: this.niceClasses,
                attorney: this.attorney,
                trademarkInventer: this.inventer,
                abroad: this.abroad,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                domestic: this.domestic,
                applicationNo: this.applicationNo,
                holderName: this.holderName
            }
            this.rows = [];
            const controller = {
                name: "Bulletin",
                actionName: 'GetTrademarkBulletinAnalyseReport'
            }
            const payload = {
                data: data,
                controller: controller,
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.trademarkBulletinAnalyseList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.total = response.data.total
                    this.bulletinOption = response.data.trademarkBulletinList
                    this.countryOption = response.data.countryList
                    this.bulletinOption.splice(0, 0, this.$nullSelected('no'))
                    this.countryOption.splice(0, 0, this.$nullSelected())

                }
            })

        },
    },
};
</script>

<style lang="scss">
.tableSize {
    font-size: 12px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
