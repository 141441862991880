<template>
    <div>
      <!-- modal -->
      <b-modal
        id="serviceFormModal"
        centered
        size="xl"
        no-close-on-backdrop
        ref="serviceFormModal"
        :title=" $t('service.service_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
      <validation-observer ref="simpleRules">
        <b-form>
        <b-row>
          <b-col class="col-md-2">
            <label for="service-code-input">{{ $t('service.code') }}</label>
            <b-form-group>
            <validation-provider
              #default="{ errors }"
              :name=" $t('service.code')"
              rules="required"
            >
            <b-form-input
              id="service-code-input"
              v-model="serviceCode"
              maxlength="15"
              size="sm"
              :state="errors.length > 0 ? false:null"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          </b-col>
          <b-col class="col-md-10">
            <label for="service-name-input">{{ $t('service.name') }}</label>
          <b-form-group>
          <validation-provider
            #default="{ errors }"
            :name=" $t('service.name')"
            rules="required"
          >
          <b-form-input
            id="service-name-input"
            v-model="serviceName"
            maxlength="400"
            size="sm"
            :state="errors.length > 0 ? false:null"
          />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
          </b-col>
          </b-row>
          <b-row>
          <b-col class="col-md-2">
            <label for="service-tpmk-code-input">{{ $t('service.tpmk_code') }}</label>
          <b-form-group>
          <validation-provider
            #default="{ errors }"
            :name=" $t('service.tpmk_code')"
            rules="required"
          >
          <b-form-input
            id="service-tpmk-code-input"
            v-model="serviceTpmkCode"
            maxlength="10"
            size="sm"
            :state="errors.length > 0 ? false:null"
          />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
          </b-col>
          <b-col class="col-md-10">
            <label for="service-tpmk-name-input">{{ $t('service.tpmk_name') }}</label>
          <b-form-group>
          <validation-provider
            #default="{ errors }"
            :name=" $t('service.tpmk_name')"
            rules="required"
          >
          <b-form-input
            id="service-tpmk-name-input"
            v-model="serviceTpmkName"
            maxlength="100"
            size="sm"
            :state="errors.length > 0 ? false:null"
          />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-md-2">
            <label for="service-ledes-code-input">{{ $t('service.ledes_code') }}</label>
          <b-form-group>
          <validation-provider
            #default="{ errors }"
            :name=" $t('service.ledes_code')"
            rules="required"
          >
          <b-form-input
            id="service-ledes-code-input"
            v-model="serviceLedesCode"
            maxlength="10"
            size="sm"
            :state="errors.length > 0 ? false:null"
          />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <label for="service-costing-type-input">{{ $t('service.costing_type') }}</label>
        <b-form-group>
        <validation-provider
            :name=" $t('service.costing_type')"
            :rules="{ excluded:0}" 
            v-slot="{ errors }"
          >
        <b-form-select
            :state="errors.length > 0 ? false:null"
            v-model="costingTypeSelect"
            value-field="id"
            text-field="name"
            size="sm"
            :options="costingTypeOption"
            />
            <small class="text-danger">{{ errors[0] }}</small>   
          </validation-provider>
        </b-form-group> 
          </b-col>
          <b-col class="col-md-3">
            <label for="service-payment-type-input">{{ $t('service.payment_type') }}</label>
        <b-form-group>
        <validation-provider
            :name=" $t('service.payment_type')"
            :rules="{ excluded:0}" 
            v-slot="{ errors }"
          >
        <b-form-select
            :state="errors.length > 0 ? false:null"
            v-model="paymentTypeSelect"
            value-field="id"
            text-field="name"
            size="sm"
            :options="paymentTypeOption"
            />
            <small class="text-danger">{{ errors[0] }}</small>   
          </validation-provider>
        </b-form-group> 
          </b-col>
          <b-col class="col-md-4">
            <label for="service-service-type-input">{{ $t('service.service_type') }}</label>
        <b-form-group>
            <validation-provider
            :name=" $t('service.service_type')"
            :rules="{ excluded:0}" 
            v-slot="{ errors }"
          >
        <b-form-select
            :state="errors.length > 0 ? false:null"
            v-model="serviceTypeSelect"
            value-field="id"
            text-field="name"
            size="sm"
            :options="serviceTypeOption"
            />
            <small class="text-danger">{{ errors[0] }}</small>   
          </validation-provider>
        </b-form-group> 
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-md-2">
            <label for="service-jurisdiction-input">{{ $t('service.jurisdiction') }}</label>
        <b-form-group>
        <validation-provider
            :name=" $t('service.jurisdiction')"
            :rules="{ excluded:0}" 
            v-slot="{ errors }"
          >
        <b-form-select
            :state="errors.length > 0 ? false:null"
            v-model="jurisdictionSelect"
            value-field="id"
            text-field="name"
            size="sm"
            :options="jurisdictionOption"
            />
            <small class="text-danger">{{ errors[0] }}</small>   
          </validation-provider>
        </b-form-group> 
          </b-col>
          <b-col class="col-md-2">
            <label for="service-sequenceNo-input">{{ $t('service.sequenceNo') }}</label>
          <b-form-group>
          <b-form-input
            id="service-sequenceNo-input"
            v-model="sequenceNo"
            type="number"
            maxlength="3"
            size="sm"
          />
        </b-form-group> 
          </b-col>
          <b-col class="col-lg-2 mt-1">
                <label for="service-is-show-in-report-input">{{ $t('service.is_show_in_report') }}</label>
                <br>
                <b-form-checkbox class="custom-control-success" name="is-show-in-report-input" id="is-show-in-report-input" v-model="isShowInReport" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
            <b-col class="col-lg-2 mt-1">
                <label for="service-is-vat-input">{{ $t('service.is_vat') }}</label>
                <br>
                <b-form-checkbox class="custom-control-success" name="is-vat-input" id="is-vat-input" v-model="isVat" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
        </b-row>
      </b-form>
    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getServiceList: {type: Function}
    },
    data() {
      return {
        id: 0,
        serviceName: '',
        serviceCode: '',
        serviceLedesCode: '',
        serviceTpmkCode: '',
        serviceTpmkName: '',
        serviceTypeSelect: 0,
        serviceTypeOption: [],
        paymentTypeSelect: 0,
        paymentTypeOption: [],
        costingTypeSelect: 0,
        costingTypeOption: [],
        jurisdictionSelect: 0,
        sequenceNo: 0,
        jurisdictionOption: [],
        isVat: false,
        isShowInReport: false,
      }
    },
    methods: {
      clear() {
      this.serviceTypeSelect = 0
      this.paymentTypeSelect = 0
      this.costingTypeSelect = 0
      this.jurisdictionSelect = 0
    },
      showInfo(id) {
        this.$refs['serviceFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'Service'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.serviceName = response.data.service.name
                this.serviceCode = response.data.service.code
                this.serviceLedesCode = response.data.service.ledesCode
                this.serviceTpmkCode = response.data.service.tpmkCode
                this.serviceTpmkName = response.data.service.tpmkName
                this.serviceTypeOption = response.data.serviceTypeList
                this.serviceTypeSelect = response.data.service.serviceTypeId
                this.paymentTypeOption = response.data.paymentTypeList
                this.paymentTypeSelect = response.data.service.paymentTypeId
                this.costingTypeOption = response.data.costingTypeList
                this.costingTypeSelect = response.data.service.costingTypeId
                this.jurisdictionOption = response.data.jurisdictionList
                this.jurisdictionSelect = response.data.service.jurisdictionId
                this.isVat = response.data.service.isVat
                this.sequenceNo = response.data.service.sequenceNo
                this.isShowInReport = response.data.service.isShowInReport
                this.serviceTypeOption.splice(0, 0, this.$nullSelected())
                this.paymentTypeOption.splice(0, 0, this.$nullSelected())
                this.jurisdictionOption.splice(0, 0, this.$nullSelected())
                this.costingTypeOption.splice(0, 0, this.$nullSelected())
            } else {
                this.$bvModal.hide('serviceFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {
          id: this.id,
          name: this.serviceName,
          serviceTypeId: this.serviceTypeSelect,
          paymentTypeId: this.paymentTypeSelect,
          costingTypeId: this.costingTypeSelect,
          jurisdictionId: this.jurisdictionSelect,
          code: this.serviceCode,
          ledesCode: this.serviceLedesCode,
          tpmkCode: this.serviceTpmkCode,
          tpmkName: this.serviceTpmkName,
          isVat: this.isVat,
          isShowInReport: this.isShowInReport,
          sequenceNo: this.sequenceNo
        }
        const controller = {name : 'Service'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('serviceFormModal')
                    this.getServiceList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>