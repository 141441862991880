<template>
<div>
    <p-d-f-viewer ref="bulletinInfoModal"></p-d-f-viewer>
    <!-- table -->
    <vue-good-table id="patentBulletinReviewTable" ref="patentBulletinReviewTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'bulletinNo'" class="text-nowrap">
                {{ $t('patentBulletinReview.bulletin_no') }}
            </span>
            <span v-else-if="props.column.field === 'bulletinDate'" class="text-nowrap">
                {{ $t('patentBulletinReview.bulletin_date') }}
            </span>
            <span v-else-if="props.column.field === 'patentPublicationType'" class="text-nowrap">
                {{ $t('patentBulletinReview.publication_type') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.bulletin')" v-b-modal.modal-top variant="warning" class="btn-icon ml-1" size="sm" @click="openBulletin(props.row.bulletinNo, props.row.bulletinPage, 2)">
                        <feather-icon icon="BoldIcon" />
                    </b-button>
                </span>
            </span>
        </template>
    </vue-good-table>
</div>
</template>

  
<script>
export default {
    props: {
        patentId: Number,
        getPatentList: {
            type: Function
        },
    },
    data() {
        return {
            dir: false,
            columns: [{
                    label: this.$t('patentBulletinReview.bulletin_no'),
                    field: 'bulletinNo',
                },
                {
                    label: this.$t('patentBulletinReview.bulletin_date'),
                    field: 'bulletinDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('patentBulletinReview.publication_type'),
                    field: `patentPublicationType`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getPatentBulletinReviewList()
    },
    methods: {
        async openBulletin(bulletinNo, bulletinPage, bulletinType) {
            this.$refs.bulletinInfoModal.showInfo(bulletinNo, bulletinPage, bulletinType)
        },
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'Bulletin'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getPatentBulletinReviewList()
                            this.getPatentList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getPatentBulletinReviewList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.patentBulletinReviewFormModal.showInfo(id, this.patentId)
        },
        getPatentBulletinReviewList() {
            this.rows = []
            const data = {
                id: this.patentId
            }
            const controller = {
                name: 'Bulletin',
                actionName: 'GetPatentBulletinPublicationListByPatentId'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    if (response.data.patentBulletinPublicationList != null) {
                        response.data.patentBulletinPublicationList.forEach(element => {
                            this.rows.push(element)
                        })
                    }
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    },
}
</script>
  
<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
