<template>
<div>
    <!-- modal -->
    <b-modal id="jobFormModal" centered size="xl" no-close-on-backdrop ref="jobFormModal" :title=" $t('job.job_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-12">
                    <label for="job-status-path-input">{{ $t('job.job_status_path') }}</label>
                    <b-form>
                        <validation-provider :name="$t('job.job_status_path')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false : null" @change="jobStatusSelector()" size="sm" v-model="jobStatusPathSelect" value-field="id" text-field="name" :options="jobStatusPathOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row v-if="jobStatusPathSelect > 0">
                <b-col class="col-lg-12 mt-1">
                    <label for="job-status-relation-input">{{ $t('job.job') }}</label>
                    <b-form>
                        <validation-provider :name="$t('job.job')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false : null" @change="giveDates()" size="sm" v-model="jobStatusRelationSelect" value-field="id" text-field="name" :options="jobStatusRelationOption"> </b-form-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col class="col-lg-12 mt-1">
                    <label for="job-status-relation-input">{{ $t('job.job') }}</label>
                    <b-form>
                        <validation-provider :name="$t('job.job')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select disabled :state="errors.length > 0 ? false : null" @change="giveDates()" size="sm" v-model="jobStatusRelationSelect" value-field="id" text-field="name" :options="jobStatusRelationOption"> </b-form-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3 mt-1">
                    <label for="job-date-input">{{ $t('job.date') }}</label>
                    <b-form>
                        <CustomDatePicker :datePickerValidateOption="1" v-model="date"></CustomDatePicker>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3 mt-1">
                    <b-form>
                        <label for="job-next-job-deadline-input">{{ $t('job.next_job_deadline') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="nextJobDeadline"></CustomDatePicker>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3 mt-1">
                    <b-form>
                        <label for="job-tpmk-deadline-input">{{ $t('job.tpmk_deadline') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="tpmkDeadline"></CustomDatePicker>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3 mt-1">
                    <b-form>
                        <label for="job-document-no-input">{{ $t('job.document_no') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="job-document-no-input" v-model="documentNo" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12 mt-1">
                    <label for="job-employee-input">{{ $t('job.employee') }}</label>
                    <b-form>
                        <validation-provider :name="$t('job.employee')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false : null" size="sm" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12 mt-1">
                    <b-form>
                        <label for="job-note-input">{{ $t('job.note') }}</label>
                        <b-form-textarea size="sm" id="job-note-input" v-model="note" rows="3" max-rows="8" maxlength="250" />
                    </b-form>
                </b-col>
            </b-row>
            <b-card :hidden="hiddenControl">
                <b-row>
                    <b-col class="mt-2">
                        <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                            <b-tab active :title=" $t('job.history')" style="width:100%;">
                                <b-card-text>
                                    <job-history-list :getJobList="getJobList" :jobId="this.id"></job-history-list>
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-col>
                </b-row>
            </b-card>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>    
export default {
    props: {
        getJobList: {
            type: Function
        },
        recordId: {
            type: Number
        }
    },
    data() {
        return {
            id: 0,
            moduleId: 0,
            documentId:0,
            jobId: 0,
            jobStatusPathId: 0,
            jobStatusPathOption: [],
            jobStatusPathSelect: 0,
            jobStatusRelationOption: [],
            jobStatusRelationSelect: 0,
            date: null,
            nextJobDeadline: null,
            tpmkDeadline: null,
            documentNo: '',
            employeeOption: [],
            employeeSelect: 0,
            note: '',
            hiddenControl: true,
            nextJobReminderTimeType: '',
            tpmkReminderTimeType: '',
            nextJobReminderValue: 0,
            tpmkReminderValue: 0,
            tpmkStartDate: null,
        }
    },
    methods: {
        jobStatusSelector(){
            this.jobStatusRelationOption = []
            const data = {moduleId: this.moduleId, jobStatusPathId: this.jobStatusPathSelect}
            const controller = {name : 'JobStatusRelation', actionName: 'GetJobStatusSelectionListByFilter'}
            const payload = {data: data, controller: controller}
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if(response.data.jobStatusSelectionList != 0){
                    response.data.jobStatusSelectionList.forEach(element => {
                        this.jobStatusRelationOption.push(element)
                    })
                    this.jobStatusRelationOption.splice(0, 0, this.$nullSelected())
                }
            }) 
        },
        giveDates(){
            this.nextJobDeadline = null
            this.tpmkDeadline = null
            const data = {jobId : 0, jobStatusRelationId : this.jobStatusRelationSelect , jobHistoryDate : this.date }
            const controller = {name : 'JobHistory', actionName: 'CalculateNextDateByJobStatusRelationId'}
            const payload = {data: data, controller: controller}
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            this.nextJobDeadline = response.data.nextJobReminderDate
            this.tpmkDeadline = response.data.tpmkReminderDate
            }) 
        },
        showInfo(id, moduleId, documentId) {
            this.$refs['jobFormModal'].show()
            if (this.id == 0) {
                this.hiddenControl = true

            } else {
                this.hiddenControl = false
            }
            this.moduleId = moduleId
            this.documentId = documentId
            this.id = id
            const data = {
                id: this.id,
                moduleId: this.moduleId
            }
            const controller = {
                name: 'Job'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.jobStatusPathId = response.data.job.jobStatusPathId
                    this.nextJobReminderTimeType = response.data.jobStatusRelationList.nextJobReminderTimeType
                    this.nextJobReminderValue = response.data.jobStatusRelationList.nextJobReminderValue
                    this.tpmkReminderTimeType = response.data.jobStatusRelationList.tpmkReminderTimeType
                    this.tpmkReminderValue = response.data.jobStatusRelationList.tpmkReminderValue
                    this.jobStatusPathOption = response.data.jobStatusPathList
                    this.jobStatusPathSelect = response.data.job.jobStatusPathId
                    if(response.data.job.jobStatusRelationId > 0){
                        this.jobStatusRelationSelect = response.data.job.jobStatusRelationId
                    }
                    if(response.data.job.date != null){
                        this.date = response.data.job.date
                    } else this.date = new Date().toJSON()
                    this.documentNo = response.data.job.documentNo
                    this.employeeOption = response.data.employeeList
                    if(response.data.job.employeeId != 0){
                        this.employeeSelect = response.data.job.employeeId
                    } else this.employeeSelect = localStorage.getItem('EmployeeId')
                    this.note = response.data.job.note
                    this.employeeOption.splice(0, 0, this.$nullSelected())
                    this.jobStatusPathOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('jobFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                job: {                    
                    employeeId: this.employeeSelect,
                    moduleId: this.moduleId,
                    recordId: this.recordId,                    
                },
                jobHistory: {
                    jobStatusPathId: this.jobStatusPathSelect,
                    jobStatusRelationId: this.jobStatusRelationSelect,
                    date: this.date,
                    nextJobDeadline: this.nextJobDeadline,
                    // tpmkStartDate: this.tpmkStartDate,
                    tpmkDeadline: this.tpmkDeadline,
                    note: this.note,
                    documentNo: this.documentNo,
                    employeeId: this.employeeSelect,
                    documentId: this.documentId,
                }
            }
            const controller = {
                name: 'Job'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.jobId = response.data.jobId
                            this.$bvModal.hide('jobFormModal')
                            this.getJobList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.vs__selected-options {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    flex-wrap: wrap;
    position: relative;
    font-size: xx-small;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
