<template>
<div>
    <translator-form ref="translatorFormModal"></translator-form>
    <patent-form ref="patentFormModal"></patent-form>
    <b-row>
        <b-col>
            <b-card>
                <b-row>
                    <b-col>
                        <b-card-title style="text-align: right;">{{$t('others.search_criteria')}}</b-card-title>
                    </b-col>
                    <b-col>
                        <b-button style="float:right;" size="sm" variant="outline-primary" v-on:keyup.enter="getPatentTranslateReportList()" @click="getPatentTranslateReportList()">{{ $t('translate.list') }}</b-button>
                    </b-col>
                </b-row>
                <b-row class="mb-1">
                    <b-col class="col-md-4">
                        <label for="translator-name-input">{{ $t('translate.translator_name') }}</label>
                        <b-form-select size="sm" class="custom-height" v-model="translatorNameSelect" value-field="id" text-field="name" :options="translatorNameOption" />
                    </b-col>
                    <b-col class="col-md-4">
                        <label for="translate-delivery-to-translator-start-date-input">{{ $t('translate.delivery_to_start_translator_date') }}</label>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" size="sm" v-model="deliveryToTranslatorStartDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :placeholder="$t('translate.delivery_to_start_translator_date')" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-col>
                    <b-col class="col-md-4">
                        <label for="translate-delivery-to-translator-end-date-input">{{ $t('translate.delivery_to_translator_end_date') }}</label>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" size="sm" v-model="deliveryToTranslatorEndDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :placeholder="$t('translate.delivery_to_translator_end_date')" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-col>
                </b-row>
                <b-row class="mb-1">
                    <b-col class="col-md-4">
                        <label for="status-input">{{ $t('translate.status') }}</label>
                        <b-form-select size="sm" class="custom-height" v-model="statusSelect" value-field="id" text-field="name" :options="statusOption" />
                    </b-col>
                    <b-col class="col-md-4">
                        <label for="translator-delivery-start-input">{{ $t('translate.translator_delivery_start_date') }}</label>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" size="sm" v-model="translatorDeliveryStartDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :placeholder="$t('translate.translator_delivery_start_date')" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-col>
                    <b-col class="col-md-4">
                        <label for="translator-delivery-end-input">{{ $t('translate.translator_delivery_end_date') }}</label>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" size="sm" v-model="translatorDeliveryEndDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :placeholder="$t('translate.translator_delivery_end_date')" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-4">
                        <label for="payment-status-input">{{ $t('translate.payment_status') }}</label>
                        <b-form-select size="sm" class="custom-height" v-model="paymentStatusSelect" value-field="id" text-field="name" :options="paymentStatusOption" />
                    </b-col>
                    <b-col class="col-md-4">
                        <label for="translator-deadline-start-input">{{ $t('translate.translator_deadline_start_date') }}</label>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" size="sm" v-model="translatorDeadlineStartDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :placeholder="$t('translate.translator_deadline_start_date')" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-col>
                    <b-col class="col-md-4">
                        <label for="translator-deadline-end-input">{{ $t('translate.translator_deadline_end_date') }}</label>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" size="sm" v-model="translatorDeadlineEndDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :placeholder="$t('translate.translator_deadline_end_date')" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="translateReportTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }" :pagination-options="{
          enabled: true,
          perPage: pageLength
        }" theme="my-theme" @on-cell-click="onCellClick">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'translator'" class="text-nowrap">
                {{ $t('translate.translator') }}
            </span>
            <span v-else-if="props.column.field === 'patentRecordNo'" class="text-nowrap">
                {{ $t('translate.folder_no') }}
            </span>
            <span v-else-if="props.column.field === 'deliveryToTranslatorDate'" class="text-nowrap">
                {{ $t('translate.delivery_to_translator_date') }}
            </span>
            <span v-else-if="props.column.field === 'translatorDeliveryDate'" class="text-nowrap">
                {{ $t('translate.translator_delivery_date') }}
            </span>
            <span v-else-if="props.column.field === 'translatorDeadlineDate'" class="text-nowrap">
                {{ $t('translate.translator_deadline_date') }}
            </span>
            <span v-else-if="props.column.field === 'paymentAmount'" class="text-nowrap">
                {{ $t('translate.payment_amount') }}
            </span>
            <span v-else-if="props.column.field === 'paymentStatus'" class="text-nowrap">
                {{ $t('translate.payment_status') }}
            </span>
            <span v-else-if="props.column.field === 'paymentDate'" class="text-nowrap">
                {{ $t('translate.payment_date') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Agent Name -->
            <span v-if="props.column.field === 'translator'" class="text-nowrap">
                <b-badge variant="primary" style="cursor: pointer;">
                    <feather-icon icon="MousePointerIcon" class="mr-25" />
                    <span>{{ props.row.translator }}</span>
                </b-badge>
            </span>
            <span v-else-if="props.column.field === 'patentRecordNo'" class="text-nowrap">
                <b-badge variant="primary" style="cursor: pointer;">
                    <feather-icon icon="MousePointerIcon" class="mr-25" />
                    <span>{{ props.row.patentRecordNo }}</span>
                </b-badge>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.approve')" variant="success" class="btn-icon mb-1 ml-1" size="sm" @click="addOrUpdate(props.row.id)">
                        <feather-icon icon="CheckSquareIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }} </span>
                    <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getPatentTranslateReportList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

  
<script>
export default {
    watch: {
        deliveryToTranslatorStartDate(newValue) {
            if(newValue == ''){
                this.deliveryToTranslatorStartDate = null
            }
        },
        deliveryToTranslatorEndDate(newValue) {
            if(newValue == ''){
                this.deliveryToTranslatorEndDate = null
            }
        },
        translatorDeliveryStartDate(newValue) {
            if(newValue == ''){
                this.translatorDeliveryStartDate = null
            }
        },
        translatorDeliveryEndDate(newValue) {
            if(newValue == ''){
                this.translatorDeliveryEndDate = null
            }
        },
        translatorDeadlineStartDate(newValue) {
            if(newValue == ''){
                this.translatorDeadlineStartDate = null
            }
        },
        translatorDeadlineEndDate(newValue) {
            if(newValue == ''){
                this.translatorDeadlineEndDate = null
            }
        },
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            translatorNameSelect: 0,
            translatorNameOption: [],
            statusSelect: 2,
            statusOption: [{
                    id: 0,
                    name: this.$t('translate.select')
                },
                {
                    id: 1,
                    name: this.$t('translate.delivered')
                },
                {
                    id: 2,
                    name: this.$t('translate.undelivered')
                },
            ],
            deliveryToTranslatorStartDate: null,
            deliveryToTranslatorEndDate: null,
            translatorDeliveryStartDate: null,
            translatorDeliveryEndDate: null,
            translatorDeadlineStartDate: null,
            translatorDeadlineEndDate: null,
            patentRecordNo: '',
            paymentStatusSelect: 0,
            paymentStatusOption: [{
                    id: 0,
                    name: this.$t('translate.select')
                },
                {
                    id: 1,
                    name: this.$t('translate.paid')
                },
                {
                    id: 2,
                    name: this.$t('translate.not_paid')
                },
            ],
            startDate: (new Date()).toJSON(),
            endDate: new Date(),
            columns: [{
                    label: this.$t('translate.translator'),
                    field: `translator`,
                    class: 'cursorPoint',
                },
                {
                    label: this.$t('translate.folder_no'),
                    field: `patentRecordNo`,
                    class: 'cursorPoint'
                },
                {
                    label: this.$t('translate.delivery_to_translator_date'),
                    field: 'deliveryToTranslatorDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('translate.translator_delivery_date'),
                    field: 'translatorDeliveryDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('translate.translator_deadline_date'),
                    field: 'translatorDeadlineDate',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('translate.payment_amount'),
                    field: 'paymentAmount',
                },
                {
                    label: this.$t('translate.payment_status'),
                    field: 'paymentStatus'
                },
                {
                    label: this.$t('translate.payment_date'),
                    field: 'paymentDate',
                    formatFn: this.$formatFn
                },
                {
                    label: 'process',
                    field: 'process',
                },
            ],
            rows: [],
            searchTerm: '',
            total: 0,
            pageLength: 10,
            pageNo: 1,

        }
    },
    mounted() {
        this.getPatentTranslateReportList()
    },
    methods: {
        onCellClick(params) {
            if (params.column.field == "translator") {
                this.$refs.translatorFormModal.showInfo(params.row.translatorId)
            }
            if (params.column.field == "patentRecordNo") {
                this.$refs.patentFormModal.showInfo(params.row.patentId)
            }
        },
        getPatentTranslateReportList() {
            this.rows = []
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                status: this.statusSelect,
                paymentStatus: this.paymentStatusSelect,
                translatorId: this.translatorNameSelect,
                deliveryToTranslatorDateStart: this.$EmptyDateToNull(this.deliveryToTranslatorStartDate),
                deliveryToTranslatorDateEnd: this.$EmptyDateToNull(this.deliveryToTranslatorEndDate),
                translatorDeliveryDateStart: this.$EmptyDateToNull(this.translatorDeliveryStartDate),
                translatorDeliveryDateEnd: this.$EmptyDateToNull(this.translatorDeliveryEndDate),
                translatorDeadlineDateStart: this.$EmptyDateToNull(this.translatorDeadlineStartDate),
                translatorDeadlineDateEnd: this.$EmptyDateToNull(this.translatorDeadlineEndDate)
            }

            const controller = {
                name: 'PatentTranslate',
                actionName: 'GetPatentTranslateList'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    response.data.patentTranslateList.forEach(element => {
                        this.rows.push(element)
                    })

                    this.translatorNameOption = response.data.translatorList
                    this.translatorNameSelect = this.translatorNameSelect
                    this.translatorNameOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate(id) {
            const data = {
                id: id
            }
            const controller = {
                name: 'PatentTranslate',
                actionName: 'CloseTranslateCase'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.getPatentTranslateReportList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    },
}
</script>

  
<style lang="scss">
.tableSize {
    font-size: 12px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.recordCursor {
    cursor: pointer;
}
</style>
