<template>
<div>
    <b-modal id="employeeLanguageFormModal" centered size="lg" no-close-on-backdrop ref="employeeLanguageFormModal" :title=" $t('employeeLanguage.language_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-md-10 mt-2">
                    <b-form>
                        <validation-provider :name=" $t('employeeLanguage.language')" :rules="{ excluded:0}" v-slot="{ errors }">
                            <label for="employee-Language-language-input">{{ $t('employeeLanguage.language') }}</label>
                            <b-form-select :state="errors.length > 0 ? false:null" size="sm" v-model="languageSelect" value-field="id" text-field="name" :options="languageOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-2 mt-2">
                    <label for="employee-language-can-translate-input">{{ $t('employeeLanguage.can_translate') }}</label>
                    <br>
                    <b-form-checkbox class="custom-control-success" name="employee-language-can-translate-input" id="employee-language-can-translate-input" v-model="canTranslate" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getEmployeeLanguageList: {
            type: Function
        }
    },
    data() {
        return {
            languageSelect: 0,
            languageOption: [],
            id: 0,
            employeeId: 0,
            canTranslate: false,
        }
    },
    methods: {
        showInfo(id, employeeId) {
            this.$refs['employeeLanguageFormModal'].show()
            this.id = id
            this.employeeId = employeeId;
            if (this.employeeId == null) {
                this.employeeId = localStorage.getItem("EmployeeId")
            }
            const data = {
                id: this.id
            }
            const controller = {
                name: 'EmployeeLanguage'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.languageOption = response.data.languageList
                    this.languageSelect = response.data.employeeLanguage.languageId
                    this.canTranslate = response.data.employeeLanguage.canTranslate
                    this.languageOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('employeeLanguageFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            if (this.employeeId == null) {
                this.employeeId = localStorage.getItem("EmployeeId")
            }
            const data = {
                id: this.id,
                employeeId: this.employeeId,
                languageId: this.languageSelect,
                canTranslate: this.canTranslate
            }
            const controller = {
                name: 'EmployeeLanguage'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('employeeLanguageFormModal')
                            this.getEmployeeLanguageList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
