<template>
<div>
    <accounting-books-receipt-form :subsidiaryLedgerId="subsidiaryLedgerId" :getAccountingBooksReceiptList="getAccountingBooksReceiptList" :getAccountingBooksList="getAccountingBooksList" ref="accountingBooksReceiptFormModal"></accounting-books-receipt-form>
    <b-row class="custom-search">
        <b-col class="col-md-3 mb-1">
            <label for="accountingTransactionType-input">{{ $t('accountingBooksReceipt.accountingTransactionType') }}</label>
            <b-form>
                <b-form-select id="accountingTransactionType-input" v-model="accountingTransactionTypeSelect" v-on:keyup.enter="getAccountingBooksReceiptList()" size="sm" value-field="id" text-field="name" :options="accountingTransactionTypeOption" />
            </b-form>
        </b-col>
        <b-col class="col-md-3 mb-1">
            <label for="date-start-input">{{ $t('accountingBooksReceipt.date_start') }}</label>
            <b-form>
                <CustomDatePicker :datePickerValidateOption="0" name="date-start-input" v-model="dateStart"></CustomDatePicker>
            </b-form>
        </b-col>
        <b-col class="col-md-3 mb-1">
            <label for="date-end-input">{{ $t('accountingBooksReceipt.date_end') }}</label>
            <b-form>
                <CustomDatePicker :datePickerValidateOption="0" name="date-end-input" v-model="dateEnd"></CustomDatePicker>
            </b-form>
        </b-col>
        <b-col class="col-md-3 mb-1 custom-search d-flex justify-content-end">
            <div class="d-flex align-items-center">
                <b-button class="d-inline-block mr-1" size="sm" variant="outline-primary" v-on:keyup.enter="getAccountingBooksReceiptList()" @click="getAccountingBooksReceiptList()">{{ $t('others.list') }}</b-button>
                <b-button style="float:right;" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('accountingBooksReceipt.add') }}</b-button>
            </div>
        </b-col>
    </b-row>
    <b-row class="custom-search">
        <b-col class="col-md-3 mb-1">
            <label for="document-date-start-input">{{ $t('accountingBooksReceipt.document_date_start') }}</label>
            <b-form>
                <CustomDatePicker :datePickerValidateOption="0" name="document-date-start-input" v-model="documentDateStart"></CustomDatePicker>
            </b-form>
        </b-col>
        <b-col class="col-md-3 mb-1">
            <label for="document-date-end-input">{{ $t('accountingBooksReceipt.document_date_end') }}</label>
            <b-form>
                <CustomDatePicker :datePickerValidateOption="0" name="document-date-end-input" v-model="documentDateEnd"></CustomDatePicker>
            </b-form>
        </b-col>
        <b-col class="col-md-3 mb-1">
            <label for="employee-input">{{ $t('accountingBooksReceipt.employee') }}</label>
            <b-form>
                <b-form-select id="employee-input" v-model="employeeSelect" size="sm" value-field="id" text-field="name" :options="employeeOption" />
            </b-form>
        </b-col>
        <b-col class="col-md-3 mb-1">
            <label for="reference-input">{{ $t('accountingBooksReceipt.reference') }}</label>
            <b-form>
                <b-form-input id="reference-input" size="sm" v-model="reference" :placeholder="$t('accountingBooksReceipt.reference')" type="text" class="d-inline-block" />
            </b-form>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="accountingBooksReceiptTable" ref="accountingBooksReceiptTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'date'" class="text-nowrap">
                {{ $t('accountingBooksReceipt.date') }}
            </span>
            <span v-else-if="props.column.field === 'accountingTransactionType'" class="text-nowrap">
                {{ $t('accountingBooksReceipt.accountingTransactionType') }}
            </span>
            <span v-else-if="props.column.field === 'number'" class="text-nowrap">
                {{ $t('accountingBooksReceipt.number') }}
            </span>
            <span v-else-if="props.column.field === 'reference'" class="text-nowrap">
                {{ $t('accountingBooksReceipt.reference') }}
            </span>
            <span v-else-if="props.column.field === 'description'" class="text-nowrap">
                {{ $t('accountingBooksReceipt.description') }}
            </span>
            <span v-else-if="props.column.field === 'amount'" class="text-nowrap">
                {{ $t('accountingBooksReceipt.amount') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('accountingBooksReceipt.copy')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="copy(props.row.id)">
                        <feather-icon icon="CopyIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getAccountingBooksReceiptList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    props: {
        getAccountingBooksList: {
            type: Function
        },
        subsidiaryLedgerId: {
            type: Number
        }
    },
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            dateStart: null,
            dateEnd: null,
            documentDateStart: null,
            documentDateEnd: null,
            reference: "",
            employeeSelect: 0,
            employeeOption: [],
            accountingTransactionTypeSelect: 0,
            accountingTransactionTypeOption: [],
            columns: [{
                    label: this.$t('accountingBooksReceipt.date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('accountingBooksReceipt.accountingTransactionType'),
                    field: `accountingTransactionType`,
                },
                {
                    label: this.$t('accountingBooksReceipt.number'),
                    field: `number`,
                },
                {
                    label: this.$t('accountingBooksReceipt.reference'),
                    field: `reference`,
                },
                {
                    label: this.$t('accountingBooksReceipt.description'),
                    field: `description`,
                },
                {
                    label: this.$t('accountingBooksReceipt.amount'),
                    field: `amount`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.dateEnd = new Date().toJSON()
        this.getAccountingBooksReceiptList()
    },
    methods: {
        async getInfo(id) {
            this.$refs.accountingBooksReceiptFormModal.showInfo(id)
        },
        getAccountingBooksReceiptList() {
            this.rows = []
            const controller = {
                name: 'AccountingTransaction'
            }
            const data = {
                subsidiaryLedgerId: this.subsidiaryLedgerId,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                dateStart: this.dateStart,
                dateEnd: this.dateEnd,
                documentDateStart: this.documentDateStart,
                documentDateEnd: this.documentDateEnd,
                reference: this.reference,
                employeeId: this.employeeSelect,
                accountingTransactionTypeId: this.accountingTransactionTypeSelect,
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                this.total = response.data.total
                if (response.data.resultStatus == true) {
                    if (this.dateEnd == null) {
                        this.dateEnd = response.data.dateEnd
                    }
                    this.rows = response.data.accountingTransactionList
                    this.employeeOption = response.data.employeeList
                    this.accountingTransactionTypeOption = response.data.accountingTransactionTypeList
                    this.employeeOption.splice(0, 0, this.$nullSelected())
                    this.accountingTransactionTypeOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'AccountingTransaction',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("AccountingBooksReceipt", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        copy(id){
            const controller = {
                name: 'AccountingTransaction',
                actionName: 'CopyAccountingTransactionWithDetails'
            }
            const data = {
                id: id,
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$SaveAlert()
                    this.getAccountingBooksReceiptList()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
