<template>
    <div>
      <!-- modal -->
      <b-modal
        id="updateFieldsFormModal"
        centered
        size="xl"
        no-close-on-backdrop
        ref="updateFieldsFormModal"
        :title=" $t('updateFields.update_fields_change')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @close="clear()"
        @ok.prevent="addOrUpdate"
      >
        <validation-observer ref="simpleRules">
            <h5 class="mt-1">{{ $t('updateFields.agent_information') }}</h5>
            <b-row>
                <b-col class="col-lg-6 mt-1">
                    <b-form>
                        <validation-provider
                            :name=" $t('updateFields.agent')"
                            #default="{ errors }"
                            :rules="{ excluded: 0 }"
                        >
      
                        <b-form-group
                            :label=" $t('updateFields.agent')"
                            label-for="updatefieldsagent"
                            :state="errors.length > 0 ? false:null"
                        >
                            <v-select 
                                id="updatefieldsagent"
                                v-model="updateFieldsAgentSelect"
                                :options="updateFieldsAgentOption"
                                @input="getClientResponsibleList"
                                label="clientName" 
                                @search="onSearchAgent">
                            </v-select>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback> 
                        </b-form-group>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-lg-6 mt-1">
                    <b-form>
                        <validation-provider
                            :name=" $t('updateFields.owner')"
                            #default="{ errors }"
                            :rules="{ excluded: 0 }"
                        >
      
                        <b-form-group
                            :label=" $t('updateFields.owner')"
                            label-for="update-fields-owner"
                            :state="updateFieldsAgentSelect > 0 ? false:true"
                        >
                            <v-select 
                                id="update-fields-owner"
                                v-model="updateFieldsOwnerSelect"
                                :options="updateFieldsOwnerOption"
                                @input="setOwnerId"
                                label="name" 
                                @search="onSearchOwner">
                            </v-select>
                            <b-form-invalid-feedback :state="updateFieldsAgentSelect > 0 ? false:true">
                              {{ errors[0] }}
                            </b-form-invalid-feedback> 
                        </b-form-group>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <h5 class="mt-1">{{ $t('updateFields.related_information') }}</h5>
            <b-row >
                <b-col class="col-lg-12 mt-1">
                    <vue-good-table 
                    id="updateFieldsRelatedTable"
                    ref="updateFieldsRelatedTable" 
                    :columns="columns" 
                    :rows="rows"
                    styleClass="vgt-table condensed tableSize"
                    :select-options="{
                        enabled: true,
                        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }" 
                        theme="my-theme" @on-selected-rows-change="selectionChanged">
                        <div slot="emptystate">
                        {{ $t('dataTable.data_not_found') }}
                        </div>
                        <!-- Row: Table Row -->
                        <template slot="table-column" slot-scope="props">
                        <span v-if="props.column.field === 'responsibleName'" class="text-nowrap">
                            {{ $t('updateFields.responsible_name') }}
                        </span>
                        <span v-else-if="props.column.field === 'email'" class="text-nowrap">
                            {{ $t('updateFields.email') }}
                        </span>
                        <span v-else-if="props.column.field === 'mailContactType'" class="text-nowrap">
                            {{ $t('updateFields.mail_contact_type') }}
                        </span>
                        <span v-else-if="props.column.field === 'responsibleField'" class="text-nowrap">
                            {{ $t('updateFields.responsible_field') }}
                        </span>
                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
      </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getUpdateFieldsList: {type: Function},
    },
    data() {
      return {
        id: 0,
        patentList: [],
        responsibleList : [],
        updateFieldsAgentSelect: 0,
        updateFieldsAgentOption: [],
        updateFieldsOwnerSelect: 0,
        updateFieldsOwnerOption: [],
        ownerId:0,
        columns: [
          {
            label: this.$t('updateFields.responsible_name'),
            field: `responsibleName`,
          },
          {
            label: this.$t('updateFields.email'),
            field: `email`,
          },
          {
            label: this.$t('updateFields.mail_contact_type'),
            field: `mailContactType`,
          },
          {
            label: this.$t('updateFields.responsible_field'),
            field: `responsibleField`,
          },
        ],
        rows: [],
      }
    },
    methods: {
      clear() {
        this.updateFieldsAgentSelect = 0
        this.updateFieldsAgentOption = []
        this.updateFieldsOwnerSelect = 0
        this.updateFieldsOwnerOption = []
        this.rows = []
      },
      showInfo(patentList){
        this.$refs['updateFieldsFormModal'].show()
        this.patentList=patentList
      },
      selectionChanged(params){
        this.responsibleList = params.selectedRows;
      },
      setOwnerId(event){
        this.ownerId =  event.clientId
      },
      onSearchAgent(search, loading) {
      if (search.length) {
        loading(true);
        this.searchAgent(loading, search, this);
      }
    },
    searchAgent(loading, search, vm) {
      if (search.length > 2) {
        const data = { clientAccountingId: 0, clientName: search }
        const controller = { name: 'ClientAccounting', actionName: 'GetClientAccountingByClientName' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          this.updateFieldsAgentOption = response.data.clientList
          loading(false)
        })
      }
    },
      onSearchOwner(search, loading) {
      if (search.length) {
        loading(true);
        this.searchOwner(loading, search, this);
      }
    },
    searchOwner(loading, search, vm) {
      if (search.length > 2) {
        const data = { name: search }
        const controller = { name: 'Client', actionName: 'GetClientByName' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          this.updateFieldsOwnerOption = response.data.clientList
          loading(false)
        })
      }
    },
    getClientResponsibleList() {      
      this.rows = [] 
        const data = { id:this.updateFieldsAgentSelect.clientAccountingId }
        const controller = { name: 'ClientResponsible', actionName: 'GetClientResponsibleListByClientAccountingId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {  
            response.data.clientResponsibleList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
    },
      addOrUpdate() {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
              const patentIdList=[]
              this.patentList.forEach(element => {
                patentIdList.push(element.id)
              })
              const responsibleIdList=[]
              this.responsibleList.forEach(element => {
                responsibleIdList.push(element.id)
              })
                const data = {patentIdList: patentIdList, clientAccountingId: this.updateFieldsAgentSelect.clientAccountingId, clientOwnerId : this.updateFieldsOwnerSelect.id, ClientResponsibleToAddList : responsibleIdList}
                const controller = {name : 'Patent',actionName: 'UpdatePatentFields'}
                const payload = {data: data, controller: controller}
                this.$store.dispatch('moduleAuth/customService',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('updateFieldsFormModal')
                    this.getUpdateFieldsList()
                    this.clear()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  [dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
  </style>
  