<template>
    <div>
        <b-modal
            id="designOppositionResponsibleFormModal"
            centered
            size="lg"
            no-close-on-backdrop
            ref="designOppositionResponsibleFormModal"
            :title=" $t('designOppositionResponsible.responsible_info')"
            :cancel-title=" $t('others.cancel_title')"
            :ok-title=" $t('others.ok_title')"
            cancel-variant="outline-secondary"
            @ok.prevent="addOrUpdate"
            hide-footer
        >
        <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="LockIcon" size="18" />
                <span class="align-middle ml-50">{{  $t('designOppositionResponsible.responsible')  }}</span>
              </b-card-title>
            </b-card-header>
            <!--eslint-disable-->
            <table class="table table-striped table-bordered">
              <tbody>
                <tr v-for="designOppositionResponsibleItem in designOppositionResponsibleList" :key="designOppositionResponsibleItem.id">
                  <td >
                      <b-form-checkbox @change="changeResponsible($event, designOppositionResponsibleItem.id)" />                                 
                  </td>
                  <td>{{  designOppositionResponsibleItem.name  }}</td>

                </tr>
              </tbody>
            </table>
          </b-card>
        </b-modal>
    </div>
</template>
<script>
export default{
  props: {
      getDesignOppositionResponsibleList: {type: Function},
    },
  data(){
    return {
      id: 0,
      designOppositionResponsibleList:[]
    }
  },
  methods:{
    changeResponsible(selected, clientResponsibleId) {
      const controller = { name: 'DesignOppositionResponsible' }
      if (selected == true) { 
        const data = { designOppositionId: this.designOppositionId, clientResponsibleId: clientResponsibleId }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.getDesignOppositionResponsibleList()
            this.$SaveAlert()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
    },
    showInfo(designOppositionId, clientId) {
      this.$refs['designOppositionResponsibleFormModal'].show()
          this.designOppositionId= designOppositionId
          const data = {clientId: clientId,designOppositionId:designOppositionId}
          const controller = {name: 'ClientResponsible', actionName:'GetClientResponsibleListForModules'}
          const payload = {data: data, controller: controller}
          this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
              if(response.data.resultStatus == true){    
                  this.designOppositionResponsibleList = response.data.clientResponsibleList
              } else {
                  this.$bvModal.hide('designOppositionResponsibleFormModal')
                  this.$WarningAlert(response.data.resultMessage)
              }  
          }) 
      },
  }
}
</script>