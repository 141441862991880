<template>
<div>
    <!-- modal -->
    <b-modal id="employeeAnnualLeaveRequestFormModal" centered size="lg" no-close-on-backdrop ref="employeeAnnualLeaveRequestFormModal" :title=" $t('annualLeave.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="col-md-3 mt-1">
                        <b-form>
                            <label for="annual-leave-employee-input">{{ $t('annualLeave.employee') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="annual-leave-employee-input" v-model="employee" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3 mt-1">
                        <label for="annual-leave-starting-date-input">{{ $t('annualLeave.starting_date') }}</label>
                        <flat-pickr v-model="startingDate" class="form-control" size="sm" time_24hr="true" :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}" />
                    </b-col>
                    <b-col class="col-md-3 mt-1">
                        <label for="annual-leave-ending-date-input">{{ $t('annualLeave.ending_date') }}</label>
                        <b-form-group>
                            <flat-pickr v-model="endingDate" class="form-control" size="sm" time_24hr="true" :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}" />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-3 mt-1">
                        <b-form>
                            <label for="annual-leave-employee-vesting-input">{{ $t('annualLeave.employee_vesting') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="annual-leave-employee-vesting-input" v-model="employeeVesting" type="number" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-4 mt-1">
                        <label for="annual-leave-request-date-input">{{ $t('annualLeave.request_date') }}</label>
                        <b-form-group>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="requestDate"></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <label for="annual-leave-status-input">{{ $t('annualLeave.annual_leave_status') }}</label>
                            <b-form-select disabled size="sm" v-model="annualLeaveStatusSelect" value-field="id" text-field="name" :options="annualLeaveStatusOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <label for="annual-leave-type-input">{{ $t('annualLeave.annual_leave_type') }}</label>
                            <b-form-select size="sm" v-model="annualLeaveTypeSelect" value-field="id" text-field="name" :options="annualLeaveTypeOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6 mt-1">
                        <label for="annual-leave-request-description-input">{{ $t('annualLeave.request_description') }}</label>
                        <b-form-textarea size="sm" id="annual-leave-request-description-input" v-model="requestDescription" rows="3" max-rows="8" maxlength="250" />
                    </b-col>
                    <b-col class="col-md-6 mt-1">
                        <label for="annual-leave-approve-description-input">{{ $t('annualLeave.approve_description') }}</label>
                        <b-form-textarea disabled size="sm" id="annual-leave-approve-description-input" v-model="approveDescription" rows="3" max-rows="8" maxlength="250" />
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getAnnualLeaveListByEmployeeId: {
            type: Function
        },
        employeeId: Number
    },

    data() {
        return {
            id: 0,
            employee: '',
            startingDate: null,
            endingDate: null,
            requestDate: null,
            annualLeaveStatusSelect: 0,
            annualLeaveStatusOption: [],
            annualLeaveTypeSelect: 0,
            annualLeaveTypeOption: [],
            requestDescription: '',
            approveDescription: '',
            total: 0,
            employeeId2: 0,
            employeeVesting: 0,
        }
    },
    methods: {
        convertToISODateTime(dateTimeString) {
            const [datePart, timePart] = dateTimeString.split(' ');
            const [year, month, day] = datePart.split('-');
            const [hours, minutes] = timePart.split(':');
            // Note: JavaScript months are 0-based (0 = January, 1 = February, ...).
            const isoDate = new Date(year, month - 1, day, hours, minutes);
            const isoString = isoDate.toISOString();
            return isoString;
        },
        showInfo(id, employeeId2) {
            this.$refs['employeeAnnualLeaveRequestFormModal'].show()
            this.tokenEmployeeId = localStorage.getItem('EmployeeId')
            if (employeeId2 > 0 && employeeId2 != this.tokenEmployeeId) {
                this.employeeId2 = employeeId2
            } else {
                this.employeeId2 = this.tokenEmployeeId
            }
            const data = {
                id: id,
                employeeId: this.employeeId2
            }
            const controller = {
                name: 'AnnualLeaveRequest'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.startingDate = response.data.annualLeaveRequest.startingDate
                    this.endingDate = response.data.annualLeaveRequest.endingDate
                    this.requestDate = response.data.annualLeaveRequest.requestDate
                    this.annualLeaveStatusSelect = response.data.annualLeaveRequest.annualLeaveStatusId
                    this.annualLeaveStatusOption = response.data.annualLeaveStatusList
                    this.annualLeaveTypeSelect = response.data.annualLeaveRequest.annualLeaveTypeId
                    this.annualLeaveTypeOption = response.data.annualLeaveTypeList
                    this.requestDescription = response.data.annualLeaveRequest.requestDescription
                    this.approveDescription = response.data.annualLeaveRequest.approveDescription
                    this.annualLeaveStatusOption.splice(0, 0, this.$nullSelected())
                    this.annualLeaveTypeOption.splice(0, 0, this.$nullSelected())
                    this.employee = response.data.employee
                    this.employeeVesting = response.data.employeeAnnualLeaveVesting
                } else {
                    this.$bvModal.hide('employeeAnnualLeaveRequestFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                    this.startingTime = ''
                    this.endingTime = ''
                    this.requestDescription = ''
                    this.annualLeaveStatusSelect = 0
                }
            })
        },
        addOrUpdate() {
            this.tokenEmployeeId = localStorage.getItem('EmployeeId')
            if (this.employeeId2 > 0 && this.employeeId2 != this.tokenEmployeeId) {
                this.employeeId2 = this.employeeId2

            } else {
                this.employeeId2 = this.tokenEmployeeId
            }
            const data = {
                startingDate: this.convertToISODateTime(this.startingDate),
                endingDate: this.convertToISODateTime(this.endingDate),
                requestDate: this.requestDate,
                annualLeaveStatusId: this.annualLeaveStatusSelect,
                annualLeaveTypeId: this.annualLeaveTypeSelect,
                requestDescription: this.requestDescription,
                approveDescription: this.approveDescription,
                employeeId: this.employeeId2,
                employeeAnnualLeaveVesting: this.employeeVesting
            }
            const controller = {
                name: 'AnnualLeaveRequest'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('employeeAnnualLeaveRequestFormModal')
                            this.$SaveAlert()
                            this.getAnnualLeaveListByEmployeeId()
                            this.startingTime = ''
                            this.endingTime = ''
                            this.requestDescription = ''
                            this.annualLeaveStatusSelect = 0
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
