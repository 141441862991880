<template>
    <div>
      <!-- modal -->
      <b-modal id="lexologyFormModal" centered size="lg" no-close-on-backdrop ref="lexologyFormModal"
        :title=" $t('lexology.lexology_info')" :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate">
        <vue-good-table id="lexologyFormTable" ref="lexologyFormTable" styleClass="vgt-table condensed tableSize" 
        :columns="columns" 
        :rows="rows" 
        :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: false,
            perPage: pageLength
          }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'type'" class="text-nowrap">
                    {{ $t('lexology.type') }}
                </span>
                <span v-else-if="props.column.field === 'organisation'" class="text-nowrap">
                    {{ $t('lexology.organisation') }}
                </span>
                <span v-else-if="props.column.field === 'totalReads'" class="text-nowrap">
                    {{ $t('lexology.total_reads') }}
                </span>
                <span v-else-if="props.column.field === 'totalInteractions'" class="text-nowrap">
                    {{ $t('lexology.total_interactions') }}
                </span>
            </template>       
            <!-- Column: Table Column -->
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'type'">
                    <b-col>
                        <b-row style="font-size: small;">
                            <tr>
                                <span>{{ props.row.type }} </span> <br />
                            </tr>
                        </b-row>
                    </b-col>
                </span>
                <span v-else-if="props.column.field === 'organisation'">
                    <b-col>
                        <b-row style="font-size: small;">
                            <tr>
                                <span>{{ props.row.organisation }} </span> <br />
                            </tr>
                        </b-row>
                    </b-col>
                </span>
                <span v-else-if="props.column.field === 'totalReads'">
                    <b-col>
                        <b-row  style="font-size: small;">
                            <tr>
                                <span>{{ props.row.totalReads }} </span> <br />
                            </tr>
                        </b-row>
                    </b-col>
                </span>
                <span v-else-if="props.column.field === 'organisation'">
                    <b-col>
                        <b-row style="font-size: small;">
                            <tr>
                                <span>{{ props.row.totalInteractions }} </span> <br />
                            </tr>
                        </b-row>
                    </b-col>
                </span>
            </template>
        </vue-good-table>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
        getLexologyList: { type: Function },
    },
      data() {
          return {
              pageLength: 10,
              dir: false,
              columns: [
                  {
                      label: this.$t('lexology.type'),
                      field: `type`,
                  },
                  {
                      label: this.$t('lexology.organisation'),
                      field: `organisation`,
                  },
                  {
                      label: this.$t('lexology.total_reads'),
                      field: 'totalReads'
                  },
                  {
                      label: this.$t('lexology.total_interactions'),
                      field: 'totalInteractions'
                  },
              ],
              rows: [],
              searchTerm: '',
          }
      },
      methods: {
        showInfo(organisations){
            this.$refs['lexologyFormModal'].show()
            this.rows = organisations;
        }
      },
  }
  </script>
  
  <style lang="scss">
  .excel-css {
      float: right;
      margin-right: 1em;
      width: 9.5em;
      color: #7367f0;
      border: 1px solid #7367f0;
      background-color: transparent;
      margin-left: 1em;
      height: 2em;
      border-radius: 0.358rem;
  }
  .tableSize {
      font-size: 13px!important;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      table-layout: auto;
  }
  </style>
    