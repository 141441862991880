<template>
<div>
    <lexology-form :getLexologyList="getLexologyList" ref="lexologyFormModal"></lexology-form>
    <b-row class="align-items-center">
        <b-col class="d-flex align-items-center col-lg-8">
            <b-col>
                <label for="lexology-from-date-input">{{ $t('lexology.from_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="from"></CustomDatePicker>            
            </b-col>
            <b-col>
                <label for="lexology-to-date-input">{{ $t('lexology.to_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="to"></CustomDatePicker>
            </b-col>
        </b-col>
        <b-col class="d-flex justify-content-end col-lg-4 mt-1">
            <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getLexologyList()" @click="getLexologyList()">{{ $t('client.list') }}</b-button>
            <b-button style="float:right;"  size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
        </b-col>
      </b-row>
    <vue-good-table id="lexologyTable" ref="lexologyTable" styleClass="vgt-table condensed tableSize" :columns="columns" :rows="rows"
    :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme" @on-cell-click="onCellClick">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'authorNames'" class="text-nowrap">
                {{ $t('lexology.author_name') }}
            </span>
            <span v-else-if="props.column.field === 'title'" class="text-nowrap">
                {{ $t('lexology.article') }}
            </span>
            <span v-else-if="props.column.field === 'totalReads'" class="text-nowrap">
                {{ $t('lexology.total_reads') }}
            </span>
            <span v-else-if="props.column.field === 'totalInteractions'" class="text-nowrap">
                {{ $t('lexology.total_interactions') }}
            </span>
            <span v-else-if="props.column.field === 'originalURL'" class="text-nowrap">
                {{ $t('lexology.original_url') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'authorNames'">
                <b-col>
                    <b-row v-for="author in props.row.authorNames" v-bind:key="author.authorName" style="font-size: small;">
                        <tr>
                            <span>{{ author.authorName }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'title'">
                <span style="cursor: pointer;">
                    <b-col>
                            <feather-icon
                                icon="MousePointerIcon"
                                class="mr-25"
                            />
                            <span>{{ props.row.title }} </span> <br />
                    </b-col>
                </span>
            </span>
            <span v-else-if="props.column.field === 'originalURL'">
                <b-badge v-if="props.row.originalURL != null" style="cursor: pointer;" variant="primary">
                    <b-col>
                            <feather-icon
                                icon="MousePointerIcon"
                                class="mr-25"
                            />
                            <a target="_blank" :href="props.row.originalURL"> Stock </a> <br />
                    </b-col>
                </b-badge>
                <b-badge v-if="props.row.lexologyURL != null" style="cursor: pointer;" variant="primary" class="mt-1">
                    <b-col>
                            <feather-icon
                                icon="MousePointerIcon"
                                class="mr-25"
                            />
                            <a target="_blank" :href="props.row.lexologyURL"> Lexology </a> <br />
                    </b-col>
                </b-badge>
            </span>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                    </span>
                    <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>
<script>    
export default {
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: this.$t('lexology.author'),
                    field: `authorNames`,
                },
                {
                    label: this.$t('lexology.article'),
                    field: `title`,
                },
                {
                    label: this.$t('lexology.total_reads'),
                    field: 'totalReads'
                },
                {
                    label: this.$t('lexology.total_interactions'),
                    field: 'totalInteractions'
                },
                {
                    label: this.$t('lexology.original_url'),
                    field: 'originalURL'
                }
            ],
            rows: [],
            searchTerm: '',
            to: new Date().toISOString().slice(0, 10),
            from: this.subtractMonths(new Date()),
            date: new Date(),
        }
    },
    mounted() {
        this.getLexologyList()
    },
    methods: {
        subtractMonths(date) {
            date.setMonth(date.getMonth() - 1);
            return date;
        },
        onCellClick(params) {
            if (params.column.field == "title") {
                  this.$refs.lexologyFormModal.showInfo(params.row.organisations)
            }
            },
        getLexologyList() {
            this.rows = []
            const data = {
                from: this.from,
                to: this.to,
                // removeEmptyDays: this.removeEmptyDays
            }
            const controller = {
                name: 'Lexology',
                actionName: 'GetLexologyList'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.lexologyObject.articles.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
         },
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}
.tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
