<template>
    <div>
      <national-patent-form :getNationalPatentList="getNationalPatentList" ref="nationalPatentFormModal"></national-patent-form>
      <!-- <national-patent-xml-upload-form ref="nationalPatentXmlUploadForm" :getNationalPatentList="getNationalPatentList"></national-patent-xml-upload-form> -->
    <b-row>
      <b-col class="col-12">
        <div style="float:left;" class="custom-search d-flex justify-content-end">
          <b-row>
            <!--Name Search-->
            <b-col>
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input size="sm" v-model="recordNo" v-on:keyup.enter="getNationalPatentList()" :placeholder="$t('nationalPatent.record_no')" type="text"
                      class="d-inline-block" />
                </div>
              </b-form-group>
            </b-col>
            <!--Select Search-->
            <b-col>
                <div class="d-flex align-items-center">
                  <b-form-input size="sm" v-model="agentName" v-on:keyup.enter="getNationalPatentList()" :placeholder="$t('nationalPatent.agent_name')" type="text"
                      class="d-inline-block" />
                </div>
            </b-col>
          </b-row>
        </div>

        <div style="float:right;" class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getNationalPatentList()" @click="getNationalPatentList()">{{ $t('nationalPatent.list') }}</b-button>
              <b-button class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{
                  $t('nationalPatent.national_patent_add') }}</b-button>
                <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{
                  $t('excel.excel') }}</b-button>
            </div>
          </b-form-group>
        </div>        
      </b-col>
    </b-row>

      <!-- table -->
      <vue-good-table id="nationalPatentTable" ref="nationalPatentTable" :columns="columns" :rows="rows" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" styleClass="vgt-table condensed tableSize" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
  }" :pagination-options="{
    enabled: true,
    perPage: pageLength
  }" theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
            {{ $t('nationalPatent.national_patent_folder_no') }}
          </span>
          <span v-else-if="props.column.field === 'applicationInfo'" class="text-nowrap">
            {{ $t('nationalPatent.application_info') }}
          </span>
          <span v-else-if="props.column.field === 'title'" class="text-nowrap">
            {{ $t('nationalPatent.title') }}
          </span>
          <span v-else-if="props.column.field === 'patentType'" class="text-nowrap">
            {{ $t('nationalPatent.national_patent_type') }}
          </span>
          <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
            {{ $t('nationalPatent.agent_name') }}
          </span>
          <span v-else-if="props.column.field === 'ownerList'" class="text-nowrap">
            {{ $t('nationalPatent.owners') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Agent Name -->
          <span v-if="props.column.field === 'agent'" class="text-nowrap">
            <b-row>
              <b-col class="font-size: x-small">
                <span>{{ props.row.agent }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="font-size: small">{{ props.row.clientAccounting }}</span>
              </b-col>
            </b-row>
          </span>
          <span v-else-if="props.column.field === 'applicationInfo'" class="text-nowrap">
            <b-row>
              <b-col class="font-size: x-small">
                <span>{{ props.row.applicationNo }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="font-size: small">{{ $formatFn(props.row.applicationDate) }}</span>
              </b-col>
            </b-row>
          </span>
          <span v-else-if="props.column.field === 'nationalPatentType'" class="text-nowrap">
            <b-row>
              <b-col class="font-size: x-small">
                <span>{{ props.row.nationalPatentType }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="font-size: small">{{ props.row.nationalPatentPublicationType }}</span>
              </b-col>
            </b-row>
          </span>
          <span v-else-if="props.column.field === 'ownerList'">
            <b-col>
                <b-row v-for="owner in props.row.ownerList" v-bind:data="owner" v-bind:key="owner.clientName" style="font-size: small;">
                    <tr v-if="props.row.ownerList.length != 1">
                        <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                        <span>{{ owner.clientName }} </span> <br />
                    </tr>
                    <tr v-else-if="props.row.ownerList.length == 1">
                        <span>{{ owner.clientName }} </span> <br />
                    </tr>
                </b-row>
            </b-col>
          </span>
          <span v-else-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
  
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm"
                @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }} </span>
              <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => getNationalPatentList()">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </template>
        
  <script>
  export default {
    data() {
      return {
        total: 0,
        pageLength: 10,
        dir: false,
        pageNo: 1,
        columns: [
          {
            label: this.$t('nationalPatent.national_patent_folder_no'),
            field: `recordNo`,
          },
          {
            label: this.$t('nationalPatent.application_info'),
            field: `applicationInfo`,
          },
          {
            label: this.$t('nationalPatent.title'),
            field: `title`,
          },
          {
            label: this.$t('nationalPatent.national_patent_type'),
            field: `nationalPatentType`,
          },
          {
            label: this.$t('nationalPatent.agent_name'),
            field: `agent`,
          },
          {
            label: this.$t('nationalPatent.owners'),
            field: `ownerList`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
        agentName: '',
        recordNo: '',
      }
    },
    mounted() {
      this.getNationalPatentList()
    },
    methods: {
      async getEpoXml() {
        this.$refs.nationalPatentXmlUploadForm.showInfo(0)
      },
      async deleted(data) {
        data = { id: data.id }
        const controller = { name: 'NationalPatent' }
        const payload = { data: data, controller: controller }
        this.$DeleteConfirm().then(result => {
          if (result.value) {
            this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$DeleteAlert()
                this.getNationalPatentList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getNationalPatentList()
              }
            })
          }
        })
      },
  
      async getInfo(id) {
        this.$refs.nationalPatentFormModal.showInfo(id, this.nationalPatentId)
      },
  
      getNationalPatentList() {
        this.rows = []
        const controller = { name: 'NationalPatent' }
        const data = {recordNo: this.recordNo, pageLength: this.pageLength, pageNo: this.pageNo, agentName : this.agentName}
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.total = response.data.total
            response.data.nationalPatentList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
      exportExcel() {
        const controller = { name: 'NationalPatent', actionName: 'CanExport' }
        const data = { columnList: this.columns, DataList: this.rows }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.$downloadFile("NationalPatent", response.data.base64, 'xlsx')
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      },
    },
  }
  </script>
  <style lang="scss" >
  .excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
  }
  
  [dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
  }
  
  .akista-d-flex {
    display: contents !important;
  }
  
  /* Solid border */
  hr.solid {
    border-top: 3px solid #bbb;
  }
  
  .tableSize {
    font-size: 14px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  
  .featherIconReSize {
    width: 12px;
    height: 12px;
  }
  </style>
        