<template>
    <div>
      <!-- modal -->
      <b-modal
        id="nationalPatentCountryFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="nationalPatentCountryFormModal"
        :title=" this.transportToCountryName"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
      <validation-observer ref="infoRules" tag="form">
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <validation-provider :name=" $t('nationalPatentCountry.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                        <b-form-group :label=" $t('nationalPatentCountry.agent')" label-for="nationalPatentCountryAgent" :state="errors.length > 0 ? false:null">
                            <v-select id="nationalPatentCountryAgent" v-model="nationalPatentCountryAgentSelect" :options="nationalPatentCountryAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                            </v-select>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="national-patent-country-agent-ref-code-input">{{ $t('nationalPatentCountry.national_patent_country_agent_ref_code') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="national-patent-country-agent-ref-code-input" v-model="agentReferenceNo" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
      </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>

  export default {
    props: {
      nationalPatentId: Number, 
      getNationalPatentCountryList: {type: Function},
      getNationalPatentList: {type: Function},
      transportToCountryName: String
    },
    data() {
      return {
        id: 0,
        nationalPatentCountryAgentSelect: 0,
        nationalPatentCountryAgentOption: [],
        agentReferenceNo: '',
      }
    },
    methods: {
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        setClientId(event) {
            this.clientId = event.clientId
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                clientAccountingId: 0,
                clientName: search
            }
            const controller = {
                name: 'ClientAccounting',
                actionName: 'GetClientAccountingByClientName'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                this.nationalPatentCountryAgentOption = response.data.clientList
                loading(false)
            })
            }
        },
        showInfo(id) {
            this.$refs['nationalPatentCountryFormModal'].show()
                this.id = id
                const data = {
                    id: this.id
                }
                const controller = {
                    name: 'NationalPatentCountry'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.agentReferenceNo = response.data.nationalPatentCountry.agentReferenceNo
                        if (response.data.nationalPatentCountry.clientAccountingId > 0) {
                            const clientAccountingData = {
                                clientAccountingId: response.data.nationalPatentCountry.clientAccountingId
                            }
                            const clientAccountingController = {
                                name: 'ClientAccounting',
                                actionName: 'GetClientAccountingByClientName'
                            }
                            const clientAccountingPayload = {
                                data: clientAccountingData,
                                controller: clientAccountingController
                            }
                            this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                                this.nationalPatentCountryAgentOption = clientAccountingResponse.data.clientList
                                this.nationalPatentCountryAgentSelect = clientAccountingResponse.data.clientList[0]
                                this.clientId = clientAccountingResponse.data.clientList[0].clientId
                            })
                        } else {
                            this.nationalPatentCountryAgentOption = []
                            this.nationalPatentCountryAgentSelect = 0;
                        }
                    } else {
                        this.$bvModal.hide('nationalPatentCountryFormModal')
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            addOrUpdate() {
                const data = {
                    id: this.id,
                    agentReferenceNo: this.agentReferenceNo,
                    clientAccountingId: this.nationalPatentCountryAgentSelect.clientAccountingId
                }
                const controller = {
                    name: 'NationalPatentCountry'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.getNationalPatentList()
                        this.getNationalPatentCountryList()
                        this.$SaveAlert()
                        this.$bvModal.hide('nationalPatentCountryFormModal')
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
      },
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  [dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
  </style>
  