<template>
<div>
    <domainOpposition-basis-for-opposition-form :getDomainOppositionList="getDomainOppositionList" :getDomainOppositionBasisForOppositionList="getDomainOppositionBasisForOppositionList" ref="domainOppositionBasisForOppositionFormModal">
    </domainOpposition-basis-for-opposition-form>
    <b-row>
        <b-col class="col-12">
            <div style="float:right;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-button style="float:right;" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('domainOppositionBasisForOpposition.basis_for_opposition_add') }}</b-button>

                    </div>
                </b-form-group>
            </div>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="domainOppositionBasisForOppositionTable" ref="domainOppositionBasisForOppositionTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('domainOppositionBasisForOpposition.name') }}
            </span>
            <span v-else-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('domainOppositionBasisForOpposition.trademark_record_no') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    props: {
        domainOppositionId: Number,
        getDomainOppositionList: {
            type: Function
        },
    },
    data() {
        return {
            dir: false,
            columns: [{
                    label: this.$t('domainOppositionBasisForOpposition.name'),
                    field: `name`,
                },
                {
                    label: this.$t('domainOppositionBasisForOpposition.trademark_record_no'),
                    field: `recordNo`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getDomainOppositionBasisForOppositionList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'DomainOppositionBasisForOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getDomainOppositionBasisForOppositionList()
                            this.getDomainOppositionList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getDomainOppositionBasisForOppositionList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.domainOppositionBasisForOppositionFormModal.showInfo(id, this.domainOppositionId)
        },
        getDomainOppositionBasisForOppositionList() {
            this.rows = []
            const data = {
                id: this.domainOppositionId
            }
            const controller = {
                name: 'DomainOppositionBasisForOpposition',
                actionName: 'GetDomainOppositionBasisForOppositionListByDomainOppositionId'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.domainOppositionBasisForOppositionList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    },
}
</script>

<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
