var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"serviceFormModal",attrs:{"id":"serviceFormModal","centered":"","size":"xl","no-close-on-backdrop":"","title":_vm.$t('service.service_info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"col-md-2"},[_c('label',{attrs:{"for":"service-code-input"}},[_vm._v(_vm._s(_vm.$t('service.code')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('service.code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"service-code-input","maxlength":"15","size":"sm","state":errors.length > 0 ? false:null},model:{value:(_vm.serviceCode),callback:function ($$v) {_vm.serviceCode=$$v},expression:"serviceCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-md-10"},[_c('label',{attrs:{"for":"service-name-input"}},[_vm._v(_vm._s(_vm.$t('service.name')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('service.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"service-name-input","maxlength":"400","size":"sm","state":errors.length > 0 ? false:null},model:{value:(_vm.serviceName),callback:function ($$v) {_vm.serviceName=$$v},expression:"serviceName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-md-2"},[_c('label',{attrs:{"for":"service-tpmk-code-input"}},[_vm._v(_vm._s(_vm.$t('service.tpmk_code')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('service.tpmk_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"service-tpmk-code-input","maxlength":"10","size":"sm","state":errors.length > 0 ? false:null},model:{value:(_vm.serviceTpmkCode),callback:function ($$v) {_vm.serviceTpmkCode=$$v},expression:"serviceTpmkCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-md-10"},[_c('label',{attrs:{"for":"service-tpmk-name-input"}},[_vm._v(_vm._s(_vm.$t('service.tpmk_name')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('service.tpmk_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"service-tpmk-name-input","maxlength":"100","size":"sm","state":errors.length > 0 ? false:null},model:{value:(_vm.serviceTpmkName),callback:function ($$v) {_vm.serviceTpmkName=$$v},expression:"serviceTpmkName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-md-2"},[_c('label',{attrs:{"for":"service-ledes-code-input"}},[_vm._v(_vm._s(_vm.$t('service.ledes_code')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('service.ledes_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"service-ledes-code-input","maxlength":"10","size":"sm","state":errors.length > 0 ? false:null},model:{value:(_vm.serviceLedesCode),callback:function ($$v) {_vm.serviceLedesCode=$$v},expression:"serviceLedesCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-md-3"},[_c('label',{attrs:{"for":"service-costing-type-input"}},[_vm._v(_vm._s(_vm.$t('service.costing_type')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('service.costing_type'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null,"value-field":"id","text-field":"name","size":"sm","options":_vm.costingTypeOption},model:{value:(_vm.costingTypeSelect),callback:function ($$v) {_vm.costingTypeSelect=$$v},expression:"costingTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-md-3"},[_c('label',{attrs:{"for":"service-payment-type-input"}},[_vm._v(_vm._s(_vm.$t('service.payment_type')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('service.payment_type'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null,"value-field":"id","text-field":"name","size":"sm","options":_vm.paymentTypeOption},model:{value:(_vm.paymentTypeSelect),callback:function ($$v) {_vm.paymentTypeSelect=$$v},expression:"paymentTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-md-4"},[_c('label',{attrs:{"for":"service-service-type-input"}},[_vm._v(_vm._s(_vm.$t('service.service_type')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('service.service_type'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null,"value-field":"id","text-field":"name","size":"sm","options":_vm.serviceTypeOption},model:{value:(_vm.serviceTypeSelect),callback:function ($$v) {_vm.serviceTypeSelect=$$v},expression:"serviceTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-md-2"},[_c('label',{attrs:{"for":"service-jurisdiction-input"}},[_vm._v(_vm._s(_vm.$t('service.jurisdiction')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('service.jurisdiction'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null,"value-field":"id","text-field":"name","size":"sm","options":_vm.jurisdictionOption},model:{value:(_vm.jurisdictionSelect),callback:function ($$v) {_vm.jurisdictionSelect=$$v},expression:"jurisdictionSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-md-2"},[_c('label',{attrs:{"for":"service-sequenceNo-input"}},[_vm._v(_vm._s(_vm.$t('service.sequenceNo')))]),_c('b-form-group',[_c('b-form-input',{attrs:{"id":"service-sequenceNo-input","type":"number","maxlength":"3","size":"sm"},model:{value:(_vm.sequenceNo),callback:function ($$v) {_vm.sequenceNo=$$v},expression:"sequenceNo"}})],1)],1),_c('b-col',{staticClass:"col-lg-2 mt-1"},[_c('label',{attrs:{"for":"service-is-show-in-report-input"}},[_vm._v(_vm._s(_vm.$t('service.is_show_in_report')))]),_c('br'),_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"name":"is-show-in-report-input","id":"is-show-in-report-input","switch":""},model:{value:(_vm.isShowInReport),callback:function ($$v) {_vm.isShowInReport=$$v},expression:"isShowInReport"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)])],1),_c('b-col',{staticClass:"col-lg-2 mt-1"},[_c('label',{attrs:{"for":"service-is-vat-input"}},[_vm._v(_vm._s(_vm.$t('service.is_vat')))]),_c('br'),_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"name":"is-vat-input","id":"is-vat-input","switch":""},model:{value:(_vm.isVat),callback:function ($$v) {_vm.isVat=$$v},expression:"isVat"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }