<template>
<div>
    <!-- modal -->
    <b-modal id="exchangeRateCalculatorFormModal" centered size="lg" no-close-on-backdrop hide-footer ref="exchangeRateCalculatorFormModal" :title="$t('exchangeRateCalculator.info')" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-6">
                <!-- Sol tarafta tarih ve girişler -->
                <b-form-group>
                    <label for="exchange-rate-calculator-date-input">{{ $t('exchangeRateCalculator.date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="requestDate" @input="getExchangeRateCalculatorList"></CustomDatePicker>
                </b-form-group>

                <label for="exchange-rate-calculator-buying-input">{{ $t('exchangeRateCalculator.first_currency') }}</label>
                <b-input-group>
                    <b-form-input @input="calculateRate()" id="exchange-rate-calculator-buying-input" size="sm" v-model="buying" type="number" />
                    <b-input-group-append style="width: 35%;">
                        <b-form-select @input="calculateRate()" v-model="buyingSelect" value-field="code" size="sm" text-field="code" :options="buyingOption" />
                    </b-input-group-append>
                </b-input-group>

                <label for="exchange-rate-calculator-selling-input">{{ $t('exchangeRateCalculator.second_currency') }}</label>
                <b-input-group>
                    <b-form-input disabled id="exchange-rate-calculator-selling-input" size="sm" v-model="selling" type="number" />
                    <b-input-group-append style="width: 35%;">
                        <b-form-select @input="calculateRate()" v-model="sellingSelect" value-field="code" size="sm" text-field="code" :options="sellingOption" />
                    </b-input-group-append>
                </b-input-group>
            </b-col>

            <b-col class="col-lg-6">
                <!-- Sağ tarafta tablo -->
                <div class="table-container" style="max-height: 400px; overflow-y: auto;">
                    <label for="exchange-rate-calculator-table-input">{{ $t('exchangeRateCalculator.currency_table') }}</label>
                    <vue-good-table id="exchangeRateCalculatorTable" ref="exchangeRateCalculatorTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
                enabled: true,
                externalQuery: searchTerm
              }" :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true,
                selectAllByGroup: true,
              }" :pagination-options="{
                enabled: false,
                perPage: pageLength
              }" theme="my-theme">
                        <div slot="emptystate">{{ $t('dataTable.data_not_found') }}</div>
                        <!-- Row: Table Row -->
                        <template slot="table-column" slot-scope="props">
                            <span v-if="props.column.field === 'priceDate'" class="text-nowrap">
                                {{ $t('exchangeRateCalculator.date') }}
                            </span>
                            <span v-else-if="props.column.field === 'currencyCode'" class="text-nowrap">
                                {{ $t('exchangeRateCalculator.currency_code') }}
                            </span>
                            <span v-else-if="props.column.field === 'buying'" class="text-nowrap">
                                {{ $t('exchangeRateCalculator.value') }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
export default {
    watch: {
        selectedSelling(value) {
            this.sellingSelect = value;
        }
    },
    data() {
        return {
            id: 0,
            requestDate: null,
            buying: 0.0,
            selling: 0.0,
            buyingSelect: 'USD',
            buyingOption: [],
            sellingSelect: 'TL',
            sellingOption: [],
            pageLength: 10,
            dir: false,
            columns: [{
                    label: this.$t('exchangeRateCalculator.date'),
                    field: `priceDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('exchangeRateCalculator.currency_code'),
                    field: `currencyCode`,
                },
                {
                    label: this.$t('exchangeRateCalculator.value'),
                    field: `buying`,
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    beforeMount() {
        this.getExchangeRateCalculatorList()
        this.calculateRate()
    },
    methods: {
        showInfo(){
            this.$refs['exchangeRateCalculatorFormModal'].show()
        },
        getExchangeRateCalculatorList() {
            if (this.requestDate == null) {
                this.$set(this, 'requestDate', new Date());
            }
            const data = {
                startingDate: this.requestDate,
                endingDate: this.requestDate
            }
            const controller = {
                name: 'Currency',
                actionName: 'GetPriceOfCurrencyListByDate'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.currencyWithPriceList.forEach(element => {
                        this.rows.push(element)
                        this.calculateRate()
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
            this.rows = []
        },
        calculateRate() {
            if (this.buying == 0.0 || this.buying == '' || this.buying == null) {
                this.buying = 0.0
                this.selling = 0.0
            }
            const data = {
                requestDate: this.requestDate,
                currencyToSell: this.sellingSelect,
                currencyToBuy: this.buyingSelect,
                amount: this.buying
            }
            const controller = {
                name: 'Currency',
                actionName: 'ExchangeCurrency'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.requestDate = response.data.requestDate
                    this.sellingSelect = this.sellingSelect
                    this.sellingOption = response.data.currencyList
                    this.buyingSelect = this.buyingSelect
                    this.buyingOption = response.data.currencyList
                    this.sellingOption.splice(0, 0, this.$nullSelected('code'))
                    this.selling = response.data.calculatedPrice
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
