<template>
    <div>
      <!-- modal -->
      <b-modal id="invoiceEditNoteFormModal" ref="invoiceEditNoteFormModal" centered size="lg" no-close-on-backdrop :title=" $t('invoiceEditNote.info')" :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" >
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-12">
                    <label for="invoice-edit-note-input">{{ $t('invoiceEditNote.note') }}</label>
                    <validation-provider #default="{ errors }" :name=" $t('invoiceEditNote.note')">
                        <b-form-textarea size="sm" id="invoice-edit-note-input" v-model="note" rows="3" max-rows="8" maxlength="250" :state="errors.length > 0 ? false:null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
      </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
        getInvoiceList: {type: Function},
    },
    data() {
      return {
        id: 0,
        note: '',
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['invoiceEditNoteFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'Invoice', actionName: 'GetInvoiceNoteByInvoiceId'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.id = response.data.invoiceId
                this.note = response.data.note
            } else {
                this.$bvModal.hide('invoiceEditNoteFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {invoiceId: this.id, note: this.note}
        const controller = {name : 'Invoice', actionName: 'UpdateInvoiceNote'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/customService',payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('invoiceEditNoteFormModal')
                    this.getInvoiceList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  