<template>
<div>
    <!-- modal -->
    <b-modal id="clientFormModal" ref="clientFormModal" centered size="xl" no-close-on-backdrop :title=" $t('client.client_info')" hide-footer>
        <reminder-form ref="reminderForm"></reminder-form>
        <b-card>
            <div class="custom-search d-flex justify-content-end">
                <b-row>
                    <b-col>
                        <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="clientConflictCheck">
                            {{$t('others.ok_title')}}
                        </b-button>
                        <b-dropdown style="width: 4em; height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                            <template #button-content>
                                <feather-icon icon="PrinterIcon" size="10" class="align-middle" />
                            </template>
                            <b-dropdown-item @click="downloadClientWordFile(wordTemplate.id, wordTemplate.name)" v-for="wordTemplate in wordTemplateList" :key="wordTemplate.id">
                                <span>{{wordTemplate.name}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown style="width: 4em; height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-1" size="sm">
                            <template #button-content>
                                <feather-icon icon="SendIcon" size="10" class="align-middle" />
                            </template>
                            <b-dropdown-item @click="prepareClientMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                <span>{{mailTemplate.explanation}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                        <b-button variant="outline-primary" class="ml-1" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                            <feather-icon icon="BellIcon" size="10" class="align-middle" />
                        </b-button>
                        <b-button :hidden="hiddenClientControl" variant="outline-primary" v-b-tooltip.html :title="$t('others.mail')" size="sm" class="btn-icon ml-1" @click="getInfoMail(0)">
                            <feather-icon icon="MailIcon" />
                        </b-button>
                        <b-button :hidden="hiddenClientControl" variant="outline-primary" v-b-tooltip.html :title="$t('others.merge_company')" size="sm" class="btn-icon ml-1" @click="mergeCompany(0)">
                            <feather-icon icon="GitMergeIcon" />
                        </b-button>
                        <client-merge-company-form :getClientList="getClientList" :clientId="this.id">
                        </client-merge-company-form>
                        <mail-send-form :getClientList="getClientList" :clientId="this.id">
                        </mail-send-form>
                    </b-col>
                </b-row>
            </div>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col-md-6 mt-2">
                        <b-form>
                            <label for="client-name-input">{{ $t('client.client_name') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name=" $t('client.client_name')" rules="required">
                                    <b-form-input size="sm" id="client-name-input" v-model="clientName" maxlength="250" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-2 mt-2">
                        <b-form>
                            <label for="tax-no-input">{{ $t('client.tax_no') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="tax-no-input" v-model="taxNo" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-2 mt-2">
                        <b-form>
                            <label for="invoice-send-type-input">{{ $t('client.invoice_send_type') }}</label>
                            <b-form-select size="sm" v-model="invoiceSendTypeSelect" value-field="id" text-field="name" :options="invoiceSendTypeOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col-md-2 mt-2">
                        <b-row class="mt-1">
                            <div class="demo-inline-spacing">
                                <b-form-checkbox v-model="isAgent" :state="state" name="checkbox-validation">
                                    {{ $t('client.agent') }}
                                </b-form-checkbox>
                                <b-form-checkbox v-model="isOwner" :state="state" name="checkbox-validation">
                                    {{ $t('client.owner') }}
                                </b-form-checkbox>
                            </div>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form>
                            <label for="owner-code-input">{{ $t('client.owner_code') }}</label>
                            <b-form-group>
                                <cleave style="height: 2em;" id="ownerCode" v-model="ownerCode" class="form-control" :raw="false" :options="ownerCodeOptions" placeholder="000000" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col>
                        <b-form>
                            <label for="sector-input">{{ $t('client.sector') }}</label>
                            <b-form-select size="sm" v-model="sectorSelect" value-field="id" text-field="nameTR" :options="sectorOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <app-collapse class="ml-1 mb-1 mt-2" type="margin" style="width: 100%">
                        <app-collapse-item :title="$t('client.reminder_selections')">
                            <b-row>
                                <b-col>
                                    <b-form-checkbox v-model="working" name="checkbox">
                                        {{ $t('clientReminder.working') }}
                                    </b-form-checkbox>
                                </b-col>
                                <b-col>
                                    <b-form-checkbox v-model="annuity" name="checkbox">
                                        {{ $t('clientReminder.annuity') }}
                                    </b-form-checkbox>
                                </b-col>
                                <b-col>
                                    <b-form-checkbox v-model="compensationFee" name="checkbox">
                                        {{ $t('clientReminder.compensation_fee') }}
                                    </b-form-checkbox>
                                </b-col>
                                <b-col>
                                    <b-form-checkbox v-model="patentCertificate" name="checkbox">
                                        {{ $t('clientReminder.patent_certificate') }}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </app-collapse-item>
                    </app-collapse>
                </b-row>

            </validation-observer>
        </b-card>
        <b-card :hidden="hiddenClientControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title=" $t('client.address')" active style="width:100%;">
                            <b-card-text>
                                <client-address-list :clientId="this.id"></client-address-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('client.contact')" style="width:100%;">
                            <b-card-text>
                                <client-contact-list :clientId="this.id"></client-contact-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('client.responsible')" style="width:100%;">
                            <b-card-text>
                                <client-responsible-list :clientId="this.id"></client-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('client.liable')" style="width:100%;">
                            <b-card-text>
                                <client-liable-list :clientId="this.id"></client-liable-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('client.accounting')" v-if="isAgent == true" style="width:100%;">
                            <b-card-text>
                                <client-accounting-list :getClientList="getClientList" :clientId="this.id"></client-accounting-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('client.notes')" style="width:100%;">
                            <b-card-text>
                                <client-note-list :clientId="this.id"></client-note-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('client.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="1"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('client.history')" style="width:100%;">
                            <b-card-text>
                                <client-history-list ref="clientHistoryList" :clientId="this.id"></client-history-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('client.conflict')" style="width:100%;">
                            <b-card-text>
                                <client-conflict-list :clientId="this.id"></client-conflict-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('client.folders')" style="width:100%;">
                            <b-card-text>
                                <client-folder-list :clientAccountingInformation="clientAccountingInformation" :clientOwnershipInfo="clientOwnershipInfo" :clientId="this.id" :getClientList="getClientList"></client-folder-list>
                            </b-card-text>
                        </b-tab>
                        <!-- <b-tab :title=" $t('others.job')">
                            <b-card-text>
                                <job-list :recordId="this.id" :moduleId="1"></job-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="1"></dailywork-list>
                            </b-card-text>
                        </b-tab> -->
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getClientList: { type: Function }
    },
    data() {
        return {
            id: 0,
            clientName: '',
            invoiceSendTypeSelect: 0,
            invoiceSendTypeOption: [],
            sectorSelect: 0,
            sectorOption: [],
            taxNo: '',
            ownerCode: null,
            hiddenClientControl: true,
            collapseType: 'margin',
            ownerCodeOptions: {
                blocks: [6],
                numericOnly: true
            },
            wordTemplateList: [],
            mailTemplateList: [],
            selected: null,
            isOwner: false,
            isAgent: false,
            working: false,
            annuity: false,
            compensationFee: false,
            patentCertificate: false,
            clientOwnershipInfo: {} ,
            clientAccountingInformation :{},
        }
    },
    computed: {
        state() {
            return this.isAgent || this.isOwner
        },
    },
    methods: {
        async mergeCompany() {
            this.$refs.clientMergeForm.showInfo(0)
        },
        async getInfoMail() {
            this.$refs.mailSendForm.showInfo(0)
        },
        async getReminderInfo() {
            this.$refs.reminderForm.showInfo(0, this.id, 1)
        },
        showInfo(id) {
            this.$refs['clientFormModal'].show()
            this.id = id

            if (this.id == 0) {
                this.hiddenClientControl = true
            } else {
                this.hiddenClientControl = false
            }

            const data = {
                id: this.id
            }
            const controller = {
                name: 'Client'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.clientId = response.data.client.id
                    this.wordTemplateList = response.data.wordTemplateList
                    this.mailTemplateList = response.data.mailTemplateList
                    this.clientName = response.data.client.name
                    this.invoiceSendTypeSelect = response.data.client.invoiceSendTypeId
                    this.invoiceSendTypeOption = response.data.invoiceSendTypeList
                    this.sectorSelect = response.data.client.sectorId
                    this.sectorOption = response.data.sectorList
                    this.isAgent = response.data.client.isAgent
                    this.isOwner = response.data.client.isOwner
                    this.taxNo = response.data.client.taxNo
                    this.ownerCode = response.data.client.ownerCode
                    this.clientOwnershipInfo = response.data.clientOwnershipInformation
                    this.clientAccountingInformation = response.data.clientAccountingInformation
                    this.invoiceSendTypeOption.splice(0, 0, this.$nullSelected())
                    this.sectorOption.splice(0, 0, this.$nullSelected('nameTR'))
                    const data1 = {
                        id: this.clientId
                    }
                    const controller1 = {
                        name: "ClientReminderOptions",
                        actionName: "GetClientReminderOptionsInfoByClientId"
                    }
                    const payload = {
                        data: data1,
                        controller: controller1
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.working = response.data.clientReminderOptions.working
                            this.annuity = response.data.clientReminderOptions.annuity
                            this.compensationFee = response.data.clientReminderOptions.compensationFee
                            this.patentCertificate = response.data.clientReminderOptions.patentCertificate
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                } else {
                    this.$bvModal.hide('clientFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadClientWordFile(documentId, name) {
            const data = {
                moduleId: 1,
                documentId: documentId,
                clientId: this.id
            }
            const controller = {
                name: 'Client',
                actionName: 'DownloadClientWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clientConflictCheck() {
            if (this.id > 0) {
                this.addOrUpdate()
            } else {
                this.$refs.simpleRules.validate().then(success => {
                    if (this.isAgent == true || this.isOwner == true) {
                        if (success) {
                            const data = {
                                string: this.clientName
                            }
                            const controller = {
                                name: 'ClientConflict',
                                actionName: 'GetClientConflictListByClientName'
                            }
                            const payload = {
                                data: data,
                                controller: controller
                            }
                            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                                if (response.data.clientConflictList.length > 0) {
                                    // Müvekkil Çıkar Çatışması için kontrol içine mesaj basılması gerekiyor bunu nasıl yapacağımızı konuşmalıyız . 
                                    this.$ClientConflictControl(response.data.resultMessage).then(result => {
                                        if (result.value) {
                                            this.addOrUpdate()
                                        }
                                    })
                                } else {
                                    this.addOrUpdate()
                                }
                            })
                        }
                    } else {
                        this.$isOwnerOrAgent()
                    }
                })
            }

        },
        prepareClientMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                clientId: this.id
            }
            const controller = {
                name: 'Client',
                actionName: 'PrepareClientMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.clientName,
                invoiceSendTypeId: this.invoiceSendTypeSelect,
                isAgent: this.isAgent,
                isOwner: this.isOwner,
                taxNo: this.taxNo,
                ownerCode: this.ownerCode,
                sectorId: this.sectorSelect
            }
            const controller = {
                name: 'Client'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (this.isAgent == true || this.isOwner == true) {
                    if (success) {
                        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                const data1 = {
                                    clientId: this.id,
                                    working: this.working,
                                    annuity: this.annuity,
                                    patentCertificate: this.patentCertificate,
                                    compensationFee: this.compensationFee
                                }
                                const controller1 = {
                                    name: 'ClientReminderOptions'
                                }
                                const payload = {
                                    data: data1,
                                    controller: controller1
                                }
                                this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                                    if (response.data.resultStatus == true) {
                                        if (this.getClientList != null) {
                                            this.getClientList()
                                        }
                                        this.$SaveAlert()
                                        this.$refs.clientHistoryList.getClientHistoryList()
                                    } else {
                                        this.$WarningAlert(response.data.resultMessage)
                                    }
                                })
                                if (this.id == 0) {
                                    this.id = response.data.clientId
                                    this.hiddenClientControl = false
                                }
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                } else {
                    this.$isOwnerOrAgent()
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}
[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
</style>
