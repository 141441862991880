<template>
    <div>
        <b-modal id="invoiceAddItemFormModal" centered size="lg" no-close-on-backdrop ref="invoiceAddItemFormModal" :title=" $t('invoiceAddItem.info')" :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.add')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
          <b-row>
            <b-col class="col-lg-12">
              <label for="invoice-add-item-name-input">{{ $t('invoiceAddItem.name') }}</label>
              <validation-provider #default="{ errors }" :name=" $t('invoiceAddItem.name')" rules="required">
                <b-form-input id="invoice-add-item-name-input" v-model="name" maxlength="500" size="sm" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-lg-12 mt-1">
              <label for="invoice-add-item-amount-input">{{ $t('invoiceAddItem.amount') }}</label>
              <b-form-group>
                <cleave id="invoice-add-item-amount-input" v-model="amount" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
              </b-form-group>
            </b-col>
          </b-row>
      </validation-observer>
    </b-modal>


    </div>
</template>
<script>
export default{
  props: {
    showInvoiceInfo: {
      type: Function
    }
  },
  data(){    
    return {
      invoiceId: 0,
      name : '',
      amount : 0.0,
      currencyId : 0,
      count:0,
      cleaveOptions: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralIntegerScale: 9,
        numeralDecimalScale: 2
      },
    }
  },
  methods:{
    showInfo(invoiceId, currencyId, count) {
      this.name = ''
      this.invoiceId = invoiceId
      this.currencyId = currencyId
      this.count = count
      this.$refs['invoiceAddItemFormModal'].show()
    },
    addOrUpdate() {      
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = {
              id: 0,
              invoiceId:this.invoiceId,
              name: this.name,
              amount : parseFloat(this.amount).toFixed(2),
              currencyId : this.currencyId,
              unitPrice : parseFloat(this.amount).toFixed(2),
              hour : 0,
              sequenceNo : this.count
          }

          const controller = {
            name: 'invoiceDetail'
          }
          const payload = {
            data: data,
            controller: controller
          }

           this.$store.dispatch('moduleAuth/save', payload).then((response) => {
            if (response.data.resultStatus == true) {
              this.$bvModal.hide('invoiceAddItemFormModal')
              this.showInvoiceInfo(this.invoiceId)

              this.$SaveAlert()
            } else {
              this.$WarningAlert(response.data.resultMessage)
            }
            })
        }
        })
      },  
    }
}
</script>
<style>
.tab-content > .active {
  display: block;
  width: 75em;
}
.cleaveStyle {
  max-height: 3em!important;
  height: 2.2em!important;
}
</style>