<template>
<div>
    <!-- modal -->
    <b-modal id="jobStatusRelationFormModal" centered size="xl" no-close-on-backdrop ref="jobStatusRelationFormModal" :title=" $t('jobStatusRelation.job_status_relation_info')" hide-footer>
        <div class="custom-search d-flex justify-content-end">
            <b-row>
                <b-col>
                    <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="addOrUpdate">
                        {{ $t('others.ok_title') }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="col-md-6">
                        <label for="job-status-relation-job-status-input">{{ $t('jobStatusRelation.job_status') }}</label>
                        <b-form-group>
                            <b-form>
                                <validation-provider :name=" $t('jobStatusRelation.job_status')" :rules="{ excluded:0}" v-slot="{ errors }">
                                    <b-form-select size="sm" v-model="jobStatusSelect" value-field="id" text-field="name" :options="jobStatusOption" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-4">
                        <label for="job-status-relation-job-reminder-criterion-input">{{ $t('jobStatusRelation.job_reminder_criterion') }}</label>
                        <b-form-group>
                            <b-form>
                                <validation-provider :name=" $t('jobStatusRelation.job_reminder_criterion')" :rules="{ excluded:0}" v-slot="{ errors }">
                                    <b-form-select size="sm" v-model="jobReminderCriterionSelect" value-field="id" text-field="name" :options="jobReminderCriterionOption" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-2">
                        <label for="job-status-relation-isFirstStep-input">{{ $t('jobStatusRelation.is_first_step') }}</label>
                        <br>
                        <b-form-checkbox class="custom-control-success" name="job-status-relation-isFirstStep-input" id="job-status-relation-isFirstStep-input" v-model="isFirstStep" switch>
                            <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                            </span>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <label for="job-status-relation-next-job-reminder-time-type-input">{{ $t('jobStatusRelation.next_job_reminder_time_type') }}</label>
                        <b-form-group>
                            <b-form>
                                <validation-provider :name=" $t('jobStatusRelation.next_job_reminder_time_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                                    <b-form-select size="sm" v-model="nextJobReminderTimeTypeSelect" value-field="id" text-field="name" :options="nextJobReminderTimeTypeOption" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-3">
                        <label for="job-status-relation-next-job-reminder-input">{{ $t('jobStatusRelation.next_job_reminder_value') }}</label>
                        <b-form-group>
                            <validation-provider :name=" $t('jobStatusRelation.next_job_reminder_value')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <b-form-input :state="errors.length > 0 ? false:null" id="job-status-relation-next-job-reminder-input" v-model="nextJobReminderValue" type="number" size="sm" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-3">
                        <label for="job-status-relation-tpmk-reminder-time-type-input">{{ $t('jobStatusRelation.tpmk_reminder_time_type') }}</label>
                        <b-form-group>
                            <b-form>
                                <validation-provider :name=" $t('jobStatusRelation.tpmk_reminder_time_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                                    <b-form-select size="sm" v-model="tPMKReminderTimeTypeSelect" value-field="id" text-field="name" :options="tPMKReminderTimeTypeOption" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-3">
                        <label for="job-status-relation-tpmk-reminder-input">{{ $t('jobStatusRelation.tpmk_reminder_value') }}</label>
                        <b-form-group>
                            <validation-provider :name=" $t('jobStatusRelation.tpmk_reminder_value')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <b-form-input :state="errors.length > 0 ? false:null" id="job-status-relation-tpmk-reminder-input" v-model="tpmkReminderValue" type="number" size="sm" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col>
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title="$t('jobStatusRelation.job_status_relation_detail')" style="width:100%;">
                            <b-card-text>
                                <job-status-relation-detail-list :jobStatusRelationId="this.id"></job-status-relation-detail-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getJobStatusRelationList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            isFirstStep: false,
            jobReminderCriterionOption: [],
            jobReminderCriterionSelect: 0,
            tPMKReminderTimeTypeOption: [],
            jobStatusSelect: 0,
            jobStatusOption: [],
            hiddenControl: true,
            jobStatusRelationId: 0,
            nextJobReminderTimeTypeId: 0,
            nextJobReminderValue: 0,
            tPMKReminderTimeTypeId: 0,
            tpmkReminderValue: 0,
            tPMKReminderTimeTypeSelect: 0,
            nextJobReminderTimeTypeOption: [],
            nextJobReminderTimeTypeSelect: 0,
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['jobStatusRelationFormModal'].show()
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'JobStatusRelation'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.tPMKReminderTimeTypeOption = response.data.tpmkReminderTimeTypeList
                    this.nextJobReminderTimeTypeOption = response.data.nextJobReminderTimeTypeList
                    this.jobStatusRelationName = response.data.jobStatusRelation.name
                    this.isFirstStep = response.data.jobStatusRelation.isFirstStep
                    this.jobStatusOption = response.data.jobStatusList
                    this.jobReminderCriterionOption = response.data.jobReminderCriterionList
                    this.nextJobReminderValue = response.data.jobStatusRelation.nextJobReminderValue
                    this.tpmkReminderValue = response.data.jobStatusRelation.tpmkReminderValue
                    //TPMKReminderValue -> tPMKReminderValue cannot be set from vue , i changed the variable name tpmkReminderValue in here. //Nevzat 25.12.2023
                    this.jobReminderCriterionOption.splice(0, 0, this.$nullSelected())
                    this.jobStatusOption.splice(0, 0, this.$nullSelected())
                    this.nextJobReminderTimeTypeOption.splice(0, 0, this.$nullSelected())
                    this.tPMKReminderTimeTypeOption.splice(0, 0, this.$nullSelected())
                    if (response.data.jobStatusRelation.tpmkReminderTimeTypeId > 0) {
                        this.tPMKReminderTimeTypeSelect = response.data.jobStatusRelation.tpmkReminderTimeTypeId
                    } else {
                        this.tPMKReminderTimeTypeSelect = 0
                    }
                    if (response.data.jobStatusRelation.nextJobReminderTimeTypeId > 0) {
                        this.nextJobReminderTimeTypeSelect = response.data.jobStatusRelation.nextJobReminderTimeTypeId
                    } else {
                        this.nextJobReminderTimeTypeId = 0
                    }
                    if (response.data.jobStatusRelation.jobReminderCriterionId > 0) {
                        this.jobReminderCriterionSelect = response.data.jobStatusRelation.jobReminderCriterionId
                    } else {
                        this.jobReminderCriterionSelect = 0
                    }
                    if (response.data.jobStatusRelation.timeTypeId > 0) {
                        this.timeTypeSelect = response.data.jobStatusRelation.timeTypeId
                    } else {
                        this.timeTypeSelect = 0
                    }
                    if (response.data.jobStatusRelation.jobStatusId > 0) {
                        this.jobStatusSelect = response.data.jobStatusRelation.jobStatusId
                    } else {
                        this.jobStatusSelect = 0
                    }
                } else {
                    this.$bvModal.hide('jobStatusRelationFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                    this.jobReminderCriterionSelect = 0
                    this.nextJobReminderTimeTypeSelect = 0
                    this.tPMKReminderTimeTypeSelect = 0
                    this.jobStatusSelect = 0
                    this.tpmkReminderValue = 0
                    this.nextJobReminderValue = 0
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                jobStatusId: this.jobStatusSelect,
                jobReminderCriterionId: this.jobReminderCriterionSelect,
                nextJobReminderValue: this.nextJobReminderValue,
                nextJobReminderTimeTypeId: this.nextJobReminderTimeTypeSelect,
                tpmkReminderValue: this.tpmkReminderValue,
                tPMKReminderTimeTypeId: this.tPMKReminderTimeTypeSelect,
                isFirstStep: this.isFirstStep,
            }
            const controller = {
                name: 'JobStatusRelation'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.Id = response.data.jobStatusRelationId
                            this.getJobStatusRelationList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.hiddenControl = false
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
