<template>
<div>
    <trademark-form :getTrademarkList="getTrademarkList" ref="trademarkFormModal"></trademark-form>
    <trademark-wipo-xml-upload-form ref="trademarkWipoXmlUploadForm" :getTrademarkList="getTrademarkList"></trademark-wipo-xml-upload-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="trademark-record-no-input">{{ $t('trademark.record_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="recordNo" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademark.record_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="trademark-application-no-input">{{ $t('trademark.application_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="applicationNo" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademark.application_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="trademark-register-no-input">{{ $t('trademark.register_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="registerNo" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademark.register_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="trademark-name-input">{{ $t('trademark.name') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="name" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademark.name')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-4  mt-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-right">
                    <b-button style="float:right;" size="sm" class="mr-05" variant="outline-primary" v-on:keyup.enter="getTrademarkList()" @click="getTrademarkList()">{{ $t('trademark.list') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('trademark.trademark_add') }}</b-button>
                    <b-dropdown style="width: 7em; height: 2.4em;" id="dropdown-grouped" variant="outline-primary" right size="sm" :text="$t('patent.process')">
                        <b-dropdown-item @click="getWipoXml(0)">
                            <span>{{ $t('trademark.add_from_wipo_xml') }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="exportExcel(0)">
                            <span>{{ $t('excel.excel') }}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1 mt-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">

                        <b-row>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="trademark-name-en-input">{{ $t('trademark.name_by_client') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="nameEn" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademark.name_by_client')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-agent-input">{{ $t('trademark.agent') }}</label>
                                <div class="d-flex align-items-center">
                                    <b-form-input size="sm" v-model="agent" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademark.agent')" type="text" class="d-inline-block" />
                                </div>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="trademark-owner-input">{{ $t('trademark.owner') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="owner" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademark.owner')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="trademark-first-register-no-input">{{ $t('trademark.first_register_no') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="firstRegisterNo" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademark.first_register_no')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="trademark-application-type-input">{{ $t('trademark.trademark_application_type') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-select v-model="trademarkApplicationTypeSelect" v-on:keyup.enter="getTrademarkList()" size="sm" value-field="id" text-field="name" :options="trademarkApplicationTypeOption" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="trademark-type-input">{{ $t('trademark.trademark_type') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-select v-model="trademarkTypeSelect" v-on:keyup.enter="getTrademarkList()" size="sm" value-field="id" text-field="name" :options="trademarkTypeOption" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="trademark-status-input">{{ $t('trademark.status') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-select v-model="trademarkStatusSelect" v-on:keyup.enter="getTrademarkList()" size="sm" value-field="id" text-field="name" :options="trademarkStatusOption" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="trademark-agent-ref-no-input">{{ $t('trademark.agent_ref_no') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="agentReferenceNo" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademark.agent_ref_no')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col class="col-3">
                                <label for="trademark-register-date-start-input">{{ $t('trademark.register_date_start') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.register_date_start')" size="sm" v-model="registerDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-register-date-end-input">{{ $t('trademark.register_date_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.register_date_end')" size="sm" v-model="registerDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-emendation-date-start-input">{{ $t('trademark.emendation_date_start') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.emendation_date_start')" size="sm" v-model="emendationDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3">
                                <label for="trademark-emendation-date-end-input">{{ $t('trademark.emendation_date_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.emendation_date_end')" size="sm" v-model="emendationDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3 mt-1">
                                <label for="trademark-bulletin-date-start-input">{{ $t('trademark.bulletin_date_start') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.bulletin_date_start')" size="sm" v-model="bulletinDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="trademark-bulletin-date-end-input">{{ $t('trademark.bulletin_date_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.bulletin_date_end')" size="sm" v-model="bulletinDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="trademark-instruction-date-start-input">{{ $t('trademark.instruction_date_start') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.instruction_date_start')" size="sm" v-model="instructionDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="trademark-instruction-date-end-input">{{ $t('trademark.instruction_date_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.instruction_date_end')" size="sm" v-model="instructionDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3 mt-1">
                                <label for="trademark-priority-document-date-start-input">{{ $t('trademark.priority_document_date_start') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.priority_document_date_start')" size="sm" v-model="priorityDocumentDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="trademark-priority-document-date-end-input">{{ $t('trademark.priority_document_date_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.priority_document_date_end')" size="sm" v-model="priorityDocumentDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="trademark-priority-document-deadline-date-start-input">{{ $t('trademark.priority_document_deadline_date_start') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.priority_document_deadline_date_start')" size="sm" v-model="priorityDocumentDeadlineDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="trademark-priority-document-deadline-date-end-input">{{ $t('trademark.priority_document_deadline_date_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.priority_document_deadline_date_end')" size="sm" v-model="priorityDocumentDeadlineDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col class="col-3 mt-1">
                                <label for="trademark-yidk-date-start-input">{{ $t('trademark.yidk_date_start') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.yidk_date_start')" size="sm" v-model="yIDKDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="trademark-yidk-date-end-input">{{ $t('trademark.yidk_date_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.yidk_date_end')" size="sm" v-model="yIDKDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="trademark-application-date-start-input">{{ $t('trademark.application_date_start') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.application_date_start')" size="sm" v-model="applicationDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="trademark-application-date-end-input">{{ $t('trademark.application_date_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademark.application_date_end')" size="sm" v-model="applicationDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="trademark-nice-class-input">{{ $t('trademark.nice_class') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="niceClass" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademark.nice_class')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="trademarkTable" ref="trademarkTable" :columns="columns" :rows="rows" :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }" styleClass="vgt-table condensed tableSize" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'logo'" class="text-nowrap">
                {{ $t('trademark.logo') }}
            </span>
            <span v-else-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('trademark.name') }}
            </span>
            <span v-else-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('trademark.trademark_folder_no') }}
            </span>
            <span v-else-if="props.column.field === 'applicationNo'" class="text-nowrap">
                {{ $t('trademark.application_no') }}
            </span>
            <span v-else-if="props.column.field === 'applicationDate'">
                {{ $t('trademark.application_date') }}
            </span>
            <span v-else-if="props.column.field === 'nextRenewalDate'">
                {{ $t('trademark.next_renewals_date') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('trademark.agent_name') }}
            </span>
            <span v-else-if="props.column.field === 'ownerList'" class="text-nowrap">
                {{ $t('trademark.owners') }}
            </span>
            <span v-else-if="props.column.field === 'niceClass'" class="text-nowrap">
                {{ $t('trademark.nice_class') }}
            </span>
            <span v-else-if="props.column.field === 'trademarkStatus'" class="text-nowrap">
                {{ $t('trademark.status') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Logo -->
            <span v-if="props.column.field === 'logo'" class="text-nowrap">
                <b-row>
                    <b-col v-if="props.row.logo != null" class="font-size: x-small">
                        <b-img>{{ 'data:image/jpeg;base64,'.concat(props.row.logo) }}</b-img>
                        <b-img :src="'data:image/jpeg;base64,'.concat(props.row.logo)" fluid alt="Responsive image" />
                    </b-col>
                </b-row>
            </span>
            <!-- Column: Agent Name -->
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.agent }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ props.row.clientAccounting }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'ownerList'">
                <b-col>
                    <b-row v-for="owner in props.row.ownerList" v-bind:data="owner" v-bind:key="owner.clientName" style="font-size: small;">
                        <tr v-if="props.row.ownerList.length != 1">
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                        <tr v-else-if="props.row.ownerList.length == 1">
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <!-- ,'100', '1000' -->
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getTrademarkList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    watch: {
        applicationDateStart(newValue) {
            if (newValue == '') {
                this.applicationDateStart = null
            }
        },
        applicationDateEnd(newValue) {
            if (newValue == '') {
                this.applicationDateEnd = null
            }
        },
        registerDateStart(newValue) {
            if (newValue == '') {
                this.registerDateStart = null
            }
        },
        registerDateEnd(newValue) {
            if (newValue == '') {
                this.registerDateEnd = null
            }
        },
        emendationDateStart(newValue) {
            if (newValue == '') {
                this.emendationDateStart = null
            }
        },
        emendationDateEnd(newValue) {
            if (newValue == '') {
                this.emendationDateEnd = null
            }
        },
        bulletinDateStart(newValue) {
            if (newValue == '') {
                this.bulletinDateStart = null
            }
        },
        bulletinDateEnd(newValue) {
            if (newValue == '') {
                this.bulletinDateEnd = null
            }
        },
        instructionDateStart(newValue) {
            if (newValue == '') {
                this.instructionDateStart = null
            }
        },
        instructionDateEnd(newValue) {
            if (newValue == '') {
                this.instructionDateEnd = null
            }
        },
        priorityDocumentDateStart(newValue) {
            if (newValue == '') {
                this.priorityDocumentDateStart = null
            }
        },
        priorityDocumentDateEnd(newValue) {
            if (newValue == '') {
                this.priorityDocumentDateEnd = null
            }
        },
        priorityDocumentDeadlineDateStart(newValue) {
            if (newValue == '') {
                this.priorityDocumentDeadlineDateStart = null
            }
        },
        priorityDocumentDeadlineDateEnd(newValue) {
            if (newValue == '') {
                this.priorityDocumentDeadlineDateEnd = null
            }
        },
        yIDKDateStart(newValue) {
            if (newValue == '') {
                this.yIDKDateStart = null
            }
        },
    },
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('trademark.logo'),
                    field: `logo`,
                },
                {
                    label: this.$t('trademark.name'),
                    field: `name`,
                },
                {
                    label: this.$t('trademark.trademark_folder_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('trademark.application_no'),
                    field: `applicationNo`,
                },
                {
                    label: this.$t('trademark.application_date'),
                    field: `applicationDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('trademark.next_renewals_date'),
                    field: `nextRenewalDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('trademark.agent_name'),
                    field: `agent`,
                },
                {
                    label: this.$t('trademark.owners'),
                    field: `ownerList`,
                },
                {
                    label: this.$t('trademark.nice_class'),
                    field: `trademarkNiceClass`,
                },
                {
                    label: this.$t('trademark.status'),
                    field: `trademarkStatus`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            agent: '',
            recordNo: '',
            agentReferenceNo: '',
            name: '',
            nameEn: '',
            trademarkTypeOption: [],
            trademarkTypeSelect: 0,
            trademarkStatusOption: [],
            trademarkStatusSelect: 0,
            trademarkApplicationTypeOption: [],
            trademarkApplicationTypeSelect: 0,
            applicationNo: '',
            registerNo: '',
            firstRegisterNo: '',
            applicationDateStart: null,
            applicationDateEnd: null,
            registerDateStart: null,
            registerDateEnd: null,
            emendationDateStart: null,
            emendationDateEnd: null,
            bulletinDateStart: null,
            bulletinDateEnd: null,
            instructionDateStart: null,
            instructionDateEnd: null,
            priorityDocumentDateStart: null,
            priorityDocumentDateEnd: null,
            priorityDocumentDeadlineDateStart: null,
            priorityDocumentDeadlineDateEnd: null,
            yIDKDateStart: null,
            yIDKDateEnd: null,
            owner: '',
            niceClass: '',
        }
    },
    mounted() {
        this.getTrademarkList()
    },
    methods: {
        async getWipoXml() {
            this.$refs.trademarkWipoXmlUploadForm.showInfo()
        },
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'Trademark'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getTrademarkList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getTrademarkList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.trademarkFormModal.showInfo(id)
        },

        getTrademarkList() {
            this.rows = []
            const controller = {
                name: 'Trademark'
            }
            const data = {
                recordNo: this.recordNo,
                agent: this.agent,
                agentReferenceNo: this.agentReferenceNo,
                name: this.name,
                nameEn: this.nameEn,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                trademarkTypeId: this.trademarkTypeSelect,
                trademarkStatusId: this.trademarkStatusSelect,
                trademarkApplicationTypeId: this.trademarkApplicationTypeSelect,
                applicationNo: this.applicationNo,
                registerNo: this.registerNo,
                firstRegisterNo: this.firstRegisterNo,
                applicationDateStart: this.applicationDateStart,
                applicationDateEnd: this.applicationDateEnd,
                registerDateStart: this.registerDateStart,
                registerDateEnd: this.registerDateEnd,
                emendationDateStart: this.emendationDateStart,
                emendationDateEnd: this.emendationDateEnd,
                bulletinDateStart: this.bulletinDateStart,
                bulletinDateEnd: this.bulletinDateEnd,
                instructionDateStart: this.instructionDateStart,
                instructionDateEnd: this.instructionDateEnd,
                priorityDocumentDateStart: this.priorityDocumentDateStart,
                priorityDocumentDateEnd: this.priorityDocumentDateEnd,
                priorityDocumentDeadlineDateStart: this.priorityDocumentDeadlineDateStart,
                priorityDocumentDeadlineDateEnd: this.priorityDocumentDeadlineDateEnd,
                yIDKDateStart: this.yIDKDateStart,
                yIDKDateEnd: this.yIDKDateEnd,
                owner: this.owner,
                niceClass: this.niceClass
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    this.trademarkStatusOption = response.data.trademarkStatusList
                    this.trademarkApplicationTypeOption = response.data.trademarkApplicationTypeList
                    this.trademarkTypeOption = response.data.trademarkTypeList
                    this.trademarkTypeOption.splice(0, 0, this.$nullSelected())
                    this.trademarkApplicationTypeOption.splice(0, 0, this.$nullSelected())
                    this.trademarkStatusOption.splice(0, 0, this.$nullSelected())

                    response.data.trademarkList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        },
        exportExcel() {

            var controller = {
                name: 'Trademark'
            }
            var data = {
                recordNo: this.recordNo,
                agent: this.agent,
                agentReferenceNo: this.agentReferenceNo,
                name: this.name,
                nameEn: this.nameEn,
                pageLength: 100000,
                pageNo: 1,
                trademarkTypeId: this.trademarkTypeSelect,
                trademarkStatusId: this.trademarkStatusSelect,
                trademarkApplicationTypeId: this.trademarkApplicationTypeSelect,
                applicationNo: this.applicationNo,
                registerNo: this.registerNo,
                firstRegisterNo: this.firstRegisterNo,
                applicationDateStart: this.applicationDateStart,
                applicationDateEnd: this.applicationDateEnd,
                registerDateStart: this.registerDateStart,
                registerDateEnd: this.registerDateEnd,
                emendationDateStart: this.emendationDateStart,
                emendationDateEnd: this.emendationDateEnd,
                bulletinDateStart: this.bulletinDateStart,
                bulletinDateEnd: this.bulletinDateEnd,
                instructionDateStart: this.instructionDateStart,
                instructionDateEnd: this.instructionDateEnd,
                priorityDocumentDateStart: this.priorityDocumentDateStart,
                priorityDocumentDateEnd: this.priorityDocumentDateEnd,
                priorityDocumentDeadlineDateStart: this.priorityDocumentDeadlineDateStart,
                priorityDocumentDeadlineDateEnd: this.priorityDocumentDeadlineDateEnd,
                yIDKDateStart: this.yIDKDateStart,
                yIDKDateEnd: this.yIDKDateEnd,
                owner: this.owner,
                isShowLogo: false
            }
            var payload = {
                data: data,
                controller: controller
            }

            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    controller = {
                        name: 'Trademark',
                        actionName: 'CanExport'
                    }
                    data = {
                        columnList: this.columns,
                        dataList: response.data.trademarkList
                    }
                    payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$downloadFile("Marka", response.data.base64, 'xlsx')
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                        loader.hide()
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }

            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.featherIconReSize {
    width: 12px;
    height: 12px;
}

[dir=ltr] .mr-05,
[dir=ltr] .mx-1 {
    margin-right: 0.5rem !important;
}
</style>
