<template>
    <div>
      <!-- modal -->
      <b-modal
        id="designOppositionBasisForOppositionFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="designOppositionBasisForOppositionFormModal"
        :title=" $t('designOppositionBasisForOpposition.opposition_basis_info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
        @close="clear()"
      >
      <validation-observer ref="infoRules" tag="form">
        <b-row>
         <b-col class="col-md-12 mt-1">
            <b-form>
                  <label for="designOppositionBasisForOpposition-Name-input">{{ $t('designOppositionBasisForOpposition.name') }}</label>
                  <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name=" $t('designOppositionBasisForOpposition.name')"
                    rules="required"
                  >
                  <b-form-input
                    id="designOppositionBasisForOpposition-Name-input"
                    v-model="designOppositionBasisForOppositionName"
                    maxlength="50"
                    :state="errors.length > 0 ? false:null"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
                </b-form>
              </b-col>
        </b-row>
      </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>

  export default {
    props: {
      getDesignOppositionBasisForOppositionList: {type: Function},
      getDesignOppositionList: {type: Function},
    },
    data() {
      return {
        id: 0,
        designOppositionId:0,
        designOppositionBasisForOppositionName : '',
      }
    },
    methods: {
      showInfo(id, designOppositionId) {
        this.$refs['designOppositionBasisForOppositionFormModal'].show()
        this.id= id
        this.designOppositionId= designOppositionId
        const data = {id : this.id }
        const controller = {name : 'DesignOppositionBasisForOpposition'}
        const payload = { data: data , controller : controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
          this.designOppositionBasisForOppositionName = response.data.designOppositionBasisForOpposition.name
        })
      },
      addOrUpdate() {
        const data = {id: this.id, designOppositionId: this.designOppositionId ,name: this.designOppositionBasisForOppositionName}
        const controller = { name: 'DesignOppositionBasisForOpposition' }
        const payload = { data: data, controller: controller }
        this.$refs.infoRules.validate().then(success => {
          if (success) {
                this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                  if(response.data.resultStatus == true){
                    this.$bvModal.hide('designOppositionBasisForOppositionFormModal')
                    this.designOppositionBasisForOppositionName  = ''
                    this.getDesignOppositionBasisForOppositionList()
                    this.getDesignOppositionList()
                    this.$SaveAlert()
                  } else {
                    this.$WarningAlert(response.data.resultMessage)
                  }
              })
              }
            })
      },
      clear(){
        this.designOppositionBasisForOppositionName = null
      }
      },
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  