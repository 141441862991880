<template>
    <div>
      <!-- modal -->
      <b-modal
        id="agentAnnuityPriceDetailFormModal"
        centered
        size="lg"
        no-close-on-backdrop
        ref="agentAnnuityPriceDetailFormModal"
        :title=" $t('agentAnnuityPriceDetail.info')"
        :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')"
        cancel-variant="outline-secondary"
        @ok.prevent="addOrUpdate"
      >
      <validation-observer ref="simpleRules">
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="agent-annuity-detail-stock-fee-input">{{ $t('agentAnnuityPriceDetail.stock_fee') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('agentAnnuityPriceDetail.stock_fee')" rules="required">
                            <b-form-input id="agent-annuity-detail-stock-fee-input" size="sm" v-model="stockFee" type="number" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="agent-annuity-detail-tpmk-fee-input">{{ $t('agentAnnuityPriceDetail.tpmk_fee') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('agentAnnuityPriceDetail.tpmk_fee')" rules="required">
                            <b-form-input id="agent-annuity-detail-tpmk-fee-input" size="sm" disabled v-model="tpmkFee" type="number" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="agent-annuity-detail-official-fee-input">{{ $t('agentAnnuityPriceDetail.official_fee') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('agentAnnuityPriceDetail.official_fee')" rules="required">
                            <b-form-input id="agent-annuity-detail-official-fee-input" size="sm" v-model="officialFee" type="number" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="agent-annuity-detail-tpmk-fee-input">{{ $t('agentAnnuityPriceDetail.tpmk_fine_fee') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('agentAnnuityPrice.tpmk_fine_fee')" rules="required">
                            <b-form-input id="agent-annuity-detail-tpmk-fee-input" size="sm" disabled v-model="tpmkFineFee" type="number" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="agent-annuity-detail-official-fine-fee-input">{{ $t('agentAnnuityPriceDetail.official_fine_fee') }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name=" $t('agentAnnuityPriceDetail.official_fine_fee')" rules="required">
                            <b-form-input id="agent-annuity-detail-official-fine-fee-input" size="sm" v-model="officialFineFee" type="number" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
        getAgentAnnuityPriceList: {type: Function},
        getAgentAnnuityPriceDetailList: {type: Function}
    },
    data() {
      return {
        tpmkCode: '',
        tpmkName: '',
        stockFee: null,
        tpmkFee: null,
        tpmkFineFee :null,
        officialFee: null,
        officialFineFee: null
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['agentAnnuityPriceDetailFormModal'].show()
        this.rows = []
        this.id = id
        const data = {id: this.id}
        const controller = {name: 'AgentAnnuityPrice', actionName: 'GetAgentAnnuityPriceListByAgentAnnuityId'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.tpmkCode = response.data.agentAnnuityPrice.tpmkCode
                this.tpmkName = response.data.agentAnnuityPrice.tpmkName
                this.stockFee = response.data.agentAnnuityPrice.stockFee
                this.tpmkFee = response.data.agentAnnuityPrice.tpmkFee
                this.tpmkFineFee = response.data.agentAnnuityPrice.tpmkFineFee
                this.officialFee = response.data.agentAnnuityPrice.officialFee
                this.officialFineFee = response.data.agentAnnuityPrice.officialFineFee
            } else {
                this.$bvModal.hide('agentAnnuityPriceDetailFormModal')
                this.$bvModal.hide('agentAnnuityPriceFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
      const data = {id: this.id, stockFee: this.stockFee, officialFee: this.officialFee, officialFineFee: this.officialFineFee}
      const controller = {name: 'AgentAnnuityPrice', actionName: 'SaveAgentAnnuityPrice'}
      const payload = {data: data, controller: controller}
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
              this.$store.dispatch('moduleAuth/save',payload).then((response) => {
                if(response.data.resultStatus == true){
                  this.$bvModal.hide('agentAnnuityPriceFormModal')
                  this.getAgentAnnuityPriceDetailList()
                  this.getAgentAnnuityPriceList()
                  this.$SaveAlert()
                } else {
                  this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
      })
    },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  </style>
  