<template>
<div>
    <div class="table-container">
    <!-- table -->
      <vue-good-table :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" 
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }" 
        
        :pagination-options="{
          enabled: false,
          perPage: pageLength
        }" theme="my-theme">
          <div slot="emptystate">
              {{ $t('dataTable.data_not_found') }}
          </div>              
          <!-- Row: Table Row -->
          <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'translatorPaymentId'" class="text-nowrap">
                {{ $t('translatorPaymentTranslations.translator_payment_id') }}
            </span>
              <span v-else-if="props.column.field === 'patentRecordNo'" class="text-nowrap">
                  {{ $t('translatorPaymentTranslations.our_ref') }}
              </span>
              <span v-else-if="props.column.field === 'givenDate'" class="text-nowrap">
                  {{ $t('translatorPaymentTranslations.given_date') }}
              </span>
              <span v-else-if="props.column.field === 'translatorDeadlineDate'" class="text-nowrap">
                  {{ $t('translatorPaymentTranslations.deadline_date') }}
              </span>
              <span v-else-if="props.column.field === 'optionDate'" class="text-nowrap">
                  {{ $t('translatorPaymentTranslations.option_date') }}
              </span>
              <span v-else-if="props.column.field === 'translatorDeliveryDate'" class="text-nowrap">
                  {{ $t('translatorPaymentTranslations.delivery_date') }}
              </span>
              <span v-else-if="props.column.field === 'deliveryToTranslatorDate'" class="text-nowrap">
                  {{ $t('translatorPaymentTranslations.time_left_to_delivery') }}
              </span>
              <span v-else-if="props.column.field === 'wordCount'" class="text-nowrap">
                  {{ $t('translatorPaymentTranslations.word_count') }}
              </span>
              <span v-else-if="props.column.field === 'imageCount'" class="text-nowrap">
                  {{ $t('translatorPaymentTranslations.word_image_count') }}
              </span>
              <span v-else-if="props.column.field === 'formulaCount'" class="text-nowrap">
                  {{ $t('translatorPaymentTranslations.formula_count') }}
              </span>
              <span v-else-if="props.column.field === 'tableCount'" class="text-nowrap">
                  {{ $t('translatorPaymentTranslations.table_count') }}
              </span>
              <span v-else-if="props.column.field === 'pageCount'" class="text-nowrap">
                  {{ $t('translatorPaymentTranslations.array_list_count') }}
              </span>
          </template>



              <template slot="table-row" slot-scope="props">
          <!-- Column: TranslatorPayment Transactions Name -->

          <span v-if="props.column.field === 'translatorPaymentId'">
            <b-form-checkbox :checked="checkStatus(props.row.translatorPaymentId)"  @change.native="save($event.target.checked, props.row)"/>
          </span>
        </template>
      </vue-good-table>
    </div>
</div>
</template>

<script>
export default {
    props: {
        getTranslatorPaymentList: Function,
    },
    data() {
        return {
            id: 0,
            pageLength: 10,
            dir: false,
            translatorPaymentList: [],
            selected: null,
            columns: [
                {
                    label: this.$t('translatorPaymentTranslations.translator_payment_id'),
                    field: `translatorPaymentId`,
                },
                {
                    label: this.$t('translatorPaymentTranslations.our_ref'),
                    field: `patentRecordNo`,
                },
                {
                    label: this.$t('translatorPaymentTranslations.given_date'),
                    field: `givenDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('translatorPaymentTranslations.deadline_date'),
                    field: `translatorDeadlineDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('translatorPaymentTranslations.option_date'),
                    field: `optionDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('translatorPaymentTranslations.delivery_date'),
                    field: `translatorDeliveryDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('translatorPaymentTranslations.time_left_to_delivery'),
                    field: `deliveryToTranslatorDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('translatorPaymentTranslations.word_count'),
                    field: `wordCount`,
                },
                {
                    label: this.$t('translatorPaymentTranslations.word_image_count'),
                    field: `imageCount`,
                },
                {
                    label: this.$t('translatorPaymentTranslations.formula_count'),
                    field: `formulaCount`,
                },
                {
                    label: this.$t('translatorPaymentTranslations.table_count'),
                    field: `tableCount`,
                },
                {
                    label: this.$t('translatorPaymentTranslations.array_list_count'),
                    field: `pageCount`,
                },
            ],
            rows: [],
            searchTerm: '',
            translatorPaymentId: 0,
            selectControl: false
        }
    },
    mounted() {
        this.getTranslatorPaymentList()
    },
    methods: {
        checkStatus(translatorPaymentId) {
        if(translatorPaymentId == 0)
            return false
        else
            return true;
    },
        getTranslatorPaymentTranslationsList(translatorPaymentId, translatorId) {
            this.translatorPaymentId = translatorPaymentId
                const data = {
                    translatorId: translatorId,
                    translatorPaymentId: translatorPaymentId
                }
                const controller = {
                    name: 'PatentTranslate',
                    actionName: 'GetPatentTranslateListByTranslatorPaymentIdAndTranslatorId'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        response.data.patentTranslateList.forEach((element) => { 
                            this.rows.push(element);
                        });
                    }
                })
            
        },
        save(selected, params) {
          this.id = params.id
          const controller = { name: 'PatentTranslate', actionName: 'SavePatentTranslatePayment' }
          if (selected) {
            const data = { patentTranslateId: this.id, translatorPaymentId: this.translatorPaymentId}
            const payload = { data: data, controller: controller }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$SaveAlert()
              } else {
                this.$WarningAlert(response.data.resultMessage)
              }
            })
          }
          else {
            const data = { patentTranslateId: this.id, translatorPaymentId: 0}
            const payload = { data: data, controller: controller }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$SaveAlert()
              } else {
                this.$WarningAlert(response.data.resultMessage)
              }
            })
          }
        },
    },
}
</script>

<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
.table-container {
  max-height: 400px; /* Tablonun maksimum yüksekliği, gereksinimlerinize göre ayarlayın */
  overflow-y: auto; /* Dikey kaydırma çubuğunu etkinleştir */
}
.vgt-table thead th.vgt-checkbox-col {
    vertical-align: middle;
    visibility: hidden;
}
</style>