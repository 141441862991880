var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"updateFieldsFormModal",attrs:{"id":"updateFieldsFormModal","centered":"","size":"xl","no-close-on-backdrop":"","title":_vm.$t('updateFields.update_fields_change'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"close":function($event){return _vm.clear()},"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('h5',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.$t('updateFields.agent_information')))]),_c('b-row',[_c('b-col',{staticClass:"col-lg-6 mt-1"},[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('updateFields.agent'),"rules":{ excluded: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('updateFields.agent'),"label-for":"updatefieldsagent","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"updatefieldsagent","options":_vm.updateFieldsAgentOption,"label":"clientName"},on:{"input":_vm.getClientResponsibleList,"search":_vm.onSearchAgent},model:{value:(_vm.updateFieldsAgentSelect),callback:function ($$v) {_vm.updateFieldsAgentSelect=$$v},expression:"updateFieldsAgentSelect"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{staticClass:"col-lg-6 mt-1"},[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('updateFields.owner'),"rules":{ excluded: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('updateFields.owner'),"label-for":"update-fields-owner","state":_vm.updateFieldsAgentSelect > 0 ? false:true}},[_c('v-select',{attrs:{"id":"update-fields-owner","options":_vm.updateFieldsOwnerOption,"label":"name"},on:{"input":_vm.setOwnerId,"search":_vm.onSearchOwner},model:{value:(_vm.updateFieldsOwnerSelect),callback:function ($$v) {_vm.updateFieldsOwnerSelect=$$v},expression:"updateFieldsOwnerSelect"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.updateFieldsAgentSelect > 0 ? false:true}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('h5',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.$t('updateFields.related_information')))]),_c('b-row',[_c('b-col',{staticClass:"col-lg-12 mt-1"},[_c('vue-good-table',{ref:"updateFieldsRelatedTable",attrs:{"id":"updateFieldsRelatedTable","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table condensed tableSize","select-options":{
                    enabled: true,
                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                },"theme":"my-theme"},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field === 'responsibleName')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('updateFields.responsible_name'))+" ")]):(props.column.field === 'email')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('updateFields.email'))+" ")]):(props.column.field === 'mailContactType')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('updateFields.mail_contact_type'))+" ")]):(props.column.field === 'responsibleField')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('updateFields.responsible_field'))+" ")]):_vm._e()]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.data_not_found'))+" ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }