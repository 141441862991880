<template>
    <div>
        <b-modal
            id="trademarkOppositionResponsibleFormModal"
            centered
            size="lg"
            no-close-on-backdrop
            ref="trademarkOppositionResponsibleFormModal"
            :title=" $t('trademarkOppositionResponsible.responsible_info')"
            :cancel-title=" $t('others.cancel_title')"
            :ok-title=" $t('others.ok_title')"
            cancel-variant="outline-secondary"
            @ok.prevent="addOrUpdate"
            hide-footer
        >
        <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="LockIcon" size="18" />
                <span class="align-middle ml-50">{{  $t('trademarkOppositionResponsible.responsible')  }}</span>
              </b-card-title>
            </b-card-header>
            <!--eslint-disable-->
            <table class="table table-striped table-bordered">
              <tbody>
                <tr v-for="trademarkOppositionResponsibleItem in trademarkOppositionResponsibleList" :key="trademarkOppositionResponsibleItem.id">
                  <td >
                      <b-form-checkbox @change="changeResponsible($event, trademarkOppositionResponsibleItem.id)" />                                 
                  </td>
                  <td>{{  trademarkOppositionResponsibleItem.name  }}</td>

                </tr>
              </tbody>
            </table>
          </b-card>
        </b-modal>
    </div>
</template>
<script>
export default{
  props: {
      getTrademarkOppositionResponsibleList: {type: Function},
      getTrademarkOppositionList: {
            type: Function
        },
    },
  data(){
    return {
      id: 0,
      trademarkOppositionResponsibleList:[]
    }
  },
  methods:{
    changeResponsible(selected, clientResponsibleId) {
      const controller = { name: 'TrademarkOppositionResponsible' }
      if (selected == true) { 
        const data = { trademarkOppositionId: this.trademarkOppositionId, clientResponsibleId: clientResponsibleId }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.getTrademarkOppositionResponsibleList()
            this.$SaveAlert()
            this.getTrademarkOppositionList()

          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
    },
    showInfo(trademarkOppositionId, clientId) {
      this.$refs['trademarkOppositionResponsibleFormModal'].show()
          this.trademarkOppositionId= trademarkOppositionId
          const data = {clientId: clientId,trademarkOppositionId:trademarkOppositionId}
          const controller = {name: 'ClientResponsible', actionName:'GetClientResponsibleListForModules'}
          const payload = {data: data, controller: controller}
          this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
              if(response.data.resultStatus == true){    
                  this.trademarkOppositionResponsibleList = response.data.clientResponsibleList
              } else {
                  this.$bvModal.hide('trademarkOppositionResponsibleFormModal')
                  this.$WarningAlert(response.data.resultMessage)
              }  
          }) 
      },
  }
}
</script>