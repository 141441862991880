<template>
<div>
    <annuity-detail-form ref="annuityDetailFormModal" :getAnnuityList="getAnnuityList" :getAnnuityDetailList="getAnnuityDetailList"></annuity-detail-form>
    <patent-form ref="patentFormModal"></patent-form>
    <design-form ref="designFormModal"></design-form>
    <!-- table -->
    <vue-good-table ref="annuityDetailTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm}" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
            enabled: true,
        perPage: pageLength
        }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'operationRecord.referanceNo'" class="text-nowrap" style="font-size: smaller;">
                {{ $t('annuityDetail.referance_no') }}
            </span>
            <span v-else-if="props.column.field ==='isPatent'" class="text-nowrap" style="font-size: smaller;">
                {{ $t('annuityDetail.type') }}
            </span>
            <span v-if="props.column.field === 'operationRecord.applicationNo'" class="text-nowrap" style="font-size: smaller;">
                {{ $t('annuityDetail.tr_application_no') }}
                <br />
                {{ $t('annuityDetail.tr_application_date') }}
            </span>
            <span v-else-if="props.column.field ==='recordNo'" class="text-nowrap" style="font-size: smaller;">
                {{ $t('annuityDetail.record_no') }}
            </span>
            <span v-if="props.column.field ==='applicationNo'" class="text-nowrap" style="font-size: smaller;">
                {{ $t('annuityDetail.application_no') }}
            </span>
            <span v-if="props.column.field ==='applicationDate'" class="text-nowrap" style="font-size: smaller;">
                {{ $t('annuityDetail.application_date') }}
            </span>
            <span v-else-if="props.column.field ==='year'" class="text-nowrap" style="font-size: smaller;">
                {{ $t('annuityDetail.year') }}
                <br />
                {{ $t('annuityDetail.stock_year') }}
            </span>
            <span v-else-if="props.column.field ==='holderName'" style="font-size: smaller;">
                {{ $t('annuityDetail.holder_name') }}
            </span>
            <span v-else-if="props.column.field ==='price'" class="text-nowrap" style="font-size: smaller;">
                {{ $t('annuityDetail.price') }}
            </span>
            <span v-else-if="props.column.field ==='annuityDetailStatus'" class="text-nowrap" style="font-size: smaller;">
                {{ $t('annuityDetail.status') }}
            </span>
            <span v-else-if="props.column.field ==='receiptNo'" class="text-nowrap" style="font-size: smaller;">
                {{ $t('annuityDetail.receipt_no') }}
                <br />
                {{ $t('annuityDetail.receipt_date') }}
            </span>
            <span v-else-if="props.column.field ==='isLateFee'" class="text-nowrap" style="font-size: xx-small;">
                {{ $t('annuityDetail.is_late_fee') }}
            </span>
            <span v-else-if="props.column.field ==='isNoPayment'" style="font-size: xx-small;">
                {{ $t('annuityDetail.is_no_payment') }}
            </span>
            <span v-else-if="props.column.field ==='isApplicationInvalid'" style="font-size: xx-small;">
                {{ $t('annuityDetail.is_application_invalid') }}
            </span>
            <span v-else-if="props.column.field ==='process'" class="text-nowrap" style="font-size: smaller;">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span :style="[ props.row.recordId === 0 ? { 'color': 'red' } :  (props.row.officialFeeId === 0 ? { 'color': 'orange' } :  '')]">
                <span v-if="props.column.field === 'operationRecord.referanceNo'" class="text-nowrap" style="font-size: smaller;">
                    <b-badge v-if="props.row.recordId != 0" variant="primary" style="cursor: pointer;" @click="showOperationInfo(props.row.recordId, props.row.moduleId)">
                        <feather-icon icon="MousePointerIcon" class="mr-25" />
                        <span>{{ props.row.operationRecord.recordNo }}</span>
                    </b-badge>
                </span>
                <span v-else-if="props.column.field === 'isPatent'" class="text-nowrap" style="font-size: smaller;">
                    <span v-if="props.row.moduleId === 5">Tasarım</span>
                    <span v-else-if="props.row.isPatent">Patent</span>
                    <span v-else-if="props.row.isPatent === false">F.Model</span>
                </span>
                <span v-else-if="props.column.field === 'operationRecord.applicationNo'" class="text-nowrap" style="font-size: smaller;">
                    <!-- operationRecord can be Null thats why i added this control 06.11.2024 Nevzat -->
                    <span v-if="props.row.operationRecord != null">
                        <span>{{props.row.operationRecord.applicationNo}}</span>
                        <br />
                        <span>{{$formatFn(props.row.operationRecord.applicationDate)}}</span>
                    </span>
                    </span>
                    <!--  -->
                    <span v-else-if="props.column.field === 'recordNo'" class="text-nowrap" style="font-size: smaller;">
                        <span>{{props.row.recordNo}}</span>
                    </span>
                    <span v-else-if="props.column.field ==='applicationNo'" class="text-nowrap" style="font-size: smaller;">
                        <span>{{props.row.applicationNo}}</span>
                    </span>
                    <span v-else-if="props.column.field ==='applicationDate'" class="text-nowrap" style="font-size: smaller;">
                        <span>{{$formatFn(props.row.applicationDate)}}</span>
                    </span>
                    <span stayle="" v-else-if="props.column.field ==='year'" class="text-nowrap" style="font-size: smaller;">
                        <span>{{props.row.year}} / {{props.row.stockYear}}</span>
                    </span>
                    <span v-else-if="props.column.field ==='holderName'" style="font-size: smaller;">
                        <span>{{props.row.holderName}}</span>
                    </span>
                    <span v-else-if="props.column.field ==='price'" class="text-nowrap" style="font-size: smaller;">
                        <span>{{props.row.price}}</span>
                    </span>
                    <span v-else-if="props.column.field ==='annuityDetailStatus'" style="font-size: smaller;">
                        <span>{{props.row.annuityDetailStatus}}</span>
                    </span>
                    <span v-else-if="props.column.field ==='receiptNo'" style="font-size: smaller;">
                        <span>{{props.row.receiptNo}}</span>
                        <br />
                        <span>{{ $formatFn(props.row.receiptDate) }}</span>
                    </span>
                    <span v-else-if="props.column.field === 'isLateFee'" style="font-size: smaller;">
                        <b-form-checkbox disabled v-model="props.row.isLateFee" />
                    </span>
                    <span v-else-if="props.column.field === 'isNoPayment'" style="font-size: smaller;">
                        <b-form-checkbox disabled v-model="props.row.isNoPayment" />
                    </span>
                    <span v-else-if="props.column.field === 'isApplicationInvalid'" style="font-size: smaller;">
                        <b-form-checkbox disabled v-model="props.row.isApplicationInvalid" />
                    </span>

                    <!-- Column: Process -->
                    <span v-else-if="props.column.field === 'process'" style="font-size: smaller;">
                        <span>
                            <b-button v-b-tooltip.html :title=" $t('others.edit')" v-b-modal.modal-center variant="primary" class="btn-icon mb-1 ml-1" size="xxs" @click="getInfo(props.row.id)">
                                <feather-icon icon="EditIcon" />
                            </b-button>

                            <b-button v-if="props.row.documentId != 0" v-b-tooltip.html :title="$t('others.download')" v-b-modal.modal-center variant="success" class="btn-icon mb-1 ml-1 btn-xxs" s @click="download(props.row)">
                                <feather-icon icon="DownloadIcon" />
                            </b-button>
                        </span>
                    </span>
                </span>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                    </span>
                    <b-form-select v-model="pageLength" :options="['12', '24', '48', '60']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    props: {
        getAnnuityList: {
            type: Function
        },
        annuityId: {
            type: Number
        },
    },
    data() {
        return {
            columns: [{
                    label: this.$t('annuityDetail.referance_no'),
                    field: 'operationRecord.referanceNo',
                    type: "string",
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.type'),
                    field: 'isPatent',
                    type: "string",
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.tr_application_date'),
                    field: 'operationRecord.applicationNo',
                    type: "string",
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.record_no'),
                    field: 'recordNo',
                    type: "string",
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.module'),
                    field: 'module',
                    type: "string",
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.application_no'),
                    field: 'applicationNo',
                    sortable: false,
                    type: "string",
                },
                {
                    label: this.$t('annuityDetail.application_date'),
                    field: 'applicationDate',
                    formatFn: this.$formatFn,
                    type: "string",
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.year'),
                    field: 'year',
                    type: "string",
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.holder_name'),
                    field: 'holderName',
                    type: "string",
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.price'),
                    field: 'price',
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.status'),
                    field: 'annuityDetailStatus',
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.receipt_no'),
                    field: 'receiptNo',
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.is_late_fee'),
                    field: 'isLateFee',
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.is_no_payment'),
                    field: 'isNoPayment',
                    sortable: false
                },
                {
                    label: this.$t('annuityDetail.is_application_invalid'),
                    field: 'isApplicationInvalid',
                    sortable: false
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em',
                    sortable: false
                },
            ],
            rows: [],
            searchTerm: '',
            pageLength: 12,
        }
    },
    mounted() {
        this.getAnnuityDetailList()
    },
    methods: {
        async getInfo(id) {
            this.$refs.annuityDetailFormModal.showInfo(id)
        },
        showOperationInfo(recordId, moduleId) {
            if (moduleId == 5)
                this.$refs.designFormModal.showInfo(recordId)
            else
                this.$refs.patentFormModal.showInfo(recordId)
        },
        getAnnuityDetailList() {
            this.rows = []
            const controller = {
                name: 'AnnuityDetail',
                actionName: 'GetAnnuityDetailListByAnnuityId'
            }
            const data = {
                id: this.annuityId
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.annuityDetailList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        async download(data) {
            data = {
                id: data.documentId
            }
            const controller = {
                name: 'Document'
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/download', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(response.data.document.name, response.data.base64, response.data.document.type)
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
