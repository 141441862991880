<template>
<div>
    <b-modal id="clientInvoiceReportListCallForm" centered size="xl" no-close-on-backdrop ref="clientInvoiceReportListCallForm" hide-footer>
        <client-invoice-report-list :type="this.type" :clientAccountingId="this.clientAccountingId" :moduleId="this.moduleId" :recordId="this.recordId"></client-invoice-report-list>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {},
    data() {
        return {
            moduleId: 0,
            clientAccountingId: 0,
            type: 0,
            recordId: 0
        }
    },
    methods: {
        showInfo(type, clientAccountingId, moduleId, recordId) {
            this.moduleId = moduleId,
                this.type = type,
                this.clientAccountingId = clientAccountingId,
                this.recordId = recordId
            this.$refs["clientInvoiceReportListCallForm"].show()
        }
    }
}
</script>
