var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"agentAnnuityPriceFormModal",attrs:{"id":"agentAnnuityPriceFormModal","centered":"","size":"xl","no-close-on-backdrop":"","title":_vm.$t('agentAnnuityPrice.agent_annuity_price_detail_info'),"hide-footer":""}},[_c('validation-observer',{ref:"simpleRules"},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_vm._v(" "+_vm._s(_vm.$t('others.ok_title'))+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('agentAnnuityPrice.client'),"rules":{ excluded: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('agentAnnuityPrice.client'),"label-for":"agent-annuity-price-client-input","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"agent-annuity-price-client-input","size":"sm","options":_vm.agentOption,"label":"name"},on:{"input":_vm.setAgentId,"search":_vm.onSearchAgent},model:{value:(_vm.agentSelect),callback:function ($$v) {_vm.agentSelect=$$v},expression:"agentSelect"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('agentAnnuityPrice.currency'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"agent-annuity-price-code-input"}},[_vm._v(_vm._s(_vm.$t('agentAnnuityPrice.currency')))]),_c('b-form-select',{attrs:{"size":"sm","state":errors.length > 0 ? false:null,"value-field":"id","text-field":"name","options":_vm.currencyOption},model:{value:(_vm.currencySelect),callback:function ($$v) {_vm.currencySelect=$$v},expression:"currencySelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('label',{attrs:{"for":"agent-annuity-price-stock-fee-input"}},[_vm._v(_vm._s(_vm.$t('agentAnnuityPrice.stock_fee')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('agentAnnuityPrice.stock_fee'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"agent-annuity-price-stock-fee-input","size":"sm","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.stockFee),callback:function ($$v) {_vm.stockFee=$$v},expression:"stockFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('label',{attrs:{"for":"agent-annuity-price-currency-input"}},[_vm._v(_vm._s(_vm.$t('agentAnnuityPrice.stabil_currency')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('agentAnnuityPrice.stabil_currency'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"agent-annuity-price-currency-input","size":"sm","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.currencyValue),callback:function ($$v) {_vm.currencyValue=$$v},expression:"currencyValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('label',{attrs:{"for":"agent-annuity-price-explanation-input"}},[_vm._v(_vm._s(_vm.$t('agentAnnuityPrice.explanation')))]),_c('b-form-group',[_c('b-form-input',{attrs:{"id":"agent-annuity-price-explanation-input","size":"sm","maxlength":"250"},model:{value:(_vm.explanation),callback:function ($$v) {_vm.explanation=$$v},expression:"explanation"}})],1)],1)],1),_c('b-col',{staticClass:"col-lg-6"},[_c('b-form',[_c('label',{attrs:{"for":"agent-annuity-price-status-input"}},[_vm._v(" "+_vm._s(_vm.$t('agentAnnuityPrice.status'))+" ")]),_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"name":"check-button","switch":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)])],1)],1)],1)],1),_c('b-card',{attrs:{"hidden":_vm.hiddenClientControl}},[_c('b-row',[_c('b-col',[_c('b-card-text',[_c('agent-annuity-price-detail-list',{attrs:{"getAgentAnnuityPriceList":_vm.getAgentAnnuityPriceList,"id":this.id}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }