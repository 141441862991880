<template>
    <div>
      <design-responsible-form :getDesignResponsibleList="getDesignResponsibleList" ref="designResponsibleFormModal"></design-responsible-form>
      <b-row>
        <b-col class="col-12">
          <div style="float:right;" class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-button style="float:right;" size="sm" variant="outline-primary"
                  @click="getInfo()">{{ $t('designResponsible.responsible_add') }}</b-button>
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table 
      id="designResponsibleTable"
      ref="designResponsibleTable" 
      :columns="columns" 
      :rows="rows"
      styleClass="vgt-table condensed tableSize"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
        theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'responsibleName'" class="text-nowrap">
            {{ $t('designResponsible.responsible_name') }}
          </span>
          <span v-else-if="props.column.field === 'email'" class="text-nowrap">
            {{ $t('designResponsible.email') }}
          </span>
          <span v-else-if="props.column.field === 'mailContactType'" class="text-nowrap">
            {{ $t('designResponsible.mail_contact_type') }}
          </span>
          <span v-else-if="props.column.field === 'responsibleField'" class="text-nowrap">
            {{ $t('designResponsible.responsible_field') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      designId: Number,
      clientId:Number
    },
    data() {
      return {
        dir: false,
        columns: [
          {
            label: this.$t('designResponsible.responsible_name') ,
            field: `responsibleName`,
          },
          {
            label: this.$t('designResponsible.email'),
            field: "email",
          },
          {
            label: this.$t('designResponsible.mail_contact_type'),
            field:"mailContactType",
          },
          {
             label: this.$t('designResponsible.responsible_field'),
             field:"responsibleField",
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      this.getDesignResponsibleList()
    },
    methods: {
      async deleted(data) {
        data = { id: data.id }
        const controller = { name: 'DesignResponsible' }
        const payload = { data: data, controller: controller }
        this.$DeleteConfirm().then(result => {          
          if (result.value) {
            this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$DeleteAlert()
                this.getDesignResponsibleList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getDesignResponsibleList()
              }
            })
          }
        })
      },
      async getInfo() {
        this.$refs.designResponsibleFormModal.showInfo(this.designId, this.clientId)
      },
      getDesignResponsibleList() {
        this.rows = [] 
        const data = { id: this.designId }
        const controller = { name: 'DesignResponsible', actionName:'GetDesignResponsibleListByDesignId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {  
            response.data.designResponsibleList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      }
    },
  }
  </script>
  <style lang="scss" >
    .tableSize {
      font-size: 13px!important;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      table-layout: auto;
  }
  </style>
  