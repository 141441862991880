<template>
    <div>
      <b-modal 
        ref="loginModalForm"  
        id="loginModalForm"    
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        no-title
        centered
        hide-footer
        @show="showInfo()">
        <div class="d-block text-center">
          <div class="auth-inner py-2">
            <!-- Login v1 -->
            <b-card class="mb-0">
      
              <b-card-title class="mb-1">
                {{ $t('others.please_reenter') }}
              </b-card-title>
              <b-card-text class="mb-2">
                {{ $t('others.ten_minutes') }}
              </b-card-text>
      
              <!-- form -->
              <validation-observer ref="simpleRules" #default="{ invalid }">
                <b-form class="auth-login-form mt-2">
                  <!-- email -->
                  <label for="username">{{ $t('login.username') }}</label>
                  <b-form-group>
                    <validation-provider #default="{ errors }" :name="$t('login.username')" rules="required|email">
                      <b-form-input id="username" disabled placeholder="test@stock.com" v-model="username" />
      
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
      
                  <!-- password -->
                  <b-form-group>
                    <label for="password">{{ $t('login.password') }}</label>
                    <validation-provider #default="{ errors }" :name="$t('login.password')" rules="required">
                      <b-input-group class="input-group-merge">
      
      
                        <b-form-input id="password" :type="passwordFieldType" placeholder="············" v-model="password"
                          @keyup.enter="login" />
      
                        <b-input-group-append is-text>
                          <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
      
                  <!-- submit button -->
                  <b-button variant="primary" type="submit" block :disabled="invalid" @click.prevent="login">
                    {{ $t('login.unlock') }}
                  </b-button>
                </b-form>
              </validation-observer>
            </b-card>
            <!-- /Login v1 -->
          </div>
        </div>
      </b-modal>
    </div>
  </template>
  <script>
import { localize } from 'vee-validate';
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    export default {
      mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      tokenValue: null
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  beforeMount() {
    //sessionStorage.language = this.$cookies.get("languageValue");
    sessionStorage.language = sessionStorage.getItem("languageValue");
    this.$i18n.locale = sessionStorage.language;
    localize(sessionStorage.language);
  },
  mounted() {
    if (!this.checkLogin()) {
      // this.$router.push({ name: 'Home' })
      this.$router.push({name: 'Home'}).catch(error => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(error)
        }
      })
    }
    //sessionStorage.language = this.$cookies.get("languageValue");
    sessionStorage.language = sessionStorage.getItem("languageValue");
  },
      methods: {
        showInfo() {
          this.username = localStorage.getItem('Email')
          this.$refs['loginModalForm'].show()
          this.tokenValue = localStorage.getItem("accessToken")
          localStorage.setItem("accessToken", null)
        },
        hideModal() {
          this.$refs['loginModalForm'].hide()
        },
        checkLogin() {
          if (this.$store.state.moduleAuth.isUserLoggedIn()) {
            return false
          }
          return true
    },
    login() {
      const payload = {
        username: this.username,
        password: this.password
      }
      /* eslint-disable */
      localStorage.setItem("accessToken", this.tokenValue)
      this.$store.dispatch('moduleAuth/login', payload).then((response) => {
        if(response.data.resultStatus == true){
          this.hideModal()
          this.password = ''
        } else if (response.data.resultStatus == false){
          localStorage.setItem("accessToken", null)
          this.$LoginError()
        } 
      }).catch(error => {
          this.$LoginError()
        })
    },
      },
    }
  </script>