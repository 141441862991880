<template>
    <div>
      <mail-send-form ref="mailSendFormModal"></mail-send-form>
      <patent-form ref="patentFormModal"></patent-form>
      <b-row class="align-items-center mb-1">
        <b-col class="d-flex align-items-center col-lg-8">
            <!--Starting Date Search-->
            <b-col class="col-md-5">
              <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" size="sm" v-model="startingDate" defaultDate:null reset-button 
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :placeholder="$t('patentWorkingReminder.starting_date')" 
              :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" 
              :label-no-date-selected="$t('dateTime.select_date')" />
            </b-col>
            <!--Ending Date Search-->
            <b-col class="col-md-5">
              <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" size="sm" v-model="endingDate" defaultDate: null reset-button 
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :placeholder="$t('patentWorkingReminder.ending_date')" 
                :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" 
                :label-no-date-selected="$t('dateTime.select_date')" />
            </b-col>
        </b-col>
        <b-col class="d-flex justify-content-end col-lg-4">
          <b-button style="float:right;" size="sm" variant="outline-primary" v-on:keyup.enter="getPatentWorkingReminderList()" @click="getPatentWorkingReminderList()">{{ $t('patent.list') }}</b-button>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table 
      id="patentWorkingReminderTable"
      ref="patentWorkingReminderTable" 
      :columns="columns" 
      :rows="rows"
      styleClass="vgt-table condensed tableSize"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
        theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
            {{ $t('patentWorkingReminder.our_ref') }}
          </span>
          <span v-else-if="props.column.field === 'applicationNo'" class="text-nowrap">
            {{ $t('patentWorkingReminder.application_no') }}
          </span>
          <span v-else-if="props.column.field === 'applicationDate'" class="text-nowrap">
            {{ $t('patentWorkingReminder.application_date') }}
          </span>
          <span v-else-if="props.column.field === 'workingDeclarationDeadline'" class="text-nowrap">
            {{ $t('patentWorkingReminder.working_declaration_deadline') }}
          </span>
          <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
            {{ $t('patentWorkingReminder.owner') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
                <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                    class="btn-icon mb-1 mr-1" size="sm" @click="getInfo(props.row.patentId)">
                    <feather-icon icon="EditIcon" />
                </b-button>
                <b-dropdown style="width: 1em; height: 2.5em;" id="dropdown-grouped" variant="primary" right class="dropdown-icon-wrapper mb-1" size="sm">
                  <template #button-content>
                    <feather-icon icon="MailIcon" size="10" class="align-middle" />
                  </template>
                  <b-dropdown-item v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id" @click="showMailForm(props.row.patentId, mailTemplate.id)" >
                    <span>{{mailTemplate.mailAccountName}}</span>
                  </b-dropdown-item>
                </b-dropdown>
            </span>
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      patentId: Number,
      getPatentList: {type: Function},
    },
    watch: {
      startingDate(newValue) {
            if(newValue == ''){
                this.startingDate = null
            }
        },
        endingDate(newValue) {
            if(newValue == ''){
                this.endingDate = null
            }
        },
    },
    data() {
      return {
        mailTemplateList: [],
        dir: false,
        columns: [
          {
            label: this.$t('patentWorkingReminder.our_ref'),
            field: 'recordNo',
          },
          {
            label : this.$t('patentWorkingReminder.application_no'),
            field : 'applicationNo', 
          },
          {
            label : this.$t('patentWorkingReminder.application_date'),
            field : 'applicationDate',
            formatFn: this.$formatFn
          },
          {
            label : this.$t('patentWorkingReminder.working_declaration_deadline'),
            field : 'workingDeclarationDeadline',
            formatFn: this.$formatFn
          },
          {
            label : this.$t('patentWorkingReminder.owner'),
            field : 'agent',
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
        item: 0,
        startingDate: new Date().toISOString().slice(0,10),
        endingDate: new Date().toISOString().slice(0,10),
      }
    },
    mounted() {
      this.getPatentWorkingReminderList()
    },
    methods: {
      async getInfo(patentId) {
        this.$refs.patentFormModal.showInfo(patentId)
      },
      getPatentWorkingReminderList() {
        this.item = 0
        this.rows = [] 
        const controller = { name: 'Patent', actionName: 'GetPatentWorkingReminderList' }
        const data = {startingDate: this.startingDate, endingDate: this.endingDate}
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.endingDate = response.data.endingDate
            this.startingDate = response.data.startingDate
            this.mailTemplateList = response.data.mailTemplateList 
            response.data.patentWorkingReminderList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
      showMailForm(patentId, mailTemplateId) {
            this.$refs.mailSendFormModal.showInfo()
            const data = {
              patentId: patentId,
              mailTemplateId: mailTemplateId
            }
            const controller = {
                name: 'MailTemplate',
                actionName: 'PreparePatentWorkingReminderMail'
            }
            const payload = {
                controller: controller,
                data: data
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    
                    this.$refs.mailSendForm.showInfo(response.data)  
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
  }
  </script>
  <style lang="scss" >
    .tableSize {
      font-size: 13px!important;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      table-layout: auto;
  }
  </style>
  