<template>
<div>
    <!-- modal -->
    <b-modal id="annualLeaveApproveFormModal" centered size="lg" no-close-on-backdrop ref="annualLeaveApproveFormModal" :title=" $t('annualLeave.info')" :cancel-title=" $t('hourlyAnnualLeave.decline')" :ok-title=" $t('hourlyAnnualLeave.accept')" cancel-variant="outline-danger" 
    @ok.prevent="approveAnnualLeave(id,true)" @cancel.prevent="approveAnnualLeave(id,false)">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="col-md-3 mt-1">
                        <b-form>
                            <label for="annual-leave-employee-input">{{ $t('annualLeave.employee') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="annual-leave-employee-input" v-model="employee" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3 mt-1">
                        <b-form>
                            <label for="annual-leave-request-date-input">{{ $t('annualLeave.request_date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0"  v-model="requestDate"></CustomDatePicker>
                        </b-form>                        
                    </b-col>
                    <b-col class="col-md-3 mt-1">
                        <b-form>
                            <label for="annual-leave-status-input">{{ $t('annualLeave.annual_leave_status') }}</label>
                            <b-form-select size="sm" v-model="annualLeaveStatusSelect" value-field="id" text-field="name" :options="annualLeaveStatusOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3 mt-1">
                        <b-form>
                            <label for="annual-leave-employee-vesting-input">{{ $t('annualLeave.employee_vesting') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="annual-leave-employee-vesting-input" v-model="employeeVesting" type="number" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-4 mt-1">
                        <label for="annual-leave-starting-date-input">{{ $t('annualLeave.starting_date') }}</label>
                        <b-form-group>
                            <flat-pickr disabled v-model="startingDate" class="form-control" size="sm" :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}" />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <label for="annual-leave-ending-date-input">{{ $t('annualLeave.ending_date') }}</label>
                        <flat-pickr disabled v-model="endingDate" class="form-control" size="sm" time_24hr="true" :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}" />
                    </b-col>
                    <b-col class="col-md-4 mt-1">
                        <b-form>
                            <label for="annual-leave-type-input">{{ $t('annualLeave.annual_leave_type') }}</label>
                            <b-form-select size="sm" v-model="annualLeaveTypeSelect" value-field="id" text-field="name" :options="annualLeaveTypeOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6 mt-1">
                        <label for="annual-leave-request-description-input">{{ $t('annualLeave.request_description') }}</label>
                        <b-form-textarea disabled size="sm" id="-annual-leave-request-description-input" v-model="requestDescription" rows="3" max-rows="8" maxlength="250" />
                    </b-col>
                    <b-col class="col-md-6 mt-1">
                        <label for="annual-leave-approve-description-input">{{ $t('annualLeave.approve_description') }}</label>
                        <b-form-textarea size="sm" id="annual-leave-approve-description-input" v-model="approveDescription" rows="3" max-rows="8" maxlength="250" />
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getAnnualLeaveList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            employee: '',
            startingDate: null,
            endingDate: null,
            requestDate: null,
            annualLeaveStatusSelect: 0,
            annualLeaveStatusOption: [],
            annualLeaveTypeSelect: 0,
            annualLeaveTypeOption: [],
            requestDescription: '',
            approveDescription: '',
            total: 0,
            tokenEmployeeId: 0,
            approval: false,
            employeeVesting: 0,
        }
    },
    methods: {
        showInfo(id, employeeId) {
            this.$refs['annualLeaveApproveFormModal'].show()
            this.tokenEmployeeId = localStorage.getItem('EmployeeId')
            if (this.employeeId > 0 && this.employeeId != this.tokenEmployeeId) {
                this.employeeId = this.employeeId
            } else {
                this.employeeId = this.tokenEmployeeId
            }
            this.id = id
            const data = {
                id: this.id,
                employeeId: this.employeeId
            }
            const controller = {
                name: 'AnnualLeaveRequest'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.startingDate = response.data.annualLeaveRequest.startingDate
                    this.endingDate = response.data.annualLeaveRequest.endingDate
                    this.requestDate = response.data.annualLeaveRequest.requestDate
                    this.annualLeaveStatusSelect = response.data.annualLeaveRequest.annualLeaveStatusId
                    this.annualLeaveStatusOption = response.data.annualLeaveStatusList
                    this.annualLeaveTypeSelect = response.data.annualLeaveRequest.annualLeaveTypeId
                    this.annualLeaveTypeOption = response.data.annualLeaveTypeList
                    this.requestDescription = response.data.annualLeaveRequest.requestDescription
                    this.approveDescription = response.data.annualLeaveRequest.approveDescription
                    this.annualLeaveStatusOption.splice(0, 0, this.$nullSelected())
                    this.annualLeaveTypeOption.splice(0, 0, this.$nullSelected())
                    this.employee = response.data.employee
                    this.employeeVesting = response.data.employeeAnnualLeaveVesting
                } else {
                    this.$bvModal.hide('annualLeaveApproveFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                    this.startingTime = ''
                    this.endingTime = ''
                    this.requestDescription = ''
                    this.annualLeaveStatusSelect = 0
                }
            })
        },
        approveAnnualLeave(id, approval) {
            this.id = id,
                this.approval = approval
            const controller = {
                name: 'AnnualLeaveRequest',
                actionName: 'ApproveAnnualLeaveRequest'
            }
            const data = {
                managerId: localStorage.getItem('EmployeeId'),
                id: this.id,
                approval: this.approval,
                approveDescription: this.approveDescription
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('annualLeaveApproveFormModal')
                            this.getAnnualLeaveList()
                            this.$SaveAlert()
                        } else {
                            this.$bvModal.hide('annualLeaveApproveFormModal')
                            this.$WarningAlert(response.data.resultMessage)
                            this.approveDescription = '',
                                this.approval = false
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
