<template>
    <div>
        <!-- modal -->
        <b-modal 
            id="patentPriorityFormModal"  centered size="lg" no-close-on-backdrop ref="patentPriorityFormModal" :title="$t('patentPriority.priority_info')"  :cancel-title=" $t('others.cancel_title')"
            :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" @close="clear()">
            <validation-observer ref="infoRules" tag="form">
                <b-row>
                    <b-col class="col-lg-12">
                        <b-form>
                            <label for="patentPriority-no-input">{{ $t('patentPriority.no') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('patentPriority.no')"
                                    rules="required">
                                    <b-form-input id="patentPriority-no-input" v-model="no" maxlength="20" size="sm"
                                        :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12 mb-1">
                        <validation-provider :name="$t('patentPriority.country')" #default="{ errors }"
                            :rules="{ excluded: 0 }">
                            <b-form>
                                <label for="country-input">{{ $t('patentPriority.country') }}</label>
                                <b-form-select v-model="countrySelect" value-field="id" text-field="name" size="sm"
                                    :options="countryOption" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <label for="patentPriority-date-input">{{ $t('patentPriority.date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="1" v-model="date"></CustomDatePicker>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>    
export default {
    props: {
        getPatentPriorityList: {
            type: Function
        },
    }, 
    data() {
        return {
            id: 0,
            patentId:0,
            date: null,
            no: '',
            countryOption: [],
            countrySelect: 0,
        }
    },
    methods: {
        showInfo(id, patentId) {
            this.$refs['patentPriorityFormModal'].show()
            this.id= id
            this.patentId= patentId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'PatentPriority'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.date = response.data.patentPriority.date
                    this.no = response.data.patentPriority.no

                    this.countrySelect = response.data.patentPriority.countryId
                    this.countryOption = response.data.countryList
                    this.countryOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('patentPriorityFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
            this.countrySelect = 0,
                this.no = null,
                this.date = null
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                patentId: this.patentId,
                countryId: this.countrySelect,
                date: this.date,
                no: this.no
            }
            const controller = {
                name: 'PatentPriority'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('patentPriorityFormModal')
                            this.getPatentPriorityList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        }
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
