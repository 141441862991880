<template>
<div>
    <!-- modal -->
    <b-modal id="mailToReadFormModal" centered size="lg" no-close-on-backdrop ref="mailToReadFormModal" :title=" $t('mailToRead.mail_to_read_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @close="clear()" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="mb-1">
                        <validation-provider :name="$t('mailToRead.module')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <label for="mailToRead-module-input">{{ $t('mailToRead.module') }}</label>
                            <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="moduleSelect" value-field="id" text-field="name" :options="moduleOption"  @change="moduleChange()"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col class="mb-1">
                        <validation-provider :name="$t('mailToRead.document_type')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <label for="mailToRead-documentType-input">{{ $t('mailToRead.document_type') }}</label>
                            <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="documentTypeSelect" value-field="id" text-field="name" :options="documentTypeOption"  @change="moduleChange()"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col class="mb-1">
                        <validation-provider :name="$t('mailToRead.document_type_detail')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                        <label for="mailToRead-documentTypeDetail-input">{{ $t('mailToRead.document_type_detail') }}</label>
                        <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="documentTypeDetailSelect" value-field="id" text-field="name" :options="documentTypeDetailOption"  @change="moduleChange()"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-1">
                        <label for="mailToRead-sender-input">{{ $t('mailToRead.sender_mail') }}</label>
                        <validation-provider #default="{ errors }" :name=" $t('mailToRead.sender_mail')" rules="required">
                            <b-form-input size="sm" id="mailToRead-sender-input" v-model="senderMail" maxlength="100" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col class="mb-1">
                        <validation-provider :name="$t('mailToRead.mail_read_type')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <label for="mailToRead-mail-read-type-input">{{ $t('mailToRead.mail_read_type') }}</label>
                            <b-form-select v-model="mailReadTypeSelect" size="sm" value-field="id" text-field="type" :options="mailReadTypeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-1">
                        <validation-provider #default="{ errors }" :name=" $t('mailToRead.mail_content')" rules="required">
                            <label for="mailToRead-mail-content-input">{{ $t('mailToRead.mail_content') }}</label>
                            <b-form-group>
                                <b-form-textarea id="mailToRead-mail-content-input" v-model="mailContent" size="sm" maxlength="250" rows="3" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getMailToReadList: {
            type: Function
        },
    },
    data() {
        return {
            subject: '',
            explanation: '',
            id: 0,
            mailToReadGroupSelect: 0,
            mailToReadGroupOption: [],
            moduleSelect: 0,
            moduleOption: [],
            documentTypeDetailSelect: 0,
            documentTypeDetailOption: [],
            documentTypeSelect: 0,
            documentTypeOption: [],
            mailContent: '',
            senderMail: '',
            mailReadTypeSelect: 0,
            mailReadTypeOption: [],
        }
    },
    methods: {
        clear() {
            this.mailToReadGroupSelect = 0
            this.moduleSelect = 0
            this.documentTypeSelect = 0
            this.documentTypeDetailSelect = 0
            this.senderMail = ''
            this.mailContent = ''
            this.mailReadTypeSelect = 0
        },
        moduleChange() {
            const controller = {
                name: 'DocumentType',
                actionName: 'GetDocumentTypeListByModuleId'
            }
            const data = {
                id: this.moduleSelect
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((documentTypeResponse) => {
                this.documentTypeOption = documentTypeResponse.data.documentTypeList
                this.documentTypeOption.splice(0, 0, this.$nullSelected('name'))
            })
        },
        documentTypeChange() {
            const controller = {
                name: 'DocumentTypeDetail',
                actionName: 'GetDocumentTypeDetailListByDocumentTypeId'
            }
            const data = {
                id: this.documentTypeSelect
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((documentTypeDetailResponse) => {
                this.documentTypeDetailOption = documentTypeDetailResponse.data.documentTypeDetailList
                this.documentTypeDetailOption.splice(0, 0, this.$nullSelected('name'))
            })
        },
        showInfo(id) {
            this.$refs['mailToReadFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'MailToRead'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.senderMail = response.data.mailToRead.senderMail
                    this.mailContent = response.data.mailToRead.mailContent
                    this.mailReadTypeSelect = response.data.mailToRead.mailReadTypeId
                    this.mailReadTypeOption = response.data.mailReadTypeList
                    this.mailReadTypeOption.splice(0, 0, this.$nullSelected('type'))
                    this.moduleOption = response.data.moduleList
                    this.moduleSelect = response.data.mailToRead.moduleId
                    this.moduleOption.splice(0, 0, this.$nullSelected('name'))
                    this.documentTypeDetailSelect = response.data.mailToRead.documentTypeDetailId
                    if (this.moduleSelect > 0) {
                        const controller = {
                            name: 'DocumentType',
                            actionName: 'GetDocumentTypeListByModuleId'
                        }
                        const data = {
                            id: this.moduleSelect
                        }
                        const payload = {
                            data: data,
                            controller: controller
                        }
                        this.$store.dispatch('moduleAuth/customService', payload).then((documentTypeResponse) => {
                            this.documentTypeOption = documentTypeResponse.data.documentTypeList
                            this.documentTypeOption.splice(0, 0, this.$nullSelected('name'))
                        })
                    }
                    if (response.data.mailToRead.documentTypeDetailId > 0) {
                        const data1 = {
                            id: response.data.mailToRead.documentTypeDetailId
                        }
                        const controller1 = {
                            name: 'DocumentTypeDetail'
                        }
                        const payload1 = {
                            data: data1,
                            controller: controller1
                        }
                        this.$store.dispatch('moduleAuth/getInfo', payload1).then((getInfoResponse) => {
                            this.documentTypeSelect = getInfoResponse.data.documentTypeDetail.documentTypeId
                            const controller = {
                                name: 'DocumentTypeDetail',
                                actionName: 'GetDocumentTypeDetailListByDocumentTypeId'
                            }
                            const data = {
                                id: getInfoResponse.data.documentTypeDetail.documentTypeId
                            }
                            const payload = {
                                data: data,
                                controller: controller
                            }
                            this.$store.dispatch('moduleAuth/customService', payload).then((documentTypeDetailResponse) => {
                                this.documentTypeDetailOption = documentTypeDetailResponse.data.documentTypeDetailList
                                this.documentTypeDetailOption.splice(0, 0, this.$nullSelected('name'))
                            })

                        })

                    }
                } else {
                    this.$bvModal.hide('mailToReadFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },

        addOrUpdate() {
            const data = {
                id: this.id,
                mailAccountId: this.mailToReadGroupSelect,
                moduleId: this.moduleSelect,
                documentTypeDetailId: this.documentTypeDetailSelect,
                mailContent: this.mailContent,
                senderMail: this.senderMail,
                mailReadTypeId : this.mailReadTypeSelect
            }
            const controller = {
                name: 'MailToRead'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('mailToReadFormModal')
                            this.getMailToReadList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
