<template>
<div>
    <!-- modal -->
    <b-modal id="translatorPriceFormModal" centered size="lg" no-close-on-backdrop ref="translatorPriceFormModal" :title=" $t('translatorPrice.price_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" @close="clear()">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-4 mt-1">
                    <b-form>
                        <label for="price-wordPrice-input">{{ $t('translatorPrice.word_price') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('translatorPrice.word_price')" rules="required">
                                <b-form-input size="sm" id="price-wordPrice-input"  v-model="wordPrice"  maxlength="7"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4 mt-1">
                    <b-form>
                        <label for="price-imagePrice-input">{{ $t('translatorPrice.image_price') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('translatorPrice.image_price')" rules="required" >
                                <b-form-input size="sm"  id="price-wordPrice-input"  v-model="imagePrice" maxlength="7"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4 mt-1">
                    <b-form>
                        <label for="price-formulaPrice-input">{{ $t('translatorPrice.formula_price') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('translatorPrice.formula_price')"  rules="required" >
                                <b-form-input size="sm" id="price-formulaPrice-input"  v-model="formulaPrice" maxlength="7"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-4 mt-1" >
                    <b-form>
                        <label for="price-tablePrice-input">{{ $t('translatorPrice.table_price') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('translatorPrice.table_price')" rules="required" >
                                <b-form-input size="sm" id="price-tablePrice-input"  v-model="tablePrice" maxlength="7" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4 mt-1"> 
                    <b-form>
                        <label for="price-pagePrice-input">{{ $t('translatorPrice.page_price') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('translatorPrice.page_price')" rules="required" >
                                <b-form-input size="sm" id="price-pagePrice-input" v-model="pagePrice" maxlength="7"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                    <b-col class="col-md-4 mt-1">
                        <b-form-group>
                            <label for="design-date-input">{{ $t('translatorPrice.date') }}</label>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="leaveDate"></CustomDatePicker>
                        </b-form-group>
                </b-col>

            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>    
export default {
    props: {
        getTranslatorPriceList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            translatorId : 0,
            formulaPrice: 0,
            imagePrice: 0,
            wordPrice: 0,
            pagePrice: 0,
            tablePrice: 0,
            date: null,
        }
    },
    methods: {
        showInfo(id, translatorId) {            
            this.$refs['translatorPriceFormModal'].show() 
            this.id = id
            this.translatorId = translatorId;
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TranslatorPrice'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.formulaPrice = response.data.translatorPrice.formulaPrice
                    this.imagePrice = response.data.translatorPrice.imagePrice
                    this.wordPrice = response.data.translatorPrice.wordPrice
                    this.pagePrice = response.data.translatorPrice.pagePrice
                    this.tablePrice = response.data.translatorPrice.tablePrice
                    this.date = response.data.translatorPrice.date

                } else {
                    this.$bvModal.hide('translatorPriceFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                translatorId: this.translatorId,
                formulaPrice: this.formulaPrice,
                tablePrice: this.tablePrice,
                pagePrice: this.pagePrice,
                imagePrice : this.imagePrice,
                wordPrice: this.wordPrice,
                date : this.date,

            }
            const controller = {
                name: 'TranslatorPrice'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('translatorPriceFormModal')
                            this.getTranslatorPriceList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
