<template>
    <div>
      <patent-Translate-form :getPatentList="getPatentList" :getPatentTranslateList="getPatentTranslateList" ref="patentTranslateFormModal">
                </patent-Translate-form>
      <b-row>
        <b-col class="col-12">
          <div style="float:right;" class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-button style="float:right;" size="sm" variant="outline-primary"
                  @click="getInfo(0)">{{ $t('patentTranslate.translate_add') }}</b-button>
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table 
      id="patentTranslateTable"
      ref="patentTranslateTable" 
      :columns="columns" 
      :rows="rows"
      styleClass="vgt-table condensed tableSize"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
        theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'translator'" class="text-nowrap">
            {{ $t('patentTranslate.translator') }}
          </span>
          <span v-else-if="props.column.field === 'language'" class="text-nowrap">
            {{ $t('patentTranslate.language') }}
          </span>
          <span v-else-if="props.column.field === 'delivery_to_translator_date'" class="text-nowrap">
            {{ $t('patentTranslate.delivery_to_translator_date') }}
          </span>
          <span v-else-if="props.column.field === 'translator_deadline_date'" class="text-nowrap">
            {{ $t('patentTranslate.translator_deadline_date') }}
          </span>
          <span v-else-if="props.column.field === 'translator_delivery_date'" class="text-nowrap">
            {{ $t('patentTranslate.translator_delivery_date') }}
          </span>
          <span v-else-if="props.column.field === 'payment_date'" class="text-nowrap">
            {{ $t('patentTranslate.payment_date') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
              class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
              <feather-icon icon="EditIcon" />
            </b-button>
              <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      patentId: Number,
      getPatentList: {type: Function},
    },
    data() {
      return {
        dir: false,
        columns: [
          {
            label: this.$t('patentTranslate.translator'),
            field: 'translator',
          },
          {
            label : this.$t('patentTranslate.language'),
            field : 'language', 
          },
          {
            label : this.$t('patentTranslate.delivery_to_translator_date'),
            field : 'deliveryToTranslatorDate',
            formatFn: this.$formatFn
          },
          {
            label : this.$t('patentTranslate.translator_deadline_date'),
            field : 'translatorDeadlineDate',
            formatFn: this.$formatFn
          },
          {
            label : this.$t('patentTranslate.translator_delivery_date'),
            field : 'translatorDeliveryDate',
            formatFn: this.$formatFn
          },
          {
            label : this.$t('patentTranslate.payment_date'),
            field : 'paymentDate',
            formatFn: this.$formatFn
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      this.getPatentTranslateList()
    },
    methods: {
      async deleted(data) {
        data = { id: data.id }
        const controller = { name: 'PatentTranslate' }
        const payload = { data: data, controller: controller }
        this.$DeleteConfirm().then(result => {
          if (result.value) {
            this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$DeleteAlert()
                this.getPatentTranslateList()
                this.getPatentList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getPatentTranslateList()
              }
            })
          }
        })
      },
      async getInfo(id) {
        this.$refs.patentTranslateFormModal.showInfo(id, this.patentId)
      },
      getPatentTranslateList() {
        this.rows = [] 
        const data = { id: this.patentId }
        const controller = { name: 'PatentTranslate', actionName:'GetPatentTranslateListByPatentId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            response.data.patentTranslateList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
    },
  }
  </script>
  <style lang="scss" >
    .tableSize {
      font-size: 13px!important;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      table-layout: auto;
  }
  </style>
  