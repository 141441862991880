<template>
<div>
    <!-- modal -->
    <b-modal id="mailTemplateFormModal" centered size="lg" no-close-on-backdrop ref="mailTemplateFormModal" :title=" $t('mailTemplate.mail_template_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @close="clear()" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="mb-1">
                        <label for="mailTemplate-address-input">{{ $t('mailTemplate.mail_address') }}</label>
                        <b-form-select v-model="mailTemplateGroupSelect" size="sm" value-field="id" text-field="mailAddress" :options="mailTemplateGroupOption" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-1">
                        <label for="mailTemplate-module-input">{{ $t('mailTemplate.module') }}</label>
                        <b-form-select v-model="moduleSelect" size="sm" value-field="id" @change="moduleChange()" text-field="name" :options="moduleOption" />
                    </b-col>
                    <b-col class="mb-1">
                        <label for="mailTemplate-documentType-input">{{ $t('mailTemplate.document_type') }}</label>
                        <b-form-select v-model="documentTypeSelect" size="sm" value-field="id" text-field="name" @change="documentTypeChange()" :options="documentTypeOption" />
                    </b-col>
                    <b-col class="mb-1">
                        <label for="mailTemplate-documentTypeDetail-input">{{ $t('mailTemplate.document_type_detail') }}</label>
                        <b-form-select v-model="documentTypeDetailSelect" size="sm" value-field="id" text-field="name" :options="documentTypeDetailOption" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label for="mailTemplate-cc-input">{{ $t('mailTemplate.cc') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="mailTemplate-cc-textarea" v-model="cc" rows="3" max-rows="8" maxlength="200" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label for="mailTemplate-bcc-input">{{ $t('mailTemplate.bcc') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="mailTemplate-bcc-textarea" v-model="bcc" rows="3" max-rows="8" maxlength="200" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-1">
                        <label for="mailTemplate-textarea">{{ $t('mailTemplate.explanation') }}</label>
                        <b-form-textarea size="sm" id="mailTemplate-textarea" v-model="explanation" rows="3" max-rows="8" maxlength="100" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <validation-provider #default="{ errors }" :name=" $t('mailTemplate.subject')" rules="required">
                            <label for="mailTemplate-subject-input">{{ $t('mailTemplate.subject') }}</label>
                            <b-form-group>
                                <b-form-input id="mailTemplate-subject-input" v-model="subject" size="sm" maxlength="250" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label for="mail-template-content-input" class="mt-2">{{ $t('mailTemplate.content') }}</label>
                        <Vueditor ref="editor"></Vueditor>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getMailTemplateList: {
            type: Function
        },
    },
    data() {
        return {
            subject: '',
            explanation: '',
            id: 0,
            mailTemplateGroupSelect: 0,
            mailTemplateGroupOption: [],
            moduleSelect: 0,
            moduleOption: [],
            documentTypeSelect: 0,
            documentTypeOption: [],
            documentTypeDetailSelect: 0,
            documentTypeDetailOption: [],
            bcc: '',
            cc:'',
        }
    },
    methods: {
        clear() {
            this.mailTemplateGroupSelect = 0
            this.moduleSelect = 0
            this.documentTypeSelect = 0
            this.documentTypeDetailSelect = 0
            this.$refs.editor.setContent("");
        },
        moduleChange() {
            const controller = {
                name: 'DocumentType',
                actionName: 'GetDocumentTypeListByModuleId'
            }
            const data = {
                id: this.moduleSelect
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((documentTypeResponse) => {
                this.documentTypeOption = documentTypeResponse.data.documentTypeList
                this.documentTypeOption.splice(0, 0, this.$nullSelected('name'))
            })
        },
        documentTypeChange() {
            const controller = {
                name: 'DocumentTypeDetail',
                actionName: 'GetDocumentTypeDetailListByDocumentTypeId'
            }
            const data = {
                id: this.documentTypeSelect
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((documentTypeDetailResponse) => {
                this.documentTypeDetailOption = documentTypeDetailResponse.data.documentTypeDetailList
                this.documentTypeDetailOption.splice(0, 0, this.$nullSelected('name'))
            })
        },
        showInfo(id) {
            this.$refs['mailTemplateFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'MailTemplate'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.subject = response.data.mailTemplate.subject
                    this.$refs.editor.setContent(response.data.mailTemplate.body);
                    this.bcc = response.data.mailTemplate.bcc,
                    this.cc = response.data.mailTemplate.cc,
                    this.explanation = response.data.mailTemplate.explanation
                    this.mailTemplateGroupOption = response.data.mailAccountList
                    this.mailTemplateGroupSelect = response.data.mailTemplate.mailAccountId
                    this.mailTemplateGroupOption.splice(0, 0, this.$nullSelected('mailAddress'))
                    this.moduleOption = response.data.moduleList
                    this.moduleSelect = response.data.mailTemplate.moduleId
                    this.moduleOption.splice(0, 0, this.$nullSelected('name'))
                    this.documentTypeDetailSelect = response.data.mailTemplate.documentTypeDetailId
                    if (this.moduleSelect > 0) {
                        const controller = {
                            name: 'DocumentType',
                            actionName: 'GetDocumentTypeListByModuleId'
                        }
                        const data = {
                            id: this.moduleSelect
                        }
                        const payload = {
                            data: data,
                            controller: controller
                        }
                        this.$store.dispatch('moduleAuth/customService', payload).then((documentTypeResponse) => {
                            this.documentTypeOption = documentTypeResponse.data.documentTypeList
                            this.documentTypeOption.splice(0, 0, this.$nullSelected('name'))
                        })
                    }
                    if (response.data.mailTemplate.documentTypeDetailId > 0) {
                        const data1 = {
                            id: response.data.mailTemplate.documentTypeDetailId
                        }
                        const controller1 = {
                            name: 'DocumentTypeDetail'
                        }
                        const payload1 = {
                            data: data1,
                            controller: controller1
                        }
                        this.$store.dispatch('moduleAuth/getInfo', payload1).then((getInfoResponse) => {
                            this.documentTypeSelect = getInfoResponse.data.documentTypeDetail.documentTypeId
                            const controller = {
                                name: 'DocumentTypeDetail',
                                actionName: 'GetDocumentTypeDetailListByDocumentTypeId'
                            }
                            const data = {
                                id: getInfoResponse.data.documentTypeDetail.documentTypeId
                            }
                            const payload = {
                                data: data,
                                controller: controller
                            }
                            this.$store.dispatch('moduleAuth/customService', payload).then((documentTypeDetailResponse) => {
                                this.documentTypeDetailOption = documentTypeDetailResponse.data.documentTypeDetailList
                                this.documentTypeDetailOption.splice(0, 0, this.$nullSelected('name'))
                            })

                        })

                    }
                } else {
                    this.$bvModal.hide('mailTemplateFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },

        addOrUpdate() {
            const data = {
                id: this.id,
                mailAccountId: this.mailTemplateGroupSelect,
                subject: this.subject,
                explanation: this.explanation,
                moduleId: this.moduleSelect,
                documentTypeDetailId: this.documentTypeDetailSelect,
                body: this.$refs.editor.getContent(),
                cc : this.cc,
                bcc : this.bcc
            }
            const controller = {
                name: 'MailTemplate'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('mailTemplateFormModal')
                            this.getMailTemplateList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.vueditor {
    min-width: 300px !important;
    min-height: 50em !important;
    height: 95% !important;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
}
</style>
